import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import axios from 'axios';
import { useMsal } from '@azure/msal-react';
import { silentRequest } from '../../../../authConfig';

import * as signalR from '@microsoft/signalr';

import paginate from 'jw-paginate';

import {
  DetailsListLayoutMode,
  ShimmeredDetailsList,
  Calendar,
  DateRangeType,
  Selection,
  SelectionMode,
  CheckboxVisibility,
  mergeStyleSets,
  ActionButton,
  Spinner,
  SpinnerSize,
  CommandBar,
  SearchBox,
  Stack,
  Dialog,
  DialogType,
  DialogFooter,
  PrimaryButton,
  DefaultButton,
  mergeStyles,
  TextField,
  MessageBar,
  MessageBarType,
  DocumentCard,
  DocumentCardTitle,
  DocumentCardDetails,
  DocumentCardLogo,
  DocumentCardType,
  Text,
  ContextualMenu,
  ContextualMenuItemType,
  Link,
  IconButton,
  Checkbox,
  TooltipHost,
  Icon,
  DatePicker,
  MessageBarButton,
  DirectionalHint,
} from '@fluentui/react';

// import { getFileTypeIconProps } from '@uifabric/file-type-icons';
import { getFileTypeIconProps } from '@fluentui/react-file-type-icons';

// import { Pagination } from '@uifabric/experiments/lib/Pagination';
import { Pagination } from '@fluentui/react-experiments/lib/Pagination';

import CountUp from 'react-countup';

import { CSVLink } from 'react-csv';

import PdfAllPages from '../pdf/view/PdfAllPages';

import html2canvas from 'html2canvas';
import { useDidMountEffect } from '../../../../helpers/customHooks';
import { TextFieldEmail } from '../../../../helpers/customComponents/TextFieldEmail';
import { checkEmail } from '../../../../helpers/CheckEmail';
import { dateDifference } from '../../../../helpers/CustomFunctions';
var JSZip = require('jszip');

const API_URL = process.env.REACT_APP_API_URL;
const CERTIFICATE_REQUEST_LINK = process.env.REACT_APP_CERTIFICATE_REQUEST_LINK;
const CERTIFICATE_EXPIRE_NOTIFICATION_PERIOD = process.env.REACT_APP_CERTIFICATE_EXPIRE_NOTIFICATION_PERIOD;

const homeStyles = {
  border: 0,
  margin: 0,
  width: '100%',
  minWidth: 'calc(100vw - 310px)',
  background: '#FAF9F8',
  position: 'relative',
};

//Dialog Style
// Success dialog style
const iconClass = mergeStyles({
  fontSize: 120,
  height: 120,
  width: 120,
  margin: '0 60px',
});

const classNames = mergeStyleSets({
  fileIconHeaderIcon: {
    padding: 0,
    fontSize: '16px',
  },
  fileIconCell: {
    textAlign: 'center',
    selectors: {
      '&:before': {
        content: '.',
        display: 'inline-block',
        verticalAlign: 'middle',
        height: '100%',
        width: '0px',
        visibility: 'hidden',
      },
    },
  },
  fileIconImg: {
    verticalAlign: 'middle',
    maxHeight: '16px',
    maxWidth: '16px',
  },
  controlWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  exampleToggle: {
    display: 'inline-block',
    marginBottom: '10px',
    marginRight: '30px',
  },
  selectionDetails: {
    marginBottom: '20px',
  },
  blue: [
    {
      color: '#0078D4',
    },
    iconClass,
  ],
});

const cardStyles = {
  cardStyles: {
    root: {
      background: 'white',
      borderLeft: '5px solid #0078d4',
      width: '27%',
      minWidth: '332px',
      maxWidth: '424px',
      height: '130px',
    },
  },
  header: {
    root: {
      fontSize: 20,
      fontWeight: 'bold',
      paddingTop: '20px',
      textAlign: 'center',
    },
  },
  amount: {
    root: {
      fontSize: 30,
      paddingBottom: '25px',
      textAlign: 'center',
    },
  },
};

function formatDateTime(date) {
  let d = new Date(date.toLocaleString('en-GB', { timeZone: 'Asia/Bangkok' })),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear(),
    hour = '' + d.getHours(),
    minute = '' + d.getMinutes(),
    second = '' + d.getSeconds();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  if (hour.length < 2) hour = '0' + hour;
  if (minute.length < 2) minute = '0' + minute;
  if (second.length < 2) second = '0' + second;

  return [year, month, day, hour, minute, second].join('');
}

const onFormatDate = (date, format) => {
  if (format === 'CE') {
    return `${date.getDate()}`.padStart(2, '0') + '/' + `${date.getMonth() + 1}`.padStart(2, '0') + '/' + date.getFullYear();
  } else {
    return `${date.getDate()}`.padStart(2, '0') + '/' + `${date.getMonth() + 1}`.padStart(2, '0') + '/' + (date.getFullYear() + 543);
  }
};

const headerCSV = [
  { key: 'A', label: 'order' },
  { key: 'B', label: 'documentType' },
  { key: 'C', label: 'number' },
  { key: 'D', label: 'dateBE' },
  { key: 'E', label: 'dateCE' },
  { key: 'F', label: 'dueDateBE' },
  { key: 'G', label: 'dueDateCE' },
  { key: 'H', label: 'reference' },
  { key: 'I', label: 'includeVat' },
  { key: 'J', label: 'calculationMethod' },
  { key: 'K', label: 'itemsNumber' },
  { key: 'L', label: 'itemsSKU' },
  { key: 'M', label: 'itemsDescription' },
  { key: 'N', label: 'itemsQuantity' },
  { key: 'O', label: 'itemsUnitName' },
  { key: 'P', label: 'itemsPrice' },
  { key: 'Q', label: 'itemsDiscount' },
  { key: 'R', label: 'itemsPercentVat' },
  { key: 'S', label: 'preVatAmount' },
  { key: 'T', label: 'vatAmount' },
  { key: 'U', label: 'total' },
  { key: 'V', label: 'discount' },
  { key: 'W', label: 'nonVatPriceTotal' },
  { key: 'X', label: 'zeroVatPriceTotal' },
  { key: 'Y', label: 'vatPriceTotal' },
  { key: 'Z', label: 'vatTotal' },
  { key: 'AA', label: 'grandTotal' },
  { key: 'AB', label: 'language' },
  { key: 'AC', label: 'customerName' },
  { key: 'AD', label: 'customerAddressLineOne' },
  { key: 'AE', label: 'customerAddressLineTwo' },
  { key: 'AF', label: 'customerPostcode' },
  { key: 'AG', label: 'customerCountryName' },
  { key: 'AH', label: 'customerTaxNumberType' },
  { key: 'AI', label: 'customerTaxId' },
  { key: 'AJ', label: 'customerBranchNumber' },
  { key: 'AK', label: 'customerPhone' },
  { key: 'AL', label: 'customerEmail' },
  { key: 'AM', label: 'note' },
  { key: 'AN', label: 'createdBy' },
  { key: 'AO', label: 'receivedBy' },
  { key: 'AP', label: 'promptpay' },
  { key: 'AQ', label: 'reIssue' },
  { key: 'AR', label: 'referTypeName' },
  { key: 'AS', label: 'referNumber' },
  { key: 'AT', label: 'referDateBE' },
  { key: 'AU', label: 'referDateCE' },
  { key: 'AV', label: 'referAmountTotal' },
  { key: 'AW', label: 'referReasonName' },
  { key: 'AX', label: 'referSpecificReason' },
  { key: 'AY', label: 'sentEmailTime' },
  { key: 'AZ', label: 'sentSmsTime' },
];

const AllDocuments = () => {
  console.log('Home render!!!');

  const { t, i18n } = useTranslation();

  const history = useHistory();

  const { instance } = useMsal();
  const account = instance.getAllAccounts()[0];

  const companyId = sessionStorage.getItem('companyId') || '';

  console.log("companyId", companyId)

  const printRef = useRef();
  const [url, setUrl] = useState('');
  const [fileName, setFileName] = useState('');

  const [items, setItems] = useState([]);
  const [loadDataComplete, setLoadDataComplete] = useState(false);
  const [getNewDocuments, setGetNewDocuments] = useState(true);

  const [totalProcessingDocuments, setTotalProcessingDocuments] = useState(0);
  const [pageData, setPageData] = useState({});
  const [pageSize, setPageSize] = useState(sessionStorage.getItem('pageSize') * 1 || 10);
  const [currentPage, setCurrentPage] = useState(sessionStorage.getItem('currentPage') * 1 || 1);

  const [search, setSearch] = useState('');
  const [previousSearch, setPreviousSearch] = useState('');

  const [enableTopup, setEnableTopup] = useState(false);

  const [csvData, setCsvData] = useState({ fileName: '', data: [], downloaded: false });

  const csvLink = useRef();

  //CommandBars Items
  const [documentType, setDocumentType] = useState(
    (sessionStorage.getItem('documentType-key') && {
      key: sessionStorage.getItem('documentType-key'),
      text: sessionStorage.getItem('documentType-text'),
    }) || {
      key: 'ALL',
      text: t('documents.allDocumentsType'),
    }
  );

  const [isClickEmail, setIsClickEmail] = useState(false);
  const [isClickCopyEmail, setIsClickCopyEmail] = useState(false);
  const [fileIdEmail, setFileIdEmail] = useState('');
  const [fileNameEmail, setFileNameEmail] = useState('');
  const [fileDisplayName, setFileDisplayName] = useState('');
  const [customerEmail, setCustomerEmail] = useState('');
  const [emailList, setEmailList] = useState([]);
  const [copyEmailList, setCopyEmailList] = useState([])
  const [copyEmail, setCopyEmail] = useState('');
  const [showEmailSuccess, setShowEmailSuccess] = useState(false);
  const [showEmailError, setShowEmailError] = useState(false);

  const [showCertificateSetup, setShowCertificateSetup] = useState(false);
  // const [showXmlDeliverSetup, setShowXmlDeliverSetup] = useState(false);
  // const [showXmlDeliver, setShowXmlDeliver] = useState(true);
  const [showRepeatNumber, setShowRepeatNumber] = useState(false);
  const [showXmlDeliverWarning, setShowXmlDeliverWarning] = useState(false);

  const [duplicatedNumbers, setDuplicatedNumbers] = useState([])

  const [showNewsEvent, setShowNewsEvent] = useState(false);

  const [isClickSms, setIsClickSms] = useState(false);
  const [fileIdSms, setFileIdSms] = useState('');
  const [fileNameSms, setFileNameSms] = useState('');
  const [customerPhone, setCustomerPhone] = useState('');
  const [showSmsSuccess, setShowSmsSuccess] = useState(false);
  const [showSmsError, setShowSmsError] = useState(false);
  const [errorMessageSms, setErrorMessageSms] = useState('');

  const [showCertificatePendingExpired, setShowCertificatePendingExpired] = useState(false)
  const [showCertificateExpired, setShowCertificateExpired] = useState(false)
  const [certificateExpiredDate, setCertificateExpiredDate] = useState(null)

  //Display Summary
  const [totalDocuments, setTotalDocuments] = useState(0);
  const [totalVat, setTotalVat] = useState(0);
  const [totalGrand, setTotalGrand] = useState(0);

  const [dialogConfirm, setDialogConfirm] = useState({
    isClick: false,
    header: '',
    message: '',
    onConfirm: null,
  });

  const [dialogBatchSendEmailConfirm, setDialogBatchSendEmailConfirm] = useState({
    isClick: false,
    header: '',
    message: '',
  });

  const [isProcessing, setIsProcessing] = useState(false);
  const [isProcessingSms, setIsProcessingSms] = useState(false);
  const [isEmailSetting, setIsEmailSetting] = useState(false);

  const [selectedItems, setSelectedItems] = useState([]);

  const [selection] = useState(
    new Selection({
      onSelectionChanged: () => {
        let _selectedItems = selection.getSelection();

        setSelectedItems(_selectedItems);
      },
    })
  );

  const [primarySort, setPrimarySort] = useState(sessionStorage.getItem('primarySort') || 'createdTime');

  const [sortDirection, setSortDirection] = useState(
    sessionStorage.getItem('sortDirection') || 'DESC'
  );

  const [isSortedDescendingCreatedTime, setIsSortedDescendingCreatedTime] = useState(() => {
    if (sessionStorage.getItem('sort-createdTime') && sessionStorage.getItem('sort-createdTime') === 'false') {
      return false;
    } else {
      return true;
    }
  });
  const [isSortedDescendingNumber, setIsSortedDescendingNumber] = useState(() => {
    if (sessionStorage.getItem('sort-number') && sessionStorage.getItem('sort-number') === 'true') {
      return true;
    } else {
      return false;
    }
  });
  const [isSortedDescendingDocumentDate, setIsSortedDescendingDocumentDate] = useState(() => {
    if (sessionStorage.getItem('sort-documentDate') && sessionStorage.getItem('sort-documentDate') === 'true') {
      return true;
    } else {
      return false;
    }
  });
  const [isSortedDescendingCustomerName, setIsSortedDescendingCustomerName] = useState(() => {
    if (sessionStorage.getItem('sort-customerName') && sessionStorage.getItem('sort-customerName') === 'true') {
      return true;
    } else {
      return false;
    }
  });
  const [isSortedDescendingGrandTotal, setIsSortedDescendingGrandTotal] = useState(() => {
    if (sessionStorage.getItem('sort-grandTotal') && sessionStorage.getItem('sort-grandTotal') === 'true') {
      return true;
    } else {
      return false;
    }
  });
  const [isSortedDescendingName, setIsSortedDescendingName] = useState(() => {
    if (sessionStorage.getItem('sort-name') && sessionStorage.getItem('sort-name') === 'true') {
      return true;
    } else {
      return false;
    }
  });
  const [isSortedDescendingEmailReceiver, setIsSortedDescendingEmailReceiver] = useState(() => {
    if (sessionStorage.getItem('sort-emailReceiver') && sessionStorage.getItem('sort-emailReceiver') === 'true') {
      return true;
    } else {
      return false;
    }
  });
  const [isSortedDescendingEmailTime, setIsSortedDescendingEmailTime] = useState(() => {
    if (sessionStorage.getItem('sort-emailTime') && sessionStorage.getItem('sort-emailTime') === 'true') {
      return true;
    } else {
      return false;
    }
  });

  const [companyEmail, setCompanyEmail] = useState('');

  const [enableSms, setEnableSms] = useState(false);

  const [digitalSign, setDigitalSign] = useState(false);
  
  const [deletedDocumentsOnly, setDeletedDocumentsOnly] = useState(false)

  const getMonth = (goBack) => {
    let monthNames = [
      t('documents.months01'),
      t('documents.months02'),
      t('documents.months03'),
      t('documents.months04'),
      t('documents.months05'),
      t('documents.months06'),
      t('documents.months07'),
      t('documents.months08'),
      t('documents.months09'),
      t('documents.months10'),
      t('documents.months11'),
      t('documents.months12'),
    ];

    let d = new Date();

    let adjustDate = new Date(d.getFullYear(), d.getMonth() - goBack, 1);

    let year = adjustDate.getFullYear() + 543;

    return {
      month: adjustDate.getMonth(),
      year: adjustDate.getFullYear(),
      key: adjustDate.getMonth() + 1,
      text: monthNames[adjustDate.getMonth()] + ' ' + year,
    };
  };

  const [queryMonth, setQueryMonth] = useState(
    (sessionStorage.getItem("queryMonth-key") && {
      key: sessionStorage.getItem("queryMonth-key"),
      text: sessionStorage.getItem("queryMonth-text"),
      ...(sessionStorage.getItem("queryMonth-type") ? { type: sessionStorage.getItem("queryMonth-type") } : {}),
    }) || { key: getMonth(0).year + '' + getMonth(0).key, text: t('documents.thisMonth') }
  );

  const [timeRangeDate, setTimeRangeDate] = useState(sessionStorage.getItem("queryMonth-type") ? true : false);
  const [fromDate, setFromDate] = useState(sessionStorage.getItem("fromDate") ? new Date(sessionStorage.getItem("fromDate")) : null);
  const [toDate, setToDate] = useState(sessionStorage.getItem("toDate") ? new Date(sessionStorage.getItem("toDate")) : null);
  const [timeRangeError, setTimeRangeError] = useState(false);

  // useEffect(() => {
  //   console.log([...emailList.map(_email => (
  //     { item: _email, isSelected: false, key: _email }
  //   ))])
  // }, [emailList])
  
  const getFrom = (month, year) => {
    let _firstDay = new Date(year, month, 1);
    console.log('First day: ', formatDate(_firstDay));

    return formatDate(_firstDay);
  };

  const getTo = (month, year) => {
    let _lastDay = new Date(year, month + 1, 1);
    console.log('Last day: ', formatDate(_lastDay));

    return formatDate(_lastDay);
  };

  const getFromDMY = (day, month, year) => {
    let _firstDay = new Date(year, month, day);
    console.log('First day: ', formatDate(_firstDay));

    return formatDate(_firstDay);
  };

  const getToDMY = (day, month, year) => {
    let _lastDay = new Date(year, month, day + 1);
    console.log('Last day: ', formatDate(_lastDay));

    return formatDate(_lastDay);
  };

  const formatDate = (d) => {
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    let year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  };

  const [from, setFrom] = useState(sessionStorage.getItem('from') || getFrom(getMonth(0).month, getMonth(0).year));
  const [to, setTo] = useState(sessionStorage.getItem('to') || getTo(getMonth(0).month, getMonth(0).year));

  const [date, setDate] = useState(sessionStorage.getItem('date') ? new Date(sessionStorage.getItem('date')) : new Date());

  const [pageDropdown, setPageDropdown] = useState([]);
  const [dateType, setDateType] = useState(
    (sessionStorage.getItem('dateType-key') && {
      key: sessionStorage.getItem('dateType-key'),
      text: sessionStorage.getItem('dateType-text'),
    }) || { key: 'created', text: t('documents.createdDate') }
  );

  const resetSort = () => {
    if (dateType.key === 'document') {
      setPrimarySort('documentDate');
      setIsSortedDescendingDocumentDate(true);
      setIsSortedDescendingCreatedTime(false);

      sessionStorage.setItem('primarySort', 'documentDate');
      sessionStorage.setItem('sort-documentDate', 'true');
      sessionStorage.setItem('sort-createdTime', 'false');
    } else {
      setPrimarySort('createdTime');
      setIsSortedDescendingCreatedTime(true);
      setIsSortedDescendingDocumentDate(false);

      sessionStorage.setItem('primarySort', 'createdTime');
      sessionStorage.setItem('sort-createdTime', 'true');
      sessionStorage.setItem('sort-documentDate', 'false');
    }

    setIsSortedDescendingNumber(false);
    setIsSortedDescendingCustomerName(false);
    setIsSortedDescendingGrandTotal(false);
    setIsSortedDescendingName(false);
    setIsSortedDescendingEmailReceiver(false);
    setIsSortedDescendingEmailTime(false);

    setSortDirection('DESC');

    sessionStorage.setItem('sort-number', 'false');
    sessionStorage.setItem('sort-customerName', 'false');
    sessionStorage.setItem('sort-customerPhone', 'false');
    sessionStorage.setItem('sort-vatTotal', 'false');
    sessionStorage.setItem('sort-grandTotal', 'false');
    sessionStorage.setItem('sort-name', 'false');
    sessionStorage.setItem('sort-emailReceiver', 'false');
    sessionStorage.setItem('sort-emailTime', 'false');
    sessionStorage.setItem('sortDirection', 'DESC');

  };

  const DayPickerStrings = {
    months: [
      t('documents.months01'),
      t('documents.months02'),
      t('documents.months03'),
      t('documents.months04'),
      t('documents.months05'),
      t('documents.months06'),
      t('documents.months07'),
      t('documents.months08'),
      t('documents.months09'),
      t('documents.months10'),
      t('documents.months11'),
      t('documents.months12'),
    ],

    shortMonths: [
      t('documents.shortMonths01'),
      t('documents.shortMonths02'),
      t('documents.shortMonths03'),
      t('documents.shortMonths04'),
      t('documents.shortMonths05'),
      t('documents.shortMonths06'),
      t('documents.shortMonths07'),
      t('documents.shortMonths08'),
      t('documents.shortMonths09'),
      t('documents.shortMonths10'),
      t('documents.shortMonths11'),
      t('documents.shortMonths12'),
    ],

    days: ['อาทิตย์', 'จันทร์', 'อังคาร', 'พุธ', 'พฤหัสบดี', 'ศุกร์', 'เสาร์'],

    shortDays: ['อา.', 'จ.', 'อ.', 'พ.', 'พฤ.', 'ศ.', 'ส.'],

    goToToday: 'ไปวันที่ปัจจุบัน',
    prevMonthAriaLabel: 'ไปเดือนที่แล้ว',
    nextMonthAriaLabel: 'ไปเดือนถัดไป',
    prevYearAriaLabel: 'ไปปีที่แล้ว',
    nextYearAriaLabel: 'ไปปีถัดไป',
    closeButtonAriaLabel: 'ปิด',
    monthPickerHeaderAriaLabel: '{0}, เลือกเพื่อเปลี่ยนปี',
    yearPickerHeaderAriaLabel: '{0}, เลือกเพื่อเปลี่ยนเดือน',

    isRequiredErrorMessage: t('documents.isRequiredErrorMessage'),

    invalidInputErrorMessage: 'รูปแบบวันที่ไม่ถูกต้อง',
  };

  function getDocumentTypeText(key) {
    if (key === 'ALL') return t('documents.allDocumentsType');
    if (key === 'RECEIPT-TAXINVOICE') return t('documents.receipt-taxinvoice');
    if (key === 'RECEIPT-TAXINVOICE-ABB') return t('documents.receipt-taxinvoice-abb');
    if (key === 'RECEIPT') return t('documents.receipt');
    if (key === 'INVOICE-TAXINVOICE') return t('documents.invoice-taxinvoice');
    if (key === 'DELIVERYORDER-TAXINVOICE') return t('documents.deliveryorder-taxinvoice');
    if (key === 'TAXINVOICE') return t('documents.taxinvoice');
    if (key === 'DEBIT-NOTE') return t('documents.debit-note');
    if (key === 'CREDIT-NOTE') return t('documents.credit-note');
    if (key === 'QUOTATION') return t('documents.quotation');
    if (key === 'INVOICE') return t('documents.invoice');
    if (key === 'BILLING-NOTE') return t('documents.billing-note');
    if (key === 'DELIVERYORDER') return t('documents.deliveryorder');

    if (key === 'created') return t('documents.createdDate');
    if (key === 'document') return t('documents.documentDate');
  }

  function getDateText(monthObject) {
    if (monthObject.type === 'dateRange') {
      if (i18n.language === 'th') {
        return onFormatDate(fromDate, 'BE') + ' - ' + onFormatDate(toDate, 'BE');
      }
      return onFormatDate(fromDate, 'CE') + ' - ' + onFormatDate(toDate, 'CE');
    }
    if (monthObject.text === 'เดือนนี้' || monthObject.text === 'This Month') return t('documents.thisMonth');

    let monthNames = [
      t('documents.months01'),
      t('documents.months02'),
      t('documents.months03'),
      t('documents.months04'),
      t('documents.months05'),
      t('documents.months06'),
      t('documents.months07'),
      t('documents.months08'),
      t('documents.months09'),
      t('documents.months10'),
      t('documents.months11'),
      t('documents.months12'),
    ];

    let _monthIndex = Number(monthObject.key.substring(4, 6)) - 1;
    let _year = Number(monthObject.key.substring(0, 4));
    if (i18n.language === 'th') {
      _year = _year + 543;
    }

    return monthNames[_monthIndex] + ' ' + _year;
  }

  const commandBarItems = [
    {
      key: documentType.key,
      text: getDocumentTypeText(documentType.key),
      cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
      iconProps: { iconName: 'Page' },
      disabled: !loadDataComplete || isProcessing || isProcessingSms,
      subMenuProps: {
        items: [
          {
            key: 'ALL',
            text: t('documents.allDocumentsType'),
            iconProps: { iconName: 'Page' },
            onClick: () => {
              console.log(t('documents.allDocumentsType'));
              setDocumentType({
                key: 'ALL',
                text: t('documents.allDocumentsType'),
              });
              sessionStorage.setItem('documentType-key', 'ALL');
              sessionStorage.setItem('documentType-text', t('documents.allDocumentsType'));

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
              setPreviousSearch('');
              setSearch('');

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalGrand(0);

              setCurrentPage(1);

              resetSort();
            },
          },
          {
            key: 'RECEIPT-TAXINVOICE',
            text: t('documents.receipt-taxinvoice'),
            iconProps: { iconName: 'Page' },
            onClick: () => {
              console.log(t('documents.receipt-taxinvoice'));
              setDocumentType({
                key: 'RECEIPT-TAXINVOICE',
                text: t('documents.receipt-taxinvoice'),
              });
              sessionStorage.setItem('documentType-key', 'RECEIPT-TAXINVOICE');
              sessionStorage.setItem('documentType-text', t('documents.receipt-taxinvoice'));

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
              setPreviousSearch('');
              setSearch('');

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalGrand(0);

              setCurrentPage(1);

              resetSort();
            },
          },
          {
            key: 'RECEIPT-TAXINVOICE-ABB',
            text: t('documents.receipt-taxinvoice-abb'),
            iconProps: { iconName: 'Page' },
            onClick: () => {
              console.log(t('documents.receipt-taxinvoice-abb'));
              setDocumentType({
                key: 'RECEIPT-TAXINVOICE-ABB',
                text: t('documents.receipt-taxinvoice-abb'),
              });
              sessionStorage.setItem('documentType-key', 'RECEIPT-TAXINVOICE-ABB');
              sessionStorage.setItem('documentType-text', t('documents.receipt-taxinvoice-abb'));

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
              setPreviousSearch('');
              setSearch('');

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalGrand(0);

              setCurrentPage(1);

              resetSort();
            },
          },
          {
            key: 'RECEIPT',
            text: t('documents.receipt'),
            iconProps: { iconName: 'Page' },
            onClick: () => {
              console.log(t('documents.receipt'));
              setDocumentType({
                key: 'RECEIPT',
                text: t('documents.receipt'),
              });
              sessionStorage.setItem('documentType-key', 'RECEIPT');
              sessionStorage.setItem('documentType-text', t('documents.receipt'));

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
              setPreviousSearch('');
              setSearch('');

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalGrand(0);

              setCurrentPage(1);

              resetSort();
            },
          },
          {
            key: 'INVOICE-TAXINVOICE',
            text: t('documents.invoice-taxinvoice'),
            iconProps: { iconName: 'Page' },
            onClick: () => {
              console.log(t('documents.invoice-taxinvoice'));
              setDocumentType({
                key: 'INVOICE-TAXINVOICE',
                text: t('documents.invoice-taxinvoice'),
              });
              sessionStorage.setItem('documentType-key', 'INVOICE-TAXINVOICE');
              sessionStorage.setItem('documentType-text', t('documents.invoice-taxinvoice'));

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
              setPreviousSearch('');
              setSearch('');

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalGrand(0);

              setCurrentPage(1);

              resetSort();
            },
          },
          {
            key: 'DELIVERYORDER-TAXINVOICE',
            text: t('documents.deliveryorder-taxinvoice'),
            iconProps: { iconName: 'Page' },
            onClick: () => {
              console.log(t('documents.deliveryorder-taxinvoice'));
              setDocumentType({
                key: 'DELIVERYORDER-TAXINVOICE',
                text: t('documents.deliveryorder-taxinvoice'),
              });
              sessionStorage.setItem('documentType-key', 'DELIVERYORDER-TAXINVOICE');
              sessionStorage.setItem('documentType-text', t('documents.deliveryorder-taxinvoice'));

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
              setPreviousSearch('');
              setSearch('');

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalGrand(0);

              setCurrentPage(1);

              resetSort();
            },
          },
          {
            key: 'TAXINVOICE',
            text: t('documents.taxinvoice'),
            iconProps: { iconName: 'Page' },
            onClick: () => {
              console.log(t('documents.taxinvoice'));
              setDocumentType({
                key: 'TAXINVOICE',
                text: t('documents.taxinvoice'),
              });
              sessionStorage.setItem('documentType-key', 'TAXINVOICE');
              sessionStorage.setItem('documentType-text', t('documents.taxinvoice'));

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
              setPreviousSearch('');
              setSearch('');

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalGrand(0);

              setCurrentPage(1);

              resetSort();
            },
          },
          {
            key: 'DEBIT-NOTE',
            text: t('documents.debit-note'),
            iconProps: { iconName: 'Page' },
            onClick: () => {
              console.log(t('documents.debit-note'));
              setDocumentType({
                key: 'DEBIT-NOTE',
                text: t('documents.debit-note'),
              });
              sessionStorage.setItem('documentType-key', 'DEBIT-NOTE');
              sessionStorage.setItem('documentType-text', t('documents.debit-note'));

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
              setPreviousSearch('');
              setSearch('');

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalGrand(0);

              setCurrentPage(1);

              resetSort();
            },
          },
          {
            key: 'CREDIT-NOTE',
            text: t('documents.credit-note'),
            iconProps: { iconName: 'Page' },
            onClick: () => {
              console.log(t('documents.credit-note'));
              setDocumentType({
                key: 'CREDIT-NOTE',
                text: t('documents.credit-note'),
              });
              sessionStorage.setItem('documentType-key', 'CREDIT-NOTE');
              sessionStorage.setItem('documentType-text', t('documents.credit-note'));

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
              setPreviousSearch('');
              setSearch('');

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalGrand(0);

              setCurrentPage(1);

              resetSort();
            },
          },
          {
            key: 'QUOTATION',
            text: t('documents.quotation'),
            iconProps: { iconName: 'Page' },
            onClick: () => {
              console.log(t('documents.quotation'));
              setDocumentType({
                key: 'QUOTATION',
                text: t('documents.quotation'),
              });
              sessionStorage.setItem('documentType-key', 'QUOTATION');
              sessionStorage.setItem('documentType-text', t('documents.quotation'));

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
              setPreviousSearch('');
              setSearch('');

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalGrand(0);

              setCurrentPage(1);

              resetSort();
            },
          },
          {
            key: 'INVOICE',
            text: t('documents.invoice'),
            iconProps: { iconName: 'Page' },
            onClick: () => {
              console.log(t('documents.invoice'));
              setDocumentType({
                key: 'INVOICE',
                text: t('documents.invoice'),
              });
              sessionStorage.setItem('documentType-key', 'INVOICE');
              sessionStorage.setItem('documentType-text', t('documents.invoice'));

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
              setPreviousSearch('');
              setSearch('');

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalGrand(0);

              setCurrentPage(1);

              resetSort();
            },
          },
          {
            key: 'BILLING-NOTE',
            text: t('documents.billing-note'),
            iconProps: { iconName: 'Page' },
            onClick: () => {
              console.log(t('documents.billing-note'));
              setDocumentType({
                key: 'BILLING-NOTE',
                text: t('documents.billing-note'),
              });
              sessionStorage.setItem('documentType-key', 'BILLING-NOTE');
              sessionStorage.setItem('documentType-text', t('documents.billing-note'));

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
              setPreviousSearch('');
              setSearch('');

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalGrand(0);

              setCurrentPage(1);

              resetSort();
            },
          },
          {
            key: 'DELIVERYORDER',
            text: t('documents.deliveryorder'),
            iconProps: { iconName: 'Page' },
            onClick: () => {
              console.log(t('documents.deliveryorder'));
              setDocumentType({
                key: 'DELIVERYORDER',
                text: t('documents.deliveryorder'),
              });
              sessionStorage.setItem('documentType-key', 'DELIVERYORDER');
              sessionStorage.setItem('documentType-text', t('documents.deliveryorder'));

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
              setPreviousSearch('');
              setSearch('');

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalGrand(0);

              setCurrentPage(1);

              resetSort();
            },
          },
        ],
      },
    },
    {
      key: dateType.key,
      text: getDocumentTypeText(dateType.key),
      cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
      iconProps: { iconName: 'CalendarSettings' },
      disabled: !loadDataComplete || isProcessing || isProcessingSms,
      subMenuProps: {
        items: [
          {
            key: 'created',
            text: t('documents.createdDate'),
            iconProps: { iconName: 'CalendarSettings' },
            onClick: () => {
              console.log(t('documents.createdDate'));
              setDateType({ key: 'created', text: t('documents.createdDate') });
              sessionStorage.setItem('dateType-key', 'created');
              sessionStorage.setItem('dateType-text', t('documents.createdDate'));

              sessionStorage.setItem('sortDirection', 'DESC');

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
              setPreviousSearch('');
              setSearch('');

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalGrand(0);

              setCurrentPage(1);

              setPrimarySort('createdTime');
              setIsSortedDescendingCreatedTime(true);

              setIsSortedDescendingNumber(false);
              setIsSortedDescendingDocumentDate(false);
              setIsSortedDescendingCustomerName(false);
              setIsSortedDescendingGrandTotal(false);
              setIsSortedDescendingName(false);
              setIsSortedDescendingEmailReceiver(false);
              setIsSortedDescendingEmailTime(false);

              setSortDirection('DESC');

            },
          },
          {
            key: 'document',
            text: t('documents.documentDate'),
            iconProps: { iconName: 'CalendarSettings' },
            onClick: () => {
              console.log(t('documents.documentDate'));
              setDateType({ key: 'document', text: t('documents.documentDate') });
              sessionStorage.setItem('dateType-key', 'document');
              sessionStorage.setItem('dateType-text', t('documents.documentDate'));

              sessionStorage.setItem('sortDirection', 'DESC');

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
              setPreviousSearch('');
              setSearch('');

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalGrand(0);

              setCurrentPage(1);

              setPrimarySort('documentDate');
              setIsSortedDescendingDocumentDate(true);

              setIsSortedDescendingCreatedTime(false);
              setIsSortedDescendingNumber(false);
              setIsSortedDescendingCustomerName(false);
              setIsSortedDescendingGrandTotal(false);
              setIsSortedDescendingName(false);
              setIsSortedDescendingEmailReceiver(false);
              setIsSortedDescendingEmailTime(false);

              setSortDirection('DESC');

            },
          },
        ],
      },
    },
    {
      key: queryMonth.key,
      text: getDateText(queryMonth),
      iconProps: { iconName: 'Calendar' },
      disabled: !loadDataComplete || isProcessing || isProcessingSms,
      subMenuProps: {
        items: [
          {
            key: 'queryMonth',
          },
        ],
        onRenderMenuList: () => {
          return (
            <div style={{ width: 220 }}>
              <Stack styles={{ root: { padding: '12px 12px 0px 12px' } }}>
                <Checkbox
                  label={t('sellSummary.timeRange')}
                  checked={timeRangeDate}
                  onChange={(e) => {
                    setTimeRangeDate((prev) => !prev);
                  }}
                />
              </Stack>
              {timeRangeDate ? (
                <Stack>
                  <Stack horizontal styles={{ root: { height: 42, paddingTop: 10 } }}>
                    <Stack styles={{ root: { width: '20%', justifyContent: 'center', alignItems: 'center' } }}>
                      <Text>{t('sellSummary.fromRange')}</Text>
                    </Stack>
                    <Stack styles={{ root: { width: '80%', justifyContent: 'center' /* alignItems: 'center' */ } }}>
                      <DatePicker
                        styles={{ root: { width: '90%' } }}
                        placeholder={t('deliveryOrderTaxInvoice.pleaseSelect')}
                        strings={DayPickerStrings}
                        value={fromDate}
                        maxDate={toDate}
                        formatDate={onFormatDate}
                        onSelectDate={(_date) => {
                          setFromDate(_date);

                          setTimeRangeError(false);
                          if (Math.ceil(Math.abs(_date - toDate) / (1000 * 60 * 60 * 24)) > 30) setTimeRangeError(true);
                        }}
                      />
                    </Stack>
                  </Stack>
                  <Stack horizontal styles={{ root: { height: 42, paddingTop: 10 } }}>
                    <Stack styles={{ root: { width: '20%', justifyContent: 'center', alignItems: 'center' } }}>
                      <Text>{t('sellSummary.toRange')}</Text>
                    </Stack>
                    <Stack styles={{ root: { width: '80%' /* alignItems: 'center' */ } }}>
                      <DatePicker
                        styles={{ root: { width: '90%' } }}
                        placeholder={t('deliveryOrderTaxInvoice.pleaseSelect')}
                        strings={DayPickerStrings}
                        value={toDate}
                        minDate={fromDate}
                        formatDate={onFormatDate}
                        onSelectDate={(_date) => {
                          setToDate(_date);
                          
                          setTimeRangeError(false);
                          if (Math.ceil(Math.abs(_date - fromDate) / (1000 * 60 * 60 * 24)) > 30) setTimeRangeError(true);
                        }}
                      />
                    </Stack>
                  </Stack>
                  {timeRangeError ? (
                    <Stack styles={{ root: { padding: '12px 0px 0px 12px' } }}>
                      <Text styles={{ root: { fontSize: 12, color: '#A4262C' } }}>{t('sellSummary.timeRangeError')}</Text>
                    </Stack>
                  ) : null}
                  <Stack horizontal horizontalAlign="end" styles={{ root: { width: '92%', height: 52, padding: '10px 0px 10px 0px' } }}>
                    <PrimaryButton
                      text={t('sellSummary.search')}
                      onClick={() => {
                        if (!timeRangeError && fromDate && toDate) {
                          setQueryMonth({
                            type: 'dateRange',
                            key: formatDate(fromDate) + ':' + formatDate(toDate),
                            text: formatDate(fromDate) + ' - ' + formatDate(toDate),
                          });

                          sessionStorage.setItem("queryMonth-type", "dateRange");
                          sessionStorage.setItem("queryMonth-key", formatDate(fromDate) + ":" + formatDate(toDate));
                          sessionStorage.setItem("queryMonth-text", formatDate(fromDate) + " - " + formatDate(toDate));

                          setFrom(getFromDMY(fromDate.getDate(), fromDate.getMonth(), fromDate.getFullYear()));
                          setTo(getToDMY(toDate.getDate(), toDate.getMonth(), toDate.getFullYear()));

                          sessionStorage.setItem("from", getFromDMY(fromDate.getDate(), fromDate.getMonth(), fromDate.getFullYear()));
                          sessionStorage.setItem("to", getToDMY(toDate.getDate(), toDate.getMonth(), toDate.getFullYear()));

                          sessionStorage.setItem("fromDate", fromDate.toISOString());
                          sessionStorage.setItem("toDate", toDate.toISOString());

                          setLoadDataComplete(false);
                          setItems([]);
                          setGetNewDocuments(true);
                          setPreviousSearch('');
                          setSearch('');

                          setTotalDocuments(0);
                          setTotalVat(0);
                          setTotalGrand(0);

                          setCurrentPage(1);

                          resetSort();
                        }
                      }}
                      style={{ width: '125px', letterSpacing: '1px' }}
                    />
                  </Stack>
                </Stack>
              ) : (
                <Calendar
                  onSelectDate={(_date) => {
                    let monthNames = [
                      t('documents.months01'),
                      t('documents.months02'),
                      t('documents.months03'),
                      t('documents.months04'),
                      t('documents.months05'),
                      t('documents.months06'),
                      t('documents.months07'),
                      t('documents.months08'),
                      t('documents.months09'),
                      t('documents.months10'),
                      t('documents.months11'),
                      t('documents.months12'),
                    ];

                    setDate(_date);

                    sessionStorage.setItem('date', _date.toISOString());

                    let adjustDate = _date;

                    let year = adjustDate.getFullYear() + 543;

                    setQueryMonth({
                      key: adjustDate.getFullYear() + '' + String(adjustDate.getMonth() + 1).padStart(2, '0'),
                      text: monthNames[adjustDate.getMonth()] + ' ' + year,
                    });

                    sessionStorage.setItem("queryMonth-type", "");
                    sessionStorage.setItem('queryMonth-key', adjustDate.getFullYear() + '' + String(adjustDate.getMonth() + 1).padStart(2, '0'));
                    sessionStorage.setItem('queryMonth-text', monthNames[adjustDate.getMonth()] + ' ' + year);

                    setFrom(getFrom(adjustDate.getMonth(), adjustDate.getFullYear()));
                    setTo(getTo(adjustDate.getMonth(), adjustDate.getFullYear()));

                    sessionStorage.setItem('from', getFrom(adjustDate.getMonth(), adjustDate.getFullYear()));
                    sessionStorage.setItem('to', getTo(adjustDate.getMonth(), adjustDate.getFullYear()));

                    setLoadDataComplete(false);
                    setItems([]);
                    setGetNewDocuments(true);
                    setPreviousSearch('');
                    setSearch('');

                    setTotalDocuments(0);
                    setTotalVat(0);
                    setTotalGrand(0);

                    setCurrentPage(1);

                    resetSort();
                  }}
                  isMonthPickerVisible={true}
                  highlightSelectedMonth={true}
                  isDayPickerVisible={false}
                  dateRangeType={DateRangeType.Day}
                  autoNavigateOnSelection={false}
                  showGoToToday={false}
                  value={date}
                  strings={DayPickerStrings}
                  showSixWeeksByDefault={true}
                />
              )}
            </div>
          );
        },
      },
    },
    {
      key: pageSize,
      text: pageSize + ' ' + t('documents.documentPerPage'),
      cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
      iconProps: { iconName: 'NumberedList' },
      disabled: !loadDataComplete || isProcessing || isProcessingSms,
      subMenuProps: {
        items: [
          {
            key: 10,
            text: '10 ' + t('documents.documentPerPage'),
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('10 ' + t('documents.documentPerPage'));
              setPageSize(10);
              sessionStorage.setItem('pageSize', 10);

              setCurrentPage(1);

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
            },
          },
          {
            key: 20,
            text: '20 ' + t('documents.documentPerPage'),
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('20 ' + t('documents.documentPerPage'));
              setPageSize(20);
              sessionStorage.setItem('pageSize', 20);

              setCurrentPage(1);

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
            },
          },
          {
            key: 30,
            text: '30 ' + t('documents.documentPerPage'),
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('30 ' + t('documents.documentPerPage'));
              setPageSize(30);
              sessionStorage.setItem('pageSize', 30);

              setCurrentPage(1);

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
            },
          },
          {
            key: 40,
            text: '40 ' + t('documents.documentPerPage'),
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('40 ' + t('documents.documentPerPage'));
              setPageSize(40);
              sessionStorage.setItem('pageSize', 40);

              setCurrentPage(1);

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
            },
          },
          {
            key: 50,
            text: '50 ' + t('documents.documentPerPage'),
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('50 ' + t('documents.documentPerPage'));
              setPageSize(50);
              sessionStorage.setItem('pageSize', 50);

              setCurrentPage(1);

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
            },
          },
        ],
      },
    },
    {
      key: currentPage,
      text: t('documents.page') + ' ' + currentPage,
      iconProps: { iconName: 'Page' },
      disabled: !loadDataComplete || isProcessing || isProcessingSms,
      subMenuProps: {
        items: pageDropdown,
      },
    },

    {
      key: 'refresh',
      text: t('documents.refresh'),
      iconProps: { iconName: 'Refresh' },
      disabled: !loadDataComplete || isProcessing || isProcessingSms,
      onClick: () => {
        console.log('Refresh');

        setLoadDataComplete(false);
        setItems([]);
        setGetNewDocuments(true);
        setPreviousSearch('');
        setSearch('');

        setTotalDocuments(0);
        setTotalVat(0);
        setTotalGrand(0);

        setCurrentPage(1);

        resetSort();
      },
    },

    {
      key: 'batch-send-email',
      text: t('documents.sendEmail'),
      iconProps: { iconName: 'MailForward' },
      disabled: !loadDataComplete || isProcessing || !selectedItems.length || isProcessingSms,
      onClick: async () => {
        console.log('Send batch email.');
        console.log('Selected items:', selectedItems);

        if (isEmailSetting) {
          if (selectedItems.length > 0) {
            setCopyEmailList([companyEmail]);
            setCopyEmail(companyEmail);
            setDialogBatchSendEmailConfirm((prev) => ({
              ...prev,
              isClick: true,
              header: t('documents.sendEmail'),
              message: `${t('documents.confirmSendEmail')} ${selectedItems.length} ${t('documents.document')}?`,
            }));
          }
        } else {
          history.push({
            pathname: '/settings/emails',
            state: {},
          });
        }
      },
    },
    
    ...(enableSms
      ? [
          {
            key: 'batch-send-sms',
            text: t('documents.sendBatchSms'),
            iconProps: { iconName: 'Message' },
            disabled: !loadDataComplete || isProcessing || !selectedItems.length || isProcessingSms,
            onClick: async () => {
              if (selectedItems.length > 0) {
                setDialogConfirm((prev) => ({
                  ...prev,
                  isClick: true,
                  header: t('documents.sendBatchSms'),
                  message: <p>{`${t('documents.confirmSendSms')} ${selectedItems.length} ${t('documents.document')}?`}</p>,
                  onConfirm: async () => {
                    setDialogConfirm((prev) => ({
                      ...prev,
                      isClick: false,
                    }));

                    setIsProcessingSms(true);

                    await batchSendSms();
                  },
                }));
              }
            },
          },
        ]
      : []),
    /*   
    {
      key: 'batch-receipts',
      text: t('documents.create') + ' ' + t('documents.combinedReceipt'),
      iconProps: { iconName: 'PageAdd' },
      disabled: !loadDataComplete || isProcessing || !selectedItems.length || isProcessingSms,
      onClick: async () => {
        console.log('Selected items:', selectedItems);

        if (
          selectedItems.every(
            (selectedItem) =>
              selectedItem.type === 'INVOICE-TAXINVOICE' ||
              selectedItem.type === 'DELIVERYORDER-TAXINVOICE' ||
              selectedItem.type === 'TAXINVOICE' ||
              selectedItem.type === 'QUOTATION' ||
              selectedItem.type === 'INVOICE' ||
              selectedItem.type === 'BILLING-NOTE' ||
              selectedItem.type === 'DELIVERYORDER'
          )
        ) {
          let _items = [];
          let _amountTotal = 0;
          let _vatTotal = 0;
          let _grandTotal = 0;

          let _vatPriceTotal = 0;
          let _zeroVatPriceTotal = 0;
          let _nonVatPriceTotal = 0;

          selectedItems.forEach((_item, index) => {
            let _percentVat = '';
            let _percentVatText = '';

            if (_item.data.items.some((itemInInvoice) => itemInInvoice.percentVat === percentVat)) {
              _percentVat = percentVat;
              _percentVatText = percentVatText;
            } else if (_item.data.items.every((itemInInvoice) => itemInInvoice.percentVat === zeroVat)) {
              _percentVat = zeroVat;
              _percentVatText = zeroVatText;
            } else if (_item.data.items.every((itemInInvoice) => itemInInvoice.percentVat === nonVat)) {
              _percentVat = nonVat;
              _percentVatText = nonVatText;
            }

            _items.push({
              number: String(index + 1),
              description: _item.data.number,
              quantity: 1, // 1
              unitCode: '',
              unitName: '-',
              price: _item.data.amountTotal, // amountTotal
              discount: 0,
              percentVat: _percentVat,
              percentVatText: _percentVatText,
              total: _item.data.amountTotal,

              date: _item.data.date,
              dateBE: _item.data.dateBE,
              dateCE: _item.data.dateCE,
              vatTotal: _item.data.vatTotal,
              grandTotal: _item.data.grandTotal,
            });

            _vatPriceTotal = _vatPriceTotal + _item.data.vatPriceTotal;
            _zeroVatPriceTotal = _zeroVatPriceTotal + _item.data.zeroVatPriceTotal;
            _nonVatPriceTotal = _nonVatPriceTotal + _item.data.nonVatPriceTotal;

            _amountTotal = _amountTotal + _item.data.amountTotal;
            _vatTotal = _vatTotal + _item.data.vatTotal;
            _grandTotal = _grandTotal + _item.data.grandTotal;
          });

          history.push({
            pathname: '/etax/documents/batch-receipts',
            state: {
              command: 'reCreate',
              company: selectedItems[0].data.company,
              customer: selectedItems[0].data.customer,
              number: '',
              date: new Date().toISOString(),
              dateBE: onFormatDate(new Date(), 'BE'),
              dateCE: onFormatDate(new Date(), 'CE'),
              discount: 0,
              items: _items,
              amountTotal: _amountTotal,
              vatTotal: _vatTotal,
              grandTotal: _grandTotal,
              vatPriceTotal: _vatPriceTotal,
              zeroVatPriceTotal: _zeroVatPriceTotal,
              nonVatPriceTotal: _nonVatPriceTotal,
              note: selectedItems[0].data.note,
              createdBy: selectedItems[0].data.createdBy,
              receivedBy: selectedItems[0].data.receivedBy,
              percentVat: selectedItems[0].data.percentVat,
              includeVat: selectedItems[0].data.includeVat,
              reIssue: false,
              refer: {},
              reference: selectedItems[0].data.reference,
              backUrl: '/etax/documents/all',
            },
          });
        } else {
          alert(t('documents.combinedReceiptAlert'));
        }
      },
    },
 */
    {
      key: 'download',
      text: t('documents.download'),
      cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
      iconProps: { iconName: 'Download' },
      // disabled: !loadDataComplete || isProcessing || selectedItems.length === 0 || isProcessingSms,
      subMenuProps: {
        items: [
          {
            key: 'download-pdf-zip',
            text: t('documents.download') + ' PDF',
            iconProps: { iconName: 'ZipFolder' },
            disabled: !loadDataComplete || isProcessing || selectedItems.length === 0 || isProcessingSms,
            onClick: () => {
              console.log('Download PDF');
              selectedDownloadZip('pdf');
            },
          },
          {
            key: 'download-xml-zip',
            text: t('documents.download') + ' XML',
            iconProps: { iconName: 'ZipFolder' },
            disabled: !loadDataComplete || isProcessing || selectedItems.length === 0 || isProcessingSms,
            onClick: () => {
              console.log('Download XML');
              selectedDownloadZip('xml');
            },
          },
          {
            key: 'download-csv',
            text: t('documents.download') + ' CSV',
            iconProps: { iconName: 'ExcelDocument' },
            onClick: () => {
              console.log('Download CSV');
              handleDownloadCSV();
            },
          },
        ],
      },
    },
    {
      key: 'documentStatus',
      text: t('documents.docStatus'),
      cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
      iconProps: { iconName: 'Page' },
      // disabled: !loadDataComplete || isProcessing || selectedItems.length === 0 || isProcessingSms,
      subMenuProps: {
        items: [
          {
            key: 'allStatus',
            text: t('documents.all'),
            iconProps: { iconName: 'DocumentApproval' },
            disabled: !loadDataComplete || isProcessing || isProcessingSms,
            onClick: () => {
              console.log('All Status');
      
              setDeletedDocumentsOnly(false)
              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
              setPreviousSearch('');
      
              setCurrentPage(1);
      
              setTotalDocuments(0);
              setTotalVat(0);
              setTotalGrand(0);
      
              resetSort();
            },
          },
          {
            key: 'deleted',
            text: t('documents.voided'),
            iconProps: { iconName: 'Trash' },
            disabled: !loadDataComplete || isProcessing || isProcessingSms,
            onClick: () => {
              console.log('Trash');
      
              setDeletedDocumentsOnly(true)
              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
              setPreviousSearch('');
      
              setCurrentPage(1);
      
              setTotalDocuments(0);
              setTotalVat(0);
              setTotalGrand(0);
      
              resetSort();
            },
          },
        ],
      },
    },

  ];

  const toThaiDateString = (isoDateTime) => {
    let date = '';
    date = new Date(isoDateTime);
    let year = date.getFullYear() + 543;
    let month = (date.getMonth() + 1).toString().padStart(2, '0');
    let numOfDay = date.getDate().toString().padStart(2, '0');

    let hour = date.getHours().toString().padStart(2, '0');
    let minutes = date.getMinutes().toString().padStart(2, '0');
    let second = date.getSeconds().toString().padStart(2, '0');

    return `${numOfDay}/${month}/${year} ${hour}:${minutes}:${second} น.`;
  };

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };
/* 
  const UploadPDF = (fileName, file, email) => {
    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then(async (tokenResponse) => {
        let formData = new FormData();

        formData.append('file', file);
        formData.append('fileName', fileName);
        formData.append('to', email);
        formData.append('message', '');

        await axios
          .post(API_URL + '/sents', formData, {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              'cid': companyId,
              'Content-Type': 'multipart/form-data',
            },
            timeout: 60000,
          })
          .then(
            (response) => {
              console.log(true, response);
            },
            (error) => {
              console.log('Error sending document response: ', error);
            }
          );
      })
      .catch((error) => {
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
  };
 */
  const sendEmail = (fileName, fileId, email, copyEmail) => {
    if (email !== '' && email !== null && email !== undefined) {
      if ((isClickCopyEmail && copyEmail !== '' && copyEmail !== null && copyEmail !== undefined) || !isClickCopyEmail) {
        setIsClickEmail(false);

        setIsClickCopyEmail(false);

        instance
          .acquireTokenSilent({ ...silentRequest, account: account })
          .then((tokenResponse) => {
            axios
              .post(
                API_URL + '/emails/send',
                {
                  fileId: fileId,
                  email: email,
                  copyEmail: copyEmail,
                },
                {
                  headers: {
                    Authorization: 'Bearer ' + tokenResponse.accessToken,
                    'cid': companyId,
                    'Content-Type': 'application/json',
                },
                }
              )
              .then(
                (response) => {
                  console.log("response /emails/send", response);
                  setIsClickEmail(false);
                  setShowEmailSuccess(true);
                  window.scrollTo(0, 0);
                },
                (error) => {
                  console.log(error);
                  setIsClickEmail(false);
                  setShowEmailError(true);
                  window.scrollTo(0, 0);
                }
              );
          })
          .catch((error) => {
            //Acquire token silent failure, and send an interactive request
            console.log(error);
            instance.acquireTokenRedirect(silentRequest);
          });

/* 
        let file = '';

        instance
          .acquireTokenSilent({ ...silentRequest, account: account })
          .then((tokenResponse) => {
            axios
              .get(API_URL + '/etax/files/' + fileId, {
                headers: {
                  Authorization: 'Bearer ' + tokenResponse.accessToken,
                },
                responseType: 'blob',
              })
              .then(
                (response) => {
                  console.log(response);

                  file = response.data;

                  let allEmails = email.split(';');

                  allEmails.forEach((email) => {
                    console.log(email, true);

                    instance
                      .acquireTokenSilent({
                        ...silentRequest,
                        account: account,
                      })
                      .then(async (tokenResponse) => {
                        await axios
                          .get(API_URL + '/sents/check?email=' + email, {
                            headers: {
                              Authorization: 'Bearer ' + tokenResponse.accessToken,
                              'cid': companyId
                            },
                          })
                          .then(
                            (response) => {
                              console.log(response);
                              if (response.data.isExist) {
                                UploadPDF(fileName, file, email);
                              }
                            },
                            (error) => {
                              console.log(error);
                            }
                          );
                      });
                  });
                },
                (error) => {
                  console.log(error);
                }
              );
          })
          .catch((error) => {
            //Acquire token silent failure, and send an interactive request
            console.log(error);
            instance.acquireTokenRedirect(silentRequest);
          });
           */
      }
    }
  };

  const sendSms = (fileId, mobile) => {
    const re = /^0[1-9]{1}[0-9]{8}/;

    if (mobile !== '' && mobile !== null && mobile !== undefined && re.test(String(mobile))) {
      setIsClickSms(false);
      setErrorMessageSms('');

      instance
        .acquireTokenSilent({ ...silentRequest, account: account })
        .then((tokenResponse) => {
          axios
            .post(
              API_URL + '/etax/sms/',
              {
                fileId: fileId,
                mobile: mobile,
              },
              {
                headers: {
                  Authorization: 'Bearer ' + tokenResponse.accessToken,
                  'cid': companyId,
                  'Content-Type': 'application/json',
              },
              }
            )
            .then(
              (response) => {
                console.log(response);
                setIsClickSms(false);
                setShowSmsSuccess(true);
                window.scrollTo(0, 0);
              },
              (error) => {
                console.log(error);
                setIsClickSms(false);
                setShowSmsError(true);
                window.scrollTo(0, 0);
              }
            );
        })
        .catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);
          instance.acquireTokenRedirect(silentRequest);
        });
    } else {
      setErrorMessageSms(t('documents.mobileNumberAlert'));
    }
  };

  const getTotalProcessingDocuments = () => {
    console.log('Get total processing document...');
    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .get(API_URL + '/etax/jobs/processing/total', {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              'cid': companyId,
            },
          })
          .then(
            (response) => {
              console.log('Total processing jobs response: ', response);

              if (response.data[0] > 0) {
                console.log('Total processing jobs: ', response.data[0]);

                setTotalProcessingDocuments(response.data[0] * 1);
              } else {
                setTotalProcessingDocuments(0);
              }
            },
            (error) => {
              console.log(error);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
  };

  async function getPNGorZip() {
    setTimeout(async () => {
      try {
        const zip = new JSZip();

        console.log('download...');
        const pdfElements = printRef.current.getElementsByClassName('react-pdf__Page__canvas');

        if (pdfElements) {
          if (pdfElements.length > 1) {
            const promises = [];

            async function getCanvasBlobToPNG(elements, index) {
              const canvas = await html2canvas(elements[index]);

              return new Promise(function (resolve, reject) {
                canvas.toBlob(function (blob) {
                  resolve(zip.file(`${index + 1}.png`, blob));
                });
              });
            }

            for (let i = 0; i < pdfElements.length; i++) {
              promises.push(getCanvasBlobToPNG(pdfElements, i));
            }

            Promise.all(promises).then(async () => {
              const blob = await zip.generateAsync({
                type: 'blob',
              });

              const link = document.createElement('a');
              link.setAttribute('href', window.URL.createObjectURL(blob));
              link.setAttribute('download', fileName + '.zip');
              link.style.display = 'none';
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              setUrl('');
            });
          } else {
            const canvas = await html2canvas(pdfElements[0]);

            const data = canvas.toDataURL('image/png');

            const link = document.createElement('a');

            if (typeof link.download === 'string') {
              link.href = data;
              link.download = fileName + '.png';

              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              setUrl('');
            } else {
              window.open(data);
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    }, 1000);
  }

  const batchSendEmail = async () => {
    console.log('Call batchSendEmail function...');

    let _items = selection.getItems();

    for (let i = 0; i < selectedItems.length; i++) {
      console.log('batchSendEmail: ', selectedItems[i]);

      for (let j = 0; j < _items.length; j++) {
        if (_items[j].key === selectedItems[i].key) {
          console.log('item key: ', _items[j].key);

          if (_items[j].customerEmail) {
            _items[j].emailTime = 'processing';

            console.log('items: ', _items);
            setItems([..._items]);

            console.log('pdfFileName: ', _items[j].pdfFileName);
            console.log('key: ', _items[j].key);
            console.log('customerEmail: ', _items[j].customerEmail);

            await instance
              .acquireTokenSilent({ ...silentRequest, account: account })
              .then(async (tokenResponse) => {
                await axios
                  .post(
                    API_URL + '/emails/send',
                    {
                      fileId: _items[j].key,
                      email: _items[j].customerEmail,
                      copyEmail: isClickCopyEmail ? copyEmail : '',
                    },
                    {
                      headers: {
                        Authorization: 'Bearer ' + tokenResponse.accessToken,
                        'cid': companyId,
                        'Content-Type': 'application/json',
                      },
                    }
                  )
                  .then(
                    (response) => {
                      console.log(response);

                      if (response?.status === 200) {
                        _items[j].emailTime = 'successful';
                      } else {
                        _items[j].emailTime = 'fail';
                      }

                      setItems([..._items]);
                    },
                    (error) => {
                      console.log(error);

                      _items[j].emailTime = 'fail';
                    }
                  );
              })
              .catch((error) => {
                //Acquire token silent failure, and send an interactive request
                console.log(error);
                instance.acquireTokenRedirect(silentRequest);
              });
/* 
            let file = '';

            instance
              .acquireTokenSilent({ ...silentRequest, account: account })
              .then((tokenResponse) => {
                axios
                  .get(API_URL + '/etax/files/' + _items[j].key, {
                    headers: {
                      Authorization: 'Bearer ' + tokenResponse.accessToken,
                      'cid': companyId
                    },
                    responseType: 'blob',
                  })
                  .then(
                    (response) => {
                      console.log(response);

                      file = response.data;

                      let allEmails = _items[j].customerEmail.split(';');

                      allEmails.forEach((email) => {
                        console.log(email, true);

                        instance
                          .acquireTokenSilent({
                            ...silentRequest,
                            account: account,
                          })
                          .then(async (tokenResponse) => {
                            await axios
                              .get(API_URL + '/sents/check?email=' + email, {
                                headers: {
                                  Authorization: 'Bearer ' + tokenResponse.accessToken,
                                },
                              })
                              .then(
                                (response) => {
                                  console.log(response);
                                  if (response.data.isExist) {
                                    UploadPDF(_items[j].pdfFileName, file, email);
                                  }
                                },
                                (error) => {
                                  console.log(error);
                                }
                              );
                          });
                      });
                    },
                    (error) => {
                      console.log(error);
                    }
                  );
              })
              .catch((error) => {
                //Acquire token silent failure, and send an interactive request
                console.log(error);
                instance.acquireTokenRedirect(silentRequest);
              });
               */
          } else {
            _items[j].emailTime = 'email not found';
          }

          console.log('item: ', _items[j]);
        }
      }
    }

    setIsProcessing(false);
  };

  const batchSendSms = async () => {
    let _items = selection.getItems();

    for (let i = 0; i < selectedItems.length; i++) {
      for (let j = 0; j < _items.length; j++) {
        if (_items[j].key === selectedItems[i].key) {
          console.log('item key: ', _items[j].key);

          if (_items[j].customerPhone) {
            _items[j].smsTime = 'processing';

            setItems([..._items]);

            await instance
              .acquireTokenSilent({ ...silentRequest, account: account })
              .then(async (tokenResponse) => {
                await axios
                  .post(
                    API_URL + '/etax/sms/',
                    {
                      fileId: _items[j].key,
                      mobile: _items[j].customerPhone,
                    },
                    {
                      headers: {
                        Authorization: 'Bearer ' + tokenResponse.accessToken,
                        'cid': companyId,
                        'Content-Type': 'application/json',
                      },
                    }
                  )
                  .then(
                    (response) => {
                      console.log(response);

                      if (response?.status === 200) {
                        _items[j].smsTime = 'successful';
                      } else {
                        _items[j].smsTime = 'fail';
                      }

                      setItems([..._items]);
                    },
                    (error) => {
                      console.log(error);

                      _items[j].smsTime = 'fail';
                    }
                  );
              })
              .catch((error) => {
                //Acquire token silent failure, and send an interactive request
                console.log(error);
                instance.acquireTokenRedirect(silentRequest);
              });
          } else {
            _items[j].smsTime = 'Phone Number not found';
          }

          console.log('item: ', _items[j]);
        }
      }
    }

    setIsProcessingSms(false);
  };

  useEffect(() => {
    console.log('userEffect Call!');

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        console.log(tokenResponse);

        axios
          .get(API_URL + '/etax/companies', {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
            },
          })
          .then(
            (response) => {
              if (response.data[0]) {
                if (companyId && response.data.find(_company => _company.id === companyId)) {
                  const _company = response.data.find(_company => _company.id === companyId)
                
                  setCompanyEmail(_company.email);
                } else {
                  setCompanyEmail(response.data[0].email);
                }
              }
            },
            (error) => {
              console.log(error);
            }
          );

        //Check test certificate
        axios
        //   .get(API_URL + '/companies-details/' + companyId, {
          .get(API_URL + '/companies-details', {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              'cid': companyId
            },
          })
          .then(
            (responseCompanyDetail) => {
              if (responseCompanyDetail.data) {
                console.log('responseCompanyDetail data: ', responseCompanyDetail.data);

                if (responseCompanyDetail.data.billings?.enableTopup) {
                    setEnableTopup(responseCompanyDetail.data.billings.enableTopup);
                }

                if (responseCompanyDetail.data.settings?.enableSms) {
                    setEnableSms(responseCompanyDetail.data.settings.enableSms);
                }

                if (responseCompanyDetail.data.settings?.digitalSign) {
                    setDigitalSign(responseCompanyDetail.data.settings.digitalSign);
                }

                if (responseCompanyDetail.data.settings?.etax?.defaultCertificateId) {
                    
                    if (responseCompanyDetail.data.settings?.digitalSign) {
                      axios.get(API_URL + '/certificates', {
                        headers: {
                          Authorization: 'Bearer ' + tokenResponse.accessToken,
                          'cid': companyId
                        },
                      })
                      .then(
                        (responseCertificate) => {
                          console.log("responseCertificate", responseCertificate)

                          if (responseCertificate.data) {
                            const currentCertificate = responseCertificate.data.find(_certificate => _certificate.id === responseCompanyDetail.data.settings?.etax?.defaultCertificateId)
                          
                            if (currentCertificate) {
                              if (dateDifference(new Date(), currentCertificate?.validity?.notAfter) < Number(CERTIFICATE_EXPIRE_NOTIFICATION_PERIOD) && dateDifference(new Date(), currentCertificate?.validity?.notAfter) > 0) {
                                setShowCertificatePendingExpired(true)
                              } else if (dateDifference(new Date(), currentCertificate?.validity?.notAfter) <= 0) {
                                setShowCertificateExpired(true)
                              }

                              setCertificateExpiredDate(onFormatDate(new Date(currentCertificate?.validity?.notAfter), "BE"))
                            }
                          }
                        },
                        (error) => {
                          console.log(error);
                        }
                      );

/* 
                        axios
                        .get(API_URL + '/certificates/' + responseCompanyDetail.data.settings?.etax?.defaultCertificateId, {
                            headers: {
                                Authorization: 'Bearer ' + tokenResponse.accessToken,
                                cid: companyId
                            },
                        })
                        .then(
                            (responseCert) => {
                            if (responseCert.data) {
                                let certName = responseCert.data.certificateName;

                                console.log('Certificate name: ', responseCert.data.certificateName);

                                if ((certName.search('Test Only') > -1) || !certName) {
                                    setShowCertificateSetup(true);
                                } else {
                                axios
                                    .get(API_URL + '/etax/users', {
                                    headers: {
                                        Authorization: 'Bearer ' + tokenResponse.accessToken,
                                        'cid': companyId
                                    },
                                    })
                                    .then(
                                    (responseUser) => {
                                        console.log('etaxUsers: ', responseUser);

                                        if (responseUser.data && responseUser.data.disabled) {
                                            setShowXmlDeliverSetup(true);
                                        }
                                    },
                                    (error) => {
                                        console.log(error);
                                    }
                                    );
                                }
                            }
                            },
                            (error) => {
                            console.log(error);
                            }
                        );
 */
                    }

                } else {

                    axios
                        .get(API_URL + '/accounts', {
                            headers: {
                                Authorization: 'Bearer ' + tokenResponse.accessToken,
                            },
                        })
                        .then(
                            (responseAcc) => {
                                if (responseAcc.data) {
                                    console.log('Account data: ', responseAcc.data);

                                    if (responseAcc.data.billings?.enableTopup) {
                                        setEnableTopup(responseAcc.data.billings.enableTopup);
                                    }

                                    if (responseAcc.data.settings?.enableSms) {
                                        setEnableSms(responseAcc.data.settings.enableSms);
                                    }

                                    if (responseCompanyDetail.data.settings?.digitalSign) {
                                        setDigitalSign(responseCompanyDetail.data.settings.digitalSign);
                                    }

                                    if (responseAcc.data.settings?.etax?.defaultCertificateId) {

                                        if (responseCompanyDetail.data.settings?.digitalSign) {
                                          axios.get(API_URL + '/certificates', {
                                            headers: {
                                              Authorization: 'Bearer ' + tokenResponse.accessToken,
                                              'cid': companyId
                                            },
                                          })
                                          .then(
                                            (responseCertificate) => {
                                              console.log("responseCertificate", responseCertificate)
                                              
                                              if (responseCertificate.data) {
                                                const currentCertificate = responseCertificate.data.find(_certificate => _certificate.id === responseAcc.data.settings?.etax?.defaultCertificateId)
                                              
                                                if (currentCertificate) {
                                                  if (dateDifference(new Date(), currentCertificate?.validity?.notAfter) < 60 && dateDifference(new Date(), currentCertificate?.validity?.notAfter) > 0) {
                                                    setShowCertificatePendingExpired(true)
                                                  } else if (dateDifference(new Date(), currentCertificate?.validity?.notAfter) <= 0) {
                                                    setShowCertificateExpired(true)
                                                  }
                                                }
                                              }
                                            },
                                            (error) => {
                                              console.log(error);
                                            }
                                          );
/* 
                                            axios
                                            .get(API_URL + '/certificates/' + responseAcc.data.settings?.etax?.defaultCertificateId, {
                                                headers: {
                                                    Authorization: 'Bearer ' + tokenResponse.accessToken,
                                                    cid: companyId
                                                },
                                            })
                                            .then(
                                                (responseCert) => {
                                                if (responseCert.data) {
                                                    let certName = responseCert.data.certificateName;

                                                    console.log('Certificate name: ', responseCert.data.certificateName);

                                                    if (certName.search('Test Only') > -1) {
                                                    setShowCertificateSetup(true);
                                                    } else {
                                                    axios
                                                        .get(API_URL + '/etax/users', {
                                                        headers: {
                                                            Authorization: 'Bearer ' + tokenResponse.accessToken,
                                                            'cid': companyId
                                                        },
                                                        })
                                                        .then(
                                                        (responseUser) => {
                                                            console.log('etaxUsers: ', responseUser);

                                                            if (responseUser.data && responseUser.data.disabled) {
                                                            setShowXmlDeliverSetup(true);
                                                            }
                                                        },
                                                        (error) => {
                                                            console.log(error);
                                                        }
                                                        );
                                                    }
                                                }
                                                },
                                                (error) => {
                                                    console.log(error);
                                                }
                                            );
                                             */
                                        }
                                    }
                                }

                            },
                            (error) => {
                                console.log(error);
                            }
                        );



                }
              }
            },
            (error) => {
              console.log(error);
            }
          );

        const documentsConnection = new signalR.HubConnectionBuilder()
          .withUrl('https://leceipt.azurewebsites.net/api/OnDocumentsChanged', {
        //   .withUrl('http://localhost:7071/api/OnDocumentsChanged', {
            headers: {
              cid: companyId,
            //   oid: tokenResponse.idTokenClaims.oid,
            //   product: 'etax',
            //   fileExtension: 'pdf',
            },
          })
          .configureLogging(signalR.LogLevel.Information)
          .build();

        documentsConnection.on('documentUpdated', (data) => {
          console.log('SignalR push documentUpdated!!');
          console.log(data);

          if (data && data.createdTime) {
            let diffMilliSeconds = new Date() - new Date(data.createdTime);

            console.log('diffMilliSeconds:', diffMilliSeconds);

            // refresh only document create or update in last 15 sec.

            if (diffMilliSeconds < 15000) {
              setDocumentType({ key: 'ALL', text: t('documents.allDocumentsType') });
              setDateType({ key: 'created', text: t('documents.createdDate') });
              setQueryMonth({
                key: getMonth(0).year + '' + getMonth(0).key,
                text: t('documents.thisMonth'),
              });
              setFrom(getFrom(getMonth(0).month, getMonth(0).year));
              setTo(getTo(getMonth(0).month, getMonth(0).year));
              setPageSize(10);
              setCurrentPage(1);

              sessionStorage.setItem('documentType-key', 'ALL');
              sessionStorage.setItem('documentType-text', t('documents.allDocumentsType'));
              sessionStorage.setItem('dateType-key', 'created');
              sessionStorage.setItem('dateType-text', t('documents.createdDate'));
              sessionStorage.setItem('queryMonth-key', getMonth(0).year + '' + getMonth(0).key);
              sessionStorage.setItem('queryMonth-text', getMonth(0).text);
              sessionStorage.setItem('from', getFrom(getMonth(0).month, getMonth(0).year));
              sessionStorage.setItem('to', getTo(getMonth(0).month, getMonth(0).year));
              sessionStorage.setItem('date', new Date().toISOString());
              sessionStorage.setItem('pageSize', 10);
              sessionStorage.setItem('currentPage', 1);
              sessionStorage.setItem('sortDirection', 'DESC');
 

              setPrimarySort('createdTime');
              setIsSortedDescendingCreatedTime(true);
              setIsSortedDescendingDocumentDate(false);
              setIsSortedDescendingNumber(false);
              setIsSortedDescendingCustomerName(false);
              setIsSortedDescendingGrandTotal(false);
              setIsSortedDescendingName(false);
              setIsSortedDescendingEmailReceiver(false);
              setIsSortedDescendingEmailTime(false);

              setSortDirection('DESC');

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
              setPreviousSearch('');
              setSearch('');

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalGrand(0);

            }
          }
        });

        async function documentsStart() {
          try {
            await documentsConnection.start();
            console.log('SignalR Documents Connected.');
          } catch (err) {
            console.log(err);
            setTimeout(documentsStart, 5000);
          }
        }

        documentsConnection.onclose(documentsStart);

        // Start the connection.
        documentsStart();

        const jobsConnection = new signalR.HubConnectionBuilder()
          .withUrl('https://leceipt.azurewebsites.net/api/OnJobsChanged', {
        //   .withUrl('http://localhost:7071/api/OnJobsChanged', {
            headers: {
              cid: companyId,
            //   oid: tokenResponse.idTokenClaims.oid,
            //   product: 'etax',
            //   fileExtension: 'pdf',
            },
          })
          .configureLogging(signalR.LogLevel.Information)
          .build();

        jobsConnection.on('jobUpdated', (data) => {
          console.log('SignalR push jobUpdated!!');
          console.log(data);

          getTotalProcessingDocuments();
        });

        async function jobsStart() {
          try {
            await jobsConnection.start();
            console.log('SignalR Jobs Connected.');
          } catch (err) {
            console.log(err);
            setTimeout(jobsStart, 5000);
          }
        }

        jobsConnection.onclose(jobsStart);
        jobsStart();

        axios
          .get(API_URL + '/sources/emails', {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              'cid': companyId
            },
          })
          .then(
            (response) => {
              console.log('Email sources: ', response);

              if (response.data.id) {
                if (!response.data.disabled) {
                  setIsEmailSetting(true);
                }
              } else {
                setIsEmailSetting(false);
              }
            },
            (error) => {
              console.log(error);

              setIsEmailSetting(false);
            }
          );

        axios
          .get(API_URL + '/functions/wakeup', {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
            },
          })
          .then((response) => {
            console.log('Wake up function: ', response);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });

    getTotalProcessingDocuments();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log('userEffect getNewDocuments Call!');

    if (getNewDocuments) {
      let documentsListEndpoint = API_URL + '/documents/' + documentType.key + '/all?from=' + from + '&to=' + to + 
                                  '&date=' + dateType.key + '&offset=' + (currentPage - 1) * pageSize + '&limit=' + pageSize +  
                                  '&sortDirection=' + sortDirection + '&sortBy=' + primarySort;

      let documentsSummaryEndpoint = API_URL + '/documents/summary/' + documentType.key + '/all?from=' + from + '&to=' + to + 
                                    '&date=' + dateType.key;

      let xmlDeliveriesSummaryEndpoint = API_URL + "/etax/xml/deliveries/summaries/" + documentType.key + "/?from=" + from + "&to=" + to + "&date=" + dateType.key;
      // let xmlDeliveriesSummaryEndpoint = "http://localhost:7071/api/etax/xml/deliveries/summaries/" + documentType.key + "/?from=" + from + "&to=" + to + "&date=" + dateType.key;

      if (search) {
        documentsListEndpoint = API_URL + '/documents/search/' + documentType.key + '/all?from=' + from + '&to=' + to + 
                                '&date=' + dateType.key + '&offset=' + (currentPage - 1) * pageSize + '&limit=' + pageSize + 
                                '&search=' + search +  '&sortDirection=' + sortDirection + '&sortBy=' + primarySort;

        documentsSummaryEndpoint = API_URL + '/documents/summary/search/' + documentType.key + '/all?from=' + from + '&to=' + to + 
                                  '&date=' + dateType.key + '&search=' + search;
      }

      
      if (deletedDocumentsOnly) {
        if (search) {
          documentsListEndpoint = API_URL + '/documents/search/' + documentType.key + '/all?from=' + from + '&to=' + to +
                                  '&date=' + dateType.key + '&offset=' +(currentPage - 1) * pageSize + '&limit=' + pageSize +
                                  '&search=' + search + '&sortDirection=' +sortDirection + '&sortBy=' +primarySort + 
                                  '&deleted=' + deletedDocumentsOnly;
          // documentsSummaryEndpoint = API_URL + '/documents/summary/search/' + documentType.key + '/all?from=' + from + 
          //                           '&to=' + to + '&date=' + dateType.key + '&search=' + search + '&deleted=' + deletedDocumentsOnly;
        } else {
          documentsListEndpoint = API_URL + '/documents/' + documentType.key + '/all?from=' + from + '&to=' + to + 
                                  '&date=' + dateType.key + '&offset=' + (currentPage - 1) * pageSize + '&limit=' + pageSize + 
                                  '&sortDirection=' + sortDirection + '&sortBy=' + primarySort + '&deleted=' + deletedDocumentsOnly

          // documentsSummaryEndpoint = API_URL + '/documents/summary/' + documentType.key + '/all?from=' + from + '&to=' + to + 
          //                           '&date=' + dateType.key + '&deleted=' + deletedDocumentsOnly;
        }
      }

      console.log(documentsListEndpoint)

      instance
        .acquireTokenSilent({ ...silentRequest, account: account })
        .then(async (tokenResponse) => {
          // axios
          //   .get('http://localhost:7071/api/DocumentsTotal', {
          //     headers: {
          //       Authorization: 'Bearer ' + tokenResponse.accessToken,
          //     },
          //     params: {
          //       documentType: documentType.key,
          //       from: from,
          //       to: to,
          //       dateType: dateType.key,
          //       accessToken: tokenResponse.accessToken,
          //     },
          //   })
          //   .then((response) => {
          //     console.log('Documents summary:', response);

          //     const { countTotal, totalGrand, totalVat } = response.data;

          //     setTotalDocuments(countTotal);
          //     setTotalGrand(totalGrand);
          //     setTotalVat(totalVat);
          //   })
          //   .catch((error) => console.log(error));
          axios
            .get(documentsSummaryEndpoint, {
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
                'cid': companyId
              },
            })
            .then((response) => {
              console.log('Documents summary:', response);

              const { countTotal, totalGrand, totalVat } = response.data;

              setTotalDocuments(countTotal);
              setTotalGrand(totalGrand);
              setTotalVat(totalVat);
            })
            .catch((error) => console.log(error));

          axios
            .get(xmlDeliveriesSummaryEndpoint, {
              headers: {
                Authorization: "Bearer " + tokenResponse.accessToken,
                'cid': companyId,
              },
            })
            .then((response) => {              
              console.log("Xml deliveries summary:", response);

              const { pendingTotal } = response.data;

              console.log("XML pendingTotal:", pendingTotal);

              if (pendingTotal > 0) {
                setShowXmlDeliverWarning(true);
              }
            })
            .catch((error) => console.log(error));

          const [response] = await Promise.all([
            axios
              .get(documentsListEndpoint, {
                headers: {
                  Authorization: 'Bearer ' + tokenResponse.accessToken,
                  'cid': companyId
                },
              })
              .catch((error) => console.log(error)),
          ]);

          console.log('response pdf', response);

          let _documents = response.data.data.documents;
          let _totalDocument = response.data.data.countTotal;

          _documents.forEach((doc) => {
            if (doc.type === 'CREDIT-NOTE') {
              doc.data.amountTotal = -Math.abs(doc.data.amountTotal);
              doc.data.grandTotal = -Math.abs(doc.data.grandTotal);
              doc.data.vatTotal = -Math.abs(doc.data.vatTotal);
            }
          });

          //Page dropdown
          let _pageDropdown = [];

          for (let i = 1; i <= Math.ceil(_totalDocument / pageSize); i++) {
            _pageDropdown.push({
              key: i,
              text: t('documents.page') + ' ' + i,
              iconProps: { iconName: 'Page' },
              onClick: () => {
                console.log('Selected page: ' + i);
                setCurrentPage(i);

                setLoadDataComplete(false);
                setItems([]);
                setGetNewDocuments(true);
              },
            });
          }

          setPageDropdown(_pageDropdown);

          if (currentPage > Math.ceil(_totalDocument / pageSize)) {
            setCurrentPage(1);
          }
/* 
          //Sorting
          if (_documents && _documents.length > 0) {
            if (primarySort === 'createdTime') {
              if (isSortedDescendingCreatedTime) {
                _documents.sort(function (a, b) {
                  // Turn your strings into dates, and then subtract them
                  // to get a value that is either negative, positive, or zero.
                  return new Date(b.createdTime) - new Date(a.createdTime);
                });
              } else {
                _documents.sort(function (a, b) {
                  // Turn your strings into dates, and then subtract them
                  // to get a value that is either negative, positive, or zero.
                  return new Date(a.createdTime) - new Date(b.createdTime);
                });
              }
            } else if (primarySort === 'number') {
              if (isSortedDescendingNumber) {
                _documents.sort((a, b) => {
                  return (
                    /[A-Za-z]/.test(b.data.number) - /[A-Za-z]/.test(a.data.number) ||
                    (b.data.number.toUpperCase() < a.data.number.toUpperCase()
                      ? -1
                      : b.data.number.toUpperCase() > a.data.number.toUpperCase()
                      ? 1
                      : 0)
                  );
                });
              } else {
                _documents.sort((a, b) => {
                  return (
                    /[A-Za-z]/.test(a.data.number) - /[A-Za-z]/.test(b.data.number) ||
                    (a.data.number.toUpperCase() < b.data.number.toUpperCase()
                      ? -1
                      : a.data.number.toUpperCase() > b.data.number.toUpperCase()
                      ? 1
                      : 0)
                  );
                });
              }
            } else if (primarySort === 'documentDate') {
              if (isSortedDescendingDocumentDate) {
                _documents.sort(function (a, b) {
                  // Turn your strings into dates, and then subtract them
                  // to get a value that is either negative, positive, or zero.
                  return new Date(b.data.date) - new Date(a.data.date);
                });
              } else {
                _documents.sort(function (a, b) {
                  // Turn your strings into dates, and then subtract them
                  // to get a value that is either negative, positive, or zero.
                  return new Date(a.data.date) - new Date(b.data.date);
                });
              }
            } else if (primarySort === 'customerName') {
              if (isSortedDescendingCustomerName) {
                _documents.sort((a, b) => {
                  if (b.data.customer.name < a.data.customer.name) {
                    return -1;
                  }
                  if (b.data.customer.name > a.data.customer.name) {
                    return 1;
                  }
                  return 0;
                });
              } else {
                _documents.sort((a, b) => {
                  if (a.data.customer.name < b.data.customer.name) {
                    return -1;
                  }
                  if (a.data.customer.name > b.data.customer.name) {
                    return 1;
                  }
                  return 0;
                });
              }
            } else if (primarySort === 'customerPhone') {
              if (isSortedDescendingCustomerPhone) {
                _documents.sort((a, b) => {
                  if (b.data.customer.phone < a.data.customer.phone) {
                    return -1;
                  }
                  if (b.data.customer.phone > a.data.customer.phone) {
                    return 1;
                  }
                  return 0;
                });
              } else {
                _documents.sort((a, b) => {
                  if (a.data.customer.phone < b.data.customer.phone) {
                    return -1;
                  }
                  if (a.data.customer.phone > b.data.customer.phone) {
                    return 1;
                  }
                  return 0;
                });
              }
            } else if (primarySort === 'vatTotal') {
              if (isSortedDescendingVatTotal) {
                _documents.sort(function (a, b) {
                  return b.data.vatTotal - a.data.vatTotal;
                });
              } else {
                _documents.sort(function (a, b) {
                  return a.data.vatTotal - b.data.vatTotal;
                });
              }
            } else if (primarySort === 'grandTotal') {
              if (isSortedDescendingGrandTotal) {
                _documents.sort(function (a, b) {
                  return b.data.grandTotal - a.data.grandTotal;
                });
              } else {
                _documents.sort(function (a, b) {
                  return a.data.grandTotal - b.data.grandTotal;
                });
              }
            } else if (primarySort === 'name') {
              if (isSortedDescendingName) {
                _documents.sort((a, b) => {
                  if (b.data.name < a.data.name) {
                    return -1;
                  }
                  if (b.data.name > a.data.name) {
                    return 1;
                  }
                  return 0;
                });
              } else {
                _documents.sort((a, b) => {
                  if (a.data.name < b.data.name) {
                    return -1;
                  }
                  if (a.data.name > b.data.name) {
                    return 1;
                  }
                  return 0;
                });
              }
            } else if (primarySort === 'emailReceiver') {
              if (isSortedDescendingEmailReceiver) {
                _documents.sort((a, b) => {
                  if (b.data.customer.email < a.data.customer.email) {
                    return -1;
                  }
                  if (b.data.customer.email > a.data.customer.email) {
                    return 1;
                  }
                  return 0;
                });
              } else {
                _documents.sort((a, b) => {
                  if (a.data.customer.email < b.data.customer.email) {
                    return -1;
                  }
                  if (a.data.customer.email > b.data.customer.email) {
                    return 1;
                  }
                  return 0;
                });
              }
            } else if (primarySort === 'emailTime') {
              if (isSortedDescendingEmailTime) {
                _documents.sort(function (a, b) {
                  // Turn your strings into dates, and then subtract them
                  // to get a value that is either negative, positive, or zero.
                  return new Date(b.deliveries.email.time) - new Date(a.deliveries.email.time);
                });
              } else {
                _documents.sort(function (a, b) {
                  // Turn your strings into dates, and then subtract them
                  // to get a value that is either negative, positive, or zero.
                  return new Date(a.deliveries.email.time) - new Date(b.deliveries.email.time);
                });
              }
            }
          }
 */
          //set page
          setPageData(paginate(_totalDocument, currentPage, pageSize, 10));

          const numberArray = _documents.map(function (doc) {
            return doc.data.number;
          });
          const isDuplicate = numberArray.some(function (number, idx) {
            return numberArray.indexOf(number) !== idx;
          });

          if (isDuplicate) {
            const _duplicatedNumbers = numberArray.filter((item, index) => index !== numberArray.indexOf(item));
            const uniq = [...new Set(_duplicatedNumbers)];
            setDuplicatedNumbers(uniq)
          }
          setShowRepeatNumber(isDuplicate);

          const _docs = [];

          if (_documents.length > 0) {
            for (let i = 0; i < _documents.length; i++) {
              let fileSize = formatBytes(_documents[i].file.size);
              let createdTime = toThaiDateString(_documents[i].createdTime);

              let _companyEmail = '';

              let _customerEmail = '';
              let _customerPhone = '';
              let _amountTotal = 0;
              let _vatTotal = 0;
              let _grandTotal = 0;

              if (
                _documents[i].data.company &&
                _documents[i].data.company.email &&
                _documents[i].data.company.email !== null &&
                _documents[i].data.company.email !== undefined
              ) {
                _companyEmail = _documents[i].data.company.email;
              }

              if (
                _documents[i].data.customer &&
                _documents[i].data.customer.email &&
                _documents[i].data.customer.email !== null &&
                _documents[i].data.customer.email !== undefined
              ) {
                _customerEmail = _documents[i].data.customer.email;
              }

              if (
                _documents[i].data.customer &&
                _documents[i].data.customer.phone &&
                _documents[i].data.customer.phone !== null &&
                _documents[i].data.customer.phone !== undefined
              ) {
                _customerPhone = _documents[i].data.customer.phone;
              }

              if (_documents[i].type === 'WITHHOLDING-TAX') {
                _amountTotal = '-';
                _vatTotal = '-';
                _grandTotal = '-';
              } else {
                if (_documents[i].data.amountTotal) {
                  _amountTotal = _documents[i].data.amountTotal;
                }

                if (_documents[i].data.vatTotal) {
                  _vatTotal = _documents[i].data.vatTotal;
                }

                if (_documents[i].data.grandTotal) {
                  _grandTotal = _documents[i].data.grandTotal;
                }
              }

              let _isPdfXml = false;

              if (_documents[i].xmlFile && _documents[i].xmlFile.size) {
                _isPdfXml = true;
              }

              _docs.push({
                key: _documents[i].id,
                pdfFileName: _documents[i].file.displayName,
                name: _documents[i].data.name,
                fileExtension: _documents[i].file.extension,
                isPdfXml: _isPdfXml,
                createdBy: _documents[i].createdBy,
                createdTime: createdTime,
                createdTimeValue: _documents[i].createdTime,
                fileSize: fileSize,
                fileSizeRaw: _documents[i].file.size,
                type: _documents[i].type,
                signature: _documents[i].signatures[0].certificateName,
                product: _documents[i].product,
                number: _documents[i].data.number,
                companyEmail: _companyEmail,
                customerName: _documents[i].data.customer.name,
                customerEmail: _customerEmail,
                customerPhone: _customerPhone,
                amountTotal: _amountTotal,
                vatTotal: _vatTotal,
                grandTotal: _grandTotal,
                data: _documents[i].data,
                documentDate: _documents[i].data.dateBE,
                xmlData: _documents[i].xmlFile,
                rdStatus: _documents[i].deliveries.rd.status,
                digitalSign: _documents[i].digitalSign,
                reIssue: _documents[i].data.reIssue,
                emailReceiver: _documents[i]?.deliveries?.email?.receiver
                  ? _documents[i].deliveries.email.receiver
                  : _documents[i].data.customer.email,
                emailTime: _documents[i]?.deliveries?.email?.time ? toThaiDateString(_documents[i].deliveries.email.time) : '',
                emailTimeValue: _documents[i]?.deliveries?.email?.time,
                documentDateValue: _documents[i].data.date,
                smsTime: _documents[i]?.deliveries?.sms?.response?.date_created
                  ? toThaiDateString(_documents[i]?.deliveries?.sms?.response?.date_created)
                  : _documents[i]?.deliveries?.sms?.time 
                    ? toThaiDateString(_documents[i].deliveries.sms.time) 
                    : '',
                smsTimeValue: _documents[i]?.deliveries?.sms?.response?.date_created || _documents[i]?.deliveries?.sms?.time,                               
              });
            }
          }

          console.log('Docs data', _docs);
          setItems(_docs);

          setLoadDataComplete(true);
          setGetNewDocuments(false);
        })
        .catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);
          // instance.acquireTokenRedirect(silentRequest);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getNewDocuments]);

  const download = (item) => {
    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .get(API_URL + '/etax/files/' + item.key, {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              'cid': companyId
            },
            responseType: 'blob',
          })
          .then(
            (response) => {
              console.log(response.headers);

              const time = formatDateTime(item.createdTimeValue);

              let fileName = time + '-' + item.type + '-' + item.number.replace(/\//g, '') + '.' + item.fileExtension;

              console.log('file name: ', fileName);

              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', fileName); //or any other extension
              document.body.appendChild(link);
              link.click();
              link.remove();
            },
            (error) => {
              console.log(error);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
  };

  const downloadPNG = async (item) => {
    console.log('download PNG');

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .get(API_URL + '/etax/files/' + item.key, {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              'cid': companyId
            },
            responseType: 'blob',
          })
          .then(
            (response) => {
              console.log(response.headers);

              const time = formatDateTime(item.createdTimeValue);

              let fileName = time + '-' + item.type + '-' + item.number.replace(/\//g, '');

              setFileName(fileName);

              console.log('file name: ', fileName);

              const url = window.URL.createObjectURL(new Blob([response.data]));
              setUrl(url);
            },
            (error) => {
              console.log(error);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
  };

  useEffect(() => {
    if (url) {
      getPNGorZip();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  const deleteFile = (item) => {
    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .delete(API_URL + '/etax/documents/' + item.key, {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              'cid': companyId
            },
          })
          .then(
            (response) => {
              console.log(response);
              setLoadDataComplete(false);
              setGetNewDocuments(true);
            },
            (error) => {
              console.log(error);
            }
          );
      })
      .catch((error) => {
        // Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
  };

  const openFile = (item) => {
    if (item.fileExtension === 'pdf') {
      openFile_pdf(item);
    } else if (item.fileExtension === 'xml') {
      openFile_xml(item);
    }
  };

  const openFile_pdf = (item) => {
    history.push({
      pathname: '/etax/documents/pdf/view',
      state: {
        item: item,
      },
    });
  };

  const openFile_xml = (item) => {
    history.push({
      pathname: '/etax/documents/xml/view',
      state: {
        item: item,
      },
    });
  };

  const onPageChange = (selectedPageIndex) => {
    console.log('selectedPageIndex: ', selectedPageIndex);
    setCurrentPage(selectedPageIndex + 1);
    setGetNewDocuments(true);
    setLoadDataComplete(false);
  };

  const selectedDownloadZip = (fileExt) => {
    console.log(selectedItems);
    if (selectedItems.length > 0 && items.length > 0) {
      let _documentIds = '';

      for (let i = 0; i < selectedItems.length; i++) {
        if (i === selectedItems.length - 1) {
          _documentIds += selectedItems[i].key;
        } else {
          _documentIds += selectedItems[i].key + ',';
        }
      }

      console.log(_documentIds);

      instance
        .acquireTokenSilent({ ...silentRequest, account: account })
        .then((tokenResponse) => {
          axios
            .get(API_URL + '/files/zip', {
              params: {
                fileExtension: fileExt,
                documentIds: _documentIds,
              },
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
                cid: companyId
              },
              responseType: 'blob',
            })
            .then(
              (response) => {
                console.log(response.headers);

                let zipFileName = formatDateTime(new Date().toISOString()) + '-' + fileExt.toUpperCase() + '-Leceipt.zip';

                console.log('Zip file name: ', zipFileName);

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', zipFileName); //or any other extension
                document.body.appendChild(link);
                link.click();
                link.remove();
              },
              (error) => {
                console.log(error);
              }
            );
        })
        .catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);
          instance.acquireTokenRedirect(silentRequest);
        });
    }
  };

  const reCreateDocument = async (documentType, item) => {
    console.log(item);

    let documentPath = '/etax/documents/receipts';

    switch (documentType) {
      case 'RECEIPT-TAXINVOICE':
        documentPath = '/etax/documents/receipts-taxinvoices';
        break;
      case 'RECEIPT-TAXINVOICE-ABB':
        documentPath = '/etax/documents/receipts-taxinvoices-abb';
        break;
      case 'RECEIPT':
        if (item.data.items[0].date) {
          documentPath = '/etax/documents/batch-receipts';
        } else {
          documentPath = '/etax/documents/receipts';
        }
        break;
      case 'INVOICE-TAXINVOICE':
        documentPath = '/etax/documents/invoices-taxinvoices';
        break;
      case 'DELIVERYORDER-TAXINVOICE':
        documentPath = '/etax/documents/deliveryorders-taxinvoices';
        break;
      case 'TAXINVOICE':
        documentPath = '/etax/documents/taxinvoices';
        break;
      case 'DEBIT-NOTE':
        documentPath = '/etax/documents/debit-notes';
        break;
      case 'CREDIT-NOTE':
        documentPath = '/etax/documents/credit-notes';
        break;
      case 'QUOTATION':
        documentPath = '/documents/quotations';
        break;
      case 'INVOICE':
        documentPath = '/documents/invoices';
        break;
      case 'BILLING-NOTE':
        documentPath = '/documents/billing-notes';
        break;
      case 'DELIVERYORDER':
        documentPath = '/documents/deliveryorders';
        break;
      default:
        break;
    }

    if (documentType === item.type) {
      history.push({
        pathname: documentPath,
        state: {
          command: 'reCreate',
          company: item.data.company,
          customer: item.data.customer,
          number: item.data.number,
          date: item.data.date,
          dateBE: item.data.dateBE,
          dateCE: item.data.dateCE,
          dueDate: item.data.dueDate,
          dueDateBE: item.data.dueDateBE,
          dueDateCE: item.data.dueDateCE,
          discount: item.data.discount,
          items: item.data.items,
          amountTotal: item.data.amountTotal,
          vatTotal: item.data.vatTotal,
          grandTotal: item.data.grandTotal,
          note: item.data.note,
          createdBy: item.data.createdBy,
          receivedBy: item.data.receivedBy,
          percentVat: item.data.percentVat,
          includeVat: item.data.includeVat,
          reIssue: item.data.reIssue,
          refer: item.data.refer,
          reference: item.data.reference,
          createdTime: item.data.createdTime,
          id: item.data.id,
          message: item.data.message,
          name: item.data.name,
          payment: item.data.payment,
          schemeVersionID: item.data.schemeVersionID,
          status: item.data.status,
          typeCode: item.data.typeCode,
          backUrl: '/etax/documents/all',
          ...(item.data.extraAttributes ? {extraAttributes: item.data.extraAttributes} : {})
        },
      });
    } else {
      let _reference = item.data.reference;

      if (
        item.type === 'INVOICE-TAXINVOICE' ||
        item.type === 'DELIVERYORDER-TAXINVOICE' ||
        item.type === 'QUOTATION' ||
        item.type === 'INVOICE' ||
        item.type === 'BILLING-NOTE' ||
        item.type === 'DELIVERYORDER'
      ) {
        _reference = item.data.number;
      }

      history.push({
        pathname: documentPath,
        state: {
          command: 'reCreate',
          company: item.data.company,
          customer: item.data.customer,
          number: '',
          date: null,
          dateBE: null,
          dateCE: null,
          dueDate: item.data.dueDate,
          dueDateBE: item.data.dueDateBE,
          dueDateCE: item.data.dueDateCE,
          discount: item.data.discount,
          items: item.data.items,
          amountTotal: item.data.amountTotal,
          vatTotal: item.data.vatTotal,
          grandTotal: item.data.grandTotal,
          note: item.data.note,
          createdBy: item.data.createdBy,
          receivedBy: item.data.receivedBy,
          percentVat: item.data.percentVat,
          includeVat: item.data.includeVat,
          reIssue: item.data.reIssue,
          refer: item.data.refer,
          reference: _reference,
          createdTime: item.data.createdTime,
          id: item.data.id,
          message: item.data.message,
          name: item.data.name,
          payment: item.data.payment,
          schemeVersionID: item.data.schemeVersionID,
          status: item.data.status,
          typeCode: item.data.typeCode,
          backUrl: '/etax/documents/all',
          ...(item.data.extraAttributes ? {extraAttributes: item.data.extraAttributes} : {})
        },
      });
    }
  };

  const reCreateCreditAndDebit = async (documentType, item) => {
    console.log(item);

    let documentPath = '/etax/documents/debit-notes';

    switch (documentType) {
      case 'DEBIT-NOTE':
        documentPath = '/etax/documents/debit-notes';
        break;
      case 'CREDIT-NOTE':
        documentPath = '/etax/documents/credit-notes';
        break;
      default:
        break;
    }

    let _refer = item.data.refer || {
      "date": null,
      "dateBE": null,
      "dateCE": null,
      "number": null,
      "reasonCode": null,
      "reasonName": null,
      "specificReason": null,
      "typeCode": item.data.typeCode,
      "typeName": item.data.name
    }

    _refer.items = item.data.items

    history.push({
      pathname: documentPath,
      state: {
        command: 'reCreate',
        company: item.data.company,
        customer: item.data.customer,
        number: item.data.number,
        date: item.data.date,
        dateBE: item.data.dateBE,
        dateCE: item.data.dateCE,
        discount: item.data.discount,
        items: item.data.items,
        amountTotal: item.data.amountTotal,
        vatTotal: item.data.vatTotal,
        grandTotal: item.data.grandTotal,
        note: item.data.note,
        createdBy: item.data.createdBy,
        receivedBy: item.data.receivedBy,
        percentVat: item.data.percentVat,
        includeVat: item.data.includeVat,
        reIssue: item.data.reIssue,
        refer: _refer,
        createdTime: item.data.createdTime,
        id: item.data.id,
        message: item.data.message,
        name: item.data.name,
        payment: item.data.payment,
        schemeVersionID: item.data.schemeVersionID,
        status: item.data.status,
        typeCode: item.data.typeCode,
        backUrl: '/etax/documents/all',
        ...(item.data.extraAttributes ? {extraAttributes: item.data.extraAttributes} : {})
      },
    });
  };

  const reCreateWithholdingTax = async (item) => {
    console.log(item);

    let documentPath = '/documents/withholding-taxs';

    history.push({
      pathname: documentPath,
      state: {
        command: 'reCreate',
        bookNumber: item.data.bookNumber,
        number: item.data.number,
        company: item.data.company,
        customer: item.data.customer,
        orderNumber: item.data.orderNumber,
        formType_1: item.data.formType_1,
        formType_2: item.data.formType_2,
        formType_3: item.data.formType_3,
        formType_4: item.data.formType_4,
        formType_5: item.data.formType_5,
        formType_6: item.data.formType_6,
        formType_7: item.data.formType_7,
        paymentType_1: item.data.paymentType_1,
        paymentType_2: item.data.paymentType_2,
        paymentType_3: item.data.paymentType_3,
        paymentType_4_A: item.data.paymentType_4_A,
        paymentType_4_B_1_1: item.data.paymentType_4_B_1_1,
        paymentType_4_B_1_2: item.data.paymentType_4_B_1_2,
        paymentType_4_B_1_3: item.data.paymentType_4_B_1_3,
        paymentType_4_B_1_4: item.data.paymentType_4_B_1_4,
        paymentType_4_B_2_1: item.data.paymentType_4_B_2_1,
        paymentType_4_B_2_2: item.data.paymentType_4_B_2_2,
        paymentType_4_B_2_3: item.data.paymentType_4_B_2_3,
        paymentType_4_B_2_4: item.data.paymentType_4_B_2_4,
        paymentType_4_B_2_5: item.data.paymentType_4_B_2_5,
        paymentType_5: item.data.paymentType_5,
        paymentType_6: item.data.paymentType_6,
        amountTotal: item.data.amountTotal,
        vatTotal: item.data.vatTotal,
        vatTotalText: item.data.vatTotalText,
        paymentAmount: item.data.paymentAmount,
        payment_type_1: item.data.payment_type_1,
        payment_type_2: item.data.payment_type_2,
        payment_type_3: item.data.payment_type_3,
        payment_type_4: item.data.payment_type_4,
        otherPayment: item.data.otherPayment,
        date: item.data.date,
        dateBE: item.data.dateBE,
        dateCE: item.data.dateCE,
        backUrl: '/etax/documents/all',
      },
    });
  };

  const reIssueDocument = async (item) => {
    console.log(item);

    let documentPath = '/etax/documents/receipts';

    switch (item.type) {
      case 'RECEIPT-TAXINVOICE':
        documentPath = '/etax/documents/receipts-taxinvoices';
        break;
      case 'RECEIPT-TAXINVOICE-ABB':
        documentPath = '/etax/documents/receipts-taxinvoices-abb';
        break;
      case 'RECEIPT':
        if (item.data.items[0].date) {
          documentPath = '/etax/documents/batch-receipts';
        } else {
          documentPath = '/etax/documents/receipts';
        }
        break;
      case 'INVOICE-TAXINVOICE':
        documentPath = '/etax/documents/invoices-taxinvoices';
        break;
      case 'DELIVERYORDER-TAXINVOICE':
        documentPath = '/etax/documents/deliveryorders-taxinvoices';
        break;
      case 'TAXINVOICE':
        documentPath = '/etax/documents/taxinvoices';
        break;
      case 'DEBIT-NOTE':
        documentPath = '/etax/documents/debit-notes';
        break;
      case 'CREDIT-NOTE':
        documentPath = '/etax/documents/credit-notes';
        break;
      default:
        break;
    }

    history.push({
      pathname: documentPath,
      state: {
        command: 'reIssue',
        company: item.data.company,
        customer: item.data.customer,
        number: item.data.number,
        date: item.data.date,
        dateBE: item.data.dateBE,
        dateCE: item.data.dateCE,
        dueDate: item.data.dueDate,
        dueDateBE: item.data.dueDateBE,
        dueDateCE: item.data.dueDateCE,
        discount: item.data.discount,
        items: item.data.items,
        amountTotal: item.data.amountTotal,
        vatTotal: item.data.vatTotal,
        grandTotal: item.data.grandTotal,
        note: item.data.note,
        createdBy: item.data.createdBy,
        receivedBy: item.data.receivedBy,
        percentVat: item.data.percentVat,
        includeVat: item.data.includeVat,
        reIssue: item.data.reIssue,
        refer: item.data.refer,
        reference: item.data.reference,
        createdTime: item.data.createdTime,
        id: item.data.id,
        message: item.data.message,
        name: item.data.name,
        payment: item.data.payment,
        schemeVersionID: item.data.schemeVersionID,
        status: item.data.status,
        typeCode: item.data.typeCode,
        backUrl: '/etax/documents/all',
        ...(item.data.extraAttributes ? {extraAttributes: item.data.extraAttributes} : {})
      },
    });
  };

  const columns = [
    {
      key: 'column1',
      name: t('documents.createdDate'),
      fieldName: 'createdTime',
      minWidth: 70,
      maxWidth: 125,
      isRowHeader: true,
      isResizable: true,
      isSorted: primarySort === 'createdTime' ? true : false,
      isSortedDescending: isSortedDescendingCreatedTime,
      isSortedAscending: !isSortedDescendingCreatedTime,
      onColumnClick: () => {
        console.log('Sort created time!');
        setPrimarySort('createdTime');
        setIsSortedDescendingCreatedTime(!isSortedDescendingCreatedTime);

        // handleSort('createdTime', !isSortedDescendingCreatedTime);

        setLoadDataComplete(false);
        setItems([]);
        setGetNewDocuments(true);

        setTotalDocuments(0);
        setTotalVat(0);
        setTotalGrand(0);

        sessionStorage.setItem('primarySort', 'createdTime');
        sessionStorage.setItem('sort-createdTime', !isSortedDescendingCreatedTime === true ? 'true' : 'false');

        sessionStorage.setItem('sort-number', 'false');
        sessionStorage.setItem('sort-documentDate', 'false');
        sessionStorage.setItem('sort-customerName', 'false');
        sessionStorage.setItem('sort-customerPhone', 'false');
        sessionStorage.setItem('sort-vatTotal', 'false');
        sessionStorage.setItem('sort-grandTotal', 'false');
        sessionStorage.setItem('sort-name', 'false');
        sessionStorage.setItem('sort-emailTime', 'false');
        sessionStorage.setItem('sort-emailReceiver', 'false');

        if (!isSortedDescendingCreatedTime) {
            setSortDirection('DESC');
            sessionStorage.setItem('sortDirection', 'DESC');
        } else {
            setSortDirection('ASC');
            sessionStorage.setItem('sortDirection', 'ASC');
        }

      },
      data: 'string',
      onRender: (item) => {
        return <span style={{ color: deletedDocumentsOnly ? "#A4262C" : ""}}>{item.createdTime}</span>;
      },
    },
    {
      key: 'column2',
      name: t('documents.number'),
      fieldName: 'number',
      minWidth: 80,
      maxWidth: 150,
      isRowHeader: true,
      isResizable: true,
      isSorted: primarySort === 'number' ? true : false,
      isSortedDescending: isSortedDescendingNumber,
      isSortedAscending: !isSortedDescendingNumber,
      onColumnClick: () => {
        console.log('Sort document number!');
        setPrimarySort('number');
        setIsSortedDescendingNumber(!isSortedDescendingNumber);

        // handleSort('number', !isSortedDescendingNumber);

        setLoadDataComplete(false);
        setItems([]);
        setGetNewDocuments(true);

        setTotalDocuments(0);
        setTotalVat(0);
        setTotalGrand(0);

        sessionStorage.setItem('primarySort', 'number');
        sessionStorage.setItem('sort-number', !isSortedDescendingNumber === true ? 'true' : 'false');

        sessionStorage.setItem('sort-createdTime', 'false');
        sessionStorage.setItem('sort-documentDate', 'false');
        sessionStorage.setItem('sort-customerName', 'false');
        sessionStorage.setItem('sort-customerPhone', 'false');
        sessionStorage.setItem('sort-vatTotal', 'false');
        sessionStorage.setItem('sort-grandTotal', 'false');
        sessionStorage.setItem('sort-name', 'false');
        sessionStorage.setItem('sort-emailTime', 'false');
        sessionStorage.setItem('sort-emailReceiver', 'false');

        if (!isSortedDescendingNumber) {
            setSortDirection('DESC');
            sessionStorage.setItem('sortDirection', 'DESC');
        } else {
            setSortDirection('ASC');
            sessionStorage.setItem('sortDirection', 'ASC');
        }

      },
      data: 'string',
      onRender: (item) => {
        return (
          <ActionButton
            title={t('documents.download')}
            className={classNames.fileIconImg}
            onClick={() => {
              openFile(item);
            }}
            style={{ color: deletedDocumentsOnly ? "#A4262C" : ""}}
          >
            {item.number}
          </ActionButton>
        );
      },
    },
    {
      key: 'column3',
      name: t('documents.documentDate'),
      fieldName: 'documentDate',
      minWidth: 80,
      maxWidth: 80,
      isRowHeader: true,
      isResizable: true,
      isSorted: primarySort === 'documentDate' ? true : false,
      isSortedDescending: isSortedDescendingDocumentDate,
      isSortedAscending: !isSortedDescendingDocumentDate,
      onColumnClick: () => {
        console.log('Sort documentDate!');
        setPrimarySort('documentDate');
        setIsSortedDescendingDocumentDate(!isSortedDescendingDocumentDate);

        // handleSort('documentDate', !isSortedDescendingDocumentDate);

        setLoadDataComplete(false);
        setItems([]);
        setGetNewDocuments(true);

        setTotalDocuments(0);
        setTotalVat(0);
        setTotalGrand(0);

        sessionStorage.setItem('primarySort', 'documentDate');
        sessionStorage.setItem('sort-documentDate', !isSortedDescendingDocumentDate === true ? 'true' : 'false');

        sessionStorage.setItem('sort-createdTime', 'false');
        sessionStorage.setItem('sort-number', 'false');
        sessionStorage.setItem('sort-customerName', 'false');
        sessionStorage.setItem('sort-customerPhone', 'false');
        sessionStorage.setItem('sort-vatTotal', 'false');
        sessionStorage.setItem('sort-grandTotal', 'false');
        sessionStorage.setItem('sort-name', 'false');
        sessionStorage.setItem('sort-emailTime', 'false');
        sessionStorage.setItem('sort-emailReceiver', 'false');

        if (!isSortedDescendingDocumentDate) {
            setSortDirection('DESC');
            sessionStorage.setItem('sortDirection', 'DESC');
        } else {
            setSortDirection('ASC');
            sessionStorage.setItem('sortDirection', 'ASC');
        }

      },
      data: 'string',
      onRender: (item) => {
        return <span style={{ color: deletedDocumentsOnly ? "#A4262C" : ""}}>{item.documentDate}</span>;
      },
    },
    {
      key: 'column4',
      name: t('documents.customerName'),
      fieldName: 'customerName',
      minWidth: 90,
      maxWidth: 220,
      isRowHeader: true,
      isResizable: true,
      isSorted: primarySort === 'customerName' ? true : false,
      isSortedDescending: isSortedDescendingCustomerName,
      isSortedAscending: !isSortedDescendingCustomerName,
      onColumnClick: () => {
        console.log('Sort customerName!');
        setPrimarySort('customerName');
        setIsSortedDescendingCustomerName(!isSortedDescendingCustomerName);

        // handleSort('customerName', !isSortedDescendingCustomerName);

        setLoadDataComplete(false);
        setItems([]);
        setGetNewDocuments(true);

        setTotalDocuments(0);
        setTotalVat(0);
        setTotalGrand(0);

        sessionStorage.setItem('primarySort', 'customerName');
        sessionStorage.setItem('sort-customerName', !isSortedDescendingCustomerName === true ? 'true' : 'false');

        sessionStorage.setItem('sort-createdTime', 'false');
        sessionStorage.setItem('sort-number', 'false');
        sessionStorage.setItem('sort-customerPhone', 'false');
        sessionStorage.setItem('sort-documentDate', 'false');
        sessionStorage.setItem('sort-vatTotal', 'false');
        sessionStorage.setItem('sort-grandTotal', 'false');
        sessionStorage.setItem('sort-name', 'false');
        sessionStorage.setItem('sort-emailTime', 'false');
        sessionStorage.setItem('sort-emailReceiver', 'false');

        if (!isSortedDescendingCustomerName) {
            setSortDirection('DESC');
            sessionStorage.setItem('sortDirection', 'DESC');
        } else {
            setSortDirection('ASC');
            sessionStorage.setItem('sortDirection', 'ASC');
        }

      },
      data: 'string',
      onRender: (item) => {
        return <span style={{ color: deletedDocumentsOnly ? "#A4262C" : ""}}>{item.customerName}</span>;
      },
    },
    {
      key: 'column5',
      name: t('documents.grandTotal'),
      fieldName: 'grandTotal',
      minWidth: 70,
      maxWidth: 100,
      isRowHeader: true,
      isResizable: true,
      isSorted: primarySort === 'grandTotal' ? true : false,
      isSortedDescending: isSortedDescendingGrandTotal,
      isSortedAscending: !isSortedDescendingGrandTotal,
      onColumnClick: () => {
        console.log('Sort grandTotal!');
        setPrimarySort('grandTotal');
        setIsSortedDescendingGrandTotal(!isSortedDescendingGrandTotal);

        // handleSort('grandTotal', !isSortedDescendingGrandTotal);

        setLoadDataComplete(false);
        setItems([]);
        setGetNewDocuments(true);

        setTotalDocuments(0);
        setTotalVat(0);
        setTotalGrand(0);

        sessionStorage.setItem('primarySort', 'grandTotal');
        sessionStorage.setItem('sort-grandTotal', !isSortedDescendingGrandTotal === true ? 'true' : 'false');

        sessionStorage.setItem('sort-createdTime', 'false');
        sessionStorage.setItem('sort-number', 'false');
        sessionStorage.setItem('sort-documentDate', 'false');
        sessionStorage.setItem('sort-customerName', 'false');
        sessionStorage.setItem('sort-customerPhone', 'false');
        sessionStorage.setItem('sort-vatTotal', 'false');
        sessionStorage.setItem('sort-name', 'false');
        sessionStorage.setItem('sort-emailTime', 'false');
        sessionStorage.setItem('sort-emailReceiver', 'false');

        if (!isSortedDescendingGrandTotal) {
            setSortDirection('DESC');
            sessionStorage.setItem('sortDirection', 'DESC');
        } else {
            setSortDirection('ASC');
            sessionStorage.setItem('sortDirection', 'ASC');
        }

      },
      data: 'number',
      onRender: (item) => {
        return (
          <span style={{ display: 'block', textAlign: 'right', color: deletedDocumentsOnly ? "#A4262C" : "" }} >
            {item.grandTotal.toLocaleString(undefined, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}
          </span>
        );
      },
    },
    {
      key: 'column6',
      name: t('documents.document'),
      fieldName: 'name',
      minWidth: 160,
      maxWidth: 180,
      isCollapsible: true,
      isRowHeader: true,
      isResizable: true,
      isSorted: primarySort === 'name' ? true : false,
      isSortedDescending: isSortedDescendingName,
      isSortedAscending: !isSortedDescendingName,
      onColumnClick: () => {
        console.log('Sort name!');
        setPrimarySort('name');
        setIsSortedDescendingName(!isSortedDescendingName);

        // handleSort('name', !isSortedDescendingName);

        setLoadDataComplete(false);
        setItems([]);
        setGetNewDocuments(true);

        setTotalDocuments(0);
        setTotalVat(0);
        setTotalGrand(0);

        sessionStorage.setItem('primarySort', 'name');
        sessionStorage.setItem('sort-name', !isSortedDescendingName === true ? 'true' : 'false');

        sessionStorage.setItem('sort-createdTime', 'false');
        sessionStorage.setItem('sort-number', 'false');
        sessionStorage.setItem('sort-documentDate', 'false');
        sessionStorage.setItem('sort-customerName', 'false');
        sessionStorage.setItem('sort-customerPhone', 'false');
        sessionStorage.setItem('sort-vatTotal', 'false');
        sessionStorage.setItem('sort-grandTotal', 'false');
        sessionStorage.setItem('sort-emailTime', 'false');
        sessionStorage.setItem('sort-emailReceiver', 'false');

        if (!isSortedDescendingName) {
            setSortDirection('DESC');
            sessionStorage.setItem('sortDirection', 'DESC');
        } else {
            setSortDirection('ASC');
            sessionStorage.setItem('sortDirection', 'ASC');
        }

      },
      data: 'string',
      onRender: (item) => {
        return (
          <ActionButton
            title={t('documents.openFile')}
            className={classNames.fileIconImg}
            onClick={() => {
              openFile(item);
            }}
            style={{ color: deletedDocumentsOnly ? "#A4262C" : ""}}
          >
            {item.name}
          </ActionButton>
        );
      },
    },
    {
      key: 'column7',
      name: t('documents.email'),
      fieldName: 'emailReceiver',
      minWidth: 80,
      maxWidth: 200,
      isRowHeader: true,
      isResizable: true,
      isSorted: primarySort === 'emailReceiver' ? true : false,
      isSortedDescending: isSortedDescendingEmailReceiver,
      isSortedAscending: !isSortedDescendingEmailReceiver,
      onColumnClick: () => {
        console.log('Sort emailReceiver!');
        setPrimarySort('emailReceiver');
        setIsSortedDescendingEmailReceiver(!isSortedDescendingEmailReceiver);

        // handleSort('emailReceiver', !isSortedDescendingEmailReceiver);

        setLoadDataComplete(false);
        setItems([]);
        setGetNewDocuments(true);

        setTotalDocuments(0);
        setTotalVat(0);
        setTotalGrand(0);

        sessionStorage.setItem('primarySort', 'emailReceiver');
        sessionStorage.setItem('sort-emailReceiver', !isSortedDescendingEmailReceiver === true ? 'true' : 'false');

        sessionStorage.setItem('sort-createdTime', 'false');
        sessionStorage.setItem('sort-number', 'false');
        sessionStorage.setItem('sort-customerName', 'false');
        sessionStorage.setItem('sort-documentDate', 'false');
        sessionStorage.setItem('sort-vatTotal', 'false');
        sessionStorage.setItem('sort-grandTotal', 'false');
        sessionStorage.setItem('sort-name', 'false');
        sessionStorage.setItem('sort-emailTime', 'false');

        if (!isSortedDescendingEmailReceiver) {
            setSortDirection('DESC');
            sessionStorage.setItem('sortDirection', 'DESC');
        } else {
            setSortDirection('ASC');
            sessionStorage.setItem('sortDirection', 'ASC');
        }

      },
      data: 'string',
      onRender: (item) => {
        return <span style={{ color: deletedDocumentsOnly ? "#A4262C" : ""}}>{item.emailReceiver}</span>;
      },
    },
    ...(enableSms
      ? [
          {
            key: 'column8',
            name: t('documents.sendingDate'),
            fieldName: 'smsTime',
            minWidth: 90,
            maxWidth: 125,
            isRowHeader: true,
            isResizable: true,
            isSorted: primarySort === 'emailTime' ? true : false,
            isSortedDescending: isSortedDescendingEmailTime,
            isSortedAscending: !isSortedDescendingEmailTime,
            onColumnClick: () => {
              console.log('Sort emailTime!');
              setPrimarySort('emailTime');
              setIsSortedDescendingEmailTime(!isSortedDescendingEmailTime);

            //   handleSort('emailTime', !isSortedDescendingEmailTime);

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalGrand(0);

              sessionStorage.setItem('primarySort', 'emailTime');
              sessionStorage.setItem('sort-emailTime', !isSortedDescendingEmailTime === true ? 'true' : 'false');

              sessionStorage.setItem('sort-number', 'false');
              sessionStorage.setItem('sort-documentDate', 'false');
              sessionStorage.setItem('sort-customerName', 'false');
              sessionStorage.setItem('sort-customerPhone', 'false');
              sessionStorage.setItem('sort-vatTotal', 'false');
              sessionStorage.setItem('sort-grandTotal', 'false');
              sessionStorage.setItem('sort-name', 'false');
              sessionStorage.setItem('sort-emailReceiver', 'false');

              if (!isSortedDescendingEmailTime) {
                setSortDirection('DESC');
                sessionStorage.setItem('sortDirection', 'DESC');
              } else {
                setSortDirection('ASC');
                sessionStorage.setItem('sortDirection', 'ASC');
              }

            },
            data: 'string',
            onRender: (item) => {
              if (isProcessingSms) {
                if (item.smsTime === 'processing') {
                  return <Spinner label={t('documents.sendingSms')} labelPosition="right" style={{ marginLeft: '0px' }} />;
                } else if (item.smsTime === 'successful') {
                  return <span style={{ color: 'green' }}>{t('documents.success')}</span>;
                } else if (item.smsTime === 'Phone Number not found') {
                  return <span style={{ color: 'red' }}>{t('documents.phoneNotFound')}</span>;
                } else if (item.smsTime === 'fail') {
                  return <span style={{ color: 'red' }}>{t('documents.fail')}</span>;
                }
              } else if (item.smsTime === 'successful') {
                return <span style={{ color: 'green' }}>{t('documents.success')}</span>;
              } else if (item.smsTime === 'Phone Number not found') {
                return <span style={{ color: 'red' }}>{t('documents.phoneNotFound')}</span>;
              } else if (item.smsTime === 'fail') {
                return <span style={{ color: 'red' }}>{t('documents.fail')}</span>;
              }
              if (isProcessing) {
                if (item.emailTime === 'processing') {
                  return <Spinner label={t('documents.sendingEmail')} labelPosition="right" style={{ marginLeft: '0px' }} />;
                } else if (item.emailTime === 'successful') {
                  return <span style={{ color: 'green' }}>{t('documents.success')}</span>;
                } else if (item.emailTime === 'email not found') {
                  return <span style={{ color: 'red' }}>{t('documents.emailNotFound')}</span>;
                } else if (item.emailTime === 'fail') {
                  return <span style={{ color: 'red' }}>{t('documents.fail')}</span>;
                }
              } else if (item.emailTime === 'successful') {
                return <span style={{ color: 'green' }}>{t('documents.success')}</span>;
              } else if (item.emailTime === 'email not found') {
                return <span style={{ color: 'red' }}>{t('documents.emailNotFound')}</span>;
              } else if (item.emailTime === 'fail') {
                return <span style={{ color: 'red' }}>{t('documents.fail')}</span>;
              }
              return (
                <Stack horizontal>
                  {!['successful', 'email not found', 'fail', ''].includes(item.emailTime) && (
                    <TooltipHost content={`${t("documents.emailSentDate")} ${item.emailTime}`}>
                      <Icon iconName="MailForward" style={{ fontSize: '25px', color: '#0078D4', marginRight: 10, marginTop: -3 }} />
                    </TooltipHost>
                  )}
                  {!['successful', 'Phone Number not found', 'fail', ''].includes(item.smsTime) && (
                    <TooltipHost content={`${t("documents.smsSentDate")} ${item.smsTime}`}>
                      <Icon iconName="Message" style={{ fontSize: '20px', color: '#0078D4' }} />
                    </TooltipHost>
                  )}
                </Stack>
              );
            },
          },
        ]
      : [
          {
            key: 'column8',
            name: t('documents.emailDate'),
            fieldName: 'emailTime',
            minWidth: 90,
            maxWidth: 125,
            isRowHeader: true,
            isResizable: true,
            isSorted: primarySort === 'emailTime' ? true : false,
            isSortedDescending: isSortedDescendingEmailTime,
            isSortedAscending: !isSortedDescendingEmailTime,
            onColumnClick: () => {
              console.log('Sort emailTime!');
              setPrimarySort('emailTime');
              setIsSortedDescendingEmailTime(!isSortedDescendingEmailTime);

            //   handleSort('emailTime', !isSortedDescendingEmailTime);
            
              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalGrand(0);

              sessionStorage.setItem('primarySort', 'emailTime');
              sessionStorage.setItem('sort-emailTime', !isSortedDescendingEmailTime === true ? 'true' : 'false');

              sessionStorage.setItem('sort-number', 'false');
              sessionStorage.setItem('sort-documentDate', 'false');
              sessionStorage.setItem('sort-customerName', 'false');
              sessionStorage.setItem('sort-customerPhone', 'false');
              sessionStorage.setItem('sort-vatTotal', 'false');
              sessionStorage.setItem('sort-grandTotal', 'false');
              sessionStorage.setItem('sort-name', 'false');
              sessionStorage.setItem('sort-emailReceiver', 'false');

              if (!isSortedDescendingEmailTime) {
                setSortDirection('DESC');
                sessionStorage.setItem('sortDirection', 'DESC');
              } else {
                setSortDirection('ASC');
                sessionStorage.setItem('sortDirection', 'ASC');
              }

            },
            data: 'string',
            onRender: (item) => {
              if (item.emailTime === 'processing') {
                return <Spinner label={t('documents.sendingEmail')} labelPosition="right" style={{ marginLeft: '0px' }} />;
              } else if (item.emailTime === 'successful') {
                return <span style={{ color: 'green' }}>{t('documents.success')}</span>;
              } else if (item.emailTime === 'email not found') {
                return <span style={{ color: 'red' }}>{t('documents.emailNotFound')}</span>;
              } else if (item.emailTime === 'fail') {
                return <span style={{ color: 'red' }}>{t('documents.fail')}</span>;
              } else {
                return <span style={{ color: deletedDocumentsOnly ? "#A4262C" : ""}}>{item.emailTime}</span>;
              }
            },
          },
        ]),
    {
      key: 'column9',
      name: t('documents.file'),
      fieldName: 'file',
      minWidth: 100,
      maxWidth: 100,
      onColumnClick: '',

      onRender: (item) => (
        <Stack horizontal>
          <IconButton
            onClick={() => {
              openFile(item);
            }}
            iconProps={{
              ...getFileTypeIconProps({
                extension: item.fileExtension,
                size: 32,
                imageFileType: 'svg',
              }),
            }}
          />
          <IconButton
            onClick={() => {
              downloadPNG(item);
            }}
            iconProps={{
              ...getFileTypeIconProps({
                // extension: '.jpg',
                size: 32,
                imageFileType: 'svg',
              }),
            }}
          />
          {item.isPdfXml && (
            <IconButton
              onClick={() => openFile_xml(item)}
              iconProps={{
                ...getFileTypeIconProps({
                  extension: '.cpp',
                  size: 32,
                  imageFileType: 'svg',
                }),
              }}
            />
          )}
        </Stack>
      ),
    },
    {
      key: 'column10',
      name: '',
      fieldName: 'command',
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      data: 'string',
      onColumnClick: '',
      onRender: (item) => {
        let options_etax = [
          {
            key: 'open',
            text: t('documents.openDocument'),
            iconProps: { iconName: 'OpenFile' },
            onClick: () => {
              openFile_pdf(item);
            },
          },
          {
            key: 'download',
            text: t('documents.download'),
            iconProps: { iconName: 'DownloadDocument' },
            onClick: () => {
              download(item);
            },
          },
          {
            key: 'divider_1',
            itemType: ContextualMenuItemType.Divider,
          },
          {
            key: 'email',
            text: t('documents.email'),
            iconProps: { iconName: 'MailForward' },
            onClick: () => {
              console.log('isEmailSetting:', isEmailSetting);

              if (isEmailSetting) {
                setIsClickEmail(true);
                setFileNameEmail(item.name);
                setFileDisplayName(item.pdfFileName);
                setCustomerEmail(item.customerEmail);
                setEmailList(item.customerEmail ? [item.customerEmail] : []);
                setCopyEmailList(item.companyEmail ? [item.companyEmail] : [])
                setCopyEmail(item.companyEmail);
                setFileIdEmail(item.key);
              } else {
                history.push({
                  pathname: '/settings/emails',
                  state: {},
                });
              }
            },
          },
          ...(enableSms ? [{
            key: 'sms',
            text: 'SMS',
            iconProps: { iconName: 'Message' },
            onClick: () => {
              setIsClickSms(true);
              setFileNameSms(item.name);
              setCustomerPhone(item.customerPhone);
              setFileIdSms(item.key);
            },
          },] : []),
          {
            key: 'divider_2',
            itemType: ContextualMenuItemType.Divider,
          },
          {
            key: 'reCreate',
            text: t('documents.reCreateDocument'),
            iconProps: { iconName: 'PageAdd' },
            onClick: () => {
              reCreateDocument(item.type, item);
            },
          },
          {
            key: 'reIssue',
            text: t('documents.reIssuedDocument'),
            iconProps: { iconName: 'PageAdd' },
            onClick: () => {
              reIssueDocument(item);
            },
          },
          {
            key: 'RECEIPT-TAXINVOICE',
            text: t('documents.create') + ' ' + t('documents.receipt-taxinvoice'),
            iconProps: { iconName: 'PageAdd' },
            onClick: () => {
              reCreateDocument('RECEIPT-TAXINVOICE', item);
            },
          },
          {
            key: 'RECEIPT-TAXINVOICE-ABB',
            text: t('documents.create') + ' ' + t('documents.receipt-taxinvoice-abb'),
            iconProps: { iconName: 'PageAdd' },
            onClick: () => {
              reCreateDocument('RECEIPT-TAXINVOICE-ABB', item);
            },
          },
          {
            key: 'RECEIPT',
            text: t('documents.create') + ' ' + t('documents.receipt'),
            iconProps: { iconName: 'PageAdd' },
            onClick: () => {
              reCreateDocument('RECEIPT', item);
            },
          },
          {
            key: 'INVOICE-TAXINVOICE',
            text: t('documents.create') + ' ' + t('documents.invoice-taxinvoice'),
            iconProps: { iconName: 'PageAdd' },
            onClick: () => {
              reCreateDocument('INVOICE-TAXINVOICE', item);
            },
          },
          {
            key: 'DELIVERYORDER-TAXINVOICE',
            text: t('documents.create') + ' ' + t('documents.deliveryorder-taxinvoice'),
            iconProps: { iconName: 'PageAdd' },
            onClick: () => {
              reCreateDocument('DELIVERYORDER-TAXINVOICE', item);
            },
          },
          {
            key: 'TAXINVOICE',
            text: t('documents.create') + ' ' + t('documents.taxinvoice'),
            iconProps: { iconName: 'PageAdd' },
            onClick: () => {
              reCreateDocument('TAXINVOICE', item);
            },
          },
          {
            key: 'DEBIT-NOTE',
            text: t('documents.create') + ' ' + t('documents.debit-note'),
            iconProps: { iconName: 'PageAdd' },
            onClick: () => {
              reCreateCreditAndDebit('DEBIT-NOTE', item);
            },
          },
          {
            key: 'CREDIT-NOTE',
            text: t('documents.create') + ' ' + t('documents.credit-note'),
            iconProps: { iconName: 'PageAdd' },
            onClick: () => {
              reCreateCreditAndDebit('CREDIT-NOTE', item);
            },
          },
          {
            key: 'divider_3',
            itemType: ContextualMenuItemType.Divider,
          },
          {
            key: 'QUOTATION',
            text: t('documents.create') + ' ' + t('documents.quotation'),
            iconProps: { iconName: 'PageAdd' },
            onClick: () => {
              reCreateDocument('QUOTATION', item);
            },
          },
          {
            key: 'INVOICE',
            text: t('documents.create') + ' ' + t('documents.invoice'),
            iconProps: { iconName: 'PageAdd' },
            onClick: () => {
              reCreateDocument('INVOICE', item);
            },
          },
          {
            key: 'BILLING-NOTE',
            text: t('documents.create') + ' ' + t('documents.billing-note'),
            iconProps: { iconName: 'PageAdd' },
            onClick: () => {
              reCreateDocument('BILLING-NOTE', item);
            },
          },
          {
            key: 'DELIVERYORDER',
            text: t('documents.create') + ' ' + t('documents.deliveryorder'),
            iconProps: { iconName: 'PageAdd' },
            onClick: () => {
              reCreateDocument('DELIVERYORDER', item);
            },
          },
          {
            key: 'divider_4',
            itemType: ContextualMenuItemType.Divider,
          },
          ((item.rdStatus !== 'successful') && (item.rdStatus !== 'sp-pending'))
            ? {
                key: 'delete',
                text: t('documents.voidDocument'),
                iconProps: { iconName: 'Delete' },
                onClick: () => {
                  let _itemType = item.name;
                  if (i18n.language === 'en') {
                    if (item.type === 'RECEIPT-TAXINVOICE') _itemType = 'Receipt/Tax Invoice';
                    if (item.type === 'RECEIPT-TAXINVOICE-ABB') _itemType = 'Receipt/Tax Invoice Abb';
                    if (item.type === 'RECEIPT') _itemType = 'Receipt';
                    if (item.type === 'INVOICE-TAXINVOICE') _itemType = 'Invoice/Tax Invoice';
                    if (item.type === 'DELIVERYORDER-TAXINVOICE') _itemType = 'Delivery Order/Tax Invoice';
                    if (item.type === 'TAXINVOICE') _itemType = 'Tax Invoice';
                    if (item.type === 'DEBIT-NOTE') _itemType = 'Debit Note';
                    if (item.type === 'CREDIT-NOTE') _itemType = 'Credit Note';
                    if (item.type === 'QUOTATION') _itemType = 'Quotation';
                    if (item.type === 'INVOICE') _itemType = 'Invoice';
                    if (item.type === 'BILLING-NOTE') _itemType = 'Billing Note';
                    if (item.type === 'DELIVERYORDER') _itemType = 'Delivery Order';
                  }

                  setDialogConfirm((prev) => ({
                    ...prev,
                    isClick: true,
                    header: t('documents.deleteConfirmHeader'),
                    message: <div>
                      <p>{`${t('documents.deleteConfirm1')}${_itemType}${t('documents.deleteConfirm2')}${item.number}${t(
                      'documents.deleteConfirm3')}`}</p>
                      <p>{`${t('documents.voidDescription')}`}</p>
                    </div>,
                    onConfirm: () => {
                      setDialogConfirm((prev) => ({
                        ...prev,
                        isClick: false,
                      }));
                      deleteFile(item);
                    },
                  }));
                },
              }
            : {
                key: 'delete',
                text: t('documents.deleteDisable'),
                iconProps: { iconName: 'Delete' },
                disabled: true,
                onClick: () => {
                  let _itemType = item.name;
                  if (i18n.language === 'en') {
                    if (item.type === 'RECEIPT-TAXINVOICE') _itemType = 'Receipt/Tax Invoice';
                    if (item.type === 'RECEIPT-TAXINVOICE-ABB') _itemType = 'Receipt/Tax Invoice Abb';
                    if (item.type === 'RECEIPT') _itemType = 'Receipt';
                    if (item.type === 'INVOICE-TAXINVOICE') _itemType = 'Invoice/Tax Invoice';
                    if (item.type === 'DELIVERYORDER-TAXINVOICE') _itemType = 'Delivery Order/Tax Invoice';
                    if (item.type === 'TAXINVOICE') _itemType = 'Tax Invoice';
                    if (item.type === 'DEBIT-NOTE') _itemType = 'Debit Note';
                    if (item.type === 'CREDIT-NOTE') _itemType = 'Credit Note';
                    if (item.type === 'QUOTATION') _itemType = 'Quotation';
                    if (item.type === 'INVOICE') _itemType = 'Invoice';
                    if (item.type === 'BILLING-NOTE') _itemType = 'Billing Note';
                    if (item.type === 'DELIVERYORDER') _itemType = 'Delivery Order';
                  }

                  setDialogConfirm((prev) => ({
                    ...prev,
                    isClick: true,
                    header: t('documents.deleteConfirmHeader'),
                    message: <div>
                      <p>{`${t('documents.deleteConfirm1')}${_itemType}${t('documents.deleteConfirm2')}${item.number}${t(
                      'documents.deleteConfirm3')}`}</p>
                      <p>{`${t('documents.voidDescription')}`}</p>
                    </div>,
                    onConfirm: () => {
                      setDialogConfirm((prev) => ({
                        ...prev,
                        isClick: false,
                      }));
                      deleteFile(item);
                    },
                  }));
                },
              },
        ];

        let options_credit_debit = [
          {
            key: 'open',
            text: t('documents.openDocument'),
            iconProps: { iconName: 'OpenFile' },
            onClick: () => {
              openFile_pdf(item);
            },
          },
          {
            key: 'download',
            text: t('documents.download'),
            iconProps: { iconName: 'DownloadDocument' },
            onClick: () => {
              download(item);
            },
          },
          {
            key: 'divider_1',
            itemType: ContextualMenuItemType.Divider,
          },
          {
            key: 'email',
            text: t('documents.email'),
            iconProps: { iconName: 'MailForward' },
            onClick: () => {
              console.log('isEmailSetting:', isEmailSetting);

              if (isEmailSetting) {
                setIsClickEmail(true);
                setFileNameEmail(item.name);
                setFileDisplayName(item.pdfFileName);
                setCustomerEmail(item.customerEmail);
                setEmailList(item.customerEmail ? [item.customerEmail] : []);
                setCopyEmailList(item.companyEmail ? [item.companyEmail] : [])
                setCopyEmail(item.companyEmail);
                setFileIdEmail(item.key);
              } else {
                history.push({
                  pathname: '/settings/emails',
                  state: {},
                });
              }
            },
          },
          ...(enableSms ? [{
            key: 'sms',
            text: 'SMS',
            iconProps: { iconName: 'Message' },
            onClick: () => {
              setIsClickSms(true);
              setFileNameSms(item.name);
              setCustomerPhone(item.customerPhone);
              setFileIdSms(item.key);
            },
          },] : []),
          {
            key: 'divider_2',
            itemType: ContextualMenuItemType.Divider,
          },
          item.rdStatus !== 'successful'
            ? {
                key: 'delete',
                text: t('documents.voidDocument'),
                iconProps: { iconName: 'Delete' },
                onClick: () => {
                  let _itemType = item.name;
                  if (i18n.language === 'en') {
                    if (item.type === 'RECEIPT-TAXINVOICE') _itemType = 'Receipt/Tax Invoice';
                    if (item.type === 'RECEIPT-TAXINVOICE-ABB') _itemType = 'Receipt/Tax Invoice Abb';
                    if (item.type === 'RECEIPT') _itemType = 'Receipt';
                    if (item.type === 'INVOICE-TAXINVOICE') _itemType = 'Invoice/Tax Invoice';
                    if (item.type === 'DELIVERYORDER-TAXINVOICE') _itemType = 'Delivery Order/Tax Invoice';
                    if (item.type === 'TAXINVOICE') _itemType = 'Tax Invoice';
                    if (item.type === 'DEBIT-NOTE') _itemType = 'Debit Note';
                    if (item.type === 'CREDIT-NOTE') _itemType = 'Credit Note';
                    if (item.type === 'QUOTATION') _itemType = 'Quotation';
                    if (item.type === 'INVOICE') _itemType = 'Invoice';
                    if (item.type === 'BILLING-NOTE') _itemType = 'Billing Note';
                    if (item.type === 'DELIVERYORDER') _itemType = 'Delivery Order';
                  }

                  setDialogConfirm((prev) => ({
                    ...prev,
                    isClick: true,
                    header: t('documents.deleteConfirmHeader'),
                    message: <div>
                      <p>{`${t('documents.deleteConfirm1')}${_itemType}${t('documents.deleteConfirm2')}${item.number}${t(
                      'documents.deleteConfirm3')}`}</p>
                      <p>{`${t('documents.voidDescription')}`}</p>
                    </div>,
                    onConfirm: () => {
                      setDialogConfirm((prev) => ({
                        ...prev,
                        isClick: false,
                      }));
                      deleteFile(item);
                    },
                  }));
                },
              }
            : {
                key: 'delete',
                text: t('documents.deleteDisable'),
                iconProps: { iconName: 'Delete' },
                disabled: true,
                onClick: () => {
                  let _itemType = item.name;
                  if (i18n.language === 'en') {
                    if (item.type === 'RECEIPT-TAXINVOICE') _itemType = 'Receipt/Tax Invoice';
                    if (item.type === 'RECEIPT-TAXINVOICE-ABB') _itemType = 'Receipt/Tax Invoice Abb';
                    if (item.type === 'RECEIPT') _itemType = 'Receipt';
                    if (item.type === 'INVOICE-TAXINVOICE') _itemType = 'Invoice/Tax Invoice';
                    if (item.type === 'DELIVERYORDER-TAXINVOICE') _itemType = 'Delivery Order/Tax Invoice';
                    if (item.type === 'TAXINVOICE') _itemType = 'Tax Invoice';
                    if (item.type === 'DEBIT-NOTE') _itemType = 'Debit Note';
                    if (item.type === 'CREDIT-NOTE') _itemType = 'Credit Note';
                    if (item.type === 'QUOTATION') _itemType = 'Quotation';
                    if (item.type === 'INVOICE') _itemType = 'Invoice';
                    if (item.type === 'BILLING-NOTE') _itemType = 'Billing Note';
                    if (item.type === 'DELIVERYORDER') _itemType = 'Delivery Order';
                  }

                  setDialogConfirm((prev) => ({
                    ...prev,
                    isClick: true,
                    header: t('documents.deleteConfirmHeader'),
                    message: <div>
                      <p>{`${t('documents.deleteConfirm1')}${_itemType}${t('documents.deleteConfirm2')}${item.number}${t(
                      'documents.deleteConfirm3')}`}</p>
                      <p>{`${t('documents.voidDescription')}`}</p>
                    </div>,
                    onConfirm: () => {
                      setDialogConfirm((prev) => ({
                        ...prev,
                        isClick: false,
                      }));
                      deleteFile(item);
                    },
                  }));
                },
              },
        ];

        let options_document = [
          {
            key: 'open',
            text: t('documents.openDocument'),
            iconProps: { iconName: 'OpenFile' },
            onClick: () => {
              openFile_pdf(item);
            },
          },
          {
            key: 'download',
            text: t('documents.download'),
            iconProps: { iconName: 'DownloadDocument' },
            onClick: () => {
              download(item);
            },
          },
          {
            key: 'divider_1',
            itemType: ContextualMenuItemType.Divider,
          },
          {
            key: 'email',
            text: t('documents.email'),
            iconProps: { iconName: 'MailForward' },
            onClick: () => {
              console.log('isEmailSetting:', isEmailSetting);

              if (isEmailSetting) {
                setIsClickEmail(true);
                setFileNameEmail(item.name);
                setFileDisplayName(item.pdfFileName);
                setCustomerEmail(item.customerEmail);
                setEmailList(item.customerEmail ? [item.customerEmail] : []);
                setCopyEmailList(item.companyEmail ? [item.companyEmail] : [])
                setCopyEmail(item.companyEmail);
                setFileIdEmail(item.key);
              } else {
                history.push({
                  pathname: '/settings/emails',
                  state: {},
                });
              }
            },
          },
          ...(enableSms ? [{
            key: 'sms',
            text: 'SMS',
            iconProps: { iconName: 'Message' },
            onClick: () => {
              setIsClickSms(true);
              setFileNameSms(item.name);
              setCustomerPhone(item.customerPhone);
              setFileIdSms(item.key);
            },
          },] : []),
          {
            key: 'divider_2',
            itemType: ContextualMenuItemType.Divider,
          },
          {
            key: 'RECEIPT-TAXINVOICE',
            text: t('documents.create') + ' ' + t('documents.receipt-taxinvoice'),
            iconProps: { iconName: 'PageAdd' },
            onClick: () => {
              reCreateDocument('RECEIPT-TAXINVOICE', item);
            },
          },
          {
            key: 'RECEIPT-TAXINVOICE-ABB',
            text: t('documents.create') + ' ' + t('documents.receipt-taxinvoice-abb'),
            iconProps: { iconName: 'PageAdd' },
            onClick: () => {
              reCreateDocument('RECEIPT-TAXINVOICE-ABB', item);
            },
          },
          {
            key: 'RECEIPT',
            text: t('documents.create') + ' ' + t('documents.receipt'),
            iconProps: { iconName: 'PageAdd' },
            onClick: () => {
              reCreateDocument('RECEIPT', item);
            },
          },
          {
            key: 'INVOICE-TAXINVOICE',
            text: t('documents.create') + ' ' + t('documents.invoice-taxinvoice'),
            iconProps: { iconName: 'PageAdd' },
            onClick: () => {
              reCreateDocument('INVOICE-TAXINVOICE', item);
            },
          },
          {
            key: 'DELIVERYORDER-TAXINVOICE',
            text: t('documents.create') + ' ' + t('documents.deliveryorder-taxinvoice'),
            iconProps: { iconName: 'PageAdd' },
            onClick: () => {
              reCreateDocument('DELIVERYORDER-TAXINVOICE', item);
            },
          },
          {
            key: 'TAXINVOICE',
            text: t('documents.create') + ' ' + t('documents.taxinvoice'),
            iconProps: { iconName: 'PageAdd' },
            onClick: () => {
              reCreateDocument('TAXINVOICE', item);
            },
          },
          {
            key: 'divider_3',
            itemType: ContextualMenuItemType.Divider,
          },
          {
            key: 'reCreate',
            text: t('documents.reCreateDocument'),
            iconProps: { iconName: 'PageAdd' },
            onClick: () => {
              reCreateDocument(item.type, item);
            },
          },
          {
            key: 'QUOTATION',
            text: t('documents.create') + ' ' + t('documents.quotation'),
            iconProps: { iconName: 'PageAdd' },
            onClick: () => {
              reCreateDocument('QUOTATION', item);
            },
          },
          {
            key: 'INVOICE',
            text: t('documents.create') + ' ' + t('documents.invoice'),
            iconProps: { iconName: 'PageAdd' },
            onClick: () => {
              reCreateDocument('INVOICE', item);
            },
          },
          {
            key: 'BILLING-NOTE',
            text: t('documents.create') + ' ' + t('documents.billing-note'),
            iconProps: { iconName: 'PageAdd' },
            onClick: () => {
              reCreateDocument('BILLING-NOTE', item);
            },
          },
          {
            key: 'DELIVERYORDER',
            text: t('documents.create') + ' ' + t('documents.deliveryorder'),
            iconProps: { iconName: 'PageAdd' },
            onClick: () => {
              reCreateDocument('DELIVERYORDER', item);
            },
          },
          {
            key: 'divider_4',
            itemType: ContextualMenuItemType.Divider,
          },
          !item.rdStatus
            ? {
                key: 'delete',
                text: t('documents.voidDocument'),
                iconProps: { iconName: 'Delete' },
                onClick: () => {
                  let _itemType = item.name;
                  if (i18n.language === 'en') {
                    if (item.type === 'RECEIPT-TAXINVOICE') _itemType = 'Receipt/Tax Invoice';
                    if (item.type === 'RECEIPT-TAXINVOICE-ABB') _itemType = 'Receipt/Tax Invoice Abb';
                    if (item.type === 'RECEIPT') _itemType = 'Receipt';
                    if (item.type === 'INVOICE-TAXINVOICE') _itemType = 'Invoice/Tax Invoice';
                    if (item.type === 'DELIVERYORDER-TAXINVOICE') _itemType = 'Delivery Order/Tax Invoice';
                    if (item.type === 'TAXINVOICE') _itemType = 'Tax Invoice';
                    if (item.type === 'DEBIT-NOTE') _itemType = 'Debit Note';
                    if (item.type === 'CREDIT-NOTE') _itemType = 'Credit Note';
                    if (item.type === 'QUOTATION') _itemType = 'Quotation';
                    if (item.type === 'INVOICE') _itemType = 'Invoice';
                    if (item.type === 'BILLING-NOTE') _itemType = 'Billing Note';
                    if (item.type === 'DELIVERYORDER') _itemType = 'Delivery Order';
                  }

                  setDialogConfirm((prev) => ({
                    ...prev,
                    isClick: true,
                    header: t('documents.deleteConfirmHeader'),
                    message: <div>
                      <p>{`${t('documents.deleteConfirm1')}${_itemType}${t('documents.deleteConfirm2')}${item.number}${t(
                      'documents.deleteConfirm3')}`}</p>
                      <p>{`${t('documents.voidDescription')}`}</p>
                    </div>,
                    onConfirm: () => {
                      setDialogConfirm((prev) => ({
                        ...prev,
                        isClick: false,
                      }));
                      deleteFile(item);
                    },
                  }));
                },
              }
            : {
                key: 'delete',
                text: t('documents.voidDocument'),
                iconProps: { iconName: 'Delete' },
                disabled: true,
                onClick: () => {
                  let _itemType = item.name;
                  if (i18n.language === 'en') {
                    if (item.type === 'RECEIPT-TAXINVOICE') _itemType = 'Receipt/Tax Invoice';
                    if (item.type === 'RECEIPT-TAXINVOICE-ABB') _itemType = 'Receipt/Tax Invoice Abb';
                    if (item.type === 'RECEIPT') _itemType = 'Receipt';
                    if (item.type === 'INVOICE-TAXINVOICE') _itemType = 'Invoice/Tax Invoice';
                    if (item.type === 'DELIVERYORDER-TAXINVOICE') _itemType = 'Delivery Order/Tax Invoice';
                    if (item.type === 'TAXINVOICE') _itemType = 'Tax Invoice';
                    if (item.type === 'DEBIT-NOTE') _itemType = 'Debit Note';
                    if (item.type === 'CREDIT-NOTE') _itemType = 'Credit Note';
                    if (item.type === 'QUOTATION') _itemType = 'Quotation';
                    if (item.type === 'INVOICE') _itemType = 'Invoice';
                    if (item.type === 'BILLING-NOTE') _itemType = 'Billing Note';
                    if (item.type === 'DELIVERYORDER') _itemType = 'Delivery Order';
                  }

                  setDialogConfirm((prev) => ({
                    ...prev,
                    isClick: true,
                    header: t('documents.deleteConfirmHeader'),
                    message: <div>
                      <p>{`${t('documents.deleteConfirm1')}${_itemType}${t('documents.deleteConfirm2')}${item.number}${t(
                      'documents.deleteConfirm3')}`}</p>
                      <p>{`${t('documents.voidDescription')}`}</p>
                    </div>,
                    onConfirm: () => {
                      setDialogConfirm((prev) => ({
                        ...prev,
                        isClick: false,
                      }));
                      deleteFile(item);
                    },
                  }));
                },
              },
        ];

        let options_ABB = [
          {
            key: 'open',
            text: t('documents.openDocument'),
            iconProps: { iconName: 'OpenFile' },
            onClick: () => {
              openFile_pdf(item);
            },
          },
          {
            key: 'download',
            text: t('documents.download'),
            iconProps: { iconName: 'DownloadDocument' },
            onClick: () => {
              download(item);
            },
          },
          {
            key: 'divider_1',
            itemType: ContextualMenuItemType.Divider,
          },
          {
            key: 'email',
            text: t('documents.email'),
            iconProps: { iconName: 'MailForward' },
            onClick: () => {              
              console.log('isEmailSetting:', isEmailSetting);

              if (isEmailSetting) {
                setIsClickEmail(true);
                setFileNameEmail(item.name);
                setFileDisplayName(item.pdfFileName);
                setCustomerEmail(item.customerEmail);
                setEmailList(item.customerEmail ? [item.customerEmail] : []);
                setCopyEmailList(item.companyEmail ? [item.companyEmail] : [])
                setCopyEmail(item.companyEmail);
                setFileIdEmail(item.key);
              } else {
                history.push({
                  pathname: '/settings/emails',
                  state: {},
                });
              }
            },
          },
          ...(enableSms ? [{
            key: 'sms',
            text: 'SMS',
            iconProps: { iconName: 'Message' },
            onClick: () => {
              setIsClickSms(true);
              setFileNameSms(item.name);
              setCustomerPhone(item.customerPhone);
              setFileIdSms(item.key);
            },
          },] : []),
          {
            key: 'divider_2',
            itemType: ContextualMenuItemType.Divider,
          },
          {
            key: 'reCreate',
            text: t('documents.reCreateDocument'),
            iconProps: { iconName: 'PageAdd' },
            onClick: () => {
              reCreateDocument(item.type, item);
            },
          },
          {
            key: 'reIssue',
            text: t('documents.reIssuedDocument'),
            iconProps: { iconName: 'PageAdd' },
            onClick: () => {
              reIssueDocument(item);
            },
          },
          {
            key: 'DEBIT-NOTE',
            text: t('documents.create') + ' ' + t('documents.debit-note'),
            iconProps: { iconName: 'PageAdd' },
            onClick: () => {
              reCreateCreditAndDebit('DEBIT-NOTE', item);
            },
          },
          {
            key: 'CREDIT-NOTE',
            text: t('documents.create') + ' ' + t('documents.credit-note'),
            iconProps: { iconName: 'PageAdd' },
            onClick: () => {
              reCreateCreditAndDebit('CREDIT-NOTE', item);
            },
          },
          {
            key: 'divider_3',
            itemType: ContextualMenuItemType.Divider,
          },
          !item.rdStatus
            ? {
                key: 'delete',
                text: t('documents.voidDocument'),
                iconProps: { iconName: 'Delete' },
                onClick: () => {
                  let _itemType = item.name;
                  if (i18n.language === 'en') {
                    if (item.type === 'RECEIPT-TAXINVOICE') _itemType = 'Receipt/Tax Invoice';
                    if (item.type === 'RECEIPT-TAXINVOICE-ABB') _itemType = 'Receipt/Tax Invoice Abb';
                    if (item.type === 'RECEIPT') _itemType = 'Receipt';
                    if (item.type === 'INVOICE-TAXINVOICE') _itemType = 'Invoice/Tax Invoice';
                    if (item.type === 'DELIVERYORDER-TAXINVOICE') _itemType = 'Delivery Order/Tax Invoice';
                    if (item.type === 'TAXINVOICE') _itemType = 'Tax Invoice';
                    if (item.type === 'DEBIT-NOTE') _itemType = 'Debit Note';
                    if (item.type === 'CREDIT-NOTE') _itemType = 'Credit Note';
                    if (item.type === 'QUOTATION') _itemType = 'Quotation';
                    if (item.type === 'INVOICE') _itemType = 'Invoice';
                    if (item.type === 'BILLING-NOTE') _itemType = 'Billing Note';
                    if (item.type === 'DELIVERYORDER') _itemType = 'Delivery Order';
                  }

                  setDialogConfirm((prev) => ({
                    ...prev,
                    isClick: true,
                    header: t('documents.deleteConfirmHeader'),
                    message: <div>
                      <p>{`${t('documents.deleteConfirm1')}${_itemType}${t('documents.deleteConfirm2')}${item.number}${t(
                      'documents.deleteConfirm3')}`}</p>
                      <p>{`${t('documents.voidDescription')}`}</p>
                    </div>,
                    onConfirm: () => {
                      setDialogConfirm((prev) => ({
                        ...prev,
                        isClick: false,
                      }));
                      deleteFile(item);
                    },
                  }));
                },
              }
            : {
                key: 'delete',
                text: t('documents.voidDocument'),
                iconProps: { iconName: 'Delete' },
                disabled: true,
                onClick: () => {
                  let _itemType = item.name;
                  if (i18n.language === 'en') {
                    if (item.type === 'RECEIPT-TAXINVOICE') _itemType = 'Receipt/Tax Invoice';
                    if (item.type === 'RECEIPT-TAXINVOICE-ABB') _itemType = 'Receipt/Tax Invoice Abb';
                    if (item.type === 'RECEIPT') _itemType = 'Receipt';
                    if (item.type === 'INVOICE-TAXINVOICE') _itemType = 'Invoice/Tax Invoice';
                    if (item.type === 'DELIVERYORDER-TAXINVOICE') _itemType = 'Delivery Order/Tax Invoice';
                    if (item.type === 'TAXINVOICE') _itemType = 'Tax Invoice';
                    if (item.type === 'DEBIT-NOTE') _itemType = 'Debit Note';
                    if (item.type === 'CREDIT-NOTE') _itemType = 'Credit Note';
                    if (item.type === 'QUOTATION') _itemType = 'Quotation';
                    if (item.type === 'INVOICE') _itemType = 'Invoice';
                    if (item.type === 'BILLING-NOTE') _itemType = 'Billing Note';
                    if (item.type === 'DELIVERYORDER') _itemType = 'Delivery Order';
                  }

                  setDialogConfirm((prev) => ({
                    ...prev,
                    isClick: true,
                    header: t('documents.deleteConfirmHeader'),
                    message: <div>
                      <p>{`${t('documents.deleteConfirm1')}${_itemType}${t('documents.deleteConfirm2')}${item.number}${t(
                      'documents.deleteConfirm3')}`}</p>
                      <p>{`${t('documents.voidDescription')}`}</p>
                    </div>,
                    onConfirm: () => {
                      setDialogConfirm((prev) => ({
                        ...prev,
                        isClick: false,
                      }));
                      deleteFile(item);
                    },
                  }));
                },
              },
        ];

        let options_WithholdingTax = [
          {
            key: 'open',
            text: t('documents.openDocument'),
            iconProps: { iconName: 'OpenFile' },
            onClick: () => {
              openFile_pdf(item);
            },
          },
          {
            key: 'download',
            text: t('documents.download'),
            iconProps: { iconName: 'DownloadDocument' },
            onClick: () => {
              download(item);
            },
          },
          {
            key: 'divider_1',
            itemType: ContextualMenuItemType.Divider,
          },
          {
            key: 'email',
            text: t('documents.email'),
            iconProps: { iconName: 'MailForward' },
            onClick: () => {
              console.log('isEmailSetting:', isEmailSetting);

              if (isEmailSetting) {
                setIsClickEmail(true);
                setFileNameEmail(item.name);
                setFileDisplayName(item.pdfFileName);
                setCustomerEmail(item.customerEmail);
                setEmailList(item.customerEmail ? [item.customerEmail] : []);
                setCopyEmailList(item.companyEmail ? [item.companyEmail] : [])
                setCopyEmail(item.companyEmail);
                setFileIdEmail(item.key);
              } else {
                history.push({
                  pathname: '/settings/emails',
                  state: {},
                });
              }
            },
          },
          ...(enableSms ? [{
            key: 'sms',
            text: 'SMS',
            iconProps: { iconName: 'Message' },
            onClick: () => {
              setIsClickSms(true);
              setFileNameSms(item.name);
              setCustomerPhone(item.customerPhone);
              setFileIdSms(item.key);
            },
          },] : []),
          {
            key: 'divider_2',
            itemType: ContextualMenuItemType.Divider,
          },
          {
            key: 'reCreate',
            text: t('documents.reCreateDocument'),
            iconProps: { iconName: 'PageAdd' },
            onClick: () => {
              reCreateWithholdingTax(item);
            },
          },
          {
            key: 'divider_3',
            itemType: ContextualMenuItemType.Divider,
          },
          !item.rdStatus
            ? {
                key: 'delete',
                text: t('documents.voidDocument'),
                iconProps: { iconName: 'Delete' },
                onClick: () => {
                  let _itemType = item.name;
                  if (i18n.language === 'en') {
                    if (item.type === 'RECEIPT-TAXINVOICE') _itemType = 'Receipt/Tax Invoice';
                    if (item.type === 'RECEIPT-TAXINVOICE-ABB') _itemType = 'Receipt/Tax Invoice Abb';
                    if (item.type === 'RECEIPT') _itemType = 'Receipt';
                    if (item.type === 'INVOICE-TAXINVOICE') _itemType = 'Invoice/Tax Invoice';
                    if (item.type === 'DELIVERYORDER-TAXINVOICE') _itemType = 'Delivery Order/Tax Invoice';
                    if (item.type === 'TAXINVOICE') _itemType = 'Tax Invoice';
                    if (item.type === 'DEBIT-NOTE') _itemType = 'Debit Note';
                    if (item.type === 'CREDIT-NOTE') _itemType = 'Credit Note';
                    if (item.type === 'QUOTATION') _itemType = 'Quotation';
                    if (item.type === 'INVOICE') _itemType = 'Invoice';
                    if (item.type === 'BILLING-NOTE') _itemType = 'Billing Note';
                    if (item.type === 'DELIVERYORDER') _itemType = 'Delivery Order';
                  }

                  setDialogConfirm((prev) => ({
                    ...prev,
                    isClick: true,
                    header: t('documents.deleteConfirmHeader'),
                    message: <div>
                      <p>{`${t('documents.deleteConfirm1')}${_itemType}${t('documents.deleteConfirm2')}${item.number}${t(
                      'documents.deleteConfirm3')}`}</p>
                      <p>{`${t('documents.voidDescription')}`}</p>
                    </div>,
                    onConfirm: () => {
                      setDialogConfirm((prev) => ({
                        ...prev,
                        isClick: false,
                      }));
                      deleteFile(item);
                    },
                  }));
                },
              }
            : {
                key: 'delete',
                text: t('documents.voidDocument'),
                iconProps: { iconName: 'Delete' },
                disabled: true,
                onClick: () => {
                  let _itemType = item.name;
                  if (i18n.language === 'en') {
                    if (item.type === 'RECEIPT-TAXINVOICE') _itemType = 'Receipt/Tax Invoice';
                    if (item.type === 'RECEIPT-TAXINVOICE-ABB') _itemType = 'Receipt/Tax Invoice Abb';
                    if (item.type === 'RECEIPT') _itemType = 'Receipt';
                    if (item.type === 'INVOICE-TAXINVOICE') _itemType = 'Invoice/Tax Invoice';
                    if (item.type === 'DELIVERYORDER-TAXINVOICE') _itemType = 'Delivery Order/Tax Invoice';
                    if (item.type === 'TAXINVOICE') _itemType = 'Tax Invoice';
                    if (item.type === 'DEBIT-NOTE') _itemType = 'Debit Note';
                    if (item.type === 'CREDIT-NOTE') _itemType = 'Credit Note';
                    if (item.type === 'QUOTATION') _itemType = 'Quotation';
                    if (item.type === 'INVOICE') _itemType = 'Invoice';
                    if (item.type === 'BILLING-NOTE') _itemType = 'Billing Note';
                    if (item.type === 'DELIVERYORDER') _itemType = 'Delivery Order';
                  }

                  setDialogConfirm((prev) => ({
                    ...prev,
                    isClick: true,
                    header: t('documents.deleteConfirmHeader'),
                    message: <div>
                      <p>{`${t('documents.deleteConfirm1')}${_itemType}${t('documents.deleteConfirm2')}${item.number}${t(
                      'documents.deleteConfirm3')}`}</p>
                      <p>{`${t('documents.voidDescription')}`}</p>
                    </div>,
                    onConfirm: () => {
                      setDialogConfirm((prev) => ({
                        ...prev,
                        isClick: false,
                      }));
                      deleteFile(item);
                    },
                  }));
                },
              },
        ];

        return (
          <span data-selection-disabled={true}>
            <DefaultButton
              text={t('documents.select')}
              menuProps={{
                items:
                  item.type === 'INVOICE' || item.type === 'QUOTATION' || item.type === 'BILLING-NOTE' || item.type === 'DELIVERYORDER'
                    ? options_document.filter((option) => option.key !== item.type)
                    : item.type === 'CREDIT-NOTE' || item.type === 'DEBIT-NOTE'
                    ? options_credit_debit
                    : item.type === 'RECEIPT-TAXINVOICE-ABB'
                    ? options_ABB
                    : item.type === 'WITHHOLDING-TAX'
                    ? options_WithholdingTax
                    : options_etax.filter((option) => option.key !== item.type),
                shouldFocusOnMount: true,
                directionalHintFixed: true,
              }}
              // Optional callback to customize menu rendering
              menuAs={(props) => <ContextualMenu {...props} />}
            />
          </span>
        );
      },
    },
  ];
  
  const handleDownloadCSV = () => {
    if (selectedItems.length > 0 && items.length > 0) {
      const _csvData = [];

      selectedItems.forEach((doc, index) => {
        const documentData = doc.data;
        documentData.items.forEach((item, indexItem) => {
          let preVatAmount = 0;
          let vatAmount = 0;
          let totalAmount = 0;

          let _percentVat = 7;
          if (item.percentVat <= 0) {
            _percentVat = 0;
          }

          if (documentData.includeVat) {
            totalAmount = item.total;
            vatAmount = Math.round(((item.total * _percentVat) / (100 + _percentVat)) * 100) / 100;
            preVatAmount = item.total - vatAmount;
          } else {
            preVatAmount = item.total;
            vatAmount = Math.round(((item.total * _percentVat) / 100) * 100) / 100;
            totalAmount = preVatAmount + vatAmount;
          }

          const customerData = documentData.customer;

          let _addressLineOne = '';
          let _addressLineTwo = '';

          if (customerData.unstructure) {
            _addressLineOne = customerData.addressLineOne;
            _addressLineTwo = customerData.addressLineTwo;
          } else {
            if (customerData.buildingNumber) _addressLineOne = _addressLineOne + customerData.buildingNumber;
            if (customerData.address) _addressLineOne = _addressLineOne + ' ' + customerData.address;
            if (customerData.streetName) _addressLineOne = _addressLineOne + ' ' + customerData.streetPrefix + customerData.streetName;
            if (customerData.subDistrictName)
              _addressLineTwo =
                _addressLineTwo + (customerData.subDistrictPrefix === 'เขต' ? 'เขต ' : customerData.subDistrictPrefix) + customerData.subDistrictName;
            if (customerData.districtName)
              _addressLineTwo =
                _addressLineTwo + ' ' + (customerData.districtPrefix === 'แขวง' ? 'แขวง ' : customerData.districtPrefix) + customerData.districtName;
            if (customerData.provinceName)
              _addressLineTwo = _addressLineTwo + ' ' + (customerData.provincePrefix ? customerData.provincePrefix : '') + customerData.provinceName;
            // if (customerData.postcode) _addressLineTwo = _addressLineTwo + ' ' + customerData.postcode;
          }

          let _referTypeName = '';
          if (documentData.refer?.typeName !== documentData.name) {
            _referTypeName = documentData.refer?.typeName;
          }

          if (indexItem === 0) {
            _csvData.push({
              A: index + 1,
              B: '=""' + documentData.name + '""',
              C: '=""' + documentData.number + '""',
              D: '=""' + documentData.dateBE + '""',
              E: '=""' + documentData.dateCE + '""',
              F: documentData.dueDateBE ? '=""' + documentData.dueDateBE + '""' : '',
              G: documentData.dueDateCE ? '=""' + documentData.dueDateCE + '""' : '',
              H: documentData.reference ? '=""' + documentData.reference + '""' : '',
              I: '=""' + documentData.includeVat + '""',
              J: '',
              K: item.number ? item.number : "",
              L: item.sku ? '=""' + item.sku + '""' : "",
              M: item.description ? item.description : "",
              N: item.quantity ? item.quantity : "",
              O: item.unitName ? item.unitName : "",
              P: item.price ? item.price : "",
              Q: item.discount ? item.discount : "",
              R: item.percentVat ? item.percentVat : "",
              S: preVatAmount ? preVatAmount : "",
              T: vatAmount ? vatAmount : "",
              U: totalAmount ? totalAmount : "",
              V: documentData.discount ? documentData.discount : "",
              W: documentData.nonVatPriceTotal ? documentData.nonVatPriceTotal : '',
              X: documentData.zeroVatPriceTotal ? documentData.zeroVatPriceTotal : '',
              Y: documentData.vatPriceTotal ? documentData.vatPriceTotal : '',
              Z: documentData.vatTotal ? documentData.vatTotal : "",
              AA: documentData.grandTotal ? documentData.grandTotal : "",
              AB: customerData.language ? '=""' + customerData.language + '""' : '',
              AC: customerData.name ? '=""' + customerData.name + '""' : '',
              AD: _addressLineOne ? '=""' + _addressLineOne + '""' : '',
              AE: _addressLineTwo ? '=""' + _addressLineTwo + '""' : '',
              AF: customerData.postcode ? '=""' + customerData.postcode + '""' : '',
              AG: customerData.countryName ? '=""' + customerData.countryName + '""' : '',
              AH: customerData.taxNumberType ? '=""' + customerData.taxNumberType + '""' : '',
              AI: customerData.taxId ? '=""' + customerData.taxId + '""' : '',
              AJ: customerData.branchNumber ? '=""' + customerData.branchNumber + '""' : '',
              AK: customerData.phone ? '=""' + customerData.phone + '""' : '',
              AL: customerData.email ? '=""' + customerData.email + '""' : '',
              AM: customerData.note ? '=""' + documentData.note + '""' : '',
              AN: customerData.createdBy ? '=""' + documentData.createdBy + '""' : '',
              AO: customerData.receivedBy ? '=""' + documentData.receivedBy + '""' : '',
              AP: '',
              AQ: customerData.reIssue ? '=""' + documentData.reIssue + '""' : '',
              AR: _referTypeName ? '=""' + _referTypeName + '""' : '',
              AS: documentData.refer?.number ? '=""' + documentData.refer?.number + '""' : '',
              AT: documentData.refer?.dateBE ? '=""' + documentData.refer?.dateBE + '""' : '',
              AU: documentData.refer?.dateCE ? '=""' + documentData.refer?.dateCE + '""' : '',
              AV: documentData.refer?.amountTotal ? '=""' + documentData.refer?.amountTotal + '""' : '',
              AW: documentData.refer?.reasonName ? '=""' + documentData.refer?.reasonName + '""' : '',
              AX: documentData.refer?.specificReason ? '=""' + documentData.refer?.specificReason + '""' : '',
              AY: doc.emailTime ? '=""' + doc.emailTime + '""' : '',
              AZ: doc.smsTime ? '=""' + doc.smsTime + '""' : '',
            });
          } else {
            _csvData.push({
              A: index + 1,
              B: '',
              C: '',
              D: '',
              E: '',
              F: '',
              G: '',
              H: '',
              I: '',
              J: '',
              K: item.number ? item.number : "",
              L: item.sku ? '=""' + item.sku + '""' : "",
              M: item.description ? item.description : "",
              N: item.quantity ? item.quantity : "",
              O: item.unitName ? item.unitName : "",
              P: item.price ? item.price : "",
              Q: item.discount ? item.discount : "",
              R: item.percentVat ? item.percentVat : "",
              S: preVatAmount ? preVatAmount : "",
              T: vatAmount ? vatAmount : "",
              U: totalAmount ? totalAmount : "",
              V: '',
              W: '',
              X: '',
              Y: '',
              Z: '',
              AA: '',
              AB: '',
              AC: '',
              AD: '',
              AE: '',
              AF: '',
              AG: '',
              AH: '',
              AI: '',
              AJ: '',
              AK: '',
              AL: '',
              AM: '',
              AN: '',
              AO: '',
              AP: '',
              AQ: '',
              AR: '',
              AS: '',
              AT: '',
              AU: '',
              AV: '',
              AW: '',
              AX: '',
              AY: '',
              AZ: '',
            });
          }
        });
      });

      setCsvData((prev) => ({ ...prev, fileName: formatDateTime(new Date().toISOString()) + '-CSV-Leceipt', data: _csvData, downloaded: true }));
    } else {
      instance
        .acquireTokenSilent({ ...silentRequest, account: account })
        .then((tokenResponse) => {
          axios
            .get(API_URL + '/etax/documents/' + documentType.key + '/all?from=' + from + '&to=' + to + '&date=' + dateType.key, {
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
                'cid': companyId
              },
          }).then(response => {

            let filter_documents = response.data.filter(doc => doc.type !== 'WITHHOLDING-TAX')

            // console.log("filter_documents", filter_documents)

            filter_documents.forEach((doc) => {
              if (doc.type === 'CREDIT-NOTE') {
                doc.data.amountTotal = -Math.abs(doc.data.amountTotal);
                doc.data.grandTotal = -Math.abs(doc.data.grandTotal);
                doc.data.vatTotal = -Math.abs(doc.data.vatTotal);
              } else if (doc.type === 'DEBIT-NOTE') {
                doc.data.amountTotal = doc.data.refer.diffAmountTotal;
              }

              if (doc.data.reIssue) {
                doc.data.amountTotal = 0;
                doc.data.vatTotal = 0;
                doc.data.grandTotal = 0;
              }
            })

            const _csvData = [];

            filter_documents.forEach((doc, index) => {
              const documentData = doc.data;
              documentData.items.forEach((item, indexItem) => {
                let preVatAmount = 0;
                let vatAmount = 0;
                let totalAmount = 0;

                let _percentVat = 7;
                if (item.percentVat <= 0) {
                  _percentVat = 0;
                }

                if (documentData.includeVat) {
                  totalAmount = item.total;
                  vatAmount = Math.round(((item.total * _percentVat) / (100 + _percentVat)) * 100) / 100;
                  preVatAmount = item.total - vatAmount;
                } else {
                  preVatAmount = item.total;
                  vatAmount = Math.round(((item.total * _percentVat) / 100) * 100) / 100;
                  totalAmount = preVatAmount + vatAmount;
                }

                const customerData = documentData.customer;

                let _addressLineOne = '';
                let _addressLineTwo = '';

                if (customerData.unstructure) {
                  _addressLineOne = customerData.addressLineOne;
                  _addressLineTwo = customerData.addressLineTwo;
                } else {
                  if (customerData.buildingNumber) _addressLineOne = _addressLineOne + customerData.buildingNumber;
                  if (customerData.address) _addressLineOne = _addressLineOne + ' ' + customerData.address;
                  if (customerData.streetName) _addressLineOne = _addressLineOne + ' ' + customerData.streetPrefix + customerData.streetName;
                  if (customerData.subDistrictName)
                    _addressLineTwo =
                      _addressLineTwo + (customerData.subDistrictPrefix === 'เขต' ? 'เขต ' : customerData.subDistrictPrefix) + customerData.subDistrictName;
                  if (customerData.districtName)
                    _addressLineTwo =
                      _addressLineTwo + ' ' + (customerData.districtPrefix === 'แขวง' ? 'แขวง ' : customerData.districtPrefix) + customerData.districtName;
                  if (customerData.provinceName)
                    _addressLineTwo = _addressLineTwo + ' ' + (customerData.provincePrefix ? customerData.provincePrefix : '') + customerData.provinceName;
                  // if (customerData.postcode) _addressLineTwo = _addressLineTwo + ' ' + customerData.postcode;
                }

                let _referTypeName = '';
                if (documentData.refer?.typeName !== documentData.name) {
                  _referTypeName = documentData.refer?.typeName;
                }

                if (indexItem === 0) {
                  _csvData.push({
                    A: index + 1,
                    B: '=""' + documentData.name + '""',
                    C: '=""' + documentData.number + '""',
                    D: '=""' + documentData.dateBE + '""',
                    E: '=""' + documentData.dateCE + '""',
                    F: documentData.dueDateBE ? '=""' + documentData.dueDateBE + '""' : '',
                    G: documentData.dueDateCE ? '=""' + documentData.dueDateCE + '""' : '',
                    H: documentData.reference ? '=""' + documentData.reference + '""' : '',
                    I: '=""' + documentData.includeVat + '""',
                    J: '',
                    K: item.number ? item.number : "",
                    L: item.sku ? '=""' + item.sku + '""' : "",
                    M: item.description ? item.description : "",
                    N: item.quantity ? item.quantity : "",
                    O: item.unitName ? item.unitName : "",
                    P: item.price ? item.price : "",
                    Q: item.discount ? item.discount : "",
                    R: item.percentVat ? item.percentVat : "",
                    S: preVatAmount ? preVatAmount : "",
                    T: vatAmount ? vatAmount : "",
                    U: totalAmount ? totalAmount : "",
                    V: documentData.discount ? documentData.discount : "",
                    W: documentData.nonVatPriceTotal ? documentData.nonVatPriceTotal : '',
                    X: documentData.zeroVatPriceTotal ? documentData.zeroVatPriceTotal : '',
                    Y: documentData.vatPriceTotal ? documentData.vatPriceTotal : '',
                    Z: documentData.vatTotal ? documentData.vatTotal : "",
                    AA: documentData.grandTotal ? documentData.grandTotal : "",
                    AB: customerData.language ? '=""' + customerData.language + '""' : '',
                    AC: customerData.name ? '=""' + customerData.name + '""' : '',
                    AD: _addressLineOne ? '=""' + _addressLineOne + '""' : '',
                    AE: _addressLineTwo ? '=""' + _addressLineTwo + '""' : '',
                    AF: customerData.postcode ? '=""' + customerData.postcode + '""' : '',
                    AG: customerData.countryName ? '=""' + customerData.countryName + '""' : '',
                    AH: customerData.taxNumberType ? '=""' + customerData.taxNumberType + '""' : '',
                    AI: customerData.taxId ? '=""' + customerData.taxId + '""' : '',
                    AJ: customerData.branchNumber ? '=""' + customerData.branchNumber + '""' : '',
                    AK: customerData.phone ? '=""' + customerData.phone + '""' : '',
                    AL: customerData.email ? '=""' + customerData.email + '""' : '',
                    AM: customerData.note ? '=""' + documentData.note + '""' : '',
                    AN: customerData.createdBy ? '=""' + documentData.createdBy + '""' : '',
                    AO: customerData.receivedBy ? '=""' + documentData.receivedBy + '""' : '',
                    AP: '',
                    AQ: customerData.reIssue ? '=""' + documentData.reIssue + '""' : '',
                    AR: _referTypeName ? '=""' + _referTypeName + '""' : '',
                    AS: documentData.refer?.number ? '=""' + documentData.refer?.number + '""' : '',
                    AT: documentData.refer?.dateBE ? '=""' + documentData.refer?.dateBE + '""' : '',
                    AU: documentData.refer?.dateCE ? '=""' + documentData.refer?.dateCE + '""' : '',
                    AV: documentData.refer?.amountTotal ? '=""' + documentData.refer?.amountTotal + '""' : '',
                    AW: documentData.refer?.reasonName ? '=""' + documentData.refer?.reasonName + '""' : '',
                    AX: documentData.refer?.specificReason ? '=""' + documentData.refer?.specificReason + '""' : '',
                    AY: doc.emailTime ? '=""' + doc.emailTime + '""' : '',
                    AZ: doc.smsTime ? '=""' + doc.smsTime + '""' : '',
                  });
                } else {
                  _csvData.push({
                    A: index + 1,
                    B: '=""' + documentData.name + '""',
                    C: '=""' + documentData.number + '""',
                    D: '=""' + documentData.dateBE + '""',
                    E: '=""' + documentData.dateCE + '""',
                    F: documentData.dueDateBE ? '=""' + documentData.dueDateBE + '""' : '',
                    G: documentData.dueDateCE ? '=""' + documentData.dueDateCE + '""' : '',
                    H: documentData.reference ? '=""' + documentData.reference + '""' : '',
                    I: '=""' + documentData.includeVat + '""',
                    J: '',
                    K: item.number ? item.number : "",
                    L: item.sku ? '=""' + item.sku + '""' : "",
                    M: item.description ? item.description : "",
                    N: item.quantity ? item.quantity : "",
                    O: item.unitName ? item.unitName : "",
                    P: item.price ? item.price : "",
                    Q: item.discount ? item.discount : "",
                    R: item.percentVat ? item.percentVat : "",
                    S: preVatAmount ? preVatAmount : "",
                    T: vatAmount ? vatAmount : "",
                    U: totalAmount ? totalAmount : "",
                    V: documentData.discount ? documentData.discount : "",
                    W: documentData.nonVatPriceTotal ? documentData.nonVatPriceTotal : '',
                    X: documentData.zeroVatPriceTotal ? documentData.zeroVatPriceTotal : '',
                    Y: documentData.vatPriceTotal ? documentData.vatPriceTotal : '',
                    Z: documentData.vatTotal ? documentData.vatTotal : "",
                    AA: documentData.grandTotal ? documentData.grandTotal : "",
                    AB: customerData.language ? '=""' + customerData.language + '""' : '',
                    AC: customerData.name ? '=""' + customerData.name + '""' : '',
                    AD: _addressLineOne ? '=""' + _addressLineOne + '""' : '',
                    AE: _addressLineTwo ? '=""' + _addressLineTwo + '""' : '',
                    AF: customerData.postcode ? '=""' + customerData.postcode + '""' : '',
                    AG: customerData.countryName ? '=""' + customerData.countryName + '""' : '',
                    AH: customerData.taxNumberType ? '=""' + customerData.taxNumberType + '""' : '',
                    AI: customerData.taxId ? '=""' + customerData.taxId + '""' : '',
                    AJ: customerData.branchNumber ? '=""' + customerData.branchNumber + '""' : '',
                    AK: customerData.phone ? '=""' + customerData.phone + '""' : '',
                    AL: customerData.email ? '=""' + customerData.email + '""' : '',
                    AM: customerData.note ? '=""' + documentData.note + '""' : '',
                    AN: customerData.createdBy ? '=""' + documentData.createdBy + '""' : '',
                    AO: customerData.receivedBy ? '=""' + documentData.receivedBy + '""' : '',
                    AP: '',
                    AQ: customerData.reIssue ? '=""' + documentData.reIssue + '""' : '',
                    AR: _referTypeName ? '=""' + _referTypeName + '""' : '',
                    AS: documentData.refer?.number ? '=""' + documentData.refer?.number + '""' : '',
                    AT: documentData.refer?.dateBE ? '=""' + documentData.refer?.dateBE + '""' : '',
                    AU: documentData.refer?.dateCE ? '=""' + documentData.refer?.dateCE + '""' : '',
                    AV: documentData.refer?.amountTotal ? '=""' + documentData.refer?.amountTotal + '""' : '',
                    AW: documentData.refer?.reasonName ? '=""' + documentData.refer?.reasonName + '""' : '',
                    AX: documentData.refer?.specificReason ? '=""' + documentData.refer?.specificReason + '""' : '',
                    AY: doc.emailTime ? '=""' + doc.emailTime + '""' : '',
                    AZ: doc.smsTime ? '=""' + doc.smsTime + '""' : '',
                  });
                }
              });
            });

            setCsvData((prev) => ({ ...prev, fileName: formatDateTime(new Date().toISOString()) + '-CSV-Leceipt', data: _csvData, downloaded: true }));
        })

      }).catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
    }
  };

  useDidMountEffect(() => {
    if (csvData.downloaded) {
      csvLink.current.link.click();
      setCsvData({ fileName: '', data: [], downloaded: false });
    }
  }, [csvData]);

  const isCompactMode = false;

  function TextFieldEmailComponent(props) {
    return (
      <TextFieldEmail 
        selectedItems={[...emailList.map(_email => (
          { key: _email, text: _email }
        ))]}
        onItemsRemoved={(removeItems, removeItemIndex) => {
          const currentSelectedItemsCopy = [...emailList];
          currentSelectedItemsCopy.splice(removeItemIndex, 1);
          // this.setState({ currentSelectedItems: [...currentSelectedItemsCopy] });
          setEmailList([...currentSelectedItemsCopy])

          let multiEmail = '';

          currentSelectedItemsCopy.forEach((email, index) => {
            multiEmail = multiEmail + email;

            if (currentSelectedItemsCopy.length - 1 !== index) {
              multiEmail = multiEmail + ';';
            }
          });
          setCustomerEmail(multiEmail);
        }}
        onBlur={(e) => {
          if (checkEmail(e.target.value) && !emailList.includes(e.target.value)) {
              const currentSelectedItemsCopy = [...emailList, e.target.value];

              setEmailList(currentSelectedItemsCopy)

              let multiEmail = '';

              currentSelectedItemsCopy.forEach((email, index) => {
                multiEmail = multiEmail + email;

                if (currentSelectedItemsCopy.length - 1 !== index) {
                  multiEmail = multiEmail + ';';
                }
              });
              setCustomerEmail(multiEmail);
          }
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
              if (checkEmail(e.target.value) && !emailList.includes(e.target.value)) {
                  const currentSelectedItemsCopy = [...emailList, e.target.value];

                  setEmailList(currentSelectedItemsCopy)

                  let multiEmail = '';

                  currentSelectedItemsCopy.forEach((email, index) => {
                    multiEmail = multiEmail + email;

                    if (currentSelectedItemsCopy.length - 1 !== index) {
                      multiEmail = multiEmail + ';';
                    }
                  });
                  setCustomerEmail(multiEmail);

              }
          }
        }}
      />
    );
  }

  function TextFieldCopyEmailComponent(props) {
    return (
      <TextFieldEmail 
        selectedItems={[...copyEmailList.map(_email => (
          { key: _email, text: _email }
        ))]}
        onItemsRemoved={(removeItems, removeItemIndex) => {
          const currentSelectedItemsCopy = [...copyEmailList];
          currentSelectedItemsCopy.splice(removeItemIndex, 1);
          // this.setState({ currentSelectedItems: [...currentSelectedItemsCopy] });
          setCopyEmailList([...currentSelectedItemsCopy])

          let multiEmail = '';

          currentSelectedItemsCopy.forEach((email, index) => {
            multiEmail = multiEmail + email;

            if (currentSelectedItemsCopy.length - 1 !== index) {
              multiEmail = multiEmail + ';';
            }
          });
          setCopyEmail(multiEmail);
        }}
        onBlur={(e) => {
          if (checkEmail(e.target.value) && !copyEmailList.includes(e.target.value)) {
              const currentSelectedItemsCopy = [...copyEmailList, e.target.value];

              setCopyEmailList(currentSelectedItemsCopy)

              let multiEmail = '';

              currentSelectedItemsCopy.forEach((email, index) => {
                multiEmail = multiEmail + email;

                if (currentSelectedItemsCopy.length - 1 !== index) {
                  multiEmail = multiEmail + ';';
                }
              });
              setCopyEmail(multiEmail);
          }
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
              if (checkEmail(e.target.value) && !copyEmailList.includes(e.target.value)) {
                  const currentSelectedItemsCopy = [...copyEmailList, e.target.value];

                  setCopyEmailList(currentSelectedItemsCopy)

                  let multiEmail = '';

                  currentSelectedItemsCopy.forEach((email, index) => {
                    multiEmail = multiEmail + email;

                    if (currentSelectedItemsCopy.length - 1 !== index) {
                      multiEmail = multiEmail + ';';
                    }
                  });
                  setCopyEmail(multiEmail);

              }
          }
        }}
      />
    );
  }

  return (
    <>
      <div style={homeStyles}>
        {showCertificatePendingExpired && (
          <MessageBar
            onDismiss={() => {
              setShowCertificatePendingExpired(false);
            }}
            messageBarType={MessageBarType.warning}
            dismissButtonAriaLabel={t('documents.close')}
            isMultiline={false}
            style={{
              fontSize: 14,
            }}
            actions={
              <div>
                <MessageBarButton
                  onClick={() => window.open(CERTIFICATE_REQUEST_LINK,'_blank')}
                >
                  {t('documents.requestCertificate')}
                </MessageBarButton>
              </div>
            }
          >
            {t('documents.pendingExpiredCertificateMessage')} {`(${certificateExpiredDate})`}{t('documents.pendingExpiredCertificateMessage2')}
          </MessageBar>
        )}

        {showCertificateExpired && (
          <MessageBar
            onDismiss={() => {
              setShowCertificateExpired(false);
            }}
            messageBarType={MessageBarType.error}
            dismissButtonAriaLabel={t('documents.close')}
            isMultiline={false}
            style={{
              fontSize: 14,
            }}
            actions={
              <div>
                <MessageBarButton
                  onClick={() => window.open(CERTIFICATE_REQUEST_LINK,'_blank')}
                >
                  {t('documents.requestCertificate')}
                </MessageBarButton>
              </div>
            }
          >
            {t('documents.expiredCertificateMessage')} {`(${certificateExpiredDate})`}{t('documents.expiredCertificateMessage2')}
          </MessageBar>
        )}

        {showNewsEvent && (
          <MessageBar
            onDismiss={() => {
              setShowNewsEvent(false);
            }}
            messageBarType={MessageBarType.warning}
            dismissButtonAriaLabel={t('documents.close')}
            isMultiline={false}
            style={{
              fontSize: 14,
            }}
          >
            *** สำคัญ *** เอกสารที่ลูกค้าใช้ลดหย่อน Easy E-Receipt 2567 ต้องทำการออกเอกสาร หรือ Digitally Signed ภายในวันที่ 15 ก.พ. 2567 ***
          </MessageBar>
        )}
        {digitalSign && showCertificateSetup && (
          <MessageBar
            onDismiss={() => {
              setShowCertificateSetup(false);
            }}
            messageBarType={MessageBarType.warning}
            dismissButtonAriaLabel={t('documents.close')}
            isMultiline={false}
            style={{
              fontSize: 14,
            }}
            actions={
              <div>
                <MessageBarButton
                  onClick={() => {
                    history.push({
                    //   pathname: '/etax/certificates',
                      pathname: '/billings',
                      state: {},
                    });
                  }}
                >
                  {/* {t('documents.warningSetupCertificateLink')} */}
                  {t('documents.warningSubscriptionSoftwareButton')}
                </MessageBarButton>
              </div>
            }
          >
            {/* {t('documents.warningSetupCertificate')} */}
            {'  '}
            <Link
              onClick={() => {
                history.push({
                //   pathname: '/etax/certificates',
                  pathname: '/billings',
                  state: {},
                });
              }}
              underline
            >
              {/* {t('documents.warningSetupCertificateLink')} */}
              {t('documents.warningSubscriptionSoftwareButton')}
            </Link>
            {'  '}{t('documents.warningSubscriptionSoftware')}
          </MessageBar>
        )}

        {digitalSign && !showCertificateSetup && showXmlDeliverWarning && (
          <MessageBar
            onDismiss={() => {
              setShowXmlDeliverWarning(false);
            }}
            messageBarType={MessageBarType.warning}
            dismissButtonAriaLabel={t('documents.close')}
            isMultiline={false}
            style={{
              fontSize: 14,
            }}
            actions={
              <div>
                <MessageBarButton
                  onClick={() => {
                    history.push({
                      pathname: '/etax/xml/deliveries',
                      state: {},
                    });
                  }}
                >
                  {t('documents.warningXmlDeliveryButton')}
                </MessageBarButton>
              </div>
            }
          >
            <Link
              onClick={() => {
                history.push({
                  pathname: '/etax/xml/deliveries',
                  state: {},
                });
              }}
              underline
            >
              {t('documents.warningXmlDelivery01')}
            </Link>{' '}
            {t('documents.warningXmlDelivery02')}
          </MessageBar>
        )}

        {/* 
        {showXmlDeliverSetup ? (
          <MessageBar
            onDismiss={() => {
              setShowXmlDeliverSetup(false);
            }}
            messageBarType={MessageBarType.warning}
            dismissButtonAriaLabel={t('documents.close')}
            isMultiline={false}
            style={{
              fontSize: 14,
            }}
            actions={
              <div>
                <MessageBarButton
                  onClick={() => {
                    history.push({
                      pathname: '/etax/xml/deliver',
                      state: {},
                    });
                  }}
                >
                  ตั้งค่าการนำส่งไฟล์ XML อัตโนมัติ
                </MessageBarButton>
              </div>
            }
          >
            <Link
              onClick={() => {
                history.push({
                  pathname: '/etax/xml/deliver',
                  state: {},
                });
              }}
              underline
            >
              ตั้งค่าการนำส่งไฟล์ XML อัตโนมัติ
            </Link>{' '}
            เพื่อนำส่งไฟล์ XML เข้าระบบ e-Tax Invoice & e-Receipt แบบอัตโนมัติ
          </MessageBar>
        ) : (
          digitalSign && showXmlDeliver && (
            <MessageBar
              onDismiss={() => {
                setShowXmlDeliver(false);
              }}
              messageBarType={MessageBarType.warning}
              dismissButtonAriaLabel={t('documents.close')}
              isMultiline={false}
              style={{
                fontSize: 14,
              }}
            >
              {t('documents.warningXmlDelivery')}
            </MessageBar>
          )
        )}
 */}
        {showEmailSuccess && (
          <MessageBar
            messageBarType={MessageBarType.success}
            isMultiline={false}
            onDismiss={() => setShowEmailSuccess(false)}
            dismissButtonAriaLabel={t('documents.close')}
            style={{
              fontSize: 14,
            }}
          >
            {t('documents.sendEmailSuccess')}
          </MessageBar>
        )}
        {showEmailError && (
          <MessageBar
            messageBarType={MessageBarType.error}
            isMultiline={false}
            onDismiss={() => setShowEmailError(false)}
            dismissButtonAriaLabel={t('documents.close')}
            style={{
              fontSize: 14,
            }}
          >
            {t('documents.sendEmailFail')}
          </MessageBar>
        )}

        {showSmsSuccess && (
          <MessageBar
            messageBarType={MessageBarType.success}
            isMultiline={false}
            onDismiss={() => setShowSmsSuccess(false)}
            dismissButtonAriaLabel={t('documents.close')}
            style={{
              fontSize: 14,
            }}
          >
            {t('documents.sendSmsSuccess')}
          </MessageBar>
        )}

        {showSmsError && (
          <MessageBar
            messageBarType={MessageBarType.error}
            isMultiline={false}
            onDismiss={() => setShowSmsError(false)}
            dismissButtonAriaLabel={t('documents.close')}
            style={{
              fontSize: 14,
            }}
          >
            {t('documents.sendSmsFail')}
          </MessageBar>
        )}

        {showRepeatNumber && (
          <MessageBar
            messageBarType={MessageBarType.warning}
            isMultiline={false}
            onDismiss={() => setShowRepeatNumber(false)}
            dismissButtonAriaLabel={t('documents.close')}
            style={{
              fontSize: 14,
            }}
          >
            <TooltipHost content={duplicatedNumbers.join(", ")} directionalHint={DirectionalHint.bottomCenter}>
              <span style={{ width: 1000, whiteSpace: 'nowrap',  overflow: 'hidden', display: 'inline-block', textOverflow: "ellipsis"}}>
                {t('documents.showRepeatNumberMessage')} : {duplicatedNumbers.join(", ")}
              </span>
            </TooltipHost>
          </MessageBar>
        )}

        {(showEmailSuccess || showEmailError || showSmsSuccess || showSmsError) && <br />}
        <Stack>
          <Stack horizontal horizontalAlign="center">
            <h2 style={{ marginLeft: '0px', marginTop: '25px' }}>{t('documents.allDocuments')}</h2>
          </Stack>
          {enableTopup && (
            <Stack
              horizontal
              horizontalAlign="center"
              style={{
                marginTop: '-15px',
              }}
            >
              <ActionButton
                iconProps={{
                  iconName: 'QRCode',
                  style: {
                    fontSize: 22,
                    marginTop: '-5px',
                  },
                }}
                onClick={() => {
                  history.push({
                    pathname: '/billings',
                    state: {},
                  });
                }}
              >
                <span
                  style={{
                    fontSize: 16,
                  }}
                >
                  {t('documents.instantTopup')}
                </span>
              </ActionButton>
            </Stack>
          )}
          <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: '30px' }}>
            <DocumentCard aria-label={t('documents.totalDocuments')} styles={cardStyles.cardStyles} type={DocumentCardType.compact}>
              <DocumentCardLogo {...{ logoIcon: 'Documentation' }} title={t('documents.totalDocuments')} />
              <DocumentCardDetails>
                <DocumentCardTitle title={t('documents.totalDocuments')} styles={cardStyles.header} />
                <Text styles={cardStyles.amount}>
                  <CountUp end={totalDocuments} separator="," duration={1} /> {t('documents.documents')}
                </Text>
              </DocumentCardDetails>
            </DocumentCard>

            <DocumentCard aria-label={t('documents.vat')} styles={cardStyles.cardStyles} type={DocumentCardType.compact}>
              <DocumentCardLogo {...{ logoIcon: 'Bank' }} title={t('documents.vat')} />
              <DocumentCardDetails>
                <DocumentCardTitle title={t('documents.vat')} styles={cardStyles.header} />
                <Text styles={cardStyles.amount}>
                  <CountUp end={totalVat} separator="," decimals={2} decimal="." duration={1} /> {t('documents.thb')}
                </Text>
              </DocumentCardDetails>
            </DocumentCard>

            <DocumentCard aria-label={t('documents.salesAmount')} styles={cardStyles.cardStyles} type={DocumentCardType.compact}>
              <DocumentCardLogo {...{ logoIcon: 'Money' }} title={t('documents.salesAmount')} />
              <DocumentCardDetails>
                <DocumentCardTitle title={t('documents.salesAmount')} styles={cardStyles.header} />
                <Text styles={cardStyles.amount}>
                  <CountUp end={totalGrand} separator="," decimals={2} decimal="." duration={1} /> {t('documents.thb')}
                </Text>
              </DocumentCardDetails>
            </DocumentCard>
          </Stack>
        </Stack>

        {totalProcessingDocuments !== '' && totalProcessingDocuments > 0 && (
          <div>
            <br />
            <Spinner label={t('documents.signProcessing')} size={SpinnerSize.medium} labelPosition="left" />
          </div>
        )}
        <br />
        <Stack horizontal horizontalAlign="center">
          <Stack
            vertical
            style={{
              width: '98%',
              minWidth: '800px',
              maxWidth: '1350px',
            }}
          >
            <Stack 
                horizontal 
                // className="ms-bgColor-white" 
                styles={{
                    root: {
                      backgroundColor: 'white',
                    },
                }}
                horizontalAlign="space-between"
                >
              <Stack
                styles={{
                  root: {
                    width: 'calc(100vw - 700px)',
                    minWidth: '700px',
                    maxWidth: '900px',
                  },
                }}
              >
                <CommandBar items={commandBarItems} />
              </Stack>

              <Stack
                horizontal
                styles={{
                  root: {
                    height: 44,
                  },
                }}
                tokens={{ childrenGap: '10px' }}
              >
                <SearchBox
                  className="ms-borderColor-themePrimary"
                  styles={{
                    root: {
                      marginTop: 6,
                      marginLeft: 6,
                      width: 200,
                      fontSize: 13,
                      fontWeight: 0,
                    },
                  }}
                  disabled={!loadDataComplete}
                  placeholder={t('documents.searchPlaceholder')}
                  onChange={(e) => setSearch(e ? e.target.value.replace(/\s/g, '') : '')}
                  onBlur={(e) => {
                    if (!e.target.value && previousSearch) {
                      setLoadDataComplete(false);
                      setItems([]);
                      setGetNewDocuments(true);
                      setPreviousSearch('');

                      setCurrentPage(1);

                      setTotalDocuments(0);
                      setTotalVat(0);
                      setTotalGrand(0);

                      resetSort();
                    }
                  }}
                  onClear={() => {
                    setLoadDataComplete(false);
                    setItems([]);
                    setGetNewDocuments(true);
                    setPreviousSearch('');

                    setCurrentPage(1);

                    setTotalDocuments(0);
                    setTotalVat(0);
                    setTotalGrand(0);

                    resetSort();
                  }}
                  onSearch={() => {
                    setCurrentPage(1);
                    setPreviousSearch(search);
                    setLoadDataComplete(false);
                    setItems([]);
                    setGetNewDocuments(true);

                    setTotalDocuments(0);
                    setTotalVat(0);
                    setTotalGrand(0);

                    resetSort();
                  }}
                  value={search}
                />
                <PrimaryButton
                  disabled={!loadDataComplete || !search}
                  styles={{
                    root: {
                      marginTop: 6,
                      marginRight: 8,
                      width: 90,
                    },
                  }}
                  text={t('documents.search')}
                  onClick={() => {
                    setCurrentPage(1);
                    setPreviousSearch(search);
                    setLoadDataComplete(false);
                    setItems([]);
                    setGetNewDocuments(true);

                    setTotalDocuments(0);
                    setTotalVat(0);
                    setTotalGrand(0);

                    resetSort();
                  }}
                />
              </Stack>
            </Stack>
            {items.length ? (
              <ShimmeredDetailsList
                items={items || []}
                enableShimmer={!loadDataComplete}
                compact={isCompactMode}
                columns={columns}
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                selection={selection}
                selectionMode={!loadDataComplete || isProcessing ? SelectionMode.none : SelectionMode.multiple}
                checkboxVisibility={CheckboxVisibility.always}
                selectionPreservedOnEmptyClick={true}
              />
            ) : (
              <ShimmeredDetailsList
                items={[{}]}
                enableShimmer={!loadDataComplete}
                compact={isCompactMode}
                columns={columns}
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                selection={selection}
                selectionMode={!loadDataComplete || isProcessing ? SelectionMode.none : SelectionMode.multiple}
                checkboxVisibility={CheckboxVisibility.always}
                selectionPreservedOnEmptyClick={true}
                onRenderRow={() => {
                  return (
                    <Stack verticalAlign="center" horizontalAlign="center" styles={{ root: { backgroundColor: '#FFFFFF', height: 55 } }}>
                      <Text>{t('documents.noItemFound')}</Text>
                    </Stack>
                  );
                }}
              />
            )}
          </Stack>
        </Stack>
        <br />
        <center>
          {loadDataComplete && (
            <Pagination
              selectedPageIndex={currentPage - 1}
              pageCount={pageData.totalPages}
              onPageChange={onPageChange}
              format
              firstPageIconProps={{
                iconName: 'DoubleChevronLeft',
              }}
              previousPageIconProps={{
                iconName: 'ChevronLeft',
              }}
              nextPageIconProps={{ iconName: 'ChevronRight' }}
              lastPageIconProps={{
                iconName: 'DoubleChevronRight',
              }}
            />
          )}
          <br />
          <br />
        </center>

        <Dialog
          hidden={!isClickEmail}
          dialogContentProps={{
            type: DialogType.largeHeader,
            title: t('documents.sendFileByEmail'),
            subText: fileNameEmail,
          }}
          minWidth={500}
        >
          <Stack vertical>
            <div>
              <label>{t('documents.sendToEmail')}</label>
            </div>
            <TextFieldEmailComponent />
            <br />
            <Checkbox
              label={t('documents.ccToEmail')}
              checked={isClickCopyEmail}
              onChange={(e) => {
                console.log('copy email checkbox: ', e.target.value);
                setIsClickCopyEmail((prev) => !prev);
              }}
            />
            {isClickCopyEmail && (
              <div style={{ marginTop: '8px' }}>
                <TextFieldCopyEmailComponent />
              </div>
            )}
            <br />
            <br />
          </Stack>
          <DialogFooter>
            <PrimaryButton
              onClick={() => {
                // console.log("customerEmail", customerEmail)
                if (isClickCopyEmail) {
                  sendEmail(fileDisplayName, fileIdEmail, customerEmail, copyEmail);
                } else {
                  sendEmail(fileDisplayName, fileIdEmail, customerEmail, '');
                }
              }}
              text={t('documents.sendEmail')}
            />
            <DefaultButton onClick={() => setIsClickEmail(false)} text={t('documents.cancel')} />
          </DialogFooter>
        </Dialog>

        <Dialog
          hidden={!isClickSms}
          dialogContentProps={{
            type: DialogType.largeHeader,
            title: t('documents.sendFileBySms'),
            subText: fileNameSms,
          }}
        >
          <TextField
            label={t('documents.mobileNumer')}
            value={customerPhone}
            required
            onChange={(e) => {
              setCustomerPhone(e.target.value);
              setErrorMessageSms('');
            }}
            errorMessage={errorMessageSms}
            description={t('documents.exampleNumber')}
          />
          <br />
          <br />
          <DialogFooter>
            <PrimaryButton
              onClick={() => {
                sendSms(fileIdSms, customerPhone);
              }}
              text={t('documents.sendSms')}
            />
            <DefaultButton onClick={() => setIsClickSms(false)} text={t('documents.cancel')} />
          </DialogFooter>
        </Dialog>

        <Dialog
          hidden={!dialogConfirm.isClick}
          onDismiss={() =>
            setDialogConfirm((prev) => ({
              ...prev,
              isClick: false,
            }))
          }
          styles={{ main: { minHeight: 0 } }}
          dialogContentProps={{
            type: DialogType.largeHeader,
            title: dialogConfirm.header,
            styles: { header: { textAlign: 'center' }, title: { paddingRight: 24 }, subText: { textAlign: 'center' } },
            // subText: dialogConfirm.message,
          }}
        >
          <Stack horizontalAlign='center' styles={{ root: { textAlign: "center"}}}>
            {dialogConfirm.message}
          </Stack>
          <DialogFooter styles={{ actionsRight: { textAlign: 'center' } }}>
            <PrimaryButton onClick={dialogConfirm.onConfirm} text={t('documents.confirm')} />
            <DefaultButton
              onClick={() =>
                setDialogConfirm((prev) => ({
                  ...prev,
                  isClick: false,
                }))
              }
              text={t('documents.cancel')}
            />
          </DialogFooter>
        </Dialog>

        <Dialog
          hidden={!dialogBatchSendEmailConfirm.isClick}
          onDismiss={() =>
            setDialogBatchSendEmailConfirm((prev) => ({
              ...prev,
              isClick: false,
            }))
          }
          styles={{ main: { minHeight: 0 } }}
          dialogContentProps={{
            type: DialogType.largeHeader,
            title: dialogBatchSendEmailConfirm.header,
            styles: { /* header: { textAlign: 'center' }, */ title: { paddingRight: 24 }, /* subText: { textAlign: 'center' } */ },
            subText: dialogBatchSendEmailConfirm.message,
          }}
          minWidth={500}
        >
          <Stack /* horizontalAlign='center' */>
            <Checkbox
              label={t('documents.ccToEmail')}
              checked={isClickCopyEmail}
              onChange={(e) => {
                console.log('copy email checkbox: ', e.target.value);
                setIsClickCopyEmail((prev) => !prev);
              }}
            />
          </Stack>
          {isClickCopyEmail && (
            <div style={{ marginTop: '8px' }}>
              <TextFieldCopyEmailComponent />
            </div>
          )}
          <DialogFooter styles={{ actionsRight: { textAlign: 'center' } }}>
            <PrimaryButton onClick={async () => {
              setDialogBatchSendEmailConfirm((prev) => ({
                ...prev,
                isClick: false,
              }));

              setIsProcessing(true);

              return await batchSendEmail();
            }} text={t('documents.confirm')} />
            <DefaultButton
              onClick={() =>
                setDialogBatchSendEmailConfirm((prev) => ({
                  ...prev,
                  isClick: false,
                }))
              }
              text={t('documents.cancel')}
            />
          </DialogFooter>
        </Dialog>

        <CSVLink style={{ display: 'none' }} ref={csvLink} filename={csvData.fileName} data={csvData.data} headers={headerCSV}>
          Download csv
        </CSVLink>
      </div>
      {url ? (
        <div ref={printRef} style={{ position: 'absolute', zIndex: -1 }}>
          <PdfAllPages pdf={url} />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default AllDocuments;
