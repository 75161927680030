import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import axios from "axios";

import { useMsal } from "@azure/msal-react";
import { silentRequest } from "../../../../authConfig";

import paginate from "jw-paginate";

import { Pagination } from "@fluentui/react-experiments/lib/Pagination";

import CountUp from "react-countup";

import {
  DetailsListLayoutMode,
  ShimmeredDetailsList,
  Selection,
  SelectionMode,
  TooltipHost,
  Spinner,
  CommandBar,
  Stack,
  DocumentCard,
  DocumentCardTitle,
  DocumentCardDetails,
  DocumentCardLogo,
  DocumentCardType,
  Text,
  IconButton,
  CheckboxVisibility,
  PrimaryButton,
  MessageBar,
  MessageBarType,
  Dialog,
  DialogFooter,
  DialogType,
  DefaultButton,
  DetailsHeader,
  Checkbox,
  // DetailsRowCheck,
  // DetailsList,
  // Check,
  DirectionalHint,
  Calendar,
  DateRangeType,
  // DetailsRow
} from "@fluentui/react";

import {
  getFileTypeIconProps,
  //FileIconType
} from "@fluentui/react-file-type-icons";

const API_URL = process.env.REACT_APP_API_URL;

const homeStyles = {
  border: 0,
  margin: 0,
  width: "100%",
  background: "#FAF9F8",
};

const cardStyles = {
  cardStyles: {
    root: {
      background: "white",
      borderLeft: "5px solid #0078d4",
      width: "30%",
      minWidth: "315px",
      maxWidth: "424px",
      height: "130px",
    },
  },
  header: {
    root: {
      fontSize: 20,
      fontWeight: "bold",
      paddingTop: "20px",
      textAlign: "center",
    },
  },
  amount: {
    root: {
      fontSize: 30,
      paddingBottom: "25px",
      textAlign: "center",
    },
  },
};

const customCheckboxStyles = {
  root: {
    selectors: {
      '.ms-Checkbox-checkbox': {
        borderRadius: '50%',
      },
      '.ms-Checkbox-checkmark': {
        borderRadius: '50%',
      },
    },
  },
  checkbox: {
    marginRight: '0px'
  },
  checkmark: {
    fontSize: '10px', // Adjust this to change the size of the checkmark itself
  },
};

const formatDate = (d) => {
  let month = "" + (d.getMonth() + 1);
  let day = "" + d.getDate();
  let year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

function findDuplicates(documents) {
  const keyTypeMap = new Map();
  const duplicateNumber = [];

  for (const _document of documents) {
      // const { number, documentType } = _document;
      const number = _document.data.number
      const documentType = _document.type

      if (keyTypeMap.has(number)) {
          const types = keyTypeMap.get(number);

          if (types.has(documentType)) {
              duplicateNumber.push(_document);
          } else {
              types.add(documentType);
          }
      } else {
          keyTypeMap.set(number, new Set([documentType]));
      }
  }

  return duplicateNumber;
}

const XmlDeliveries = () => {
  // console.log("Home render!!!");

  const { t } = useTranslation();

  const history = useHistory();

  const { instance } = useMsal();
  const account = instance.getAllAccounts()[0];

  const companyId = sessionStorage.getItem("companyId") || "";

  const [documents, setDocuments] = useState([]);
  const [items, setItems] = useState([]);
  const [loadDataComplete, setLoadDataComplete] = useState(false);
  const [getNewDocuments, setGetNewDocuments] = useState(true);
  const [copySuccess, setCopySuccess] = useState(false);

  const [pageData, setPageData] = useState({});
  const [pageSize, setPageSize] = useState(sessionStorage.getItem("uploadXml-pageSize") * 1 || 100);
  const [currentPage, setCurrentPage] = useState(sessionStorage.getItem("uploadXml-currentPage") * 1 || 1);

  //CommandBars Items
  const [documentType, setDocumentType] = useState(
    (sessionStorage.getItem("uploadXml-documentType-key") && {
      key: sessionStorage.getItem("uploadXml-documentType-key"),
      text: sessionStorage.getItem("uploadXml-documentType-text"),
    }) || {
      key: "ALL",
      text: t("xmlDeliveries.allDocumentsType"),
    }
  );

  const [rdStatusType, setRdStatusType] = useState(
    (sessionStorage.getItem("uploadXml-rdStatusType-key") && {
      key: sessionStorage.getItem("uploadXml-rdStatusType-key"),
      text: sessionStorage.getItem("uploadXml-rdStatusType-text"),
    }) || { key: "all", text: t("xmlDeliveries.allRdStatus") }
  );

  //Display Summary
  const [totalDocuments, setTotalDocuments] = useState(0);
  const [totalVat, setTotalVat] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);

  const [totalPending, setTotalPending] = useState(0);
  const [totalSpPending, setTotalSpPending] = useState(0);
  const [totalFail, setTotalFail] = useState(0);
  const [totalSuccessful, setTotalSuccessful] = useState(0);

  const [showRepeatNumber, setShowRepeatNumber] = useState(false);
  const [duplicatedNumbers, setDuplicatedNumbers] = useState([])
  const [uploadAble, setUploadAble] = useState(true)

  const [showDeliveriesDateWarning, setShowDeliveriesDateWarning] = useState(true);
  const [showCertificateWarning, setShowCertificateWarning] = useState(true);

  const uploadingXmlStop = useRef(false);

  const [primarySort, setPrimarySort] = useState(
    sessionStorage.getItem("uploadXml-primarySort") || "number"
  );

  const [sortDirection, setSortDirection] = useState(
    sessionStorage.getItem("uploadXml-sortDirection") || "DESC"
  );

  const [isSortedDescendingCreatedTime, setIsSortedDescendingCreatedTime] = useState(() => {
    if (sessionStorage.getItem("uploadXml-sort-createdTime") && sessionStorage.getItem("uploadXml-sort-createdTime") === "true") {
      return true;
    } else {
      return false;
    }
  });
  const [isSortedDescendingNumber, setIsSortedDescendingNumber] = useState(() => {
    if (sessionStorage.getItem("uploadXml-sort-number") && sessionStorage.getItem("uploadXml-sort-number") === "false") {
      return false;
    } else {
      return true;
    }
  });
  const [isSortedDescendingDocumentDate, setIsSortedDescendingDocumentDate] = useState(() => {
    if (sessionStorage.getItem("uploadXml-sort-documentDate") && sessionStorage.getItem("uploadXml-sort-documentDate") === "true") {
      return true;
    } else {
      return false;
    }
  });

  const [isSortedDescendingCustomerName, setIsSortedDescendingCustomerName] = useState(() => {
    if (sessionStorage.getItem("uploadXml-sort-customerName") && sessionStorage.getItem("uploadXml-sort-customerName") === "true") {
      return true;
    } else {
      return false;
    }
  });

  const [isSortedDescendingAmountTotal, setIsSortedDescendingAmountTotal] = useState(() => {
    if (sessionStorage.getItem("uploadXml-sort-amountTotal") && sessionStorage.getItem("uploadXml-sort-amountTotal") === "true") {
      return true;
    } else {
      return false;
    }
  });

  const [isSortedDescendingRdStatus, setIsSortedDescendingRdStatus] = useState(() => {
    if (sessionStorage.getItem("uploadXml-sort-rdStatus") && sessionStorage.getItem("uploadXml-sort-rdStatus") === "true") {
      return true;
    } else {
      return false;
    }
  });

  const [isSortedDescendingName, setIsSortedDescendingName] = useState(() => {
    if (sessionStorage.getItem("uploadXml-sort-name") && sessionStorage.getItem("uploadXml-sort-name") === "true") {
      return true;
    } else {
      return false;
    }
  });

  const getMonth = (goBack) => {
    let monthNames = [
      t("uploadXml.months01"),
      t("uploadXml.months02"),
      t("uploadXml.months03"),
      t("uploadXml.months04"),
      t("uploadXml.months05"),
      t("uploadXml.months06"),
      t("uploadXml.months07"),
      t("uploadXml.months08"),
      t("uploadXml.months09"),
      t("uploadXml.months10"),
      t("uploadXml.months11"),
      t("uploadXml.months12"),
    ];

    let d = new Date();

    let adjustDate = new Date(d.getFullYear(), d.getMonth() - goBack, 1);

    let year = adjustDate.getFullYear() + 543;

    return {
      month: adjustDate.getMonth(),
      year: adjustDate.getFullYear(),
      key: adjustDate.getMonth() + 1,
      text: monthNames[adjustDate.getMonth()] + " " + year,
    };
  };

  const [queryMonth, setQueryMonth] = useState(
    (sessionStorage.getItem("uploadXml-queryMonth-key") && {
      key: sessionStorage.getItem("uploadXml-queryMonth-key"),
      text: sessionStorage.getItem("uploadXml-queryMonth-text"),
    }) || { key: getMonth(0).year + "" + getMonth(0).key, text: t("uploadXml.thisMonth")}
  );

  const [date, setDate] = useState(sessionStorage.getItem("uploadXml-date") ? new Date(sessionStorage.getItem("uploadXml-date")) : new Date());

  const getFrom = (month, year) => {
    let _firstDay = new Date(year, month, 1);
    // console.log("First day: ", formatDate(_firstDay));

    return formatDate(_firstDay);
  };

  const getTo = (month, year) => {
    let _lastDay = new Date(year, month + 1, 1);
    // console.log("Last day: ", formatDate(_lastDay));

    return formatDate(_lastDay);
  };

  const [from, setFrom] = useState(sessionStorage.getItem("uploadXml-from") || getFrom(new Date().getMonth(), new Date().getFullYear()));
  const [to, setTo] = useState(sessionStorage.getItem("uploadXml-to") || getTo(new Date().getMonth(), new Date().getFullYear()));

  const [isProcessDocument, setIsProcessDocument] = useState(false);
  const [isProcessDocumentComplete, setIsProcessDocumentComplete] = useState(false);

  const [serviceProviderEnable, setServiceProviderEnable] = useState(false);
  const [isLoadCompaneisDetailsComplete, setIsLoadCompaneisDetailsComplete] = useState(false);

  const [xmlCheckEnable, setXmlCheckEnable] = useState(false);
  const [xmlCheckNotification, setXmlCheckNotification] = useState("");

  const [selectedItems, setSelectedItems] = useState([]);

  const [selection] = useState(
    new Selection({
      onSelectionChanged: () => {
        // console.log("isCreateDocument: ", isProcessDocument);
        // console.log("isCreateDocumentComplete: ", isProcessDocumentComplete);
        let _selectedItems = selection.getSelection();

        setSelectedItems(_selectedItems);
      },
    })
  );

  const [pageDropdown, setPageDropdown] = useState([]);
  const dateType = { key: "document", text: t("xmlDeliveries.documentDate") }
  
  const [dialogConfirm, setDialogConfirm] = useState({
    type: "",
    isClick: false,
    header: '',
    message: '',
    onConfirm: null,
  });

  const resetSort = () => {
    if (dateType.key === "document") {
      setPrimarySort("documentDate");
      setIsSortedDescendingDocumentDate(true);
      setIsSortedDescendingCreatedTime(false);
      setIsSortedDescendingNumber(false);

      sessionStorage.setItem("uploadXml-primarySort", "documentDate");
      sessionStorage.setItem("uploadXml-sort-documentDate", "true");
      sessionStorage.setItem("uploadXml-sort-createdTime", "false");
      sessionStorage.setItem("uploadXml-sort-number", "false");
    } else if (dateType.key === "created") {
      setPrimarySort("createdTime");
      setIsSortedDescendingCreatedTime(true);
      setIsSortedDescendingDocumentDate(false);
      setIsSortedDescendingNumber(false);

      sessionStorage.setItem("uploadXml-primarySort", "createdTime");
      sessionStorage.setItem("uploadXml-sort-createdTime", "true");
      sessionStorage.setItem("uploadXml-sort-documentDate", "false");
      sessionStorage.setItem("uploadXml-sort-number", "false");
    } else {
      setPrimarySort("number");
      setIsSortedDescendingCreatedTime(false);
      setIsSortedDescendingDocumentDate(false);
      setIsSortedDescendingNumber(true);

      sessionStorage.setItem("uploadXml-primarySort", "number");
      sessionStorage.setItem("uploadXml-sort-createdTime", "false");
      sessionStorage.setItem("uploadXml-sort-documentDate", "false");
      sessionStorage.setItem("uploadXml-sort-number", "true");
    }

    // setIsSortedDescendingNumber(false);
    setIsSortedDescendingCustomerName(false);
    // setIsSortedDescendingCustomerPhone(false);
    // setIsSortedDescendingVatTotal(false);
    setIsSortedDescendingAmountTotal(false);
    // setIsSortedDescendingGrandTotal(false);
    setIsSortedDescendingName(false);
    setIsSortedDescendingRdStatus(false)

    setSortDirection("DESC");

    // sessionStorage.setItem("uploadXml-sort-number", "false");
    sessionStorage.setItem("uploadXml-sort-customerName", "false");
    sessionStorage.setItem("uploadXml-sort-customerPhone", "false");
    sessionStorage.setItem("uploadXml-sort-vatTotal", "false");
    sessionStorage.setItem("uploadXml-sort-grandTotal", "false");
    sessionStorage.setItem("uploadXml-sort-name", "false");
    sessionStorage.setItem("uploadXml-sort-rdStatus", "false");
    sessionStorage.setItem("uploadXml-sort-amountTotal", "false");

    sessionStorage.setItem("uploadXml-sortDirection", "DESC");


  };

  const DayPickerStrings = {
    months: [
      t('xmlDeliveries.months01'),
      t('xmlDeliveries.months02'),
      t('xmlDeliveries.months03'),
      t('xmlDeliveries.months04'),
      t('xmlDeliveries.months05'),
      t('xmlDeliveries.months06'),
      t('xmlDeliveries.months07'),
      t('xmlDeliveries.months08'),
      t('xmlDeliveries.months09'),
      t('xmlDeliveries.months10'),
      t('xmlDeliveries.months11'),
      t('xmlDeliveries.months12'),
    ],

    shortMonths: [
      t('xmlDeliveries.shortMonths01'),
      t('xmlDeliveries.shortMonths02'),
      t('xmlDeliveries.shortMonths03'),
      t('xmlDeliveries.shortMonths04'),
      t('xmlDeliveries.shortMonths05'),
      t('xmlDeliveries.shortMonths06'),
      t('xmlDeliveries.shortMonths07'),
      t('xmlDeliveries.shortMonths08'),
      t('xmlDeliveries.shortMonths09'),
      t('xmlDeliveries.shortMonths10'),
      t('xmlDeliveries.shortMonths11'),
      t('xmlDeliveries.shortMonths12'),
    ],
    days: [
      t('xmlDeliveries.days01'),
      t('xmlDeliveries.days02'),
      t('xmlDeliveries.days03'),
      t('xmlDeliveries.days04'),
      t('xmlDeliveries.days05'),
      t('xmlDeliveries.days06'),
      t('xmlDeliveries.days07'),
    ],
    shortDays: [
      t('xmlDeliveries.shortDays01'),
      t('xmlDeliveries.shortDays02'),
      t('xmlDeliveries.shortDays03'),
      t('xmlDeliveries.shortDays04'),
      t('xmlDeliveries.shortDays05'),
      t('xmlDeliveries.shortDays06'),
      t('xmlDeliveries.shortDays07'),
    ],

    goToToday: t('xmlDeliveries.goToToday'),
    prevMonthAriaLabel: t('xmlDeliveries.prevMonthAriaLabel'),
    nextMonthAriaLabel: t('xmlDeliveries.nextMonthAriaLabel'),
    prevYearAriaLabel: t('xmlDeliveries.prevYearAriaLabel'),
    nextYearAriaLabel: t('xmlDeliveries.nextYearAriaLabel'),
    closeButtonAriaLabel: t('xmlDeliveries.close'),
    monthPickerHeaderAriaLabel: t('xmlDeliveries.monthPickerHeaderAriaLabel'),
    yearPickerHeaderAriaLabel: t('xmlDeliveries.yearPickerHeaderAriaLabel'),

    isRequiredErrorMessage: t('xmlDeliveries.isRequiredErrorMessage'),

    invalidInputErrorMessage: t('xmlDeliveries.invalidInputErrorMessage'),
  };

  function getDateText(monthObject) {
    if (monthObject.type === "dateRange") {
      let _splitDate = monthObject.key.split(":");
      let _fromSplit = _splitDate[0].split("-");
      let _toSplit = _splitDate[1].split("-");

      let _from = _fromSplit[2] + "/" + _fromSplit[1] + "/" + (Number(_fromSplit[0]) + 543);
      let _to = _toSplit[2] + "/" + _toSplit[1] + "/" + (Number(_toSplit[0]) + 543);

      return _from + " - " + _to;
    }

    if (monthObject.text === "เดือนนี้" || monthObject.text === "This Month") return "เดือนนี้";

    let monthNames = [
      t('xmlDeliveries.months01'),
      t('xmlDeliveries.months02'),
      t('xmlDeliveries.months03'),
      t('xmlDeliveries.months04'),
      t('xmlDeliveries.months05'),
      t('xmlDeliveries.months06'),
      t('xmlDeliveries.months07'),
      t('xmlDeliveries.months08'),
      t('xmlDeliveries.months09'),
      t('xmlDeliveries.months10'),
      t('xmlDeliveries.months11'),
      t('xmlDeliveries.months12'),
    ];

    let _monthIndex = Number(monthObject.key.substring(4, 6)) - 1;
    let _year = Number(monthObject.key.substring(0, 4));
    // if (i18n.language === 'th') {
    _year = _year + 543;
    // }

    return monthNames[_monthIndex] + " " + _year;
  }

  function getRdStatusTypeText(key) {
    if (key === 'all') return t('xmlDeliveries.allRdStatus');
    if (key === 'pending') return t('xmlDeliveries.pendingRdStatus');
    if (key === 'sp-pending') return t('xmlDeliveries.spPendingRdStatus');
    if (key === 'successful') return t('xmlDeliveries.successfulRdStatus');
    if (key === 'fail') return t('xmlDeliveries.failRdStatus');
  }

  function getDocumentTypeText(key) {
    if (key === 'ALL') return t('xmlDeliveries.allDocumentsType');
    if (key === 'RECEIPT-TAXINVOICE') return t('xmlDeliveries.receipt-taxinvoice');
    if (key === 'RECEIPT-TAXINVOICE-ABB') return t('xmlDeliveries.receipt-taxinvoice-abb');
    if (key === 'RECEIPT') return t('xmlDeliveries.receipt');
    if (key === 'INVOICE-TAXINVOICE') return t('xmlDeliveries.invoice-taxinvoice');
    if (key === 'DELIVERYORDER-TAXINVOICE') return t('xmlDeliveries.deliveryorder-taxinvoice');
    if (key === 'TAXINVOICE') return t('xmlDeliveries.taxinvoice');
    if (key === 'DEBIT-NOTE') return t('xmlDeliveries.debit-note');
    if (key === 'CREDIT-NOTE') return t('xmlDeliveries.credit-note');
    if (key === 'QUOTATION') return t('xmlDeliveries.quotation');
    if (key === 'INVOICE') return t('xmlDeliveries.invoice');
    if (key === 'BILLING-NOTE') return t('xmlDeliveries.billing-note');
    if (key === 'DELIVERYORDER') return t('xmlDeliveries.deliveryorder');

    if (key === 'created') return t('xmlDeliveries.createdDate');
    if (key === 'document') return t('xmlDeliveries.documentDate');
  }

  let commandBarItems = [
    {
      key: rdStatusType.key,
      text: getRdStatusTypeText(rdStatusType.key),
      cacheKey: "myCacheKey", // changing this key will invalidate this item's cache
      iconProps: { iconName: "Page" },
      disabled: !loadDataComplete || isProcessDocument,
      subMenuProps: {
        items: [
          {
            key: "all",
            text: t("xmlDeliveries.allRdStatus"),
            iconProps: { iconName: "Page" },
            onClick: () => {
              setRdStatusType({
                key: "all",
                text: t("xmlDeliveries.allRdStatus"),
              });
              sessionStorage.setItem("uploadXml-rdStatusType-key", "all");
              sessionStorage.setItem("uploadXml-rdStatusType-text", t("xmlDeliveries.allRdStatus"));

              setLoadDataComplete(false);
              setDocuments([])
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalAmount(0);
              // setTotalGrand(0);

              setTotalPending(0);
              setTotalSpPending(0);
              setTotalSuccessful(0);
              setTotalFail(0);

              setCurrentPage(1);

              resetSort();
            },
            //['data-automation-id']: 'newEmailButton', // optional
          },
          {
            key: "pending",
            text: t("xmlDeliveries.pendingRdStatus"),
            iconProps: { iconName: "Page" },
            onClick: () => {
              setRdStatusType({
                key: "pending",
                text: t("xmlDeliveries.pendingRdStatus"),
              });
              sessionStorage.setItem("uploadXml-rdStatusType-key", "pending");
              sessionStorage.setItem("uploadXml-rdStatusType-text", t("xmlDeliveries.pendingRdStatus"));

              setLoadDataComplete(false);
              setDocuments([])
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalAmount(0);
              // setTotalGrand(0);

              setTotalPending(0);
              setTotalSpPending(0);
              setTotalSuccessful(0);
              setTotalFail(0);

              setCurrentPage(1);

              resetSort();
            },
            //['data-automation-id']: 'newEmailButton', // optional
          },
          {
            key: "sp-pending",
            text: t("xmlDeliveries.spPendingRdStatus"),
            iconProps: { iconName: "Page" },
            onClick: () => {
              setRdStatusType({
                key: "sp-pending",
                text: t("xmlDeliveries.spPendingRdStatus")
              });
              sessionStorage.setItem("uploadXml-rdStatusType-key", "sp-pending");
              sessionStorage.setItem("uploadXml-rdStatusType-text", t("xmlDeliveries.spPendingRdStatus"));

              setLoadDataComplete(false);
              setDocuments([])
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalAmount(0);
              // setTotalGrand(0);

              setTotalPending(0);
              setTotalSpPending(0);
              setTotalSuccessful(0);
              setTotalFail(0);

              setCurrentPage(1);

              resetSort();
            },
          },
          {
            key: "successful",
            text: t("xmlDeliveries.successfulRdStatus"),
            iconProps: { iconName: "Page" },
            onClick: () => {
              setRdStatusType({
                key: "successful",
                text: t("xmlDeliveries.successfulRdStatus"),
              });
              sessionStorage.setItem("uploadXml-rdStatusType-key", "successful");
              sessionStorage.setItem("uploadXml-rdStatusType-text", t("xmlDeliveries.successfulRdStatus"));

              setLoadDataComplete(false);
              setDocuments([])
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalAmount(0);
              // setTotalGrand(0);

              setTotalPending(0);
              setTotalSpPending(0);
              setTotalSuccessful(0);
              setTotalFail(0);

              setCurrentPage(1);

              resetSort();
            },
            //['data-automation-id']: 'newEmailButton', // optional
          },
          {
            key: "fail",
            text: t("xmlDeliveries.failRdStatus"),
            iconProps: { iconName: "Page" },
            onClick: () => {
              setRdStatusType({
                key: "fail",
                text: t("xmlDeliveries.failRdStatus"),
              });
              sessionStorage.setItem("uploadXml-rdStatusType-key", "fail");
              sessionStorage.setItem("uploadXml-rdStatusType-text", t("xmlDeliveries.failRdStatus"));

              setLoadDataComplete(false);
              setDocuments([])
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalAmount(0);
              // setTotalGrand(0);

              setTotalPending(0);
              setTotalSpPending(0);
              setTotalSuccessful(0);
              setTotalFail(0);

              setCurrentPage(1);

              resetSort();
            },
          }
        ],
      },
    },
    {
      key: documentType.key,
      text: getDocumentTypeText(documentType.key),
      cacheKey: "myCacheKey", // changing this key will invalidate this item's cache
      iconProps: { iconName: "Page" },
      disabled: !loadDataComplete || isProcessDocument,
      subMenuProps: {
        items: [
          {
            key: "ALL",
            text: t("xmlDeliveries.allDocumentsType"),
            iconProps: { iconName: "Page" },
            onClick: () => {
              setDocumentType({
                key: "ALL",
                text: t("xmlDeliveries.allDocumentsType"),
              });
              sessionStorage.setItem("uploadXml-documentType-key", "ALL");
              sessionStorage.setItem("uploadXml-documentType-text", t("xmlDeliveries.allDocumentsType"));

              setLoadDataComplete(false);
              setDocuments([])
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalAmount(0);
              // setTotalGrand(0);

              setTotalPending(0);
              setTotalSpPending(0);
              setTotalSuccessful(0);
              setTotalFail(0);

              setCurrentPage(1);

              resetSort();
            },
            //['data-automation-id']: 'newEmailButton', // optional
          },
          {
            key: "RECEIPT-TAXINVOICE",
            text: t("xmlDeliveries.receipt-taxinvoice"),
            iconProps: { iconName: "Page" },
            onClick: () => {
              setDocumentType({
                key: "RECEIPT-TAXINVOICE",
                text: t("xmlDeliveries.receipt-taxinvoice"),
              });
              sessionStorage.setItem("uploadXml-documentType-key", "RECEIPT-TAXINVOICE");
              sessionStorage.setItem("uploadXml-documentType-text", t("xmlDeliveries.receipt-taxinvoice"));

              setLoadDataComplete(false);
              setDocuments([])
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalAmount(0);
              // setTotalGrand(0);

              setTotalPending(0);
              setTotalSpPending(0);
              setTotalSuccessful(0);
              setTotalFail(0);

              setCurrentPage(1);

              resetSort();
            },
            //['data-automation-id']: 'newEmailButton', // optional
          },
          {
            key: "RECEIPT",
            text: t("xmlDeliveries.receipt"),
            iconProps: { iconName: "Page" },
            onClick: () => {
              setDocumentType({
                key: "RECEIPT",
                text: t("xmlDeliveries.receipt"),
              });
              sessionStorage.setItem("uploadXml-documentType-key", "RECEIPT");
              sessionStorage.setItem("uploadXml-documentType-text", t("xmlDeliveries.receipt"));

              setLoadDataComplete(false);
              setDocuments([])
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalAmount(0);
              // setTotalGrand(0);

              setTotalPending(0);
              setTotalSpPending(0);
              setTotalSuccessful(0);
              setTotalFail(0);

              setCurrentPage(1);

              resetSort();
            },
            //['data-automation-id']: 'newEmailButton', // optional
          },
          {
            key: "INVOICE-TAXINVOICE",
            text: t("xmlDeliveries.invoice-taxinvoice"),
            iconProps: { iconName: "Page" },
            onClick: () => {
              setDocumentType({
                key: "INVOICE-TAXINVOICE",
                text: t("xmlDeliveries.invoice-taxinvoice"),
              });
              sessionStorage.setItem("uploadXml-documentType-key", "INVOICE-TAXINVOICE");
              sessionStorage.setItem("uploadXml-documentType-text", t("xmlDeliveries.invoice-taxinvoice"));

              setLoadDataComplete(false);
              setDocuments([])
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalAmount(0);
              // setTotalGrand(0);

              setTotalPending(0);
              setTotalSpPending(0);
              setTotalSuccessful(0);
              setTotalFail(0);

              setCurrentPage(1);

              resetSort();
            },
            //['data-automation-id']: 'newEmailButton', // optional
          },
          {
            key: "DELIVERYORDER-TAXINVOICE",
            text: t("xmlDeliveries.deliveryorder-taxinvoice"),
            iconProps: { iconName: "Page" },
            onClick: () => {
              setDocumentType({
                key: "DELIVERYORDER-TAXINVOICE",
                text: t("xmlDeliveries.deliveryorder-taxinvoice"),
              });
              sessionStorage.setItem("uploadXml-documentType-key", "DELIVERYORDER-TAXINVOICE");
              sessionStorage.setItem("uploadXml-documentType-text", t("xmlDeliveries.deliveryorder-taxinvoice"));

              setLoadDataComplete(false);
              setDocuments([])
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalAmount(0);
              // setTotalGrand(0);

              setTotalPending(0);
              setTotalSpPending(0);
              setTotalSuccessful(0);
              setTotalFail(0);

              setCurrentPage(1);

              resetSort();
            },
            //['data-automation-id']: 'newEmailButton', // optional
          },
          {
            key: "TAXINVOICE",
            text: t("xmlDeliveries.taxinvoice"),
            iconProps: { iconName: "Page" },
            onClick: () => {
              setDocumentType({
                key: "TAXINVOICE",
                text: t("xmlDeliveries.taxinvoice"),
              });
              sessionStorage.setItem("uploadXml-documentType-key", "TAXINVOICE");
              sessionStorage.setItem("uploadXml-documentType-text", t("xmlDeliveries.taxinvoice"));

              setLoadDataComplete(false);
              setDocuments([])
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalAmount(0);
              // setTotalGrand(0);

              setTotalPending(0);
              setTotalSpPending(0);
              setTotalSuccessful(0);
              setTotalFail(0);

              setCurrentPage(1);

              resetSort();
            },
            //['data-automation-id']: 'newEmailButton', // optional
          },
          {
            key: "DEBIT-NOTE",
            text: t("xmlDeliveries.debit-note"),
            iconProps: { iconName: "Page" },
            onClick: () => {
              setDocumentType({
                key: "DEBIT-NOTE",
                text: t("xmlDeliveries.debit-note"),
              });
              sessionStorage.setItem("uploadXml-documentType-key", "DEBIT-NOTE");
              sessionStorage.setItem("uploadXml-documentType-text", t("xmlDeliveries.debit-note"));

              setLoadDataComplete(false);
              setDocuments([])
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalAmount(0);
              // setTotalGrand(0);

              setTotalPending(0);
              setTotalSpPending(0);
              setTotalSuccessful(0);
              setTotalFail(0);

              setCurrentPage(1);

              resetSort();
            },
          },
          {
            key: "CREDIT-NOTE",
            text: t("xmlDeliveries.credit-note"),
            iconProps: { iconName: "Page" },
            onClick: () => {
              setDocumentType({
                key: "CREDIT-NOTE",
                text: t("xmlDeliveries.credit-note"),
              });
              sessionStorage.setItem("uploadXml-documentType-key", "CREDIT-NOTE");
              sessionStorage.setItem("uploadXml-documentType-text", t("xmlDeliveries.credit-note"));

              setLoadDataComplete(false);
              setDocuments([])
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalAmount(0);
              // setTotalGrand(0);

              setTotalPending(0);
              setTotalSpPending(0);
              setTotalSuccessful(0);
              setTotalFail(0);

              setCurrentPage(1);

              resetSort();
            },
          }
        ],
      },
    },
    {
          key: queryMonth.key,
          text: getDateText(queryMonth),
          iconProps: { iconName: "Calendar" },
          disabled: !loadDataComplete,
          subMenuProps: {
            items: [
              {
                key: "queryMonth",
              },
            ],
            onRenderMenuList: () => {
              return (
                <div style={{ width: 220 }}>
                  <Calendar
                    onSelectDate={(_date) => {
                      let monthNames = [
                        t("uploadXml.months01"),
                        t("uploadXml.months02"),
                        t("uploadXml.months03"),
                        t("uploadXml.months04"),
                        t("uploadXml.months05"),
                        t("uploadXml.months06"),
                        t("uploadXml.months07"),
                        t("uploadXml.months08"),
                        t("uploadXml.months09"),
                        t("uploadXml.months10"),
                        t("uploadXml.months11"),
                        t("uploadXml.months12"),
                      ];
    
                      setDate(_date);
    
                      sessionStorage.setItem("uploadXml-date", _date.toISOString());
    
                      let adjustDate = _date;
    
                      let year = adjustDate.getFullYear() + 543;
    
                      setQueryMonth({
                        key: adjustDate.getFullYear() + "" + String(adjustDate.getMonth() + 1).padStart(2, "0"),
                        text: monthNames[adjustDate.getMonth()] + " " + year,
                      });
    
                      sessionStorage.setItem(
                        "uploadXml-queryMonth-key",
                        adjustDate.getFullYear() + "" + String(adjustDate.getMonth() + 1).padStart(2, "0")
                      );
                      sessionStorage.setItem("uploadXml-queryMonth-text", monthNames[adjustDate.getMonth()] + " " + year);
    
                      setFrom(getFrom(adjustDate.getMonth(), adjustDate.getFullYear()));
                      setTo(getTo(adjustDate.getMonth(), adjustDate.getFullYear()));
    
                      sessionStorage.setItem("uploadXml-from", getFrom(adjustDate.getMonth(), adjustDate.getFullYear()));
                      sessionStorage.setItem("uploadXml-to", getTo(adjustDate.getMonth(), adjustDate.getFullYear()));
    
                      setLoadDataComplete(false);
                      setDocuments([])
                      setItems([]);
                      setGetNewDocuments(true);
    
                      setTotalDocuments(0);
                      setTotalVat(0);
                      setTotalAmount(0);
    
                      setTotalPending(0);
                      setTotalSpPending(0);
                      setTotalSuccessful(0);
                      setTotalFail(0);
    
                      setCurrentPage(1);
                    }}
                    isMonthPickerVisible={true}
                    highlightSelectedMonth={true}
                    isDayPickerVisible={false}
                    dateRangeType={DateRangeType.Day}
                    autoNavigateOnSelection={false}
                    showGoToToday={false}
                    value={date}
                    strings={DayPickerStrings}
                    showSixWeeksByDefault={true}
                  />
                </div>
              );
            },
          },
        },
    {
      key: pageSize,
      text: pageSize + t('xmlDeliveries.documentPerPage'),
      cacheKey: "myCacheKey", // changing this key will invalidate this item's cache
      iconProps: { iconName: "NumberedList" },
      disabled: !loadDataComplete || isProcessDocument,
      subMenuProps: {
        items: [
          {
            key: 10,
            text: "10" + t('xmlDeliveries.documentPerPage'),
            iconProps: { iconName: "NumberedList" },
            onClick: () => {
              setPageSize(10);
              sessionStorage.setItem("uploadXml-pageSize", 10);

              setLoadDataComplete(false);
              setDocuments([])
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalAmount(0);
              // setTotalGrand(0);

              setTotalPending(0);
              setTotalSpPending(0);
              setTotalSuccessful(0);
              setTotalFail(0);

              setCurrentPage(1)
            },
          },
          {
            key: 50,
            text: "50" + t('xmlDeliveries.documentPerPage'),
            iconProps: { iconName: "NumberedList" },
            onClick: () => {
              setPageSize(50);
              sessionStorage.setItem("uploadXml-pageSize", 50);

              setLoadDataComplete(false);
              setDocuments([])
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalAmount(0);
              // setTotalGrand(0);

              setTotalPending(0);
              setTotalSpPending(0);
              setTotalSuccessful(0);
              setTotalFail(0);

              setCurrentPage(1)
            },
          },
          {
            key: 100,
            text: "100" + t('xmlDeliveries.documentPerPage'),
            iconProps: { iconName: "NumberedList" },
            onClick: () => {
              setPageSize(100);
              sessionStorage.setItem("uploadXml-pageSize", 100);

              setLoadDataComplete(false);
              setDocuments([])
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalAmount(0);
              // setTotalGrand(0);

              setTotalPending(0);
              setTotalSpPending(0);
              setTotalSuccessful(0);
              setTotalFail(0);

              setCurrentPage(1)
            },
          },
          {
            key: 300,
            text: "300" + t('xmlDeliveries.documentPerPage'),
            iconProps: { iconName: "NumberedList" },
            onClick: () => {
              setPageSize(300);
              sessionStorage.setItem("uploadXml-pageSize", 300);

              setLoadDataComplete(false);
              setDocuments([])
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalAmount(0);
              // setTotalGrand(0);

              setTotalPending(0);
              setTotalSpPending(0);
              setTotalSuccessful(0);
              setTotalFail(0);

              setCurrentPage(1)
            },
          },
          {
            key: 500,
            text: "500" + t('xmlDeliveries.documentPerPage'),
            iconProps: { iconName: "NumberedList" },
            onClick: () => {
              setPageSize(500);
              sessionStorage.setItem("uploadXml-pageSize", 500);

              setLoadDataComplete(false);
              setDocuments([])
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalAmount(0);
              // setTotalGrand(0);

              setTotalPending(0);
              setTotalSpPending(0);
              setTotalSuccessful(0);
              setTotalFail(0);

              setCurrentPage(1)
            },
          }
        ],
      },
    },

    {
      key: currentPage,
      text: t('xmlDeliveries.page') + ' ' + currentPage,
      iconProps: { iconName: "Page" },
      disabled: !loadDataComplete || isProcessDocument,
      subMenuProps: {
        items: pageDropdown,
      },
    },
    /* 
    {
        key: "download",
        text: "ดาวน์โหลด",
        iconProps: { iconName: "Download" },
        disabled: !loadDataComplete || isProcessDocument,
        onClick: () => {
            console.log("Download");
            downloadZip();
        },
    },
    
    {
        key: "delete",
        text: "ลบ",
        iconProps: { iconName: "Delete" },
        disabled:
            !loadDataComplete ||
            isProcessDocument ||
            selectedItems.length == 0 ||
            !isAdmin,
        onClick: () => {
            console.log("Delete");
            console.log("selectionItems: ", selectedItems);

            let confirm = window.confirm(
                "กรุณายืนยัน การลบเอกสารที่เลือก จำนวน " +
                selectedItems.length +
                " เอกสาร"
            );

            if (confirm && isAdmin) {
                setIsProcessDocument(true);
                deleteSelectedDocuments();
            }
            /* 
            setLoadDataComplete(false);
            setDocuments([]);
            setItems([]);
            setGetNewDocuments(true);

            setTotalDocuments(0);
            setTotalVat(0);
            setTotalAmount(0);
            setTotalGrand(0);
*
        },
    },
     
    {
        key: "recreate-xml",
        text: "สร้าง XML ใหม่",
        iconProps: { iconName: "RepeatAll" },
        disabled: !loadDataComplete || isProcessDocument || selectedItems.length == 0 || !isAdmin,
        onClick: () => {
            console.log("Recreate XML");

            console.log("selectionItems: ", selectedItems);

            let confirm = window.confirm("กรุณายืนยัน การสร้างไฟล์ XML ใหม่, เอกสารที่เลือก จำนวน " + selectedItems.length + " เอกสาร");

            if (confirm && isAdmin) {
                setIsProcessDocument(true);
                reCreateXmlSelectedDocuments();

                
            }
        },
    },
*/
    {
      key: "refresh",
      text: t("xmlDeliveries.refresh"),
      iconProps: { iconName: "Refresh" },
      disabled: !loadDataComplete || isProcessDocument,
      onClick: () => {
        console.log("Refresh");

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);

        setTotalDocuments(0);
        setTotalVat(0);
        setTotalAmount(0);
        // setTotalGrand(0);

        setTotalPending(0);
        setTotalSpPending(0);
        setTotalSuccessful(0);
        setTotalFail(0);

        setCurrentPage(1);

        // resetSort();
      },
    },
  ];

  const toThaiDateString = (isoDateTime) => {
    let date = "";
    date = new Date(isoDateTime);

    //console.log(dateTime.toLocaleDateString() + ' ' + dateTime.toLocaleTimeString());
    /* 
  let monthNames = [
    "มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน",
    "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม.",
    "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"
  ];
*/
    let year = date.getFullYear() + 543;
    //let month = monthNames[date.getMonth()];
    let month = (date.getMonth() + 1).toString().padStart(2, "0");
    let numOfDay = date.getDate().toString().padStart(2, "0");

    let hour = date.getHours().toString().padStart(2, "0");
    let minutes = date.getMinutes().toString().padStart(2, "0");
    // let second = date.getSeconds().toString().padStart(2, "0");

    // return `${numOfDay}/${month}/${year} ${hour}:${minutes}:${second} น.`;
    return `${numOfDay}/${month}/${year} ${hour}:${minutes}`;
  };

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  useEffect(() => {
    console.log("userEffect initial Call!");

    let documentsCompaniesDetailsEndpoint = API_URL + "/companies-details";

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then(async (tokenResponse) => {

        axios
          .get(documentsCompaniesDetailsEndpoint, {
            headers: {
              Authorization: "Bearer " + tokenResponse.accessToken,
              'cid': companyId,
            },
          })
          .then((response) => {
            console.log("Documents companies details:", response.data);

            let _companiesDetails = response.data;

            // if (_companiesDetails.length > 0) {

              if (_companiesDetails.settings?.etax?.serviceProvider?.enabled) {
                setServiceProviderEnable(true);
                console.log("serviceProviderEnable: true");
              } else {
                setServiceProviderEnable(false);
                console.log("serviceProviderEnable: false");
              }

            // }

            setIsLoadCompaneisDetailsComplete(true);
          })
          .catch((error) => console.log(error));


        let etaxXmlDeliveriesSettingsEndpoint = API_URL + "/etax/xml/deliveries/settings";

        axios
          .get(etaxXmlDeliveriesSettingsEndpoint, {
            headers: {
              Authorization: "Bearer " + tokenResponse.accessToken,
            },
          })
          .then((response) => {
            console.log("etaxXmlDeliveriesSettings response:", response.data);

            let etaxXmlDeliveriesSettings = response.data;

            if (etaxXmlDeliveriesSettings.xmlCheckEnable) {

              setXmlCheckEnable(etaxXmlDeliveriesSettings.xmlCheckEnable);

            } 

            if (etaxXmlDeliveriesSettings.xmlCheckNotification) {

              setXmlCheckNotification(etaxXmlDeliveriesSettings.xmlCheckNotification);

            } 

          })
          .catch((error) => console.log(error));


      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    console.log("userEffect getNewDocuments Call!");

    if (getNewDocuments) {
      setShowRepeatNumber(false);
      setDuplicatedNumbers([])

      let documentsListEndpoint =
        API_URL +
        // 'http://localhost:7071/api' +
        "/etax/xml/deliveries/" +
        documentType.key +
        "/?from=" +
        from +
        "&to=" +
        to +
        "&date=" +
        dateType.key +
        "&offset=0&limit=20000&deleted=false&rdStatus=" +
        rdStatusType.key +
        "&sortDirection=" +
        sortDirection +
        "&sortBy=" +
        primarySort;
      // let documentsSummaryEndpoint = API_URL + "/documents/summary/" + documentType.key + "/pdf?from=" + from + "&to=" + to + "&date=" + dateType.key + "&deleted=false&rdStatus=" + rdStatusType.key;
      // let documentsSummaryEndpoint = API_URL + "/documents/summary/" + documentType.key + "/xml?from=" + from + "&to=" + to + "&date=" + dateType.key + "&deleted=false&companiesId=" + companiesFilter.key + "&rdStatus=" + rdStatusType.key;
      // let documentsSummaryEndpoint = 'http://localhost:7071/api' + '/documents/summaries/' + documentType.key + '/xml?from=' + from + '&to=' + to + '&date=' + dateType.key + '&deleted=' + deletedStatus.key + '&companiesId=' + companiesFilter.key;
      // let xmlDeliveriesSummaryEndpoint = "http://localhost:7071/api/etax/deliveries/xml/summaries/" + documentType.key + "/pdf?from=" + from + "&to=" + to + "&date=" + dateType.key + "&deleted=false&rdStatus=" + rdStatusType.key;
      let xmlDeliveriesSummaryEndpoint = API_URL + "/etax/xml/deliveries/summaries/" + documentType.key + "/?from=" + from + "&to=" + to + "&date=" + dateType.key;
      // let xmlDeliveriesSummaryEndpoint = "http://localhost:7071/api/etax/xml/deliveries/summaries/" + documentType.key + "/?from=" + from + "&to=" + to + "&date=" + dateType.key;

      // let documentsCompaniesDetailsEndpoint = 'http://localhost:7071/api' + '/companies/name/' + documentType.key + '/xml?from=' + from + '&to=' + to + '&date=' + dateType.key + '&deleted=' + deletedStatus.key;

      instance
        .acquireTokenSilent({ ...silentRequest, account: account })
        .then(async (tokenResponse) => {
          // axios
          //   .get('http://localhost:7071/api/DocumentsTotal', {
          //     headers: {
          //       Authorization: 'Bearer ' + tokenResponse.accessToken,
          //     },
          //     params: {
          //       documentType: documentType.key,
          //       from: from,
          //       to: to,
          //       dateType: dateType.key,
          //       accessToken: tokenResponse.accessToken,
          //     },
          //   })
          //   .then((response) => {
          //     console.log('Documents summary:', response);

          //     const { countTotal, totalGrand, totalVat } = response.data;

          //     setTotalDocuments(countTotal);
          //      setTotalAmount(0);
          //     setTotalGrand(totalGrand);
          //     setTotalVat(totalVat);
          //   })
          //   .catch((error) => console.log(error));
          /* 
                    axios
                      .get(documentsSummaryEndpoint, {
                        headers: {
                          Authorization: "Bearer " + tokenResponse.accessToken,
                          'cid': companyId,
                        },
                      })
                      .then((response) => {
                        console.log("Documents summary:", response);
          
                        const { countTotal, totalGrand, totalVat } = response.data;
                        // const { totalPending, totalFail, totalSuccessful, totalNotDelivery } = response.data.deliveries.rd;
          
                        console.log("countTotal:", countTotal);
          
                        setTotalDocuments(countTotal);
            setTotalAmount(0);
                        setTotalGrand(totalGrand);
                        setTotalVat(totalVat);
                        /* 
                                      setTotalPending(totalPending);
                                      setTotalFail(totalFail);
                                      setTotalSuccessful(totalSuccessful);
                                      setTotalNotDelivery(totalNotDelivery);
                                       /
                      })
                      .catch((error) => console.log(error));
           */
          axios
            .get(xmlDeliveriesSummaryEndpoint, {
              headers: {
                Authorization: "Bearer " + tokenResponse.accessToken,
                'cid': companyId,
              },
            })
            .then((response) => {
              console.log("Xml deliveries summary:", response);

              const { countTotal, totalAmount, totalVat, successfulTotal, failTotal, spPendingTotal, pendingTotal } = response.data;
              // const { totalPending, totalFail, totalSuccessful, totalNotDelivery } = response.data.deliveries.rd;

              console.log("countTotal:", countTotal);

              setTotalDocuments(countTotal);
              setTotalVat(totalVat);
              setTotalAmount(totalAmount);
              // setTotalGrand(totalGrand);

              setTotalSuccessful(successfulTotal);
              setTotalFail(failTotal);
              setTotalSpPending(spPendingTotal);
              setTotalPending(pendingTotal);
              /* 
                            setTotalPending(totalPending);
                            setTotalFail(totalFail);
                            setTotalSuccessful(totalSuccessful);
                            setTotalNotDelivery(totalNotDelivery);
                             */
            })
            .catch((error) => console.log(error));



          const [response] = await Promise.all([
            axios
              .get(documentsListEndpoint, {
                headers: {
                  Authorization: "Bearer " + tokenResponse.accessToken,
                  'cid': companyId,
                },
              })
              .catch((error) => console.log(error)),
          ]);

          console.log("response pdf", response);

          let _documents = response.data.data.documents;
          let _totalDocument = response.data.data.countTotal;

          setDocuments(_documents)

          _documents.forEach((doc) => {
            if (doc.type === "CREDIT-NOTE") {
              doc.data.amountTotal = -Math.abs(doc.data.refer.diffAmountTotal);
              doc.data.grandTotal = -Math.abs(doc.data.grandTotal);
              doc.data.vatTotal = -Math.abs(doc.data.vatTotal);
            } else if (doc.type === "DEBIT-NOTE") {
              doc.data.amountTotal = doc.data.refer.diffAmountTotal;
            }
          });

          //Page dropdown
          let _pageDropdown = [];

          for (let i = 1; i <= Math.ceil(_totalDocument / pageSize); i++) {
            _pageDropdown.push({
              key: i,
              text: t('xmlDeliveries.page') + ' ' + i,
              iconProps: { iconName: "Page" },
              onClick: () => {
                console.log("Selected page: " + i);
                setCurrentPage(i);
              },
            });
          }

          setPageDropdown(_pageDropdown);

          if (currentPage > Math.ceil(_totalDocument / pageSize)) {
            setCurrentPage(1);
          }

          //set page
          setPageData(paginate(_totalDocument, currentPage, pageSize, 10));

          const _duplicatedNumberDocuments = findDuplicates(_documents)

          if (_duplicatedNumberDocuments.length) {
            const _duplicatedNumbers = _duplicatedNumberDocuments.map((doc) => doc.data.number);
            const uniq = [...new Set(_duplicatedNumbers)];
            setDuplicatedNumbers(uniq)
          }

          setShowRepeatNumber(_duplicatedNumberDocuments.length ? true : false);
          setUploadAble(_duplicatedNumberDocuments.length ? false : true)

          let _docs = [];

          if (_documents.length > 0) {
            for (let i = 0; i < _documents.length; i++) {
              let fileSize = formatBytes(_documents[i].file.size);
              let createdTime = toThaiDateString(_documents[i].createdTime);

              let rdDeliveredTime = "-";
              if (_documents[i].deliveries.rd.time) {
                rdDeliveredTime = toThaiDateString(_documents[i].deliveries.rd.time);
              }

              let rdRefNumber = "-";
              if (_documents[i].deliveries.rd.refNumber) {
                rdRefNumber = _documents[i].deliveries.rd.refNumber;
              }

              let _companyEmail = "";

              let _customerEmail = "";
              let _customerPhone = "";
              let _amountTotal = 0;
              let _vatTotal = 0;
              let _grandTotal = 0;

              if (_documents[i].data.company && _documents[i].data.company.email && _documents[i].data.company.email !== null && _documents[i].data.company.email !== undefined) {
                _companyEmail = _documents[i].data.company.email;
              }

              if (_documents[i].data.customer && _documents[i].data.customer.email && _documents[i].data.customer.email !== null && _documents[i].data.customer.email !== undefined) {
                _customerEmail = _documents[i].data.customer.email;
              }

              if (_documents[i].data.customer && _documents[i].data.customer.phone && _documents[i].data.customer.phone !== null && _documents[i].data.customer.phone !== undefined) {
                _customerPhone = _documents[i].data.customer.phone;
              }

              if (_documents[i].type === "WITHHOLDING-TAX") {
                _amountTotal = "-";
                _vatTotal = "-";
                _grandTotal = "-";
              } else {
                if (_documents[i].data.amountTotal) {
                  _amountTotal = _documents[i].data.amountTotal;
                }

                if (_documents[i].data.vatTotal) {
                  _vatTotal = _documents[i].data.vatTotal;
                }

                if (_documents[i].data.grandTotal) {
                  _grandTotal = _documents[i].data.grandTotal;
                }
              }

              let _isPdfXml = false;

              if (_documents[i].xmlFile && _documents[i].xmlFile.size) {
                _isPdfXml = true;
              }

              /* 
                                          let _rdStatus = "-";
              
                                          if (
                                              _documents[i].deliveries.rd.status ===
                                              "pending"
                                          ) {
                                              _rdStatus = "รอส่งอัตโนมัติ";
                                          } else if (
                                              _documents[i].deliveries.rd.status ===
                                              "successful"
                                          ) {
                                              _rdStatus = "สำเร็จ";
                                          } else if (
                                              _documents[i].deliveries.rd.status ===
                                              "fail"
                                          ) {
                                              _rdStatus = "ไม่สำเร็จ";
                                          }
               */
              let _rdStatus = "-";

              if (_documents[i].deliveries.rd.status === "pending") {
                _rdStatus = "-";
              } else if (_documents[i].deliveries.rd.status === "") {
                _rdStatus = "-";
              } else if (_documents[i].deliveries.rd.status === null) {
                _rdStatus = "-";
              } else if (_documents[i].deliveries.rd.status === "sp-pending") {
                _rdStatus = t("xmlDeliveries.spPendingRdStatusShort");
              } else if (_documents[i].deliveries.rd.status === "successful") {
                _rdStatus = t("xmlDeliveries.successfulRdStatus");
              } else if (_documents[i].deliveries.rd.status === "fail") {
                _rdStatus = t("xmlDeliveries.failRdStatus");
              }

              // console.log("_rdStatus:", _rdStatus);

              // let _rdStatusResponse = "-";
              /* 
                            if (_documents[i].deliveries && _documents[i].deliveries.rd && _documents[i].deliveries.rd.response) {
                              if (_documents[i].deliveries.rd.response.status === true) {
                                _rdStatusResponse = "สำเร็จ";
                              } else if (_documents[i].deliveries.rd.response.status === false) {
                                _rdStatusResponse = "ไม่สำเร็จ";
                              }
                            }
               */
              /* 
                                          let _deleted = "-";
              
                                          if (_documents[i].deleted) {
                                              _deleted = "ลบ"
                                          }
               */
              _docs.push({
                key: _documents[i].id,
                pdfFileName: _documents[i].file.displayName,
                name: _documents[i].data.name,
                fileExtension: _documents[i].file.extension,
                isPdfXml: _isPdfXml,
                createdBy: _documents[i].createdBy,
                createdTime: createdTime,
                createdTimeValue: _documents[i].createdTime,
                fileSize: fileSize,
                fileSizeRaw: _documents[i].file.size,
                type: _documents[i].type,
                signature: _documents[i].signatures[0].certificateName,
                product: _documents[i].product,
                number: _documents[i].data.number,
                companyEmail: _companyEmail,
                customerName: _documents[i].data.customer.name,
                customerEmail: _customerEmail,
                customerPhone: _customerPhone,
                amountTotal: _amountTotal,
                vatTotal: _vatTotal,
                grandTotal: _grandTotal,
                data: _documents[i].data,
                documentDate: _documents[i].data.dateBE,
                xmlData: _documents[i].xmlFile,
                // rdStatus: _documents[i].deliveries.rd.status,
                digitalSign: _documents[i].digitalSign,
                reIssue: _documents[i].data.reIssue,
                emailReceiver: _documents[i]?.deliveries?.email?.receiver ? _documents[i].deliveries.email.receiver : _documents[i].data.customer.email,
                emailTime: _documents[i]?.deliveries?.email?.time ? toThaiDateString(_documents[i].deliveries.email.time) : "",
                emailTimeValue: _documents[i]?.deliveries?.email?.time,
                documentDateValue: _documents[i].data.date,
                smsTime: _documents[i]?.deliveries?.sms?.response?.date_created ? toThaiDateString(_documents[i]?.deliveries?.sms?.response?.date_created) : "",
                smsTimeValue: _documents[i]?.deliveries?.sms?.response?.date_created,
                // rdStatus: _rdStatus,
                companyName: _documents[i].data.company.name,
                deleted: _documents[i].deleted,
                rdStatus: _rdStatus,
                rdStatusValue: _documents[i]?.deliveries?.rd?.status,
                rdDeliveredTime: rdDeliveredTime,
                rdDeliveredTimeValue: _documents[i].deliveries.rd.time,
                rdRefNumber: rdRefNumber,
                isUploadXml: false,
              });
            }
          }

          console.log("Docs data", _docs);
          setItems(_docs);

          setLoadDataComplete(true);
          setGetNewDocuments(false);
        })
        .catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);
          instance.acquireTokenRedirect(silentRequest);
        });
    }
    // eslint-disable-next-line
  }, [getNewDocuments]);

  useEffect(() => {
      console.log("currentPage", currentPage)
      if (documents.length > 0) {
        const _documents = [...documents]
  
        const _docs = []
        for (let i = (currentPage - 1) * pageSize; i < Math.min(currentPage * pageSize, _documents.length); i++) {
          let fileSize = formatBytes(_documents[i].file.size);
          let createdTime = toThaiDateString(_documents[i].createdTime);
  
          let rdDeliveredTime = "-";
          if (_documents[i].deliveries.rd.time) {
            rdDeliveredTime = toThaiDateString(_documents[i].deliveries.rd.time);
          }
  
          let rdRefNumber = "-";
          if (_documents[i].deliveries.rd.refNumber) {
            rdRefNumber = _documents[i].deliveries.rd.refNumber;
          }
  
          let _companyEmail = "";
  
          let _customerEmail = "";
          let _customerPhone = "";
          let _amountTotal = 0;
          let _vatTotal = 0;
          let _grandTotal = 0;
  
          if (_documents[i].data.company && _documents[i].data.company.email && _documents[i].data.company.email !== null && _documents[i].data.company.email !== undefined) {
            _companyEmail = _documents[i].data.company.email;
          }
  
          if (_documents[i].data.customer && _documents[i].data.customer.email && _documents[i].data.customer.email !== null && _documents[i].data.customer.email !== undefined) {
            _customerEmail = _documents[i].data.customer.email;
          }
  
          if (_documents[i].data.customer && _documents[i].data.customer.phone && _documents[i].data.customer.phone !== null && _documents[i].data.customer.phone !== undefined) {
            _customerPhone = _documents[i].data.customer.phone;
          }
  
          if (_documents[i].type === "WITHHOLDING-TAX") {
            _amountTotal = "-";
            _vatTotal = "-";
            _grandTotal = "-";
          } else {
            if (_documents[i].data.amountTotal) {
              _amountTotal = _documents[i].data.amountTotal;
            }
  
            if (_documents[i].data.vatTotal) {
              _vatTotal = _documents[i].data.vatTotal;
            }
  
            if (_documents[i].data.grandTotal) {
              _grandTotal = _documents[i].data.grandTotal;
            }
          }
  
          let _isPdfXml = false;
  
          if (_documents[i].xmlFile && _documents[i].xmlFile.size) {
            _isPdfXml = true;
          }
  
          let _rdStatus = "-";
  
          if (_documents[i].deliveries.rd.status === "pending") {
            _rdStatus = "-";
          } else if (_documents[i].deliveries.rd.status === "") {
            _rdStatus = "-";
          } else if (_documents[i].deliveries.rd.status === null) {
            _rdStatus = "-";
          } else if (_documents[i].deliveries.rd.status === "sp-pending") {
            _rdStatus = t("xmlDeliveries.spPendingRdStatusShort");
          } else if (_documents[i].deliveries.rd.status === "successful") {
            _rdStatus = t("xmlDeliveries.successfulRdStatus");
          } else if (_documents[i].deliveries.rd.status === "fail") {
            _rdStatus = t("xmlDeliveries.failRdStatus");
          }
  
          _docs.push({
            key: _documents[i].id,
            pdfFileName: _documents[i].file.displayName,
            name: _documents[i].data.name,
            fileExtension: _documents[i].file.extension,
            isPdfXml: _isPdfXml,
            createdBy: _documents[i].createdBy,
            createdTime: createdTime,
            createdTimeValue: _documents[i].createdTime,
            fileSize: fileSize,
            fileSizeRaw: _documents[i].file.size,
            type: _documents[i].type,
            signature: _documents[i].signatures[0].certificateName,
            product: _documents[i].product,
            number: _documents[i].data.number,
            companyEmail: _companyEmail,
            customerName: _documents[i].data.customer.name,
            customerEmail: _customerEmail,
            customerPhone: _customerPhone,
            amountTotal: _amountTotal,
            vatTotal: _vatTotal,
            grandTotal: _grandTotal,
            data: _documents[i].data,
            documentDate: _documents[i].data.dateBE,
            xmlData: _documents[i].xmlFile,
            // rdStatus: _documents[i].deliveries.rd.status,
            digitalSign: _documents[i].digitalSign,
            reIssue: _documents[i].data.reIssue,
            emailReceiver: _documents[i]?.deliveries?.email?.receiver ? _documents[i].deliveries.email.receiver : _documents[i].data.customer.email,
            emailTime: _documents[i]?.deliveries?.email?.time ? toThaiDateString(_documents[i].deliveries.email.time) : "",
            emailTimeValue: _documents[i]?.deliveries?.email?.time,
            documentDateValue: _documents[i].data.date,
            smsTime: _documents[i]?.deliveries?.sms?.response?.date_created ? toThaiDateString(_documents[i]?.deliveries?.sms?.response?.date_created) : "",
            smsTimeValue: _documents[i]?.deliveries?.sms?.response?.date_created,
            // rdStatus: _rdStatus,
            companyName: _documents[i].data.company.name,
            deleted: _documents[i].deleted,
            rdStatus: _rdStatus,
            rdStatusValue: _documents[i]?.deliveries?.rd?.status,
            rdDeliveredTime: rdDeliveredTime,
            rdDeliveredTimeValue: _documents[i].deliveries.rd.time,
            rdRefNumber: rdRefNumber,
            isUploadXml: false,
          });
        }
  
        setItems(_docs);
      }

      // eslint-disable-next-line
    }, [currentPage])

  useEffect(() => {
    // console.log("isCreateDocumentComplete useEffect: ", isProcessDocumentComplete);

    if (loadDataComplete && !isProcessDocument) {
      let _items = selection.getItems();

      let _selectedItems = selection.getSelection();

      if (isProcessDocumentComplete) {
        setIsProcessDocumentComplete(false);
        for (let i = 0; i < _items.length; i++) {
          if (_items[i].status !== "") {
            _items[i].status = "";
          }
        }
      } else {
        for (let i = 0; i < _items.length; i++) {
          if (_items[i].status !== "") {
            _items[i].status = "";
          }
        }

        for (let i = 0; i < _selectedItems.length; i++) {
          for (let j = 0; j < _items.length; j++) {
            if (_items[j].key === _selectedItems[i].key) {
              _items[j].status = "pending";
            }
          }
        }
      }

      setItems(_items);
    }

    // eslint-disable-next-line
  }, [selectedItems]);

  const openFile = (event, item) => {
    if (item.fileExtension === "pdf") {
      openFilePdf(event, item);
    } else if (item.fileExtension === "xml") {
      openFileXml(event, item);
    }
  };

  const openFilePdf = (event, item) => {
    history.push({
      pathname: "/etax/documents/pdf/view",
      state: {
        item: item,
        backUrl: "/etax/xml/deliveries"
        //documentId: item.key,
        //certificateName: item.signature
      },
    });
  };

  const openFileXml = (event, item) => {
    history.push({
      pathname: "/etax/documents/xml/view",
      state: {
        item: item,
        backUrl: "/etax/xml/deliveries"
        /* 
    item: {
      key: item.id,
      signature: item.signatures[0].certificateName,
      createdTimeValue: item.createdTime,
      type: item.type,
      number: item.data.number,
      fileExtension: item.file.extension,
    }, */
        //documentId: item.key,
        //certificateName: item.signature
      },
    });
  };

  const onPageChange = (selectedPageIndex) => {
    console.log("selectedPageIndex: ", selectedPageIndex);
    setCurrentPage(selectedPageIndex + 1);
  };

  const setRdStatusSelectedDocuments = (status) => {

    let _items = selection.getItems();
    for (let i = 0; i < selectedItems.length; i++) {
      console.log("Upload XML selected document: ", selectedItems[i]);
      for (let j = 0; j < _items.length; j++) {
        //console.log('item key: ', _items);
        if (_items[j].key === selectedItems[i].key) {
          console.log("item key: ", _items[j].key);

          if (status === "waiting") {
            if ((_items[j].rdStatusValue !== "sp-pending") && (_items[j].rdStatusValue !== "successful") && (_items[j].rdStatusValue !== "fail")) {

              _items[j].rdStatus = t("xmlDeliveries.waiting");
              _items[j].rdStatusValue = "waiting";

            }
          }

          console.log("items: ", _items);
          setItems([..._items]);
        }
      }
    }

  }

  const uploadXmlSelectedDocuments = async () => {

    setRdStatusSelectedDocuments("waiting");

    let _items = selection.getItems();

    await instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then(async (tokenResponse) => {
        for (let i = 0; i < selectedItems.length; i++) {
          console.log("Upload XML selected document: ", selectedItems[i]);

          // setUploadingDocumentId(selectedItems[i].key);
          // setUploadingDocumentNumber(selectedItems[i].number);
          // setUploadingDocumentStatus("กำลังอัปโหลดไฟล์ XML...");

          console.log('uploadingXmlStop: ', uploadingXmlStop.current);

          if (uploadingXmlStop.current) {
            uploadingXmlStop.current = false;
            return;
          }

          if ((selectedItems[i].rdStatusValue === 'sp-pending') || (selectedItems[i].rdStatusValue === 'successful') || (selectedItems[i].rdStatusValue === 'fail')) {
            console.log('Already uploaded xml or successful or fail, document id: ', selectedItems[i].key);
            continue;
          }

          for (let j = 0; j < _items.length; j++) {
            //console.log('item key: ', _items);
            if (_items[j].key === selectedItems[i].key) {
              console.log("item key: ", _items[j].key);
              _items[j].rdStatus = t("xmlDeliveries.uploading");
              _items[j].rdStatusValue = "uploading";
              //_selectedItems[i].status = "pending";
              //setItems([]);
              //_items.sort();
              console.log("items: ", _items);
              setItems([..._items]);
              /* 
          await new Promise(resolve => {                        
              setTimeout(resolve, 1500)                        
          });
*/

              console.log("item: ", _items[j]);
              // let detail = await getInvoiceDetail(_items[j].number);

              const promiseXmlUpload = await new Promise(async (resolve) => {
                axios
                  .post(`${API_URL}/etax/xml/deliveries/${_items[j].key}`, {},
                  // .post(`http://localhost:7071/api/etax/xml/deliveries/${_items[j].key}`, {},
                    {
                      headers: {
                        Authorization: "Bearer " + tokenResponse.accessToken,
                        'cid': companyId,
                      },
                    }
                  )
                  .then(
                    async (response) => {
                      console.log("Upload XML response: ", response);

                      let count = 0;

                      let interval = await setInterval(async () => {
                        count += 1;

                        console.log('count: ', count);

                        if (response.data && (response.data?.convoId && response.data?.timestamp)) {

                          //Ginko Soft Response

                          _items[j].rdStatus = t("xmlDeliveries.spPendingRdStatusShort");
                          _items[j].rdStatusValue = "sp-pending";

                          _items[j].rdDeliveredTime = toThaiDateString(response.data.timestamp);
                          _items[j].rdDeliveredTimeValue = response.data.timestamp;
                          _items[j].rdRefNumber = response.data.convoId;
                          // _items[j].rdStatusResponse = "สำเร็จ";


                          setItems([..._items]);

                          clearInterval(interval);

                          // setUploadingDocumentStatus("อัปโหลดไฟล์ XML สำเร็จ");

                          // setUploadingDocumentMessage(response.data?.data[0]?.data[0].message);

                          // setUploadingDocumentFileName(response.data?.data[0]?.filename);

                          // setUploadingDocumentRefNo(response.data?.data[0]?.refno);

                          resolve(true);

                        } else if (response.data && response.data?.data && (response.data?.data.resultCode === "200")) {

                          //Stream IT Consulting Response

                          let rdDeliveredTime = "-";
                          if (response.data?.data?.responseDTTM) {

                            rdDeliveredTime = response.data?.data?.responseDTTM.replaceAll("-", "/");

                          }

                          _items[j].rdStatus = t("xmlDeliveries.spPendingRdStatusShort");
                          _items[j].rdStatusValue = "sp-pending";

                          // _items[j].rdDeliveredTime = toThaiDateString(response.data.timestamp);
                          _items[j].rdDeliveredTime = toThaiDateString(response.data?.data?.responseDTTM);
                          // _items[j].rdDeliveredTime = rdDeliveredTime;
                          _items[j].rdDeliveredTimeValue = rdDeliveredTime;
                          // _items[j].rdRefNumber = response.data.convoId;
                          // _items[j].rdStatusResponse = "สำเร็จ";


                          setItems([..._items]);

                          clearInterval(interval);

                          // setUploadingDocumentStatus("อัปโหลดไฟล์ XML สำเร็จ");

                          // setUploadingDocumentMessage(response.data?.data[0]?.data[0].message);

                          // setUploadingDocumentFileName(response.data?.data[0]?.filename);

                          // setUploadingDocumentRefNo(response.data?.data[0]?.refno);

                          resolve(true);

                        } else if (count === 3) {

                          _items[j].rdStatus = t("xmlDeliveries.failRdStatus");
                          _items[j].rdStatusValue = "fail";

                          setItems([..._items]);

                          clearInterval(interval);

                          resolve(false);

                        }
                        // Sleep before upload
                        // }, 500);
                      }, 300);
                    },
                    (error) => {
                      console.log("Error upload XML response: ", error);

                      _items[j].rdStatus = t("xmlDeliveries.failRdStatus");
                      _items[j].rdStatusValue = "fail";

                      setItems([..._items]);
                      resolve(false);
                    }
                  );
              }); //promise

              Promise.all([promiseXmlUpload]).then((value) => {
                console.log(i, value);
              });
            } //if
          } //for j
        } //for i
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });

    setIsProcessDocumentComplete(true);
    setIsProcessDocument(false);
  };

  const columns = [
    {
      key: "column1",
      name: t("xmlDeliveries.createdTime"),
      fieldName: "createdTime",
      minWidth: 90,
      maxWidth: 125,
      isRowHeader: true,
      isResizable: true,
      isSorted: primarySort === "createdTime" ? true : false,
      isSortedDescending: isSortedDescendingCreatedTime,
      isSortedAscending: !isSortedDescendingCreatedTime,
      onColumnClick: () => {
        console.log("Sort created time!");
        setPrimarySort("createdTime");
        setIsSortedDescendingCreatedTime(!isSortedDescendingCreatedTime);

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);
        // setPreviousSearch('');
        // setSearch('');

        setTotalDocuments(0);
        setTotalVat(0);
        setTotalAmount(0);
        // setTotalGrand(0);

        setTotalPending(0);
        setTotalSpPending(0);
        setTotalSuccessful(0);
        setTotalFail(0);

        setCurrentPage(1)

        sessionStorage.setItem("uploadXml-primarySort", "createdTime");
        sessionStorage.setItem("uploadXml-sort-createdTime", !isSortedDescendingCreatedTime === true ? "true" : "false");

        sessionStorage.setItem("uploadXml-sort-number", "false");
        sessionStorage.setItem("uploadXml-sort-documentDate", "false");
        sessionStorage.setItem("uploadXml-sort-customerName", "false");
        sessionStorage.setItem("uploadXml-sort-customerPhone", "false");
        sessionStorage.setItem("uploadXml-sort-vatTotal", "false");
        sessionStorage.setItem("uploadXml-sort-grandTotal", "false");
        sessionStorage.setItem("uploadXml-sort-name", "false");
        sessionStorage.setItem("uploadXml-sort-amountTotal", "false");
        sessionStorage.setItem("uploadXml-sort-rdStatus", "false");

        if (!isSortedDescendingCreatedTime) {
          setSortDirection("DESC");
          sessionStorage.setItem("uploadXml-sortDirection", "DESC");
        } else {
          setSortDirection("ASC");
          sessionStorage.setItem("uploadXml-sortDirection", "ASC");
        }
      },
      data: "string",
      onRender: (item) => {

        return <TooltipHost content={item.createdTime}><span>{item.createdTime}</span></TooltipHost>;
      },
      // isPadded: true,
    },
    {
      key: "column2",
      name: t("xmlDeliveries.number"),
      fieldName: "number",
      minWidth: 110,
      maxWidth: 150,
      isRowHeader: true,
      isResizable: true,
      isSorted: primarySort === "number" ? true : false,
      isSortedDescending: isSortedDescendingNumber,
      isSortedAscending: !isSortedDescendingNumber,
      onColumnClick: () => {
        console.log("Sort document number!");
        setPrimarySort("number");
        setIsSortedDescendingNumber(!isSortedDescendingNumber);

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);
        // setPreviousSearch("");
        // setSearch("");

        setTotalDocuments(0);
        setTotalVat(0);
        setTotalAmount(0);
        // setTotalGrand(0);

        setTotalPending(0);
        setTotalSpPending(0);
        setTotalSuccessful(0);
        setTotalFail(0);

        setCurrentPage(1)

        sessionStorage.setItem("uploadXml-primarySort", "number");
        sessionStorage.setItem("uploadXml-sort-number", !isSortedDescendingNumber === true ? "true" : "false");

        sessionStorage.setItem("uploadXml-sort-createdTime", "false");
        sessionStorage.setItem("uploadXml-sort-documentDate", "false");
        sessionStorage.setItem("uploadXml-sort-customerName", "false");
        sessionStorage.setItem("uploadXml-sort-customerPhone", "false");
        sessionStorage.setItem("uploadXml-sort-vatTotal", "false");
        sessionStorage.setItem("uploadXml-sort-grandTotal", "false");
        sessionStorage.setItem("uploadXml-sort-name", "false");
        sessionStorage.setItem("uploadXml-sort-amountTotal", "false");
        sessionStorage.setItem("uploadXml-sort-rdStatus", "false");

        if (!isSortedDescendingNumber) {
          setSortDirection("DESC");
          sessionStorage.setItem("uploadXml-sortDirection", "DESC");
        } else {
          setSortDirection("ASC");
          sessionStorage.setItem("uploadXml-sortDirection", "ASC");
        }
      },
      data: "string",
      /* 
      onRender: (item) => {
          return (
              <TooltipHost content={item.key}>
                  <ActionButton
                      title="ดาวน์โหลด"
                      className={classNames.fileIconImg}
                      onClick={(event) => {
                          download(event, item);
                      }}
                  >
                      {item.deleted ? <span style={{ color: "#a4262c" }}>{item.number}</span> : item.number}
                  </ActionButton>
              </TooltipHost>
          );
      },
*/
      onRender: (item) => {
        return (<span data-selection-disabled={true}>{item.number}</span>);
      },
      // isPadded: true,
    },
    {
      key: "column3",
      name: t("xmlDeliveries.documentDate"),
      fieldName: "documentDate",
      minWidth: 80,
      maxWidth: 80,
      isRowHeader: true,
      isResizable: true,
      isSorted: primarySort === "documentDate" ? true : false,
      isSortedDescending: isSortedDescendingDocumentDate,
      isSortedAscending: !isSortedDescendingDocumentDate,
      onColumnClick: () => {
        console.log("Sort documentDate!");
        setPrimarySort("documentDate");
        setIsSortedDescendingDocumentDate(!isSortedDescendingDocumentDate);

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);
        // setPreviousSearch("");
        // setSearch("");

        setTotalDocuments(0);
        setTotalVat(0);
        setTotalAmount(0);
        // setTotalGrand(0);

        setTotalPending(0);
        setTotalSpPending(0);
        setTotalSuccessful(0);
        setTotalFail(0);

        setCurrentPage(1)

        sessionStorage.setItem("uploadXml-primarySort", "documentDate");
        sessionStorage.setItem("uploadXml-sort-documentDate", !isSortedDescendingDocumentDate === true ? "true" : "false");

        sessionStorage.setItem("uploadXml-sort-createdTime", "false");
        sessionStorage.setItem("uploadXml-sort-number", "false");
        sessionStorage.setItem("uploadXml-sort-customerName", "false");
        sessionStorage.setItem("uploadXml-sort-customerPhone", "false");
        sessionStorage.setItem("uploadXml-sort-vatTotal", "false");
        sessionStorage.setItem("uploadXml-sort-grandTotal", "false");
        sessionStorage.setItem("uploadXml-sort-name", "false");
        sessionStorage.setItem("uploadXml-sort-amountTotal", "false");
        sessionStorage.setItem("uploadXml-sort-rdStatus", "false");

        if (!isSortedDescendingDocumentDate) {
          setSortDirection("DESC");
          sessionStorage.setItem("uploadXml-sortDirection", "DESC");
        } else {
          setSortDirection("ASC");
          sessionStorage.setItem("uploadXml-sortDirection", "ASC");
        }
      },
      data: "string",
      onRender: (item) => {
        return <TooltipHost content={item.documentDate}><span>{item.documentDate}</span></TooltipHost>;
      },
      //isPadded: true,
    },
    {
      key: "column4",
      name: t("xmlDeliveries.customerName"),
      fieldName: "customerName",
      minWidth: 50,
      maxWidth: 150,
      isRowHeader: true,
      isResizable: true,
      isSorted: primarySort === "customerName" ? true : false,
      isSortedDescending: isSortedDescendingCustomerName,
      isSortedAscending: !isSortedDescendingCustomerName,
      onColumnClick: () => {
        console.log("Sort customerName!");
        setPrimarySort("customerName");
        setIsSortedDescendingCustomerName(!isSortedDescendingCustomerName);

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);
        // setPreviousSearch("");
        // setSearch("");

        setTotalDocuments(0);
        setTotalVat(0);
        setTotalAmount(0);
        // setTotalGrand(0);

        setTotalPending(0);
        setTotalSpPending(0);
        setTotalSuccessful(0);
        setTotalFail(0);

        setCurrentPage(1)

        sessionStorage.setItem("uploadXml-primarySort", "customerName");
        sessionStorage.setItem("uploadXml-sort-customerName", !isSortedDescendingCustomerName === true ? "true" : "false");

        sessionStorage.setItem("uploadXml-sort-createdTime", "false");
        sessionStorage.setItem("uploadXml-sort-documentDate", "false");
        sessionStorage.setItem("uploadXml-sort-number", "false");
        sessionStorage.setItem("uploadXml-sort-customerPhone", "false");
        sessionStorage.setItem("uploadXml-sort-vatTotal", "false");
        sessionStorage.setItem("uploadXml-sort-grandTotal", "false");
        sessionStorage.setItem("uploadXml-sort-name", "false");
        sessionStorage.setItem("uploadXml-sort-amountTotal", "false");
        sessionStorage.setItem("uploadXml-sort-rdStatus", "false");

        if (!isSortedDescendingCustomerName) {
          setSortDirection("DESC");
          sessionStorage.setItem("uploadXml-sortDirection", "DESC");
        } else {
          setSortDirection("ASC");
          sessionStorage.setItem("uploadXml-sortDirection", "ASC");
        }
      },
      data: "string",
      onRender: (item) => {
        return <TooltipHost content={item.customerName}>{item.customerName}</TooltipHost>;
      },
      // isPadded: true,
    },
    /* 
        {
          key: "column5",
          name: "ยอด (รวม Vat)",
          fieldName: "grandTotal",
          minWidth: 70,
          maxWidth: 100,
          isRowHeader: true,
          isResizable: true,
          isSorted: primarySort === "grandTotal" ? true : false,
          isSortedDescending: isSortedDescendingGrandTotal,
          isSortedAscending: !isSortedDescendingGrandTotal,
          isCollapsible: true,
          onColumnClick: () => {
            console.log("Sort grandTotal!");
            setPrimarySort("grandTotal");
            setIsSortedDescendingGrandTotal(!isSortedDescendingGrandTotal);
    
            setLoadDataComplete(false);
            setDocuments([]);
            setItems([]);
            setGetNewDocuments(true);
            // setPreviousSearch("");
            // setSearch("");
    
            setTotalDocuments(0);
            setTotalVat(0);
            setTotalAmount(0);
            setTotalGrand(0);
    
            sessionStorage.setItem("uploadXml-primarySort", "grandTotal");
            sessionStorage.setItem("uploadXml-sort-grandTotal", !isSortedDescendingGrandTotal === true ? "true" : "false");
    
            sessionStorage.setItem("uploadXml-sort-createdTime", "false");
            sessionStorage.setItem("uploadXml-sort-number", "false");
            sessionStorage.setItem("uploadXml-sort-documentDate", "false");
            sessionStorage.setItem("uploadXml-sort-customerName", "false");
            sessionStorage.setItem("uploadXml-sort-customerPhone", "false");
            sessionStorage.setItem("uploadXml-sort-vatTotal", "false");
            sessionStorage.setItem("uploadXml-sort-name", "false");
    
            if (!isSortedDescendingGrandTotal) {
              setSortDirection("DESC");
              sessionStorage.setItem("uploadXml-sortDirection", "DESC");
            } else {
              setSortDirection("ASC");
              sessionStorage.setItem("uploadXml-sortDirection", "ASC");
            }
          },
          data: "number",
          onRender: (item) => {
            return (
              <span style={{ display: "block", textAlign: "right" }}>
                {item.grandTotal.toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })}
              </span>
            );
          },
          // isPadded: true,
        },
         */
    {
      key: "column5",
      name: t("xmlDeliveries.amountTotal"),
      fieldName: "amountTotal",
      minWidth: 70,
      maxWidth: 100,
      isRowHeader: true,
      isResizable: true,
      isSorted: primarySort === "amountTotal" ? true : false,
      isSortedDescending: isSortedDescendingAmountTotal,
      isSortedAscending: !isSortedDescendingAmountTotal,
      isCollapsible: true,
      onColumnClick: () => {
        console.log("Sort amountTotal!");
        setPrimarySort("amountTotal");
        setIsSortedDescendingAmountTotal(!isSortedDescendingAmountTotal);

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);
        // setPreviousSearch("");
        // setSearch("");

        setTotalDocuments(0);
        setTotalVat(0);
        setTotalAmount(0);
        // setTotalGrand(0);

        setTotalPending(0);
        setTotalSpPending(0);
        setTotalSuccessful(0);
        setTotalFail(0);

        setCurrentPage(1)

        sessionStorage.setItem("uploadXml-primarySort", "amountTotal");
        sessionStorage.setItem("uploadXml-sort-amountTotal", !isSortedDescendingAmountTotal === true ? "true" : "false");

        sessionStorage.setItem("uploadXml-sort-createdTime", "false");
        sessionStorage.setItem("uploadXml-sort-number", "false");
        sessionStorage.setItem("uploadXml-sort-documentDate", "false");
        sessionStorage.setItem("uploadXml-sort-customerName", "false");
        sessionStorage.setItem("uploadXml-sort-customerPhone", "false");
        sessionStorage.setItem("uploadXml-sort-vatTotal", "false");
        sessionStorage.setItem("uploadXml-sort-name", "false");
        sessionStorage.setItem("uploadXml-sort-rdStatus", "false");
        /* 
                if (!isSortedDescendingGrandTotal) {
                  setSortDirection("DESC");
                  sessionStorage.setItem("uploadXml-sortDirection", "DESC");
                } else {
                  setSortDirection("ASC");
                  sessionStorage.setItem("uploadXml-sortDirection", "ASC");
                }
         */
        if (!isSortedDescendingAmountTotal) {
          setSortDirection("DESC");
          sessionStorage.setItem("uploadXml-sortDirection", "DESC");
        } else {
          setSortDirection("ASC");
          sessionStorage.setItem("uploadXml-sortDirection", "ASC");
        }
      },
      data: "number",
      onRender: (item) => {
        return (
          <span style={{ display: "block", textAlign: "right" }}>
            {item.amountTotal.toLocaleString(undefined, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}
          </span>
        );
      },
      // isPadded: true,
    },
    {
      key: "column6",
      name: t("xmlDeliveries.document"),
      fieldName: "name",
      minWidth: 50,
      maxWidth: 180,
      isCollapsible: true,
      isRowHeader: true,
      isResizable: true,
      isSorted: primarySort === "name" ? true : false,
      isSortedDescending: isSortedDescendingName,
      isSortedAscending: !isSortedDescendingName,
      onColumnClick: () => {
        console.log("Sort name!");
        setPrimarySort("name");
        setIsSortedDescendingName(!isSortedDescendingName);

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);
        // setPreviousSearch("");
        // setSearch("");

        setTotalDocuments(0);
        setTotalVat(0);
        setTotalAmount(0);
        // setTotalGrand(0);

        setTotalPending(0);
        setTotalSpPending(0);
        setTotalSuccessful(0);
        setTotalFail(0);

        setCurrentPage(1)

        sessionStorage.setItem("uploadXml-primarySort", "name");
        sessionStorage.setItem("uploadXml-sort-name", !isSortedDescendingName === true ? "true" : "false");

        sessionStorage.setItem("uploadXml-sort-createdTime", "false");
        sessionStorage.setItem("uploadXml-sort-number", "false");
        sessionStorage.setItem("uploadXml-sort-documentDate", "false");
        sessionStorage.setItem("uploadXml-sort-customerName", "false");
        sessionStorage.setItem("uploadXml-sort-customerPhone", "false");
        sessionStorage.setItem("uploadXml-sort-vatTotal", "false");
        sessionStorage.setItem("uploadXml-sort-grandTotal", "false");
        sessionStorage.setItem("uploadXml-sort-amountTotal", "false");
        sessionStorage.setItem("uploadXml-sort-rdStatus", "false");

        if (!isSortedDescendingName) {
          setSortDirection("DESC");
          sessionStorage.setItem("uploadXml-sortDirection", "DESC");
        } else {
          setSortDirection("ASC");
          sessionStorage.setItem("uploadXml-sortDirection", "ASC");
        }
      },
      data: "string",
      onRender: (item) => {
        /* 
                return (
                  <ActionButton
                    title="เปิดไฟล์ XML"
                    className={classNames.fileIconImg}
                    onClick={(event) => {
                      openFileXml(event, item);
                    }}
                  >
                    {item.name}
                  </ActionButton>
                );
         */
        return (
          <TooltipHost content={getDocumentTypeText(item.type)}>
            <span>{getDocumentTypeText(item.type)}</span>
          </TooltipHost>
        );

      },
      // isPadded: true,
    },
    {
      key: "column7",
      name: t("xmlDeliveries.rdTime"),
      fieldName: "rdDeliveredTime",
      minWidth: 90,
      maxWidth: 120,
      isResizable: true,
      //isSorted: true,
      //isSortedDescending: true,
      isCollapsible: false,
      onColumnClick: "",
      data: "string",
      onRender: (item) => {
        return (
          <TooltipHost content={item.rdDeliveredTime}>
            <span>{item.rdDeliveredTime}</span>
          </TooltipHost>
        );
      },
      //isPadded: true,
    },
    {
      key: "column8",
      name: t("xmlDeliveries.rdRefNumber"),
      fieldName: "rdRefNumber",
      minWidth: 240,
      maxWidth: 240,
      isResizable: true,
      //isSorted: true,
      //isSortedDescending: true,
      isCollapsible: false,
      onColumnClick: "",
      data: "string",
      onRender: (item) => {
        return (
          <TooltipHost content={item.rdRefNumber}>
            <span>{item.rdRefNumber}</span>
          </TooltipHost>
        );
      },
      //isPadded: true,
    },
    {
      key: "column9",
      name: t("xmlDeliveries.rdStatus"),
      fieldName: "status",
      minWidth: 90,
      maxWidth: 90,
      isResizable: false,
      //isSorted: true,
      //isSortedDescending: true,
      isCollapsible: false,
      isSorted: primarySort === "rdStatus" ? true : false,
      isSortedDescending: isSortedDescendingRdStatus,
      isSortedAscending: !isSortedDescendingRdStatus,
      onColumnClick: () => {
        setPrimarySort("rdStatus");
        setIsSortedDescendingRdStatus(!isSortedDescendingRdStatus);

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);
        // setPreviousSearch("");
        // setSearch("");

        setTotalDocuments(0);
        setTotalVat(0);
        setTotalAmount(0);
        // setTotalGrand(0);

        setTotalPending(0);
        setTotalSpPending(0);
        setTotalSuccessful(0);
        setTotalFail(0);

        setCurrentPage(1)

        sessionStorage.setItem("uploadXml-primarySort", "rdStatus");
        sessionStorage.setItem("uploadXml-sort-rdStatus", !isSortedDescendingRdStatus === true ? "true" : "false");

        sessionStorage.setItem("uploadXml-sort-createdTime", "false");
        sessionStorage.setItem("uploadXml-sort-number", "false");
        sessionStorage.setItem("uploadXml-sort-documentDate", "false");
        sessionStorage.setItem("uploadXml-sort-customerName", "false");
        sessionStorage.setItem("uploadXml-sort-customerPhone", "false");
        sessionStorage.setItem("uploadXml-sort-vatTotal", "false");
        sessionStorage.setItem("uploadXml-sort-grandTotal", "false");
        sessionStorage.setItem("uploadXml-sort-name", "false");
        sessionStorage.setItem("uploadXml-sort-amountTotal", "false");
        sessionStorage.setItem("uploadXml-sort-rdStatus", "false");

        if (!isSortedDescendingRdStatus) {
          setSortDirection("DESC");
          sessionStorage.setItem("uploadXml-sortDirection", "DESC");
        } else {
          setSortDirection("ASC");
          sessionStorage.setItem("uploadXml-sortDirection", "ASC");
        }
      },
      data: "string",
      onRender: (item) => {
        /* 
        return item.isUploadXml ? (
          <Spinner size={SpinnerSize.small} />
        ) : (item.rdStatus === 'นำส่งไม่สำเร็จ') ? (
          <span
            style={{
              display: "block",
              textAlign: "center",
              color: "#a4262c",
            }}
          >
            {item.rdStatus}
          </span>
        ) : (
          <span style={{ display: "block", textAlign: "left" }}>{item.rdStatus}</span>
        );


        if ((item.rdStatusValue === "") || (item.rdStatusValue === null)) return (<div data-selection-disabled={true}>
          <span style={{ display: 'block', textAlign: 'center' }}>-</span>
        </div>)
 */
        let _status = "-";
        if ((item.rdStatusValue === "") || (item.rdStatusValue === null)) {
          _status = <span style={{ display: 'block', textAlign: 'center' }}>-</span>
        } else if (item.rdStatusValue === "successful") {
          _status = <div data-selection-disabled={true}><span style={{ color: "green" }}>{t("xmlDeliveries.successfulRdStatus")}</span></div>
        } else if (item.rdStatusValue === "sp-pending") {
          _status = <div data-selection-disabled={true}><TooltipHost content={t("xmlDeliveries.spPendingRdStatus")}><span style={{ color: "#106EBE" }}>{t("xmlDeliveries.spPendingRdStatusShort")}</span></TooltipHost></div>
        } else if (item.rdStatusValue === "fail") {
          _status = <div data-selection-disabled={true}><span style={{ color: "red" }}>{t("xmlDeliveries.failRdStatus")}</span></div>
        } else if (item.rdStatusValue === "uploading") {
          _status = <Spinner label={t("xmlDeliveries.uploading")} labelPosition="right" style={{ marginLeft: '0px' }} />
        } else if (item.rdStatusValue === "waiting") {
          _status = <span style={{ color: "#106EBE" }}>{t("xmlDeliveries.waiting")}</span>
        }

        return (<div style={{ display: "flex", alignItems: "start", flexDirection: "row" }}>{_status}</div>);
        /* 
                return <div data-selection-disabled={true}>
                  <TooltipHost content={_status}>
                    <Stack verticalAlign='center' horizontalAlign='center'>
                      <div style={{ display: "flex", alignItems: "start", flexDirection: "row" }}>
                        {_status}
                        {item.rdStatusValue === "fail" ? (
                          <IconButton
                            styles={{ flexContainer: { display: "flex", alignItems: "start", marginTop: 4 } }}
                            onClick={() => {
                              // openFile(item);
                              // handleShowResponseMessage(item)
                            }}
                            iconProps={{
                              iconName: "Error"
                            }}
                          />
                        ) : null}
                      </div>
                    </Stack>
                  </TooltipHost>
                </div>
         */
      },

      // isPadded: true,
    },
    {
      key: "column10",
      name: t("xmlDeliveries.file"),
      // className: classNames.fileIconCell,
      // iconClassName: classNames.fileIconHeaderIcon,
      // ariaLabel: 'Column operations for File type, Press to sort on File type',
      // iconName: 'Page',
      // isIconOnly: true,
      fieldName: "name",
      minWidth: 70,
      maxWidth: 70,
      onColumnClick: "",

      onRender: (item) => (
        <>
          {/* <TooltipHost content={`ไฟล์ ${item.fileExtension}`}> */}
          {/* <img src={item.iconName} className={classNames.fileIconImg} alt={`${item.fileExtension} file icon`} /> */}
          <IconButton
            onClick={(event) => {
              openFile(event, item);
            }}
            iconProps={{
              ...getFileTypeIconProps({
                extension: item.fileExtension,
                size: 32,
                imageFileType: "svg",
              }),
            }}
          />
          {/* </TooltipHost> */}
          {item.isPdfXml && (
            <IconButton
              onClick={(event) => {
                openFileXml(event, item);
              }}
              iconProps={{
                ...getFileTypeIconProps({
                  extension: "xml",
                  size: 32,
                  imageFileType: "svg",
                }),
              }}
            />
          )}
        </>
      ),
    }
  ];


  const onRenderDetailsHeader = (props) => {
    if (!props) {
      return null;
    }

    const checked = () => {
      if (!items.length) {
        return false
      } else if (selection.getSelectedCount() === items.length || selection.getSelectedCount() === selection.getItems().filter(_item => _item.rdStatus === "-").length) {
        return true
      } else {
        return false
      }
    }

    return (

      // <div style={{ pointerEvents: "none" }}>
      <DetailsHeader
        {...props}
        // checkboxVisibility={CheckboxVisibility.always}
        // onColumnClick={(e, column) => console.log("column", column)}
        onRenderDetailsCheckbox={(checkboxProps) => {
          // console.log("checkboxProps", checkboxProps)
          return (
            <Checkbox
              styles={customCheckboxStyles}
              {...checkboxProps}
              // canSelect={true}
              // style={{ pointerEvents: "none" }}
              // selected={selection.getSelectedCount() === items.length || selection.getSelectedCount() === selection.getItems().filter(_item => _item.rdStatus === "-").length}
              // isVisible={true}

              checked={checked()}
              onChange={(e) => {
                // console.log("get all select", selection)

                if (selectedItems.length) {
                  setSelectedItems([]);
                  selection.getItems().forEach(_item => {
                    selection.setKeySelected(_item.key, false, false)
                  })
                } else {
                  let _selectedItems = selection.getItems().filter(_item => _item.rdStatus === "-")

                  // console.log("_selectedItems", _selectedItems)
                  setSelectedItems(_selectedItems);
                  _selectedItems.forEach((_item) => {
                    selection.setKeySelected(_item.key, true, false)
                  })
                }

              }}
            />

          )
        }}
      />

    );
  };

  // const onRenderRow = (props) => {
  //   if (!props) {
  //     return null;
  //   }

  //   const customCheckbox = (checkboxProps) => (
  //     <Checkbox
  //       {...checkboxProps}
  //       checked={selectedItems.some(_item => _item.key === props.item.key)}
  //       onChange={(e, checked) => {
  //         // console.log(`Checkbox for ${props.item.key} changed:`, checked);
  //         // selection.setKeySelected(props.item.key, checked, false);
  //         if (props.item.rdStatus === "-") {
  //           if (selectedItems.some(_item => _item.key === props.item.key)) {
  //             setSelectedItems(prevState => prevState.filter(_item => _item.key !== props.item.key));
  //           } else {
  //             setSelectedItems(prevState => [...prevState, props.item]);
  //           }
  //         }
  //       }}
  //       styles={customCheckboxStyles}
  //     />
  //   );

  //   return (
  //     <DetailsRow {...props} onRenderDetailsCheckbox={customCheckbox} />
  //   );
  // };

  const isCompactMode = false;
  //const isModealSelection = false;
  //const annoucedMessage = undefined;

  return (
    <div style={homeStyles}>
      <Stack>
        {xmlCheckNotification && <MessageBar
          onDismiss={() => {}}
          messageBarType={MessageBarType.warning}
          dismissButtonAriaLabel={t("xmlDeliveries.close")}
          isMultiline={false}
          style={{
            fontSize: 14,
          }}
        >
          {xmlCheckNotification}
        </MessageBar>
        }
        {serviceProviderEnable && showDeliveriesDateWarning && <MessageBar
          onDismiss={() => {
            setShowDeliveriesDateWarning(false);
          }}
          messageBarType={MessageBarType.warning}
          dismissButtonAriaLabel={t("xmlDeliveries.close")}
          isMultiline={false}
          style={{
            fontSize: 14,
          }}
        >
          {t("xmlDeliveries.warningXmlDelivery")}
        </MessageBar>
        }
        {serviceProviderEnable && showCertificateWarning && <MessageBar
          onDismiss={() => {
            setShowCertificateWarning(false);
          }}
          messageBarType={MessageBarType.warning}
          dismissButtonAriaLabel={t("xmlDeliveries.close")}
          isMultiline={false}
          style={{
            fontSize: 14,
          }}
        >
          {t("xmlDeliveries.warningCertificateExpired")}
        </MessageBar>
        }
        {isLoadCompaneisDetailsComplete && !serviceProviderEnable && <MessageBar
          onDismiss={() => {
            // setShowCertificateWarning(false);
          }}
          messageBarType={MessageBarType.warning}
          dismissButtonAriaLabel={t("xmlDeliveries.close")}
          isMultiline={false}
          style={{
            fontSize: 14,
          }}
        >
          {t("xmlDeliveries.warningActivateXmlDelivery")}
        </MessageBar>
        }
        {copySuccess &&
          <MessageBar

            messageBarType={MessageBarType.success}
            isMultiline={false}
            onDismiss={() => { setCopySuccess(false) }}
          >
            {t("xmlDeliveries.copied")}
          </MessageBar>
        }
        {showRepeatNumber && (
          <MessageBar
            messageBarType={MessageBarType.error}
            isMultiline={false}
            onDismiss={() => setShowRepeatNumber(false)}
            dismissButtonAriaLabel={t('xmlDeliveries.close')}
            style={{
              fontSize: 14,
            }}
          >
            <TooltipHost content={duplicatedNumbers.join(", ")} directionalHint={DirectionalHint.bottomCenter}>
              <span style={{ width: 1000, whiteSpace: 'nowrap', overflow: 'hidden', display: 'inline-block', textOverflow: "ellipsis" }}>
                {t('xmlDeliveries.showRepeatNumberMessage')} : {duplicatedNumbers.join(", ")}
              </span>
            </TooltipHost>
          </MessageBar>
        )}

        <center>
          <h2 style={{ marginLeft: "0px", marginTop: "25px" }}>{t('xmlDeliveries.header')}</h2>
          {/* <h4>( สำหรับส่งให้กรมสรรพากร )</h4> */}
          {/* <br/> */}
        </center>
        {/*
                <Stack
                    horizontal
                    horizontalAlign="center"
                    //wrap
                  
                    tokens={{ childrenGap: "30px" }}
                >
                    <DocumentCard
                        aria-label="จำนวนเอกสาร"
                        styles={cardStyles.cardStyles}
                        type={DocumentCardType.compact}
                    //onClickHref="http://bing.com"
                    >
                        <DocumentCardLogo
                            {...{ logoIcon: "Documentation" }}
                            title="จำนวนเอกสาร"
                        />
                        <DocumentCardDetails>
                            <DocumentCardTitle
                                title="จำนวนเอกสาร"
                                //shouldTruncate
                                styles={cardStyles.header}
                            />
                            <Text styles={cardStyles.amount}>
                                <CountUp
                                    end={totalDocuments}
                                    separator=","
                                    duration={1}
                                />{" "}
                                เอกสาร
                            </Text>
                        </DocumentCardDetails>
                    </DocumentCard>

                    <DocumentCard
                        aria-label="ภาษีขาย (Vat)"
                        styles={cardStyles.cardStyles}
                        type={DocumentCardType.compact}
                    //onClickHref="http://bing.com"
                    >
                        <DocumentCardLogo
                            {...{ logoIcon: "Bank" }}
                            title="จำนวนเอกสาร"
                        />
                        <DocumentCardDetails>
                            <DocumentCardTitle
                                title="ภาษีขาย (Vat)"
                                //shouldTruncate
                                styles={cardStyles.header}
                            />
                            <Text styles={cardStyles.amount}>
                                <CountUp
                                    end={totalVat}
                                    separator=","
                                    decimals={2}
                                    decimal="."
                                    duration={1}
                                />{" "}
                                บาท
                            </Text>
                        </DocumentCardDetails>
                    </DocumentCard>

                    <DocumentCard
                        aria-label="ยอดขาย (รวม Vat)"
                        styles={cardStyles.cardStyles}
                        type={DocumentCardType.compact}
                    //onClickHref="http://bing.com"
                    >
                        <DocumentCardLogo
                            {...{ logoIcon: "Money" }}
                            title="จำนวนเอกสาร"
                        />
                        <DocumentCardDetails>
                            <DocumentCardTitle
                                title="ยอดขาย (รวม Vat)"
                                //shouldTruncate
                                styles={cardStyles.header}
                            />
                            <Text styles={cardStyles.amount}>
                                <CountUp
                                    end={totalGrand}
                                    separator=","
                                    decimals={2}
                                    decimal="."
                                    duration={1}
                                />{" "}
                                บาท
                            </Text>
                        </DocumentCardDetails>
                    </DocumentCard>
                </Stack>
                 */}
      </Stack>

      <Stack
        horizontal
        horizontalAlign="center"
        //wrap

        tokens={{ childrenGap: "30px" }}
      >
        <DocumentCard
          aria-label={t('xmlDeliveries.notSubmittedCard')}
          styles={cardStyles.cardStyles}
          type={DocumentCardType.compact}
        //onClickHref="http://bing.com"
        >
          <DocumentCardLogo {...{ logoIcon: "Documentation" }} title={t('xmlDeliveries.notSubmittedCard')} />
          {/* <div className={conversationTileClass}> */}
          <DocumentCardDetails>
            <DocumentCardTitle
              title={t('xmlDeliveries.notSubmittedCard')}
              //shouldTruncate
              styles={cardStyles.header}
            />
            {/* <Text styles={cardStyles.amount}>{totalDocuments} ใบ</Text> */}
            <Text styles={cardStyles.amount}>
              <CountUp end={totalPending} separator="," duration={1} /> {t('xmlDeliveries.documents')}
            </Text>
            {/* </div>  */}
          </DocumentCardDetails>
        </DocumentCard>

        <DocumentCard
          aria-label={t('xmlDeliveries.spPendingRdStatus')}
          styles={cardStyles.cardStyles}
          type={DocumentCardType.compact}
        //onClickHref="http://bing.com"
        >
          <DocumentCardLogo {...{ logoIcon: "Recent" }} title={t('xmlDeliveries.spPendingRdStatus')} />
          {/* <div className={conversationTileClass}> */}
          <DocumentCardDetails>
            <DocumentCardTitle
              title={t('xmlDeliveries.spPendingRdStatus')}
              //shouldTruncate
              styles={cardStyles.header}
            />
            {/* <Text styles={cardStyles.amount}>{totalGrand.toLocaleString(undefined, {maximumFractionDigits:2, minimumFractionDigits:2})} บาท</Text> */}
            <Text styles={cardStyles.amount}>
              <CountUp end={totalSpPending} separator="," duration={1} /> {t('xmlDeliveries.documents')}
            </Text>
            {/* </div>  */}
          </DocumentCardDetails>
        </DocumentCard>

        <DocumentCard
          aria-label={t('xmlDeliveries.successfulRdStatus')}
          styles={cardStyles.cardStyles}
          type={DocumentCardType.compact}
        //onClickHref="http://bing.com"
        >
          <DocumentCardLogo {...{ logoIcon: "Completed" }} title={t('xmlDeliveries.successfulRdStatus')} />
          {/* <div className={conversationTileClass}> */}
          <DocumentCardDetails>
            <DocumentCardTitle
              title={t('xmlDeliveries.successfulRdStatus')}
              //shouldTruncate
              styles={cardStyles.header}
            />
            {/* <Text styles={cardStyles.amount}>{totalVat.toLocaleString(undefined, {maximumFractionDigits:2, minimumFractionDigits:2})} บาท</Text> */}
            <Text styles={cardStyles.amount}>
              <CountUp end={totalSuccessful} separator="," duration={1} /> {t('xmlDeliveries.documents')}
            </Text>
            {/* </div>  */}
          </DocumentCardDetails>
        </DocumentCard>
      </Stack>
      <br />
      {/* 
      <Stack horizontal horizontalAlign="center">
        <h3>{companiesFilter.text}</h3>
      </Stack>

      <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: "10px" }}>
        <Stack vertical horizontalAlign="end" style={{ marginLeft: "5px" }}>
          <span>lastLogin: </span>
          <br />
          <span>document id: </span>
          <span>เลขที่เอกสาร: </span>
          <span>สถานะนำส่ง XML: </span>
          <br />
          <span>ข้อความตอบกลับ: </span>
          <span>ชื่อไฟล์: </span>
          <span>Ref Number: </span>
        </Stack>

        <Stack vertical horizontalAlign="start" style={{ width: "500px" }}>
          <span>{etaxUserLastLogin}&nbsp;</span>
          <br />
          <span>{uploadingDocumentId}&nbsp;</span>
          <span>{uploadingDocumentNumber}&nbsp;</span>
          <span>{uploadingDocumentStatus}&nbsp;</span>
          <br />
          <span>{uploadingDocumentMessage}&nbsp;</span>
          <span>{uploadingDocumentFileName}&nbsp;</span>
          <span>{uploadingDocumentRefNo}&nbsp;</span>
        </Stack>
      </Stack>
      <br />
 */}
      <Stack horizontal horizontalAlign="end"
        style={{
          width: "98%",
          //minWidth: '1070px',
          maxWidth: "1450px",
          marginTop: "-18px",
          fontSize: "12px"
        }}
      >

        {t('xmlDeliveries.failRdStatus')} {totalFail} {t('xmlDeliveries.documents')}

      </Stack>
      <Stack horizontal horizontalAlign="center">
        <Stack>
          {t('xmlDeliveries.totalDocuments')}{" "}{totalDocuments.toLocaleString(undefined, { maximumFractionDigits: 0, minimumFractionDigits: 0, })}{" "}{t('xmlDeliveries.documents')},{" "}
          {t('xmlDeliveries.amountTotal')}{" "}{totalAmount.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2, })}{" "}{t('xmlDeliveries.thb')},{" "}
          {t('xmlDeliveries.vat')}{" "}{totalVat.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2, })}{" "}{t('xmlDeliveries.thb')}
        </Stack>

      </Stack>
      <br />

      <Stack horizontal horizontalAlign="center" style={{ width: "98%" }} tokens={{ childrenGap: "15px" }}>
        {/* 
                <DefaultButton
                    text="ล็อกอิน e-Tax RD"
                    style={{ width: '150px' }}
                    onClick={() => {

                        console.log("e-Tax Login press");
                        console.log("log in to company: ", companiesFilter.text);

                        let confirm = window.confirm(
                            "กรุณายืนยัน การ Log in e-Tax RD " +
                                companiesFilter.text
                        );

                        if (confirm && isAdmin) {
                            etaxLogin();
                        }

                    }}
                />  
 */}
        {!isProcessDocument && (<PrimaryButton
          text={t('xmlDeliveries.xmlDeliveryButton')}
          style={{ width: "180px", marginLeft: "25px" }}
          disabled={!xmlCheckEnable || !serviceProviderEnable || !uploadAble || !loadDataComplete || isProcessDocument || selectedItems.length === 0 || selectedItems.some(_item => _item.rdStatus !== "-")}
          iconProps={{ iconName: 'Upload' }}
          onClick={() => {
            setDialogConfirm((prev) => ({
              ...prev,
              type: "",
              isClick: true,
              header: t('xmlDeliveries.dialogXmlDeliveryHeader'),
              message: <div>
                <p>{t('xmlDeliveries.dialogBatchXmlDeliveryMessage')}</p>
              </div>,
              onConfirm: () => {
                setDialogConfirm((prev) => ({
                  ...prev,
                  isClick: false,
                }));

                setIsProcessDocument(true);
                uploadXmlSelectedDocuments();

                // console.log("upload xml", selectedItems)
              },
            }));
          }}
        />
        )}

        {isProcessDocument && (
          <PrimaryButton
            text={t('xmlDeliveries.stopButton')}
            style={{ width: "200px", marginLeft: "25px" }}
            disabled={uploadingXmlStop.current}
            iconProps={{ iconName: 'StopSolid' }}
            onClick={() => {
              // setUploadingXmlStop(true);

              uploadingXmlStop.current = true;
            }}
          />
        )}
      </Stack>

      <br />
      {/* 
      <Stack horizontal horizontalAlign="center">
        จำนวนเอกสารไม่นำส่ง{" "}
        {totalNotDelivery.toLocaleString(undefined, {
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
        })}{" "}
        เอกสาร
      </Stack>
      <br />
 */}

      <Stack horizontal horizontalAlign="center">
        <Stack
          vertical
          style={{
            width: "98%",
            //minWidth: '1070px',
            maxWidth: "1350px",
          }}
        >
          <CommandBar
            items={commandBarItems}
          //overflowItems={_overflowItems}
          //overflowButtonProps={overflowProps}
          //farItems={_farItems}
          //ariaLabel="Use left and right arrow keys to navigate between commands"
          />
          <ShimmeredDetailsList
            items={items || []}
            enableShimmer={!loadDataComplete}
            compact={isCompactMode}
            columns={columns}
            // selectionMode={SelectionMode.none}
            //selectionMode={SelectionMode.multiple}
            selection={selection}
            // selectionMode={SelectionMode.none}
            selectionMode={!loadDataComplete || isProcessDocument ? SelectionMode.none : SelectionMode.multiple}
            // getKey={(item) => item?.key || null}
            // setKey="set"
            layoutMode={DetailsListLayoutMode.justified}
            isHeaderVisible={true}
            checkboxVisibility={CheckboxVisibility.always}
            //selection={Selection}
            selectionPreservedOnEmptyClick={true}
            onRenderDetailsHeader={onRenderDetailsHeader}
          // onRenderRow={onRenderRow}
          />
        </Stack>
      </Stack>
      {/* </Fabric> */}
      <br />
      <center>
        {
          /* {!(documents.length === 0 || documents.length < pageSize) && */
          loadDataComplete && (
            <Pagination
              //selectedPageIndex={page}
              selectedPageIndex={currentPage - 1}
              //pageCount={pageCount}
              pageCount={pageData.totalPages}
              onPageChange={onPageChange}
              format
              firstPageIconProps={{
                iconName: "DoubleChevronLeft",
              }}
              previousPageIconProps={{ iconName: "ChevronLeft" }}
              nextPageIconProps={{ iconName: "ChevronRight" }}
              lastPageIconProps={{
                iconName: "DoubleChevronRight",
              }}
            />
          )
        }

        <br />
        <br />

        {/* 
            <br/>
            <a href="http://nav.cx/3p1xabz" target="_blank">
                <img src={PUBLIC_URL + '/images/line-add-friend.png'} alt="เพิ่มเพื่อน" height="36" border="0"></img>
            </a>
            <br/>
            <br/>
            <div>ฝ่ายบริการลูกค้า Line: @leceipt</div>
            <br/>
            <div>โทร. 02-107-0999</div>
            <br/>
            <div>เวลาทำการ 8.30 น. - 17.30 น. ทุกวัน</div>
 */}
      </center>
      <Dialog
        hidden={!dialogConfirm.isClick}
        onDismiss={() =>
          setDialogConfirm((prev) => ({
            ...prev,
            isClick: false,
          }))
        }
        styles={{ main: { minHeight: 0 } }}
        dialogContentProps={{
          type: DialogType.largeHeader,
          title: dialogConfirm.header,
          styles: { header: { textAlign: 'center' }, title: { paddingRight: 24 }, subText: { textAlign: 'center' } },
          // subText: dialogConfirm.message,
        }}
      >
        <Stack horizontalAlign='center' styles={{ root: { textAlign: "center" } }}>
          {dialogConfirm.message}
        </Stack>
        {dialogConfirm.type === "sendXMLFail" ? (
          <DialogFooter styles={{ actionsRight: { justifyContent: 'center' } }}>
            {/* <PrimaryButton onClick={dialogMode.onConfirm} text={t('documents.confirm')} /> */}
            {/* <DefaultButton onClick={dialogMode.onCancel} text={t('documents.back')} /> */}
            <DefaultButton onClick={() =>
              setDialogConfirm((prev) => ({
                ...prev,
                isClick: false,
              }))
            } text={t('documents.back')} />
          </DialogFooter>
        ) : (
          <DialogFooter styles={{ actionsRight: { textAlign: 'center' } }}>
            <PrimaryButton onClick={dialogConfirm.onConfirm} text={t('xmlDeliveries.confirm')} />
            <DefaultButton
              onClick={() =>
                setDialogConfirm((prev) => ({
                  ...prev,
                  isClick: false,
                }))
              }
              text={t('xmlDeliveries.cancel')}
            />
          </DialogFooter>
        )}
      </Dialog>
    </div>
  );
};

export default XmlDeliveries;