export const translationTh = {
  "header": {
    "profile": "ผู้ใช้งาน",
    "resetPassword": "เปลี่ยนรหัสผ่าน",
    "logout": "ออกจากระบบ",
    "paper": "กระดาษ",
    "mode": "โหมด",
    "workdays": "เวลาทำการ 8.30 น. - 17.30 น. จันทร์-ศุกร์",
    "pleaseContact": "กรุณาติดต่อเจ้าหน้าที่",
    "changeModeHeader": "ต้องการเปลี่ยนโหมดการใช้งาน",
  },
  "navigation": {
    "folder": "แฟ้มเอกสาร",
    "sales": "รายรับ",
    "accountReceivable": "ลูกหนี้การค้า",
    "purchasing": "รายจ่าย",
    "withholdingTax": "หัก ณ ที่จ่าย (50 ทวิ)",
    "send-receivedDocument": "รับ-ส่งเอกสาร",
    "sendDocument": "ส่งเอกสาร",
    "inbox": "กล่องเอกสารเข้า",
    "sentItems": "ประวัติการส่งเอกสาร",
    "organizationContacts": "ข้อมูลภายใน",
    "company": "บริษัท",
    "organization": "องค์กร",
    "contacts": "ผู้ติดต่อ",
    "externalDataPaid": "ข้อมูลภายนอก ERP",
    "externalData": "ข้อมูลภายนอก อื่นๆ",
    "fileExcel": "ไฟล์ Excel",
    "reports": "รายงาน",
    "salesReport": "รายงานยอดขาย",
    "outputTaxReport": "รายงานภาษีขาย",
    "inputTaxReport": "รายงานภาษีซื้อ",
    "xmlDeliveryReport": "รายงานการนำส่ง XML",
    "fileRdPrep": "ไฟล์ RDPrep",
    "pp30": "ภ.พ.30",
    "pnd2": "ภ.ง.ด.2",
    "pnd3": "ภ.ง.ด.3",
    "pnd53": "ภ.ง.ด.53",
    "rdData": "ข้อมูลกรมสรรพากร",
    "vatRd": "ข้อมูลผู้ประกอบการ VAT",
    "taxIdRd": "ข้อมูลเลขประจำตัวผู้เสียภาษี",
    "eTaxRd": "ข้อมูลผู้ประกอบการ e-Tax",
    "setting": "ตั้งค่า",
    "certificates": "ใบรับรองอิเล็กทรอนิกส์",
    "xmlDelivery": "การนำส่งไฟล์ XML",
    "templates": "รูปแบบเอกสาร",
    "emailSetting": "อีเมลที่ใช้ส่งเอกสาร",
    "apiConnect": "การเชื่อมต่อ API",
    "userManual": "คู่มือการใช้งาน",
    "confirmLogout": "กรุณายืนยันการออกจากระบบ",
    "createDocument": "สร้างเอกสาร",
    "eTaxDocument": "เอกสาร e-Tax",
    "receipt-taxinvoice": "ใบเสร็จรับเงิน/ใบกำกับภาษี",
    "receipt-taxinvoice-abb": "ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ",
    "receipt": "ใบเสร็จรับเงิน",
    "invoice-taxinvoice": "ใบแจ้งหนี้/ใบกำกับภาษี",
    "deliveryorder-taxinvoice": "ใบส่งของ/ใบกำกับภาษี",
    "taxinvoice": "ใบกำกับภาษี",
    "debit-note": "ใบเพิ่มหนี้",
    "credit-note": "ใบลดหนี้",
    "quotation": "ใบเสนอราคา",
    "invoice": "ใบแจ้งหนี้",
    "billing-note": "ใบวางบิล",
    "deliveryorders": "ใบส่งของ",
    "businessDocument": "เอกสารธุรกิจ",
    "withholdingTaxDocument": "หนังสือรับรองการหักภาษี ณ ที่จ่าย",
    "serviceZone": "ฝ่ายบริการลูกค้า",
    "tel": "โทร. 02-107-0999",
    "workdays": "เวลาทำการ 8.30 น. - 17.30 น. จันทร์-ศุกร์",
    "version": "อัปเดตล่าสุด",
    "suggestService": "แนะนำบริการ",
    "product": "สินค้า",
    "productList": "รายการสินค้า",
    "productStockControl": "จัดการสต็อกสินค้า",
    "emailDeliveries": "รายงานการนำส่ง อีเมล",
    "smsDeliveries": "รายงานการนำส่ง SMS",
    "storages": "พื้นที่เก็บข้อมูลบนคลาวด์",
    "submitEtax": "นำส่งข้อมูล E-Tax",
    "submitXml": "นำส่งไฟล์ XML",
    "requesterTaxInvoice": "ผู้ขอใบกำกับภาษี",
    "formRequestTaxInvoice": "แบบฟอร์มขอใบกำกับภาษี"
  },
  "documents": {
    "warningSetupCertificate": "เอกสาร e-Tax Invoice & e-Receipt จะสมบูรณ์ก็ต่อเมื่อใช้ใบรับรองอิเล็กทรอนิกส์ของจริงเท่านั้น",
    "warningSetupCertificateLink": "ติดตั้งใบรับรองอิเล็กทรอนิกส์",
    "warningXmlDelivery": "ไฟล์ XML จะส่งกรมสรรพากร วันที่ 5 ของทุกเดือน",
    "warningSubscriptionSoftware": "ซอฟต์แวร์ Leceipt แบบ Subscription",
    "warningSubscriptionSoftwareButton": "เปิดใช้งาน",
    "warningXmlDelivery01": "นำส่งไฟล์ XML เข้าระบบ e-Tax Invoice & e-Receipt",
    "warningXmlDelivery02": "เนื่องจากมีไฟล์ XML ที่ยังไม่ได้นำส่ง",
    "warningXmlDeliveryButton": "นำส่งไฟล์ XML",
    "close": "ปิด",
    "allDocuments": "เอกสารรายรับทั้งหมด",
    "totalDocuments": "จำนวนเอกสาร",
    "notDeposit": "รอชำระเงิน",
    "deposit": "ชำระแล้ว",
    "vat": "ภาษีขาย (VAT)",
    "salesAmount": "ยอดขาย (รวม VAT)",
    "documents": "เอกสาร",
    "thb": "บาท",
    "signProcessing": "กำลังสร้างเอกสารและลงลายเซ็นดิจิทัล...",
    "search": "ค้นหา",
    "searchPlaceholder": "ค้นหาเลขที่ ชื่อ เลขผู้เสียภาษี",
    "sendFileByEmail": "ส่งไฟล์ทางอีเมล",
    "sendToEmail": "ส่งไปที่อีเมล",
    "ccToEmail": "สำเนาไปที่อีเมล",
    "sendEmail": "ส่งอีเมล",
    "sendBatchSms": "ส่ง SMS",
    "cancel": "ยกเลิก",
    "sendFileBySms": "ส่งไฟล์ทาง SMS",
    "mobileNumer": "เบอร์มือถือ",
    "exampleNumber": "ตัวอย่าง 0825798555",
    "sendSms": "ส่งข้อความ SMS",
    "shortMonths01": "ม.ค.",
    "shortMonths02": "ก.พ.",
    "shortMonths03": "มี.ค.",
    "shortMonths04": "เม.ย.",
    "shortMonths05": "พ.ค.",
    "shortMonths06": "มิ.ย.",
    "shortMonths07": "ก.ค.",
    "shortMonths08": "ส.ค.",
    "shortMonths09": "ก.ย.",
    "shortMonths10": "ต.ค.",
    "shortMonths11": "พ.ย.",
    "shortMonths12": "ธ.ค.",
    "months01": "มกราคม",
    "months02": "กุมภาพันธ์",
    "months03": "มีนาคม",
    "months04": "เมษายน",
    "months05": "พฤษภาคม",
    "months06": "มิถุนายน",
    "months07": "กรกฎาคม",
    "months08": "สิงหาคม",
    "months09": "กันยายน",
    "months10": "ตุลาคม",
    "months11": "พฤศจิกายน",
    "months12": "ธันวาคม",
    "days01": "อาทิตย์",
    "days02": "จันทร์",
    "days03": "อังคาร",
    "days04": "พุธ",
    "days05": "พฤหัสบดี",
    "days06": "ศุกร์",
    "days07": "เสาร์",
    "shortDays01": "อา.",
    "shortDays02": "จ.",
    "shortDays03": "อ.",
    "shortDays04": "พ.",
    "shortDays05": "พฤ.",
    "shortDays06": "ศ.",
    "shortDays07": "ส.",
    "goToToday": "ไปวันที่ปัจจุบัน",
    "invalidInputErrorMessage": "รูปแบบวันที่ไม่ถูกต้อง",
    "prevMonthAriaLabel": "ไปเดือนที่แล้ว",
    "nextMonthAriaLabel": "ไปเดือนถัดไป",
    "prevYearAriaLabel": "ไปปีที่แล้ว",
    "nextYearAriaLabel": "ไปปีถัดไป",
    "monthPickerHeaderAriaLabel": "{0}, เลือกเพื่อเปลี่ยนเดือน",
    "yearPickerHeaderAriaLabel": "{0}, เลือกเพื่อเปลี่ยนปี",
    "allDocumentsType": "เอกสารทุกประเภท",
    "receipt-taxinvoice": "ใบเสร็จรับเงิน/ใบกำกับภาษี",
    "receipt-taxinvoice-abb": "ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ",
    "receipt": "ใบเสร็จรับเงิน",
    "invoice-taxinvoice": "ใบแจ้งหนี้/ใบกำกับภาษี",
    "deliveryorder-taxinvoice": "ใบส่งของ/ใบกำกับภาษี",
    "taxinvoice": "ใบกำกับภาษี",
    "debit-note": "ใบเพิ่มหนี้",
    "credit-note": "ใบลดหนี้",
    "quotation": "ใบเสนอราคา",
    "invoice": "ใบแจ้งหนี้",
    "billing-note": "ใบวางบิล",
    "deliveryorder": "ใบส่งของ",
    "createdDate": "วันที่สร้างเอกสาร",
    "documentDate": "วันที่บนเอกสาร",
    "thisMonth": "เดือนนี้",
    "documentPerPage": "เอกสาร/หน้า",
    "page": "หน้า",
    "refresh": "รีเฟรช",
    "confirmSendEmail": "คุณต้องการที่จะส่งอีเมล",
    "confirmSendSms": "คุณต้องการที่จะส่ง SMS",
    "create": "สร้าง",
    "combinedReceipt": "ใบเสร็จรับเงินรวม",
    "combinedReceiptAlert": "ขออภัย มีประเภทเอกสารที่ไม่สามารถสร้างใบเสร็จรับเงินรวมได้",
    "download": "ดาวน์โหลด",
    "emailAlert": "กรุณากรอกอีเมลที่ถูกต้อง",
    "mobileNumberAlert": "กรุณากรอกเบอร์มือถือที่ถูกต้อง",
    "number": "เลขที่",
    "customerName": "ชื่อลูกค้า",
    "grandTotal": "ยอด (รวม Vat)",
    "document": "เอกสาร",
    "openFile": "เปิดไฟล์",
    "email": "อีเมล",
    "emailDate": "วันที่ส่งอีเมล",
    "sendingDate": "วันที่ส่ง",
    "sendingEmail": "กำลังส่งอีเมล...",
    "sendingSms": "กำลังส่ง SMS...",
    "success": "สำเร็จ",
    "emailNotFound": "ไม่พบอีเมล",
    "phoneNotFound": "ไม่พบเบอร์โทร",
    "fail": "ไม่สำเร็จ",
    "file": "ไฟล์",
    "openDocument": "เปิดเอกสาร",
    "reCreateDocument": "สร้างใบใหม่ข้อมูลเดิม",
    "reIssuedDocument": "สร้างใบใหม่แทนใบเดิม",
    "delete": "ลบ",
    "deleteDisable": "ลบ (ส่งสรรพากรแล้ว)",
    "sendEmailSuccess": "ส่งอีเมลสำเร็จ!",
    "sendEmailFail": "ส่งอีเมลไม่สำเร็จ!",
    "sendSmsSuccess": "ส่งข้อความ SMS สำเร็จ!",
    "sendSmsFail": "ส่งข้อความ SMS ไม่สำเร็จ!",
    "instantTopup": "เติมเครดิต เร็วทันใจ 24 ชม.",
    "select": "เลือก",
    "isRequiredErrorMessage": "กรุณาเลือกวันที่",
    "noItemFound": "ไม่พบข้อมูล",
    "documentStatus": "สถานะเอกสาร",
    "confirm": "ยืนยัน",
    "confirmChangeStatus": "ยืนยันการเปลี่ยนสถานะ",
    "allStatus": "ทุกสถานะ",
    "draftStatus": "ฉบับร่าง",
    "openStatus": "เปิด",
    "partialPaidStatus": "จ่ายบางส่วน",
    "sentStatus": "ส่งแล้ว",
    "paidStatus": "จ่ายแล้ว",
    "voidStatus": "ยกเลิก",
    "uncollectibleStatus": "เก็บเงินไม่ได้",
    "status": "สถานะ",
    "batchChangeStatus": "เปลี่ยนสถานะเอกสาร",
    "processing": "กำลังดำเนินการ...",
    "recordPaymentDialogHeader": "การชำระเงิน",
    "date": "วันที่",
    "pleaseSelectDate": "กรุณาเลือกวันที่",
    "payment": "ช่องทางการชำระเงิน",
    "paymentNote": "หมายเหตุการชำระเงิน",
    "paymentAmount": "จำนวนที่จ่าย",
    "recordGrandTotal": "จำนวนที่ต้องจ่าย",
    "amountPaidErrorMessage": "กรุณาระบุมากกว่า 0",
    "amountPaidErrorMessage2": "จำนวนสูงสุดที่ระบุได้คือ ",
    "pleaseSelect": "กรุณาเลือก",
    "deleteConfirmHeader": "ยกเลิกเอกสาร",
    "deleteConfirm1": "คุณต้องการที่จะยกเลิก ",
    "deleteConfirm2": " เลขที่ ",
    "deleteConfirm3": " ใช่ไหม?",
    "deleteConfirm4": " ของ ",
    "logoutConfirmHeader": "ออกจากระบบ",
    "logoutConfirm": "กรุณายืนยันการออกจากระบบ",
    "showRepeatNumberMessage": "ระบบพบว่ามีเลขที่เอกสารซ้ำกัน",
    "recordNoteHeader": "หมายเหตุ",
    "recordNoteAddNoteButton": "เพิ่มหมายเหตุ",
    "getErrorMessageTaxIdZeroOnly": "ข้อมูลไม่ถูกต้อง กรุณาเลือกประเภทผู้เสียภาษีเป็น ไม่ระบุ",
    "credits": "เอกสาร",
    "emailSentDate": "วันที่ส่ง Email:",
    "smsSentDate": "วันที่ส่ง SMS:",
    "dueDate": "ครบกำหนด",
    "back": "กลับ",
    "voided": "ถังขยะ",
    "all": "ทั้งหมด",
    "docStatus": "สถานะเอกสาร",
    "voidDocument": "ยกเลิกเอกสาร",
    "voidDescription": "หากยกเลิกแล้ว จะไม่สามารถนำกลับมาใช้งานได้",
    "pendingExpiredCertificateMessage": "*** สำคัญ *** ใบรับรองอิเล็กทรอนิกส์ของท่านใกล้หมดอายุแล้ว",
    "pendingExpiredCertificateMessage2": " กรุณาทำการขอใบรับรองอิเล็กทรอนิกส์ใหม่",
    "expiredCertificateMessage": "*** สำคัญ *** ใบรับรองอิเล็กทรอนิกส์ของท่านหมดอายุแล้ว",
    "expiredCertificateMessage2": " กรุณาทำการขอใบรับรองอิเล็กทรอนิกส์ใหม่",
    "requestCertificate": "ขอใบรับรองฯใหม่"
  },
  "accountReceivable": {
    "header": "ลูกหนี้การค้า",
    "taxWithheld": "ถูกหัก ณ ที่จ่าย",
    "custom": "กำหนดเอง",
    "taxWithheldRate": "อัตราภาษี",
    "taxWithheldAmount": "หัก ณ ที่จ่าย",
    "totalPaid": "รับชำระเงินรวม",
    "totalBeforeWht": "มูลค่าหลังหัก",
    "totalPaymentRec": "รวมรับชำระ",
  },
  "expenseDocuments": {
    "goToToday": "ไปวันที่ปัจจุบัน",
    "isRequiredErrorMessage": "กรุณาเลือกวันที่",
    "eWithholdingTaxDownload": "ดาวน์โหลดไฟล์ e-Withholding Tax",
    "header": "บันทึกซื้อสินค้า/ค่าใช้จ่าย",
    "totalDocuments": "จำนวนเอกสาร",
    "vat": "ภาษีซื้อ (VAT)",
    "expenseAmount": "ยอดซื้อ (รวม VAT)",
    "expenseNote": "บันทึกค่าใช้จ่าย",
    "signProcessing": "กำลังสร้างเอกสารและลงลายเซ็นดิจิทัล...",
    "documentDate": "วันที่บนเอกสาร",
    "number": "เลขที่",
    "customerName": "ผู้ขาย",
    "vatColumn": "VAT",
    "grandTotal": "ยอด (รวม VAT)",
    "whtTotal": "หัก ณ ที่จ่าย",
    "paymentTotal": "ยอดที่ต้องชำระ",
    "file": "ไฟล์",
    "select": "เลือก",
    "open": "ดู/แก้ไข",
    "download": "ดาวน์โหลดบันทึกค่าใช้จ่าย",
    "reCreateDocument": "บันทึกค่าใช้จ่ายใหม่ข้อมูลเดิม",
    "withholdingTaxs": "สร้างหนังสือรับรองการหักภาษี ณ ที่จ่าย",
    "delete": "ลบ",
    "document": "เอกสาร",
    "thb": "บาท",
  },
  "WithholdingTaxDocuments": {
    "totalDocuments": "จำนวนเอกสาร",
    "vat": "ภาษีซื้อ (VAT)",
    "expenseAmount": "ยอดซื้อ (รวม VAT)",
    "expenseNote": "บันทึกค่าใช้จ่าย",
    "signProcessing": "กำลังสร้างเอกสารและลงลายเซ็นดิจิทัล...",
    "documentDate": "วันที่บนเอกสาร",
    "number": "เลขที่",
    "customerName": "ชื่อลูกค้า",
    "vatColumn": "VAT",
    "grandTotal": "ยอด (รวม VAT)",
    "whtTotal": "หัก ณ ที่จ่าย",
    "paymentTotal": "ยอดที่ต้องชำระ",
    "file": "ไฟล์",
    "select": "เลือก",
    "open": "ดู/แก้ไข",
    "download": "ดาวน์โหลดบันทึกค่าใช้จ่าย",
    "reCreateDocument": "บันทึกค่าใช้จ่ายใหม่ข้อมูลเดิม",
    "withholdingTaxs": "สร้างหนังสือรับรองการหักภาษี ณ ที่จ่าย",
    "delete": "ลบ",
    "document": "เอกสาร",
    "search": "ค้นหา",
    "thb": "บาท",
    "upload": "อัปโหลด",
    "header": "เอกสารหัก ณ ที่จ่าย (50 ทวิ)",
    "total": "เงินที่จ่าย",
    "taxTotal": "เงินภาษีที่หัก",
  },
  "whtDropzone": {
    "dropzoneAlertCSV": "ขออภัย ระบบพบว่าไฟล์ CSV มีคอลัมน์ไม่ครบถ้วนหรือไม่ถูกต้อง กรุณาตรวจสอบไฟล์ของคุณ หรือดาวน์โหลด ตัวอย่างไฟล์ Excel สำหรับอัปโหลด ใหม่",
    "dropzoneAlertExcel": "ขออภัย ระบบพบว่าไฟล์ Excel มีคอลัมน์ไม่ครบถ้วนหรือไม่ถูกต้อง กรุณาตรวจสอบไฟล์ของคุณ หรือดาวน์โหลด ตัวอย่างไฟล์ Excel สำหรับอัปโหลด ใหม่",
    "dropzoneAlert500": "สร้างเอกสารได้สูงสุด 500 ใบ/ครั้ง กรุณาอัปโหลดใหม่อีกครั้ง",
    "processing": "กำลังดำเนินการ...",
    "header": "อัปโหลดไฟล์ Excel สร้างเอกสาร หัก ณ ที่จ่าย (50 ทวิ)",
    "upload": "อัปโหลด",
    "uploadDescription": "วางไฟล์ .csv, .xls หรือ .xlsx เพื่อสร้างเอกสาร หัก ณ ที่จ่าย (50 ทวิ)",
    "pnd2": "ตัวอย่างไฟล์ ภ.ง.ด.2 .csv สำหรับอัปโหลด",
    "pnd3": "ตัวอย่างไฟล์ ภ.ง.ด.3 .csv สำหรับอัปโหลด",
    "pnd53": "ตัวอย่างไฟล์ ภ.ง.ด.53 .csv สำหรับอัปโหลด",
    "article": "คู่มืออัปโหลดไฟล์ CSV",
  },
  "whtDropzoneResult": {
    "number": "เลขที่เอกสารที่ไม่ผ่าน",
    "taxId": "เลขประจำตัวผู้เสียภาษีอากร",
    "dateBE": "วันเดือนปีที่จ่าย",
    "error": "รายละเอียดที่ไม่ถูกต้อง",
    "header": "ขออภัย ตรวจพบรายละเอียดเอกสารที่ไม่ถูกต้อง",
    "back": "ย้อนกลับ",
  },
  "whtDropzoneUploadList": {
    "header": "ระบบจะสร้างเอกสาร หัก ณ ที่จ่าย (50 ทวิ) ตามรายละเอียดต่อไปนี้",
    "back": "ย้อนกลับ",
    "cancel": "ยกเลิก",
    "date": "วันเดือนปีที่จ่าย",
    "number": "เลขที่เอกสาร",
    "taxId": "เลขประจำตัวผู้เสียภาษีอากร",
    "name": "ชื่อ",
    "amountTotal": "จำนวนเงินที่จ่าย",
    "vatTotal": "จำนวนเงินภาษีที่หัก",
    "docStatus": "สถานะเอกสาร",
    "success": "สำเร็จ",
    "pending": "รอดำเนินการ",
    "processing": "กำลังสร้างเอกสาร...",
    "fail": "ไม่สำเร็จ",
    "viewPDF": "ดูตัวอย่าง",
    "createDoc": "สร้างเอกสาร",
    "tryAgain": "กรุณาลองใหม่อีกครั้ง",
    "signProcessing": "กำลังสร้างเอกสารและลงลายเซ็นดิจิทัล...",
    "viewAll": "ดูเอกสารทั้งหมด",
  },
  "excelDropzone": {
    "dropzoneAlertCSV": "ขออภัย ระบบพบว่าไฟล์ CSV มีคอลัมน์ไม่ครบถ้วนหรือไม่ถูกต้อง กรุณาตรวจสอบไฟล์ของคุณ หรือดาวน์โหลด ตัวอย่างไฟล์ Excel สำหรับอัปโหลด ใหม่",
    "dropzoneAlertExcel": "ขออภัย ระบบพบว่าไฟล์ Excel มีคอลัมน์ไม่ครบถ้วนหรือไม่ถูกต้อง กรุณาตรวจสอบไฟล์ของคุณ หรือดาวน์โหลด ตัวอย่างไฟล์ Excel สำหรับอัปโหลด ใหม่",
    "dropzoneAlert500": "สร้างเอกสารได้สูงสุด 500 ใบ/ครั้ง กรุณาอัปโหลดใหม่อีกครั้ง",
    "dropzoneAlertOther": "พบข้อผิดพลาด กรุณาตรวจสอบแล้วอัปโหลดใหม่อีกครั้ง หรือเปลี่ยนสกุลไฟล์เป็น xlsx, xls แล้วอัปโหลดใหม่",
    "dropzoneAlertNoOrder": "ขออภัย ระบบไม่พบ order ในบรรทัดที่ ",
    "dropzoneAlertOrderInvalid1": "ขออภัย ระบบพบว่า order ในบรรทัดที่ ",
    "dropzoneAlertOrderInvalid2": " ไม่ถูกต้อง กรุณาระบุเป็นตัวเลขเท่านั้น",
    "dropzoneAlertNoData": "ขออภัย ระบบพบว่าไฟล์ Excel มีข้อมูลไม่ครบถ้วนหรือไม่ถูกต้อง กรุณาตรวจสอบไฟล์ของคุณ หรือดาวน์โหลด ตัวอย่างไฟล์ Excel สำหรับอัปโหลด ใหม่",
    "processing": "กำลังดำเนินการ...",
    "header": "อัปโหลดไฟล์ Excel สร้างเอกสาร e-Tax",
    "upload": "อัปโหลด",
    "uploadDescription": "วางไฟล์ .csv, .xls หรือ .xlsx เพื่อสร้างเอกสาร e-Tax",
    "article": "คู่มือการอัปโหลดไฟล์ Excel",
    "excelTemplate": "ตัวอย่างไฟล์ Excel สำหรับอัปโหลด",
    "excelTemplateNew": "ตัวอย่างไฟล์ Excel",
    "excelTemplateNewCreditDebit": "ตัวอย่างไฟล์ Excel สำหรับใบลดหนี้และใบเพิ่มหนี้",
    "pendingExpiredCertificateMessage": "*** สำคัญ *** ใบรับรองอิเล็กทรอนิกส์ของท่านใกล้หมดอายุแล้ว",
    "pendingExpiredCertificateMessage2": " กรุณาทำการขอใบรับรองอิเล็กทรอนิกส์ใหม่",
    "expiredCertificateMessage": "*** สำคัญ *** ใบรับรองอิเล็กทรอนิกส์ของท่านหมดอายุแล้ว",
    "expiredCertificateMessage2": " กรุณาทำการขอใบรับรองอิเล็กทรอนิกส์ใหม่",
    "requestCertificate": "ขอใบรับรองฯใหม่"
  },
  "excelDragDropDropzone": {
    "dropzoneAlertCSV": "ขออภัย ระบบพบว่าไฟล์ CSV มีคอลัมน์ไม่ครบถ้วนหรือไม่ถูกต้อง กรุณาตรวจสอบไฟล์ของคุณ หรือดาวน์โหลด ตัวอย่างไฟล์ Excel สำหรับอัปโหลด ใหม่",
    "dropzoneAlertExcel": "ขออภัย ระบบพบว่าไฟล์ Excel มีคอลัมน์ไม่ครบถ้วนหรือไม่ถูกต้อง กรุณาตรวจสอบไฟล์ของคุณ หรือดาวน์โหลด ตัวอย่างไฟล์ Excel สำหรับอัปโหลด ใหม่",
    "dropzoneAlert500": "สร้างเอกสารได้สูงสุด 500 ใบ/ครั้ง กรุณาอัปโหลดใหม่อีกครั้ง",
    "dropzoneAlertOther": "พบข้อผิดพลาด กรุณาตรวจสอบแล้วอัปโหลดใหม่อีกครั้ง หรือเปลี่ยนสกุลไฟล์เป็น xlsx, xls แล้วอัปโหลดใหม่",
    "dropzoneAlertNoOrder": "ขออภัย ระบบไม่พบ order ในบรรทัดที่ ",
    "dropzoneAlertOrderInvalid1": "ขออภัย ระบบพบว่า order ในบรรทัดที่ ",
    "dropzoneAlertOrderInvalid2": " ไม่ถูกต้อง กรุณาระบุเป็นตัวเลขเท่านั้น",
    "processing": "กำลังดำเนินการ...",
    "header": "อัปโหลดไฟล์ Excel สร้างเอกสาร e-Tax",
    "upload": "อัปโหลด",
    "uploadDescription": "วางไฟล์ .csv, .xls หรือ .xlsx เพื่อสร้างเอกสาร e-Tax",
    "article": "คู่มือการอัปโหลดไฟล์ Excel",
    "excelTemplate": "ตัวอย่างไฟล์ Excel สำหรับอัปโหลด",
  },
  "excelDragDropCheck": {
    "checkError": "กรุณากำหนดตำแหน่งข้อมูลในรายการที่จำเป็นทั้งหมด",
    "processing": "กำลังดำเนินการ...",
    "confirm": "ยืนยัน",
    "back": "กลับ",
    "close": "ปิด",
    "file": "ชื่อไฟล์:",
    "reset": "ล้างการกำหนดตำแหน่งข้อมูล",
    "columnFromFile": "ข้อมูลจากไฟล์โอนย้าย",
    "columnList": "รายการ",
    "columnPosition": "ตำแหน่งข้อมูล",
  },
  "excelDragDropFail": {
    "order": "ลำดับที่ไม่ผ่าน",
    "number": "เลขที่เอกสาร",
    "dateBE": "วันที่บนเอกสาร",
    "error": "รายละเอียดที่ไม่ถูกต้อง",
    "back": "ย้อนกลับ",
    "header": "ขออภัย ตรวจพบรายละเอียดเอกสารที่ไม่ถูกต้อง",
  },
  "excelDragDropSuccess": {
    "date": "วันเดือนปีที่จ่าย",
    "number": "เลขที่เอกสาร",
    "taxId": "เลขประจำตัวผู้เสียภาษีอากร",
    "name": "ชื่อ",
    "amountTotal": "จำนวนเงินที่จ่าย",
    "vatTotal": "จำนวนเงินภาษีที่หัก",
    "key": "ลำดับ",
    "dateBE": "วันที่บนเอกสาร",
    "referNo": "เลขที่เอกสาร",
    "receiptType": "ชื่อเอกสาร",
    "customerName": "ชื่อผู้ซื้อ",
    "vat": "ภาษีมูลค่าเพิ่ม",
    "total": "ยอดรวมสุทธิ",
    "docStatus": "สถานะเอกสาร",
    "success": "สำเร็จ",
    "pending": "รอดำเนินการ",
    "processing": "กำลังสร้างเอกสาร...",
    "processing2": "กำลังดำเนินการ...",
    "fail": "ไม่สำเร็จ",
    "viewPDF": "ดูตัวอย่าง",
    "createDoc": "สร้างเอกสาร",
    "tryAgain": "กรุณาลองใหม่อีกครั้ง",
    "header": "ระบบจะสร้างเอกสาร e-Tax ตามรายละเอียดต่อไปนี้",
    "cancel": "ยกเลิก",
    "signProcessing": "กำลังสร้างเอกสารและลงลายเซ็นดิจิทัล...",
    "viewAll": "ดูเอกสารทั้งหมด",
    "back": "กลับ",
    "receipt-taxinvoice": "ใบเสร็จรับเงิน/ใบกำกับภาษี",
    "receipt-taxinvoice-abb": "ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ",
    "receipt": "ใบเสร็จรับเงิน",
    "invoice-taxinvoice": "ใบแจ้งหนี้/ใบกำกับภาษี",
    "deliveryorder-taxinvoice": "ใบส่งของ/ใบกำกับภาษี",
    "taxinvoice": "ใบกำกับภาษี",
    "debit-note": "ใบเพิ่มหนี้",
    "credit-note": "ใบลดหนี้",
    "quotation": "ใบเสนอราคา",
    "invoice": "ใบแจ้งหนี้",
    "billing-note": "ใบวางบิล",
    "deliveryorder": "ใบส่งของ",
  },
  "excelDragDropRowName": {
    "order": "ลำดับที่",
    "documentType": "ประเภทเอกสาร",
    "number": "เลขที่",
    "dateBE": "วันเดือนปี",
    "dueDateBE": "วันเดือนปีครบกำหนดชำระ",
    "reference": "อ้างอิง",
    "includeVat": "รวมภาษีมูลค่าเพิ่ม",
    "calculationMethod": "วิธีคำนวณราคา",
    "discount": "ส่วนลด",
    "language": "ภาษา",
    "customerName": "ชื่อลูกค้า",
    "customerAddressLineOne": "ที่อยู่ บรรทัดที่ 1",
    "customerAddressLineTwo": "ที่อยู่ บรรทัดที่ 2",
    "customerPostcode": "รหัสไปรษณีย์",
    "customerCountryName": "ประเทศ",
    "customerTaxNumberType": "ประเภทผู้เสียภาษี",
    "customerTaxId": "เลขที่ประจำตัวผู้เสียภาษี",
    "customerBranchNumber": "สาขาที่",
    "customerPhone": "เบอร์โทร",
    "customerEmail": "อีเมล",
    "note": "หมายเหตุ",
    "createdBy": "ผู้จัดทำ",
    "receivedBy": "ผู้รับเงิน",
    "reIssue": "ออกใบใหม่แทนใบเดิม",
    "referTypeCode": "ประเภทใบกำกับภาษีเดิม",
    "referNumber": "เลขที่ใบกำกับภาษีเดิม",
    "referDateBE": "วันเดือนปีใบกำกับภาษีเดิม",
    "referAmountTotal": "มูลค่าใบกำกับภาษีเดิม",
    "referReasonCode": "รหัสสาเหตุการยกเลิกใบกำกับภาษีเดิม",
    "referSpecificReason": "ระบุสาเหตุ",

    "itemDescription1": "รายการสินค้าที่ 1",
    "itemQuantity1": "จำนวนสินค้าที่ 1",
    "itemUnitName1": "หน่วยสินค้าที่ 1",
    "itemPrice1": "ราคาสินค้าที่ 1",
    "itemDiscount1": "ส่วนลดสินค้าที่ 1",
    "itemPercentVat1": "ภาษีสินค้าที่ 1",

    "itemDescription2": "รายการสินค้าที่ 2",
    "itemQuantity2": "จำนวนสินค้าที่ 2",
    "itemUnitName2": "หน่วยสินค้าที่ 2",
    "itemPrice2": "ราคาสินค้าที่ 2",
    "itemDiscount2": "ส่วนลดสินค้าที่ 2",
    "itemPercentVat2": "ภาษีสินค้าที่ 2",

    "itemDescription3": "รายการสินค้าที่ 3",
    "itemQuantity3": "จำนวนสินค้าที่ 3",
    "itemUnitName3": "หน่วยสินค้าที่ 3",
    "itemPrice3": "ราคาสินค้าที่ 3",
    "itemDiscount3": "ส่วนลดสินค้าที่ 3",
    "itemPercentVat3": "ภาษีสินค้าที่ 3",

    "itemDescription4": "รายการสินค้าที่ 4",
    "itemQuantity4": "จำนวนสินค้าที่ 4",
    "itemUnitName4": "หน่วยสินค้าที่ 4",
    "itemPrice4": "ราคาสินค้าที่ 4",
    "itemDiscount4": "ส่วนลดสินค้าที่ 4",
    "itemPercentVat4": "ภาษีสินค้าที่ 4",

    "itemDescription5": "รายการสินค้าที่ 5",
    "itemQuantity5": "จำนวนสินค้าที่ 5",
    "itemUnitName5": "หน่วยสินค้าที่ 5",
    "itemPrice5": "ราคาสินค้าที่ 5",
    "itemDiscount5": "ส่วนลดสินค้าที่ 5",
    "itemPercentVat5": "ภาษีสินค้าที่ 5",

    "noRow": "รายการ",
  },
  "excelDropzoneResult": {
    "order": "ลำดับที่ไม่ผ่าน",
    "number": "เลขที่เอกสาร",
    "dateBE": "วันที่บนเอกสาร",
    "error": "รายละเอียดที่ไม่ถูกต้อง",
    "back": "ย้อนกลับ",
    "header": "ขออภัย ตรวจพบรายละเอียดเอกสารที่ไม่ถูกต้อง",
  },
  "excelDropzoneUploadList": {
    "date": "วันเดือนปีที่จ่าย",
    "number": "เลขที่เอกสาร",
    "taxId": "เลขประจำตัวผู้เสียภาษีอากร",
    "name": "ชื่อ",
    "amountTotal": "จำนวนเงินที่จ่าย",
    "vatTotal": "จำนวนเงินภาษีที่หัก",
    "key": "ลำดับ",
    "dateBE": "วันที่บนเอกสาร",
    "referNo": "เลขที่เอกสาร",
    "receiptType": "ชื่อเอกสาร",
    "customerName": "ชื่อผู้ซื้อ",
    "vat": "ภาษีมูลค่าเพิ่ม",
    "total": "ยอดรวมสุทธิ",
    "docStatus": "สถานะเอกสาร",
    "success": "สำเร็จ",
    "pending": "รอดำเนินการ",
    "processing": "กำลังสร้างเอกสาร...",
    "processing2": "กำลังดำเนินการ...",
    "fail": "ไม่สำเร็จ",
    "viewPDF": "ดูตัวอย่าง",
    "createDoc": "สร้างเอกสาร",
    "tryAgain": "กรุณาลองใหม่อีกครั้ง",
    "header": "ระบบจะสร้างเอกสาร e-Tax ตามรายละเอียดต่อไปนี้",
    "cancel": "ยกเลิก",
    "signProcessing": "กำลังสร้างเอกสารและลงลายเซ็นดิจิทัล...",
    "viewAll": "ดูเอกสารทั้งหมด",
    "back": "กลับ",
    "receipt-taxinvoice": "ใบเสร็จรับเงิน/ใบกำกับภาษี",
    "receipt-taxinvoice-abb": "ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ",
    "receipt": "ใบเสร็จรับเงิน",
    "invoice-taxinvoice": "ใบแจ้งหนี้/ใบกำกับภาษี",
    "deliveryorder-taxinvoice": "ใบส่งของ/ใบกำกับภาษี",
    "taxinvoice": "ใบกำกับภาษี",
    "debit-note": "ใบเพิ่มหนี้",
    "credit-note": "ใบลดหนี้",
    "quotation": "ใบเสนอราคา",
    "invoice": "ใบแจ้งหนี้",
    "billing-note": "ใบวางบิล",
    "deliveryorder": "ใบส่งของ",
    "showDifferenceMonth": "ระบบพบว่า มีเอกสารที่มีค่า วัน/เดือน/ปี ไม่ตรงกับเดือนปัจจุบัน กรุณาตรวจสอบก่อนสร้างเอกสาร",
  },
  "contactDropzone": {
    "dropzoneAlertCSV": "ขออภัย ระบบพบว่าไฟล์ CSV มีคอลัมน์ไม่ครบถ้วนหรือไม่ถูกต้อง กรุณาตรวจสอบไฟล์ของคุณ หรือดาวน์โหลด ตัวอย่างไฟล์ Excel สำหรับอัปโหลด ใหม่",
    "dropzoneAlertExcel": "ขออภัย ระบบพบว่าไฟล์ Excel มีคอลัมน์ไม่ครบถ้วนหรือไม่ถูกต้อง กรุณาตรวจสอบไฟล์ของคุณ หรือดาวน์โหลด ตัวอย่างไฟล์ Excel สำหรับอัปโหลด ใหม่",
    "dropzoneAlert500": "สร้างเอกสารได้สูงสุด 500 ใบ/ครั้ง กรุณาอัปโหลดใหม่อีกครั้ง",
    "processing": "กำลังดำเนินการ...",
    "header": "อัปโหลดไฟล์ Excel เพิ่มผู้ติดต่อ",
    "upload": "อัปโหลด",
    "uploadDescription": "วางไฟล์ .csv, .xls หรือ .xlsx เพื่อเพิ่มผู้ติดต่อ",
    "excelTemplate": "ตัวอย่างไฟล์ Excel สำหรับอัปโหลด",
  },
  "contactDropzoneResult": {
    "error": "รายละเอียดที่ไม่ถูกต้อง",
    "header": "ขออภัย ตรวจพบรายละเอียดเอกสารที่ไม่ถูกต้อง",
    "back": "ย้อนกลับ",
    "name": "ชื่อ",
    "taxId": "เลขประจำตัวผู้เสียภาษี",
    "branchNumber": "สาขาที่",
  },
  "contactDropzoneUploadList": {
    "header": "ระบบจะเพิ่มผู้ติดต่อ ตามรายชื่อต่อไปนี้",
    "back": "ย้อนกลับ",
    "cancel": "ยกเลิก",
    "taxId": "เลขประจำตัวผู้เสียภาษี",
    "branchNumber": "สาขาที่",
    "address": "ที่อยู่",
    "name": "ชื่อ",
    "status": "สถานะรายชื่อ",
    "amountTotal": "จำนวนเงินที่จ่าย",
    "vatTotal": "จำนวนเงินภาษีที่หัก",
    "docStatus": "สถานะเอกสาร",
    "success": "สำเร็จ",
    "pending": "รอดำเนินการ",
    "processing": "กำลังเพิ่มรายชื่อ...",
    "fail": "ไม่สำเร็จ",
    "viewPDF": "ดูตัวอย่าง",
    "addContact": "เพิ่มรายชื่อ",
    "createDoc": "สร้าง",
    "tryAgain": "กรุณาลองใหม่อีกครั้ง",
    "signProcessing": "กำลังสร้างเอกสารและลงลายเซ็นดิจิทัล...",
    "viewAll": "ดูรายชื่อทั้งหมด",
    "processing2": "กำลังดำเนินการ...",
  },
  "inbox": {
    "inboxDate": "วันที่ได้รับเอกสาร",
    "sender": "ชื่อผู้ส่ง",
    "email": "อีเมล",
    "file": "ชื่อไฟล์",
    "fileSize": "ขนาด (KB)",
    "message": "ข้อความ",
    "header": "กล่องเอกสารเข้า",
    "searchPlaceholder": "ชื่อผู้ส่ง ชื่ออีเมล หรือชื่อไฟล์",
  },
  "sent": {
    "sentDate": "วันที่ส่งเอกสาร",
    "receiver": "ชื่อผู้รับ",
    "email": "อีเมล",
    "file": "ชื่อไฟล์",
    "fileSize": "ขนาด (KB)",
    "status": "สถานะนำส่ง",
    "message": "ข้อความ",
    "header": "ประวัติการส่งเอกสาร",
    "searchPlaceholder": "ชื่อผู้รับ ชื่ออีเมล หรือชื่อไฟล์",
    "sentEmail": "ส่งเอกสาร",
  },
  "sentUpload": {
    "sentDate": "วันที่นำส่ง",
    "receiver": "ชื่อผู้รับ",
    "file": "ชื่อไฟล์",
    "fileSize": "ขนาด (KB)",
    "status": "สถานะนำส่ง",
    "sentEmail": "ส่งเอกสาร",
    "sentReport": "ดูรายงานการส่งเอกสาร",
    "index": "ลำดับ",
    "chooseFile": "เลือกไฟล์เอกสาร",
    "receiverInfo": "ข้อมูลผู้รับเอกสาร",
    "leceiptReceiverOnly": "(เฉพาะผู้รับเอกสารที่มีบัญชีใน Leceipt เท่านั้น)",
    "upload": "อัปโหลด",
    "uploadDescription": "วางไฟล์ .pdf หรือ .zip เพื่อส่งเอกสารให้ผู้รับ",
    "uploadDescriptionSize": "ขนาดไม่เกิน 35 MB",
    "back": "กลับ",
    "sent": "ส่ง",
    "email": "อีเมลผู้รับเอกสาร",
    "fillEmail": "กรุณากรอก E-mail",
    "noEmail": "ไม่มี E-mail นี้บนระบบ Leceipt",
    "wrongEmail": "รูปแบบ E-mail ไม่ถูกต้อง",
    "message": "ข้อความ",
    "downloading": "กำลังโหลด...",
    "next": "ต่อไป",
    "success": "สำเร็จ",
    "pending": "รอดำเนินการ",
    "processing": "กำลังส่งเอกสาร...",
    "fail": "ไม่สำเร็จ",
  },
  "sentReport": {
    "download": "ดาวน์โหลด",
    "back": "กลับ",
    "downloading": "กำลังโหลด...",
    "processing": "กำลังดำเนินการ",
    "processingMessage": "สร้างตัวอย่างเอกสาร รายงานการส่งเอกสาร",
  },
  "company": {
    "shortMonths01": "ม.ค.",
    "shortMonths02": "ก.พ.",
    "shortMonths03": "มี.ค.",
    "shortMonths04": "เม.ย.",
    "shortMonths05": "พ.ค.",
    "shortMonths06": "มิ.ย.",
    "shortMonths07": "ก.ค.",
    "shortMonths08": "ส.ค.",
    "shortMonths09": "ก.ย.",
    "shortMonths10": "ต.ค.",
    "shortMonths11": "พ.ย.",
    "shortMonths12": "ธ.ค.",
    "months01": "มกราคม",
    "months02": "กุมภาพันธ์",
    "months03": "มีนาคม",
    "months04": "เมษายน",
    "months05": "พฤษภาคม",
    "months06": "มิถุนายน",
    "months07": "กรกฎาคม",
    "months08": "สิงหาคม",
    "months09": "กันยายน",
    "months10": "ตุลาคม",
    "months11": "พฤศจิกายน",
    "months12": "ธันวาคม",
    "goToToday": "ไปวันที่ปัจจุบัน",
    "dropdownErrMsg": "กรุณาเลือก",
    "getErrorMessage": "กรุณากรอกข้อมูล",
    "getPostCodeErrorMessage1": "กรุณาระบุตัวเลขเท่านั้น",
    "getTaxIdErrorMessage": "กรอกเลข 13 หลัก",
    "getBranchNumberErrorMessage1": "ระบุเป็นตัวเลขเท่านั้น",
    "getBranchNumberErrorMessage2": "กรอกเลข 5 หลัก",
    "getPhoneNumberErrorMessage": "รูปแบบไม่ถูกต้อง ตัวอย่าง: 0812345678",
    "documentHeader": "ใบเสร็จรับเงิน/ใบกำกับภาษี",
    "documentSubHeader": "Receipt/Tax Invoice",
    "original": "ต้นฉบับ",
    "companyTel": "โทร",
    "companyEmail": "อีเมล",
    "companyTaxId": "เลขประจำตัวผู้เสียภาษี",
    "customerName": "ชื่อลูกค้า",
    "pleaseSelect": "กรุณาเลือก",
    "country": "ประเทศ",
    "addressStructure": "โครงสร้างที่อยู่",
    "on": "เปิด",
    "off": "ปิด",
    "lineOne": "ที่อยู่ บรรทัดที่ 1",
    "lineTwo": "ที่อยู่ บรรทัดที่ 2",
    "english": "ภาษาอังกฤษ",
    "taxNumberType": "ประเภทผู้เสียภาษี",
    "txid": "เลขประจำตัวผู้เสียภาษี",
    "nidn": "เลขประจำตัวประชาชน",
    "ccpt": "เลขที่หนังสือเดินทาง",
    "othr": "ไม่ระบุ",
    "number": "เลขที่",
    "branchDisplay": "สำนักงานใหญ่/สาขา",
    "tel": "โทร",
    "priceIncludeVat": "ราคารวมภาษีมูลค่าเพิ่ม",
    "date": "วันที่",
    "pleaseSelectDate": "กรุณาเลือกวันที่",
    "reference": "อ้างอิง",
    "itemNumber": "ลำดับ",
    "item": "รายการ",
    "quantity": "จำนวน",
    "unit": "หน่วย",
    "unitPrice": "ราคา/หน่วย",
    "discount": "ส่วนลด",
    "itemVat": "ภาษี (%)",
    "totalIncludeVat": "มูลค่ารวม VAT(บาท)",
    "totalBeforeVat": "มูลค่าก่อนภาษี (บาท)",
    "totalErrorMessage1": "มูลค่าไม่สามารถติดลบได้",
    "delete": "ลบ",
    "addItem": "เพิ่มรายการ",
    "note": "หมายเหตุ",
    "receivedBy": "ผู้รับเงิน",
    "createdBy": "ผู้จัดทำ",
    "reIssueMessage1": "เป็นการยกเลิกและออกใบกำกับภาษีใหม่แทนใบกำกับภาษีเดิม",
    "amountTotalBeforeVat": "รวมเงิน (ก่อน Vat)",
    "amountTotal": "รวมเป็นเงิน",
    "thb": "บาท",
    "vat": "ภาษีมูลค่าเพิ่ม",
    "grandTotal": "จำนวนเงินทั้งสิ้น",
    "referNumber": "เลขที่ใบกำกับภาษีเดิม",
    "referDate": "วันที่ใบกำกับภาษีเดิม",
    "reason": "สาเหตุการยกเลิกใบกำกับภาษีเดิม",
    "specificReason": "ระบุสาเหตุ",
    "nonVatPriceTotal": "มูลค่ารายการยกเว้นภาษี",
    "zeroVatPriceTotal": "มูลค่ารายการภาษี 0%",
    "vatPriceTotal": "มูลค่ารายการภาษี 7%",
    "next": "ต่อไป",
    "cancel": "ยกเลิก",
    "loading": "กำลังโหลด...",
    "isRequiredErrorMessage": "กรุณาเลือกวันที่",
    "taxIdError": "กรอกเลขประจำตัวผู้เสียภาษีให้ครบ 13 หลัก",
    "updateSuccess": "บันทึกสำเร็จ!",
    "updateFail": "บันทึกไม่สำเร็จ!",
    "header": "ข้อมูลบริษัท",
    "headerDescription": "(ข้อมูลทั้งหมดข้างล่างนี้ ใช้สำหรับแสดงบนหัวเอกสารของท่าน)",
    "companyInfo": "ข้อมูลชื่อกิจการ",
    "taxId": "เลขประจำตัวผู้เสียภาษี 13 หลัก",
    "branchNumber": "เลขที่",
    "branchNumberDesc": "ตัวอย่าง 00001 (อ้างอิงจาก ภ.พ.20)",
    "search": "ค้นหา",
    "name": "ชื่อบริษัท",
    "namePlaceHolder": "บริษัท ผู้ขาย จำกัด",
    "addressForCreate": "ที่อยู่สำหรับออกเอกสาร",
    "buildingNumber": "บ้านเลขที่",
    "address": "ที่อยู่",
    "streetPrefix": "คำนำหน้าถนน",
    "streetName": "ถนน",
    "provincePrefix": "คำนำหน้าจังหวัด",
    "province": "จังหวัด",
    "districtPrefix": "คำนำหน้าอำเภอ",
    "district": "อำเภอ (เลือกจังหวัดก่อน)",
    "subDistrictPrefix": "คำนำหน้าตำบล",
    "subDistrict": "ตำบล (เลือกอำเภอก่อน)",
    "postcode": "รหัสไปรษณีย์",
    "getPostCodeErrorMessage2": "กรุณาระบุเลขให้ครบ 5 หลัก",
    "phone": "เบอร์โทร",
    "email": "อีเมล",
    "contactForCustomer": "ลูกค้าติดต่อที่",
    "registerVat": "จดทะเบียนภาษีมูลค่าเพิ่ม",
    "registerVatYes": "ได้จดทะเบียน ภ.พ. 20",
    "registerVatNo": "ไม่ได้จดทะเบียน ภ.พ. 20",
    "saveSeparator": "กดปุ่มเพื่อบันทึกข้อมูล",
    "save": "บันทึก",
    "saving": "กำลังบันทึก...",
    "notDisplay": "ไม่แสดง",
    "back": "กลับ",
    "addCompany": "เพิ่มบริษัทใหม่",
    "add": "เพิ่ม",
    "searchFail": "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง",
    "searchNotFound": "ไม่พบข้อมูลบริษัท",
  },
  "contact": {
    "upload": "อัปโหลด",
    "refresh": "รีเฟรช",
    "name": "ชื่อ",
    "taxId": "เลขประจำตัวผู้เสียภาษี",
    "address": "ที่อยู่",
    "contactName": "ชื่อผู้ติดต่อ",
    "phone": "เบอร์โทร",
    "edit": "ดู/แก้ไข",
    "delete": "ลบ",
    "receipt-taxinvoice": "สร้างใบเสร็จรับเงิน/ใบกำกับภาษี",
    "receipt-taxinvoice-abb": "สร้างใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ",
    "receipt": "สร้างใบเสร็จรับเงิน",
    "invoice-taxinvoice": "สร้างใบแจ้งหนี้/ใบกำกับภาษี",
    "deliveryorder-taxinvoice": "สร้างใบส่งของ/ใบกำกับภาษี",
    "taxinvoice": "สร้างใบกำกับภาษี",
    "debit-note": "สร้างใบเพิ่มหนี้",
    "credit-note": "สร้างใบลดหนี้",
    "quotation": "สร้างใบเสนอราคา",
    "invoice": "สร้างใบแจ้งหนี้",
    "billing-note": "สร้างใบวางบิล",
    "deliveryorder": "สร้างใบส่งของ",
    "expense-note": "สร้างบันทึกซื้อ/ค่าใช้จ่าย",
    "withholding-tax": "สร้างหนังสือรับรองการหักภาษี ณ ที่จ่าย",
    "select": "เลือก",
    "updateSuccess": "บันทึกสำเร็จ!",
    "addContact": "เพิ่มผู้ติดต่อ",
    "searchPlaceholder": "ชื่อผู้ติดต่อ เลขประจำตัวผู้เสียภาษี เบอร์โทร",
    "close": "ปิด",
    "header": "ผู้ติดต่อทั้งหมด",
    "deleteConfirmHeader": "ลบผู้ติดต่อ",
    "deleteConfirm1": "คุณต้องการที่จะลบผู้ติดต่อ ",
    "deleteConfirm2": " ใช่ไหม?",
  },
  "addContact": {
    "dropdownErrMsg": "กรุณาเลือก",
    "getErrorMessage": "กรุณากรอกข้อมูล",
    "getPostCodeErrorMessage1": "กรุณาระบุตัวเลขเท่านั้น",
    "getPostCodeErrorMessage2": "กรุณาระบุเลขให้ครบ 5 หลัก",
    "getTaxIdErrorMessage": "กรอกเลข 13 หลัก",
    "getBranchNumberErrorMessage1": "ระบุเป็นตัวเลขเท่านั้น",
    "getBranchNumberErrorMessage2": "กรอกเลข 5 หลัก",
    "getPhoneNumberErrorMessage": "รูปแบบไม่ถูกต้อง ตัวอย่าง: 0812345678",
    "documentHeader": "ใบเสร็จรับเงิน/ใบกำกับภาษี",
    "documentSubHeader": "Receipt/Tax Invoice",
    "original": "ต้นฉบับ",
    "companyTel": "โทร",
    "companyEmail": "อีเมล",
    "companyTaxId": "เลขประจำตัวผู้เสียภาษี",
    "customerName": "ชื่อลูกค้า",
    "pleaseSelect": "กรุณาเลือก",
    "othr": "ไม่ระบุ",
    "number": "เลขที่",
    "tel": "โทร",
    "priceIncludeVat": "ราคารวมภาษีมูลค่าเพิ่ม",
    "date": "วันที่",
    "pleaseSelectDate": "กรุณาเลือกวันที่",
    "reference": "อ้างอิง",
    "itemNumber": "ลำดับ",
    "item": "รายการ",
    "quantity": "จำนวน",
    "unit": "หน่วย",
    "unitPrice": "ราคา/หน่วย",
    "discount": "ส่วนลด",
    "itemVat": "ภาษี (%)",
    "totalIncludeVat": "มูลค่ารวม VAT(บาท)",
    "totalBeforeVat": "มูลค่าก่อนภาษี (บาท)",
    "totalErrorMessage1": "มูลค่าไม่สามารถติดลบได้",
    "delete": "ลบ",
    "addItem": "เพิ่มรายการ",
    "note": "หมายเหตุ",
    "receivedBy": "ผู้รับเงิน",
    "createdBy": "ผู้จัดทำ",
    "reIssueMessage1": "เป็นการยกเลิกและออกใบกำกับภาษีใหม่แทนใบกำกับภาษีเดิม",
    "amountTotalBeforeVat": "รวมเงิน (ก่อน Vat)",
    "amountTotal": "รวมเป็นเงิน",
    "thb": "บาท",
    "vat": "ภาษีมูลค่าเพิ่ม",
    "grandTotal": "จำนวนเงินทั้งสิ้น",
    "referNumber": "เลขที่ใบกำกับภาษีเดิม",
    "referDate": "วันที่ใบกำกับภาษีเดิม",
    "reason": "สาเหตุการยกเลิกใบกำกับภาษีเดิม",
    "specificReason": "ระบุสาเหตุ",
    "nonVatPriceTotal": "มูลค่ารายการยกเว้นภาษี",
    "zeroVatPriceTotal": "มูลค่ารายการภาษี 0%",
    "vatPriceTotal": "มูลค่ารายการภาษี 7%",
    "next": "ต่อไป",
    "cancel": "ยกเลิก",
    "loading": "กำลังโหลด...",
    "isRequiredErrorMessage": "กรุณาเลือกวันที่",
    "taxIdError": "กรอกเลขประจำตัวผู้เสียภาษีให้ครบ 13 หลัก",
    "updateSuccess": "บันทึกสำเร็จ!",
    "updateFail": "บันทึกไม่สำเร็จ!",
    "namePlaceHolder": "บริษัท ผู้ขาย จำกัด",
    "addressForCreate": "ที่อยู่สำหรับออกเอกสาร",
    "email": "อีเมล",
    "contactForCustomer": "ลูกค้าติดต่อที่",
    "registerVat": "จดทะเบียนภาษีมูลค่าเพิ่ม",
    "registerVatYes": "ได้จดทะเบียน ภ.พ. 20",
    "registerVatNo": "ไม่ได้จดทะเบียน ภ.พ. 20",
    "saveSeparator": "กดปุ่มเพื่อบันทึกข้อมูล",
    "save": "บันทึก",
    "saving": "กำลังบันทึก...",
    "notDisplay": "ไม่แสดง",
    "alreadyHaveTaxId": "เลขประจำตัวผู้เสียภาษีมีในรายชื่อผู้ติดต่อแล้ว!",
    "alreadyHaveContact": "ผู้ติดต่อนี้ถูกใช้แล้ว!",
    "processing": "กำลังดำเนินการ...",
    "header": "เพิ่มผู้ติดต่อ",
    "headerDescription": "(ข้อมูลทั้งหมดข้างล่างนี้ ใช้สำหรับแสดงข้อมูลผู้ติดต่อบนเอกสารของท่าน)",
    "companyInfo": "ข้อมูลชื่อกิจการ",
    "taxNumberType": "ประเภทบุคคล",
    "txid": "เลขประจำตัวผู้เสียภาษี",
    "nidn": "เลขประจำตัวประชาชน",
    "ccpt": "เลขที่หนังสือเดินทาง",
    "english": "ภาษาอังกฤษ",
    "on": "เปิด",
    "off": "ปิด",
    "taxId": "เลขที่",
    "branchDisplay": "สาขา",
    "branchNumber": "เลขที่",
    "branchNumberDesc": "ตัวอย่าง 00001",
    "search": "ค้นหา",
    "companyName": "ชื่อบริษัท",
    "name": "ชื่อ",
    "addressHeader": "ที่อยู่จดทะเบียน",
    "addressStructure": "โครงสร้างที่อยู่",
    "buildingNumber": "บ้านเลขที่",
    "address": "ที่อยู่",
    "streetPrefix": "คำนำหน้าถนน",
    "streetName": "ถนน",
    "provincePrefix": "คำนำหน้าจังหวัด",
    "province": "จังหวัด",
    "districtPrefix": "คำนำหน้าอำเภอ",
    "district": "อำเภอ (เลือกจังหวัดก่อน)",
    "subDistrictPrefix": "คำนำหน้าตำบล",
    "subDistrict": "ตำบล (เลือกอำเภอก่อน)",
    "postcode": "รหัสไปรษณีย์",
    "lineOne": "ที่อยู่ บรรทัดที่ 1",
    "lineTwo": "ที่อยู่ บรรทัดที่ 2",
    "country": "ประเทศ",
    "contactHeader": "ช่องทางติดต่อ",
    "phone": "เบอร์โทร",
    "website": "เว็บไซต์",
    "fax": "เบอร์แฟกซ์",
    "contactHeader2": "ข้อมูลบุคคลที่ติดต่อได้",
    "contactName": "ชื่อผู้ติดต่อ",
    "contactNickName": "ชื่อเล่น",
    "contactEmail": "อีเมล",
    "contactPhone": "เบอร์โทร",
    "contactDepartment": "แผนก",
    "contactPosition": "ตำแหน่งงาน",
    "paymentHeader": "ข้อมูลธนาคารของคู่ค้า",
    "accountName": "ชื่อบัญชีธนาคาร",
    "bankName": "ธนาคาร",
    "bankBranch": "สาขา",
    "accountType": "บัญชีประเภท",
    "accountNumber": "บัญชีเลขที่",
    "paymentNote": "หมายเหตุการชำระเงิน",
    "addBank": "เพิ่มธนาคาร",
    "back": "กลับ",
    "getErrorMessageTaxIdZeroOnly": "ข้อมูลไม่ถูกต้อง"
  },
  "person": {
    "upload": "อัปโหลด",
    "refresh": "รีเฟรช",
    "name": "ชื่อ",
    "taxId": "เลขประจำตัวผู้เสียภาษี",
    "address": "ที่อยู่",
    "contactName": "ชื่อผู้ติดต่อ",
    "phone": "เบอร์โทร",
    "edit": "ดู/แก้ไข",
    "delete": "ลบ",
    "receipt-taxinvoice": "สร้างใบเสร็จรับเงิน/ใบกำกับภาษี",
    "receipt-taxinvoice-abb": "สร้างใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ",
    "receipt": "สร้างใบเสร็จรับเงิน",
    "invoice-taxinvoice": "สร้างใบแจ้งหนี้/ใบกำกับภาษี",
    "deliveryorder-taxinvoice": "สร้างใบส่งของ/ใบกำกับภาษี",
    "taxinvoice": "สร้างใบกำกับภาษี",
    "debit-note": "สร้างใบเพิ่มหนี้",
    "credit-note": "สร้างใบลดหนี้",
    "quotation": "สร้างใบเสนอราคา",
    "invoice": "สร้างใบแจ้งหนี้",
    "billing-note": "สร้างใบวางบิล",
    "deliveryorder": "สร้างใบส่งของ",
    "expense-note": "สร้างบันทึกซื้อ/ค่าใช้จ่าย",
    "withholding-tax": "สร้างหนังสือรับรองการหักภาษี ณ ที่จ่าย",
    "select": "เลือก",
    "updateSuccess": "บันทึกสำเร็จ!",
    "addContact": "เพิ่มผู้ติดต่อ",
    "searchPlaceholder": "ชื่อผู้ติดต่อ เลขประจำตัวผู้เสียภาษี เบอร์โทร",
    "close": "ปิด",
    "header": "ผู้ติดต่อทั้งหมด",
    "deleteConfirmHeader": "ลบผู้ติดต่อ",
    "deleteConfirm1": "คุณต้องการที่จะลบผู้ติดต่อ ",
    "deleteConfirm2": " ใช่ไหม?",
  },
  "addPerson": {
    "dropdownErrMsg": "กรุณาเลือก",
    "getErrorMessage": "กรุณากรอกข้อมูล",
    "getPostCodeErrorMessage1": "กรุณาระบุตัวเลขเท่านั้น",
    "getPostCodeErrorMessage2": "กรุณาระบุเลขให้ครบ 5 หลัก",
    "getTaxIdErrorMessage": "กรอกเลข 13 หลัก",
    "getBranchNumberErrorMessage1": "ระบุเป็นตัวเลขเท่านั้น",
    "getBranchNumberErrorMessage2": "กรอกเลข 5 หลัก",
    "getPhoneNumberErrorMessage": "รูปแบบไม่ถูกต้อง ตัวอย่าง: 0812345678",
    "documentHeader": "ใบเสร็จรับเงิน/ใบกำกับภาษี",
    "documentSubHeader": "Receipt/Tax Invoice",
    "original": "ต้นฉบับ",
    "companyTel": "โทร",
    "companyEmail": "อีเมล",
    "companyTaxId": "เลขประจำตัวผู้เสียภาษี",
    "customerName": "ชื่อลูกค้า",
    "pleaseSelect": "กรุณาเลือก",
    "othr": "ไม่ระบุ",
    "number": "เลขที่",
    "tel": "โทร",
    "priceIncludeVat": "ราคารวมภาษีมูลค่าเพิ่ม",
    "date": "วันที่",
    "pleaseSelectDate": "กรุณาเลือกวันที่",
    "reference": "อ้างอิง",
    "itemNumber": "ลำดับ",
    "item": "รายการ",
    "quantity": "จำนวน",
    "unit": "หน่วย",
    "unitPrice": "ราคา/หน่วย",
    "discount": "ส่วนลด",
    "itemVat": "ภาษี (%)",
    "totalIncludeVat": "มูลค่ารวม VAT(บาท)",
    "totalBeforeVat": "มูลค่าก่อนภาษี (บาท)",
    "totalErrorMessage1": "มูลค่าไม่สามารถติดลบได้",
    "delete": "ลบ",
    "addItem": "เพิ่มรายการ",
    "note": "หมายเหตุ",
    "receivedBy": "ผู้รับเงิน",
    "createdBy": "ผู้จัดทำ",
    "reIssueMessage1": "เป็นการยกเลิกและออกใบกำกับภาษีใหม่แทนใบกำกับภาษีเดิม",
    "amountTotalBeforeVat": "รวมเงิน (ก่อน Vat)",
    "amountTotal": "รวมเป็นเงิน",
    "thb": "บาท",
    "vat": "ภาษีมูลค่าเพิ่ม",
    "grandTotal": "จำนวนเงินทั้งสิ้น",
    "referNumber": "เลขที่ใบกำกับภาษีเดิม",
    "referDate": "วันที่ใบกำกับภาษีเดิม",
    "reason": "สาเหตุการยกเลิกใบกำกับภาษีเดิม",
    "specificReason": "ระบุสาเหตุ",
    "nonVatPriceTotal": "มูลค่ารายการยกเว้นภาษี",
    "zeroVatPriceTotal": "มูลค่ารายการภาษี 0%",
    "vatPriceTotal": "มูลค่ารายการภาษี 7%",
    "next": "ต่อไป",
    "cancel": "ยกเลิก",
    "loading": "กำลังโหลด...",
    "isRequiredErrorMessage": "กรุณาเลือกวันที่",
    "taxIdError": "กรอกเลขประจำตัวผู้เสียภาษีให้ครบ 13 หลัก",
    "updateSuccess": "บันทึกสำเร็จ!",
    "updateFail": "บันทึกไม่สำเร็จ!",
    "namePlaceHolder": "บริษัท ผู้ขาย จำกัด",
    "addressForCreate": "ที่อยู่สำหรับออกเอกสาร",
    "email": "อีเมล",
    "contactForCustomer": "ลูกค้าติดต่อที่",
    "registerVat": "จดทะเบียนภาษีมูลค่าเพิ่ม",
    "registerVatYes": "ได้จดทะเบียน ภ.พ. 20",
    "registerVatNo": "ไม่ได้จดทะเบียน ภ.พ. 20",
    "saveSeparator": "กดปุ่มเพื่อบันทึกข้อมูล",
    "save": "บันทึก",
    "saving": "กำลังบันทึก...",
    "notDisplay": "ไม่แสดง",
    "alreadyHaveTaxId": "เลขประจำตัวผู้เสียภาษีมีในรายชื่อผู้ติดต่อแล้ว!",
    "alreadyHaveContact": "ผู้ติดต่อนี้ถูกใช้แล้ว!",
    "processing": "กำลังดำเนินการ...",
    "header": "เพิ่มผู้ติดต่อ",
    "headerDescription": "(ข้อมูลทั้งหมดข้างล่างนี้ ใช้สำหรับแสดงข้อมูลผู้ติดต่อบนเอกสารของท่าน)",
    "companyInfo": "ข้อมูลชื่อกิจการ",
    "taxNumberType": "ประเภทบุคคล",
    "txid": "เลขประจำตัวผู้เสียภาษี",
    "nidn": "เลขประจำตัวประชาชน",
    "ccpt": "เลขที่หนังสือเดินทาง",
    "english": "ภาษาอังกฤษ",
    "on": "เปิด",
    "off": "ปิด",
    "taxId": "เลขที่",
    "branchDisplay": "สาขา",
    "branchNumber": "เลขที่",
    "branchNumberDesc": "ตัวอย่าง 00001",
    "search": "ค้นหา",
    "companyName": "ชื่อบริษัท",
    "name": "ชื่อ",
    "addressHeader": "ที่อยู่จดทะเบียน",
    "addressStructure": "โครงสร้างที่อยู่",
    "buildingNumber": "บ้านเลขที่",
    "address": "ที่อยู่",
    "streetPrefix": "คำนำหน้าถนน",
    "streetName": "ถนน",
    "provincePrefix": "คำนำหน้าจังหวัด",
    "province": "จังหวัด",
    "districtPrefix": "คำนำหน้าอำเภอ",
    "district": "อำเภอ (เลือกจังหวัดก่อน)",
    "subDistrictPrefix": "คำนำหน้าตำบล",
    "subDistrict": "ตำบล (เลือกอำเภอก่อน)",
    "postcode": "รหัสไปรษณีย์",
    "lineOne": "ที่อยู่ บรรทัดที่ 1",
    "lineTwo": "ที่อยู่ บรรทัดที่ 2",
    "country": "ประเทศ",
    "contactHeader": "ช่องทางติดต่อ",
    "phone": "เบอร์โทร",
    "website": "เว็บไซต์",
    "fax": "เบอร์แฟกซ์",
    "contactHeader2": "ข้อมูลบุคคลที่ติดต่อได้",
    "contactName": "ชื่อผู้ติดต่อ",
    "contactNickName": "ชื่อเล่น",
    "contactEmail": "อีเมล",
    "contactPhone": "เบอร์โทร",
    "contactDepartment": "แผนก",
    "contactPosition": "ตำแหน่งงาน",
    "paymentHeader": "ข้อมูลธนาคารของคู่ค้า",
    "accountName": "ชื่อบัญชีธนาคาร",
    "bankName": "ธนาคาร",
    "bankBranch": "สาขา",
    "accountType": "บัญชีประเภท",
    "accountNumber": "บัญชีเลขที่",
    "paymentNote": "หมายเหตุการชำระเงิน",
    "addBank": "เพิ่มธนาคาร",
    "back": "กลับ",
    "organization": "องค์กร",
    "label": "ป้ายกำกับ",
    "addOneMorePhone": "เพิ่มเบอร์โทร",
    "addOneMoreEmail": "เพิ่มอีเมล",
    "owner": "ผู้สร้าง",
    "visibleTo": "กลุ่มที่มองเห็น",
  },
  "product": {
    "header": "รายการสินค้า",
    "addProduct": "เพิ่มสินค้า",
    "productName": "ชื่อสินค้า",
    "price": "ราคา",
    "thb": "(บาท)",
    "quantity": "คลัง",
    "updateTime": "อัปเดต",
    "status": "สถานะ",
    "select": "เลือก",
    "min": "ต่ำสุด",
    "max": "สูงสุด",
    "categoryPlaceHolder": "ประเภท",
    "search": "ค้นหา",
    "reset": "รีเซ็ต",
    "recover": "กู้คืน",
    "edit": "แก้ไข",
    "deactivate": "ปิดการใช้งาน",
    "activate": "เปิดการใช้งาน",
    "copy": "คัดลอก",
    "delete": "ลบ",
    "cancel": "ยกเลิก",
    "save": "บันทึก",
    "collapse": "ย่อ",
    "variations": "ตัวเลือก",
    "expand": "ขยาย",
    "productPerPage": "สินค้า/หน้า",
    "noItemFound": "ไม่พบข้อมูล",
  },
  "addProduct": {
    "header": "เพิ่มสินค้าใหม่",
    "usedSku": "เลขที่นี้ถูกใช้แล้ว",
    "pleaseFillInformation": "กรุณาระบุ",
    "basicInformation": "รายละเอียดสินค้า",
    "productName": "ชื่อสินค้า",
    "category": "ประเภท",
    "description": "รายละเอียดสินค้า",
    "productAttributes": "คุณลักษณะของสินค้า",
    "brand": "แบรนด์",
    "salesInformation": "ข้อมูลการขาย",
    "enableVariations": "เปิดใช้งานตัวเลือกสินค้า",
    "on": "เปิด",
    "off": "ปิด",
    "variationName": "ชื่อตัวเลือก",
    "option": "ตัวเลือก",
    "addVariation": "เพิ่มตัวเลือกสินค้า",
    "variationList": "รายการตัวเลือกสินค้า",
    "price": "ราคา",
    "discount": "ส่วนลด",
    "quantity": "จำนวน",
    "apply": "ปรับใช้",
    "unit": "หน่วย",
    "percentVat": "ภาษี (%)",
    "sellerSku": "เลข SKU",
    "save": "บันทึก",
    "SaveAsDraft": "บันทึก",
    "variation1": "ตัวเลือกที่ 1",
    "variation2": "ตัวเลือกที่ 2",
    "back": "กลับ",
    "duplicatedSku": "เลขที่ซ้ำ",
    "tryAgain": "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง",
  },
  "units": {
    "EA": "ชิ้น/อัน",
    "BX": "กล่อง",
    "NMP": "แพ็ค",
    "AU": "หน่วย",
    "BAG": "ถุง",
    "BKT": "ปี๊บ",
    "BT": "ขวด",
    "CAN": "กระป๋อง",
    "CELL": "เซลล์",
    "DAY": "วัน",
    "DR": "ถัง",
    "DZ": "โหล",
    "GLL": "แกลลอน",
    "JOB": "งาน",
    "PCS": "ชิ้น",
    "SET": "ชุด",
    "M": "เมตร",
    "YD": "หลา",
  },
  "receiptTaxInvoice": {
    "shortMonths01": "ม.ค.",
    "shortMonths02": "ก.พ.",
    "shortMonths03": "มี.ค.",
    "shortMonths04": "เม.ย.",
    "shortMonths05": "พ.ค.",
    "shortMonths06": "มิ.ย.",
    "shortMonths07": "ก.ค.",
    "shortMonths08": "ส.ค.",
    "shortMonths09": "ก.ย.",
    "shortMonths10": "ต.ค.",
    "shortMonths11": "พ.ย.",
    "shortMonths12": "ธ.ค.",
    "months01": "มกราคม",
    "months02": "กุมภาพันธ์",
    "months03": "มีนาคม",
    "months04": "เมษายน",
    "months05": "พฤษภาคม",
    "months06": "มิถุนายน",
    "months07": "กรกฎาคม",
    "months08": "สิงหาคม",
    "months09": "กันยายน",
    "months10": "ตุลาคม",
    "months11": "พฤศจิกายน",
    "months12": "ธันวาคม",
    "goToToday": "ไปวันที่ปัจจุบัน",
    "dropdownErrMsg": "กรุณาเลือก",
    "getErrorMessage": "กรุณากรอกข้อมูล",
    "getPostCodeErrorMessage1": "กรุณาระบุตัวเลขเท่านั้น",
    "getPostCodeErrorMessage2": "กรุณาระบุเลขให้ครบ 5 หลัก",
    "getTaxIdErrorMessage": "กรอกเลข 13 หลัก",
    "getBranchNumberErrorMessage1": "ระบุเป็นตัวเลขเท่านั้น",
    "getBranchNumberErrorMessage2": "กรอกเลข 5 หลัก",
    "getPhoneNumberErrorMessage": "รูปแบบไม่ถูกต้อง ตัวอย่าง: 0812345678",
    "header": "สร้างเอกสาร ใบเสร็จรับเงิน/ใบกำกับภาษี",
    "documentHeader": "ใบเสร็จรับเงิน/ใบกำกับภาษี",
    "documentSubHeader": "Receipt/Tax Invoice",
    "original": "ต้นฉบับ",
    "companyTel": "โทร",
    "companyEmail": "อีเมล",
    "companyTaxId": "เลขประจำตัวผู้เสียภาษี",
    "customerName": "ชื่อลูกค้า",
    "pleaseSelect": "กรุณาเลือก",
    "country": "ประเทศ",
    "addressStructure": "โครงสร้างที่อยู่",
    "on": "เปิด",
    "off": "ปิด",
    "lineOne": "ที่อยู่ บรรทัดที่ 1",
    "lineTwo": "ที่อยู่ บรรทัดที่ 2",
    "buildingNumber": "อาคารเลขที่",
    "address": "ที่อยู่",
    "streetName": "ถนน",
    "province": "จังหวัด",
    "district": "อำเภอ",
    "subDistrict": "ตำบล",
    "postcode": "รหัสไปรษณีย์",
    "english": "ภาษาอังกฤษ",
    "taxNumberType": "ประเภทผู้เสียภาษี",
    "txid": "เลขประจำตัวผู้เสียภาษี",
    "nidn": "เลขประจำตัวประชาชน",
    "ccpt": "เลขที่หนังสือเดินทาง",
    "othr": "ไม่ระบุ",
    "number": "เลขที่",
    "branchDisplay": "สำนักงานใหญ่/สาขา",
    "branchNumberDesc": "เช่น 00001",
    "tel": "โทร",
    "email": "อีเมล",
    "priceIncludeVat": "ราคารวมภาษีมูลค่าเพิ่ม",
    "date": "วันที่",
    "pleaseSelectDate": "กรุณาเลือกวันที่",
    "reference": "อ้างอิง",
    "itemNumber": "ลำดับ",
    "item": "รายการ",
    "quantity": "จำนวน",
    "unit": "หน่วย",
    "unitPrice": "ราคา/หน่วย",
    "discount": "ส่วนลด",
    "itemVat": "ภาษี (%)",
    "totalIncludeVat": "มูลค่ารวม VAT(บาท)",
    "totalBeforeVat": "มูลค่าก่อนภาษี (บาท)",
    "totalErrorMessage1": "มูลค่าไม่สามารถติดลบได้",
    "delete": "ลบ",
    "addItem": "เพิ่มรายการ",
    "note": "หมายเหตุ",
    "receivedBy": "ผู้รับเงิน",
    "createdBy": "ผู้จัดทำ",
    "reIssueMessage1": "เป็นการยกเลิกและออกใบกำกับภาษีใหม่แทนใบกำกับภาษีเดิม",
    "amountTotalBeforeVat": "รวมเงิน (ก่อน Vat)",
    "amountTotal": "รวมเป็นเงิน",
    "thb": "บาท",
    "vat": "ภาษีมูลค่าเพิ่ม",
    "grandTotal": "จำนวนเงินทั้งสิ้น",
    "referNumber": "เลขที่ใบกำกับภาษีเดิม",
    "referDate": "วันที่ใบกำกับภาษีเดิม",
    "reason": "สาเหตุการยกเลิกใบกำกับภาษีเดิม",
    "specificReason": "ระบุสาเหตุ",
    "nonVatPriceTotal": "มูลค่ารายการยกเว้นภาษี",
    "zeroVatPriceTotal": "มูลค่ารายการภาษี 0%",
    "vatPriceTotal": "มูลค่ารายการภาษี 7%",
    "next": "ต่อไป",
    "cancel": "ยกเลิก",
    "loading": "กำลังโหลด...",
    "isRequiredErrorMessage": "กรุณาเลือกวันที่",
    "getErrorMessageExceedMaxLength": "ความยาวห้ามเกิน ",
    "getErrorMessageExceedMaxLength2": " ตัว",
    "getErrorMessageNoComma": "เลขที่ห้ามระบุ ,",
    "getErrorMessageNoEmptySpace": "เลขที่ห้ามมีช่องว่าง",
    "pendingExpiredCertificateMessage": "*** สำคัญ *** ใบรับรองอิเล็กทรอนิกส์ของท่านใกล้หมดอายุแล้ว",
    "pendingExpiredCertificateMessage2": " กรุณาทำการขอใบรับรองอิเล็กทรอนิกส์ใหม่",
    "expiredCertificateMessage": "*** สำคัญ *** ใบรับรองอิเล็กทรอนิกส์ของท่านหมดอายุแล้ว",
    "expiredCertificateMessage2": " กรุณาทำการขอใบรับรองอิเล็กทรอนิกส์ใหม่",
    "requestCertificate": "ขอใบรับรองฯใหม่"
  },
  "receiptTaxInvoiceConfirm": {
    "confirm": "ยืนยัน",
    "edit": "แก้ไข",
    "processing": "กำลังดำเนินการ",
    "processingMessage": "สร้างตัวอย่างเอกสาร ใบเสร็จรับเงิน/ใบกำกับภาษี",
    "dialogConfirmHeader": "ระบบพบว่ามีเลขที่เอกสารซ้ำกัน",
    "dialogConfirmDescription": "ท่านยืนยันที่จะดำเนินการต่อหรือไม่",
    "cancel": "ยกเลิก",
  },
  "receiptTaxInvoiceAbb": {
    "shortMonths01": "ม.ค.",
    "shortMonths02": "ก.พ.",
    "shortMonths03": "มี.ค.",
    "shortMonths04": "เม.ย.",
    "shortMonths05": "พ.ค.",
    "shortMonths06": "มิ.ย.",
    "shortMonths07": "ก.ค.",
    "shortMonths08": "ส.ค.",
    "shortMonths09": "ก.ย.",
    "shortMonths10": "ต.ค.",
    "shortMonths11": "พ.ย.",
    "shortMonths12": "ธ.ค.",
    "months01": "มกราคม",
    "months02": "กุมภาพันธ์",
    "months03": "มีนาคม",
    "months04": "เมษายน",
    "months05": "พฤษภาคม",
    "months06": "มิถุนายน",
    "months07": "กรกฎาคม",
    "months08": "สิงหาคม",
    "months09": "กันยายน",
    "months10": "ตุลาคม",
    "months11": "พฤศจิกายน",
    "months12": "ธันวาคม",
    "goToToday": "ไปวันที่ปัจจุบัน",
    "dropdownErrMsg": "กรุณาเลือก",
    "getErrorMessage": "กรุณากรอกข้อมูล",
    "getPostCodeErrorMessage1": "กรุณาระบุตัวเลขเท่านั้น",
    "getPostCodeErrorMessage2": "กรุณาระบุเลขให้ครบ 5 หลัก",
    "getTaxIdErrorMessage": "กรอกเลข 13 หลัก",
    "getBranchNumberErrorMessage1": "ระบุเป็นตัวเลขเท่านั้น",
    "getBranchNumberErrorMessage2": "กรอกเลข 5 หลัก",
    "getPhoneNumberErrorMessage": "รูปแบบไม่ถูกต้อง ตัวอย่าง: 0812345678",
    "header": "สร้างเอกสาร ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ",
    "documentHeader": "ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ",
    "documentSubHeader": "Receipt/Abbreviated Tax Invoice",
    "original": "ต้นฉบับ",
    "companyTel": "โทร",
    "companyEmail": "อีเมล",
    "companyTaxId": "เลขประจำตัวผู้เสียภาษี",
    "customerName": "ชื่อลูกค้า",
    "pleaseSelect": "กรุณาเลือก",
    "country": "ประเทศ",
    "addressStructure": "โครงสร้างที่อยู่",
    "on": "เปิด",
    "off": "ปิด",
    "lineOne": "ที่อยู่ บรรทัดที่ 1",
    "lineTwo": "ที่อยู่ บรรทัดที่ 2",
    "buildingNumber": "อาคารเลขที่",
    "address": "ที่อยู่",
    "streetName": "ถนน",
    "province": "จังหวัด",
    "district": "อำเภอ",
    "subDistrict": "ตำบล",
    "postcode": "รหัสไปรษณีย์",
    "english": "ภาษาอังกฤษ",
    "taxNumberType": "ประเภทผู้เสียภาษี",
    "txid": "เลขประจำตัวผู้เสียภาษี",
    "nidn": "เลขประจำตัวประชาชน",
    "ccpt": "เลขที่หนังสือเดินทาง",
    "othr": "ไม่ระบุ",
    "number": "เลขที่",
    "branchDisplay": "สำนักงานใหญ่/สาขา",
    "branchNumberDesc": "เช่น 00001",
    "tel": "โทร",
    "email": "อีเมล",
    "priceIncludeVat": "ราคารวมภาษีมูลค่าเพิ่ม",
    "date": "วันที่",
    "pleaseSelectDate": "กรุณาเลือกวันที่",
    "reference": "อ้างอิง",
    "itemNumber": "ลำดับ",
    "item": "รายการ",
    "quantity": "จำนวน",
    "unit": "หน่วย",
    "unitPrice": "ราคา/หน่วย",
    "discount": "ส่วนลด",
    "itemVat": "ภาษี (%)",
    "totalIncludeVat": "มูลค่ารวม VAT(บาท)",
    "totalBeforeVat": "มูลค่าก่อนภาษี (บาท)",
    "totalErrorMessage1": "มูลค่าไม่สามารถติดลบได้",
    "delete": "ลบ",
    "addItem": "เพิ่มรายการ",
    "note": "หมายเหตุ",
    "receivedBy": "ผู้รับเงิน",
    "createdBy": "ผู้จัดทำ",
    "reIssueMessage1": "เป็นการยกเลิกและออกใบกำกับภาษีใหม่แทนใบกำกับภาษีเดิม",
    "amountTotalBeforeVat": "รวมเงิน (ก่อน Vat)",
    "amountTotal": "รวมเป็นเงิน",
    "thb": "บาท",
    "vat": "ภาษีมูลค่าเพิ่ม",
    "grandTotal": "จำนวนเงินทั้งสิ้น",
    "referNumber": "เลขที่ใบกำกับภาษีเดิม",
    "referDate": "วันที่ใบกำกับภาษีเดิม",
    "reason": "สาเหตุการยกเลิกใบกำกับภาษีเดิม",
    "specificReason": "ระบุสาเหตุ",
    "nonVatPriceTotal": "มูลค่ารายการยกเว้นภาษี",
    "zeroVatPriceTotal": "มูลค่ารายการภาษี 0%",
    "vatPriceTotal": "มูลค่ารายการภาษี 7%",
    "next": "ต่อไป",
    "cancel": "ยกเลิก",
    "loading": "กำลังโหลด...",
    "isRequiredErrorMessage": "กรุณาเลือกวันที่",
    "reason1": "ชื่อผิด",
    "reason2": "ที่อยู่ผิด",
    "reason3": "เหตุอื่น (ระบุสาเหตุ)",
    "getErrorMessageExceedMaxLength": "ความยาวห้ามเกิน ",
    "getErrorMessageExceedMaxLength2": " ตัว",
    "getErrorMessageNoComma": "เลขที่ห้ามระบุ ,",
    "getErrorMessageNoEmptySpace": "เลขที่ห้ามมีช่องว่าง",
    "pendingExpiredCertificateMessage": "*** สำคัญ *** ใบรับรองอิเล็กทรอนิกส์ของท่านใกล้หมดอายุแล้ว",
    "pendingExpiredCertificateMessage2": " กรุณาทำการขอใบรับรองอิเล็กทรอนิกส์ใหม่",
    "expiredCertificateMessage": "*** สำคัญ *** ใบรับรองอิเล็กทรอนิกส์ของท่านหมดอายุแล้ว",
    "expiredCertificateMessage2": " กรุณาทำการขอใบรับรองอิเล็กทรอนิกส์ใหม่",
    "requestCertificate": "ขอใบรับรองฯใหม่"
  },
  "receiptTaxInvoiceAbbConfirm": {
    "confirm": "ยืนยัน",
    "edit": "แก้ไข",
    "processing": "กำลังดำเนินการ",
    "processingMessage": "สร้างตัวอย่างเอกสาร ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ",
    "dialogConfirmHeader": "ระบบพบว่ามีเลขที่เอกสารซ้ำกัน",
    "dialogConfirmDescription": "ท่านยืนยันที่จะดำเนินการต่อหรือไม่",
    "cancel": "ยกเลิก",
  },
  "taxInvoice": {
    "shortMonths01": "ม.ค.",
    "shortMonths02": "ก.พ.",
    "shortMonths03": "มี.ค.",
    "shortMonths04": "เม.ย.",
    "shortMonths05": "พ.ค.",
    "shortMonths06": "มิ.ย.",
    "shortMonths07": "ก.ค.",
    "shortMonths08": "ส.ค.",
    "shortMonths09": "ก.ย.",
    "shortMonths10": "ต.ค.",
    "shortMonths11": "พ.ย.",
    "shortMonths12": "ธ.ค.",
    "months01": "มกราคม",
    "months02": "กุมภาพันธ์",
    "months03": "มีนาคม",
    "months04": "เมษายน",
    "months05": "พฤษภาคม",
    "months06": "มิถุนายน",
    "months07": "กรกฎาคม",
    "months08": "สิงหาคม",
    "months09": "กันยายน",
    "months10": "ตุลาคม",
    "months11": "พฤศจิกายน",
    "months12": "ธันวาคม",
    "goToToday": "ไปวันที่ปัจจุบัน",
    "dropdownErrMsg": "กรุณาเลือก",
    "getErrorMessage": "กรุณากรอกข้อมูล",
    "getPostCodeErrorMessage1": "กรุณาระบุตัวเลขเท่านั้น",
    "getPostCodeErrorMessage2": "กรุณาระบุเลขให้ครบ 5 หลัก",
    "getTaxIdErrorMessage": "กรอกเลข 13 หลัก",
    "getBranchNumberErrorMessage1": "ระบุเป็นตัวเลขเท่านั้น",
    "getBranchNumberErrorMessage2": "กรอกเลข 5 หลัก",
    "getPhoneNumberErrorMessage": "รูปแบบไม่ถูกต้อง ตัวอย่าง: 0812345678",
    "header": "สร้างเอกสาร ใบกำกับภาษี",
    "documentHeader": "ใบกำกับภาษี",
    "documentSubHeader": "Tax Invoice",
    "original": "ต้นฉบับ",
    "companyTel": "โทร",
    "companyEmail": "อีเมล",
    "companyTaxId": "เลขประจำตัวผู้เสียภาษี",
    "customerName": "ชื่อลูกค้า",
    "pleaseSelect": "กรุณาเลือก",
    "country": "ประเทศ",
    "addressStructure": "โครงสร้างที่อยู่",
    "on": "เปิด",
    "off": "ปิด",
    "lineOne": "ที่อยู่ บรรทัดที่ 1",
    "lineTwo": "ที่อยู่ บรรทัดที่ 2",
    "buildingNumber": "อาคารเลขที่",
    "address": "ที่อยู่",
    "streetName": "ถนน",
    "province": "จังหวัด",
    "district": "อำเภอ",
    "subDistrict": "ตำบล",
    "postcode": "รหัสไปรษณีย์",
    "english": "ภาษาอังกฤษ",
    "taxNumberType": "ประเภทผู้เสียภาษี",
    "txid": "เลขประจำตัวผู้เสียภาษี",
    "nidn": "เลขประจำตัวประชาชน",
    "ccpt": "เลขที่หนังสือเดินทาง",
    "othr": "ไม่ระบุ",
    "number": "เลขที่",
    "branchDisplay": "สำนักงานใหญ่/สาขา",
    "branchNumberDesc": "เช่น 00001",
    "tel": "โทร",
    "email": "อีเมล",
    "priceIncludeVat": "ราคารวมภาษีมูลค่าเพิ่ม",
    "date": "วันที่",
    "pleaseSelectDate": "กรุณาเลือกวันที่",
    "reference": "อ้างอิง",
    "itemNumber": "ลำดับ",
    "item": "รายการ",
    "quantity": "จำนวน",
    "unit": "หน่วย",
    "unitPrice": "ราคา/หน่วย",
    "discount": "ส่วนลด",
    "itemVat": "ภาษี (%)",
    "totalIncludeVat": "มูลค่ารวม VAT(บาท)",
    "totalBeforeVat": "มูลค่าก่อนภาษี (บาท)",
    "totalErrorMessage1": "มูลค่าไม่สามารถติดลบได้",
    "delete": "ลบ",
    "addItem": "เพิ่มรายการ",
    "note": "หมายเหตุ",
    "receivedBy": "ผู้รับเงิน",
    "createdBy": "ผู้จัดทำ",
    "reIssueMessage1": "เป็นการยกเลิกและออกใบกำกับภาษีใหม่แทนใบกำกับภาษีเดิม",
    "amountTotalBeforeVat": "รวมเงิน (ก่อน Vat)",
    "amountTotal": "รวมเป็นเงิน",
    "thb": "บาท",
    "vat": "ภาษีมูลค่าเพิ่ม",
    "grandTotal": "จำนวนเงินทั้งสิ้น",
    "referNumber": "เลขที่ใบกำกับภาษีเดิม",
    "referDate": "วันที่ใบกำกับภาษีเดิม",
    "reason": "สาเหตุการยกเลิกใบกำกับภาษีเดิม",
    "specificReason": "ระบุสาเหตุ",
    "nonVatPriceTotal": "มูลค่ารายการยกเว้นภาษี",
    "zeroVatPriceTotal": "มูลค่ารายการภาษี 0%",
    "vatPriceTotal": "มูลค่ารายการภาษี 7%",
    "next": "ต่อไป",
    "cancel": "ยกเลิก",
    "loading": "กำลังโหลด...",
    "isRequiredErrorMessage": "กรุณาเลือกวันที่",
    "getErrorMessageExceedMaxLength": "ความยาวห้ามเกิน ",
    "getErrorMessageExceedMaxLength2": " ตัว",
    "getErrorMessageNoComma": "เลขที่ห้ามระบุ ,",
    "getErrorMessageNoEmptySpace": "เลขที่ห้ามมีช่องว่าง",
    "pendingExpiredCertificateMessage": "*** สำคัญ *** ใบรับรองอิเล็กทรอนิกส์ของท่านใกล้หมดอายุแล้ว",
    "pendingExpiredCertificateMessage2": " กรุณาทำการขอใบรับรองอิเล็กทรอนิกส์ใหม่",
    "expiredCertificateMessage": "*** สำคัญ *** ใบรับรองอิเล็กทรอนิกส์ของท่านหมดอายุแล้ว",
    "expiredCertificateMessage2": " กรุณาทำการขอใบรับรองอิเล็กทรอนิกส์ใหม่",
    "requestCertificate": "ขอใบรับรองฯใหม่"
  },
  "taxInvoiceConfirm": {
    "confirm": "ยืนยัน",
    "edit": "แก้ไข",
    "processing": "กำลังดำเนินการ",
    "processingMessage": "สร้างตัวอย่างเอกสาร ใบกำกับภาษี",
    "dialogConfirmHeader": "ระบบพบว่ามีเลขที่เอกสารซ้ำกัน",
    "dialogConfirmDescription": "ท่านยืนยันที่จะดำเนินการต่อหรือไม่",
    "cancel": "ยกเลิก",
  },
  "receipt": {
    "shortMonths01": "ม.ค.",
    "shortMonths02": "ก.พ.",
    "shortMonths03": "มี.ค.",
    "shortMonths04": "เม.ย.",
    "shortMonths05": "พ.ค.",
    "shortMonths06": "มิ.ย.",
    "shortMonths07": "ก.ค.",
    "shortMonths08": "ส.ค.",
    "shortMonths09": "ก.ย.",
    "shortMonths10": "ต.ค.",
    "shortMonths11": "พ.ย.",
    "shortMonths12": "ธ.ค.",
    "months01": "มกราคม",
    "months02": "กุมภาพันธ์",
    "months03": "มีนาคม",
    "months04": "เมษายน",
    "months05": "พฤษภาคม",
    "months06": "มิถุนายน",
    "months07": "กรกฎาคม",
    "months08": "สิงหาคม",
    "months09": "กันยายน",
    "months10": "ตุลาคม",
    "months11": "พฤศจิกายน",
    "months12": "ธันวาคม",
    "goToToday": "ไปวันที่ปัจจุบัน",
    "dropdownErrMsg": "กรุณาเลือก",
    "getErrorMessage": "กรุณากรอกข้อมูล",
    "getPostCodeErrorMessage1": "กรุณาระบุตัวเลขเท่านั้น",
    "getPostCodeErrorMessage2": "กรุณาระบุเลขให้ครบ 5 หลัก",
    "getTaxIdErrorMessage": "กรอกเลข 13 หลัก",
    "getBranchNumberErrorMessage1": "ระบุเป็นตัวเลขเท่านั้น",
    "getBranchNumberErrorMessage2": "กรอกเลข 5 หลัก",
    "getPhoneNumberErrorMessage": "รูปแบบไม่ถูกต้อง ตัวอย่าง: 0812345678",
    "header": "สร้างเอกสาร ใบเสร็จรับเงิน",
    "documentHeader": "ใบเสร็จรับเงิน",
    "documentSubHeader": "Receipt",
    "original": "ต้นฉบับ",
    "companyTel": "โทร",
    "companyEmail": "อีเมล",
    "companyTaxId": "เลขประจำตัวผู้เสียภาษี",
    "customerName": "ชื่อลูกค้า",
    "pleaseSelect": "กรุณาเลือก",
    "country": "ประเทศ",
    "addressStructure": "โครงสร้างที่อยู่",
    "on": "เปิด",
    "off": "ปิด",
    "lineOne": "ที่อยู่ บรรทัดที่ 1",
    "lineTwo": "ที่อยู่ บรรทัดที่ 2",
    "buildingNumber": "อาคารเลขที่",
    "address": "ที่อยู่",
    "streetName": "ถนน",
    "province": "จังหวัด",
    "district": "อำเภอ",
    "subDistrict": "ตำบล",
    "postcode": "รหัสไปรษณีย์",
    "english": "ภาษาอังกฤษ",
    "taxNumberType": "ประเภทผู้เสียภาษี",
    "txid": "เลขประจำตัวผู้เสียภาษี",
    "nidn": "เลขประจำตัวประชาชน",
    "ccpt": "เลขที่หนังสือเดินทาง",
    "othr": "ไม่ระบุ",
    "number": "เลขที่",
    "branchDisplay": "สำนักงานใหญ่/สาขา",
    "branchNumberDesc": "เช่น 00001",
    "tel": "โทร",
    "email": "อีเมล",
    "priceIncludeVat": "ราคารวมภาษีมูลค่าเพิ่ม",
    "date": "วันที่",
    "pleaseSelectDate": "กรุณาเลือกวันที่",
    "reference": "อ้างอิง",
    "itemNumber": "ลำดับ",
    "item": "รายการ",
    "quantity": "จำนวน",
    "unit": "หน่วย",
    "unitPrice": "ราคา/หน่วย",
    "discount": "ส่วนลด",
    "itemVat": "ภาษี (%)",
    "totalIncludeVat": "มูลค่ารวม VAT(บาท)",
    "totalBeforeVat": "มูลค่าก่อนภาษี (บาท)",
    "totalErrorMessage1": "มูลค่าไม่สามารถติดลบได้",
    "delete": "ลบ",
    "addItem": "เพิ่มรายการ",
    "note": "หมายเหตุ",
    "receivedBy": "ผู้รับเงิน",
    "createdBy": "ผู้จัดทำ",
    "reIssueMessage1": "เป็นการยกเลิกและออกใบกำกับภาษีใหม่แทนใบกำกับภาษีเดิม",
    "amountTotalBeforeVat": "รวมเงิน (ก่อน Vat)",
    "amountTotal": "รวมเป็นเงิน",
    "thb": "บาท",
    "vat": "ภาษีมูลค่าเพิ่ม",
    "grandTotal": "จำนวนเงินทั้งสิ้น",
    "referNumber": "เลขที่ใบกำกับภาษีเดิม",
    "referDate": "วันที่ใบกำกับภาษีเดิม",
    "reason": "สาเหตุการยกเลิกใบกำกับภาษีเดิม",
    "specificReason": "ระบุสาเหตุ",
    "nonVatPriceTotal": "มูลค่ารายการยกเว้นภาษี",
    "zeroVatPriceTotal": "มูลค่ารายการภาษี 0%",
    "vatPriceTotal": "มูลค่ารายการภาษี 7%",
    "next": "ต่อไป",
    "cancel": "ยกเลิก",
    "loading": "กำลังโหลด...",
    "isRequiredErrorMessage": "กรุณาเลือกวันที่",
    "total": "มูลค่า (บาท)",
    "specificAmount": "ระบุจำนวนเงิน (บาท)",
    "getErrorMessageExceedMaxLength": "ความยาวห้ามเกิน ",
    "getErrorMessageExceedMaxLength2": " ตัว",
    "getErrorMessageNoComma": "เลขที่ห้ามระบุ ,",
    "getErrorMessageNoEmptySpace": "เลขที่ห้ามมีช่องว่าง",
    "pendingExpiredCertificateMessage": "*** สำคัญ *** ใบรับรองอิเล็กทรอนิกส์ของท่านใกล้หมดอายุแล้ว",
    "pendingExpiredCertificateMessage2": " กรุณาทำการขอใบรับรองอิเล็กทรอนิกส์ใหม่",
    "expiredCertificateMessage": "*** สำคัญ *** ใบรับรองอิเล็กทรอนิกส์ของท่านหมดอายุแล้ว",
    "expiredCertificateMessage2": " กรุณาทำการขอใบรับรองอิเล็กทรอนิกส์ใหม่",
    "requestCertificate": "ขอใบรับรองฯใหม่"
  },
  "receiptConfirm": {
    "confirm": "ยืนยัน",
    "edit": "แก้ไข",
    "processing": "กำลังดำเนินการ",
    "processingMessage": "สร้างตัวอย่างเอกสาร ใบเสร็จรับเงิน",
    "dialogConfirmHeader": "ระบบพบว่ามีเลขที่เอกสารซ้ำกัน",
    "dialogConfirmDescription": "ท่านยืนยันที่จะดำเนินการต่อหรือไม่",
    "cancel": "ยกเลิก",
  },
  "batchReceipt": {
    "shortMonths01": "ม.ค.",
    "shortMonths02": "ก.พ.",
    "shortMonths03": "มี.ค.",
    "shortMonths04": "เม.ย.",
    "shortMonths05": "พ.ค.",
    "shortMonths06": "มิ.ย.",
    "shortMonths07": "ก.ค.",
    "shortMonths08": "ส.ค.",
    "shortMonths09": "ก.ย.",
    "shortMonths10": "ต.ค.",
    "shortMonths11": "พ.ย.",
    "shortMonths12": "ธ.ค.",
    "months01": "มกราคม",
    "months02": "กุมภาพันธ์",
    "months03": "มีนาคม",
    "months04": "เมษายน",
    "months05": "พฤษภาคม",
    "months06": "มิถุนายน",
    "months07": "กรกฎาคม",
    "months08": "สิงหาคม",
    "months09": "กันยายน",
    "months10": "ตุลาคม",
    "months11": "พฤศจิกายน",
    "months12": "ธันวาคม",
    "goToToday": "ไปวันที่ปัจจุบัน",
    "dropdownErrMsg": "กรุณาเลือก",
    "getErrorMessage": "กรุณากรอกข้อมูล",
    "getPostCodeErrorMessage1": "กรุณาระบุตัวเลขเท่านั้น",
    "getPostCodeErrorMessage2": "กรุณาระบุเลขให้ครบ 5 หลัก",
    "getTaxIdErrorMessage": "กรอกเลข 13 หลัก",
    "getBranchNumberErrorMessage1": "ระบุเป็นตัวเลขเท่านั้น",
    "getBranchNumberErrorMessage2": "กรอกเลข 5 หลัก",
    "getPhoneNumberErrorMessage": "รูปแบบไม่ถูกต้อง ตัวอย่าง: 0812345678",
    "header": "สร้างเอกสาร ใบเสร็จรับเงิน",
    "documentHeader": "ใบเสร็จรับเงิน",
    "documentSubHeader": "Receipt",
    "original": "ต้นฉบับ",
    "companyTel": "โทร",
    "companyEmail": "อีเมล",
    "companyTaxId": "เลขประจำตัวผู้เสียภาษี",
    "customerName": "ชื่อลูกค้า",
    "pleaseSelect": "กรุณาเลือก",
    "country": "ประเทศ",
    "addressStructure": "โครงสร้างที่อยู่",
    "on": "เปิด",
    "off": "ปิด",
    "lineOne": "ที่อยู่ บรรทัดที่ 1",
    "lineTwo": "ที่อยู่ บรรทัดที่ 2",
    "buildingNumber": "อาคารเลขที่",
    "address": "ที่อยู่",
    "streetName": "ถนน",
    "province": "จังหวัด",
    "district": "อำเภอ",
    "subDistrict": "ตำบล",
    "postcode": "รหัสไปรษณีย์",
    "english": "ภาษาอังกฤษ",
    "taxNumberType": "ประเภทผู้เสียภาษี",
    "txid": "เลขประจำตัวผู้เสียภาษี",
    "nidn": "เลขประจำตัวประชาชน",
    "ccpt": "เลขที่หนังสือเดินทาง",
    "othr": "ไม่ระบุ",
    "number": "เลขที่",
    "branchDisplay": "สำนักงานใหญ่/สาขา",
    "branchNumberDesc": "เช่น 00001",
    "tel": "โทร",
    "email": "อีเมล",
    "priceIncludeVat": "ราคารวมภาษีมูลค่าเพิ่ม",
    "date": "วันที่",
    "pleaseSelectDate": "กรุณาเลือกวันที่",
    "reference": "อ้างอิง",
    "itemNumber": "ลำดับ",
    "item": "รายการ",
    "quantity": "จำนวน",
    "unit": "หน่วย",
    "unitPrice": "ราคา/หน่วย",
    "discount": "ส่วนลด",
    "itemVat": "ภาษี (%)",
    "totalIncludeVat": "มูลค่ารวม VAT(บาท)",
    "totalBeforeVat": "มูลค่าก่อนภาษี (บาท)",
    "totalErrorMessage1": "มูลค่าไม่สามารถติดลบได้",
    "delete": "ลบ",
    "addItem": "เพิ่มรายการ",
    "note": "หมายเหตุ",
    "receivedBy": "ผู้รับเงิน",
    "createdBy": "ผู้จัดทำ",
    "reIssueMessage1": "เป็นการยกเลิกและออกใบกำกับภาษีใหม่แทนใบกำกับภาษีเดิม",
    "amountTotalBeforeVat": "รวมเงิน (ก่อน Vat)",
    "amountTotal": "รวมเป็นเงิน",
    "thb": "บาท",
    "vat": "ภาษีมูลค่าเพิ่ม",
    "grandTotal": "จำนวนเงินทั้งสิ้น",
    "referNumber": "เลขที่ใบกำกับภาษีเดิม",
    "referDate": "วันที่ใบกำกับภาษีเดิม",
    "reason": "สาเหตุการยกเลิกใบกำกับภาษีเดิม",
    "specificReason": "ระบุสาเหตุ",
    "nonVatPriceTotal": "มูลค่ารายการยกเว้นภาษี",
    "zeroVatPriceTotal": "มูลค่ารายการภาษี 0%",
    "vatPriceTotal": "มูลค่ารายการภาษี 7%",
    "next": "ต่อไป",
    "cancel": "ยกเลิก",
    "loading": "กำลังโหลด...",
    "isRequiredErrorMessage": "กรุณาเลือกวันที่",
    "total": "มูลค่า (บาท)",
    "specificAmount": "ระบุจำนวนเงิน (บาท)",
    "invoiceNumber": "เลขที่",
    "invoiceDate": "วันที่",
    "invoiceVat": "ภาษี (%)",
    "invoiceAmountTotal": "รวม (บาท)",
    "invoiceVAT": "VAT (บาท)",
    "invoiceGrandTotal": "รวมภาษี (บาท)",
  },
  "batchReceiptConfirm": {
    "confirm": "ยืนยัน",
    "edit": "แก้ไข",
    "processing": "กำลังดำเนินการ",
    "processingMessage": "สร้างตัวอย่างเอกสาร ใบเสร็จรับเงิน",
  },
  "invoiceTaxInvoice": {
    "shortMonths01": "ม.ค.",
    "shortMonths02": "ก.พ.",
    "shortMonths03": "มี.ค.",
    "shortMonths04": "เม.ย.",
    "shortMonths05": "พ.ค.",
    "shortMonths06": "มิ.ย.",
    "shortMonths07": "ก.ค.",
    "shortMonths08": "ส.ค.",
    "shortMonths09": "ก.ย.",
    "shortMonths10": "ต.ค.",
    "shortMonths11": "พ.ย.",
    "shortMonths12": "ธ.ค.",
    "months01": "มกราคม",
    "months02": "กุมภาพันธ์",
    "months03": "มีนาคม",
    "months04": "เมษายน",
    "months05": "พฤษภาคม",
    "months06": "มิถุนายน",
    "months07": "กรกฎาคม",
    "months08": "สิงหาคม",
    "months09": "กันยายน",
    "months10": "ตุลาคม",
    "months11": "พฤศจิกายน",
    "months12": "ธันวาคม",
    "goToToday": "ไปวันที่ปัจจุบัน",
    "dropdownErrMsg": "กรุณาเลือก",
    "getErrorMessage": "กรุณากรอกข้อมูล",
    "getPostCodeErrorMessage1": "กรุณาระบุตัวเลขเท่านั้น",
    "getPostCodeErrorMessage2": "กรุณาระบุเลขให้ครบ 5 หลัก",
    "getTaxIdErrorMessage": "กรอกเลข 13 หลัก",
    "getBranchNumberErrorMessage1": "ระบุเป็นตัวเลขเท่านั้น",
    "getBranchNumberErrorMessage2": "กรอกเลข 5 หลัก",
    "getPhoneNumberErrorMessage": "รูปแบบไม่ถูกต้อง ตัวอย่าง: 0812345678",
    "header": "สร้างเอกสาร ใบแจ้งหนี้/ใบกำกับภาษี",
    "documentHeader": "ใบแจ้งหนี้/ใบกำกับภาษี",
    "documentSubHeader": "Invoice/Tax Invoice",
    "original": "ต้นฉบับ",
    "companyTel": "โทร",
    "companyEmail": "อีเมล",
    "companyTaxId": "เลขประจำตัวผู้เสียภาษี",
    "customerName": "ชื่อลูกค้า",
    "pleaseSelect": "กรุณาเลือก",
    "country": "ประเทศ",
    "addressStructure": "โครงสร้างที่อยู่",
    "on": "เปิด",
    "off": "ปิด",
    "lineOne": "ที่อยู่ บรรทัดที่ 1",
    "lineTwo": "ที่อยู่ บรรทัดที่ 2",
    "buildingNumber": "อาคารเลขที่",
    "address": "ที่อยู่",
    "streetName": "ถนน",
    "province": "จังหวัด",
    "district": "อำเภอ",
    "subDistrict": "ตำบล",
    "postcode": "รหัสไปรษณีย์",
    "english": "ภาษาอังกฤษ",
    "taxNumberType": "ประเภทผู้เสียภาษี",
    "txid": "เลขประจำตัวผู้เสียภาษี",
    "nidn": "เลขประจำตัวประชาชน",
    "ccpt": "เลขที่หนังสือเดินทาง",
    "othr": "ไม่ระบุ",
    "number": "เลขที่",
    "branchDisplay": "สำนักงานใหญ่/สาขา",
    "branchNumberDesc": "เช่น 00001",
    "tel": "โทร",
    "email": "อีเมล",
    "priceIncludeVat": "ราคารวมภาษีมูลค่าเพิ่ม",
    "date": "วันที่",
    "pleaseSelectDate": "กรุณาเลือกวันที่",
    "reference": "อ้างอิง",
    "itemNumber": "ลำดับ",
    "item": "รายการ",
    "quantity": "จำนวน",
    "unit": "หน่วย",
    "unitPrice": "ราคา/หน่วย",
    "discount": "ส่วนลด",
    "itemVat": "ภาษี (%)",
    "totalIncludeVat": "มูลค่ารวม VAT(บาท)",
    "totalBeforeVat": "มูลค่าก่อนภาษี (บาท)",
    "totalErrorMessage1": "มูลค่าไม่สามารถติดลบได้",
    "delete": "ลบ",
    "addItem": "เพิ่มรายการ",
    "note": "หมายเหตุ",
    "receivedBy": "ผู้รับเงิน",
    "createdBy": "ผู้จัดทำ",
    "reIssueMessage1": "เป็นการยกเลิกและออกใบกำกับภาษีใหม่แทนใบกำกับภาษีเดิม",
    "amountTotalBeforeVat": "รวมเงิน (ก่อน Vat)",
    "amountTotal": "รวมเป็นเงิน",
    "thb": "บาท",
    "vat": "ภาษีมูลค่าเพิ่ม",
    "grandTotal": "จำนวนเงินทั้งสิ้น",
    "referNumber": "เลขที่ใบกำกับภาษีเดิม",
    "referDate": "วันที่ใบกำกับภาษีเดิม",
    "reason": "สาเหตุการยกเลิกใบกำกับภาษีเดิม",
    "specificReason": "ระบุสาเหตุ",
    "nonVatPriceTotal": "มูลค่ารายการยกเว้นภาษี",
    "zeroVatPriceTotal": "มูลค่ารายการภาษี 0%",
    "vatPriceTotal": "มูลค่ารายการภาษี 7%",
    "next": "ต่อไป",
    "cancel": "ยกเลิก",
    "loading": "กำลังโหลด...",
    "isRequiredErrorMessage": "กรุณาเลือกวันที่",
    "dueDate": "ครบกำหนดชำระเงิน",
    "getErrorMessageExceedMaxLength": "ความยาวห้ามเกิน ",
    "getErrorMessageExceedMaxLength2": " ตัว",
    "getErrorMessageNoComma": "เลขที่ห้ามระบุ ,",
    "getErrorMessageNoEmptySpace": "เลขที่ห้ามมีช่องว่าง",
    "pendingExpiredCertificateMessage": "*** สำคัญ *** ใบรับรองอิเล็กทรอนิกส์ของท่านใกล้หมดอายุแล้ว",
    "pendingExpiredCertificateMessage2": " กรุณาทำการขอใบรับรองอิเล็กทรอนิกส์ใหม่",
    "expiredCertificateMessage": "*** สำคัญ *** ใบรับรองอิเล็กทรอนิกส์ของท่านหมดอายุแล้ว",
    "expiredCertificateMessage2": " กรุณาทำการขอใบรับรองอิเล็กทรอนิกส์ใหม่",
    "requestCertificate": "ขอใบรับรองฯใหม่",
    "itemWhtTotal": "เงินที่หัก",
  },
  "invoiceTaxInvoiceConfirm": {
    "confirm": "ยืนยัน",
    "edit": "แก้ไข",
    "processing": "กำลังดำเนินการ",
    "processingMessage": "สร้างตัวอย่างเอกสาร ใบแจ้งหนี้/ใบกำกับภาษี",
    "dialogConfirmHeader": "ระบบพบว่ามีเลขที่เอกสารซ้ำกัน",
    "dialogConfirmDescription": "ท่านยืนยันที่จะดำเนินการต่อหรือไม่",
    "cancel": "ยกเลิก",
  },
  "deliveryOrderTaxInvoice": {
    "shortMonths01": "ม.ค.",
    "shortMonths02": "ก.พ.",
    "shortMonths03": "มี.ค.",
    "shortMonths04": "เม.ย.",
    "shortMonths05": "พ.ค.",
    "shortMonths06": "มิ.ย.",
    "shortMonths07": "ก.ค.",
    "shortMonths08": "ส.ค.",
    "shortMonths09": "ก.ย.",
    "shortMonths10": "ต.ค.",
    "shortMonths11": "พ.ย.",
    "shortMonths12": "ธ.ค.",
    "months01": "มกราคม",
    "months02": "กุมภาพันธ์",
    "months03": "มีนาคม",
    "months04": "เมษายน",
    "months05": "พฤษภาคม",
    "months06": "มิถุนายน",
    "months07": "กรกฎาคม",
    "months08": "สิงหาคม",
    "months09": "กันยายน",
    "months10": "ตุลาคม",
    "months11": "พฤศจิกายน",
    "months12": "ธันวาคม",
    "goToToday": "ไปวันที่ปัจจุบัน",
    "dropdownErrMsg": "กรุณาเลือก",
    "getErrorMessage": "กรุณากรอกข้อมูล",
    "getPostCodeErrorMessage1": "กรุณาระบุตัวเลขเท่านั้น",
    "getPostCodeErrorMessage2": "กรุณาระบุเลขให้ครบ 5 หลัก",
    "getTaxIdErrorMessage": "กรอกเลข 13 หลัก",
    "getBranchNumberErrorMessage1": "ระบุเป็นตัวเลขเท่านั้น",
    "getBranchNumberErrorMessage2": "กรอกเลข 5 หลัก",
    "getPhoneNumberErrorMessage": "รูปแบบไม่ถูกต้อง ตัวอย่าง: 0812345678",
    "header": "สร้างเอกสาร ใบส่งของ/ใบกำกับภาษี",
    "documentHeader": "ใบส่งของ/ใบกำกับภาษี",
    "documentSubHeader": "Delivery Order/Tax Invoice",
    "original": "ต้นฉบับ",
    "companyTel": "โทร",
    "companyEmail": "อีเมล",
    "companyTaxId": "เลขประจำตัวผู้เสียภาษี",
    "customerName": "ชื่อลูกค้า",
    "pleaseSelect": "กรุณาเลือก",
    "country": "ประเทศ",
    "addressStructure": "โครงสร้างที่อยู่",
    "on": "เปิด",
    "off": "ปิด",
    "lineOne": "ที่อยู่ บรรทัดที่ 1",
    "lineTwo": "ที่อยู่ บรรทัดที่ 2",
    "buildingNumber": "อาคารเลขที่",
    "address": "ที่อยู่",
    "streetName": "ถนน",
    "province": "จังหวัด",
    "district": "อำเภอ",
    "subDistrict": "ตำบล",
    "postcode": "รหัสไปรษณีย์",
    "english": "ภาษาอังกฤษ",
    "taxNumberType": "ประเภทผู้เสียภาษี",
    "txid": "เลขประจำตัวผู้เสียภาษี",
    "nidn": "เลขประจำตัวประชาชน",
    "ccpt": "เลขที่หนังสือเดินทาง",
    "othr": "ไม่ระบุ",
    "number": "เลขที่",
    "branchDisplay": "สำนักงานใหญ่/สาขา",
    "branchNumberDesc": "เช่น 00001",
    "tel": "โทร",
    "email": "อีเมล",
    "priceIncludeVat": "ราคารวมภาษีมูลค่าเพิ่ม",
    "date": "วันที่",
    "pleaseSelectDate": "กรุณาเลือกวันที่",
    "reference": "อ้างอิง",
    "itemNumber": "ลำดับ",
    "item": "รายการ",
    "quantity": "จำนวน",
    "unit": "หน่วย",
    "unitPrice": "ราคา/หน่วย",
    "discount": "ส่วนลด",
    "itemVat": "ภาษี (%)",
    "totalIncludeVat": "มูลค่ารวม VAT(บาท)",
    "totalBeforeVat": "มูลค่าก่อนภาษี (บาท)",
    "totalErrorMessage1": "มูลค่าไม่สามารถติดลบได้",
    "delete": "ลบ",
    "addItem": "เพิ่มรายการ",
    "note": "หมายเหตุ",
    "receivedBy": "ผู้รับเงิน",
    "createdBy": "ผู้จัดทำ",
    "reIssueMessage1": "เป็นการยกเลิกและออกใบกำกับภาษีใหม่แทนใบกำกับภาษีเดิม",
    "amountTotalBeforeVat": "รวมเงิน (ก่อน Vat)",
    "amountTotal": "รวมเป็นเงิน",
    "thb": "บาท",
    "vat": "ภาษีมูลค่าเพิ่ม",
    "grandTotal": "จำนวนเงินทั้งสิ้น",
    "referNumber": "เลขที่ใบกำกับภาษีเดิม",
    "referDate": "วันที่ใบกำกับภาษีเดิม",
    "reason": "สาเหตุการยกเลิกใบกำกับภาษีเดิม",
    "specificReason": "ระบุสาเหตุ",
    "nonVatPriceTotal": "มูลค่ารายการยกเว้นภาษี",
    "zeroVatPriceTotal": "มูลค่ารายการภาษี 0%",
    "vatPriceTotal": "มูลค่ารายการภาษี 7%",
    "next": "ต่อไป",
    "cancel": "ยกเลิก",
    "loading": "กำลังโหลด...",
    "isRequiredErrorMessage": "กรุณาเลือกวันที่",
    "dueDate": "ครบกำหนดชำระเงิน",
    "getErrorMessageExceedMaxLength": "ความยาวห้ามเกิน ",
    "getErrorMessageExceedMaxLength2": " ตัว",
    "getErrorMessageNoComma": "เลขที่ห้ามระบุ ,",
    "getErrorMessageNoEmptySpace": "เลขที่ห้ามมีช่องว่าง",
    "pendingExpiredCertificateMessage": "*** สำคัญ *** ใบรับรองอิเล็กทรอนิกส์ของท่านใกล้หมดอายุแล้ว",
    "pendingExpiredCertificateMessage2": " กรุณาทำการขอใบรับรองอิเล็กทรอนิกส์ใหม่",
    "expiredCertificateMessage": "*** สำคัญ *** ใบรับรองอิเล็กทรอนิกส์ของท่านหมดอายุแล้ว",
    "expiredCertificateMessage2": " กรุณาทำการขอใบรับรองอิเล็กทรอนิกส์ใหม่",
    "requestCertificate": "ขอใบรับรองฯใหม่"
  },
  "deliveryOrderTaxInvoiceConfirm": {
    "confirm": "ยืนยัน",
    "edit": "แก้ไข",
    "processing": "กำลังดำเนินการ",
    "processingMessage": "สร้างตัวอย่างเอกสาร ใบส่งของ/ใบกำกับภาษี",
    "dialogConfirmHeader": "ระบบพบว่ามีเลขที่เอกสารซ้ำกัน",
    "dialogConfirmDescription": "ท่านยืนยันที่จะดำเนินการต่อหรือไม่",
    "cancel": "ยกเลิก",
  },
  "debitNoteTaxInvoice": {
    "shortMonths01": "ม.ค.",
    "shortMonths02": "ก.พ.",
    "shortMonths03": "มี.ค.",
    "shortMonths04": "เม.ย.",
    "shortMonths05": "พ.ค.",
    "shortMonths06": "มิ.ย.",
    "shortMonths07": "ก.ค.",
    "shortMonths08": "ส.ค.",
    "shortMonths09": "ก.ย.",
    "shortMonths10": "ต.ค.",
    "shortMonths11": "พ.ย.",
    "shortMonths12": "ธ.ค.",
    "months01": "มกราคม",
    "months02": "กุมภาพันธ์",
    "months03": "มีนาคม",
    "months04": "เมษายน",
    "months05": "พฤษภาคม",
    "months06": "มิถุนายน",
    "months07": "กรกฎาคม",
    "months08": "สิงหาคม",
    "months09": "กันยายน",
    "months10": "ตุลาคม",
    "months11": "พฤศจิกายน",
    "months12": "ธันวาคม",
    "goToToday": "ไปวันที่ปัจจุบัน",
    "dropdownErrMsg": "กรุณาเลือก",
    "getErrorMessage": "กรุณากรอกข้อมูล",
    "getPostCodeErrorMessage1": "กรุณาระบุตัวเลขเท่านั้น",
    "getPostCodeErrorMessage2": "กรุณาระบุเลขให้ครบ 5 หลัก",
    "getTaxIdErrorMessage": "กรอกเลข 13 หลัก",
    "getBranchNumberErrorMessage1": "ระบุเป็นตัวเลขเท่านั้น",
    "getBranchNumberErrorMessage2": "กรอกเลข 5 หลัก",
    "getPhoneNumberErrorMessage": "รูปแบบไม่ถูกต้อง ตัวอย่าง: 0812345678",
    "header": "สร้างเอกสาร ใบเพิ่มหนี้",
    "documentHeader": "ใบเพิ่มหนี้",
    "documentSubHeader": "Debit Note",
    "original": "ต้นฉบับ",
    "companyTel": "โทร",
    "companyEmail": "อีเมล",
    "companyTaxId": "เลขประจำตัวผู้เสียภาษี",
    "customerName": "ชื่อลูกค้า",
    "pleaseSelect": "กรุณาเลือก",
    "country": "ประเทศ",
    "addressStructure": "โครงสร้างที่อยู่",
    "on": "เปิด",
    "off": "ปิด",
    "lineOne": "ที่อยู่ บรรทัดที่ 1",
    "lineTwo": "ที่อยู่ บรรทัดที่ 2",
    "buildingNumber": "อาคารเลขที่",
    "address": "ที่อยู่",
    "streetName": "ถนน",
    "province": "จังหวัด",
    "district": "อำเภอ",
    "subDistrict": "ตำบล",
    "postcode": "รหัสไปรษณีย์",
    "english": "ภาษาอังกฤษ",
    "taxNumberType": "ประเภทผู้เสียภาษี",
    "txid": "เลขประจำตัวผู้เสียภาษี",
    "nidn": "เลขประจำตัวประชาชน",
    "ccpt": "เลขที่หนังสือเดินทาง",
    "othr": "ไม่ระบุ",
    "number": "เลขที่",
    "branchDisplay": "สำนักงานใหญ่/สาขา",
    "branchNumberDesc": "เช่น 00001",
    "tel": "โทร",
    "email": "อีเมล",
    "priceIncludeVat": "ราคารวมภาษีมูลค่าเพิ่ม",
    "date": "วันที่",
    "pleaseSelectDate": "กรุณาเลือกวันที่",
    "reference": "อ้างอิง",
    "itemNumber": "ลำดับ",
    "item": "รายการ",
    "quantity": "จำนวน",
    "unit": "หน่วย",
    "unitPrice": "ราคา/หน่วย",
    "discount": "ส่วนลด",
    "itemVat": "ภาษี (%)",
    "totalIncludeVat": "มูลค่ารวม VAT(บาท)",
    "totalBeforeVat": "มูลค่าก่อนภาษี (บาท)",
    "totalErrorMessage1": "มูลค่าไม่สามารถติดลบได้",
    "delete": "ลบ",
    "addItem": "เพิ่มรายการ",
    "note": "หมายเหตุ",
    "receivedBy": "ผู้รับเงิน",
    "createdBy": "ผู้จัดทำ",
    "reIssueMessage1": "เป็นการยกเลิกและออกใบกำกับภาษีใหม่แทนใบกำกับภาษีเดิม",
    "amountTotalBeforeVat": "รวมเงิน (ก่อน VAT)",
    "amountTotal": "รวมเป็นเงิน",
    "thb": "บาท",
    "vat": "ภาษีมูลค่าเพิ่ม",
    "grandTotal": "จำนวนเงินทั้งสิ้น",
    "specificReason": "ระบุสาเหตุ",
    "nonVatPriceTotal": "มูลค่ารายการยกเว้นภาษี",
    "zeroVatPriceTotal": "มูลค่ารายการภาษี 0%",
    "vatPriceTotal": "มูลค่ารายการภาษี 7%",
    "next": "ต่อไป",
    "cancel": "ยกเลิก",
    "loading": "กำลังโหลด...",
    "isRequiredErrorMessage": "กรุณาเลือกวันที่",
    "dueDate": "ครบกำหนดชำระเงิน",
    "calculationMethod": "การคำนวณราคา",
    "previousAmountTotal": "มูลค่าตามใบกำกับภาษีเดิม",
    "beforeVat": "(ก่อน VAT)",
    "rightAmountTotal": "มูลค่าที่ถูกต้อง",
    "referTypeCode": "ประเภทเอกสารอ้างถึง",
    "referNumber": "เลขที่เอกสารอ้างถึง",
    "referDate": "วันที่เอกสารอ้างถึง",
    "reason": "สาเหตุการออกใบเพิ่มหนี้",
    "diff": "ผลต่าง",
    "debitPrice": "ราคาเพิ่มหนี้",
    "actualPrice": "ราคาจริง",
    "getErrorMessageExceedMaxLength": "ความยาวห้ามเกิน ",
    "getErrorMessageExceedMaxLength2": " ตัว",
    "getErrorMessageNoComma": "เลขที่ห้ามระบุ ,",
    "getErrorMessageNoEmptySpace": "เลขที่ห้ามมีช่องว่าง",
    "pendingExpiredCertificateMessage": "*** สำคัญ *** ใบรับรองอิเล็กทรอนิกส์ของท่านใกล้หมดอายุแล้ว",
    "pendingExpiredCertificateMessage2": " กรุณาทำการขอใบรับรองอิเล็กทรอนิกส์ใหม่",
    "expiredCertificateMessage": "*** สำคัญ *** ใบรับรองอิเล็กทรอนิกส์ของท่านหมดอายุแล้ว",
    "expiredCertificateMessage2": " กรุณาทำการขอใบรับรองอิเล็กทรอนิกส์ใหม่",
    "requestCertificate": "ขอใบรับรองฯใหม่"
  },
  "debitNoteTaxInvoiceConfirm": {
    "confirm": "ยืนยัน",
    "edit": "แก้ไข",
    "processing": "กำลังดำเนินการ",
    "processingMessage": "สร้างตัวอย่างเอกสาร ใบเพิ่มหนี้",
    "dialogConfirmHeader": "ระบบพบว่ามีเลขที่เอกสารซ้ำกัน",
    "dialogConfirmDescription": "ท่านยืนยันที่จะดำเนินการต่อหรือไม่",
    "cancel": "ยกเลิก",
  },
  "creditNoteTaxInvoice": {
    "shortMonths01": "ม.ค.",
    "shortMonths02": "ก.พ.",
    "shortMonths03": "มี.ค.",
    "shortMonths04": "เม.ย.",
    "shortMonths05": "พ.ค.",
    "shortMonths06": "มิ.ย.",
    "shortMonths07": "ก.ค.",
    "shortMonths08": "ส.ค.",
    "shortMonths09": "ก.ย.",
    "shortMonths10": "ต.ค.",
    "shortMonths11": "พ.ย.",
    "shortMonths12": "ธ.ค.",
    "months01": "มกราคม",
    "months02": "กุมภาพันธ์",
    "months03": "มีนาคม",
    "months04": "เมษายน",
    "months05": "พฤษภาคม",
    "months06": "มิถุนายน",
    "months07": "กรกฎาคม",
    "months08": "สิงหาคม",
    "months09": "กันยายน",
    "months10": "ตุลาคม",
    "months11": "พฤศจิกายน",
    "months12": "ธันวาคม",
    "goToToday": "ไปวันที่ปัจจุบัน",
    "dropdownErrMsg": "กรุณาเลือก",
    "getErrorMessage": "กรุณากรอกข้อมูล",
    "getPostCodeErrorMessage1": "กรุณาระบุตัวเลขเท่านั้น",
    "getPostCodeErrorMessage2": "กรุณาระบุเลขให้ครบ 5 หลัก",
    "getTaxIdErrorMessage": "กรอกเลข 13 หลัก",
    "getBranchNumberErrorMessage1": "ระบุเป็นตัวเลขเท่านั้น",
    "getBranchNumberErrorMessage2": "กรอกเลข 5 หลัก",
    "getPhoneNumberErrorMessage": "รูปแบบไม่ถูกต้อง ตัวอย่าง: 0812345678",
    "header": "สร้างเอกสาร ใบลดหนี้",
    "documentHeader": "ใบลดหนี้",
    "documentSubHeader": "Credit Note",
    "original": "ต้นฉบับ",
    "companyTel": "โทร",
    "companyEmail": "อีเมล",
    "companyTaxId": "เลขประจำตัวผู้เสียภาษี",
    "customerName": "ชื่อลูกค้า",
    "pleaseSelect": "กรุณาเลือก",
    "country": "ประเทศ",
    "addressStructure": "โครงสร้างที่อยู่",
    "on": "เปิด",
    "off": "ปิด",
    "lineOne": "ที่อยู่ บรรทัดที่ 1",
    "lineTwo": "ที่อยู่ บรรทัดที่ 2",
    "buildingNumber": "อาคารเลขที่",
    "address": "ที่อยู่",
    "streetName": "ถนน",
    "province": "จังหวัด",
    "district": "อำเภอ",
    "subDistrict": "ตำบล",
    "postcode": "รหัสไปรษณีย์",
    "english": "ภาษาอังกฤษ",
    "taxNumberType": "ประเภทผู้เสียภาษี",
    "txid": "เลขประจำตัวผู้เสียภาษี",
    "nidn": "เลขประจำตัวประชาชน",
    "ccpt": "เลขที่หนังสือเดินทาง",
    "othr": "ไม่ระบุ",
    "number": "เลขที่",
    "branchDisplay": "สำนักงานใหญ่/สาขา",
    "branchNumberDesc": "เช่น 00001",
    "tel": "โทร",
    "email": "อีเมล",
    "priceIncludeVat": "ราคารวมภาษีมูลค่าเพิ่ม",
    "date": "วันที่",
    "pleaseSelectDate": "กรุณาเลือกวันที่",
    "reference": "อ้างอิง",
    "itemNumber": "ลำดับ",
    "item": "รายการ",
    "quantity": "จำนวน",
    "unit": "หน่วย",
    "unitPrice": "ราคา/หน่วย",
    "discount": "ส่วนลด",
    "itemVat": "ภาษี (%)",
    "totalIncludeVat": "มูลค่ารวม VAT(บาท)",
    "totalBeforeVat": "มูลค่าก่อนภาษี (บาท)",
    "totalErrorMessage1": "มูลค่าไม่สามารถติดลบได้",
    "delete": "ลบ",
    "addItem": "เพิ่มรายการ",
    "note": "หมายเหตุ",
    "receivedBy": "ผู้รับเงิน",
    "createdBy": "ผู้จัดทำ",
    "reIssueMessage1": "เป็นการยกเลิกและออกใบกำกับภาษีใหม่แทนใบกำกับภาษีเดิม",
    "amountTotalBeforeVat": "รวมเงิน (ก่อน VAT)",
    "amountTotal": "รวมเป็นเงิน",
    "thb": "บาท",
    "vat": "ภาษีมูลค่าเพิ่ม",
    "grandTotal": "จำนวนเงินทั้งสิ้น",
    "specificReason": "ระบุสาเหตุ",
    "nonVatPriceTotal": "มูลค่ารายการยกเว้นภาษี",
    "zeroVatPriceTotal": "มูลค่ารายการภาษี 0%",
    "vatPriceTotal": "มูลค่ารายการภาษี 7%",
    "next": "ต่อไป",
    "cancel": "ยกเลิก",
    "loading": "กำลังโหลด...",
    "isRequiredErrorMessage": "กรุณาเลือกวันที่",
    "dueDate": "ครบกำหนดชำระเงิน",
    "calculationMethod": "การคำนวณราคา",
    "previousAmountTotal": "มูลค่าตามใบกำกับภาษีเดิม",
    "beforeVat": "(ก่อน VAT)",
    "rightAmountTotal": "มูลค่าที่ถูกต้อง",
    "referTypeCode": "ประเภทเอกสารอ้างถึง",
    "referNumber": "เลขที่เอกสารอ้างถึง",
    "referDate": "วันที่เอกสารอ้างถึง",
    "reason": "สาเหตุการออกใบลดหนี้",
    "diff": "ผลต่าง",
    "creditPrice": "ราคาลดหนี้",
    "actualPrice": "ราคาจริง",
    "getErrorMessageExceedMaxLength": "ความยาวห้ามเกิน ",
    "getErrorMessageExceedMaxLength2": " ตัว",
    "getErrorMessageNoComma": "เลขที่ห้ามระบุ ,",
    "getErrorMessageNoEmptySpace": "เลขที่ห้ามมีช่องว่าง",
    "pendingExpiredCertificateMessage": "*** สำคัญ *** ใบรับรองอิเล็กทรอนิกส์ของท่านใกล้หมดอายุแล้ว",
    "pendingExpiredCertificateMessage2": " กรุณาทำการขอใบรับรองอิเล็กทรอนิกส์ใหม่",
    "expiredCertificateMessage": "*** สำคัญ *** ใบรับรองอิเล็กทรอนิกส์ของท่านหมดอายุแล้ว",
    "expiredCertificateMessage2": " กรุณาทำการขอใบรับรองอิเล็กทรอนิกส์ใหม่",
    "requestCertificate": "ขอใบรับรองฯใหม่"
  },
  "creditNoteTaxInvoiceConfirm": {
    "confirm": "ยืนยัน",
    "edit": "แก้ไข",
    "processing": "กำลังดำเนินการ",
    "processingMessage": "สร้างตัวอย่างเอกสาร ใบลดหนี้",
    "dialogConfirmHeader": "ระบบพบว่ามีเลขที่เอกสารซ้ำกัน",
    "dialogConfirmDescription": "ท่านยืนยันที่จะดำเนินการต่อหรือไม่",
    "cancel": "ยกเลิก",
  },
  "quotation": {
    "shortMonths01": "ม.ค.",
    "shortMonths02": "ก.พ.",
    "shortMonths03": "มี.ค.",
    "shortMonths04": "เม.ย.",
    "shortMonths05": "พ.ค.",
    "shortMonths06": "มิ.ย.",
    "shortMonths07": "ก.ค.",
    "shortMonths08": "ส.ค.",
    "shortMonths09": "ก.ย.",
    "shortMonths10": "ต.ค.",
    "shortMonths11": "พ.ย.",
    "shortMonths12": "ธ.ค.",
    "months01": "มกราคม",
    "months02": "กุมภาพันธ์",
    "months03": "มีนาคม",
    "months04": "เมษายน",
    "months05": "พฤษภาคม",
    "months06": "มิถุนายน",
    "months07": "กรกฎาคม",
    "months08": "สิงหาคม",
    "months09": "กันยายน",
    "months10": "ตุลาคม",
    "months11": "พฤศจิกายน",
    "months12": "ธันวาคม",
    "goToToday": "ไปวันที่ปัจจุบัน",
    "dropdownErrMsg": "กรุณาเลือก",
    "getErrorMessage": "กรุณากรอกข้อมูล",
    "getPostCodeErrorMessage1": "กรุณาระบุตัวเลขเท่านั้น",
    "getPostCodeErrorMessage2": "กรุณาระบุเลขให้ครบ 5 หลัก",
    "getTaxIdErrorMessage": "กรอกเลข 13 หลัก",
    "getBranchNumberErrorMessage1": "ระบุเป็นตัวเลขเท่านั้น",
    "getBranchNumberErrorMessage2": "กรอกเลข 5 หลัก",
    "getPhoneNumberErrorMessage": "รูปแบบไม่ถูกต้อง ตัวอย่าง: 0812345678",
    "header": "สร้างเอกสาร ใบเสนอราคา",
    "documentHeader": "ใบเสนอราคา",
    "documentSubHeader": "Quotation",
    "original": "ต้นฉบับ",
    "companyTel": "โทร",
    "companyEmail": "อีเมล",
    "companyTaxId": "เลขประจำตัวผู้เสียภาษี",
    "customerName": "ชื่อลูกค้า",
    "pleaseSelect": "กรุณาเลือก",
    "country": "ประเทศ",
    "addressStructure": "โครงสร้างที่อยู่",
    "on": "เปิด",
    "off": "ปิด",
    "lineOne": "ที่อยู่ บรรทัดที่ 1",
    "lineTwo": "ที่อยู่ บรรทัดที่ 2",
    "buildingNumber": "อาคารเลขที่",
    "address": "ที่อยู่",
    "streetName": "ถนน",
    "province": "จังหวัด",
    "district": "อำเภอ",
    "subDistrict": "ตำบล",
    "postcode": "รหัสไปรษณีย์",
    "english": "ภาษาอังกฤษ",
    "taxNumberType": "ประเภทผู้เสียภาษี",
    "txid": "เลขประจำตัวผู้เสียภาษี",
    "nidn": "เลขประจำตัวประชาชน",
    "ccpt": "เลขที่หนังสือเดินทาง",
    "othr": "ไม่ระบุ",
    "number": "เลขที่",
    "branchDisplay": "สำนักงานใหญ่/สาขา",
    "branchNumberDesc": "เช่น 00001",
    "tel": "โทร",
    "email": "อีเมล",
    "priceIncludeVat": "ราคารวมภาษีมูลค่าเพิ่ม",
    "date": "วันที่",
    "pleaseSelectDate": "กรุณาเลือกวันที่",
    "reference": "อ้างอิง",
    "itemNumber": "ลำดับ",
    "item": "รายการ",
    "quantity": "จำนวน",
    "unit": "หน่วย",
    "unitPrice": "ราคา/หน่วย",
    "discount": "ส่วนลด",
    "itemVat": "ภาษี (%)",
    "totalIncludeVat": "มูลค่ารวม VAT(บาท)",
    "totalBeforeVat": "มูลค่าก่อนภาษี (บาท)",
    "totalErrorMessage1": "มูลค่าไม่สามารถติดลบได้",
    "delete": "ลบ",
    "addItem": "เพิ่มรายการ",
    "note": "หมายเหตุ",
    "receivedBy": "ผู้รับเงิน",
    "createdBy": "ผู้จัดทำ",
    "reIssueMessage1": "เป็นการยกเลิกและออกใบกำกับภาษีใหม่แทนใบกำกับภาษีเดิม",
    "amountTotalBeforeVat": "รวมเงิน (ก่อน Vat)",
    "amountTotal": "รวมเป็นเงิน",
    "thb": "บาท",
    "vat": "ภาษีมูลค่าเพิ่ม",
    "grandTotal": "จำนวนเงินทั้งสิ้น",
    "referNumber": "เลขที่ใบกำกับภาษีเดิม",
    "referDate": "วันที่ใบกำกับภาษีเดิม",
    "reason": "สาเหตุการยกเลิกใบกำกับภาษีเดิม",
    "specificReason": "ระบุสาเหตุ",
    "nonVatPriceTotal": "มูลค่ารายการยกเว้นภาษี",
    "zeroVatPriceTotal": "มูลค่ารายการภาษี 0%",
    "vatPriceTotal": "มูลค่ารายการภาษี 7%",
    "next": "ต่อไป",
    "cancel": "ยกเลิก",
    "loading": "กำลังโหลด...",
    "isRequiredErrorMessage": "กรุณาเลือกวันที่",
    "dueDate": "ครบกำหนดชำระเงิน",
    "contactName": "ผู้ติดต่อ",
    "total": "มูลค่า (บาท)",
    "payment": "ช่องทางการชำระเงิน",
    "accountName": "ชื่อบัญชีธนาคาร",
    "bankName": "ธนาคาร",
    "bankBranch": "สาขา",
    "accountType": "บัญชีประเภท",
    "accountNumber": "บัญชีเลขที่",
    "addBank": "เพิ่มธนาคาร",
    "paymentNote": "หมายเหตุการชำระเงิน",
    "promtpayQr": "พร้อมเพย์ QR",
    "taxNumber": "เลขที่",
    "paypalConnectSuccess": "เชื่อมต่อ PayPal สำเร็จแล้ว",
    "paypalConnect": "กรุณาเชื่อมต่อ PayPal ก่อน",
    "stripeConnectSuccess": "เชื่อมต่อ Stripe สำเร็จแล้ว",
    "stripeConnect": "กรุณาเชื่อมต่อ Stripe ก่อน",
    "paymentGateway": "ช่องทางชำระอื่น",
    "notSelect": "ไม่ระบุ",
    "getErrorMessageExceedMaxLength": "ความยาวห้ามเกิน ",
    "getErrorMessageExceedMaxLength2": " ตัว",
    "getErrorMessageNoComma": "เลขที่ห้ามระบุ ,",
    "getErrorMessageNoEmptySpace": "เลขที่ห้ามมีช่องว่าง",
    "pendingExpiredCertificateMessage": "*** สำคัญ *** ใบรับรองอิเล็กทรอนิกส์ของท่านใกล้หมดอายุแล้ว",
    "pendingExpiredCertificateMessage2": " กรุณาทำการขอใบรับรองอิเล็กทรอนิกส์ใหม่",
    "expiredCertificateMessage": "*** สำคัญ *** ใบรับรองอิเล็กทรอนิกส์ของท่านหมดอายุแล้ว",
    "expiredCertificateMessage2": " กรุณาทำการขอใบรับรองอิเล็กทรอนิกส์ใหม่",
    "requestCertificate": "ขอใบรับรองฯใหม่"
  },
  "quotationConfirm": {
    "confirm": "ยืนยัน",
    "edit": "แก้ไข",
    "processing": "กำลังดำเนินการ",
    "processingMessage": "สร้างตัวอย่างเอกสาร ใบเสนอราคา",
    "dialogConfirmHeader": "ระบบพบว่ามีเลขที่เอกสารซ้ำกัน",
    "dialogConfirmDescription": "ท่านยืนยันที่จะดำเนินการต่อหรือไม่",
    "cancel": "ยกเลิก",
  },
  "invoice": {
    "shortMonths01": "ม.ค.",
    "shortMonths02": "ก.พ.",
    "shortMonths03": "มี.ค.",
    "shortMonths04": "เม.ย.",
    "shortMonths05": "พ.ค.",
    "shortMonths06": "มิ.ย.",
    "shortMonths07": "ก.ค.",
    "shortMonths08": "ส.ค.",
    "shortMonths09": "ก.ย.",
    "shortMonths10": "ต.ค.",
    "shortMonths11": "พ.ย.",
    "shortMonths12": "ธ.ค.",
    "months01": "มกราคม",
    "months02": "กุมภาพันธ์",
    "months03": "มีนาคม",
    "months04": "เมษายน",
    "months05": "พฤษภาคม",
    "months06": "มิถุนายน",
    "months07": "กรกฎาคม",
    "months08": "สิงหาคม",
    "months09": "กันยายน",
    "months10": "ตุลาคม",
    "months11": "พฤศจิกายน",
    "months12": "ธันวาคม",
    "goToToday": "ไปวันที่ปัจจุบัน",
    "dropdownErrMsg": "กรุณาเลือก",
    "getErrorMessage": "กรุณากรอกข้อมูล",
    "getPostCodeErrorMessage1": "กรุณาระบุตัวเลขเท่านั้น",
    "getPostCodeErrorMessage2": "กรุณาระบุเลขให้ครบ 5 หลัก",
    "getTaxIdErrorMessage": "กรอกเลข 13 หลัก",
    "getBranchNumberErrorMessage1": "ระบุเป็นตัวเลขเท่านั้น",
    "getBranchNumberErrorMessage2": "กรอกเลข 5 หลัก",
    "getPhoneNumberErrorMessage": "รูปแบบไม่ถูกต้อง ตัวอย่าง: 0812345678",
    "header": "สร้างเอกสาร ใบแจ้งหนี้",
    "documentHeader": "ใบแจ้งหนี้",
    "documentSubHeader": "Invoice",
    "original": "ต้นฉบับ",
    "companyTel": "โทร",
    "companyEmail": "อีเมล",
    "companyTaxId": "เลขประจำตัวผู้เสียภาษี",
    "customerName": "ชื่อลูกค้า",
    "pleaseSelect": "กรุณาเลือก",
    "country": "ประเทศ",
    "addressStructure": "โครงสร้างที่อยู่",
    "on": "เปิด",
    "off": "ปิด",
    "lineOne": "ที่อยู่ บรรทัดที่ 1",
    "lineTwo": "ที่อยู่ บรรทัดที่ 2",
    "buildingNumber": "อาคารเลขที่",
    "address": "ที่อยู่",
    "streetName": "ถนน",
    "province": "จังหวัด",
    "district": "อำเภอ",
    "subDistrict": "ตำบล",
    "postcode": "รหัสไปรษณีย์",
    "english": "ภาษาอังกฤษ",
    "taxNumberType": "ประเภทผู้เสียภาษี",
    "txid": "เลขประจำตัวผู้เสียภาษี",
    "nidn": "เลขประจำตัวประชาชน",
    "ccpt": "เลขที่หนังสือเดินทาง",
    "othr": "ไม่ระบุ",
    "number": "เลขที่",
    "branchDisplay": "สำนักงานใหญ่/สาขา",
    "branchNumberDesc": "เช่น 00001",
    "tel": "โทร",
    "email": "อีเมล",
    "priceIncludeVat": "ราคารวมภาษีมูลค่าเพิ่ม",
    "date": "วันที่",
    "pleaseSelectDate": "กรุณาเลือกวันที่",
    "reference": "อ้างอิง",
    "itemNumber": "ลำดับ",
    "item": "รายการ",
    "quantity": "จำนวน",
    "unit": "หน่วย",
    "unitPrice": "ราคา/หน่วย",
    "discount": "ส่วนลด",
    "itemVat": "ภาษี (%)",
    "totalIncludeVat": "มูลค่ารวม VAT(บาท)",
    "totalBeforeVat": "มูลค่าก่อนภาษี (บาท)",
    "totalErrorMessage1": "มูลค่าไม่สามารถติดลบได้",
    "delete": "ลบ",
    "addItem": "เพิ่มรายการ",
    "note": "หมายเหตุ",
    "receivedBy": "ผู้รับเงิน",
    "createdBy": "ผู้จัดทำ",
    "reIssueMessage1": "เป็นการยกเลิกและออกใบกำกับภาษีใหม่แทนใบกำกับภาษีเดิม",
    "amountTotalBeforeVat": "รวมเงิน (ก่อน Vat)",
    "amountTotal": "รวมเป็นเงิน",
    "thb": "บาท",
    "vat": "ภาษีมูลค่าเพิ่ม",
    "grandTotal": "จำนวนเงินทั้งสิ้น",
    "referNumber": "เลขที่ใบกำกับภาษีเดิม",
    "referDate": "วันที่ใบกำกับภาษีเดิม",
    "reason": "สาเหตุการยกเลิกใบกำกับภาษีเดิม",
    "specificReason": "ระบุสาเหตุ",
    "nonVatPriceTotal": "มูลค่ารายการยกเว้นภาษี",
    "zeroVatPriceTotal": "มูลค่ารายการภาษี 0%",
    "vatPriceTotal": "มูลค่ารายการภาษี 7%",
    "next": "ต่อไป",
    "cancel": "ยกเลิก",
    "loading": "กำลังโหลด...",
    "isRequiredErrorMessage": "กรุณาเลือกวันที่",
    "dueDate": "ครบกำหนดชำระเงิน",
    "contactName": "ผู้ติดต่อ",
    "total": "มูลค่า (บาท)",
    "payment": "ช่องทางการชำระเงิน",
    "accountName": "ชื่อบัญชีธนาคาร",
    "bankName": "ธนาคาร",
    "bankBranch": "สาขา",
    "accountType": "บัญชีประเภท",
    "accountNumber": "บัญชีเลขที่",
    "addBank": "เพิ่มธนาคาร",
    "paymentNote": "หมายเหตุการชำระเงิน",
    "promtpayQr": "พร้อมเพย์ QR",
    "taxNumber": "เลขที่",
    "paypalConnectSuccess": "เชื่อมต่อ PayPal สำเร็จแล้ว",
    "paypalConnect": "กรุณาเชื่อมต่อ PayPal ก่อน",
    "stripeConnectSuccess": "เชื่อมต่อ Stripe สำเร็จแล้ว",
    "stripeConnect": "กรุณาเชื่อมต่อ Stripe ก่อน",
    "paymentGateway": "ช่องทางชำระอื่น",
    "notSelect": "ไม่ระบุ",
    "getErrorMessageExceedMaxLength": "ความยาวห้ามเกิน ",
    "getErrorMessageExceedMaxLength2": " ตัว",
    "getErrorMessageNoComma": "เลขที่ห้ามระบุ ,",
    "getErrorMessageNoEmptySpace": "เลขที่ห้ามมีช่องว่าง",
    "pendingExpiredCertificateMessage": "*** สำคัญ *** ใบรับรองอิเล็กทรอนิกส์ของท่านใกล้หมดอายุแล้ว",
    "pendingExpiredCertificateMessage2": " กรุณาทำการขอใบรับรองอิเล็กทรอนิกส์ใหม่",
    "expiredCertificateMessage": "*** สำคัญ *** ใบรับรองอิเล็กทรอนิกส์ของท่านหมดอายุแล้ว",
    "expiredCertificateMessage2": " กรุณาทำการขอใบรับรองอิเล็กทรอนิกส์ใหม่",
    "requestCertificate": "ขอใบรับรองฯใหม่"
  },
  "invoiceConfirm": {
    "confirm": "ยืนยัน",
    "edit": "แก้ไข",
    "processing": "กำลังดำเนินการ",
    "processingMessage": "สร้างตัวอย่างเอกสาร ใบแจ้งหนี้",
    "dialogConfirmHeader": "ระบบพบว่ามีเลขที่เอกสารซ้ำกัน",
    "dialogConfirmDescription": "ท่านยืนยันที่จะดำเนินการต่อหรือไม่",
    "cancel": "ยกเลิก",
  },
  "deliveryorders": {
    "shortMonths01": "ม.ค.",
    "shortMonths02": "ก.พ.",
    "shortMonths03": "มี.ค.",
    "shortMonths04": "เม.ย.",
    "shortMonths05": "พ.ค.",
    "shortMonths06": "มิ.ย.",
    "shortMonths07": "ก.ค.",
    "shortMonths08": "ส.ค.",
    "shortMonths09": "ก.ย.",
    "shortMonths10": "ต.ค.",
    "shortMonths11": "พ.ย.",
    "shortMonths12": "ธ.ค.",
    "months01": "มกราคม",
    "months02": "กุมภาพันธ์",
    "months03": "มีนาคม",
    "months04": "เมษายน",
    "months05": "พฤษภาคม",
    "months06": "มิถุนายน",
    "months07": "กรกฎาคม",
    "months08": "สิงหาคม",
    "months09": "กันยายน",
    "months10": "ตุลาคม",
    "months11": "พฤศจิกายน",
    "months12": "ธันวาคม",
    "goToToday": "ไปวันที่ปัจจุบัน",
    "dropdownErrMsg": "กรุณาเลือก",
    "getErrorMessage": "กรุณากรอกข้อมูล",
    "getPostCodeErrorMessage1": "กรุณาระบุตัวเลขเท่านั้น",
    "getPostCodeErrorMessage2": "กรุณาระบุเลขให้ครบ 5 หลัก",
    "getTaxIdErrorMessage": "กรอกเลข 13 หลัก",
    "getBranchNumberErrorMessage1": "ระบุเป็นตัวเลขเท่านั้น",
    "getBranchNumberErrorMessage2": "กรอกเลข 5 หลัก",
    "getPhoneNumberErrorMessage": "รูปแบบไม่ถูกต้อง ตัวอย่าง: 0812345678",
    "header": "สร้างเอกสาร ใบส่งของ",
    "documentHeader": "ใบส่งของ",
    "documentSubHeader": "Delivery Order",
    "original": "ต้นฉบับ",
    "companyTel": "โทร",
    "companyEmail": "อีเมล",
    "companyTaxId": "เลขประจำตัวผู้เสียภาษี",
    "customerName": "ชื่อลูกค้า",
    "pleaseSelect": "กรุณาเลือก",
    "country": "ประเทศ",
    "addressStructure": "โครงสร้างที่อยู่",
    "on": "เปิด",
    "off": "ปิด",
    "lineOne": "ที่อยู่ บรรทัดที่ 1",
    "lineTwo": "ที่อยู่ บรรทัดที่ 2",
    "buildingNumber": "อาคารเลขที่",
    "address": "ที่อยู่",
    "streetName": "ถนน",
    "province": "จังหวัด",
    "district": "อำเภอ",
    "subDistrict": "ตำบล",
    "postcode": "รหัสไปรษณีย์",
    "english": "ภาษาอังกฤษ",
    "taxNumberType": "ประเภทผู้เสียภาษี",
    "txid": "เลขประจำตัวผู้เสียภาษี",
    "nidn": "เลขประจำตัวประชาชน",
    "ccpt": "เลขที่หนังสือเดินทาง",
    "othr": "ไม่ระบุ",
    "number": "เลขที่",
    "branchDisplay": "สำนักงานใหญ่/สาขา",
    "branchNumberDesc": "เช่น 00001",
    "tel": "โทร",
    "email": "อีเมล",
    "priceIncludeVat": "ราคารวมภาษีมูลค่าเพิ่ม",
    "date": "วันที่",
    "pleaseSelectDate": "กรุณาเลือกวันที่",
    "reference": "อ้างอิง",
    "itemNumber": "ลำดับ",
    "item": "รายการ",
    "quantity": "จำนวน",
    "unit": "หน่วย",
    "unitPrice": "ราคา/หน่วย",
    "discount": "ส่วนลด",
    "itemVat": "ภาษี (%)",
    "totalIncludeVat": "มูลค่ารวม VAT(บาท)",
    "totalBeforeVat": "มูลค่าก่อนภาษี (บาท)",
    "totalErrorMessage1": "มูลค่าไม่สามารถติดลบได้",
    "delete": "ลบ",
    "addItem": "เพิ่มรายการ",
    "note": "หมายเหตุ",
    "receivedBy": "ผู้รับเงิน",
    "createdBy": "ผู้จัดทำ",
    "reIssueMessage1": "เป็นการยกเลิกและออกใบกำกับภาษีใหม่แทนใบกำกับภาษีเดิม",
    "amountTotalBeforeVat": "รวมเงิน (ก่อน Vat)",
    "amountTotal": "รวมเป็นเงิน",
    "thb": "บาท",
    "vat": "ภาษีมูลค่าเพิ่ม",
    "grandTotal": "จำนวนเงินทั้งสิ้น",
    "referNumber": "เลขที่ใบกำกับภาษีเดิม",
    "referDate": "วันที่ใบกำกับภาษีเดิม",
    "reason": "สาเหตุการยกเลิกใบกำกับภาษีเดิม",
    "specificReason": "ระบุสาเหตุ",
    "nonVatPriceTotal": "มูลค่ารายการยกเว้นภาษี",
    "zeroVatPriceTotal": "มูลค่ารายการภาษี 0%",
    "vatPriceTotal": "มูลค่ารายการภาษี 7%",
    "next": "ต่อไป",
    "cancel": "ยกเลิก",
    "loading": "กำลังโหลด...",
    "isRequiredErrorMessage": "กรุณาเลือกวันที่",
    "dueDate": "ครบกำหนดชำระเงิน",
    "contactName": "ผู้ติดต่อ",
    "total": "มูลค่า (บาท)",
    "payment": "ช่องทางการชำระเงิน",
    "accountName": "ชื่อบัญชีธนาคาร",
    "bankName": "ธนาคาร",
    "bankBranch": "สาขา",
    "accountType": "บัญชีประเภท",
    "accountNumber": "บัญชีเลขที่",
    "addBank": "เพิ่มธนาคาร",
    "paymentNote": "หมายเหตุการชำระเงิน",
    "promtpayQr": "พร้อมเพย์ QR",
    "taxNumber": "เลขที่",
    "paypalConnectSuccess": "เชื่อมต่อ PayPal สำเร็จแล้ว",
    "paypalConnect": "กรุณาเชื่อมต่อ PayPal ก่อน",
    "stripeConnectSuccess": "เชื่อมต่อ Stripe สำเร็จแล้ว",
    "stripeConnect": "กรุณาเชื่อมต่อ Stripe ก่อน",
    "paymentGateway": "ช่องทางชำระอื่น",
    "notSelect": "ไม่ระบุ",
    "getErrorMessageExceedMaxLength": "ความยาวห้ามเกิน ",
    "getErrorMessageExceedMaxLength2": " ตัว",
    "getErrorMessageNoComma": "เลขที่ห้ามระบุ ,",
    "getErrorMessageNoEmptySpace": "เลขที่ห้ามมีช่องว่าง",
    "pendingExpiredCertificateMessage": "*** สำคัญ *** ใบรับรองอิเล็กทรอนิกส์ของท่านใกล้หมดอายุแล้ว",
    "pendingExpiredCertificateMessage2": " กรุณาทำการขอใบรับรองอิเล็กทรอนิกส์ใหม่",
    "expiredCertificateMessage": "*** สำคัญ *** ใบรับรองอิเล็กทรอนิกส์ของท่านหมดอายุแล้ว",
    "expiredCertificateMessage2": " กรุณาทำการขอใบรับรองอิเล็กทรอนิกส์ใหม่",
    "requestCertificate": "ขอใบรับรองฯใหม่"
  },
  "deliveryordersConfirm": {
    "confirm": "ยืนยัน",
    "edit": "แก้ไข",
    "processing": "กำลังดำเนินการ",
    "processingMessage": "สร้างตัวอย่างเอกสาร ใบส่งของ",
    "dialogConfirmHeader": "ระบบพบว่ามีเลขที่เอกสารซ้ำกัน",
    "dialogConfirmDescription": "ท่านยืนยันที่จะดำเนินการต่อหรือไม่",
    "cancel": "ยกเลิก",
  },
  "billingNote": {
    "shortMonths01": "ม.ค.",
    "shortMonths02": "ก.พ.",
    "shortMonths03": "มี.ค.",
    "shortMonths04": "เม.ย.",
    "shortMonths05": "พ.ค.",
    "shortMonths06": "มิ.ย.",
    "shortMonths07": "ก.ค.",
    "shortMonths08": "ส.ค.",
    "shortMonths09": "ก.ย.",
    "shortMonths10": "ต.ค.",
    "shortMonths11": "พ.ย.",
    "shortMonths12": "ธ.ค.",
    "months01": "มกราคม",
    "months02": "กุมภาพันธ์",
    "months03": "มีนาคม",
    "months04": "เมษายน",
    "months05": "พฤษภาคม",
    "months06": "มิถุนายน",
    "months07": "กรกฎาคม",
    "months08": "สิงหาคม",
    "months09": "กันยายน",
    "months10": "ตุลาคม",
    "months11": "พฤศจิกายน",
    "months12": "ธันวาคม",
    "goToToday": "ไปวันที่ปัจจุบัน",
    "dropdownErrMsg": "กรุณาเลือก",
    "getErrorMessage": "กรุณากรอกข้อมูล",
    "getPostCodeErrorMessage1": "กรุณาระบุตัวเลขเท่านั้น",
    "getPostCodeErrorMessage2": "กรุณาระบุเลขให้ครบ 5 หลัก",
    "getTaxIdErrorMessage": "กรอกเลข 13 หลัก",
    "getBranchNumberErrorMessage1": "ระบุเป็นตัวเลขเท่านั้น",
    "getBranchNumberErrorMessage2": "กรอกเลข 5 หลัก",
    "getPhoneNumberErrorMessage": "รูปแบบไม่ถูกต้อง ตัวอย่าง: 0812345678",
    "header": "สร้างเอกสาร ใบวางบิล",
    "documentHeader": "ใบวางบิล",
    "documentSubHeader": "Billing Note",
    "original": "ต้นฉบับ",
    "companyTel": "โทร",
    "companyEmail": "อีเมล",
    "companyTaxId": "เลขประจำตัวผู้เสียภาษี",
    "customerName": "ชื่อลูกค้า",
    "pleaseSelect": "กรุณาเลือก",
    "country": "ประเทศ",
    "addressStructure": "โครงสร้างที่อยู่",
    "on": "เปิด",
    "off": "ปิด",
    "lineOne": "ที่อยู่ บรรทัดที่ 1",
    "lineTwo": "ที่อยู่ บรรทัดที่ 2",
    "buildingNumber": "อาคารเลขที่",
    "address": "ที่อยู่",
    "streetName": "ถนน",
    "province": "จังหวัด",
    "district": "อำเภอ",
    "subDistrict": "ตำบล",
    "postcode": "รหัสไปรษณีย์",
    "english": "ภาษาอังกฤษ",
    "taxNumberType": "ประเภทผู้เสียภาษี",
    "txid": "เลขประจำตัวผู้เสียภาษี",
    "nidn": "เลขประจำตัวประชาชน",
    "ccpt": "เลขที่หนังสือเดินทาง",
    "othr": "ไม่ระบุ",
    "number": "เลขที่",
    "branchDisplay": "สำนักงานใหญ่/สาขา",
    "branchNumberDesc": "เช่น 00001",
    "tel": "โทร",
    "email": "อีเมล",
    "priceIncludeVat": "ราคารวมภาษีมูลค่าเพิ่ม",
    "date": "วันที่",
    "pleaseSelectDate": "กรุณาเลือกวันที่",
    "reference": "อ้างอิง",
    "itemNumber": "ลำดับ",
    "item": "รายการ",
    "quantity": "จำนวน",
    "unit": "หน่วย",
    "unitPrice": "ราคา/หน่วย",
    "discount": "ส่วนลด",
    "itemVat": "ภาษี (%)",
    "totalIncludeVat": "มูลค่ารวม VAT(บาท)",
    "totalBeforeVat": "มูลค่าก่อนภาษี (บาท)",
    "totalErrorMessage1": "มูลค่าไม่สามารถติดลบได้",
    "delete": "ลบ",
    "addItem": "เพิ่มรายการ",
    "note": "หมายเหตุ",
    "receivedBy": "ผู้รับเงิน",
    "createdBy": "ผู้จัดทำ",
    "reIssueMessage1": "เป็นการยกเลิกและออกใบกำกับภาษีใหม่แทนใบกำกับภาษีเดิม",
    "amountTotalBeforeVat": "รวมเงิน (ก่อน Vat)",
    "amountTotal": "รวมเป็นเงิน",
    "thb": "บาท",
    "vat": "ภาษีมูลค่าเพิ่ม",
    "grandTotal": "จำนวนเงินทั้งสิ้น",
    "referNumber": "เลขที่ใบกำกับภาษีเดิม",
    "referDate": "วันที่ใบกำกับภาษีเดิม",
    "reason": "สาเหตุการยกเลิกใบกำกับภาษีเดิม",
    "specificReason": "ระบุสาเหตุ",
    "nonVatPriceTotal": "มูลค่ารายการยกเว้นภาษี",
    "zeroVatPriceTotal": "มูลค่ารายการภาษี 0%",
    "vatPriceTotal": "มูลค่ารายการภาษี 7%",
    "next": "ต่อไป",
    "cancel": "ยกเลิก",
    "loading": "กำลังโหลด...",
    "isRequiredErrorMessage": "กรุณาเลือกวันที่",
    "dueDate": "ครบกำหนดชำระเงิน",
    "contactName": "ผู้ติดต่อ",
    "total": "มูลค่า (บาท)",
    "payment": "ช่องทางการชำระเงิน",
    "accountName": "ชื่อบัญชีธนาคาร",
    "bankName": "ธนาคาร",
    "bankBranch": "สาขา",
    "accountType": "บัญชีประเภท",
    "accountNumber": "บัญชีเลขที่",
    "addBank": "เพิ่มธนาคาร",
    "paymentNote": "หมายเหตุการชำระเงิน",
    "promtpayQr": "พร้อมเพย์ QR",
    "taxNumber": "เลขที่",
    "paypalConnectSuccess": "เชื่อมต่อ PayPal สำเร็จแล้ว",
    "paypalConnect": "กรุณาเชื่อมต่อ PayPal ก่อน",
    "stripeConnectSuccess": "เชื่อมต่อ Stripe สำเร็จแล้ว",
    "stripeConnect": "กรุณาเชื่อมต่อ Stripe ก่อน",
    "paymentGateway": "ช่องทางชำระอื่น",
    "notSelect": "ไม่ระบุ",
    "getErrorMessageExceedMaxLength": "ความยาวห้ามเกิน ",
    "getErrorMessageExceedMaxLength2": " ตัว",
    "getErrorMessageNoComma": "เลขที่ห้ามระบุ ,",
    "getErrorMessageNoEmptySpace": "เลขที่ห้ามมีช่องว่าง",
    "pendingExpiredCertificateMessage": "*** สำคัญ *** ใบรับรองอิเล็กทรอนิกส์ของท่านใกล้หมดอายุแล้ว",
    "pendingExpiredCertificateMessage2": " กรุณาทำการขอใบรับรองอิเล็กทรอนิกส์ใหม่",
    "expiredCertificateMessage": "*** สำคัญ *** ใบรับรองอิเล็กทรอนิกส์ของท่านหมดอายุแล้ว",
    "expiredCertificateMessage2": " กรุณาทำการขอใบรับรองอิเล็กทรอนิกส์ใหม่",
    "requestCertificate": "ขอใบรับรองฯใหม่"
  },
  "billingNoteConfirm": {
    "confirm": "ยืนยัน",
    "edit": "แก้ไข",
    "processing": "กำลังดำเนินการ",
    "processingMessage": "สร้างตัวอย่างเอกสาร ใบวางบิล",
    "dialogConfirmHeader": "ระบบพบว่ามีเลขที่เอกสารซ้ำกัน",
    "dialogConfirmDescription": "ท่านยืนยันที่จะดำเนินการต่อหรือไม่",
    "cancel": "ยกเลิก",
  },
  "withholdingTax": {
    "next": "ต่อไป",
    "cancel": "ยกเลิก",
    "loading": "กำลังโหลด...",
    "header": "สร้างเอกสาร หนังสือรับรองการหักภาษี ณ ที่จ่าย",
    "pendingExpiredCertificateMessage": "*** สำคัญ *** ใบรับรองอิเล็กทรอนิกส์ของท่านใกล้หมดอายุแล้ว",
    "pendingExpiredCertificateMessage2": " กรุณาทำการขอใบรับรองอิเล็กทรอนิกส์ใหม่",
    "expiredCertificateMessage": "*** สำคัญ *** ใบรับรองอิเล็กทรอนิกส์ของท่านหมดอายุแล้ว",
    "expiredCertificateMessage2": " กรุณาทำการขอใบรับรองอิเล็กทรอนิกส์ใหม่",
    "requestCertificate": "ขอใบรับรองฯใหม่"
  },
  "withholdingTaxConfirm": {
    "confirm": "ยืนยัน",
    "edit": "แก้ไข",
    "processing": "กำลังดำเนินการ",
    "processingMessage": "สร้างตัวอย่างเอกสาร หนังสือรับรองการหักภาษี ณ ที่จ่าย",
  },
  "expenseNote": {
    "shortMonths01": "ม.ค.",
    "shortMonths02": "ก.พ.",
    "shortMonths03": "มี.ค.",
    "shortMonths04": "เม.ย.",
    "shortMonths05": "พ.ค.",
    "shortMonths06": "มิ.ย.",
    "shortMonths07": "ก.ค.",
    "shortMonths08": "ส.ค.",
    "shortMonths09": "ก.ย.",
    "shortMonths10": "ต.ค.",
    "shortMonths11": "พ.ย.",
    "shortMonths12": "ธ.ค.",
    "months01": "มกราคม",
    "months02": "กุมภาพันธ์",
    "months03": "มีนาคม",
    "months04": "เมษายน",
    "months05": "พฤษภาคม",
    "months06": "มิถุนายน",
    "months07": "กรกฎาคม",
    "months08": "สิงหาคม",
    "months09": "กันยายน",
    "months10": "ตุลาคม",
    "months11": "พฤศจิกายน",
    "months12": "ธันวาคม",
    "goToToday": "ไปวันที่ปัจจุบัน",
    "dropdownErrMsg": "กรุณาเลือก",
    "getErrorMessage": "กรุณากรอกข้อมูล",
    "getPostCodeErrorMessage1": "กรุณาระบุตัวเลขเท่านั้น",
    "getPostCodeErrorMessage2": "กรุณาระบุเลขให้ครบ 5 หลัก",
    "getTaxIdErrorMessage": "กรอกเลข 13 หลัก",
    "getBranchNumberErrorMessage1": "ระบุเป็นตัวเลขเท่านั้น",
    "getBranchNumberErrorMessage2": "กรอกเลข 5 หลัก",
    "getPhoneNumberErrorMessage": "รูปแบบไม่ถูกต้อง ตัวอย่าง: 0812345678",
    "header": "สร้างเอกสาร บันทึกค่าใช้จ่าย",
    "documentHeader": "บันทึกค่าใช้จ่าย",
    "documentSubHeader": "Expense Note",
    "original": "ต้นฉบับ",
    "companyTel": "โทร",
    "companyEmail": "อีเมล",
    "companyTaxId": "เลขประจำตัวผู้เสียภาษี",
    "customerName": "ชื่อผู้ขาย/ผู้รับเงิน",
    "pleaseSelect": "กรุณาเลือก",
    "country": "ประเทศ",
    "addressStructure": "โครงสร้างที่อยู่",
    "on": "เปิด",
    "off": "ปิด",
    "lineOne": "ที่อยู่ บรรทัดที่ 1",
    "lineTwo": "ที่อยู่ บรรทัดที่ 2",
    "buildingNumber": "อาคารเลขที่",
    "address": "ที่อยู่",
    "streetName": "ถนน",
    "province": "จังหวัด",
    "district": "อำเภอ",
    "subDistrict": "ตำบล",
    "postcode": "รหัสไปรษณีย์",
    "english": "ภาษาอังกฤษ",
    "taxNumberType": "ประเภทผู้เสียภาษี",
    "txid": "เลขประจำตัวผู้เสียภาษี",
    "nidn": "เลขประจำตัวประชาชน",
    "ccpt": "เลขที่หนังสือเดินทาง",
    "othr": "ไม่ระบุ",
    "number": "เลขที่",
    "branchDisplay": "สำนักงานใหญ่/สาขา",
    "branchNumberDesc": "เช่น 00001",
    "tel": "โทร",
    "email": "อีเมล",
    "priceIncludeVat": "ราคารวมภาษีมูลค่าเพิ่ม",
    "date": "วันที่",
    "pleaseSelectDate": "กรุณาเลือกวันที่",
    "reference": "อ้างอิง",
    "itemNumber": "ลำดับ",
    "item": "รายการ",
    "quantity": "จำนวน",
    "unit": "หน่วย",
    "unitPrice": "ราคา/หน่วย",
    "discount": "ส่วนลด",
    "itemVat": "ภาษี (%)",
    "totalIncludeVat": "มูลค่ารวม VAT(บาท)",
    "totalBeforeVat": "มูลค่าก่อนภาษี (บาท)",
    "totalErrorMessage1": "มูลค่าไม่สามารถติดลบได้",
    "delete": "ลบ",
    "addItem": "เพิ่มรายการ",
    "note": "หมายเหตุ",
    "receivedBy": "ผู้รับเงิน",
    "createdBy": "ผู้จัดทำ",
    "reIssueMessage1": "เป็นการยกเลิกและออกใบกำกับภาษีใหม่แทนใบกำกับภาษีเดิม",
    "amountTotalBeforeVat": "รวมเงิน (ก่อน Vat)",
    "amountTotal": "รวมเป็นเงิน",
    "thb": "บาท",
    "vat": "ภาษีมูลค่าเพิ่ม",
    "grandTotal": "จำนวนเงินทั้งสิ้น",
    "referNumber": "เลขที่ใบกำกับภาษีเดิม",
    "referDate": "วันที่ใบกำกับภาษีเดิม",
    "reason": "สาเหตุการยกเลิกใบกำกับภาษีเดิม",
    "specificReason": "ระบุสาเหตุ",
    "nonVatPriceTotal": "มูลค่ารายการยกเว้นภาษี",
    "zeroVatPriceTotal": "มูลค่ารายการภาษี 0%",
    "vatPriceTotal": "มูลค่ารายการภาษี 7%",
    "next": "ต่อไป",
    "cancel": "ยกเลิก",
    "loading": "กำลังโหลด...",
    "isRequiredErrorMessage": "กรุณาเลือกวันที่",
    "dueDate": "ครบกำหนดชำระเงิน",
    "contactName": "ผู้ติดต่อ",
    "total": "มูลค่า (บาท)",
    "payment": "การชำระเงิน",
    "accountName": "ชื่อบัญชีธนาคาร",
    "bankName": "ธนาคาร",
    "bankBranch": "สาขา",
    "accountType": "บัญชีประเภท",
    "accountNumber": "บัญชีเลขที่",
    "addBank": "เพิ่มธนาคาร",
    "paymentNote": "หมายเหตุการชำระเงิน",
    "promtpayQr": "พร้อมเพย์ QR",
    "taxNumber": "เลขที่",
    "updateSuccess": "บันทึกสำเร็จ!",
    "taxInvoiceNumber": "รับใบกำกับภาษีเลขที่",
    "taxInvoiceDate": "วันที่ใบกำกับภาษี",
    "itemWhtType": "หัก ณ ที่จ่าย",
    "itemWhtTotal": "เงินที่หัก",
    "whtTotal": "จำนวนเงินที่ถูกหัก ณ ที่จ่าย",
    "totalToPay": "จำนวนเงินที่ต้องชำระ",
    "payNumber": "ชำระเงินครั้งที่",
    "payDate": "วันที่ชำระ",
    "payBy": "จ่ายเงินโดย",
    "BANK": "ธนาคาร",
    "CASH": "เงินสด",
    "paidAmount": "จำนวนเงินที่ชำระ",
    "addPaymentList": "เพิ่มช่องทางการชำระเงิน",
    "save": "บันทึก",
    "approvedBy": "ผู้อนุมัติ",
    "approved": "อนุมัติ",
    "approvedDate": "วันที่อนุมัติ",
  },
  "expenseNoteConfirm": {
    "confirm": "ยืนยัน",
    "edit": "แก้ไข",
    "processing": "กำลังดำเนินการ",
    "processingMessage": "สร้างตัวอย่างเอกสาร บันทึกค่าใช้จ่าย",
  },
  "xero": {
    "loading": "กำลังโหลด...",
    "page": "หน้า",
    "allDocuments": "เอกสารทั้งหมด",
    "documentDate": "วันที่บนเอกสาร",
    "number": "เลขที่",
    "customerName": "ชื่อลูกค้า",
    "customerAddress": "ที่อยู่ลูกค้า",
    "phone": "เบอร์โทร",
    "grandTotal": "ยอด (รวม VAT)",
    "pending": "รอดำเนินการ",
    "processing": "กำลังสร้างเอกสาร...",
    "success": "สำเร็จ",
    "fail": "ไม่สำเร็จ",
    "receipt-taxinvoice": "ใบเสร็จรับเงิน/ใบกำกับภาษี",
    "receipt-taxinvoice-abb": "ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ",
    "receipt": "ใบเสร็จรับเงิน",
    "invoice-taxinvoice": "ใบแจ้งหนี้/ใบกำกับภาษี",
    "deliveryorder-taxinvoice": "ใบส่งของ/ใบกำกับภาษี",
    "taxinvoice": "ใบกำกับภาษี",
    "thisMonth": "เดือนนี้",
    "shortMonths01": "ม.ค.",
    "shortMonths02": "ก.พ.",
    "shortMonths03": "มี.ค.",
    "shortMonths04": "เม.ย.",
    "shortMonths05": "พ.ค.",
    "shortMonths06": "มิ.ย.",
    "shortMonths07": "ก.ค.",
    "shortMonths08": "ส.ค.",
    "shortMonths09": "ก.ย.",
    "shortMonths10": "ต.ค.",
    "shortMonths11": "พ.ย.",
    "shortMonths12": "ธ.ค.",
    "months01": "มกราคม",
    "months02": "กุมภาพันธ์",
    "months03": "มีนาคม",
    "months04": "เมษายน",
    "months05": "พฤษภาคม",
    "months06": "มิถุนายน",
    "months07": "กรกฎาคม",
    "months08": "สิงหาคม",
    "months09": "กันยายน",
    "months10": "ตุลาคม",
    "months11": "พฤศจิกายน",
    "months12": "ธันวาคม",
    "goToToday": "ไปวันที่ปัจจุบัน",
    "isRequiredErrorMessage": "กรุณาเลือกวันที่",
    "anotherOrganization": "เชื่อมต่อบริษัทอื่น",
    "authorised": "อนุมัติแล้ว",
    "paid": "จ่ายแล้ว",
    "draft": "ฉบับร่าง",
    "voided": "ถูกยกเลิก",
    "deleted": "ถูกลบแล้ว",
    "refresh": "รีเฟรช",
    "disconnect": "ยกเลิกการเชื่อมต่อ",
    "disconnectConfirm": "กรุณายืนยัน ยกเลิกการเชื่อมต่อ ระบบ Xero",
    "header": "รายการ Invoices จากระบบ Xero",
    "subHeader": "กรอกเลขที่เอกสารเริ่มต้น เพื่อทำการสร้างเอกสาร",
    "prefixNumber": "เลขที่เอกสารด้านหน้า (คงที่)",
    "prefixNumberDescription": "เช่น RE202201",
    "countingNumber": "ด้านหลัง (เพิ่มทีละ 1)",
    "countingNumberDesciption": "เช่น 00001",
    "countingNumberErrorMessage": "กรุณากรอกเลขที่เอกสาร",
    "startDocumentNumber": "เลขที่เอกสารเริ่มต้นที่",
    "endDocumentNumber": "เลขที่เอกสารต่อไป",
    "createDocument": "สร้างเอกสาร",
    "cancel": "ยกเลิก",
    "signProcessing": "กำลังสร้างเอกสารและลงลายเซ็นดิจิทัล...",
    "createdDocuments": "ดูเอกสารทั้งหมด",
    "search": "ค้นหา",
    "searchBoxPlaceholder": "เลขที่ Invoice",
    "create": "สร้าง",
    "createdBy": "กรอกข้อมูล ผู้รับเงิน/ผู้จัดทำ",
    "createdByDesc": "ข้อมูลจะแสดงในทุกเอกสารที่ถูกเลือก",
  },
  "xeroConnect": {
    "header": "เชื่อมต่อกับ Xero",
    "subHeader1": "เพื่อแสดงรายการ Invoices สำหรับสร้างเอกสาร e-Tax ใน ระบบ Leceipt",
    "subHeader2": "การเชื่อมต่อมีค่าบริการรายเดือน (ทดลองใช้งานฟรี)",
    "connect": "เชื่อมต่อ",
    "processing": "กำลังดำเนินการ...",
    "loading": "กำลังโหลด...",
    "successMessage": "เชื่อมต่อสำเร็จ!",
    "successButton": "ดูข้อมูล",
    "failMessage": "ไม่สำเร็จ!",
    "failButton": "ลองอีกครั้ง",
    "footer1": "ระบบจะเรียกรายการ Invoices มาแสดง สำหรับสร้างเอกสาร e-Tax Invoice & e-Receipt",
    "footer2": "แต่ไม่ได้บันทึกข้อมูลจาก ระบบ Xero ไว้ใน ระบบ Leceipt",
  },
  "dynamicBc": {
    "loading": "กำลังโหลด...",
    "page": "หน้า",
    "allDocuments": "เอกสารทั้งหมด",
    "documentDate": "วันที่บนเอกสาร",
    "number": "เลขที่",
    "customerName": "ชื่อลูกค้า",
    "customerAddress": "ที่อยู่ลูกค้า",
    "phone": "เบอร์โทร",
    "grandTotal": "ยอด (รวม VAT)",
    "pending": "รอดำเนินการ",
    "processing": "กำลังสร้างเอกสาร...",
    "success": "สำเร็จ",
    "fail": "ไม่สำเร็จ",
    "receipt-taxinvoice": "ใบเสร็จรับเงิน/ใบกำกับภาษี",
    "receipt-taxinvoice-abb": "ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ",
    "receipt": "ใบเสร็จรับเงิน",
    "invoice-taxinvoice": "ใบแจ้งหนี้/ใบกำกับภาษี",
    "deliveryorder-taxinvoice": "ใบส่งของ/ใบกำกับภาษี",
    "taxinvoice": "ใบกำกับภาษี",
    "thisMonth": "เดือนนี้",
    "shortMonths01": "ม.ค.",
    "shortMonths02": "ก.พ.",
    "shortMonths03": "มี.ค.",
    "shortMonths04": "เม.ย.",
    "shortMonths05": "พ.ค.",
    "shortMonths06": "มิ.ย.",
    "shortMonths07": "ก.ค.",
    "shortMonths08": "ส.ค.",
    "shortMonths09": "ก.ย.",
    "shortMonths10": "ต.ค.",
    "shortMonths11": "พ.ย.",
    "shortMonths12": "ธ.ค.",
    "months01": "มกราคม",
    "months02": "กุมภาพันธ์",
    "months03": "มีนาคม",
    "months04": "เมษายน",
    "months05": "พฤษภาคม",
    "months06": "มิถุนายน",
    "months07": "กรกฎาคม",
    "months08": "สิงหาคม",
    "months09": "กันยายน",
    "months10": "ตุลาคม",
    "months11": "พฤศจิกายน",
    "months12": "ธันวาคม",
    "goToToday": "ไปวันที่ปัจจุบัน",
    "isRequiredErrorMessage": "กรุณาเลือกวันที่",
    "anotherOrganization": "เชื่อมต่อบริษัทอื่น",
    "authorised": "อนุมัติแล้ว",
    "paid": "จ่ายแล้ว",
    "draft": "ฉบับร่าง",
    "voided": "ถูกยกเลิก",
    "deleted": "ถูกลบแล้ว",
    "refresh": "รีเฟรช",
    "disconnect": "ยกเลิกการเชื่อมต่อ",
    "disconnectConfirm": "กรุณายืนยัน ยกเลิกการเชื่อมต่อ ระบบ Microsoft Dynamics 365 Business Central",
    "header": "รายการ Invoices จากระบบ Microsoft Dynamics 365 Business Central",
    "subHeader": "กรอกเลขที่เอกสารเริ่มต้น เพื่อทำการสร้างเอกสาร",
    "prefixNumber": "เลขที่เอกสารด้านหน้า (คงที่)",
    "prefixNumberDescription": "เช่น RE202201",
    "countingNumber": "ด้านหลัง (เพิ่มทีละ 1)",
    "countingNumberDesciption": "เช่น 00001",
    "countingNumberErrorMessage": "กรุณากรอกเลขที่เอกสาร",
    "startDocumentNumber": "เลขที่เอกสารเริ่มต้นที่",
    "endDocumentNumber": "เลขที่เอกสารต่อไป",
    "createDocument": "สร้างเอกสาร",
    "cancel": "ยกเลิก",
    "signProcessing": "กำลังสร้างเอกสารและลงลายเซ็นดิจิทัล...",
    "createdDocuments": "ดูเอกสารทั้งหมด",
    "search": "ค้นหา",
    "searchBoxPlaceholder": "เลขที่ Invoice",
    "create": "สร้าง",
    "allType": "สถานะทั้งหมด",
    "paidType": "สถานะ Paid",
    "openType": "สถานะ Open",
    "draftType": "สถานะ Draft",
    "documentPerPage": "เอกสาร/หน้า",
    "vatTotal": "VAT",
    "createdBy": "กรอกข้อมูล ผู้รับเงิน/ผู้จัดทำ",
    "createdByDesc": "ข้อมูลจะแสดงในทุกเอกสารที่ถูกเลือก",
    "timeRange": "ช่วงเวลา",
    "fromRange": "จาก",
    "toRange": "ถึง",
    "timeRangeError": "กรุณาระบุช่วงเวลาไม่เกิน 30 วัน",
    "timeRangeError2": "กรุณาระบุช่วงเวลาไม่เกิน 15 วัน",
  },
  "dynamicBcConnect": {
    "header": "เชื่อมต่อกับ Microsoft Dynamics 365 Business Central",
    "subHeader1": "เพื่อแสดงรายการ Invoices สำหรับสร้างเอกสาร e-Tax ใน ระบบ Leceipt",
    "subHeader2": "การเชื่อมต่อมีค่าบริการรายเดือน (ทดลองใช้งานฟรี)",
    "connect": "เชื่อมต่อ",
    "processing": "กำลังดำเนินการ...",
    "loading": "กำลังโหลด...",
    "successMessage": "เชื่อมต่อสำเร็จ!",
    "successButton": "ดูข้อมูล",
    "failMessage": "ไม่สำเร็จ!",
    "failButton": "ลองอีกครั้ง",
    "footer1": "ระบบจะเรียกรายการ Invoices มาแสดง สำหรับสร้างเอกสาร e-Tax Invoice & e-Receipt",
    "footer2": "แต่ไม่ได้บันทึกข้อมูลจาก ระบบ Microsoft Dynamics 365 Business Central ไว้ใน ระบบ Leceipt",
  },
  "dynamicCrm": {
    "loading": "กำลังโหลด...",
    "page": "หน้า",
    "allDocuments": "เอกสารทั้งหมด",
    "documentDate": "วันที่บนเอกสาร",
    "number": "เลขที่",
    "customerName": "ชื่อ",
    "customerAddress": "ที่อยู่ลูกค้า",
    "phone": "เบอร์โทร",
    "grandTotal": "ยอด (รวม VAT)",
    "pending": "รอดำเนินการ",
    "processing": "กำลังสร้างเอกสาร...",
    "success": "สำเร็จ",
    "fail": "ไม่สำเร็จ",
    "receipt-taxinvoice": "ใบเสร็จรับเงิน/ใบกำกับภาษี",
    "receipt-taxinvoice-abb": "ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ",
    "receipt": "ใบเสร็จรับเงิน",
    "invoice-taxinvoice": "ใบแจ้งหนี้/ใบกำกับภาษี",
    "deliveryorder-taxinvoice": "ใบส่งของ/ใบกำกับภาษี",
    "taxinvoice": "ใบกำกับภาษี",
    "thisMonth": "เดือนนี้",
    "shortMonths01": "ม.ค.",
    "shortMonths02": "ก.พ.",
    "shortMonths03": "มี.ค.",
    "shortMonths04": "เม.ย.",
    "shortMonths05": "พ.ค.",
    "shortMonths06": "มิ.ย.",
    "shortMonths07": "ก.ค.",
    "shortMonths08": "ส.ค.",
    "shortMonths09": "ก.ย.",
    "shortMonths10": "ต.ค.",
    "shortMonths11": "พ.ย.",
    "shortMonths12": "ธ.ค.",
    "months01": "มกราคม",
    "months02": "กุมภาพันธ์",
    "months03": "มีนาคม",
    "months04": "เมษายน",
    "months05": "พฤษภาคม",
    "months06": "มิถุนายน",
    "months07": "กรกฎาคม",
    "months08": "สิงหาคม",
    "months09": "กันยายน",
    "months10": "ตุลาคม",
    "months11": "พฤศจิกายน",
    "months12": "ธันวาคม",
    "goToToday": "ไปวันที่ปัจจุบัน",
    "isRequiredErrorMessage": "กรุณาเลือกวันที่",
    "anotherOrganization": "เชื่อมต่อบริษัทอื่น",
    "authorised": "อนุมัติแล้ว",
    "paid": "จ่ายแล้ว",
    "draft": "ฉบับร่าง",
    "voided": "ถูกยกเลิก",
    "deleted": "ถูกลบแล้ว",
    "refresh": "รีเฟรช",
    "disconnect": "ยกเลิกการเชื่อมต่อ",
    "disconnectConfirm": "กรุณายืนยัน ยกเลิกการเชื่อมต่อ ระบบ Microsoft Dynamic 365 Sales (CRM)",
    "header": "รายการ Invoices จากระบบ Microsoft Dynamic 365 Sales (CRM)",
    "subHeader": "กรอกเลขที่เอกสารเริ่มต้น เพื่อทำการสร้างเอกสาร",
    "prefixNumber": "เลขที่เอกสารด้านหน้า (คงที่)",
    "prefixNumberDescription": "เช่น RE202201",
    "countingNumber": "ด้านหลัง (เพิ่มทีละ 1)",
    "countingNumberDesciption": "เช่น 00001",
    "countingNumberErrorMessage": "กรุณากรอกเลขที่เอกสาร",
    "startDocumentNumber": "เลขที่เอกสารเริ่มต้นที่",
    "endDocumentNumber": "เลขที่เอกสารต่อไป",
    "createDocument": "สร้างเอกสาร",
    "cancel": "ยกเลิก",
    "signProcessing": "กำลังสร้างเอกสารและลงลายเซ็นดิจิทัล...",
    "createdDocuments": "ดูเอกสารทั้งหมด",
    "search": "ค้นหา",
    "searchBoxPlaceholder": "เลขที่ Invoice, ชื่อ",
    "create": "สร้าง",
    "allType": "สถานะทั้งหมด",
    "paidType": "สถานะ Paid",
    "openType": "สถานะ Open",
    "draftType": "สถานะ Draft",
    "documentPerPage": "เอกสาร/หน้า",
    "vatTotal": "VAT",
    "createdBy": "กรอกข้อมูล ผู้รับเงิน/ผู้จัดทำ",
    "createdByDesc": "ข้อมูลจะแสดงในทุกเอกสารที่ถูกเลือก",
    "activeType": "สถานะ Active",
    "completeType": "สถานะ Complete",
    "partialType": "สถานะ Partial",
    "cancelType": "สถานะ Cancel",
    "createdTime": "วันที่สร้าง",
    "amountTotal": "ยอด (ไม่รวม VAT)",
  },
  "dynamicCrmConnect": {
    "header": "เชื่อมต่อกับ Microsoft Dynamics 365 Sales (CRM)",
    "subHeader1": "เพื่อแสดงรายการ Invoices สำหรับสร้างเอกสาร e-Tax ใน ระบบ Leceipt",
    "subHeader2": "การเชื่อมต่อมีค่าบริการรายเดือน (ทดลองใช้งานฟรี)",
    "connect": "เชื่อมต่อ",
    "processing": "กำลังดำเนินการ...",
    "loading": "กำลังโหลด...",
    "successMessage": "เชื่อมต่อสำเร็จ!",
    "successButton": "ดูข้อมูล",
    "failMessage": "ไม่สำเร็จ!",
    "failButton": "ลองอีกครั้ง",
    "footer1": "ระบบจะเรียกรายการ Invoices มาแสดง สำหรับสร้างเอกสาร e-Tax Invoice & e-Receipt",
    "footer2": "แต่ไม่ได้บันทึกข้อมูลจาก ระบบ Microsoft Dynamics 365 Sales (CRM) ไว้ใน ระบบ Leceipt",
    "resourceUrl": "ตัวอย่าง https://my-org.crm.dynamics.com",
  },
  "dynamicFo": {
    "loading": "กำลังโหลด...",
    "page": "หน้า",
    "allDocuments": "เอกสารทั้งหมด",
    "documentDate": "วันที่บนเอกสาร",
    "number": "เลขที่",
    "customerName": "ลูกค้า",
    "customerAddress": "ที่อยู่ลูกค้า",
    "phone": "เบอร์โทร",
    "grandTotal": "ยอด (รวม VAT)",
    "pending": "รอดำเนินการ",
    "processing": "กำลังสร้างเอกสาร...",
    "success": "สำเร็จ",
    "fail": "ไม่สำเร็จ",
    "receipt-taxinvoice": "ใบเสร็จรับเงิน/ใบกำกับภาษี",
    "receipt-taxinvoice-abb": "ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ",
    "receipt": "ใบเสร็จรับเงิน",
    "invoice-taxinvoice": "ใบแจ้งหนี้/ใบกำกับภาษี",
    "deliveryorder-taxinvoice": "ใบส่งของ/ใบกำกับภาษี",
    "taxinvoice": "ใบกำกับภาษี",
    "creditNote": "ใบลดหนี้",
    "thisMonth": "เดือนนี้",
    "shortMonths01": "ม.ค.",
    "shortMonths02": "ก.พ.",
    "shortMonths03": "มี.ค.",
    "shortMonths04": "เม.ย.",
    "shortMonths05": "พ.ค.",
    "shortMonths06": "มิ.ย.",
    "shortMonths07": "ก.ค.",
    "shortMonths08": "ส.ค.",
    "shortMonths09": "ก.ย.",
    "shortMonths10": "ต.ค.",
    "shortMonths11": "พ.ย.",
    "shortMonths12": "ธ.ค.",
    "months01": "มกราคม",
    "months02": "กุมภาพันธ์",
    "months03": "มีนาคม",
    "months04": "เมษายน",
    "months05": "พฤษภาคม",
    "months06": "มิถุนายน",
    "months07": "กรกฎาคม",
    "months08": "สิงหาคม",
    "months09": "กันยายน",
    "months10": "ตุลาคม",
    "months11": "พฤศจิกายน",
    "months12": "ธันวาคม",
    "goToToday": "ไปวันที่ปัจจุบัน",
    "isRequiredErrorMessage": "กรุณาเลือกวันที่",
    "anotherOrganization": "เชื่อมต่อบริษัทอื่น",
    "authorised": "อนุมัติแล้ว",
    "paid": "จ่ายแล้ว",
    "draft": "ฉบับร่าง",
    "voided": "ถูกยกเลิก",
    "deleted": "ถูกลบแล้ว",
    "refresh": "รีเฟรช",
    "disconnect": "ยกเลิกการเชื่อมต่อ",
    "disconnectConfirm": "กรุณายืนยัน ยกเลิกการเชื่อมต่อ ระบบ Microsoft Dynamics 365 Finance and Operations",
    "header": "รายการ Invoices จากระบบ Microsoft Dynamics 365 Finance and Operations",
    "subHeader": "กรอกเลขที่เอกสารเริ่มต้น เพื่อทำการสร้างเอกสาร",
    "prefixNumber": "เลขที่เอกสารด้านหน้า (คงที่)",
    "prefixNumberDescription": "เช่น RE202201",
    "countingNumber": "ด้านหลัง (เพิ่มทีละ 1)",
    "countingNumberDesciption": "เช่น 00001",
    "countingNumberErrorMessage": "กรุณากรอกเลขที่เอกสาร",
    "startDocumentNumber": "เลขที่เอกสารเริ่มต้นที่",
    "endDocumentNumber": "เลขที่เอกสารต่อไป",
    "createDocument": "สร้างเอกสาร",
    "cancel": "ยกเลิก",
    "signProcessing": "กำลังสร้างเอกสารและลงลายเซ็นดิจิทัล...",
    "createdDocuments": "ดูเอกสารทั้งหมด",
    "search": "ค้นหา",
    "searchBoxPlaceholder": "เลขที่ Invoice",
    "create": "สร้าง",
    "allType": "สถานะทั้งหมด",
    "paidType": "สถานะ Paid",
    "openType": "สถานะ Open",
    "draftType": "สถานะ Draft",
    "documentPerPage": "เอกสาร/หน้า",
    "vatTotal": "VAT",
    "createdBy": "กรอกข้อมูล ผู้รับเงิน/ผู้จัดทำ",
    "createdByDesc": "ข้อมูลจะแสดงในทุกเอกสารที่ถูกเลือก",
    "timeRange": "ช่วงเวลา",
    "fromRange": "จาก",
    "toRange": "ถึง",
    "timeRangeError": "กรุณาระบุช่วงเวลาไม่เกิน 30 วัน",
    "timeRangeError2": "กรุณาระบุช่วงเวลาไม่เกิน 15 วัน",
    "selectCompanyCoachMark": "เลือกบริษัทที่ต้องการได้ที่นี่",
    "journalNumber": "เลขที่สมุด",
    "createCreditNote": "สร้างใบลดหนี้",
    "creditNoteNumber": "เลขที่ใบลดหนี้",
    "referNumber": "เลขที่ใบกำกับภาษีเดิม",
  },
  "dynamicFoConnect": {
    "header": "เชื่อมต่อกับ Microsoft Dynamics 365 Finance and Operations",
    "subHeader1": "เพื่อแสดงรายการ Invoices สำหรับสร้างเอกสาร e-Tax ใน ระบบ Leceipt",
    "subHeader2": "การเชื่อมต่อมีค่าบริการรายเดือน (ทดลองใช้งานฟรี)",
    "connect": "เชื่อมต่อ",
    "processing": "กำลังดำเนินการ...",
    "loading": "กำลังโหลด...",
    "successMessage": "เชื่อมต่อสำเร็จ!",
    "successButton": "ดูข้อมูล",
    "failMessage": "ไม่สำเร็จ!",
    "failButton": "ลองอีกครั้ง",
    "footer1": "ระบบจะเรียกรายการ Invoices มาแสดง สำหรับสร้างเอกสาร e-Tax Invoice & e-Receipt",
    "footer2": "แต่ไม่ได้บันทึกข้อมูลจาก ระบบ Microsoft Dynamics 365 Finance and Operations ไว้ใน ระบบ Leceipt",
    "resourceUrl": "ตัวอย่าง https://my-org.operations.dynamics.com",
  },
  "jdCentral": {
    "loading": "กำลังโหลด...",
    "page": "หน้า",
    "allDocuments": "เอกสารทั้งหมด",
    "documentDate": "วันที่บนเอกสาร",
    "number": "เลขที่",
    "customerName": "ชื่อลูกค้า",
    "customerAddress": "ที่อยู่ลูกค้า",
    "phone": "เบอร์โทร",
    "grandTotal": "ยอด (รวม VAT)",
    "pending": "รอดำเนินการ",
    "processing": "กำลังสร้างเอกสาร...",
    "success": "สำเร็จ",
    "fail": "ไม่สำเร็จ",
    "receipt-taxinvoice": "ใบเสร็จรับเงิน/ใบกำกับภาษี",
    "receipt-taxinvoice-abb": "ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ",
    "receipt": "ใบเสร็จรับเงิน",
    "invoice-taxinvoice": "ใบแจ้งหนี้/ใบกำกับภาษี",
    "deliveryorder-taxinvoice": "ใบส่งของ/ใบกำกับภาษี",
    "taxinvoice": "ใบกำกับภาษี",
    "thisMonth": "เดือนนี้",
    "shortMonths01": "ม.ค.",
    "shortMonths02": "ก.พ.",
    "shortMonths03": "มี.ค.",
    "shortMonths04": "เม.ย.",
    "shortMonths05": "พ.ค.",
    "shortMonths06": "มิ.ย.",
    "shortMonths07": "ก.ค.",
    "shortMonths08": "ส.ค.",
    "shortMonths09": "ก.ย.",
    "shortMonths10": "ต.ค.",
    "shortMonths11": "พ.ย.",
    "shortMonths12": "ธ.ค.",
    "months01": "มกราคม",
    "months02": "กุมภาพันธ์",
    "months03": "มีนาคม",
    "months04": "เมษายน",
    "months05": "พฤษภาคม",
    "months06": "มิถุนายน",
    "months07": "กรกฎาคม",
    "months08": "สิงหาคม",
    "months09": "กันยายน",
    "months10": "ตุลาคม",
    "months11": "พฤศจิกายน",
    "months12": "ธันวาคม",
    "goToToday": "ไปวันที่ปัจจุบัน",
    "isRequiredErrorMessage": "กรุณาเลือกวันที่",
    "anotherOrganization": "เชื่อมต่อบริษัทอื่น",
    "authorised": "อนุมัติแล้ว",
    "paid": "จ่ายแล้ว",
    "draft": "ฉบับร่าง",
    "voided": "ถูกยกเลิก",
    "deleted": "ถูกลบแล้ว",
    "refresh": "รีเฟรช",
    "disconnect": "ยกเลิกการเชื่อมต่อ",
    "disconnectConfirm": "กรุณายืนยัน ยกเลิกการเชื่อมต่อ ระบบ JD Central",
    "header": "รายการออเดอร์ JD Central",
    "subHeader": "กรอกเลขที่เอกสารเริ่มต้น เพื่อทำการสร้างเอกสาร",
    "prefixNumber": "เลขที่เอกสารด้านหน้า (คงที่)",
    "prefixNumberDescription": "เช่น RE202201",
    "countingNumber": "ด้านหลัง (เพิ่มทีละ 1)",
    "countingNumberDesciption": "เช่น 00001",
    "countingNumberErrorMessage": "กรุณากรอกเลขที่เอกสาร",
    "startDocumentNumber": "เลขที่เอกสารเริ่มต้นที่",
    "endDocumentNumber": "เลขที่เอกสารต่อไป",
    "createDocument": "สร้างเอกสาร",
    "cancel": "ยกเลิก",
    "signProcessing": "กำลังสร้างเอกสารและลงลายเซ็นดิจิทัล...",
    "createdDocuments": "ดูเอกสารทั้งหมด",
    "search": "ค้นหา",
    "searchBoxPlaceholder": "เลขที่ออเดอร์ JD Central, ชื่อลูกค้า",
    "create": "สร้าง",
    "allType": "ออเดอร์ทั้งหมด",
    "unpaidType": "ยังไม่ชำระเงิน",
    "readyToship": "พร้อมส่ง",
    "shipping": "อยู่ระหว่างการขนส่ง",
    "shipped": "จัดส่งแล้ว",
    "paidType": "สถานะ Paid",
    "openType": "สถานะ Open",
    "draftType": "สถานะ Draft",
    "documentPerPage": "เอกสาร/หน้า",
    "vatTotal": "VAT",
    "createdBy": "กรอกข้อมูล ผู้รับเงิน/ผู้จัดทำ",
    "createdByDesc": "ข้อมูลจะแสดงในทุกเอกสารที่ถูกเลือก",
    "activeType": "สถานะ Active",
    "completeType": "สถานะ Complete",
    "partialType": "สถานะ Partial",
    "cancelType": "สถานะ Cancel",
    "createdTime": "วันที่สร้าง",
    "amountTotal": "ยอด (ไม่รวม VAT)",
    "orderId": "เลขที่",
    "payment": "การชำระเงิน",
    "orders": "จำนวนออเดอร์",
    "orderUnit": "ออเดอร์",
    "vat": "ภาษีขาย (Vat)",
    "thb": "บาท",
    "saleIncludeVat": "ยอดขาย (รวม Vat)",
    "sale": "ยอดขาย",
    "displayShipCost": "แสดงค่าขนส่งในเอกสาร",
    "on": "เปิด",
    "off": "ปิด",
  },
  "jdCentralConnect": {
    "header": "เชื่อมต่อกับ JD Central",
    "subHeader1": "เพื่อแสดงรายการ Orders สำหรับสร้างเอกสาร e-Tax ใน ระบบ Leceipt",
    "subHeader2": "การเชื่อมต่อมีค่าบริการรายเดือน (ทดลองใช้งานฟรี)",
    "connect": "เชื่อมต่อ",
    "processing": "กำลังดำเนินการ...",
    "loading": "กำลังโหลด...",
    "successMessage": "เชื่อมต่อสำเร็จ!",
    "successButton": "ดูข้อมูล",
    "failMessage": "ไม่สำเร็จ!",
    "failButton": "ลองอีกครั้ง",
    "footer1": "ระบบจะเรียกรายการ Orders มาแสดง สำหรับสร้างเอกสาร e-Tax Invoice & e-Receipt",
    "footer2": "แต่ไม่ได้บันทึกข้อมูลจาก ระบบ JD Central ไว้ใน ระบบ Leceipt",
  },
  "lazada": {
    "loading": "กำลังโหลด...",
    "page": "หน้า",
    "allDocuments": "เอกสารทั้งหมด",
    "documentDate": "วันที่บนเอกสาร",
    "number": "เลขที่",
    "customerName": "ชื่อลูกค้า",
    "customerAddress": "ที่อยู่ลูกค้า",
    "phone": "เบอร์โทร",
    "grandTotal": "ยอด (รวม VAT)",
    "pending": "รอดำเนินการ",
    "processing": "กำลังสร้างเอกสาร...",
    "success": "สำเร็จ",
    "fail": "ไม่สำเร็จ",
    "receipt-taxinvoice": "ใบเสร็จรับเงิน/ใบกำกับภาษี",
    "receipt-taxinvoice-abb": "ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ",
    "receipt": "ใบเสร็จรับเงิน",
    "invoice-taxinvoice": "ใบแจ้งหนี้/ใบกำกับภาษี",
    "deliveryorder-taxinvoice": "ใบส่งของ/ใบกำกับภาษี",
    "taxinvoice": "ใบกำกับภาษี",
    "thisMonth": "เดือนนี้",
    "shortMonths01": "ม.ค.",
    "shortMonths02": "ก.พ.",
    "shortMonths03": "มี.ค.",
    "shortMonths04": "เม.ย.",
    "shortMonths05": "พ.ค.",
    "shortMonths06": "มิ.ย.",
    "shortMonths07": "ก.ค.",
    "shortMonths08": "ส.ค.",
    "shortMonths09": "ก.ย.",
    "shortMonths10": "ต.ค.",
    "shortMonths11": "พ.ย.",
    "shortMonths12": "ธ.ค.",
    "months01": "มกราคม",
    "months02": "กุมภาพันธ์",
    "months03": "มีนาคม",
    "months04": "เมษายน",
    "months05": "พฤษภาคม",
    "months06": "มิถุนายน",
    "months07": "กรกฎาคม",
    "months08": "สิงหาคม",
    "months09": "กันยายน",
    "months10": "ตุลาคม",
    "months11": "พฤศจิกายน",
    "months12": "ธันวาคม",
    "goToToday": "ไปวันที่ปัจจุบัน",
    "isRequiredErrorMessage": "กรุณาเลือกวันที่",
    "anotherOrganization": "เชื่อมต่อบริษัทอื่น",
    "authorised": "อนุมัติแล้ว",
    "paid": "จ่ายแล้ว",
    "draft": "ฉบับร่าง",
    "voided": "ถูกยกเลิก",
    "deleted": "ถูกลบแล้ว",
    "refresh": "รีเฟรช",
    "disconnect": "ยกเลิกการเชื่อมต่อ",
    "disconnectConfirm": "กรุณายืนยัน ยกเลิกการเชื่อมต่อ ระบบ Lazada",
    "header": "รายการออเดอร์ Lazada",
    "subHeader": "กรอกเลขที่เอกสารเริ่มต้น เพื่อทำการสร้างเอกสาร",
    "prefixNumber": "เลขที่เอกสารด้านหน้า (คงที่)",
    "prefixNumberDescription": "เช่น RE202201",
    "countingNumber": "ด้านหลัง (เพิ่มทีละ 1)",
    "countingNumberDesciption": "เช่น 00001",
    "countingNumberErrorMessage": "กรุณากรอกเลขที่เอกสาร",
    "startDocumentNumber": "เลขที่เอกสารเริ่มต้นที่",
    "endDocumentNumber": "เลขที่เอกสารต่อไป",
    "createDocument": "สร้างเอกสาร",
    "cancel": "ยกเลิก",
    "signProcessing": "กำลังสร้างเอกสารและลงลายเซ็นดิจิทัล...",
    "createdDocuments": "ดูเอกสารทั้งหมด",
    "search": "ค้นหา",
    "searchBoxPlaceholder": "เลขที่ออเดอร์ Lazada",
    "create": "สร้าง",
    "allType": "ออเดอร์ทั้งหมด",
    "delivered": "จัดส่งสำเร็จ",
    "shipped": "อยู่ระหว่างการจัดส่ง",
    "readyToShip": "พร้อมส่ง",
    "unpaidType": "ยังไม่ชำระเงิน",
    "returned": "ตีกลับ",
    "shippedBack": "คืนสินค้า",
    "shipping": "อยู่ระหว่างการขนส่ง",
    "paidType": "สถานะ Paid",
    "openType": "สถานะ Open",
    "draftType": "สถานะ Draft",
    "documentPerPage": "ออเดอร์/หน้า",
    "vatTotal": "VAT",
    "createdBy": "กรอกข้อมูล ผู้รับเงิน/ผู้จัดทำ",
    "createdByDesc": "ข้อมูลจะแสดงในทุกออเดอร์ที่ถูกเลือก",
    "activeType": "สถานะ Active",
    "completeType": "สถานะ Complete",
    "partialType": "สถานะ Partial",
    "cancelType": "สถานะ Cancel",
    "createdTime": "วันที่สร้าง",
    "amountTotal": "ยอด (ไม่รวม VAT)",
    "orderId": "เลขที่",
    "payment": "การชำระเงิน",
    "orders": "จำนวนออเดอร์",
    "vat": "ภาษีขาย (Vat)",
    "thb": "บาท",
    "saleIncludeVat": "ยอดขาย (รวม Vat)",
    "sale": "ยอดขาย",
    "displayShipCost": "แสดงค่าขนส่งในเอกสาร",
    "on": "เปิด",
    "off": "ปิด",
    "total": "ยอดรวม",
    "orderUnit": "ออเดอร์",
    "month": "เดือน ",
    "automation": "สร้างเอกสารอัตโนมัติ",
    "onAutomationStatus": "ระบบอัตโนมัติทำงาน",
    "offAutomationStatus": "ระบบอัตโนมัติไม่ทำงาน",
    "awb": "ใบปะหน้า",
    "transactionList": "รายการธุรกรรม",
    "payoutList": "รายการจ่าย",
    "messageBarAWBFails": "ดาวน์โหลดใบปะหน้าไม่สำเร็จ กรุณาตรวจสอบออเดอร์ของคุณ",
    "isRequest": "ขอใบกำกับภาษี",
    "taxInvoiceNumber": "เลขที่เอกสาร",
    "reference": "เลขที่อ้างอิง",
    "duplicateTaxInvoiceHeader": "ระบบพบเลขที่เอกสารซ้ำกัน",
    "duplicateReferenceHeader": "ระบบพบเลขที่อ้างอิงซ้ำกัน",
    "duplicateTaxInvoiceList": "รายการเลขที่เอกสารที่ซ้ำ",
    "duplicateReferenceList": "รายการเลขที่อ้างอิงที่ซ้ำ",
    "createAt": "สร้างเมื่อ",
    "proceedMessage": "หากยืนยันที่จะสร้างเอกสาร ให้กดปุ่ม",
    "updateTime": "วันที่อัปเดตสถานะ",
    "createTime": "วันที่สร้างออเดอร์",
    "day1": "วันที่",
    "day2": "",
    "offsetError": "ระบบพบว่ามีการขอออเดอร์เกินลิมิตที่ Lazada กำหนดไว้ ขอแนะนำให้เลือกช่วงเวลาเป็นแบบรายวันแทน",
  },
  "lazadaConnect": {
    "header": "เชื่อมต่อกับ Lazada",
    "subHeader1": "เพื่อแสดงรายการ Orders สำหรับสร้างเอกสาร e-Tax ใน ระบบ Leceipt",
    "subHeader2": "การเชื่อมต่อมีค่าบริการรายเดือน (ทดลองใช้งานฟรี)",
    "connect": "เชื่อมต่อ",
    "processing": "กำลังดำเนินการ...",
    "loading": "กำลังโหลด...",
    "successMessage": "เชื่อมต่อสำเร็จ!",
    "successButton": "ดูข้อมูล",
    "failMessage": "ไม่สำเร็จ!",
    "failButton": "ลองอีกครั้ง",
    "footer1": "ระบบจะเรียกรายการ Orders มาแสดง สำหรับสร้างเอกสาร e-Tax Invoice & e-Receipt",
    "footer2": "แต่ไม่ได้บันทึกข้อมูลจาก ระบบ Lazada ไว้ใน ระบบ Leceipt",
  },
  "lazadaAutomation": {
    "triggerStatus": "สร้างเอกสารเมื่อสถานะออเดอร์เป็น",
    "receiptTaxInvoice": "ใบเสร็จรับเงิน/ใบกำกับภาษี เต็มรูปแบบ",
    "orderStatus": "สถานะออเดอร์",
    "number": "เลขที่",
    "prefix": "เลขด้านหน้า (คงที่)",
    "suffix": "ด้านหลัง (เพิ่มทีละ 1)",
    "year": "ปี",
    "month": "เดือน",
    "day": "วัน",
    "example": "ตัวอย่าง:",
    "showShippingFee": "แสดงค่าขนส่งในเอกสาร",
    "forceCreate": "หากลูกค้าไม่ขอใบกำกับภาษีเต็มรูปแบบ ให้ออกใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ",
    "receiptTaxInvoiceAbb": "ใบเสร็จรับเงิน/ใบกำกับภาษี อย่างย่อ",
    "autoSendEmail": "ส่งอีเมลให้ลูกค้าอัตโนมัติ",
    "onAutoSendEmail": "ระบบจะส่งเอกสารทางอีเมลให้ลูกค้าทันทีหลังจากสร้างเอกสาร แบบอัตโนมัติ",
    "offAutoSendEmail": "ระบบจะทำการสร้างเอกสารอัตโนมัติ แต่ไม่ส่งอีเมลให้ลูกค้า",
    "none": "ไม่มี",
    "createOnlyBillingAddress": "สร้างเอกสารเฉพาะออเดอร์ที่ขอใบกำกับภาษี",
    "receipt-taxinvoice": "ใบเสร็จรับเงิน/ใบกำกับภาษี",
    "receipt-taxinvoice-abb": "ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ",
    "on": "เปิด",
    "off": "ปิด",
    "onCreateOnlyBillingAddressNote": "สร้างเอกสารเฉพาะออเดอร์ที่ลูกค้ากรอกข้อมูลขอใบกำกับภาษี",
    "offCreateOnlyBillingAddressNote": "สร้างเอกสารทุกออเดอร์ (ใช้ที่อยู่จัดส่ง ถ้าลูกค้าไม่ได้กรอกข้อมูลขอใบกำกับภาษี)",
    "sendEmailOnlyBillingAddress": "ส่งอีเมลเฉพาะลูกค้าที่ขอใบกำกับภาษี",
    "cc": "สำเนาไปที่อีเมล",
    "unSavedMessage": "ข้อมูลยังไม่ได้ถูกบันทึก คุณต้องการออกจากหน้านี้หรือไม่",
    "emailSetting": "ตั้งค่าอีเมล",
  },
  "lazadaFinance": {
    "loading": "กำลังโหลด...",
    "page": "หน้า",
    "allDocuments": "เอกสารทั้งหมด",
    "documentDate": "วันที่บนเอกสาร",
    "number": "เลขที่",
    "customerName": "ชื่อลูกค้า",
    "customerAddress": "ที่อยู่ลูกค้า",
    "phone": "เบอร์โทร",
    "grandTotal": "ยอด (รวม VAT)",
    "pending": "รอดำเนินการ",
    "processing": "กำลังสร้างเอกสาร...",
    "success": "สำเร็จ",
    "fail": "ไม่สำเร็จ",
    "receipt-taxinvoice": "ใบเสร็จรับเงิน/ใบกำกับภาษี",
    "receipt-taxinvoice-abb": "ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ",
    "receipt": "ใบเสร็จรับเงิน",
    "invoice-taxinvoice": "ใบแจ้งหนี้/ใบกำกับภาษี",
    "deliveryorder-taxinvoice": "ใบส่งของ/ใบกำกับภาษี",
    "taxinvoice": "ใบกำกับภาษี",
    "thisMonth": "เดือนนี้",
    "shortMonths01": "ม.ค.",
    "shortMonths02": "ก.พ.",
    "shortMonths03": "มี.ค.",
    "shortMonths04": "เม.ย.",
    "shortMonths05": "พ.ค.",
    "shortMonths06": "มิ.ย.",
    "shortMonths07": "ก.ค.",
    "shortMonths08": "ส.ค.",
    "shortMonths09": "ก.ย.",
    "shortMonths10": "ต.ค.",
    "shortMonths11": "พ.ย.",
    "shortMonths12": "ธ.ค.",
    "months01": "มกราคม",
    "months02": "กุมภาพันธ์",
    "months03": "มีนาคม",
    "months04": "เมษายน",
    "months05": "พฤษภาคม",
    "months06": "มิถุนายน",
    "months07": "กรกฎาคม",
    "months08": "สิงหาคม",
    "months09": "กันยายน",
    "months10": "ตุลาคม",
    "months11": "พฤศจิกายน",
    "months12": "ธันวาคม",
    "goToToday": "ไปวันที่ปัจจุบัน",
    "isRequiredErrorMessage": "กรุณาเลือกวันที่",
    "anotherOrganization": "เชื่อมต่อบริษัทอื่น",
    "authorised": "อนุมัติแล้ว",
    "paid": "จ่ายแล้ว",
    "draft": "ฉบับร่าง",
    "voided": "ถูกยกเลิก",
    "deleted": "ถูกลบแล้ว",
    "refresh": "รีเฟรช",
    "disconnect": "ยกเลิกการเชื่อมต่อ",
    "disconnectConfirm": "กรุณายืนยัน ยกเลิกการเชื่อมต่อ ระบบ Lazada",
    "header": "รายการธุรกรรม Lazada",
    "subHeader": "กรอกเลขที่เอกสารเริ่มต้น เพื่อทำการสร้างเอกสาร",
    "prefixNumber": "เลขที่เอกสารด้านหน้า (คงที่)",
    "prefixNumberDescription": "เช่น RE202201",
    "countingNumber": "ด้านหลัง (เพิ่มทีละ 1)",
    "countingNumberDesciption": "เช่น 00001",
    "countingNumberErrorMessage": "กรุณากรอกเลขที่เอกสาร",
    "startDocumentNumber": "เลขที่เอกสารเริ่มต้นที่",
    "endDocumentNumber": "เลขที่เอกสารต่อไป",
    "createDocument": "สร้างเอกสาร",
    "cancel": "ยกเลิก",
    "signProcessing": "กำลังสร้างเอกสารและลงลายเซ็นดิจิทัล...",
    "createdDocuments": "ดูเอกสารทั้งหมด",
    "search": "ค้นหา",
    "searchBoxPlaceholder": "เลขที่ออเดอร์ Lazada",
    "create": "สร้าง",
    "allType": "ออเดอร์ทั้งหมด",
    "delivered": "จัดส่งสำเร็จ",
    "shipped": "อยู่ระหว่างการจัดส่ง",
    "readyToShip": "พร้อมส่ง",
    "unpaidType": "ยังไม่ชำระเงิน",
    "returned": "ตีกลับ",
    "shippedBack": "คืนสินค้า",
    "shipping": "อยู่ระหว่างการขนส่ง",
    "paidType": "สถานะ Paid",
    "openType": "สถานะ Open",
    "draftType": "สถานะ Draft",
    "documentPerPage": "ออเดอร์/หน้า",
    "vatTotal": "VAT",
    "createdBy": "กรอกข้อมูล ผู้รับเงิน/ผู้จัดทำ",
    "createdByDesc": "ข้อมูลจะแสดงในทุกออเดอร์ที่ถูกเลือก",
    "activeType": "สถานะ Active",
    "completeType": "สถานะ Complete",
    "partialType": "สถานะ Partial",
    "cancelType": "สถานะ Cancel",
    "createdTime": "วันที่สร้าง",
    "amountTotal": "ยอด (ไม่รวม VAT)",
    "orderId": "เลขที่",
    "payment": "การชำระเงิน",
    "orders": "จำนวนออเดอร์",
    "vat": "ภาษีขาย (Vat)",
    "thb": "บาท",
    "saleIncludeVat": "ยอดขาย (รวม Vat)",
    "sale": "ยอดขาย",
    "displayShipCost": "แสดงค่าขนส่งในเอกสาร",
    "on": "เปิด",
    "off": "ปิด",
    "total": "ยอดรวม",
    "orderUnit": "ออเดอร์",
    "month": "เดือน ",
    "automation": "สร้างเอกสารอัตโนมัติ",
    "onAutomationStatus": "ระบบอัตโนมัติทำงาน",
    "offAutomationStatus": "ระบบอัตโนมัติไม่ทำงาน",
    "transactionDate": "วันที่ทำรายการ",
    "transactionType": "ประเภทของธุรกรรม",
    "transactionNumber": "หมายเลขธุรกรรม",
    "orderNumber": "หมายเลขคำสั่งซื้อ",
    "orderItemID": "หมายเลขสินค้า",
    "itemName": "ชื่อสินค้า",
    "comment": "ความคิดเห็น",
    "amount": "จำนวน",
    "statementPeriod": "รอบบิล",
    "taxInvoiceNumber": "เลขใบกำกับภาษี",
    "shipmentProvider": "ชื่อขนส่ง",
  },
  "lazadaPayout": {
    "loading": "กำลังโหลด...",
    "page": "หน้า",
    "allDocuments": "เอกสารทั้งหมด",
    "documentDate": "วันที่บนเอกสาร",
    "number": "เลขที่",
    "customerName": "ชื่อลูกค้า",
    "customerAddress": "ที่อยู่ลูกค้า",
    "phone": "เบอร์โทร",
    "grandTotal": "ยอด (รวม VAT)",
    "pending": "รอดำเนินการ",
    "processing": "กำลังสร้างเอกสาร...",
    "success": "สำเร็จ",
    "fail": "ไม่สำเร็จ",
    "receipt-taxinvoice": "ใบเสร็จรับเงิน/ใบกำกับภาษี",
    "receipt-taxinvoice-abb": "ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ",
    "receipt": "ใบเสร็จรับเงิน",
    "invoice-taxinvoice": "ใบแจ้งหนี้/ใบกำกับภาษี",
    "deliveryorder-taxinvoice": "ใบส่งของ/ใบกำกับภาษี",
    "taxinvoice": "ใบกำกับภาษี",
    "thisMonth": "เดือนนี้",
    "shortMonths01": "ม.ค.",
    "shortMonths02": "ก.พ.",
    "shortMonths03": "มี.ค.",
    "shortMonths04": "เม.ย.",
    "shortMonths05": "พ.ค.",
    "shortMonths06": "มิ.ย.",
    "shortMonths07": "ก.ค.",
    "shortMonths08": "ส.ค.",
    "shortMonths09": "ก.ย.",
    "shortMonths10": "ต.ค.",
    "shortMonths11": "พ.ย.",
    "shortMonths12": "ธ.ค.",
    "months01": "มกราคม",
    "months02": "กุมภาพันธ์",
    "months03": "มีนาคม",
    "months04": "เมษายน",
    "months05": "พฤษภาคม",
    "months06": "มิถุนายน",
    "months07": "กรกฎาคม",
    "months08": "สิงหาคม",
    "months09": "กันยายน",
    "months10": "ตุลาคม",
    "months11": "พฤศจิกายน",
    "months12": "ธันวาคม",
    "goToToday": "ไปวันที่ปัจจุบัน",
    "isRequiredErrorMessage": "กรุณาเลือกวันที่",
    "anotherOrganization": "เชื่อมต่อบริษัทอื่น",
    "authorised": "อนุมัติแล้ว",
    "paid": "จ่ายแล้ว",
    "draft": "ฉบับร่าง",
    "voided": "ถูกยกเลิก",
    "deleted": "ถูกลบแล้ว",
    "refresh": "รีเฟรช",
    "disconnect": "ยกเลิกการเชื่อมต่อ",
    "disconnectConfirm": "กรุณายืนยัน ยกเลิกการเชื่อมต่อ ระบบ Lazada",
    "header": "รายการจ่าย Lazada",
    "subHeader": "กรอกเลขที่เอกสารเริ่มต้น เพื่อทำการสร้างเอกสาร",
    "prefixNumber": "เลขที่เอกสารด้านหน้า (คงที่)",
    "prefixNumberDescription": "เช่น RE202201",
    "countingNumber": "ด้านหลัง (เพิ่มทีละ 1)",
    "countingNumberDesciption": "เช่น 00001",
    "countingNumberErrorMessage": "กรุณากรอกเลขที่เอกสาร",
    "startDocumentNumber": "เลขที่เอกสารเริ่มต้นที่",
    "endDocumentNumber": "เลขที่เอกสารต่อไป",
    "createDocument": "สร้างเอกสาร",
    "cancel": "ยกเลิก",
    "signProcessing": "กำลังสร้างเอกสารและลงลายเซ็นดิจิทัล...",
    "createdDocuments": "ดูเอกสารทั้งหมด",
    "search": "ค้นหา",
    "searchBoxPlaceholder": "เลขที่ออเดอร์ Lazada",
    "create": "สร้าง",
    "allType": "ออเดอร์ทั้งหมด",
    "delivered": "จัดส่งสำเร็จ",
    "shipped": "อยู่ระหว่างการจัดส่ง",
    "readyToShip": "พร้อมส่ง",
    "unpaidType": "ยังไม่ชำระเงิน",
    "returned": "ตีกลับ",
    "shippedBack": "คืนสินค้า",
    "shipping": "อยู่ระหว่างการขนส่ง",
    "paidType": "สถานะ Paid",
    "openType": "สถานะ Open",
    "draftType": "สถานะ Draft",
    "documentPerPage": "ออเดอร์/หน้า",
    "vatTotal": "VAT",
    "createdBy": "กรอกข้อมูล ผู้รับเงิน/ผู้จัดทำ",
    "createdByDesc": "ข้อมูลจะแสดงในทุกออเดอร์ที่ถูกเลือก",
    "activeType": "สถานะ Active",
    "completeType": "สถานะ Complete",
    "partialType": "สถานะ Partial",
    "cancelType": "สถานะ Cancel",
    "createdTime": "วันที่สร้าง",
    "amountTotal": "ยอด (ไม่รวม VAT)",
    "orderId": "เลขที่",
    "payment": "การชำระเงิน",
    "orders": "จำนวนออเดอร์",
    "vat": "ภาษีขาย (Vat)",
    "thb": "บาท",
    "saleIncludeVat": "ยอดขาย (รวม Vat)",
    "sale": "ยอดขาย",
    "displayShipCost": "แสดงค่าขนส่งในเอกสาร",
    "on": "เปิด",
    "off": "ปิด",
    "total": "ยอดรวม",
    "orderUnit": "ออเดอร์",
    "month": "เดือน ",
    "automation": "สร้างเอกสารอัตโนมัติ",
    "onAutomationStatus": "ระบบอัตโนมัติทำงาน",
    "offAutomationStatus": "ระบบอัตโนมัติไม่ทำงาน",
    "transactionDate": "วันที่ทำรายการ",
    "transactionType": "ประเภทของธุรกรรม",
    "transactionNumber": "หมายเลขธุรกรรม",
    "orderNumber": "หมายเลขคำสั่งซื้อ",
    "orderItemID": "หมายเลขสินค้า",
    "itemName": "ชื่อสินค้า",
    "comment": "ความคิดเห็น",
    "amount": "จำนวน",
    "statementPeriod": "รอบบิล",
    "taxInvoiceNumber": "เลขใบกำกับภาษี",
    "statementNumber": "เลขที่",
    "itemRevenue": "ยอดสินค้า",
    "feesTotal": "ค่าธรรมเนียม",
    "shipmentFee": "ค่าธรรมเนียมค่าขนส่ง",
    "payout": "ยอดจ่าย",
    "createdAt": "วันที่สร้าง",
    "updatedAt": "วันที่อัพเดต",
  },
  "lineShopping": {
    "loading": "กำลังโหลด...",
    "page": "หน้า",
    "allDocuments": "เอกสารทั้งหมด",
    "documentDate": "วันที่บนเอกสาร",
    "number": "เลขที่",
    "customerName": "ชื่อลูกค้า",
    "customerAddress": "ที่อยู่ลูกค้า",
    "phone": "เบอร์โทร",
    "grandTotal": "ยอด (รวม VAT)",
    "pending": "รอดำเนินการ",
    "processing": "กำลังสร้างเอกสาร...",
    "success": "สำเร็จ",
    "fail": "ไม่สำเร็จ",
    "receipt-taxinvoice": "ใบเสร็จรับเงิน/ใบกำกับภาษี",
    "receipt-taxinvoice-abb": "ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ",
    "receipt": "ใบเสร็จรับเงิน",
    "invoice-taxinvoice": "ใบแจ้งหนี้/ใบกำกับภาษี",
    "deliveryorder-taxinvoice": "ใบส่งของ/ใบกำกับภาษี",
    "taxinvoice": "ใบกำกับภาษี",
    "thisMonth": "เดือนนี้",
    "shortMonths01": "ม.ค.",
    "shortMonths02": "ก.พ.",
    "shortMonths03": "มี.ค.",
    "shortMonths04": "เม.ย.",
    "shortMonths05": "พ.ค.",
    "shortMonths06": "มิ.ย.",
    "shortMonths07": "ก.ค.",
    "shortMonths08": "ส.ค.",
    "shortMonths09": "ก.ย.",
    "shortMonths10": "ต.ค.",
    "shortMonths11": "พ.ย.",
    "shortMonths12": "ธ.ค.",
    "months01": "มกราคม",
    "months02": "กุมภาพันธ์",
    "months03": "มีนาคม",
    "months04": "เมษายน",
    "months05": "พฤษภาคม",
    "months06": "มิถุนายน",
    "months07": "กรกฎาคม",
    "months08": "สิงหาคม",
    "months09": "กันยายน",
    "months10": "ตุลาคม",
    "months11": "พฤศจิกายน",
    "months12": "ธันวาคม",
    "goToToday": "ไปวันที่ปัจจุบัน",
    "isRequiredErrorMessage": "กรุณาเลือกวันที่",
    "anotherOrganization": "เชื่อมต่อร้านอื่น",
    "authorised": "อนุมัติแล้ว",
    "paid": "จ่ายแล้ว",
    "draft": "ฉบับร่าง",
    "voided": "ถูกยกเลิก",
    "deleted": "ถูกลบแล้ว",
    "refresh": "รีเฟรช",
    "disconnect": "ยกเลิกการเชื่อมต่อ",
    "disconnectConfirm": "กรุณายืนยัน ยกเลิกการเชื่อมต่อ LINE SHOPPING",
    "header": "รายการออเดอร์ LINE SHOPPING",
    "subHeader": "กรอกเลขที่เอกสารเริ่มต้น เพื่อทำการสร้างเอกสาร",
    "prefixNumber": "เลขที่เอกสารด้านหน้า (คงที่)",
    "prefixNumberDescription": "เช่น RE202201",
    "countingNumber": "ด้านหลัง (เพิ่มทีละ 1)",
    "countingNumberDesciption": "เช่น 00001",
    "countingNumberErrorMessage": "กรุณากรอกเลขที่เอกสาร",
    "startDocumentNumber": "เลขที่เอกสารเริ่มต้นที่",
    "endDocumentNumber": "เลขที่เอกสารต่อไป",
    "createDocument": "สร้างเอกสาร",
    "cancel": "ยกเลิก",
    "signProcessing": "กำลังสร้างเอกสารและลงลายเซ็นดิจิทัล...",
    "createdDocuments": "ดูเอกสารทั้งหมด",
    "search": "ค้นหา",
    "searchBoxPlaceholder": "เลขที่ออเดอร์",
    "create": "สร้าง",
    "allType": "ออเดอร์ทั้งหมด",
    "delivered": "จัดส่งสำเร็จ",
    "shipped": "อยู่ระหว่างการจัดส่ง",
    "readyToShip": "พร้อมส่ง",
    "unpaidType": "ยังไม่ชำระเงิน",
    "returned": "ตีกลับ",
    "shippedBack": "คืนสินค้า",
    "shipping": "อยู่ระหว่างการขนส่ง",
    "paidType": "สถานะ Paid",
    "openType": "สถานะ Open",
    "draftType": "สถานะ Draft",
    "documentPerPage": "ออเดอร์/หน้า",
    "vatTotal": "VAT",
    "createdBy": "กรอกข้อมูล ผู้รับเงิน/ผู้จัดทำ",
    "createdByDesc": "ข้อมูลจะแสดงในทุกออเดอร์ที่ถูกเลือก",
    "activeType": "สถานะ Active",
    "completeType": "สถานะ Complete",
    "partialType": "สถานะ Partial",
    "cancelType": "สถานะ Cancel",
    "createdTime": "วันที่สร้าง",
    "amountTotal": "ยอด (ไม่รวม VAT)",
    "orderId": "เลขที่",
    "payment": "การชำระเงิน",
    "orders": "จำนวนออเดอร์",
    "vat": "ภาษีขาย (Vat)",
    "thb": "บาท",
    "saleIncludeVat": "ยอดขาย (รวม Vat)",
    "sale": "ยอดขาย",
    "displayShipCost": "แสดงค่าขนส่งในเอกสาร",
    "on": "เปิด",
    "off": "ปิด",
    "total": "ยอดรวม",
    "orderUnit": "ออเดอร์",
    "month": "เดือน ",
    "finalized": "ยืนยันการสั่งซื้อ",
    "completed": "จัดส่งสำเร็จ",
    "expired": "หมดอายุ",
    "canceled": "ยกเลิก",
    "paymentMethod": "ช่องทางชำระเงิน",
    "allPaymentMethodType": "ช่องทางการชำระเงินทั้งหมด",
    "bank": "BANK",
    "cod": "COD",
    "creditAndDebitCard": "CREDIT/DEBIT CARD",
    "kPlus": "KPLUS",
    "scb": "SCB",
    "paymentStatus": "สถานะการจ่าย",
    "allPaymentStatus": "สถานะการชำระเงินทั้งหมด",
    "paidPaymentStatus": "ชำระแล้ว",
    "pendingPaymentStatus": "รอชำระ",
    "noPaymentPaymentStatus": "ไม่ชำระ",
    "refundPaymentStatus": "คืนเงิน",
    "pendingRefundPaymentStatus": "รอคืนเงิน",
    "failedRefundPaymentStatus": "คืนเงินล้มเหลว",
    "failedAfterPaidPaymentStatus": "ชำระล้มเหลว",
    "rejectedRefundPaymentStatus": "ปฎิเสธการคืนเงิน",
    "shipmentStatus": "สถานะการส่ง",
    "allShipmentStatus": "สถานะการจัดส่งทั้งหมด",
    "shippedAllShipmentStatus": "ส่งแล้วทั้งหมด",
    "onDeliveryShipmentStatus": "กำลังส่ง",
    "pendingShipmentStatus": "รอส่ง",
    "shipmentReadyShipmentStatus": "พร้อมส่ง",
    "noShipmentShipmentStatus": "ไม่ส่ง",
    "updatedDate": "วันที่อัปเดต",
    "selectNotCreatedItem": "เลือกเฉพาะออเดอร์ที่ยังไม่สร้าง",
  },
  "lineShoppingConnect": {
    "header": "เชื่อมต่อกับ LINE SHOPPING",
    "subHeader1": "เพื่อแสดงรายการ Orders สำหรับสร้างเอกสาร e-Tax ใน ระบบ Leceipt",
    "subHeader2": "การเชื่อมต่อมีค่าบริการรายเดือน (ทดลองใช้งานฟรี)",
    "connect": "เชื่อมต่อ",
    "processing": "กำลังดำเนินการ...",
    "loading": "กำลังโหลด...",
    "successMessage": "เชื่อมต่อสำเร็จ!",
    "successButton": "ดูข้อมูล",
    "failMessage": "ไม่สำเร็จ!",
    "failButton": "ลองอีกครั้ง",
    "footer1": "ระบบจะเรียกรายการ Orders มาแสดง สำหรับสร้างเอกสาร e-Tax Invoice & e-Receipt",
    "footer2": "แต่ไม่ได้บันทึกข้อมูลจาก ระบบ LINE SHOPPING ไว้ใน ระบบ Leceipt",
  },
  "paypal": {
    "allDocuments": "เอกสารทั้งหมด",
    "customerAddress": "ที่อยู่ลูกค้า",
    "phone": "เบอร์โทร",
    "thisMonth": "เดือนนี้",
    "shortMonths01": "ม.ค.",
    "shortMonths02": "ก.พ.",
    "shortMonths03": "มี.ค.",
    "shortMonths04": "เม.ย.",
    "shortMonths05": "พ.ค.",
    "shortMonths06": "มิ.ย.",
    "shortMonths07": "ก.ค.",
    "shortMonths08": "ส.ค.",
    "shortMonths09": "ก.ย.",
    "shortMonths10": "ต.ค.",
    "shortMonths11": "พ.ย.",
    "shortMonths12": "ธ.ค.",
    "months01": "มกราคม",
    "months02": "กุมภาพันธ์",
    "months03": "มีนาคม",
    "months04": "เมษายน",
    "months05": "พฤษภาคม",
    "months06": "มิถุนายน",
    "months07": "กรกฎาคม",
    "months08": "สิงหาคม",
    "months09": "กันยายน",
    "months10": "ตุลาคม",
    "months11": "พฤศจิกายน",
    "months12": "ธันวาคม",
    "goToToday": "ไปวันที่ปัจจุบัน",
    "isRequiredErrorMessage": "กรุณาเลือกวันที่",
    "anotherOrganization": "เชื่อมต่อบริษัทอื่น",
    "authorised": "อนุมัติแล้ว",
    "paid": "จ่ายแล้ว",
    "draft": "ฉบับร่าง",
    "voided": "ถูกยกเลิก",
    "deleted": "ถูกลบแล้ว",
    "refresh": "รีเฟรช",
    "disconnect": "ยกเลิกการเชื่อมต่อ",
    "subHeader": "กรอกเลขที่เอกสารเริ่มต้น เพื่อทำการสร้างเอกสาร",
    "prefixNumber": "เลขที่เอกสารด้านหน้า (คงที่)",
    "prefixNumberDescription": "เช่น RE202201",
    "countingNumber": "ด้านหลัง (เพิ่มทีละ 1)",
    "countingNumberDesciption": "เช่น 00001",
    "countingNumberErrorMessage": "กรุณากรอกเลขที่เอกสาร",
    "startDocumentNumber": "เลขที่เอกสารเริ่มต้นที่",
    "endDocumentNumber": "เลขที่เอกสารต่อไป",
    "allType": "สถานะทั้งหมด",
    "paidType": "สถานะ Paid",
    "openType": "สถานะ Open",
    "draftType": "สถานะ Draft",
    "documentPerPage": "เอกสาร/หน้า",
    "vatTotal": "VAT",
    "page": "หน้า",
    "pending": "รอดำเนินการ",
    "processing": "กำลังสร้างเอกสาร...",
    "success": "สำเร็จ",
    "fail": "ไม่สำเร็จ",
    "createdTime": "วันที่สร้าง",
    "number": "เลขที่",
    "documentDate": "วันที่บนเอกสาร",
    "customerName": "ชื่อลูกค้า",
    "customerEmail": "อีเมลลูกค้า",
    "grandTotal": "ยอด (รวม VAT)",
    "receipt-taxinvoice": "ใบเสร็จรับเงิน/ใบกำกับภาษี",
    "receipt-taxinvoice-abb": "ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ",
    "receipt": "ใบเสร็จรับเงิน",
    "invoice-taxinvoice": "ใบแจ้งหนี้/ใบกำกับภาษี",
    "deliveryorder-taxinvoice": "ใบส่งของ/ใบกำกับภาษี",
    "taxinvoice": "ใบกำกับภาษี",
    "create": "สร้าง",
    "disconnectConfirm": "กรุณายืนยัน ยกเลิกการเชื่อมต่อ ระบบ PayPal",
    "header": "รายการ Invoices จากระบบ PayPal",
    "loading": "กำลังโหลด...",
    "createdBy": "กรอกข้อมูล ผู้รับเงิน/ผู้จัดทำ",
    "createdByDesc": "ข้อมูลจะแสดงในทุกเอกสารที่ถูกเลือก",
    "createDocument": "สร้างเอกสาร",
    "cancel": "ยกเลิก",
    "signProcessing": "กำลังสร้างเอกสารและลงลายเซ็นดิจิทัล...",
    "createdDocuments": "ดูเอกสารทั้งหมด",
    "searchBoxPlaceholder": "เลขที่ Invoice",
    "search": "ค้นหา",
    "footer": "ยกเลิกการเชื่อมต่อกับ ระบบ PayPal",
    "processing2": "กำลังดำเนินการ...",
    "duplicateInvoiceNumber": "ขออภัย เลขที่ใบแจ้งหนี้ นี้ถูกใช้แล้ว"
  },
  "paypalConnect": {
    "header": "เชื่อมต่อกับ PayPal",
    "subHeader1": "เพื่อแสดงรายการ Invoices สำหรับสร้างเอกสาร e-Tax ใน ระบบ Leceipt",
    "subHeader2": "การเชื่อมต่อมีค่าบริการรายเดือน (ทดลองใช้งานฟรี)",
    "connect": "เชื่อมต่อ",
    "processing": "กำลังดำเนินการ...",
    "loading": "กำลังโหลด...",
    "successMessage": "เชื่อมต่อสำเร็จ!",
    "successButton": "ดูข้อมูล",
    "failMessage": "ไม่สำเร็จ!",
    "failButton": "ลองอีกครั้ง",
    "footer1": "ระบบจะเรียกรายการ Invoices มาแสดง สำหรับสร้างเอกสาร e-Tax Invoice & e-Receipt",
    "footer2": "แต่ไม่ได้บันทึกข้อมูลจาก ระบบ PayPal ไว้ใน ระบบ Leceipt",
  },
  "stripe": {
    "allDocuments": "เอกสารทั้งหมด",
    "customerAddress": "ที่อยู่ลูกค้า",
    "phone": "เบอร์โทร",
    "thisMonth": "เดือนนี้",
    "shortMonths01": "ม.ค.",
    "shortMonths02": "ก.พ.",
    "shortMonths03": "มี.ค.",
    "shortMonths04": "เม.ย.",
    "shortMonths05": "พ.ค.",
    "shortMonths06": "มิ.ย.",
    "shortMonths07": "ก.ค.",
    "shortMonths08": "ส.ค.",
    "shortMonths09": "ก.ย.",
    "shortMonths10": "ต.ค.",
    "shortMonths11": "พ.ย.",
    "shortMonths12": "ธ.ค.",
    "months01": "มกราคม",
    "months02": "กุมภาพันธ์",
    "months03": "มีนาคม",
    "months04": "เมษายน",
    "months05": "พฤษภาคม",
    "months06": "มิถุนายน",
    "months07": "กรกฎาคม",
    "months08": "สิงหาคม",
    "months09": "กันยายน",
    "months10": "ตุลาคม",
    "months11": "พฤศจิกายน",
    "months12": "ธันวาคม",
    "goToToday": "ไปวันที่ปัจจุบัน",
    "isRequiredErrorMessage": "กรุณาเลือกวันที่",
    "anotherOrganization": "เชื่อมต่อบริษัทอื่น",
    "authorised": "อนุมัติแล้ว",
    "paid": "จ่ายแล้ว",
    "draft": "ฉบับร่าง",
    "voided": "ถูกยกเลิก",
    "deleted": "ถูกลบแล้ว",
    "refresh": "รีเฟรช",
    "disconnect": "ยกเลิกการเชื่อมต่อ",
    "subHeader": "กรอกเลขที่เอกสารเริ่มต้น เพื่อทำการสร้างเอกสาร",
    "prefixNumber": "เลขที่เอกสารด้านหน้า (คงที่)",
    "prefixNumberDescription": "เช่น RE202201",
    "countingNumber": "ด้านหลัง (เพิ่มทีละ 1)",
    "countingNumberDesciption": "เช่น 00001",
    "countingNumberErrorMessage": "กรุณากรอกเลขที่เอกสาร",
    "startDocumentNumber": "เลขที่เอกสารเริ่มต้นที่",
    "endDocumentNumber": "เลขที่เอกสารต่อไป",
    "allType": "สถานะทั้งหมด",
    "paidType": "สถานะ Paid",
    "openType": "สถานะ Open",
    "draftType": "สถานะ Draft",
    "documentPerPage": "เอกสาร/หน้า",
    "vatTotal": "VAT",
    "page": "หน้า",
    "pending": "รอดำเนินการ",
    "processing": "กำลังสร้างเอกสาร...",
    "success": "สำเร็จ",
    "fail": "ไม่สำเร็จ",
    "createdTime": "วันที่สร้าง",
    "number": "เลขที่",
    "documentDate": "วันที่บนเอกสาร",
    "customerName": "ชื่อลูกค้า",
    "customerEmail": "อีเมลลูกค้า",
    "grandTotal": "ยอด (รวม VAT)",
    "receipt-taxinvoice": "ใบเสร็จรับเงิน/ใบกำกับภาษี",
    "receipt-taxinvoice-abb": "ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ",
    "receipt": "ใบเสร็จรับเงิน",
    "invoice-taxinvoice": "ใบแจ้งหนี้/ใบกำกับภาษี",
    "deliveryorder-taxinvoice": "ใบส่งของ/ใบกำกับภาษี",
    "taxinvoice": "ใบกำกับภาษี",
    "create": "สร้าง",
    "disconnectConfirm": "กรุณายืนยัน ยกเลิกการเชื่อมต่อ ระบบ Stripe",
    "header": "รายการ Invoices จากระบบ Stripe",
    "loading": "กำลังโหลด...",
    "createdBy": "กรอกข้อมูล ผู้รับเงิน/ผู้จัดทำ",
    "createdByDesc": "ข้อมูลจะแสดงในทุกเอกสารที่ถูกเลือก",
    "createDocument": "สร้างเอกสาร",
    "cancel": "ยกเลิก",
    "signProcessing": "กำลังสร้างเอกสารและลงลายเซ็นดิจิทัล...",
    "createdDocuments": "ดูเอกสารทั้งหมด",
    "searchBoxPlaceholder": "เลขที่ Invoice",
    "search": "ค้นหา",
    "footer": "ยกเลิกการเชื่อมต่อกับ ระบบ Stripe",
    "processing2": "กำลังดำเนินการ...",
  },
  "stripeConnect": {
    "header": "เชื่อมต่อกับ Stripe",
    "subHeader1": "เพื่อแสดงรายการ Invoices สำหรับสร้างเอกสาร e-Tax ใน ระบบ Leceipt",
    "connect": "เชื่อมต่อ",
    "processing": "กำลังดำเนินการ...",
    "loading": "กำลังโหลด...",
    "successMessage": "เชื่อมต่อสำเร็จ!",
    "successButton": "ดูข้อมูล",
    "failMessage": "ไม่สำเร็จ!",
    "failButton": "ลองอีกครั้ง",
    "footer1": "ระบบจะเรียกรายการ Invoices มาแสดง สำหรับสร้างเอกสาร e-Tax Invoice & e-Receipt",
    "footer2": "แต่ไม่ได้บันทึกข้อมูลจาก ระบบ Stripe ไว้ใน ระบบ Leceipt",
  },
  "peak": {
    "page": "หน้า",
    "allDocuments": "เอกสารทั้งหมด",
    "thisMonth": "เดือนนี้",
    "shortMonths01": "ม.ค.",
    "shortMonths02": "ก.พ.",
    "shortMonths03": "มี.ค.",
    "shortMonths04": "เม.ย.",
    "shortMonths05": "พ.ค.",
    "shortMonths06": "มิ.ย.",
    "shortMonths07": "ก.ค.",
    "shortMonths08": "ส.ค.",
    "shortMonths09": "ก.ย.",
    "shortMonths10": "ต.ค.",
    "shortMonths11": "พ.ย.",
    "shortMonths12": "ธ.ค.",
    "months01": "มกราคม",
    "months02": "กุมภาพันธ์",
    "months03": "มีนาคม",
    "months04": "เมษายน",
    "months05": "พฤษภาคม",
    "months06": "มิถุนายน",
    "months07": "กรกฎาคม",
    "months08": "สิงหาคม",
    "months09": "กันยายน",
    "months10": "ตุลาคม",
    "months11": "พฤศจิกายน",
    "months12": "ธันวาคม",
    "goToToday": "ไปวันที่ปัจจุบัน",
    "isRequiredErrorMessage": "กรุณาเลือกวันที่",
    "anotherOrganization": "เชื่อมต่อบริษัทอื่น",
    "authorised": "อนุมัติแล้ว",
    "paid": "จ่ายแล้ว",
    "draft": "ฉบับร่าง",
    "voided": "ถูกยกเลิก",
    "deleted": "ถูกลบแล้ว",
    "subHeader": "กรอกเลขที่เอกสารเริ่มต้น เพื่อทำการสร้างเอกสาร",
    "prefixNumber": "เลขที่เอกสารด้านหน้า (คงที่)",
    "prefixNumberDescription": "เช่น RE202201",
    "countingNumber": "ด้านหลัง (เพิ่มทีละ 1)",
    "countingNumberDesciption": "เช่น 00001",
    "countingNumberErrorMessage": "กรุณากรอกเลขที่เอกสาร",
    "startDocumentNumber": "เลขที่เอกสารเริ่มต้นที่",
    "endDocumentNumber": "เลขที่เอกสารต่อไป",
    "createDocument": "สร้างเอกสาร",
    "createdDocuments": "ดูเอกสารทั้งหมด",
    "search": "ค้นหา",
    "searchBoxPlaceholder": "เลขที่ Invoice",
    "create": "สร้าง",
    "openType": "สถานะ Open",
    "vatTotal": "VAT",
    "createdBy": "กรอกข้อมูล ผู้รับเงิน/ผู้จัดทำ",
    "createdByDesc": "ข้อมูลจะแสดงในทุกเอกสารที่ถูกเลือก",
    "thb": "บาท",
    "allType": "สถานะทั้งหมด",
    "draftType": "ร่าง",
    "pendingApproveType": "รออนุมัติ",
    "pendingPaymentType": "รอรับชำระ",
    "overdueType": "เกินเวลารับชำระ",
    "paidType": "รับชำระแล้ว",
    "paidType2": "จ่ายชำระ",
    "voidType": "ถังขยะ",
    "receipt-taxinvoice": "ใบเสร็จรับเงิน/ใบกำกับภาษี",
    "receipt-taxinvoice-abb": "ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ",
    "receipt": "ใบเสร็จรับเงิน",
    "invoice-taxinvoice": "ใบแจ้งหนี้/ใบกำกับภาษี",
    "deliveryorder-taxinvoice": "ใบส่งของ/ใบกำกับภาษี",
    "taxinvoice": "ใบกำกับภาษี",
    "number": "เลขที่",
    "documentDate": "วันที่บนเอกสาร",
    "customerName": "ชื่อลูกค้า",
    "customerAddress": "ที่อยู่ลูกค้า",
    "phone": "เบอร์โทร",
    "grandTotal": "ยอด (รวม VAT)",
    "view": "ดู",
    "pending": "รอดำเนินการ",
    "processing": "กำลังสร้างเอกสาร...",
    "success": "สำเร็จ",
    "fail": "ไม่สำเร็จ",
    "createEtax": "สร้างเอกสาร e-Tax",
    "documentPerPage": "เอกสาร/หน้า",
    "refresh": "รีเฟรช",
    "disconnect": "ยกเลิกการเชื่อมต่อ",
    "disconnectConfirm": "กรุณายืนยัน ยกเลิกการเชื่อมต่อ ระบบ Peak",
    "header": "รายการเอกสาร PEAK Account",
    "documents": "จำนวนเอกสาร",
    "documentsUnit": "เอกสาร",
    "cancel": "ยกเลิก",
    "signProcessing": "กำลังสร้างเอกสารและลงลายเซ็นดิจิทัล...",
    "viewAllEtax": "ดูเอกสาร e-Tax ทั้งหมด",
    "loading": "กำลังโหลด...",
    "timeRange": "ช่วงเวลา",
    "fromRange": "จาก",
    "toRange": "ถึง",
    "timeRangeError": "กรุณาระบุช่วงเวลาไม่เกิน 30 วัน",
    "oldPeak": "PEAK เก่า",
    "newPeak": "PEAK ใหม่",
  },
  "peakConnect": {
    "header": "เชื่อมต่อกับ PEAK Account",
    "subHeader1": "เพื่อแสดงรายการเอกสาร สำหรับสร้างเอกสาร e-Tax ใน ระบบ Leceipt",
    "subHeader2": "การเชื่อมต่อมีค่าบริการรายเดือน (ทดลองใช้งานฟรี)",
    "connect": "เชื่อมต่อ",
    "processing": "กำลังดำเนินการ...",
    "loading": "กำลังโหลด...",
    "successMessage": "เชื่อมต่อสำเร็จ!",
    "successButton": "ดูข้อมูล",
    "failMessage": "ไม่สำเร็จ!",
    "failButton": "ลองอีกครั้ง",
    "footer1": "ระบบจะเรียกรายการเอกสารมาแสดง สำหรับสร้างเอกสาร e-Tax Invoice & e-Receipt",
    "footer2": "แต่ไม่ได้บันทึกข้อมูลจาก ระบบ PEAK ไว้ใน ระบบ Leceipt",
    "plsContact": "กรุณาติดต่อ PEAK เพื่อขอรายละเอียดการเชื่อมต่อข้างล่างนี้",
  },
  "quickBook": {
    "page": "หน้า",
    "allDocuments": "เอกสารทั้งหมด",
    "thisMonth": "เดือนนี้",
    "shortMonths01": "ม.ค.",
    "shortMonths02": "ก.พ.",
    "shortMonths03": "มี.ค.",
    "shortMonths04": "เม.ย.",
    "shortMonths05": "พ.ค.",
    "shortMonths06": "มิ.ย.",
    "shortMonths07": "ก.ค.",
    "shortMonths08": "ส.ค.",
    "shortMonths09": "ก.ย.",
    "shortMonths10": "ต.ค.",
    "shortMonths11": "พ.ย.",
    "shortMonths12": "ธ.ค.",
    "months01": "มกราคม",
    "months02": "กุมภาพันธ์",
    "months03": "มีนาคม",
    "months04": "เมษายน",
    "months05": "พฤษภาคม",
    "months06": "มิถุนายน",
    "months07": "กรกฎาคม",
    "months08": "สิงหาคม",
    "months09": "กันยายน",
    "months10": "ตุลาคม",
    "months11": "พฤศจิกายน",
    "months12": "ธันวาคม",
    "goToToday": "ไปวันที่ปัจจุบัน",
    "isRequiredErrorMessage": "กรุณาเลือกวันที่",
    "anotherOrganization": "เชื่อมต่อบริษัทอื่น",
    "authorised": "อนุมัติแล้ว",
    "paid": "จ่ายแล้ว",
    "draft": "ฉบับร่าง",
    "voided": "ถูกยกเลิก",
    "deleted": "ถูกลบแล้ว",
    "subHeader": "กรอกเลขที่เอกสารเริ่มต้น เพื่อทำการสร้างเอกสาร",
    "prefixNumber": "เลขที่เอกสารด้านหน้า (คงที่)",
    "prefixNumberDescription": "เช่น RE202201",
    "countingNumber": "ด้านหลัง (เพิ่มทีละ 1)",
    "countingNumberDesciption": "เช่น 00001",
    "countingNumberErrorMessage": "กรุณากรอกเลขที่เอกสาร",
    "startDocumentNumber": "เลขที่เอกสารเริ่มต้นที่",
    "endDocumentNumber": "เลขที่เอกสารต่อไป",
    "create": "สร้าง",
    "openType": "สถานะ Open",
    "createdBy": "กรอกข้อมูล ผู้รับเงิน/ผู้จัดทำ",
    "createdByDesc": "ข้อมูลจะแสดงในทุกเอกสารที่ถูกเลือก",
    "allType": "สถานะทั้งหมด",
    "draftType": "ร่าง",
    "pendingApproveType": "รออนุมัติ",
    "pendingPaymentType": "รอรับชำระ",
    "overdueType": "เกินเวลารับชำระ",
    "paidType": "รับชำระแล้ว",
    "paidType2": "จ่ายชำระ",
    "voidType": "ถังขยะ",
    "receipt-taxinvoice": "ใบเสร็จรับเงิน/ใบกำกับภาษี",
    "receipt-taxinvoice-abb": "ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ",
    "receipt": "ใบเสร็จรับเงิน",
    "invoice-taxinvoice": "ใบแจ้งหนี้/ใบกำกับภาษี",
    "deliveryorder-taxinvoice": "ใบส่งของ/ใบกำกับภาษี",
    "taxinvoice": "ใบกำกับภาษี",
    "number": "เลขที่",
    "documentDate": "วันที่บนเอกสาร",
    "customerName": "ชื่อลูกค้า",
    "customerAddress": "ที่อยู่ลูกค้า",
    "phone": "เบอร์โทร",
    "grandTotal": "ยอด (รวม VAT)",
    "view": "ดู",
    "pending": "รอดำเนินการ",
    "processing": "กำลังสร้างเอกสาร...",
    "success": "สำเร็จ",
    "fail": "ไม่สำเร็จ",
    "createEtax": "สร้างเอกสาร e-Tax",
    "documentPerPage": "เอกสาร/หน้า",
    "refresh": "รีเฟรช",
    "disconnect": "ยกเลิกการเชื่อมต่อ",
    "disconnectConfirm": "กรุณายืนยัน ยกเลิกการเชื่อมต่อ ระบบ QuickBooks",
    "header": "รายการ ",
    "header2": " จากระบบ QuickBooks",
    "documents": "จำนวนเอกสาร",
    "documentsUnit": "เอกสาร",
    "cancel": "ยกเลิก",
    "signProcessing": "กำลังสร้างเอกสารและลงลายเซ็นดิจิทัล...",
    "viewAllEtax": "ดูเอกสาร e-Tax ทั้งหมด",
    "loading": "กำลังโหลด...",
    "createdTime": "วันที่สร้าง",
    "vatTotal": "VAT",
    "createDocument": "สร้างเอกสาร",
    "createdDocuments": "ดูเอกสารทั้งหมด",
    "search": "ค้นหา",
    "searchBoxPlaceholder": "เลขที่ ",
    "searchBoxPlaceholder2": "",
  },
  "quickBookConnect": {
    "header": "เชื่อมต่อกับ QuickBooks",
    "subHeader1": "เพื่อแสดงรายการ Invoices สำหรับสร้างเอกสาร e-Tax ใน ระบบ Leceipt",
    "subHeader2": "การเชื่อมต่อมีค่าบริการรายเดือน (ทดลองใช้งานฟรี)",
    "connect": "เชื่อมต่อ",
    "processing": "กำลังดำเนินการ...",
    "loading": "กำลังโหลด...",
    "successMessage": "เชื่อมต่อสำเร็จ!",
    "successButton": "ดูข้อมูล",
    "failMessage": "ไม่สำเร็จ!",
    "failButton": "ลองอีกครั้ง",
    "footer1": "ระบบจะเรียกรายการ Invoices มาแสดง สำหรับสร้างเอกสาร e-Tax Invoice & e-Receipt",
    "footer2": "แต่ไม่ได้บันทึกข้อมูลจาก ระบบ QuickBooks ไว้ใน ระบบ Leceipt",
  },
  "sapB1": {
    "page": "หน้า",
    "allDocuments": "เอกสารทั้งหมด",
    "thisMonth": "เดือนนี้",
    "shortMonths01": "ม.ค.",
    "shortMonths02": "ก.พ.",
    "shortMonths03": "มี.ค.",
    "shortMonths04": "เม.ย.",
    "shortMonths05": "พ.ค.",
    "shortMonths06": "มิ.ย.",
    "shortMonths07": "ก.ค.",
    "shortMonths08": "ส.ค.",
    "shortMonths09": "ก.ย.",
    "shortMonths10": "ต.ค.",
    "shortMonths11": "พ.ย.",
    "shortMonths12": "ธ.ค.",
    "months01": "มกราคม",
    "months02": "กุมภาพันธ์",
    "months03": "มีนาคม",
    "months04": "เมษายน",
    "months05": "พฤษภาคม",
    "months06": "มิถุนายน",
    "months07": "กรกฎาคม",
    "months08": "สิงหาคม",
    "months09": "กันยายน",
    "months10": "ตุลาคม",
    "months11": "พฤศจิกายน",
    "months12": "ธันวาคม",
    "goToToday": "ไปวันที่ปัจจุบัน",
    "isRequiredErrorMessage": "กรุณาเลือกวันที่",
    "anotherOrganization": "เชื่อมต่อบริษัทอื่น",
    "authorised": "อนุมัติแล้ว",
    "paid": "จ่ายแล้ว",
    "draft": "ฉบับร่าง",
    "voided": "ถูกยกเลิก",
    "deleted": "ถูกลบแล้ว",
    "subHeader": "กรอกเลขที่เอกสารเริ่มต้น เพื่อทำการสร้างเอกสาร",
    "prefixNumber": "เลขที่เอกสารด้านหน้า (คงที่)",
    "prefixNumberDescription": "เช่น RE202201",
    "countingNumber": "ด้านหลัง (เพิ่มทีละ 1)",
    "countingNumberDesciption": "เช่น 00001",
    "countingNumberErrorMessage": "กรุณากรอกเลขที่เอกสาร",
    "startDocumentNumber": "เลขที่เอกสารเริ่มต้นที่",
    "endDocumentNumber": "เลขที่เอกสารต่อไป",
    "create": "สร้าง",
    "createdBy": "กรอกข้อมูล ผู้รับเงิน/ผู้จัดทำ",
    "createdByDesc": "ข้อมูลจะแสดงในทุกเอกสารที่ถูกเลือก",
    "allType": "สถานะทั้งหมด",
    "draftType": "ร่าง",
    "pendingApproveType": "รออนุมัติ",
    "pendingPaymentType": "รอรับชำระ",
    "overdueType": "เกินเวลารับชำระ",
    "voidType": "ถังขยะ",
    "receipt-taxinvoice": "ใบเสร็จรับเงิน/ใบกำกับภาษี",
    "receipt-taxinvoice-abb": "ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ",
    "receipt": "ใบเสร็จรับเงิน",
    "invoice-taxinvoice": "ใบแจ้งหนี้/ใบกำกับภาษี",
    "deliveryorder-taxinvoice": "ใบส่งของ/ใบกำกับภาษี",
    "taxinvoice": "ใบกำกับภาษี",
    "number": "เลขที่",
    "documentDate": "วันที่บนเอกสาร",
    "customerName": "ชื่อลูกค้า",
    "customerAddress": "ที่อยู่ลูกค้า",
    "phone": "เบอร์โทร",
    "grandTotal": "ยอด (รวม VAT)",
    "view": "ดู",
    "pending": "รอดำเนินการ",
    "processing": "กำลังสร้างเอกสาร...",
    "success": "สำเร็จ",
    "fail": "ไม่สำเร็จ",
    "createEtax": "สร้างเอกสาร e-Tax",
    "documentPerPage": "เอกสาร/หน้า",
    "refresh": "รีเฟรช",
    "disconnect": "ยกเลิกการเชื่อมต่อ",
    "disconnectConfirm": "กรุณายืนยัน ยกเลิกการเชื่อมต่อ ระบบ SAP Business One",
    "header": "รายการ Invoices จากระบบ SAP Business One",
    "documents": "จำนวนเอกสาร",
    "documentsUnit": "เอกสาร",
    "cancel": "ยกเลิก",
    "signProcessing": "กำลังสร้างเอกสารและลงลายเซ็นดิจิทัล...",
    "viewAllEtax": "ดูเอกสาร e-Tax ทั้งหมด",
    "loading": "กำลังโหลด...",
    "createdTime": "วันที่สร้าง",
    "vatTotal": "VAT",
    "createDocument": "สร้างเอกสาร",
    "createdDocuments": "ดูเอกสารทั้งหมด",
    "search": "ค้นหา",
    "searchBoxPlaceholder": "เลขที่ Invoice",
    "paidType": "สถานะ Paid",
    "deliveredType": "สถานะ Delivered",
    "openType": "สถานะ Open",
    "closeType": "สถานะ Close",
    "taxId": "เลขประจำตัวผู้เสียภาษี",
  },
  "sapB1Connect": {
    "header": "เชื่อมต่อกับ SAP Business One",
    "subHeader1": "เพื่อแสดงรายการ Invoices สำหรับสร้างเอกสาร e-Tax ใน ระบบ Leceipt",
    "subHeader2": "การเชื่อมต่อมีค่าบริการรายเดือน (ทดลองใช้งานฟรี)",
    "connect": "เชื่อมต่อ",
    "processing": "กำลังดำเนินการ...",
    "loading": "กำลังโหลด...",
    "successMessage": "เชื่อมต่อสำเร็จ!",
    "successButton": "ดูข้อมูล",
    "failMessage": "ไม่สำเร็จ!",
    "failButton": "ลองอีกครั้ง",
    "footer1": "ระบบจะเรียกรายการ Invoices มาแสดง สำหรับสร้างเอกสาร e-Tax Invoice & e-Receipt",
    "footer2": "แต่ไม่ได้บันทึกข้อมูลจาก ระบบ SAP Business One ไว้ใน ระบบ Leceipt",
  },
  "sapS4Hana": {
    "page": "หน้า",
    "allDocuments": "เอกสารทั้งหมด",
    "thisMonth": "เดือนนี้",
    "shortMonths01": "ม.ค.",
    "shortMonths02": "ก.พ.",
    "shortMonths03": "มี.ค.",
    "shortMonths04": "เม.ย.",
    "shortMonths05": "พ.ค.",
    "shortMonths06": "มิ.ย.",
    "shortMonths07": "ก.ค.",
    "shortMonths08": "ส.ค.",
    "shortMonths09": "ก.ย.",
    "shortMonths10": "ต.ค.",
    "shortMonths11": "พ.ย.",
    "shortMonths12": "ธ.ค.",
    "months01": "มกราคม",
    "months02": "กุมภาพันธ์",
    "months03": "มีนาคม",
    "months04": "เมษายน",
    "months05": "พฤษภาคม",
    "months06": "มิถุนายน",
    "months07": "กรกฎาคม",
    "months08": "สิงหาคม",
    "months09": "กันยายน",
    "months10": "ตุลาคม",
    "months11": "พฤศจิกายน",
    "months12": "ธันวาคม",
    "goToToday": "ไปวันที่ปัจจุบัน",
    "isRequiredErrorMessage": "กรุณาเลือกวันที่",
    "anotherOrganization": "เชื่อมต่อบริษัทอื่น",
    "authorised": "อนุมัติแล้ว",
    "paid": "จ่ายแล้ว",
    "draft": "ฉบับร่าง",
    "voided": "ถูกยกเลิก",
    "deleted": "ถูกลบแล้ว",
    "subHeader": "กรอกเลขที่เอกสารเริ่มต้น เพื่อทำการสร้างเอกสาร",
    "prefixNumber": "เลขที่เอกสารด้านหน้า (คงที่)",
    "prefixNumberDescription": "เช่น RE202201",
    "countingNumber": "ด้านหลัง (เพิ่มทีละ 1)",
    "countingNumberDesciption": "เช่น 00001",
    "countingNumberErrorMessage": "กรุณากรอกเลขที่เอกสาร",
    "startDocumentNumber": "เลขที่เอกสารเริ่มต้นที่",
    "endDocumentNumber": "เลขที่เอกสารต่อไป",
    "create": "สร้าง",
    "createdBy": "กรอกข้อมูล ผู้รับเงิน/ผู้จัดทำ",
    "createdByDesc": "ข้อมูลจะแสดงในทุกเอกสารที่ถูกเลือก",
    "allType": "สถานะทั้งหมด",
    "draftType": "ร่าง",
    "pendingApproveType": "รออนุมัติ",
    "pendingPaymentType": "รอรับชำระ",
    "overdueType": "เกินเวลารับชำระ",
    "voidType": "ถังขยะ",
    "receipt-taxinvoice": "ใบเสร็จรับเงิน/ใบกำกับภาษี",
    "receipt-taxinvoice-abb": "ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ",
    "receipt": "ใบเสร็จรับเงิน",
    "invoice-taxinvoice": "ใบแจ้งหนี้/ใบกำกับภาษี",
    "deliveryorder-taxinvoice": "ใบส่งของ/ใบกำกับภาษี",
    "taxinvoice": "ใบกำกับภาษี",
    "number": "เลขที่",
    "documentDate": "วันที่บนเอกสาร",
    "customerName": "ชื่อลูกค้า",
    "customerAddress": "ที่อยู่ลูกค้า",
    "phone": "เบอร์โทร",
    "grandTotal": "ยอด (รวม VAT)",
    "view": "ดู",
    "pending": "รอดำเนินการ",
    "processing": "กำลังสร้างเอกสาร...",
    "success": "สำเร็จ",
    "fail": "ไม่สำเร็จ",
    "createEtax": "สร้างเอกสาร e-Tax",
    "documentPerPage": "เอกสาร/หน้า",
    "refresh": "รีเฟรช",
    "disconnect": "ยกเลิกการเชื่อมต่อ",
    "disconnectConfirm": "กรุณายืนยัน ยกเลิกการเชื่อมต่อ ระบบ SAP S/4HANA",
    "documents": "จำนวนเอกสาร",
    "documentsUnit": "เอกสาร",
    "cancel": "ยกเลิก",
    "signProcessing": "กำลังสร้างเอกสารและลงลายเซ็นดิจิทัล...",
    "viewAllEtax": "ดูเอกสาร e-Tax ทั้งหมด",
    "loading": "กำลังโหลด...",
    "createdTime": "วันที่สร้าง",
    "vatTotal": "VAT",
    "createDocument": "สร้างเอกสาร",
    "createdDocuments": "ดูเอกสารทั้งหมด",
    "search": "ค้นหา",
    "paidType": "สถานะ Paid",
    "deliveredType": "สถานะ Delivered",
    "openType": "สถานะ Open",
    "closeType": "สถานะ Close",
    "taxId": "เลขประจำตัวผู้เสียภาษี",
    "customerCode": "รหัสพาร์ทเนอร์",
    "amountTotal": "ยอด",
    "header": "รายการ Billing Documents จากระบบ SAP S/4HANA",
    "searchBoxPlaceholder": "เลขที่ Billing Documents",
  },
  "sapS4HanaConnect": {
    "header": "เชื่อมต่อกับ SAP S/4HANA",
    "subHeader1": "เพื่อแสดงรายการ Invoices สำหรับสร้างเอกสาร e-Tax ใน ระบบ Leceipt",
    "subHeader2": "การเชื่อมต่อมีค่าบริการรายเดือน (ทดลองใช้งานฟรี)",
    "connect": "เชื่อมต่อ",
    "processing": "กำลังดำเนินการ...",
    "loading": "กำลังโหลด...",
    "successMessage": "เชื่อมต่อสำเร็จ!",
    "successButton": "ดูข้อมูล",
    "failMessage": "ไม่สำเร็จ!",
    "failButton": "ลองอีกครั้ง",
    "footer1": "ระบบจะเรียกรายการ Invoices มาแสดง สำหรับสร้างเอกสาร e-Tax Invoice & e-Receipt",
    "footer2": "แต่ไม่ได้บันทึกข้อมูลจาก ระบบ SAP S/4HANA ไว้ใน ระบบ Leceipt",
  },
  "shipnity": {
    "page": "หน้า",
    "allDocuments": "เอกสารทั้งหมด",
    "thisMonth": "เดือนนี้",
    "shortMonths01": "ม.ค.",
    "shortMonths02": "ก.พ.",
    "shortMonths03": "มี.ค.",
    "shortMonths04": "เม.ย.",
    "shortMonths05": "พ.ค.",
    "shortMonths06": "มิ.ย.",
    "shortMonths07": "ก.ค.",
    "shortMonths08": "ส.ค.",
    "shortMonths09": "ก.ย.",
    "shortMonths10": "ต.ค.",
    "shortMonths11": "พ.ย.",
    "shortMonths12": "ธ.ค.",
    "months01": "มกราคม",
    "months02": "กุมภาพันธ์",
    "months03": "มีนาคม",
    "months04": "เมษายน",
    "months05": "พฤษภาคม",
    "months06": "มิถุนายน",
    "months07": "กรกฎาคม",
    "months08": "สิงหาคม",
    "months09": "กันยายน",
    "months10": "ตุลาคม",
    "months11": "พฤศจิกายน",
    "months12": "ธันวาคม",
    "goToToday": "ไปวันที่ปัจจุบัน",
    "isRequiredErrorMessage": "กรุณาเลือกวันที่",
    "anotherOrganization": "เชื่อมต่อบริษัทอื่น",
    "authorised": "อนุมัติแล้ว",
    "paid": "จ่ายแล้ว",
    "draft": "ฉบับร่าง",
    "voided": "ถูกยกเลิก",
    "deleted": "ถูกลบแล้ว",
    "subHeader": "กรอกเลขที่เอกสารเริ่มต้น เพื่อทำการสร้างเอกสาร",
    "prefixNumber": "เลขที่เอกสารด้านหน้า (คงที่)",
    "prefixNumberDescription": "เช่น RE202201",
    "countingNumber": "ด้านหลัง (เพิ่มทีละ 1)",
    "countingNumberDesciption": "เช่น 00001",
    "countingNumberErrorMessage": "กรุณากรอกเลขที่เอกสาร",
    "startDocumentNumber": "เลขที่เอกสารเริ่มต้นที่",
    "endDocumentNumber": "เลขที่เอกสารต่อไป",
    "create": "สร้าง",
    "createdBy": "กรอกข้อมูล ผู้รับเงิน/ผู้จัดทำ",
    "createdByDesc": "ข้อมูลจะแสดงในทุกเอกสารที่ถูกเลือก",
    "allType": "สถานะทั้งหมด",
    "draftType": "ร่าง",
    "pendingApproveType": "รออนุมัติ",
    "pendingPaymentType": "รอรับชำระ",
    "overdueType": "เกินเวลารับชำระ",
    "voidType": "ถังขยะ",
    "receipt-taxinvoice": "ใบเสร็จรับเงิน/ใบกำกับภาษี",
    "receipt-taxinvoice-abb": "ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ",
    "receipt": "ใบเสร็จรับเงิน",
    "invoice-taxinvoice": "ใบแจ้งหนี้/ใบกำกับภาษี",
    "deliveryorder-taxinvoice": "ใบส่งของ/ใบกำกับภาษี",
    "taxinvoice": "ใบกำกับภาษี",
    "documentDate": "วันที่บนเอกสาร",
    "customerName": "ชื่อลูกค้า",
    "customerAddress": "ที่อยู่ลูกค้า",
    "phone": "เบอร์โทร",
    "grandTotal": "ยอด (รวม VAT)",
    "view": "ดู",
    "pending": "รอดำเนินการ",
    "processing": "กำลังสร้างเอกสาร...",
    "success": "สำเร็จ",
    "fail": "ไม่สำเร็จ",
    "createEtax": "สร้างเอกสาร e-Tax",
    "documentPerPage": "เอกสาร/หน้า",
    "refresh": "รีเฟรช",
    "disconnect": "ยกเลิกการเชื่อมต่อ",
    "disconnectConfirm": "กรุณายืนยัน ยกเลิกการเชื่อมต่อ ระบบ Shipnity",
    "documents": "จำนวนเอกสาร",
    "documentsUnit": "เอกสาร",
    "cancel": "ยกเลิก",
    "signProcessing": "กำลังสร้างเอกสารและลงลายเซ็นดิจิทัล...",
    "viewAllEtax": "ดูเอกสาร e-Tax ทั้งหมด",
    "loading": "กำลังโหลด...",
    "createdTime": "วันที่สร้าง",
    "vatTotal": "VAT",
    "createDocument": "สร้างเอกสาร",
    "createdDocuments": "ดูเอกสารทั้งหมด",
    "search": "ค้นหา",
    "paidType": "สถานะ Paid",
    "deliveredType": "สถานะ Delivered",
    "openType": "สถานะ Open",
    "closeType": "สถานะ Close",
    "taxId": "เลขประจำตัวผู้เสียภาษี",
    "customerCode": "รหัสพาร์ทเนอร์",
    "amountTotal": "ยอด",
    "header": "รายการออเดอร์ Shipnity",
    "searchBoxPlaceholder": "ค้นหา",
    "closed": "ส่งของแล้ว",
    "open": "ออเดอร์ใหม่",
    "checkBank": "ตรวจหลักฐานการโอนเงิน",
    "printOrder": "รอพิมพ์ชื่อที่อยู่",
    "packing": "รอเลขพัสดุ",
    "payLater": "จ่ายเงินย้อนหลัง",
    "realPreorder": "พรีออเดอร์",
    "preorder": "เกินกำหนดชำระ",
    "orderNumber": "ออเดอร์",
    "number": "เลขที่ใบกำกับ",
    "processing2": "กำลังดำเนินการ...",
    "footer": "ยกเลิกการเชื่อมต่อกับ ระบบ Shipnity",
    "UNCONFIRMED": "ไม่ยืนยัน",
    "CHECK_TRANSFER": "ส่งเช็ค",
    "NOT_FULLY_PAY": "ชำระไม่ครบถ้วน",
    "NOT_READY_TO_SHIP": "ไม่พร้อมส่ง",
    "PRINT_ORDER": "พิมพ์ออเดอร์",
    "PACKING": "กำลังเตรียม",
    "PACKED": "เตรียมแล้ว",
    "PAY_LATER": "ชำระภายหลัง",
    "PAY_LATER_CLOSED": "ชำระภายหลังเรียบร้อย",
    "CONFIRM_SHIP": "ยืนยันนำส่ง",
    "HOLDING": "เก็บไว้",
    "PREORDER": "สั่งของล่วงหน้า",
    "EXPIRED": "หมดอายุ",
    "created": "วันที่สร้าง",
    "closedDate": "วันที่ส่งของแล้ว",
    "transferred": "วันที่ส่ง",
    "mark_transferred": "วันที่แสดง",
    "printed": "วันที่พิมพ์",
  },
  "shipnityConnect": {
    "header": "เชื่อมต่อกับ Shipnity",
    "subHeader1": "เพื่อแสดงรายการ Orders สำหรับสร้างเอกสาร e-Tax ใน ระบบ Leceipt",
    "subHeader2": "การเชื่อมต่อมีค่าบริการรายเดือน (ทดลองใช้งานฟรี)",
    "connect": "เชื่อมต่อ",
    "processing": "กำลังดำเนินการ...",
    "loading": "กำลังโหลด...",
    "successMessage": "เชื่อมต่อสำเร็จ!",
    "successButton": "ดูข้อมูล",
    "failMessage": "ไม่สำเร็จ!",
    "failButton": "ลองอีกครั้ง",
    "footer1": "ระบบจะเรียกรายการ Orders มาแสดง สำหรับสร้างเอกสาร e-Tax Invoice & e-Receipt",
    "footer2": "แต่ไม่ได้บันทึกข้อมูลจาก ระบบ Shipnity ไว้ใน ระบบ Leceipt",
    "shipnityEmail": "อีเมลผู้ใช้งาน ระบบ Shipnity",
    "token": "Token (ขอจากผู้ดูแลระบบ Shipnity)",
  },
  "shopee": {
    "page": "หน้า",
    "allDocuments": "เอกสารทั้งหมด",
    "thisMonth": "เดือนนี้",
    "shortMonths01": "ม.ค.",
    "shortMonths02": "ก.พ.",
    "shortMonths03": "มี.ค.",
    "shortMonths04": "เม.ย.",
    "shortMonths05": "พ.ค.",
    "shortMonths06": "มิ.ย.",
    "shortMonths07": "ก.ค.",
    "shortMonths08": "ส.ค.",
    "shortMonths09": "ก.ย.",
    "shortMonths10": "ต.ค.",
    "shortMonths11": "พ.ย.",
    "shortMonths12": "ธ.ค.",
    "months01": "มกราคม",
    "months02": "กุมภาพันธ์",
    "months03": "มีนาคม",
    "months04": "เมษายน",
    "months05": "พฤษภาคม",
    "months06": "มิถุนายน",
    "months07": "กรกฎาคม",
    "months08": "สิงหาคม",
    "months09": "กันยายน",
    "months10": "ตุลาคม",
    "months11": "พฤศจิกายน",
    "months12": "ธันวาคม",
    "goToToday": "ไปวันที่ปัจจุบัน",
    "isRequiredErrorMessage": "กรุณาเลือกวันที่",
    "anotherOrganization": "เชื่อมต่อบริษัทอื่น",
    "authorised": "อนุมัติแล้ว",
    "paid": "จ่ายแล้ว",
    "draft": "ฉบับร่าง",
    "voided": "ถูกยกเลิก",
    "deleted": "ถูกลบแล้ว",
    "subHeader": "กรอกเลขที่เอกสารเริ่มต้น เพื่อทำการสร้างเอกสาร",
    "prefixNumber": "เลขที่เอกสารด้านหน้า (คงที่)",
    "prefixNumberDescription": "เช่น RE202201",
    "countingNumber": "ด้านหลัง (เพิ่มทีละ 1)",
    "countingNumberDesciption": "เช่น 00001",
    "countingNumberErrorMessage": "กรุณากรอกเลขที่เอกสาร",
    "startDocumentNumber": "เลขที่เอกสารเริ่มต้นที่",
    "endDocumentNumber": "เลขที่เอกสารต่อไป",
    "create": "สร้าง",
    "createdBy": "กรอกข้อมูล ผู้รับเงิน/ผู้จัดทำ",
    "createdByDesc": "ข้อมูลจะแสดงในทุกเอกสารที่ถูกเลือก",
    "draftType": "ร่าง",
    "pendingApproveType": "รออนุมัติ",
    "pendingPaymentType": "รอรับชำระ",
    "overdueType": "เกินเวลารับชำระ",
    "voidType": "ถังขยะ",
    "receipt-taxinvoice": "ใบเสร็จรับเงิน/ใบกำกับภาษี",
    "receipt-taxinvoice-abb": "ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ",
    "receipt": "ใบเสร็จรับเงิน",
    "invoice-taxinvoice": "ใบแจ้งหนี้/ใบกำกับภาษี",
    "deliveryorder-taxinvoice": "ใบส่งของ/ใบกำกับภาษี",
    "taxinvoice": "ใบกำกับภาษี",
    "documentDate": "วันที่บนเอกสาร",
    "customerName": "ชื่อลูกค้า",
    "customerAddress": "ที่อยู่ลูกค้า",
    "phone": "เบอร์โทร",
    "grandTotal": "ยอด (รวม VAT)",
    "view": "ดู",
    "pending": "รอดำเนินการ",
    "processing": "กำลังสร้างเอกสาร...",
    "success": "สำเร็จ",
    "fail": "ไม่สำเร็จ",
    "createEtax": "สร้างเอกสาร e-Tax",
    "documentPerPage": "เอกสาร/หน้า",
    "refresh": "รีเฟรช",
    "disconnect": "ยกเลิกการเชื่อมต่อ",
    "disconnectConfirm": "กรุณายืนยัน ยกเลิกการเชื่อมต่อ ระบบ Shopee",
    "documents": "จำนวนเอกสาร",
    "documentsUnit": "เอกสาร",
    "cancel": "ยกเลิก",
    "signProcessing": "กำลังสร้างเอกสารและลงลายเซ็นดิจิทัล...",
    "viewAllEtax": "ดูเอกสาร e-Tax ทั้งหมด",
    "loading": "กำลังโหลด...",
    "createdTime": "วันที่สร้าง",
    "vatTotal": "VAT",
    "createDocument": "สร้างเอกสาร",
    "createdDocuments": "ดูเอกสารทั้งหมด",
    "search": "ค้นหา",
    "paidType": "สถานะ Paid",
    "deliveredType": "สถานะ Delivered",
    "openType": "สถานะ Open",
    "closeType": "สถานะ Close",
    "taxId": "เลขประจำตัวผู้เสียภาษี",
    "customerCode": "รหัสพาร์ทเนอร์",
    "amountTotal": "ยอด",
    "header": "รายการออเดอร์ Shopee",
    "closed": "ส่งของแล้ว",
    "open": "ออเดอร์ใหม่",
    "checkBank": "ตรวจหลักฐานการโอนเงิน",
    "printOrder": "รอพิมพ์ชื่อที่อยู่",
    "packing": "รอเลขพัสดุ",
    "payLater": "จ่ายเงินย้อนหลัง",
    "realPreorder": "พรีออเดอร์",
    "preorder": "เกินกำหนดชำระ",
    "orderNumber": "ออเดอร์",
    "number": "เลขที่ใบกำกับ",
    "processing2": "กำลังดำเนินการ...",
    "footer": "ยกเลิกการเชื่อมต่อกับ ระบบ Shopee",
    "COMPLETED": "สำเร็จ",
    "ToConfirmReceive": "จัดส่งสำเร็จ",
    "SHIPPED": "อยู่ระหว่างการจัดส่ง",
    "readyToSHIPPED": "พร้อมส่ง",
    "PROCESSED": "กำลังดำเนินการ",
    "InvoicePENDING": "รอการชำระเงิน",
    "UNPAID": "ไม่ได้ชำระเงิน",
    "InCANCEL": "อยู่ระหว่างการยกเลิก",
    "CANCELLED": "ยกเลิก",
    "updateTime": "วันที่อัปเดตสถานะ",
    "createTime": "วันที่สร้างออเดอร์",
    "updatedDate": "อัปเดตสถานะ",
    "day1": "วันที่",
    "day2": "",
    "orderId": "เลขที่",
    "total": "ยอดรวม",
    "allType": "ออเดอร์ทั้งหมด",
    "orders": "ออเดอร์",
    "orderUnit": "ออเดอร์",
    "month": "เดือน ",
    "displayShipCost": "แสดงค่าขนส่งในเอกสาร",
    "on": "เปิด",
    "off": "ปิด",
    "searchBoxPlaceholder": "เลขที่ออเดอร์ Shopee",
    "buyerPayment": "ยอดลูกค้าชำระ",
    "automation": "สร้างเอกสารอัตโนมัติ",
    "onAutomationStatus": "ระบบอัตโนมัติทำงาน",
    "offAutomationStatus": "ระบบอัตโนมัติไม่ทำงาน",
    "isRequest": "ขอใบกำกับภาษี",
    "taxInvoiceNumber": "เลขที่เอกสาร",
    "reference": "เลขที่อ้างอิง",
    "duplicateTaxInvoiceHeader": "ระบบพบเลขที่เอกสารซ้ำกัน",
    "duplicateReferenceHeader": "ระบบพบเลขที่อ้างอิงซ้ำกัน",
    "duplicateTaxInvoiceList": "รายการเลขที่เอกสารที่ซ้ำ",
    "duplicateReferenceList": "รายการเลขที่อ้างอิงที่ซ้ำ",
    "etaxCreatedOrdersn": "รายการออเดอร์ที่นำไปสร้างเอกสารแล้ว",
    "ordersn": "หมายเลขคำสั่งซื้อ",
    "etaxCreatedOrdersnHeader": "ระบบพบออเดอร์ที่นำไปสร้างเอกสารแล้ว",
    "createAt": "สร้างเมื่อ",
    "proceedMessage": "หากยืนยันที่จะสร้างเอกสาร ให้กดปุ่ม",
    "payoutList": "รายการจ่าย",
    "payout": "ยอดจ่าย",
  },
  "shopeeConnect": {
    "header": "เชื่อมต่อกับ Shopee",
    "subHeader1": "เพื่อแสดงรายการ Orders สำหรับสร้างเอกสาร e-Tax ใน ระบบ Leceipt",
    "subHeader2": "การเชื่อมต่อมีค่าบริการรายเดือน (ทดลองใช้งานฟรี)",
    "connect": "เชื่อมต่อ",
    "processing": "กำลังดำเนินการ...",
    "loading": "กำลังโหลด...",
    "successMessage": "เชื่อมต่อสำเร็จ!",
    "successButton": "ดูข้อมูล",
    "failMessage": "ไม่สำเร็จ!",
    "failButton": "ลองอีกครั้ง",
    "footer1": "ระบบจะเรียกรายการ Orders มาแสดง สำหรับสร้างเอกสาร e-Tax Invoice & e-Receipt",
    "footer2": "แต่ไม่ได้บันทึกข้อมูลจาก ระบบ Shopee ไว้ใน ระบบ Leceipt",
  },
  "shopeeAutomation": {
    "triggerStatus": "สร้างเอกสารเมื่อสถานะออเดอร์เป็น",
    "receiptTaxInvoice": "ใบเสร็จรับเงิน/ใบกำกับภาษี เต็มรูปแบบ",
    "orderStatus": "สถานะออเดอร์",
    "number": "เลขที่",
    "prefix": "เลขด้านหน้า (คงที่)",
    "suffix": "ด้านหลัง (เพิ่มทีละ 1)",
    "year": "ปี",
    "month": "เดือน",
    "day": "วัน",
    "example": "ตัวอย่าง:",
    "showShippingFee": "แสดงค่าขนส่งในเอกสาร",
    "forceCreate": "หากลูกค้าไม่ขอใบกำกับภาษีเต็มรูปแบบ ให้ออกใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ",
    "receiptTaxInvoiceAbb": "ใบเสร็จรับเงิน/ใบกำกับภาษี อย่างย่อ",
    "autoSendEmail": "ส่งอีเมลให้ลูกค้าอัตโนมัติ",
    "onAutoSendEmail": "ระบบจะส่งเอกสารทางอีเมลให้ลูกค้าทันทีหลังจากสร้างเอกสาร แบบอัตโนมัติ",
    "offAutoSendEmail": "ระบบจะทำการสร้างเอกสารอัตโนมัติ แต่ไม่ส่งอีเมลให้ลูกค้า",
    "none": "ไม่มี",
    "createOnlyBillingAddress": "สร้างเอกสารเฉพาะออเดอร์ที่ขอใบกำกับภาษี",
    "receipt-taxinvoice": "ใบเสร็จรับเงิน/ใบกำกับภาษี",
    "receipt-taxinvoice-abb": "ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ",
    "autoSendEmailNote": "หากลูกค้าไม่กรอกข้อมูลขอใบกำกับภาษี ระบบจะไม่สามารถส่งอีเมล",
    "on": "เปิด",
    "off": "ปิด",
    "onCreateOnlyBillingAddressNote": "สร้างเอกสารเฉพาะออเดอร์ที่ลูกค้ากรอกข้อมูลขอใบกำกับภาษี",
    "offCreateOnlyBillingAddressNote": "สร้างเอกสารทุกออเดอร์ (ใช้ที่อยู่จัดส่ง ถ้าลูกค้าไม่ได้กรอกข้อมูลขอใบกำกับภาษี)",
    "sendEmailOnlyBillingAddress": "ส่งอีเมลเฉพาะลูกค้าที่ขอใบกำกับภาษี",
    "cc": "สำเนาไปที่อีเมล",
    "unSavedMessage": "ข้อมูลยังไม่ได้ถูกบันทึก คุณต้องการออกจากหน้านี้หรือไม่",
    "emailSetting": "ตั้งค่าอีเมล",
  },
  "tiktokShop": {
    "page": "หน้า",
    "allDocuments": "เอกสารทั้งหมด",
    "thisMonth": "เดือนนี้",
    "shortMonths01": "ม.ค.",
    "shortMonths02": "ก.พ.",
    "shortMonths03": "มี.ค.",
    "shortMonths04": "เม.ย.",
    "shortMonths05": "พ.ค.",
    "shortMonths06": "มิ.ย.",
    "shortMonths07": "ก.ค.",
    "shortMonths08": "ส.ค.",
    "shortMonths09": "ก.ย.",
    "shortMonths10": "ต.ค.",
    "shortMonths11": "พ.ย.",
    "shortMonths12": "ธ.ค.",
    "months01": "มกราคม",
    "months02": "กุมภาพันธ์",
    "months03": "มีนาคม",
    "months04": "เมษายน",
    "months05": "พฤษภาคม",
    "months06": "มิถุนายน",
    "months07": "กรกฎาคม",
    "months08": "สิงหาคม",
    "months09": "กันยายน",
    "months10": "ตุลาคม",
    "months11": "พฤศจิกายน",
    "months12": "ธันวาคม",
    "goToToday": "ไปวันที่ปัจจุบัน",
    "isRequiredErrorMessage": "กรุณาเลือกวันที่",
    "anotherOrganization": "เชื่อมต่อร้านอื่น",
    "authorised": "อนุมัติแล้ว",
    "paid": "จ่ายแล้ว",
    "draft": "ฉบับร่าง",
    "voided": "ถูกยกเลิก",
    "deleted": "ถูกลบแล้ว",
    "subHeader": "กรอกเลขที่เอกสารเริ่มต้น เพื่อทำการสร้างเอกสาร",
    "prefixNumber": "เลขที่เอกสารด้านหน้า (คงที่)",
    "prefixNumberDescription": "เช่น RE202201",
    "countingNumber": "ด้านหลัง (เพิ่มทีละ 1)",
    "countingNumberDesciption": "เช่น 00001",
    "countingNumberErrorMessage": "กรุณากรอกเลขที่เอกสาร",
    "startDocumentNumber": "เลขที่เอกสารเริ่มต้นที่",
    "endDocumentNumber": "เลขที่เอกสารต่อไป",
    "create": "สร้าง",
    "createdBy": "กรอกข้อมูล ผู้รับเงิน/ผู้จัดทำ",
    "createdByDesc": "ข้อมูลจะแสดงในทุกเอกสารที่ถูกเลือก",
    "draftType": "ร่าง",
    "pendingApproveType": "รออนุมัติ",
    "pendingPaymentType": "รอรับชำระ",
    "overdueType": "เกินเวลารับชำระ",
    "voidType": "ถังขยะ",
    "receipt-taxinvoice": "ใบเสร็จรับเงิน/ใบกำกับภาษี",
    "receipt-taxinvoice-abb": "ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ",
    "receipt": "ใบเสร็จรับเงิน",
    "invoice-taxinvoice": "ใบแจ้งหนี้/ใบกำกับภาษี",
    "deliveryorder-taxinvoice": "ใบส่งของ/ใบกำกับภาษี",
    "taxinvoice": "ใบกำกับภาษี",
    "documentDate": "วันที่บนเอกสาร",
    "customerName": "ชื่อลูกค้า",
    "customerAddress": "ที่อยู่ลูกค้า",
    "phone": "เบอร์โทร",
    "grandTotal": "ยอด (รวม VAT)",
    "view": "ดู",
    "pending": "รอดำเนินการ",
    "processing": "กำลังสร้างเอกสาร...",
    "success": "สำเร็จ",
    "fail": "ไม่สำเร็จ",
    "createEtax": "สร้างเอกสาร e-Tax",
    "documentPerPage": "เอกสาร/หน้า",
    "refresh": "รีเฟรช",
    "disconnect": "ยกเลิกการเชื่อมต่อ",
    "disconnectConfirm": "กรุณายืนยัน ยกเลิกการเชื่อมต่อ ระบบ TikTok Shop",
    "documents": "จำนวนเอกสาร",
    "documentsUnit": "เอกสาร",
    "cancel": "ยกเลิก",
    "signProcessing": "กำลังสร้างเอกสารและลงลายเซ็นดิจิทัล...",
    "viewAllEtax": "ดูเอกสาร e-Tax ทั้งหมด",
    "loading": "กำลังโหลด...",
    "createdTime": "วันที่สร้าง",
    "vatTotal": "VAT",
    "createDocument": "สร้างเอกสาร",
    "createdDocuments": "ดูเอกสารทั้งหมด",
    "search": "ค้นหา",
    "paidType": "สถานะ Paid",
    "deliveredType": "สถานะ Delivered",
    "openType": "สถานะ Open",
    "closeType": "สถานะ Close",
    "taxId": "เลขประจำตัวผู้เสียภาษี",
    "customerCode": "รหัสพาร์ทเนอร์",
    "amountTotal": "ยอด",
    "header": "รายการออเดอร์ TikTok Shop",
    "closed": "ส่งของแล้ว",
    "open": "ออเดอร์ใหม่",
    "checkBank": "ตรวจหลักฐานการโอนเงิน",
    "printOrder": "รอพิมพ์ชื่อที่อยู่",
    "packing": "รอเลขพัสดุ",
    "payLater": "จ่ายเงินย้อนหลัง",
    "realPreorder": "พรีออเดอร์",
    "preorder": "เกินกำหนดชำระ",
    "orderNumber": "ออเดอร์",
    "number": "เลขที่ใบกำกับ",
    "processing2": "กำลังดำเนินการ...",
    "footer": "ยกเลิกการเชื่อมต่อกับ ระบบ TikTok Shop",
    "COMPLETED": "สำเร็จ",
    "SHIPPED": "อยู่ระหว่างการจัดส่ง",
    "readyToSHIPPED": "พร้อมส่ง",
    "PROCESSED": "กำลังดำเนินการ",
    "InvoicePENDING": "รอการชำระเงิน",
    "UNPAID": "ไม่ได้ชำระเงิน",
    "InCANCEL": "อยู่ระหว่างการยกเลิก",
    "CANCELLED": "ยกเลิก",
    "updateTime": "วันที่อัปเดตสถานะ",
    "createTime": "วันที่สร้างออร์เดอร์",
    "updateTimeColumn": "วันที่อัปเดต",
    "updatedDate": "อัปเดตสถานะ",
    "day1": "วันที่",
    "day2": "",
    "orderId": "เลขที่",
    "total": "ยอดรวม",
    "allType": "ออเดอร์ทั้งหมด",
    "orders": "ออเดอร์",
    "orderUnit": "ออเดอร์",
    "month": "เดือน ",
    "displayShipCost": "แสดงค่าขนส่งในเอกสาร",
    "on": "เปิด",
    "off": "ปิด",
    "searchBoxPlaceholder": "เลขที่ออเดอร์",
    "buyerPayment": "ยอดลูกค้าชำระ",
    "automation": "สร้างเอกสารอัตโนมัติ",
    "onAutomationStatus": "ระบบอัตโนมัติทำงาน",
    "offAutomationStatus": "ระบบอัตโนมัติไม่ทำงาน",
    "completeStatus": "สำเร็จ",
    "deliveredStatus": "ส่งแล้ว",
    "inTransitStatus": "อยู่ระหว่างการจัดส่ง",
    "partiallyShippingStatus": "จัดส่งบางส่วน",
    "awaitingCollectionStatus": "รอการจัดเก็บ",
    "awaitingShipmentStatus": "รอการจัดส่ง",
    "unpaidStatus": "ยังไม่ชำระ",
    "canceledStatus": "ยกเลิก",
    "shipmentProvider": "ชื่อขนส่ง",
    "productName": "ชื่อสินค้า",
    "subTotal": "ยอดรวม",
    "settlement": "ยอดโอน",
    "settlementDate": "วันที่ชำระเงิน",
    "commission": "ค่าคอมมิชชั่น",
    "sortType1": "วันที่โอน",
    "sortType0": "วันที่ธุรกรรม",
    "transactionFee": "ค่าธรรมเนียม",
    "timeRangeError": "กรุณาระบุช่วงเวลาไม่เกิน 7 วัน",
    "nextPage": "หน้าถัดไป",
    "previousPage": "หน้าก่อนหน้า",
    "noItemFound": "ไม่พบข้อมูล",
    "confirm": "ยืนยัน",
    "messageBarError": "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง",
    "taxInvoiceNumber": "เลขที่เอกสาร",
    "reference": "เลขที่อ้างอิง",
    "duplicateTaxInvoiceHeader": "ระบบพบเลขที่เอกสารซ้ำกัน",
    "duplicateReferenceHeader": "ระบบพบเลขที่อ้างอิงซ้ำกัน",
    "duplicateTaxInvoiceList": "รายการเลขที่เอกสารที่ซ้ำ",
    "duplicateReferenceList": "รายการเลขที่อ้างอิงที่ซ้ำ",
    "createAt": "สร้างเมื่อ",
    "proceedMessage": "หากยืนยันที่จะสร้างเอกสาร ให้กดปุ่ม",
  },
  "tiktokShopConnect": {
    "header": "เชื่อมต่อกับ TikTok Shop",
    "subHeader1": "เพื่อแสดงรายการ Orders สำหรับสร้างเอกสาร e-Tax ใน ระบบ Leceipt",
    "subHeader2": "การเชื่อมต่อมีค่าบริการรายเดือน (ทดลองใช้งานฟรี)",
    "connect": "เชื่อมต่อ",
    "processing": "กำลังดำเนินการ...",
    "loading": "กำลังโหลด...",
    "successMessage": "เชื่อมต่อสำเร็จ!",
    "successButton": "ดูข้อมูล",
    "failMessage": "ไม่สำเร็จ!",
    "failButton": "ลองอีกครั้ง",
    "footer1": "ระบบจะเรียกรายการ Orders มาแสดง สำหรับสร้างเอกสาร e-Tax Invoice & e-Receipt",
    "footer2": "แต่ไม่ได้บันทึกข้อมูลจาก ระบบ TikTok Shop ไว้ใน ระบบ Leceipt",
  },
  "tiktokShopAutomation": {
    "triggerStatus": "สร้างเอกสารเมื่อสถานะออเดอร์เป็น",
    "receiptTaxInvoice": "ใบเสร็จรับเงิน/ใบกำกับภาษี เต็มรูปแบบ",
    "orderStatus": "สถานะออเดอร์",
    "number": "เลขที่",
    "prefix": "เลขด้านหน้า (คงที่)",
    "suffix": "ด้านหลัง (เพิ่มทีละ 1)",
    "year": "ปี",
    "month": "เดือน",
    "day": "วัน",
    "example": "ตัวอย่าง:",
    "showShippingFee": "แสดงค่าขนส่งในเอกสาร",
    "forceCreate": "หากลูกค้าไม่ขอใบกำกับภาษีเต็มรูปแบบ ให้ออกใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ",
    "receiptTaxInvoiceAbb": "ใบเสร็จรับเงิน/ใบกำกับภาษี อย่างย่อ",
    "autoSendEmail": "ส่งอีเมลให้ลูกค้าอัตโนมัติ",
    "onAutoSendEmail": "ระบบจะส่งเอกสารทางอีเมลให้ลูกค้าทันทีหลังจากสร้างเอกสาร แบบอัตโนมัติ",
    "offAutoSendEmail": "ระบบจะทำการสร้างเอกสารอัตโนมัติ แต่ไม่ส่งอีเมลให้ลูกค้า",
    "none": "ไม่มี",
    "createOnlyBillingAddress": "สร้างเอกสารเฉพาะออเดอร์ที่ขอใบกำกับภาษี",
    "receipt-taxinvoice": "ใบเสร็จรับเงิน/ใบกำกับภาษี",
    "receipt-taxinvoice-abb": "ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ",
    "on": "เปิด",
    "off": "ปิด",
    "onCreateOnlyBillingAddressNote": "สร้างเอกสารเฉพาะออเดอร์ที่ลูกค้ากรอกข้อมูลขอใบกำกับภาษี",
    "offCreateOnlyBillingAddressNote": "สร้างเอกสารทุกออเดอร์ (ใช้ที่อยู่จัดส่ง ถ้าลูกค้าไม่ได้กรอกข้อมูลขอใบกำกับภาษี)",
    "sendEmailOnlyBillingAddress": "ส่งอีเมลเฉพาะลูกค้าที่ขอใบกำกับภาษี",
    "cc": "สำเนาไปที่อีเมล",
    "unSavedMessage": "ข้อมูลยังไม่ได้ถูกบันทึก คุณต้องการออกจากหน้านี้หรือไม่",
    "emailSetting": "ตั้งค่าอีเมล",
  },
  "trCloud": {
    "page": "หน้า",
    "allDocuments": "เอกสารทั้งหมด",
    "thisMonth": "เดือนนี้",
    "shortMonths01": "ม.ค.",
    "shortMonths02": "ก.พ.",
    "shortMonths03": "มี.ค.",
    "shortMonths04": "เม.ย.",
    "shortMonths05": "พ.ค.",
    "shortMonths06": "มิ.ย.",
    "shortMonths07": "ก.ค.",
    "shortMonths08": "ส.ค.",
    "shortMonths09": "ก.ย.",
    "shortMonths10": "ต.ค.",
    "shortMonths11": "พ.ย.",
    "shortMonths12": "ธ.ค.",
    "months01": "มกราคม",
    "months02": "กุมภาพันธ์",
    "months03": "มีนาคม",
    "months04": "เมษายน",
    "months05": "พฤษภาคม",
    "months06": "มิถุนายน",
    "months07": "กรกฎาคม",
    "months08": "สิงหาคม",
    "months09": "กันยายน",
    "months10": "ตุลาคม",
    "months11": "พฤศจิกายน",
    "months12": "ธันวาคม",
    "goToToday": "ไปวันที่ปัจจุบัน",
    "isRequiredErrorMessage": "กรุณาเลือกวันที่",
    "anotherOrganization": "เชื่อมต่อบริษัทอื่น",
    "authorised": "อนุมัติแล้ว",
    "draft": "ฉบับร่าง",
    "voided": "ถูกยกเลิก",
    "deleted": "ถูกลบแล้ว",
    "subHeader": "กรอกเลขที่เอกสารเริ่มต้น เพื่อทำการสร้างเอกสาร",
    "prefixNumber": "เลขที่เอกสารด้านหน้า (คงที่)",
    "prefixNumberDescription": "เช่น RE202201",
    "countingNumber": "ด้านหลัง (เพิ่มทีละ 1)",
    "countingNumberDesciption": "เช่น 00001",
    "countingNumberErrorMessage": "กรุณากรอกเลขที่เอกสาร",
    "startDocumentNumber": "เลขที่เอกสารเริ่มต้นที่",
    "endDocumentNumber": "เลขที่เอกสารต่อไป",
    "create": "สร้าง",
    "createdBy": "กรอกข้อมูล ผู้รับเงิน/ผู้จัดทำ",
    "createdByDesc": "ข้อมูลจะแสดงในทุกเอกสารที่ถูกเลือก",
    "draftType": "ร่าง",
    "pendingApproveType": "รออนุมัติ",
    "pendingPaymentType": "รอรับชำระ",
    "overdueType": "เกินเวลารับชำระ",
    "voidType": "ถังขยะ",
    "receipt-taxinvoice": "ใบเสร็จรับเงิน/ใบกำกับภาษี",
    "receipt-taxinvoice-abb": "ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ",
    "receipt": "ใบเสร็จรับเงิน",
    "invoice-taxinvoice": "ใบแจ้งหนี้/ใบกำกับภาษี",
    "deliveryorder-taxinvoice": "ใบส่งของ/ใบกำกับภาษี",
    "taxinvoice": "ใบกำกับภาษี",
    "documentDate": "วันที่บนเอกสาร",
    "customerName": "ชื่อลูกค้า",
    "customerAddress": "ที่อยู่ลูกค้า",
    "phone": "เบอร์โทร",
    "grandTotal": "ยอด (รวม VAT)",
    "view": "ดู",
    "pending": "รอดำเนินการ",
    "processing": "กำลังสร้างเอกสาร...",
    "success": "สำเร็จ",
    "fail": "ไม่สำเร็จ",
    "createEtax": "สร้างเอกสาร e-Tax",
    "documentPerPage": "เอกสาร/หน้า",
    "refresh": "รีเฟรช",
    "disconnect": "ยกเลิกการเชื่อมต่อ",
    "disconnectConfirm": "กรุณายืนยัน ยกเลิกการเชื่อมต่อ ระบบ TRCloud",
    "documents": "จำนวนเอกสาร",
    "documentsUnit": "เอกสาร",
    "cancel": "ยกเลิก",
    "signProcessing": "กำลังสร้างเอกสารและลงลายเซ็นดิจิทัล...",
    "viewAllEtax": "ดูเอกสาร e-Tax ทั้งหมด",
    "loading": "กำลังโหลด...",
    "vatTotal": "VAT",
    "createDocument": "สร้างเอกสาร",
    "createdDocuments": "ดูเอกสารทั้งหมด",
    "search": "ค้นหา",
    "paidType": "สถานะ Paid",
    "deliveredType": "สถานะ Delivered",
    "openType": "สถานะ Open",
    "closeType": "สถานะ Close",
    "taxId": "เลขประจำตัวผู้เสียภาษี",
    "customerCode": "รหัสพาร์ทเนอร์",
    "amountTotal": "ยอด",
    "header": "รายการ Invoices จากระบบ TRCloud",
    "closed": "ส่งของแล้ว",
    "open": "ออเดอร์ใหม่",
    "checkBank": "ตรวจหลักฐานการโอนเงิน",
    "printOrder": "รอพิมพ์ชื่อที่อยู่",
    "packing": "รอเลขพัสดุ",
    "payLater": "จ่ายเงินย้อนหลัง",
    "realPreorder": "พรีออเดอร์",
    "preorder": "เกินกำหนดชำระ",
    "orderNumber": "ออเดอร์",
    "processing2": "กำลังดำเนินการ...",
    "footer": "ยกเลิกการเชื่อมต่อกับ ระบบ TRCloud",
    "COMPLETED": "สำเร็จ",
    "SHIPPED": "อยู่ระหว่างการจัดส่ง",
    "readyToSHIPPED": "พร้อมส่ง",
    "PROCESSED": "กำลังดำเนินการ",
    "InvoicePENDING": "รอการชำระเงิน",
    "UNPAID": "ไม่ได้ชำระเงิน",
    "InCANCEL": "อยู่ระหว่างการยกเลิก",
    "CANCELLED": "ยกเลิก",
    "updateTime": "วันที่อัปเดตสถานะ",
    "createTime": "วันที่สร้างเอกสาร",
    "updatedDate": "อัปเดตสถานะ",
    "day1": "วันที่",
    "day2": "",
    "orderId": "เลขที่",
    "total": "ยอดรวม",
    "orders": "ออเดอร์",
    "orderUnit": "ออเดอร์",
    "month": "เดือน",
    "displayShipCost": "แสดงค่าขนส่งในเอกสาร",
    "on": "เปิด",
    "off": "ปิด",
    "searchBoxPlaceholder": "คำค้นหา",
    "allType": "สถานะทั้งหมด",
    "debtor": "ยังไม่ชำระเงิน",
    "partial": "จ่ายบางส่วน",
    "cash": "จ่ายเงินสด",
    "paid": "ชำระแล้ว",
    "createdTime": "วันที่บนเอกสาร",
    "documentTotal": "จำนวนเอกสาร",
    "vat": "ภาษีขาย (VAT)",
    "sale": "ยอดขาย (รวม VAT)",
    "thb": "บาท",
    "number": "เลขที่",
    "creditNote": "ใบลดหนี้",
    "allOrderType": "ใบกำกับภาษี",
    "taxInvoiceNumber": "เลขที่เอกสาร",
    "reference": "เลขที่อ้างอิง",
    "duplicateTaxInvoiceHeader": "ระบบพบเอกสารที่ถูกนำไปสร้างแล้ว",
    "duplicateReferenceHeader": "ระบบพบเลขที่อ้างอิงซ้ำกัน",
    "duplicateTaxInvoiceList": "รายการเลขที่เอกสาร",
    "duplicateReferenceList": "รายการเลขที่อ้างอิงที่ซ้ำ",
    "createAt": "สร้างเมื่อ",
    "proceedMessage": "หากยืนยันที่จะสร้างเอกสาร ให้กดปุ่ม",
    "taxInvoice": "ใบกำกับภาษี",
    "notSupportDeposit": "ระบบยังไม่รองรับเอกสารที่มีค่ามัดจำ กรุณาตรวจสอบเอกสารที่มีค่ามัดจำทุกครั้งก่อนสร้างเอกสาร",
  },
  "trCloudConnect": {
    "header": "เชื่อมต่อกับ TRCloud",
    "subHeader1": "เพื่อแสดงรายการ Invoices สำหรับสร้างเอกสาร e-Tax ใน ระบบ Leceipt",
    "subHeader2": "การเชื่อมต่อมีค่าบริการรายเดือน (ทดลองใช้งานฟรี)",
    "connect": "เชื่อมต่อ",
    "processing": "กำลังดำเนินการ...",
    "loading": "กำลังโหลด...",
    "successMessage": "เชื่อมต่อสำเร็จ!",
    "successButton": "ดูข้อมูล",
    "failMessage": "ไม่สำเร็จ!",
    "failButton": "ลองอีกครั้ง",
    "footer1": "ระบบจะเรียกรายการ Invoices มาแสดง สำหรับสร้างเอกสาร e-Tax Invoice & e-Receipt",
    "footer2": "แต่ไม่ได้บันทึกข้อมูลจาก ระบบ TRCloud ไว้ใน ระบบ Leceipt",
  },
  "wooCommerce": {
    "loading": "กำลังโหลด...",
    "page": "หน้า",
    "allDocuments": "เอกสารทั้งหมด",
    "documentDate": "วันที่บนเอกสาร",
    "number": "เลขที่",
    "customerName": "ลูกค้า",
    "customerAddress": "ที่อยู่ลูกค้า",
    "phone": "เบอร์โทร",
    "grandTotal": "ยอด (รวม VAT)",
    "pending": "รอดำเนินการ",
    "processing": "กำลังสร้างเอกสาร...",
    "success": "สำเร็จ",
    "fail": "ไม่สำเร็จ",
    "receipt-taxinvoice": "ใบเสร็จรับเงิน/ใบกำกับภาษี",
    "receipt-taxinvoice-abb": "ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ",
    "receipt": "ใบเสร็จรับเงิน",
    "invoice-taxinvoice": "ใบแจ้งหนี้/ใบกำกับภาษี",
    "deliveryorder-taxinvoice": "ใบส่งของ/ใบกำกับภาษี",
    "taxinvoice": "ใบกำกับภาษี",
    "thisMonth": "เดือนนี้",
    "shortMonths01": "ม.ค.",
    "shortMonths02": "ก.พ.",
    "shortMonths03": "มี.ค.",
    "shortMonths04": "เม.ย.",
    "shortMonths05": "พ.ค.",
    "shortMonths06": "มิ.ย.",
    "shortMonths07": "ก.ค.",
    "shortMonths08": "ส.ค.",
    "shortMonths09": "ก.ย.",
    "shortMonths10": "ต.ค.",
    "shortMonths11": "พ.ย.",
    "shortMonths12": "ธ.ค.",
    "months01": "มกราคม",
    "months02": "กุมภาพันธ์",
    "months03": "มีนาคม",
    "months04": "เมษายน",
    "months05": "พฤษภาคม",
    "months06": "มิถุนายน",
    "months07": "กรกฎาคม",
    "months08": "สิงหาคม",
    "months09": "กันยายน",
    "months10": "ตุลาคม",
    "months11": "พฤศจิกายน",
    "months12": "ธันวาคม",
    "goToToday": "ไปวันที่ปัจจุบัน",
    "isRequiredErrorMessage": "กรุณาเลือกวันที่",
    "anotherOrganization": "เชื่อมต่อบริษัทอื่น",
    "authorised": "อนุมัติแล้ว",
    "paid": "จ่ายแล้ว",
    "draft": "ฉบับร่าง",
    "voided": "ถูกยกเลิก",
    "deleted": "ถูกลบแล้ว",
    "refresh": "รีเฟรช",
    "disconnect": "ยกเลิกการเชื่อมต่อ",
    "disconnectConfirm": "กรุณายืนยัน ยกเลิกการเชื่อมต่อ ระบบ Woo Commerce",
    "header": "รายการ Orders จากระบบ Woo Commerce",
    "subHeader": "กรอกเลขที่เอกสารเริ่มต้น เพื่อทำการสร้างเอกสาร",
    "prefixNumber": "เลขที่เอกสารด้านหน้า (คงที่)",
    "prefixNumberDescription": "เช่น RE202201",
    "countingNumber": "ด้านหลัง (เพิ่มทีละ 1)",
    "countingNumberDesciption": "เช่น 00001",
    "countingNumberErrorMessage": "กรุณากรอกเลขที่เอกสาร",
    "startDocumentNumber": "เลขที่เอกสารเริ่มต้นที่",
    "endDocumentNumber": "เลขที่เอกสารต่อไป",
    "createDocument": "สร้างเอกสาร",
    "cancel": "ยกเลิก",
    "signProcessing": "กำลังสร้างเอกสารและลงลายเซ็นดิจิทัล...",
    "createdDocuments": "ดูเอกสารทั้งหมด",
    "search": "ค้นหา",
    "searchBoxPlaceholder": "คำค้นหา",
    "create": "สร้าง",
    "allType": "สถานะทั้งหมด",
    "pendingType": "สถานะ Pending",
    "processingType": "สถานะ Processing",
    "on-holdType": "สถานะ On-hold",
    "completedType": "สถานะ Completed",
    "cancelledType": "สถานะ Cancelled",
    "refundedType": "สถานะ Refunded",
    "failedType": "สถานะ Failed",
    "trashType": "สถานะ Trash",
    "documentPerPage": "เอกสาร/หน้า",
    "vatTotal": "VAT",
    "createdBy": "กรอกข้อมูล ผู้รับเงิน/ผู้จัดทำ",
    "createdByDesc": "ข้อมูลจะแสดงในทุกเอกสารที่ถูกเลือก",
    "timeRange": "ช่วงเวลา",
    "fromRange": "จาก",
    "toRange": "ถึง",
    "timeRangeError": "กรุณาระบุช่วงเวลาไม่เกิน 30 วัน",
    "timeRangeError2": "กรุณาระบุช่วงเวลาไม่เกิน 15 วัน",
    "day1": "วันที่",
    "day2": "",
    "updateTime": "วันที่อัปเดตสถานะ",
    "createTime": "วันที่สร้างออเดอร์",
    "updatedDate": "อัปเดตสถานะ",
    "pendingTypeColumn": " Pending",
    "processingTypeColumn": " Processing",
    "on-holdTypeColumn": " On-hold",
    "completedTypeColumn": " Completed",
    "cancelledTypeColumn": " Cancelled",
    "refundedTypeColumn": " Refunded",
    "failedTypeColumn": " Failed",
    "trashTypeColumn": " Trash",
    "createdTime": "วันที่สร้าง",
  },
  "wooCommerceConnect": {
    "header": "เชื่อมต่อกับ Woo Commerce",
    "subHeader1": "เพื่อแสดงรายการ Orders สำหรับสร้างเอกสาร e-Tax ใน ระบบ Leceipt",
    "subHeader2": "การเชื่อมต่อมีค่าบริการรายเดือน (ทดลองใช้งานฟรี)",
    "connect": "เชื่อมต่อ",
    "processing": "กำลังดำเนินการ...",
    "loading": "กำลังโหลด...",
    "successMessage": "เชื่อมต่อสำเร็จ!",
    "successButton": "ดูข้อมูล",
    "failMessage": "ไม่สำเร็จ!",
    "failButton": "ลองอีกครั้ง",
    "footer1": "ระบบจะเรียกรายการ Orders มาแสดง สำหรับสร้างเอกสาร e-Tax Invoice & e-Receipt",
    "footer2": "แต่ไม่ได้บันทึกข้อมูลจาก ระบบ Woo Commerce ไว้ใน ระบบ Leceipt",
    "resourceUrl": "ตัวอย่าง https://my-org.operations.dynamics.com",
    "shopUrl": "ตัวอย่าง https://my-shop.com",
  },
  "zoho": {
    "page": "หน้า",
    "allDocuments": "เอกสารทั้งหมด",
    "thisMonth": "เดือนนี้",
    "shortMonths01": "ม.ค.",
    "shortMonths02": "ก.พ.",
    "shortMonths03": "มี.ค.",
    "shortMonths04": "เม.ย.",
    "shortMonths05": "พ.ค.",
    "shortMonths06": "มิ.ย.",
    "shortMonths07": "ก.ค.",
    "shortMonths08": "ส.ค.",
    "shortMonths09": "ก.ย.",
    "shortMonths10": "ต.ค.",
    "shortMonths11": "พ.ย.",
    "shortMonths12": "ธ.ค.",
    "months01": "มกราคม",
    "months02": "กุมภาพันธ์",
    "months03": "มีนาคม",
    "months04": "เมษายน",
    "months05": "พฤษภาคม",
    "months06": "มิถุนายน",
    "months07": "กรกฎาคม",
    "months08": "สิงหาคม",
    "months09": "กันยายน",
    "months10": "ตุลาคม",
    "months11": "พฤศจิกายน",
    "months12": "ธันวาคม",
    "goToToday": "ไปวันที่ปัจจุบัน",
    "isRequiredErrorMessage": "กรุณาเลือกวันที่",
    "anotherOrganization": "เชื่อมต่อบริษัทอื่น",
    "authorised": "อนุมัติแล้ว",
    "draft": "ฉบับร่าง",
    "voided": "ถูกยกเลิก",
    "deleted": "ถูกลบแล้ว",
    "subHeader": "กรอกเลขที่เอกสารเริ่มต้น เพื่อทำการสร้างเอกสาร",
    "prefixNumber": "เลขที่เอกสารด้านหน้า (คงที่)",
    "prefixNumberDescription": "เช่น RE202201",
    "countingNumber": "ด้านหลัง (เพิ่มทีละ 1)",
    "countingNumberDesciption": "เช่น 00001",
    "countingNumberErrorMessage": "กรุณากรอกเลขที่เอกสาร",
    "startDocumentNumber": "เลขที่เอกสารเริ่มต้นที่",
    "endDocumentNumber": "เลขที่เอกสารต่อไป",
    "create": "สร้าง",
    "createdBy": "กรอกข้อมูล ผู้รับเงิน/ผู้จัดทำ",
    "createdByDesc": "ข้อมูลจะแสดงในทุกเอกสารที่ถูกเลือก",
    "draftType": "ร่าง",
    "pendingApproveType": "รออนุมัติ",
    "pendingPaymentType": "รอรับชำระ",
    "overdueType": "เกินเวลารับชำระ",
    "voidType": "ถังขยะ",
    "receipt-taxinvoice": "ใบเสร็จรับเงิน/ใบกำกับภาษี",
    "receipt-taxinvoice-abb": "ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ",
    "receipt": "ใบเสร็จรับเงิน",
    "invoice-taxinvoice": "ใบแจ้งหนี้/ใบกำกับภาษี",
    "deliveryorder-taxinvoice": "ใบส่งของ/ใบกำกับภาษี",
    "taxinvoice": "ใบกำกับภาษี",
    "documentDate": "วันที่บนเอกสาร",
    "customerName": "ชื่อลูกค้า",
    "customerAddress": "ที่อยู่ลูกค้า",
    "phone": "เบอร์โทร",
    "grandTotal": "ยอด (รวม VAT)",
    "view": "ดู",
    "pending": "รอดำเนินการ",
    "processing": "กำลังสร้างเอกสาร...",
    "success": "สำเร็จ",
    "fail": "ไม่สำเร็จ",
    "createEtax": "สร้างเอกสาร e-Tax",
    "documentPerPage": "เอกสาร/หน้า",
    "refresh": "รีเฟรช",
    "disconnect": "ยกเลิกการเชื่อมต่อ",
    "disconnectConfirm": "กรุณายืนยัน ยกเลิกการเชื่อมต่อ ระบบ Zoho",
    "documents": "จำนวนเอกสาร",
    "documentsUnit": "เอกสาร",
    "cancel": "ยกเลิก",
    "signProcessing": "กำลังสร้างเอกสารและลงลายเซ็นดิจิทัล...",
    "viewAllEtax": "ดูเอกสาร e-Tax ทั้งหมด",
    "loading": "กำลังโหลด...",
    "vatTotal": "VAT",
    "createDocument": "สร้างเอกสาร",
    "createdDocuments": "ดูเอกสารทั้งหมด",
    "search": "ค้นหา",
    "paidType": "สถานะ Paid",
    "deliveredType": "สถานะ Delivered",
    "openType": "สถานะ Open",
    "closeType": "สถานะ Close",
    "taxId": "เลขประจำตัวผู้เสียภาษี",
    "customerCode": "รหัสพาร์ทเนอร์",
    "amountTotal": "ยอด",
    "header": "รายการ Invoices จากระบบ Zoho",
    "closed": "ส่งของแล้ว",
    "open": "ออเดอร์ใหม่",
    "checkBank": "ตรวจหลักฐานการโอนเงิน",
    "printOrder": "รอพิมพ์ชื่อที่อยู่",
    "packing": "รอเลขพัสดุ",
    "payLater": "จ่ายเงินย้อนหลัง",
    "realPreorder": "พรีออเดอร์",
    "preorder": "เกินกำหนดชำระ",
    "orderNumber": "ออเดอร์",
    "processing2": "กำลังดำเนินการ...",
    "footer": "ยกเลิกการเชื่อมต่อกับ ระบบ Zoho",
    "COMPLETED": "สำเร็จ",
    "SHIPPED": "อยู่ระหว่างการจัดส่ง",
    "readyToSHIPPED": "พร้อมส่ง",
    "PROCESSED": "กำลังดำเนินการ",
    "InvoicePENDING": "รอการชำระเงิน",
    "UNPAID": "ไม่ได้ชำระเงิน",
    "InCANCEL": "อยู่ระหว่างการยกเลิก",
    "CANCELLED": "ยกเลิก",
    "updateTime": "วันที่อัปเดตสถานะ",
    "createTime": "วันที่สร้างเอกสาร",
    "updatedDate": "อัปเดตสถานะ",
    "day1": "วันที่",
    "day2": "",
    "orderId": "เลขที่",
    "total": "ยอดรวม",
    "orders": "ออเดอร์",
    "orderUnit": "ออเดอร์",
    "month": "เดือน",
    "displayShipCost": "แสดงค่าขนส่งในเอกสาร",
    "on": "เปิด",
    "off": "ปิด",
    "allType": "ใบกำกับภาษีทั้งหมด",
    "debtor": "ยังไม่ชำระเงิน",
    "partial": "จ่ายบางส่วน",
    "cash": "จ่ายเงินสด",
    "paid": "ชำระแล้ว",
    "createdTime": "วันที่บนเอกสาร",
    "documentTotal": "จำนวนใบกำกับภาษี",
    "vat": "ภาษีขาย (VAT)",
    "sale": "ยอดขาย (รวม VAT)",
    "thb": "บาท",
    "number": "เลขที่",
    "email": "อีเมล",
    "messageBar": "ขออภัย ระบบได้ทำการเรียกเอกสารครบของวันนี้แล้ว",
    "searchBoxPlaceholder": "เลขที่ Invoice หรือชื่อลูกค้า",
  },
  "zohoConnect": {
    "header": "เชื่อมต่อกับ Zoho",
    "subHeader1": "เพื่อแสดงรายการ Invoices สำหรับสร้างเอกสาร e-Tax ใน ระบบ Leceipt",
    "subHeader2": "การเชื่อมต่อมีค่าบริการรายเดือน (ทดลองใช้งานฟรี)",
    "connect": "เชื่อมต่อ",
    "processing": "กำลังดำเนินการ...",
    "loading": "กำลังโหลด...",
    "successMessage": "เชื่อมต่อสำเร็จ!",
    "successButton": "ดูข้อมูล",
    "failMessage": "ไม่สำเร็จ!",
    "failButton": "ลองอีกครั้ง",
    "footer1": "ระบบจะเรียกรายการ Invoices มาแสดง สำหรับสร้างเอกสาร e-Tax Invoice & e-Receipt",
    "footer2": "แต่ไม่ได้บันทึกข้อมูลจาก ระบบ Zoho ไว้ใน ระบบ Leceipt",
  },
  "inputTax": {
    "warningSetupCertificate": "เอกสาร e-Tax Invoice & e-Receipt จะสมบูรณ์ก็ต่อเมื่อใช้ใบรับรองอิเล็กทรอนิกส์ของจริงเท่านั้น",
    "warningSetupCertificateLink": "ติดตั้งใบรับรองอิเล็กทรอนิกส์",
    "warningXmlDelivery": "ไฟล์ XML จะส่งกรมสรรพากร วันที่ 5 ของทุกเดือน",
    "close": "ปิด",
    "allDocuments": "เอกสารรายรับทั้งหมด",
    "totalDocuments": "จำนวนเอกสาร",
    "vat": "ภาษีขาย (VAT)",
    "salesAmount": "ยอดขาย (รวม VAT)",
    "documents": "เอกสาร",
    "thb": "บาท",
    "signProcessing": "กำลังสร้างเอกสารและลงลายเซ็นดิจิทัล...",
    "sendFileByEmail": "ส่งไฟล์ทางอีเมล",
    "sendToEmail": "ส่งไปที่อีเมล",
    "ccToEmail": "สำเนาไปที่อีเมล",
    "sendEmail": "ส่งอีเมล",
    "cancel": "ยกเลิก",
    "sendFileBySms": "ส่งไฟล์ทาง SMS",
    "mobileNumer": "เบอร์มือถือ",
    "exampleNumber": "ตัวอย่าง 0825798555",
    "sendSms": "ส่งข้อความ SMS",
    "shortMonths01": "ม.ค.",
    "shortMonths02": "ก.พ.",
    "shortMonths03": "มี.ค.",
    "shortMonths04": "เม.ย.",
    "shortMonths05": "พ.ค.",
    "shortMonths06": "มิ.ย.",
    "shortMonths07": "ก.ค.",
    "shortMonths08": "ส.ค.",
    "shortMonths09": "ก.ย.",
    "shortMonths10": "ต.ค.",
    "shortMonths11": "พ.ย.",
    "shortMonths12": "ธ.ค.",
    "months01": "มกราคม",
    "months02": "กุมภาพันธ์",
    "months03": "มีนาคม",
    "months04": "เมษายน",
    "months05": "พฤษภาคม",
    "months06": "มิถุนายน",
    "months07": "กรกฎาคม",
    "months08": "สิงหาคม",
    "months09": "กันยายน",
    "months10": "ตุลาคม",
    "months11": "พฤศจิกายน",
    "months12": "ธันวาคม",
    "allDocumentsType": "เอกสารทุกประเภท",
    "receipt-taxinvoice": "ใบเสร็จรับเงิน/ใบกำกับภาษี",
    "receipt-taxinvoice-abb": "ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ",
    "receipt": "ใบเสร็จรับเงิน",
    "invoice-taxinvoice": "ใบแจ้งหนี้/ใบกำกับภาษี",
    "deliveryorder-taxinvoice": "ใบส่งของ/ใบกำกับภาษี",
    "taxinvoice": "ใบกำกับภาษี",
    "debit-note": "ใบเพิ่มหนี้",
    "credit-note": "ใบลดหนี้",
    "quotation": "ใบเสนอราคา",
    "invoice": "ใบแจ้งหนี้",
    "billing-note": "ใบวางบิล",
    "createdDate": "วันที่สร้างเอกสาร",
    "documentDate": "วันที่บนเอกสาร",
    "thisMonth": "เดือนนี้",
    "documentPerPage": "เอกสาร/หน้า",
    "page": "หน้า",
    "refresh": "รีเฟรช",
    "confirmSendEmail": "คุณต้องการที่จะส่งอีเมล",
    "create": "สร้าง",
    "combinedReceipt": "ใบเสร็จรับเงินรวม",
    "combinedReceiptAlert": "ขออภัย มีประเภทเอกสารที่ไม่สามารถสร้างใบเสร็จรับเงินรวมได้",
    "download": "ดาวน์โหลด",
    "emailAlert": "กรุณากรอกอีเมลที่ถูกต้อง",
    "mobileNumberAlert": "กรุณากรอกเบอร์มือถือที่ถูกต้อง",
    "number": "เลขที่",
    "customerName": "ชื่อผู้ขายสินค้า/ผู้ให้บริการ",
    "grandTotal": "ยอด (รวม Vat)",
    "document": "เอกสาร",
    "openFile": "เปิดไฟล์",
    "email": "อีเมล",
    "emailDate": "วันที่ส่งอีเมล",
    "sendingEmail": "กำลังส่งอีเมล...",
    "success": "สำเร็จ",
    "emailNotFound": "ไม่พบอีเมล",
    "fail": "ไม่สำเร็จ",
    "file": "ไฟล์",
    "openDocument": "เปิดเอกสาร",
    "reCreateDocument": "สร้างใบใหม่ข้อมูลเดิม",
    "reIssuedDocument": "สร้างใบใหม่แทนใบเดิม",
    "delete": "ลบ",
    "deleteDisable": "ลบ (ส่งสรรพากรแล้ว)",
    "sendEmailSuccess": "ส่งอีเมลสำเร็จ!",
    "sendEmailFail": "ส่งอีเมลไม่สำเร็จ!",
    "sendSmsSuccess": "ส่งข้อความ SMS สำเร็จ!",
    "sendSmsFail": "ส่งข้อความ SMS ไม่สำเร็จ!",
    "instantTopup": "เติมเครดิต เร็วทันใจ 24 ชม.",
    "select": "เลือก",
    "isRequiredErrorMessage": "กรุณาเลือกวันที่",
    "inputTaxReport": "ดูรายงานภาษีซื้อ",
    "downloadPdf": "ดาวน์โหลด PDF",
    "downloadCsv": "ดาวน์โหลด CSV",
    "taxId": "เลขประจำตัวผู้เสียภาษี",
    "branchNumber": "สถานประกอบการ",
    "amountTotal": "ยอด (ไม่รวม VAT)",
    "vatTotal": "VAT",
    "header": "รายงานภาษีซื้อ",
    "documentUnit": "ใบ",
    "purchaseTotal": "ยอดซื้อ (ไม่รวม VAT)",
    "purchaseVat": "ภาษีซื้อ (VAT)",
    "loading": "กำลังโหลด...",
    "search": "ค้นหา",
    "searchPlaceholder": "ค้นหา",
  },
  "inputTaxConfirm": {
    "confirm": "ยืนยัน",
    "edit": "แก้ไข",
    "processing": "กำลังดำเนินการ",
    "processingMessage": "สร้างตัวอย่างเอกสาร รายงานภาษีซื้อ",
    "downloadPdf": "ดาวน์โหลด PDF",
    "downloadCsv": "ดาวน์โหลด CSV",
    "back": "กลับ",
    "loading": "กำลังโหลด...",
  },
  "emailDeliveries": {
    "warningSetupCertificate": "เอกสาร e-Tax Invoice & e-Receipt จะสมบูรณ์ก็ต่อเมื่อใช้ใบรับรองอิเล็กทรอนิกส์ของจริงเท่านั้น",
    "warningSetupCertificateLink": "ติดตั้งใบรับรองอิเล็กทรอนิกส์",
    "warningXmlDelivery": "ไฟล์ XML จะส่งกรมสรรพากร วันที่ 5 ของทุกเดือน",
    "close": "ปิด",
    "allDocuments": "เอกสารรายรับทั้งหมด",
    "totalDocuments": "จำนวนเอกสาร",
    "vat": "ภาษีขาย (VAT)",
    "salesAmount": "ยอดขาย (รวม VAT)",
    "documents": "เอกสาร",
    "thb": "บาท",
    "signProcessing": "กำลังสร้างเอกสารและลงลายเซ็นดิจิทัล...",
    "sendFileByEmail": "ส่งไฟล์ทางอีเมล",
    "sendToEmail": "ส่งไปที่อีเมล",
    "ccToEmail": "สำเนาไปที่อีเมล",
    "sendEmail": "ส่งอีเมล",
    "cancel": "ยกเลิก",
    "sendFileBySms": "ส่งไฟล์ทาง SMS",
    "mobileNumer": "เบอร์มือถือ",
    "exampleNumber": "ตัวอย่าง 0825798555",
    "sendSms": "ส่งข้อความ SMS",
    "shortMonths01": "ม.ค.",
    "shortMonths02": "ก.พ.",
    "shortMonths03": "มี.ค.",
    "shortMonths04": "เม.ย.",
    "shortMonths05": "พ.ค.",
    "shortMonths06": "มิ.ย.",
    "shortMonths07": "ก.ค.",
    "shortMonths08": "ส.ค.",
    "shortMonths09": "ก.ย.",
    "shortMonths10": "ต.ค.",
    "shortMonths11": "พ.ย.",
    "shortMonths12": "ธ.ค.",
    "months01": "มกราคม",
    "months02": "กุมภาพันธ์",
    "months03": "มีนาคม",
    "months04": "เมษายน",
    "months05": "พฤษภาคม",
    "months06": "มิถุนายน",
    "months07": "กรกฎาคม",
    "months08": "สิงหาคม",
    "months09": "กันยายน",
    "months10": "ตุลาคม",
    "months11": "พฤศจิกายน",
    "months12": "ธันวาคม",
    "allDocumentsType": "เอกสารทุกประเภท",
    "receipt-taxinvoice": "ใบเสร็จรับเงิน/ใบกำกับภาษี",
    "receipt-taxinvoice-abb": "ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ",
    "receipt": "ใบเสร็จรับเงิน",
    "invoice-taxinvoice": "ใบแจ้งหนี้/ใบกำกับภาษี",
    "deliveryorder-taxinvoice": "ใบส่งของ/ใบกำกับภาษี",
    "taxinvoice": "ใบกำกับภาษี",
    "debit-note": "ใบเพิ่มหนี้",
    "credit-note": "ใบลดหนี้",
    "quotation": "ใบเสนอราคา",
    "invoice": "ใบแจ้งหนี้",
    "billing-note": "ใบวางบิล",
    "createdDate": "วันที่ส่ง",
    "documentDate": "วันที่บนเอกสาร",
    "thisMonth": "เดือนนี้",
    "documentPerPage": "เอกสาร/หน้า",
    "page": "หน้า",
    "refresh": "รีเฟรช",
    "confirmSendEmail": "คุณต้องการที่จะส่งอีเมล",
    "create": "สร้าง",
    "combinedReceipt": "ใบเสร็จรับเงินรวม",
    "combinedReceiptAlert": "ขออภัย มีประเภทเอกสารที่ไม่สามารถสร้างใบเสร็จรับเงินรวมได้",
    "download": "ดาวน์โหลด",
    "emailAlert": "กรุณากรอกอีเมลที่ถูกต้อง",
    "mobileNumberAlert": "กรุณากรอกเบอร์มือถือที่ถูกต้อง",
    "number": "เลขที่",
    "customerName": "ชื่อลูกค้า",
    "grandTotal": "ยอด (รวม Vat)",
    "document": "เอกสาร",
    "openFile": "เปิดไฟล์",
    "email": "อีเมล",
    "emailDate": "วันที่ส่งอีเมล",
    "sendingEmail": "กำลังส่งอีเมล...",
    "success": "สำเร็จ",
    "emailNotFound": "ไม่พบอีเมล",
    "fail": "ไม่สำเร็จ",
    "file": "ไฟล์",
    "openDocument": "เปิดเอกสาร",
    "reCreateDocument": "สร้างใบใหม่ข้อมูลเดิม",
    "reIssuedDocument": "สร้างใบใหม่แทนใบเดิม",
    "delete": "ลบ",
    "deleteDisable": "ลบ (ส่งสรรพากรแล้ว)",
    "sendEmailSuccess": "ส่งอีเมลสำเร็จ!",
    "sendEmailFail": "ส่งอีเมลไม่สำเร็จ!",
    "sendSmsSuccess": "ส่งข้อความ SMS สำเร็จ!",
    "sendSmsFail": "ส่งข้อความ SMS ไม่สำเร็จ!",
    "instantTopup": "เติมเครดิต เร็วทันใจ 24 ชม.",
    "select": "เลือก",
    "isRequiredErrorMessage": "กรุณาเลือกวันที่",
    "inputTaxReport": "ดูรายงานภาษีซื้อ",
    "downloadPdf": "ดาวน์โหลด PDF",
    "downloadCsv": "ดาวน์โหลด CSV",
    "taxId": "เลขประจำตัวผู้เสียภาษี",
    "branchNumber": "สถานประกอบการ",
    "amountTotal": "ยอด (ไม่รวม VAT)",
    "vatTotal": "VAT",
    "header": "รายงานการนำส่งอีเมล",
    "documentUnit": "ใบ",
    "purchaseTotal": "ยอดซื้อ (ไม่รวม VAT)",
    "purchaseVat": "ภาษีซื้อ (VAT)",
    "loading": "กำลังโหลด...",
    "search": "ค้นหา",
    "searchPlaceholder": "ค้นหา",
    "saleTotal": "ยอดขาย (ไม่รวม VAT)",
    "saleVat": "ภาษีขาย (VAT)",
    "to": "ผู้รับ",
    "documentTypeName": "ประเภทเอกสาร",
    "provider": "ผู้ให้บริการอีเมล",
    "timeRange": "ช่วงเวลา",
    "fromRange": "จาก",
    "toRange": "ถึง",
    "timeRangeError": "กรุณาระบุช่วงเวลาไม่เกิน 30 วัน",
    "timeRangeError2": "กรุณาระบุช่วงเวลาไม่เกิน 15 วัน",
    "month": "เดือน ",
    "company": "ชื่อผู้ประกอบการ ",
    "companyTaxId": "เลขประจำตัวผู้เสียภาษี ",
    "address": "ที่อยู่ ",
  },
  "smsDeliveries": {
    "warningSetupCertificate": "เอกสาร e-Tax Invoice & e-Receipt จะสมบูรณ์ก็ต่อเมื่อใช้ใบรับรองอิเล็กทรอนิกส์ของจริงเท่านั้น",
    "warningSetupCertificateLink": "ติดตั้งใบรับรองอิเล็กทรอนิกส์",
    "warningXmlDelivery": "ไฟล์ XML จะส่งกรมสรรพากร วันที่ 5 ของทุกเดือน",
    "close": "ปิด",
    "allDocuments": "เอกสารรายรับทั้งหมด",
    "totalDocuments": "จำนวนเอกสาร",
    "vat": "ภาษีขาย (VAT)",
    "salesAmount": "ยอดขาย (รวม VAT)",
    "documents": "เอกสาร",
    "thb": "บาท",
    "signProcessing": "กำลังสร้างเอกสารและลงลายเซ็นดิจิทัล...",
    "sendFileByEmail": "ส่งไฟล์ทางอีเมล",
    "sendToEmail": "ส่งไปที่อีเมล",
    "ccToEmail": "สำเนาไปที่อีเมล",
    "sendEmail": "ส่งอีเมล",
    "cancel": "ยกเลิก",
    "sendFileBySms": "ส่งไฟล์ทาง SMS",
    "mobileNumer": "เบอร์มือถือ",
    "exampleNumber": "ตัวอย่าง 0825798555",
    "sendSms": "ส่งข้อความ SMS",
    "shortMonths01": "ม.ค.",
    "shortMonths02": "ก.พ.",
    "shortMonths03": "มี.ค.",
    "shortMonths04": "เม.ย.",
    "shortMonths05": "พ.ค.",
    "shortMonths06": "มิ.ย.",
    "shortMonths07": "ก.ค.",
    "shortMonths08": "ส.ค.",
    "shortMonths09": "ก.ย.",
    "shortMonths10": "ต.ค.",
    "shortMonths11": "พ.ย.",
    "shortMonths12": "ธ.ค.",
    "months01": "มกราคม",
    "months02": "กุมภาพันธ์",
    "months03": "มีนาคม",
    "months04": "เมษายน",
    "months05": "พฤษภาคม",
    "months06": "มิถุนายน",
    "months07": "กรกฎาคม",
    "months08": "สิงหาคม",
    "months09": "กันยายน",
    "months10": "ตุลาคม",
    "months11": "พฤศจิกายน",
    "months12": "ธันวาคม",
    "allDocumentsType": "เอกสารทุกประเภท",
    "receipt-taxinvoice": "ใบเสร็จรับเงิน/ใบกำกับภาษี",
    "receipt-taxinvoice-abb": "ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ",
    "receipt": "ใบเสร็จรับเงิน",
    "invoice-taxinvoice": "ใบแจ้งหนี้/ใบกำกับภาษี",
    "deliveryorder-taxinvoice": "ใบส่งของ/ใบกำกับภาษี",
    "taxinvoice": "ใบกำกับภาษี",
    "debit-note": "ใบเพิ่มหนี้",
    "credit-note": "ใบลดหนี้",
    "quotation": "ใบเสนอราคา",
    "invoice": "ใบแจ้งหนี้",
    "billing-note": "ใบวางบิล",
    "createdDate": "วันที่ส่ง",
    "documentDate": "วันที่บนเอกสาร",
    "thisMonth": "เดือนนี้",
    "documentPerPage": "เอกสาร/หน้า",
    "page": "หน้า",
    "refresh": "รีเฟรช",
    "confirmSendEmail": "คุณต้องการที่จะส่งอีเมล",
    "create": "สร้าง",
    "combinedReceipt": "ใบเสร็จรับเงินรวม",
    "combinedReceiptAlert": "ขออภัย มีประเภทเอกสารที่ไม่สามารถสร้างใบเสร็จรับเงินรวมได้",
    "download": "ดาวน์โหลด",
    "emailAlert": "กรุณากรอกอีเมลที่ถูกต้อง",
    "mobileNumberAlert": "กรุณากรอกเบอร์มือถือที่ถูกต้อง",
    "number": "เลขที่",
    "customerName": "ชื่อลูกค้า",
    "grandTotal": "ยอด (รวม Vat)",
    "document": "เอกสาร",
    "openFile": "เปิดไฟล์",
    "email": "อีเมล",
    "emailDate": "วันที่ส่งอีเมล",
    "sendingEmail": "กำลังส่งอีเมล...",
    "success": "สำเร็จ",
    "emailNotFound": "ไม่พบอีเมล",
    "fail": "ไม่สำเร็จ",
    "file": "ไฟล์",
    "openDocument": "เปิดเอกสาร",
    "reCreateDocument": "สร้างใบใหม่ข้อมูลเดิม",
    "reIssuedDocument": "สร้างใบใหม่แทนใบเดิม",
    "delete": "ลบ",
    "deleteDisable": "ลบ (ส่งสรรพากรแล้ว)",
    "sendEmailSuccess": "ส่งอีเมลสำเร็จ!",
    "sendEmailFail": "ส่งอีเมลไม่สำเร็จ!",
    "sendSmsSuccess": "ส่งข้อความ SMS สำเร็จ!",
    "sendSmsFail": "ส่งข้อความ SMS ไม่สำเร็จ!",
    "instantTopup": "เติมเครดิต เร็วทันใจ 24 ชม.",
    "select": "เลือก",
    "isRequiredErrorMessage": "กรุณาเลือกวันที่",
    "inputTaxReport": "ดูรายงานภาษีซื้อ",
    "downloadPdf": "ดาวน์โหลด PDF",
    "downloadCsv": "ดาวน์โหลด CSV",
    "taxId": "เลขประจำตัวผู้เสียภาษี",
    "branchNumber": "สถานประกอบการ",
    "amountTotal": "ยอด (ไม่รวม VAT)",
    "vatTotal": "VAT",
    "header": "รายงานการนำส่ง SMS",
    "documentUnit": "ใบ",
    "purchaseTotal": "ยอดซื้อ (ไม่รวม VAT)",
    "purchaseVat": "ภาษีซื้อ (VAT)",
    "loading": "กำลังโหลด...",
    "search": "ค้นหา",
    "searchPlaceholder": "ค้นหา",
    "saleTotal": "ยอดขาย (ไม่รวม VAT)",
    "saleVat": "ภาษีขาย (VAT)",
    "to": "เบอร์ผู้รับ",
    "documentTypeName": "ประเภทเอกสาร",
    "provider": "ผู้ให้บริการข้อความ",
    "timeRange": "ช่วงเวลา",
    "fromRange": "จาก",
    "toRange": "ถึง",
    "timeRangeError": "กรุณาระบุช่วงเวลาไม่เกิน 30 วัน",
    "timeRangeError2": "กรุณาระบุช่วงเวลาไม่เกิน 15 วัน",
    "month": "เดือน ",
    "company": "ชื่อผู้ประกอบการ ",
    "companyTaxId": "เลขประจำตัวผู้เสียภาษี ",
    "address": "ที่อยู่ ",
    "message": "ข้อความ",
  },
  "outputTax": {
    "warningSetupCertificate": "เอกสาร e-Tax Invoice & e-Receipt จะสมบูรณ์ก็ต่อเมื่อใช้ใบรับรองอิเล็กทรอนิกส์ของจริงเท่านั้น",
    "warningSetupCertificateLink": "ติดตั้งใบรับรองอิเล็กทรอนิกส์",
    "warningXmlDelivery": "ไฟล์ XML จะส่งกรมสรรพากร วันที่ 5 ของทุกเดือน",
    "close": "ปิด",
    "allDocuments": "เอกสารรายรับทั้งหมด",
    "totalDocuments": "จำนวนเอกสาร",
    "vat": "ภาษีขาย (VAT)",
    "salesAmount": "ยอดขาย (รวม VAT)",
    "documents": "เอกสาร",
    "thb": "บาท",
    "signProcessing": "กำลังสร้างเอกสารและลงลายเซ็นดิจิทัล...",
    "sendFileByEmail": "ส่งไฟล์ทางอีเมล",
    "sendToEmail": "ส่งไปที่อีเมล",
    "ccToEmail": "สำเนาไปที่อีเมล",
    "sendEmail": "ส่งอีเมล",
    "cancel": "ยกเลิก",
    "sendFileBySms": "ส่งไฟล์ทาง SMS",
    "mobileNumer": "เบอร์มือถือ",
    "exampleNumber": "ตัวอย่าง 0825798555",
    "sendSms": "ส่งข้อความ SMS",
    "shortMonths01": "ม.ค.",
    "shortMonths02": "ก.พ.",
    "shortMonths03": "มี.ค.",
    "shortMonths04": "เม.ย.",
    "shortMonths05": "พ.ค.",
    "shortMonths06": "มิ.ย.",
    "shortMonths07": "ก.ค.",
    "shortMonths08": "ส.ค.",
    "shortMonths09": "ก.ย.",
    "shortMonths10": "ต.ค.",
    "shortMonths11": "พ.ย.",
    "shortMonths12": "ธ.ค.",
    "months01": "มกราคม",
    "months02": "กุมภาพันธ์",
    "months03": "มีนาคม",
    "months04": "เมษายน",
    "months05": "พฤษภาคม",
    "months06": "มิถุนายน",
    "months07": "กรกฎาคม",
    "months08": "สิงหาคม",
    "months09": "กันยายน",
    "months10": "ตุลาคม",
    "months11": "พฤศจิกายน",
    "months12": "ธันวาคม",
    "allDocumentsType": "เอกสารทุกประเภท",
    "receipt-taxinvoice": "ใบเสร็จรับเงิน/ใบกำกับภาษี",
    "receipt-taxinvoice-abb": "ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ",
    "receipt": "ใบเสร็จรับเงิน",
    "invoice-taxinvoice": "ใบแจ้งหนี้/ใบกำกับภาษี",
    "deliveryorder-taxinvoice": "ใบส่งของ/ใบกำกับภาษี",
    "taxinvoice": "ใบกำกับภาษี",
    "debit-note": "ใบเพิ่มหนี้",
    "credit-note": "ใบลดหนี้",
    "quotation": "ใบเสนอราคา",
    "invoice": "ใบแจ้งหนี้",
    "billing-note": "ใบวางบิล",
    "createdDate": "วันที่สร้างเอกสาร",
    "documentDate": "วันที่บนเอกสาร",
    "thisMonth": "เดือนนี้",
    "documentPerPage": "เอกสาร/หน้า",
    "page": "หน้า",
    "refresh": "รีเฟรช",
    "confirmSendEmail": "คุณต้องการที่จะส่งอีเมล",
    "create": "สร้าง",
    "combinedReceipt": "ใบเสร็จรับเงินรวม",
    "combinedReceiptAlert": "ขออภัย มีประเภทเอกสารที่ไม่สามารถสร้างใบเสร็จรับเงินรวมได้",
    "download": "ดาวน์โหลด",
    "emailAlert": "กรุณากรอกอีเมลที่ถูกต้อง",
    "mobileNumberAlert": "กรุณากรอกเบอร์มือถือที่ถูกต้อง",
    "number": "เลขที่",
    "customerName": "ชื่อลูกค้า",
    "grandTotal": "ยอด (รวม Vat)",
    "document": "เอกสาร",
    "openFile": "เปิดไฟล์",
    "email": "อีเมล",
    "emailDate": "วันที่ส่งอีเมล",
    "sendingEmail": "กำลังส่งอีเมล...",
    "success": "สำเร็จ",
    "emailNotFound": "ไม่พบอีเมล",
    "fail": "ไม่สำเร็จ",
    "file": "ไฟล์",
    "openDocument": "เปิดเอกสาร",
    "reCreateDocument": "สร้างใบใหม่ข้อมูลเดิม",
    "reIssuedDocument": "สร้างใบใหม่แทนใบเดิม",
    "delete": "ลบ",
    "deleteDisable": "ลบ (ส่งสรรพากรแล้ว)",
    "sendEmailSuccess": "ส่งอีเมลสำเร็จ!",
    "sendEmailFail": "ส่งอีเมลไม่สำเร็จ!",
    "sendSmsSuccess": "ส่งข้อความ SMS สำเร็จ!",
    "sendSmsFail": "ส่งข้อความ SMS ไม่สำเร็จ!",
    "instantTopup": "เติมเครดิต เร็วทันใจ 24 ชม.",
    "select": "เลือก",
    "isRequiredErrorMessage": "กรุณาเลือกวันที่",
    "expenseTaxReport": "ดูรายงานภาษีซื้อ",
    "downloadPdf": "ดาวน์โหลด PDF",
    "downloadCsv": "ดาวน์โหลด CSV",
    "taxId": "เลขประจำตัวผู้เสียภาษี",
    "branchNumber": "สถานประกอบการ",
    "amountTotal": "ยอด (ไม่รวม VAT)",
    "vatTotal": "VAT",
    "documentUnit": "ใบ",
    "purchaseTotal": "ยอดซื้อ (ไม่รวม VAT)",
    "purchaseVat": "ภาษีซื้อ (VAT)",
    "loading": "กำลังโหลด...",
    "search": "ค้นหา",
    "searchPlaceholder": "ค้นหา",
    "report": "ดูรายงานภาษีขาย",
    "header": "รายงานภาษีขาย",
    "saleTotal": "ยอดขาย (ไม่รวม VAT)",
    "saleVat": "ภาษีขาย (VAT)",
    "showRepeatNumberMessage": "ระบบพบว่ามีเลขที่เอกสารซ้ำกัน",
    "showRepeatReferenceMessage": "ระบบพบว่ามีเอกสารที่เลขอ้างอิงซ้ำกัน",
  },
  "outputTaxConfirm": {
    "confirm": "ยืนยัน",
    "edit": "แก้ไข",
    "processing": "กำลังดำเนินการ",
    "processingMessage": "สร้างตัวอย่างเอกสาร รายงานภาษีขาย",
    "downloadPdf": "ดาวน์โหลด PDF",
    "downloadCsv": "ดาวน์โหลด CSV",
    "back": "กลับ",
    "loading": "กำลังโหลด...",
  },
  "sellSummary": {
    "warningSetupCertificate": "เอกสาร e-Tax Invoice & e-Receipt จะสมบูรณ์ก็ต่อเมื่อใช้ใบรับรองอิเล็กทรอนิกส์ของจริงเท่านั้น",
    "warningSetupCertificateLink": "ติดตั้งใบรับรองอิเล็กทรอนิกส์",
    "warningXmlDelivery": "ไฟล์ XML จะส่งกรมสรรพากร วันที่ 5 ของทุกเดือน",
    "close": "ปิด",
    "allDocuments": "เอกสารรายรับทั้งหมด",
    "totalDocuments": "จำนวนเอกสาร",
    "vat": "ภาษีขาย (VAT)",
    "salesAmount": "ยอดขาย (รวม VAT)",
    "documents": "เอกสาร",
    "thb": "บาท",
    "signProcessing": "กำลังสร้างเอกสารและลงลายเซ็นดิจิทัล...",
    "sendFileByEmail": "ส่งไฟล์ทางอีเมล",
    "sendToEmail": "ส่งไปที่อีเมล",
    "ccToEmail": "สำเนาไปที่อีเมล",
    "sendEmail": "ส่งอีเมล",
    "cancel": "ยกเลิก",
    "sendFileBySms": "ส่งไฟล์ทาง SMS",
    "mobileNumer": "เบอร์มือถือ",
    "exampleNumber": "ตัวอย่าง 0825798555",
    "sendSms": "ส่งข้อความ SMS",
    "shortMonths01": "ม.ค.",
    "shortMonths02": "ก.พ.",
    "shortMonths03": "มี.ค.",
    "shortMonths04": "เม.ย.",
    "shortMonths05": "พ.ค.",
    "shortMonths06": "มิ.ย.",
    "shortMonths07": "ก.ค.",
    "shortMonths08": "ส.ค.",
    "shortMonths09": "ก.ย.",
    "shortMonths10": "ต.ค.",
    "shortMonths11": "พ.ย.",
    "shortMonths12": "ธ.ค.",
    "months01": "มกราคม",
    "months02": "กุมภาพันธ์",
    "months03": "มีนาคม",
    "months04": "เมษายน",
    "months05": "พฤษภาคม",
    "months06": "มิถุนายน",
    "months07": "กรกฎาคม",
    "months08": "สิงหาคม",
    "months09": "กันยายน",
    "months10": "ตุลาคม",
    "months11": "พฤศจิกายน",
    "months12": "ธันวาคม",
    "allDocumentsType": "เอกสารทุกประเภท",
    "receipt-taxinvoice": "ใบเสร็จรับเงิน/ใบกำกับภาษี",
    "receipt-taxinvoice-abb": "ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ",
    "receipt": "ใบเสร็จรับเงิน",
    "invoice-taxinvoice": "ใบแจ้งหนี้/ใบกำกับภาษี",
    "deliveryorder-taxinvoice": "ใบส่งของ/ใบกำกับภาษี",
    "taxinvoice": "ใบกำกับภาษี",
    "debit-note": "ใบเพิ่มหนี้",
    "credit-note": "ใบลดหนี้",
    "quotation": "ใบเสนอราคา",
    "invoice": "ใบแจ้งหนี้",
    "billing-note": "ใบวางบิล",
    "createdDate": "วันที่สร้างเอกสาร",
    "documentDate": "วันที่บนเอกสาร",
    "thisMonth": "เดือนนี้",
    "documentPerPage": "เอกสาร/หน้า",
    "page": "หน้า",
    "refresh": "รีเฟรช",
    "confirmSendEmail": "คุณต้องการที่จะส่งอีเมล",
    "create": "สร้าง",
    "combinedReceipt": "ใบเสร็จรับเงินรวม",
    "combinedReceiptAlert": "ขออภัย มีประเภทเอกสารที่ไม่สามารถสร้างใบเสร็จรับเงินรวมได้",
    "download": "ดาวน์โหลด",
    "emailAlert": "กรุณากรอกอีเมลที่ถูกต้อง",
    "mobileNumberAlert": "กรุณากรอกเบอร์มือถือที่ถูกต้อง",
    "number": "เลขที่",
    "customerName": "ชื่อลูกค้า",
    "grandTotal": "ยอด (รวม Vat)",
    "document": "เอกสาร",
    "openFile": "เปิดไฟล์",
    "email": "อีเมล",
    "emailDate": "วันที่ส่งอีเมล",
    "sendingEmail": "กำลังส่งอีเมล...",
    "success": "สำเร็จ",
    "emailNotFound": "ไม่พบอีเมล",
    "fail": "ไม่สำเร็จ",
    "file": "ไฟล์",
    "openDocument": "เปิดเอกสาร",
    "reCreateDocument": "สร้างใบใหม่ข้อมูลเดิม",
    "reIssuedDocument": "สร้างใบใหม่แทนใบเดิม",
    "delete": "ลบ",
    "deleteDisable": "ลบ (ส่งสรรพากรแล้ว)",
    "sendEmailSuccess": "ส่งอีเมลสำเร็จ!",
    "sendEmailFail": "ส่งอีเมลไม่สำเร็จ!",
    "sendSmsSuccess": "ส่งข้อความ SMS สำเร็จ!",
    "sendSmsFail": "ส่งข้อความ SMS ไม่สำเร็จ!",
    "instantTopup": "เติมเครดิต เร็วทันใจ 24 ชม.",
    "select": "เลือก",
    "isRequiredErrorMessage": "กรุณาเลือกวันที่",
    "expenseTaxReport": "ดูรายงานภาษีซื้อ",
    "downloadPdf": "ดาวน์โหลด PDF",
    "downloadCsv": "ดาวน์โหลด CSV",
    "taxId": "เลขประจำตัวผู้เสียภาษี",
    "branchNumber": "สถานประกอบการ",
    "amountTotal": "ยอด (ไม่รวม VAT)",
    "vatTotal": "VAT",
    "documentUnit": "ใบ",
    "purchaseTotal": "ยอดซื้อ (ไม่รวม VAT)",
    "purchaseVat": "ภาษีซื้อ (VAT)",
    "loading": "กำลังโหลด...",
    "search": "ค้นหา",
    "searchPlaceholder": "ค้นหา",
    "saleTotal": "ยอดขาย (ไม่รวม VAT)",
    "report": "ดูรายงานยอดขาย",
    "header": "รายงานยอดขาย",
    "saleVat": "ภาษีขาย (VAT)",
    "saleTotalWithVat": "ยอดขาย (รวม VAT)",
    "timeRange": "ช่วงเวลา",
    "fromRange": "จาก",
    "toRange": "ถึง",
    "timeRangeError": "กรุณาระบุช่วงเวลาไม่เกิน 30 วัน",
    "timeRangeError2": "กรุณาระบุช่วงเวลาไม่เกิน 15 วัน",
    "reference": "อ้างอิง",
  },
  "sellSummaryConfirm": {
    "confirm": "ยืนยัน",
    "edit": "แก้ไข",
    "processing": "กำลังดำเนินการ",
    "processingMessage": "สร้างตัวอย่างเอกสาร รายงานยอดขาย",
    "downloadPdf": "ดาวน์โหลด PDF",
    "downloadCsv": "ดาวน์โหลด CSV",
    "back": "กลับ",
    "loading": "กำลังโหลด...",
  },
  "xmlUpload": {
    "warningSetupCertificate": "เอกสาร e-Tax Invoice & e-Receipt จะสมบูรณ์ก็ต่อเมื่อใช้ใบรับรองอิเล็กทรอนิกส์ของจริงเท่านั้น",
    "warningSetupCertificateLink": "ติดตั้งใบรับรองอิเล็กทรอนิกส์",
    "warningXmlDelivery": "ไฟล์ XML จะส่งกรมสรรพากร วันที่ 5 ของทุกเดือน",
    "close": "ปิด",
    "allDocuments": "เอกสารรายรับทั้งหมด",
    "totalDocuments": "จำนวนเอกสาร",
    "vat": "ภาษีขาย (VAT)",
    "salesAmount": "ยอดขาย (รวม VAT)",
    "documents": "เอกสาร",
    "thb": "บาท",
    "signProcessing": "กำลังสร้างเอกสารและลงลายเซ็นดิจิทัล...",
    "sendFileByEmail": "ส่งไฟล์ทางอีเมล",
    "sendToEmail": "ส่งไปที่อีเมล",
    "ccToEmail": "สำเนาไปที่อีเมล",
    "sendEmail": "ส่งอีเมล",
    "cancel": "ยกเลิก",
    "sendFileBySms": "ส่งไฟล์ทาง SMS",
    "mobileNumer": "เบอร์มือถือ",
    "exampleNumber": "ตัวอย่าง 0825798555",
    "sendSms": "ส่งข้อความ SMS",
    "shortMonths01": "ม.ค.",
    "shortMonths02": "ก.พ.",
    "shortMonths03": "มี.ค.",
    "shortMonths04": "เม.ย.",
    "shortMonths05": "พ.ค.",
    "shortMonths06": "มิ.ย.",
    "shortMonths07": "ก.ค.",
    "shortMonths08": "ส.ค.",
    "shortMonths09": "ก.ย.",
    "shortMonths10": "ต.ค.",
    "shortMonths11": "พ.ย.",
    "shortMonths12": "ธ.ค.",
    "months01": "มกราคม",
    "months02": "กุมภาพันธ์",
    "months03": "มีนาคม",
    "months04": "เมษายน",
    "months05": "พฤษภาคม",
    "months06": "มิถุนายน",
    "months07": "กรกฎาคม",
    "months08": "สิงหาคม",
    "months09": "กันยายน",
    "months10": "ตุลาคม",
    "months11": "พฤศจิกายน",
    "months12": "ธันวาคม",
    "allDocumentsType": "เอกสารทุกประเภท",
    "receipt-taxinvoice": "ใบเสร็จรับเงิน/ใบกำกับภาษี",
    "receipt-taxinvoice-abb": "ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ",
    "receipt": "ใบเสร็จรับเงิน",
    "invoice-taxinvoice": "ใบแจ้งหนี้/ใบกำกับภาษี",
    "deliveryorder-taxinvoice": "ใบส่งของ/ใบกำกับภาษี",
    "taxinvoice": "ใบกำกับภาษี",
    "debit-note": "ใบเพิ่มหนี้",
    "credit-note": "ใบลดหนี้",
    "quotation": "ใบเสนอราคา",
    "invoice": "ใบแจ้งหนี้",
    "billing-note": "ใบวางบิล",
    "createdDate": "วันที่สร้างเอกสาร",
    "documentDate": "วันที่บนเอกสาร",
    "thisMonth": "เดือนนี้",
    "documentPerPage": "เอกสาร/หน้า",
    "page": "หน้า",
    "refresh": "รีเฟรช",
    "confirmSendEmail": "คุณต้องการที่จะส่งอีเมล",
    "create": "สร้าง",
    "combinedReceipt": "ใบเสร็จรับเงินรวม",
    "combinedReceiptAlert": "ขออภัย มีประเภทเอกสารที่ไม่สามารถสร้างใบเสร็จรับเงินรวมได้",
    "download": "ดาวน์โหลด",
    "emailAlert": "กรุณากรอกอีเมลที่ถูกต้อง",
    "mobileNumberAlert": "กรุณากรอกเบอร์มือถือที่ถูกต้อง",
    "number": "เลขที่",
    "customerName": "ชื่อลูกค้า",
    "grandTotal": "ยอด (รวม Vat)",
    "document": "เอกสาร",
    "openFile": "เปิดไฟล์",
    "email": "อีเมล",
    "emailDate": "วันที่ส่งอีเมล",
    "sendingEmail": "กำลังส่งอีเมล...",
    "success": "สำเร็จ",
    "emailNotFound": "ไม่พบอีเมล",
    "fail": "ไม่สำเร็จ",
    "file": "ไฟล์",
    "openDocument": "เปิดเอกสาร",
    "reCreateDocument": "สร้างใบใหม่ข้อมูลเดิม",
    "reIssuedDocument": "สร้างใบใหม่แทนใบเดิม",
    "delete": "ลบ",
    "deleteDisable": "ลบ (ส่งสรรพากรแล้ว)",
    "sendEmailSuccess": "ส่งอีเมลสำเร็จ!",
    "sendEmailFail": "ส่งอีเมลไม่สำเร็จ!",
    "sendSmsSuccess": "ส่งข้อความ SMS สำเร็จ!",
    "sendSmsFail": "ส่งข้อความ SMS ไม่สำเร็จ!",
    "instantTopup": "เติมเครดิต เร็วทันใจ 24 ชม.",
    "select": "เลือก",
    "isRequiredErrorMessage": "กรุณาเลือกวันที่",
    "expenseTaxReport": "ดูรายงานภาษีซื้อ",
    "downloadPdf": "ดาวน์โหลด PDF",
    "downloadCsv": "ดาวน์โหลด CSV",
    "taxId": "เลขประจำตัวผู้เสียภาษี",
    "branchNumber": "สถานประกอบการ",
    "amountTotal": "ยอด (ไม่รวม VAT)",
    "vatTotal": "VAT",
    "documentUnit": "ใบ",
    "purchaseTotal": "ยอดซื้อ (ไม่รวม VAT)",
    "purchaseVat": "ภาษีซื้อ (VAT)",
    "loading": "กำลังโหลด...",
    "search": "ค้นหา",
    "searchPlaceholder": "ค้นหา",
    "saleTotalWithVat": "ยอดขาย (รวม VAT)",
    "report": "ดูรายงานการนำส่ง XML",
    "rdStatus": "สถานะนำส่ง",
    "rdTime": "วันที่นำส่ง",
    "rdRefNumber": "หมายเลขอ้างอิงกรมสรรพากร",
    "header": "รายงานการนำส่ง XML",
    "saleTotal": "ยอดขาย (ไม่รวม VAT)",
    "saleVat": "ภาษีขาย (VAT)",
  },
  "xmlUploadConfirm": {
    "confirm": "ยืนยัน",
    "edit": "แก้ไข",
    "processing": "กำลังดำเนินการ",
    "processingMessage": "สร้างตัวอย่างเอกสาร รายงานการนำส่ง XML",
    "downloadPdf": "ดาวน์โหลด PDF",
    "downloadCsv": "ดาวน์โหลด CSV",
    "back": "กลับ",
    "loading": "กำลังโหลด...",
  },
  "xmlDeliveries": {
    "allDocumentsType": "เอกสารทุกประเภท",
    "receipt-taxinvoice": "ใบเสร็จรับเงิน/ใบกำกับภาษี",
    "receipt": "ใบเสร็จรับเงิน",
    "invoice-taxinvoice": "ใบแจ้งหนี้/ใบกำกับภาษี",
    "deliveryorder-taxinvoice": "ใบส่งของ/ใบกำกับภาษี",
    "taxinvoice": "ใบกำกับภาษี",
    "debit-note": "ใบเพิ่มหนี้",
    "credit-note": "ใบลดหนี้",
    "createdDate": "วันที่สร้างเอกสาร",
    "documentDate": "วันที่บนเอกสาร",
    "timeRange": "ช่วงเวลา",
    "fromRange": "จาก",
    "toRange": "ถึง",
    "timeRangeError": "กรุณาระบุช่วงเวลาไม่เกิน 30 วัน",
    "pleaseSelect": "กรุณาเลือก",
    "search": "ค้นหา",
    "documentPerPage": " เอกสาร/หน้า",
    "page": "หน้า",
    "refresh": "รีเฟรช",
    "waiting": "รอดำเนินการ",
    "uploading": "กำลังส่งข้อมูล",
    "createdTime": "วันที่สร้าง",
    "number": "เลขที่",
    "customerName": "ชื่อลูกค้า",
    "amountTotal": "ยอด (ไม่รวม VAT)",
    "document": "เอกสาร",
    "rdTime": "วันที่นำส่ง",
    "rdRefNumber": "หมายเลขอ้างอิงกรมสรรพากร",
    "rdStatus": "สถานะนำส่ง",
    "file": "ไฟล์",
    "warningXmlDelivery": "นำส่งไฟล์ XML ภายในวันที่ 15 ของเดือนถัดไป โดยดูจากวันที่บนเอกสาร",
    "warningCertificateExpired": "ณ วันที่นำส่งข้อมูล ใบรับรองอิเล็กทรอนิกส์ที่อยู่ในเอกสารต้องไม่หมดอายุ หากใบรับรองอิเล็กทรอนิกส์ในเอกสารหมดอายุจะไม่สามารถนำส่งข้อมูลได้",
    "warningActivateXmlDelivery": "เปิดใช้งานการนำส่งไฟล์ XML เข้าระบบ e-Tax Invoice & e-Receipt ของกรมสรรพากร ผ่าน Service Provider, กรุณาส่งอีเมลแจ้งเปิดใช้งานที่ support@leceipt.com",
    "copied": "Copy สำเร็จ!",
    "header": "นำส่งไฟล์ XML ผ่าน Service Provider",
    "notSubmittedCard": "ยังไม่ได้นำส่ง",
    "documents": "เอกสาร",
    "totalDocuments": "จำนวนเอกสาร",
    "thb": "บาท",
    "vat": "ภาษีขาย (VAT)",
    "xmlDeliveryButton": "นำส่งไฟล์ XML",
    "stopButton": "หยุดทำงาน",
    "allRdStatus": "สถานะนำส่งทั้งหมด",
    "shortMonths01": "ม.ค.",
    "shortMonths02": "ก.พ.",
    "shortMonths03": "มี.ค.",
    "shortMonths04": "เม.ย.",
    "shortMonths05": "พ.ค.",
    "shortMonths06": "มิ.ย.",
    "shortMonths07": "ก.ค.",
    "shortMonths08": "ส.ค.",
    "shortMonths09": "ก.ย.",
    "shortMonths10": "ต.ค.",
    "shortMonths11": "พ.ย.",
    "shortMonths12": "ธ.ค.",
    "months01": "มกราคม",
    "months02": "กุมภาพันธ์",
    "months03": "มีนาคม",
    "months04": "เมษายน",
    "months05": "พฤษภาคม",
    "months06": "มิถุนายน",
    "months07": "กรกฎาคม",
    "months08": "สิงหาคม",
    "months09": "กันยายน",
    "months10": "ตุลาคม",
    "months11": "พฤศจิกายน",
    "months12": "ธันวาคม",
    "days01": "อาทิตย์",
    "days02": "จันทร์",
    "days03": "อังคาร",
    "days04": "พุธ",
    "days05": "พฤหัสบดี",
    "days06": "ศุกร์",
    "days07": "เสาร์",
    "shortDays01": "อา.",
    "shortDays02": "จ.",
    "shortDays03": "อ.",
    "shortDays04": "พ.",
    "shortDays05": "พฤ.",
    "shortDays06": "ศ.",
    "shortDays07": "ส.",
    "goToToday": "ไปวันที่ปัจจุบัน",
    "invalidInputErrorMessage": "รูปแบบวันที่ไม่ถูกต้อง",
    "prevMonthAriaLabel": "ไปเดือนที่แล้ว",
    "nextMonthAriaLabel": "ไปเดือนถัดไป",
    "prevYearAriaLabel": "ไปปีที่แล้ว",
    "nextYearAriaLabel": "ไปปีถัดไป",
    "monthPickerHeaderAriaLabel": "{0}, เลือกเพื่อเปลี่ยนเดือน",
    "yearPickerHeaderAriaLabel": "{0}, เลือกเพื่อเปลี่ยนปี",
    "pendingRdStatus": "ยังไม่ได้นำส่ง",
    "spPendingRdStatus": "นำส่งแล้ว (รอสถานะ)",
    "spPendingRdStatusShort": "นำส่งแล้ว",
    "successfulRdStatus": "นำส่งสำเร็จ",
    "failRdStatus": "นำส่งไม่สำเร็จ",
    "close": "ปิด",
    "cancel": "ยกเลิก",
    "dialogXmlDeliveryHeader": "ยืนยันการนำส่งไฟล์ XML",
    "dialogBatchXmlDeliveryMessage": "คุณยืนยันที่จะนำส่งไฟล์ XML ที่เลือกไว้หรือไม่",
    "confirm": "ยืนยัน",
    "showRepeatNumberMessage": "ระบบพบว่ามีเลขที่เอกสารซ้ำกัน",
    "isRequiredErrorMessage": "กรุณาเลือกวันที่",
  },
  "pnd2": {
    "warningSetupCertificate": "เอกสาร e-Tax Invoice & e-Receipt จะสมบูรณ์ก็ต่อเมื่อใช้ใบรับรองอิเล็กทรอนิกส์ของจริงเท่านั้น",
    "warningSetupCertificateLink": "ติดตั้งใบรับรองอิเล็กทรอนิกส์",
    "warningXmlDelivery": "ไฟล์ XML จะส่งกรมสรรพากร วันที่ 5 ของทุกเดือน",
    "close": "ปิด",
    "allDocuments": "เอกสารรายรับทั้งหมด",
    "vat": "ภาษีขาย (VAT)",
    "salesAmount": "ยอดขาย (รวม VAT)",
    "documents": "เอกสาร",
    "thb": "บาท",
    "signProcessing": "กำลังสร้างเอกสารและลงลายเซ็นดิจิทัล...",
    "sendSms": "ส่งข้อความ SMS",
    "shortMonths01": "ม.ค.",
    "shortMonths02": "ก.พ.",
    "shortMonths03": "มี.ค.",
    "shortMonths04": "เม.ย.",
    "shortMonths05": "พ.ค.",
    "shortMonths06": "มิ.ย.",
    "shortMonths07": "ก.ค.",
    "shortMonths08": "ส.ค.",
    "shortMonths09": "ก.ย.",
    "shortMonths10": "ต.ค.",
    "shortMonths11": "พ.ย.",
    "shortMonths12": "ธ.ค.",
    "months01": "มกราคม",
    "months02": "กุมภาพันธ์",
    "months03": "มีนาคม",
    "months04": "เมษายน",
    "months05": "พฤษภาคม",
    "months06": "มิถุนายน",
    "months07": "กรกฎาคม",
    "months08": "สิงหาคม",
    "months09": "กันยายน",
    "months10": "ตุลาคม",
    "months11": "พฤศจิกายน",
    "months12": "ธันวาคม",
    "allDocumentsType": "เอกสารทุกประเภท",
    "receipt-taxinvoice": "ใบเสร็จรับเงิน/ใบกำกับภาษี",
    "receipt-taxinvoice-abb": "ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ",
    "receipt": "ใบเสร็จรับเงิน",
    "invoice-taxinvoice": "ใบแจ้งหนี้/ใบกำกับภาษี",
    "deliveryorder-taxinvoice": "ใบส่งของ/ใบกำกับภาษี",
    "taxinvoice": "ใบกำกับภาษี",
    "debit-note": "ใบเพิ่มหนี้",
    "credit-note": "ใบลดหนี้",
    "quotation": "ใบเสนอราคา",
    "invoice": "ใบแจ้งหนี้",
    "billing-note": "ใบวางบิล",
    "createdDate": "วันที่สร้างเอกสาร",
    "thisMonth": "เดือนนี้",
    "documentPerPage": "เอกสาร/หน้า",
    "page": "หน้า",
    "refresh": "รีเฟรช",
    "confirmSendEmail": "คุณต้องการที่จะส่งอีเมล",
    "create": "สร้าง",
    "combinedReceipt": "ใบเสร็จรับเงินรวม",
    "combinedReceiptAlert": "ขออภัย มีประเภทเอกสารที่ไม่สามารถสร้างใบเสร็จรับเงินรวมได้",
    "download": "ดาวน์โหลด",
    "emailAlert": "กรุณากรอกอีเมลที่ถูกต้อง",
    "mobileNumberAlert": "กรุณากรอกเบอร์มือถือที่ถูกต้อง",
    "number": "เลขที่",
    "customerName": "ชื่อลูกค้า",
    "document": "เอกสาร",
    "openFile": "เปิดไฟล์",
    "email": "อีเมล",
    "emailDate": "วันที่ส่งอีเมล",
    "sendingEmail": "กำลังส่งอีเมล...",
    "success": "สำเร็จ",
    "emailNotFound": "ไม่พบอีเมล",
    "fail": "ไม่สำเร็จ",
    "openDocument": "เปิดเอกสาร",
    "reCreateDocument": "สร้างใบใหม่ข้อมูลเดิม",
    "reIssuedDocument": "สร้างใบใหม่แทนใบเดิม",
    "delete": "ลบ",
    "deleteDisable": "ลบ (ส่งสรรพากรแล้ว)",
    "instantTopup": "เติมเครดิต เร็วทันใจ 24 ชม.",
    "select": "เลือก",
    "isRequiredErrorMessage": "กรุณาเลือกวันที่",
    "expenseTaxReport": "ดูรายงานภาษีซื้อ",
    "branchNumber": "สถานประกอบการ",
    "amountTotal": "ยอด (ไม่รวม VAT)",
    "purchaseTotal": "ยอดซื้อ (ไม่รวม VAT)",
    "purchaseVat": "ภาษีซื้อ (VAT)",
    "loading": "กำลังโหลด...",
    "search": "ค้นหา",
    "searchPlaceholder": "ค้นหา",
    "saleTotalWithVat": "ยอดขาย (รวม VAT)",
    "report": "ดูรายงานการนำส่ง XML",
    "rdStatus": "สถานะนำส่ง",
    "rdTime": "วันที่นำส่ง",
    "rdRefNumber": "หมายเลขอ้างอิงกรมสรรพากร",
    "saleTotal": "ยอดขาย (ไม่รวม VAT)",
    "saleVat": "ภาษีขาย (VAT)",
    "downloadTxt": "ดาวน์โหลดไฟล์ RDPrep (.txt)",
    "downloadCsv": "ดาวน์โหลดไฟล์ RDPrep (.csv)",
    "row": "รายที่",
    "documentDate": "วันที่หัก ณ ที่จ่าย",
    "taxId": "เลขประจำตัวผู้เสียภาษี",
    "name": "ชื่อผู้มีเงินได้",
    "grandTotal": "เงินที่จ่าย",
    "vatTotal": "เงินภาษีที่หัก",
    "file": "ไฟล์",
    "sendEmailSuccess": "ส่งอีเมลสำเร็จ!",
    "sendEmailFail": "ส่งอีเมลไม่สำเร็จ!",
    "sendSmsSuccess": "ส่งข้อความ SMS สำเร็จ!",
    "sendSmsFail": "ส่งข้อความ SMS ไม่สำเร็จ!",
    "header": "ภ.ง.ด.2",
    "subHeader": "(ดาวน์โหลดไฟล์ .txt หรือ .csv เพื่อนำไปสร้างแบบยื่นรายการภาษีเงินได้หัก ณ ที่จ่าย ภ.ง.ด.2 ในโปรแกรม RDPrep)",
    "totalDocuments": "จำนวนเอกสาร 50 ทวิ",
    "documentUnit": "รายการ",
    "totalGrand": "เงินที่จ่าย",
    "totalVat": "เงินภาษีที่หัก",
    "sendFileByEmail": "ส่งไฟล์ทางอีเมล",
    "sendToEmail": "ส่งไปที่อีเมล",
    "ccToEmail": "สำเนาไปที่อีเมล",
    "sendEmail": "ส่งอีเมล",
    "cancel": "ยกเลิก",
    "sendFileBySms": "ส่งไฟล์ทาง SMS",
    "mobileNumer": "เบอร์มือถือ",
    "exampleNumber": "ตัวอย่าง 0825798555",
  },
  "pnd3": {
    "warningSetupCertificate": "เอกสาร e-Tax Invoice & e-Receipt จะสมบูรณ์ก็ต่อเมื่อใช้ใบรับรองอิเล็กทรอนิกส์ของจริงเท่านั้น",
    "warningSetupCertificateLink": "ติดตั้งใบรับรองอิเล็กทรอนิกส์",
    "warningXmlDelivery": "ไฟล์ XML จะส่งกรมสรรพากร วันที่ 5 ของทุกเดือน",
    "close": "ปิด",
    "allDocuments": "เอกสารรายรับทั้งหมด",
    "vat": "ภาษีขาย (VAT)",
    "salesAmount": "ยอดขาย (รวม VAT)",
    "documents": "เอกสาร",
    "thb": "บาท",
    "signProcessing": "กำลังสร้างเอกสารและลงลายเซ็นดิจิทัล...",
    "sendSms": "ส่งข้อความ SMS",
    "shortMonths01": "ม.ค.",
    "shortMonths02": "ก.พ.",
    "shortMonths03": "มี.ค.",
    "shortMonths04": "เม.ย.",
    "shortMonths05": "พ.ค.",
    "shortMonths06": "มิ.ย.",
    "shortMonths07": "ก.ค.",
    "shortMonths08": "ส.ค.",
    "shortMonths09": "ก.ย.",
    "shortMonths10": "ต.ค.",
    "shortMonths11": "พ.ย.",
    "shortMonths12": "ธ.ค.",
    "months01": "มกราคม",
    "months02": "กุมภาพันธ์",
    "months03": "มีนาคม",
    "months04": "เมษายน",
    "months05": "พฤษภาคม",
    "months06": "มิถุนายน",
    "months07": "กรกฎาคม",
    "months08": "สิงหาคม",
    "months09": "กันยายน",
    "months10": "ตุลาคม",
    "months11": "พฤศจิกายน",
    "months12": "ธันวาคม",
    "allDocumentsType": "เอกสารทุกประเภท",
    "receipt-taxinvoice": "ใบเสร็จรับเงิน/ใบกำกับภาษี",
    "receipt-taxinvoice-abb": "ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ",
    "receipt": "ใบเสร็จรับเงิน",
    "invoice-taxinvoice": "ใบแจ้งหนี้/ใบกำกับภาษี",
    "deliveryorder-taxinvoice": "ใบส่งของ/ใบกำกับภาษี",
    "taxinvoice": "ใบกำกับภาษี",
    "debit-note": "ใบเพิ่มหนี้",
    "credit-note": "ใบลดหนี้",
    "quotation": "ใบเสนอราคา",
    "invoice": "ใบแจ้งหนี้",
    "billing-note": "ใบวางบิล",
    "createdDate": "วันที่สร้างเอกสาร",
    "thisMonth": "เดือนนี้",
    "documentPerPage": "เอกสาร/หน้า",
    "page": "หน้า",
    "refresh": "รีเฟรช",
    "confirmSendEmail": "คุณต้องการที่จะส่งอีเมล",
    "create": "สร้าง",
    "combinedReceipt": "ใบเสร็จรับเงินรวม",
    "combinedReceiptAlert": "ขออภัย มีประเภทเอกสารที่ไม่สามารถสร้างใบเสร็จรับเงินรวมได้",
    "download": "ดาวน์โหลด",
    "emailAlert": "กรุณากรอกอีเมลที่ถูกต้อง",
    "mobileNumberAlert": "กรุณากรอกเบอร์มือถือที่ถูกต้อง",
    "number": "เลขที่",
    "customerName": "ชื่อลูกค้า",
    "document": "เอกสาร",
    "openFile": "เปิดไฟล์",
    "email": "อีเมล",
    "emailDate": "วันที่ส่งอีเมล",
    "sendingEmail": "กำลังส่งอีเมล...",
    "success": "สำเร็จ",
    "emailNotFound": "ไม่พบอีเมล",
    "fail": "ไม่สำเร็จ",
    "openDocument": "เปิดเอกสาร",
    "reCreateDocument": "สร้างใบใหม่ข้อมูลเดิม",
    "reIssuedDocument": "สร้างใบใหม่แทนใบเดิม",
    "delete": "ลบ",
    "deleteDisable": "ลบ (ส่งสรรพากรแล้ว)",
    "instantTopup": "เติมเครดิต เร็วทันใจ 24 ชม.",
    "select": "เลือก",
    "isRequiredErrorMessage": "กรุณาเลือกวันที่",
    "expenseTaxReport": "ดูรายงานภาษีซื้อ",
    "branchNumber": "สถานประกอบการ",
    "amountTotal": "ยอด (ไม่รวม VAT)",
    "purchaseTotal": "ยอดซื้อ (ไม่รวม VAT)",
    "purchaseVat": "ภาษีซื้อ (VAT)",
    "loading": "กำลังโหลด...",
    "search": "ค้นหา",
    "searchPlaceholder": "ค้นหา",
    "saleTotalWithVat": "ยอดขาย (รวม VAT)",
    "report": "ดูรายงานการนำส่ง XML",
    "rdStatus": "สถานะนำส่ง",
    "rdTime": "วันที่นำส่ง",
    "rdRefNumber": "หมายเลขอ้างอิงกรมสรรพากร",
    "saleTotal": "ยอดขาย (ไม่รวม VAT)",
    "saleVat": "ภาษีขาย (VAT)",
    "downloadTxt": "ดาวน์โหลดไฟล์ RDPrep (.txt)",
    "downloadCsv": "ดาวน์โหลดไฟล์ RDPrep (.csv)",
    "row": "รายที่",
    "documentDate": "วันที่หัก ณ ที่จ่าย",
    "taxId": "เลขประจำตัวผู้เสียภาษี",
    "name": "ชื่อผู้มีเงินได้",
    "grandTotal": "เงินที่จ่าย",
    "vatTotal": "เงินภาษีที่หัก",
    "file": "ไฟล์",
    "sendEmailSuccess": "ส่งอีเมลสำเร็จ!",
    "sendEmailFail": "ส่งอีเมลไม่สำเร็จ!",
    "sendSmsSuccess": "ส่งข้อความ SMS สำเร็จ!",
    "sendSmsFail": "ส่งข้อความ SMS ไม่สำเร็จ!",
    "totalDocuments": "จำนวนเอกสาร 50 ทวิ",
    "documentUnit": "รายการ",
    "totalGrand": "เงินที่จ่าย",
    "totalVat": "เงินภาษีที่หัก",
    "sendFileByEmail": "ส่งไฟล์ทางอีเมล",
    "sendToEmail": "ส่งไปที่อีเมล",
    "ccToEmail": "สำเนาไปที่อีเมล",
    "sendEmail": "ส่งอีเมล",
    "cancel": "ยกเลิก",
    "sendFileBySms": "ส่งไฟล์ทาง SMS",
    "mobileNumer": "เบอร์มือถือ",
    "exampleNumber": "ตัวอย่าง 0825798555",
    "header": "ภ.ง.ด.3",
    "subHeader": "(ดาวน์โหลดไฟล์ .txt หรือ .csv เพื่อนำไปสร้างแบบยื่นรายการภาษีเงินได้หัก ณ ที่จ่าย ภ.ง.ด.3 ในโปรแกรม RDPrep)",
  },
  "pnd53": {
    "warningSetupCertificate": "เอกสาร e-Tax Invoice & e-Receipt จะสมบูรณ์ก็ต่อเมื่อใช้ใบรับรองอิเล็กทรอนิกส์ของจริงเท่านั้น",
    "warningSetupCertificateLink": "ติดตั้งใบรับรองอิเล็กทรอนิกส์",
    "warningXmlDelivery": "ไฟล์ XML จะส่งกรมสรรพากร วันที่ 5 ของทุกเดือน",
    "close": "ปิด",
    "allDocuments": "เอกสารรายรับทั้งหมด",
    "vat": "ภาษีขาย (VAT)",
    "salesAmount": "ยอดขาย (รวม VAT)",
    "documents": "เอกสาร",
    "thb": "บาท",
    "signProcessing": "กำลังสร้างเอกสารและลงลายเซ็นดิจิทัล...",
    "sendSms": "ส่งข้อความ SMS",
    "shortMonths01": "ม.ค.",
    "shortMonths02": "ก.พ.",
    "shortMonths03": "มี.ค.",
    "shortMonths04": "เม.ย.",
    "shortMonths05": "พ.ค.",
    "shortMonths06": "มิ.ย.",
    "shortMonths07": "ก.ค.",
    "shortMonths08": "ส.ค.",
    "shortMonths09": "ก.ย.",
    "shortMonths10": "ต.ค.",
    "shortMonths11": "พ.ย.",
    "shortMonths12": "ธ.ค.",
    "months01": "มกราคม",
    "months02": "กุมภาพันธ์",
    "months03": "มีนาคม",
    "months04": "เมษายน",
    "months05": "พฤษภาคม",
    "months06": "มิถุนายน",
    "months07": "กรกฎาคม",
    "months08": "สิงหาคม",
    "months09": "กันยายน",
    "months10": "ตุลาคม",
    "months11": "พฤศจิกายน",
    "months12": "ธันวาคม",
    "allDocumentsType": "เอกสารทุกประเภท",
    "receipt-taxinvoice": "ใบเสร็จรับเงิน/ใบกำกับภาษี",
    "receipt-taxinvoice-abb": "ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ",
    "receipt": "ใบเสร็จรับเงิน",
    "invoice-taxinvoice": "ใบแจ้งหนี้/ใบกำกับภาษี",
    "deliveryorder-taxinvoice": "ใบส่งของ/ใบกำกับภาษี",
    "taxinvoice": "ใบกำกับภาษี",
    "debit-note": "ใบเพิ่มหนี้",
    "credit-note": "ใบลดหนี้",
    "quotation": "ใบเสนอราคา",
    "invoice": "ใบแจ้งหนี้",
    "billing-note": "ใบวางบิล",
    "createdDate": "วันที่สร้างเอกสาร",
    "thisMonth": "เดือนนี้",
    "documentPerPage": "เอกสาร/หน้า",
    "page": "หน้า",
    "refresh": "รีเฟรช",
    "confirmSendEmail": "คุณต้องการที่จะส่งอีเมล",
    "create": "สร้าง",
    "combinedReceipt": "ใบเสร็จรับเงินรวม",
    "combinedReceiptAlert": "ขออภัย มีประเภทเอกสารที่ไม่สามารถสร้างใบเสร็จรับเงินรวมได้",
    "download": "ดาวน์โหลด",
    "emailAlert": "กรุณากรอกอีเมลที่ถูกต้อง",
    "mobileNumberAlert": "กรุณากรอกเบอร์มือถือที่ถูกต้อง",
    "number": "เลขที่",
    "customerName": "ชื่อลูกค้า",
    "document": "เอกสาร",
    "openFile": "เปิดไฟล์",
    "email": "อีเมล",
    "emailDate": "วันที่ส่งอีเมล",
    "sendingEmail": "กำลังส่งอีเมล...",
    "success": "สำเร็จ",
    "emailNotFound": "ไม่พบอีเมล",
    "fail": "ไม่สำเร็จ",
    "openDocument": "เปิดเอกสาร",
    "reCreateDocument": "สร้างใบใหม่ข้อมูลเดิม",
    "reIssuedDocument": "สร้างใบใหม่แทนใบเดิม",
    "delete": "ลบ",
    "deleteDisable": "ลบ (ส่งสรรพากรแล้ว)",
    "instantTopup": "เติมเครดิต เร็วทันใจ 24 ชม.",
    "select": "เลือก",
    "isRequiredErrorMessage": "กรุณาเลือกวันที่",
    "expenseTaxReport": "ดูรายงานภาษีซื้อ",
    "branchNumber": "สถานประกอบการ",
    "amountTotal": "ยอด (ไม่รวม VAT)",
    "purchaseTotal": "ยอดซื้อ (ไม่รวม VAT)",
    "purchaseVat": "ภาษีซื้อ (VAT)",
    "loading": "กำลังโหลด...",
    "search": "ค้นหา",
    "searchPlaceholder": "ค้นหา",
    "saleTotalWithVat": "ยอดขาย (รวม VAT)",
    "report": "ดูรายงานการนำส่ง XML",
    "rdStatus": "สถานะนำส่ง",
    "rdTime": "วันที่นำส่ง",
    "rdRefNumber": "หมายเลขอ้างอิงกรมสรรพากร",
    "saleTotal": "ยอดขาย (ไม่รวม VAT)",
    "saleVat": "ภาษีขาย (VAT)",
    "downloadTxt": "ดาวน์โหลดไฟล์ RDPrep (.txt)",
    "downloadCsv": "ดาวน์โหลดไฟล์ RDPrep (.csv)",
    "row": "รายที่",
    "documentDate": "วันที่หัก ณ ที่จ่าย",
    "taxId": "เลขประจำตัวผู้เสียภาษี",
    "name": "ชื่อผู้มีเงินได้",
    "grandTotal": "เงินที่จ่าย",
    "vatTotal": "เงินภาษีที่หัก",
    "file": "ไฟล์",
    "sendEmailSuccess": "ส่งอีเมลสำเร็จ!",
    "sendEmailFail": "ส่งอีเมลไม่สำเร็จ!",
    "sendSmsSuccess": "ส่งข้อความ SMS สำเร็จ!",
    "sendSmsFail": "ส่งข้อความ SMS ไม่สำเร็จ!",
    "totalDocuments": "จำนวนเอกสาร 50 ทวิ",
    "documentUnit": "รายการ",
    "totalGrand": "เงินที่จ่าย",
    "totalVat": "เงินภาษีที่หัก",
    "sendFileByEmail": "ส่งไฟล์ทางอีเมล",
    "sendToEmail": "ส่งไปที่อีเมล",
    "ccToEmail": "สำเนาไปที่อีเมล",
    "sendEmail": "ส่งอีเมล",
    "cancel": "ยกเลิก",
    "sendFileBySms": "ส่งไฟล์ทาง SMS",
    "mobileNumer": "เบอร์มือถือ",
    "exampleNumber": "ตัวอย่าง 0825798555",
    "header": "ภ.ง.ด.53",
    "subHeader": "(ดาวน์โหลดไฟล์ .txt หรือ .csv เพื่อนำไปสร้างแบบยื่นรายการภาษีเงินได้หัก ณ ที่จ่าย ภ.ง.ด.53 ในโปรแกรม RDPrep)",
  },
  "pp30": {
    "warningSetupCertificate": "เอกสาร e-Tax Invoice & e-Receipt จะสมบูรณ์ก็ต่อเมื่อใช้ใบรับรองอิเล็กทรอนิกส์ของจริงเท่านั้น",
    "warningSetupCertificateLink": "ติดตั้งใบรับรองอิเล็กทรอนิกส์",
    "warningXmlDelivery": "ไฟล์ XML จะส่งกรมสรรพากร วันที่ 5 ของทุกเดือน",
    "close": "ปิด",
    "allDocuments": "เอกสารรายรับทั้งหมด",
    "vat": "ภาษีขาย (VAT)",
    "salesAmount": "ยอดขาย (รวม VAT)",
    "documents": "เอกสาร",
    "thb": "บาท",
    "signProcessing": "กำลังสร้างเอกสารและลงลายเซ็นดิจิทัล...",
    "sendSms": "ส่งข้อความ SMS",
    "shortMonths01": "ม.ค.",
    "shortMonths02": "ก.พ.",
    "shortMonths03": "มี.ค.",
    "shortMonths04": "เม.ย.",
    "shortMonths05": "พ.ค.",
    "shortMonths06": "มิ.ย.",
    "shortMonths07": "ก.ค.",
    "shortMonths08": "ส.ค.",
    "shortMonths09": "ก.ย.",
    "shortMonths10": "ต.ค.",
    "shortMonths11": "พ.ย.",
    "shortMonths12": "ธ.ค.",
    "months01": "มกราคม",
    "months02": "กุมภาพันธ์",
    "months03": "มีนาคม",
    "months04": "เมษายน",
    "months05": "พฤษภาคม",
    "months06": "มิถุนายน",
    "months07": "กรกฎาคม",
    "months08": "สิงหาคม",
    "months09": "กันยายน",
    "months10": "ตุลาคม",
    "months11": "พฤศจิกายน",
    "months12": "ธันวาคม",
    "allDocumentsType": "เอกสารทุกประเภท",
    "receipt-taxinvoice": "ใบเสร็จรับเงิน/ใบกำกับภาษี",
    "receipt-taxinvoice-abb": "ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ",
    "receipt": "ใบเสร็จรับเงิน",
    "invoice-taxinvoice": "ใบแจ้งหนี้/ใบกำกับภาษี",
    "deliveryorder-taxinvoice": "ใบส่งของ/ใบกำกับภาษี",
    "taxinvoice": "ใบกำกับภาษี",
    "debit-note": "ใบเพิ่มหนี้",
    "credit-note": "ใบลดหนี้",
    "quotation": "ใบเสนอราคา",
    "invoice": "ใบแจ้งหนี้",
    "billing-note": "ใบวางบิล",
    "createdDate": "วันที่สร้างเอกสาร",
    "thisMonth": "เดือนนี้",
    "documentPerPage": "เอกสาร/หน้า",
    "page": "หน้า",
    "refresh": "รีเฟรช",
    "confirmSendEmail": "คุณต้องการที่จะส่งอีเมล",
    "create": "สร้าง",
    "combinedReceipt": "ใบเสร็จรับเงินรวม",
    "combinedReceiptAlert": "ขออภัย มีประเภทเอกสารที่ไม่สามารถสร้างใบเสร็จรับเงินรวมได้",
    "download": "ดาวน์โหลด",
    "emailAlert": "กรุณากรอกอีเมลที่ถูกต้อง",
    "mobileNumberAlert": "กรุณากรอกเบอร์มือถือที่ถูกต้อง",
    "number": "เลขที่",
    "customerName": "ชื่อลูกค้า",
    "document": "เอกสาร",
    "openFile": "เปิดไฟล์",
    "email": "อีเมล",
    "emailDate": "วันที่ส่งอีเมล",
    "sendingEmail": "กำลังส่งอีเมล...",
    "success": "สำเร็จ",
    "emailNotFound": "ไม่พบอีเมล",
    "fail": "ไม่สำเร็จ",
    "openDocument": "เปิดเอกสาร",
    "reCreateDocument": "สร้างใบใหม่ข้อมูลเดิม",
    "reIssuedDocument": "สร้างใบใหม่แทนใบเดิม",
    "delete": "ลบ",
    "deleteDisable": "ลบ (ส่งสรรพากรแล้ว)",
    "instantTopup": "เติมเครดิต เร็วทันใจ 24 ชม.",
    "select": "เลือก",
    "isRequiredErrorMessage": "กรุณาเลือกวันที่",
    "expenseTaxReport": "ดูรายงานภาษีซื้อ",
    "branchNumber": "สถานประกอบการ",
    "amountTotal": "ยอด (ไม่รวม VAT)",
    "purchaseTotal": "ยอดซื้อ (ไม่รวม VAT)",
    "purchaseVat": "ภาษีซื้อ (VAT)",
    "loading": "กำลังโหลด...",
    "search": "ค้นหา",
    "searchPlaceholder": "ค้นหา",
    "saleTotalWithVat": "ยอดขาย (รวม VAT)",
    "report": "ดูรายงานการนำส่ง XML",
    "rdStatus": "สถานะนำส่ง",
    "rdTime": "วันที่นำส่ง",
    "rdRefNumber": "หมายเลขอ้างอิงกรมสรรพากร",
    "saleTotal": "ยอดขาย (ไม่รวม VAT)",
    "saleVat": "ภาษีขาย (VAT)",
    "downloadTxt": "ดาวน์โหลดไฟล์ RDPrep (.txt)",
    "downloadCsv": "ดาวน์โหลดไฟล์ RDPrep (.csv)",
    "row": "รายที่",
    "taxId": "เลขประจำตัวผู้เสียภาษี",
    "name": "ชื่อผู้มีเงินได้",
    "grandTotal": "เงินที่จ่าย",
    "vatTotal": "เงินภาษีที่หัก",
    "file": "ไฟล์",
    "sendEmailSuccess": "ส่งอีเมลสำเร็จ!",
    "sendEmailFail": "ส่งอีเมลไม่สำเร็จ!",
    "sendSmsSuccess": "ส่งข้อความ SMS สำเร็จ!",
    "sendSmsFail": "ส่งข้อความ SMS ไม่สำเร็จ!",
    "totalDocuments": "จำนวนเอกสาร 50 ทวิ",
    "documentUnit": "รายการ",
    "totalGrand": "เงินที่จ่าย",
    "sendFileByEmail": "ส่งไฟล์ทางอีเมล",
    "sendToEmail": "ส่งไปที่อีเมล",
    "ccToEmail": "สำเนาไปที่อีเมล",
    "sendEmail": "ส่งอีเมล",
    "cancel": "ยกเลิก",
    "sendFileBySms": "ส่งไฟล์ทาง SMS",
    "mobileNumer": "เบอร์มือถือ",
    "exampleNumber": "ตัวอย่าง 0825798555",
    "vatTax": "รายการภาษีขาย",
    "expenseTax": "รายการภาษีซื้อ",
    "pdf": "ดาวน์โหลด ภ.พ.30",
    "documentDate": "วันที่บนเอกสาร",
    "header": "ภ.พ.30",
    "subHeader": "(ดาวน์โหลดไฟล์ .txt หรือ .csv เพื่อนำไปสร้างแบบยื่นรายการภาษีมูลค่าเพิ่ม ภ.พ.30 ในโปรแกรม RDPrep)",
    "totalVat": "ภาษีขาย (VAT)",
    "totalExpenseTax": "ภาษีซื้อ (VAT)",
    "vatDiff": "ภาษีขาย - ภาษีซื้อ",
  },
  "pp30Summary": {
    "header": "ข้อมูลแบบแสดงรายการภาษีมูลค่าเพิ่ม ภ.พ.30",
    "month": "ภ.พ.30 สำหรับงวด",
    "vatPurchase": "ภาษีซื้อ",
    "totalPurchaseThisMonth": "ยอดซื้อในเดือนนี้",
    "vatPurchaseThisMonth": "ภาษีซื้อเดือนนี้",
    "thb": "บาท",
    "vatSales": "ภาษีขาย",
    "totalSalesThisMonth": "ยอดขายในเดือนนี้",
    "totalSalesZero": "ยอดขายที่เสียภาษีในอัตราร้อยละ 0",
    "totalSalesNon": "ยอดขายที่ได้รับยกเว้น",
    "totalSalesVat": "ยอดขายที่ต้องเสียภาษี",
    "vatSalesThisMonth": "ภาษีขายเดือนนี้",
    "totalVatThisMonth": "ภาษีที่ต้องชำระเดือนนี้",
    "previousVat": "ภาษีที่ชำระเกินยกมา/เครดิตภาษี",
    "totalVat": "ภาษีที่ต้องชำระ",
    "next": "ต่อไป",
    "cancel": "ยกเลิก",
    "minus": "ลบ",
  },
  "pp30Confirm": {
    "confirm": "ยืนยัน",
    "edit": "แก้ไข",
    "processing": "กำลังดำเนินการ",
    "processingMessage": "สร้างตัวอย่างแบบแสดงรายการภาษีมูลค่าเพิ่ม ภ.พ.30",
    "downloadPdf": "ดาวน์โหลด PDF",
    "downloadCsv": "ดาวน์โหลด CSV",
    "back": "กลับ",
    "loading": "กำลังโหลด...",
    "taxId": "เลขประจำตัวผู้เสียภาษี",
    "taxId2": "ไม่มีอยู่ในระบบฐานข้อมูล VAT",
    "taxId3": "ของกรมสรรพากร",
    "download": "ดาวน์โหลด",
  },
  "rdVat": {
    "number": "ลำดับ",
    "taxId": "เลขประจำตัวผู้เสียภาษีอากร",
    "branch": "สาขา",
    "name": "ชื่อผู้ประกอบการฯ",
    "address": "ที่อยู่",
    "postcode": "รหัสไปรษณีย์",
    "registerDate": "วันที่จดทะเบียน VAT",
    "header": "รายชื่อผู้ประกอบการจดทะเบียนภาษีมูลค่าเพิ่ม ซึ่งยังคงประกอบกิจการอยู่ ณ วันที่",
    "subHeader": "(ข้อมูลจากระบบฐานข้อมูลกรมสรรพากร)",
    "taxId2": "เลขประจำตัวผู้เสียภาษี 13 หลัก",
    "search": "ค้นหาข้อมูล",
    "footer1": "บริการข้อมูลการตรวจสอบสถานะและรายละเอียดผู้ประกอบการ ที่ได้ดำเนินการจดทะเบียนกับกรมสรรพากรอย่างถูกต้อง อีกทั้งมีสิทธิในการออกใบกำกับภาษีมูลค่าเพิ่มตามกฎหมาย ผู้ประกอบการทั้งในและต่างประเทศที่ต้องการตรวจสอบบริษัทคู่ค้าว่าเป็นผู้ที่สามารถออกใบกำกับภาษีได้ถูกต้องตามกฎหมายไทยหรือไม่",
    "footer2": "เนื่องจากสถานะนี้สามารถเปลี่ยนแปลงซึ่งป้องกันการแอบอ้างการออกใบกำกับภาษีอย่างผิดกฎหมาย ซึ่งจะเกิดความเสียหายแก่บริษัทผู้ใช้บริการหรือรับใบกำกับภาษีดังกล่าว อีกทั้งเป็นการตรวจสอบการมีตัวตนจริงของคู่ค้า",
  },
  "certificates": {
    "cancelConfirmHeader": "ยกเลิกการติดตั้งใบรับรองอิเล็กทรอนิกส์",
    "deleteConfirmHeader": "ลบใบรับรองอิเล็กทรอนิกส์",
    "cancelConfirm": "กรุณายืนยัน การยกเลิกการติดตั้งใบรับรองอิเล็กทรอนิกส์",
    "deleteConfirm": "กรุณายืนยัน การลบใบรับรองอิเล็กทรอนิกส์!",
    "serialNumber": "ซีเรียลนัมเบอร์:",
    "email": "อีเมล:",
    "issuedBy": "ออกให้โดย:",
    "start": "วันที่เริ่มต้น:",
    "end": "วันที่หมดอายุ:",
    "choose": "เลือกใช้",
    "delete": "ลบ",
    "header": "ใบรับรองอิเล็กทรอนิกส์",
    "loading": "กำลังโหลด...",
    "cancelCertificate": "ยกเลิกการติดตั้งใบรับรอง",
    "add": "เพิ่มใบรับรอง",
    "installStep": "ขั้นตอนการติดตั้ง ใบรับรองอิเล็กทรอนิกส์",
    "certificateCreateDescription1": "* เจ้าหน้าที่จะส่ง แบบคำขอใบรับรองอิเล็กทรอนิกส์ ให้ภายใน 1-2 วันทำการ",
    "certificateCreateDescription2": "หลังจากได้รับแบบคำขอใบรับรองฯทางอีเมลแล้ว ให้ดำเนินการตามขั้นตอนต่อไปนี้",
    "firstAgreement1": "1. เซ็นเอกสาร แบบคำขอใบรับรองอิเล็กทรอนิกส์",
    "firstAgreement2": "กรรมการผู้มีอำนาจลงนามเซ็นทุกหน้า พร้อมตราประทับ (ถ้ามี)",
    "firstAgreement3": "แบบฟอร์มมอบอำนาจ กรรมการ + พยานเซ็น",
    "firstAgreement4": "พร้อมติดอากรแสตมป์ 30 บาท",
    "secondAgreement1": "2. เอกสารประกอบแบบคำขอ",
    "secondAgreement2": "หนังสือรับรองบริษัท อายุไม่เกิน 3 เดือน",
    "secondAgreement3": "กรรมการเซ็นทุกหน้า พร้อมตราประทับ (ถ้ามี)",
    "secondAgreement4": "สำเนาบัตรประชาชนกรรมการ",
    "secondAgreement5": "พร้อมเซ็นรับรองสำเนา",
    "thirdAgreement1": "3. ส่งเอกสารทางอีเมลเพื่อตรวจสอบความถูกต้อง",
    "thirdAgreement2": "สแกนเอกสารในข้อ 1 และ ข้อ 2 แล้วส่งมาที่อีเมล support@leceipt.com",
    "fourthAgreement1": "4. จัดส่งเอกสารตัวจริงทางไปรษณีย์",
    "fourthAgreement2": "หลังจากตรวจสอบเอกสารทางอีเมลแล้ว จะแจ้งที่อยู่สำหรับจัดส่งเอกสารตัวจริง",
    "processing": "กำลังดำเนินการ...",
    "processing2": "ระบบกำลังดำเนินการ...",
    "secondStep": "ขั้นตอนที่ 2: ส่งเอกสารทางไปรษณีย์",
    "footer1": "กรุณารอเจ้าหน้าที่โทรติดต่อท่าน ก่อนจัดส่งเอกสารทางไปรษณีย์ ปกติจะใช้เวลาประมาณ 1-2 วันทำการในการตรวจสอบเอกสาร",
    "footer2": "หากต้องการสอบถาม โทร. 02-107-0999 เวลา 8.30 - 17.30 น. (ทุกวัน จันทร์ - อาทิตย์)",
    "footer3": "ที่อยู่ในการจัดส่งเอกสาร",
    "footer4": "ฝ่ายบริหารจัดการใบรับรองอิเล็กทรอนิกส์",
    "footer5": "บริษัท ฟรีเวชั่น จำกัด",
    "footer6": "682 หมู่ 1 ถ.สุขาภิบาล 1",
    "footer7": "ต.บรบือ อ.บรบือ จ.มหาสารคาม 44130",
    "footer8": "โทร. 02-107-0999",
    "footer9": "หลังจากบริษัท ได้รับเอกสารทางไปรษณีย์แล้ว จะดำเนินการขอใบรับรองอิเล็กทรอนิกส์ให้ท่าน ปกติบริษัทที่มีหน้าที่ออกใบรับรองอิเล็กทรอนิกส์ (CA) จะใช้เวลาดำเนินการประมาณ 3-5 วันทำการ",
    "footer10": "ท่านสามารถโทรสอบถามสถานะการดำเนินงานได้ตลอด ที่เบอร์ 02-107-0999 เวลา 8.30 - 17.30 น. (ทุกวัน จันทร์ - อาทิตย์)",
  },
  "xmlDeliver": {
    "header": "การนำส่งไฟล์ XML",
    "subHeader": "เชื่อมต่อ ระบบ Leceipt กับ E-Tax เพื่อนำส่งไฟล์ XML",
    "user": "ชื่อผู้ใช้งาน ระบบ E-Tax",
    "password": "รหัสผ่าน",
    "revealPassword": "แสดงรหัส",
    "connect": "เชื่อมต่อ",
    "disconnect": "ยกเลิกการเชื่อมต่อ",
    "disconnectConfirmHeader": "ยกเลิกนำส่งไฟล์ XML อัตโนมัติ",
    "disconnectConfirm": "กรุณายืนยัน การยกเลิกนำส่งไฟล์ XML อัตโนมัติ (ไฟล์ที่สร้างก่อนยกเลิกจะยังคงนำส่งแบบอัตโนมัติ)",
    "processing": "กำลังดำเนินการ ใช้เวลาประมาณ 2-5 นาที...",
    "loading": "กำลังโหลด...",
    "successMessage": "เชื่อมต่อสำเร็จ!",
    "successMessage2": "เชื่อมต่อเรียบร้อยแล้ว!",
    "details": "รายละเอียด",
    "failMessage": "ไม่สำเร็จ!",
    "failButton": "ลองอีกครั้ง",
    "footer1": "ระบบจะนำส่งไฟล์ XML อัตโนมัติ เฉพาะไฟล์ที่สร้างหลังจากทำการเชื่อมต่อ",
    "footer2": "ไฟล์ที่สร้างก่อนการเชื่อมต่อจะไม่ได้นำส่งอัตโนมัติ",
  },
  "pdfTemplate": {
    "showMessageBarSuccess": "รีเซ็ตรูปแบบเอกสารสำเร็จ!",
    "showMessageBarError": "รีเซ็ตรูปแบบเอกสารไม่สำเร็จ!",
    "header": "รูปแบบเอกสาร",
    "documentType": "ประเภทเอกสาร",
    "receipt-taxinvoice": "ใบเสร็จรับเงิน/ใบกำกับภาษี",
    "receipt-taxinvoice-abb": "ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ",
    "receipt": "ใบเสร็จรับเงิน",
    "invoice-taxinvoice": "ใบแจ้งหนี้/ใบกำกับภาษี",
    "deliveryorder-taxinvoice": "ใบส่งของ/ใบกำกับภาษี",
    "taxinvoice": "ใบกำกับภาษี",
    "debit-note": "ใบเพิ่มหนี้",
    "credit-note": "ใบลดหนี้",
    "quotation": "ใบเสนอราคา",
    "invoice": "ใบแจ้งหนี้",
    "billing-note": "ใบวางบิล",
    "deliveryorder": "ใบส่งของ",
    "preview": "ดูตัวอย่างเอกสาร",
    "disconnectConfirmHeader": "รีเซ็ตรูปแบบเอกสาร ",
    "disconnectConfirm1": "กรุณายืนยัน การรีเซ็ตรูปแบบเอกสาร ",
    "disconnectConfirm2": " (รูปแบบเอกสารจะกลับไปเป็นรูปแบบตั้งต้น)",
    "reset": "รีเซ็ตเอกสาร",
    "loading": "กำลังโหลดข้อมูล...",
    "example": "ตัวอย่างข้อมูล",
    "jsonParameter": "(คำอธิบาย JSON Parameters)",
  },
  "templatePreview": {
    "updateSuccess": "บันทึกสำเร็จ!",
    "updateFail": "บันทึกไม่สำเร็จ!",
    "header": "รูปแบบเอกสาร",
    "save": "บันทึก",
    "back": "กลับ",
    "saving": "กำลังบันทึก...",
    "loading": "กำลังโหลด...",
  },
  "emailSetting": {
    "getErrorMessage": "กรุณากรอกข้อมูล",
    "disconnect": "ยกเลิกการตั้งค่าอีเมลที่ใช้ส่งเอกสาร",
    "disconnectConfirm": "กรุณายืนยัน ยกเลิกการตั้งค่าอีเมลที่ใช้ส่งเอกสาร",
    "updateSuccess": "ตั้งค่าสำเร็จ!",
    "updateFail": "ตั้งค่าไม่สำเร็จ!",
    "showMessageBarResetSuccess": "รีเซ็ตรูปแบบอีเมลสำเร็จ!",
    "showMessageBarResetError": "รีเซ็ตรูปแบบอีเมลไม่สำเร็จ!",
    "showMessageBarUpdateSuccess": "บันทึกรูปแบบอีเมลสำเร็จ!",
    "showMessageBarUpdateError": "บันทึกรูปแบบอีเมลไม่สำเร็จ!",
    "header": "ตั้งค่าอีเมลที่ใช้ส่งเอกสาร",
    "processing": "กำลังดำเนินการ...",
    "toggleCompanyEmail": "ส่งเอกสารจากอีเมลองค์กร",
    "on": "เปิด",
    "off": "ปิด",
    "pleaseSelect": "กรุณาเลือก",
    "provider": "ผู้ให้บริการอีเมล",
    "email": "อีเมล:",
    "disconnectButton": "ลบการตั้งค่าอีเมล",
    "senderName": "ชื่อผู้ส่งเอกสาร",
    "senderEmail": "อีเมลผู้ส่ง",
    "revealPassword": "แสดงรหัส",
    "save": "บันทึก",
    "back": "กลับ",
    "customizeFormat": "ปรับแต่ง รูปแบบข้อความในอีเมล",
    "testSending": "ทดลองส่งอีเมล",
    "saveFirst": "(กดปุ่มบันทึกด้านบนก่อน)",
    "send": "ส่งอีเมล",
    "sending": "กำลังส่งอีเมล...",
    "resetConfirmHeader": "รีเซ็ตรูปแบบอีเมล",
    "resetConfirm": "กรุณายืนยัน การรีเซ็ตรูปแบบอีเมล (รูปแบบอีเมลจะกลับไปเป็นรูปแบบตั้งต้น)",
    "saveSeparator": "กดปุ่มเพื่อบันทึกข้อมูลการตั้งค่า",
    "saveSeparator2": "กดปุ่มเพื่อบันทึกรูปแบบอีเมล",
    "loading": "กำลังโหลด...",
    "loading2": "กำลังโหลดข้อมูล...",
    "saving": "กำลังบันทึก...",
    "saving2": "กำลังบันทึกรูปแบบอีเมล...",
    "resetting": "กำลังรีเซ็ตรูปแบบอีเมล...",
    "reset": "รีเซ็ตอีเมล",
  },
  "apiKeys": {
    "header": "การเชื่อมต่อ API",
    "privateKey": "API Key ส่วนตัว",
    "createKeyToConnect": "API Key สร้างเพื่อเข้าใช้งาน",
    "createKeyToConnect2": "กดปุ่มสร้าง API Key สำหรับการเชื่อมต่อผ่าน",
    "name": "ชื่อ:",
    "key": "API Key:",
    "createdDate": "วันที่สร้าง:",
    "keyDescription": "API Key จะแสดงครั้งเดียวตอนที่สร้าง หากท่านจำไม่ได้ให้กดปุ่มลบแล้วสร้างใหม่",
    "keyDescription2": "API Key ส่วนตัว มีค่าเท่ากับ Username + Password สำหรับการเข้าถึงบัญชีของท่าน ผ่าน API Authentication",
    "keyDescription3": "(หากท่านไม่ได้ใช้งาน API ไม่มีความจำเป็นที่จะต้องสร้าง API Key)",
    "delete": "ลบ API Key",
    "deleteConfirm": "กรุณายืนยัน การลบ API Key",
    "processing": "ระบบกำลังดำเนินการ...",
    "loading": "กำลังโหลด...",
    "keyName": "ตั้งชื่อ API Key",
    "confirm": "ยืนยัน สร้าง API Key",
    "cancel": "ยกเลิก",
    "generate": "สร้าง API Key",
  },
  "taxId": {
    "header": "ตรวจสอบเลขประจำตัวผู้เสียภาษีอากร และเลขประจำตัวประชาชน (13 หลัก)",
    "subHeader": "ว่าถูกต้องและมีอยู่จริงในฐานข้อมูลหรือไม่ ณ วันที่",
    "subHeader2": "(ข้อมูลจากระบบฐานข้อมูลกรมสรรพากร)",
    "taxId": "เลขประจำตัวผู้เสียภาษี 13 หลัก",
    "search": "ค้นหาข้อมูล",
    "footer1": "บริการตรวจสอบความถูกต้องของเลขประจำตัวผู้เสียภาษีอากร และเลขประจำตัวประชาชน ว่าเป็นเลขที่มีอยู่จริงและเป็นเลขที่ถูกต้อง หน่วยงานที่ต้องการตรวจสอบความมีอยู่จริงของเลขประจำตัวผู้เสียภาษีอากร และเลขประจำตัวประชาชน",
    "footer2": "เพื่อป้องกันการปลอมเลขประจำตัวผู้เสียภาษีอากร และเลขประจำตัวประชาชนในการประกอบธุรกรรมต่างๆ ที่ก่อให้เกิดความเสียหายแก่หน่วยงานหรือผู้ประกอบการต่างๆ",
    "processing": "กำลังตรวจสอบข้อมูลกับระบบสรรพากร...",
    "found": "พบเลขประจำตัวผู้เสียภาษี",
    "notFound": "ไม่พบเลขประจำตัวผู้เสียภาษี",
    "found2": "ในระบบฐานข้อมูลกรมสรรพากร",
  },
  "rdEtax": {
    "taxId": "เลขประจำตัวผู้เสียภาษีอากร",
    "taxId2": "เลขประจำตัวผู้เสียภาษี 13 หลัก",
    "name": "ชื่อผู้เสียภาษี",
    "taxInvoice": "ใบกำกับภาษี",
    "receipt": "ใบรับ",
    "registerDate": "วันที่ได้รับอนุมัติ",
    "endDate": "วันที่สิ้นสุด",
    "remark": "หมายเหตุ",
    "header": "รายชื่อผู้ประกอบการที่ได้รับอนุมัติให้จัดทำ ส่งมอบ และเก็บรักษา",
    "subHeader": "ใบกำกับภาษีอิเล็กทรอนิกส์ และใบรับอิเล็กทรอนิกส์ ณ วันที่",
    "subHeader2": "(ข้อมูลจากระบบฐานข้อมูลกรมสรรพากร)",
    "search": "ค้นหาข้อมูล",
    "footer1": "บริการข้อมูลตรวจสอบสถานะ และรายชื่อผู้ประกอบการที่ได้รับอนุมัติให้เป็นผู้จัดทำ ส่งมอบ และเก็บรักษาใบกำกับภาษีอิเล็กทรอนิกส์ และใบรับอิเล็กทรอนิกส์ตามกฎหมาย ผู้ประกอบการที่ต้องการตรวจสอบบริษัทคู่ค้าว่าเป็นผู้ที่สามารถออกใบกำกับภาษีอิเล็กทรอนิกส์ และใบรับอิเล็กทรอนิกส์ได้ถูกต้องตามกฎหมายหรือไม่",
    "footer2": "เนื่องจากสถานะนี้สามารถเปลี่ยนแปลง เพื่อป้องกันการแอบอ้างการออกใบกำกับภาษีอย่างผิดกฎหมาย ซึ่งจะเกิดความเสียหายแก่หน่วยงานหรือผู้ประกอบการต่างๆ ในภายหลัง และสร้างความเชื่อมั่นต่อบริษัทคู่ค้า",
  },
  "excelValidate": {
    "documentType": "documentType ห้ามเว้นว่าง"
  },
  "feedback": {
    "header": "แจ้งปัญหาหรือแนะนำบริการ",
    "sending": "กำลังส่งข้อมูล...",
    "sent": "ส่งข้อมูลสำเร็จ",
    "pleaseSelect": "กรุณาเลือก",
    "category": "หมวดหมู่",
    "REPORT": "แจ้งปัญหาการใช้งาน",
    "FUNCTION": "แนะนำฟังก์ชันเพิ่มเติม",
    "SERVICE": "แนะนำบริการ",
    "PAYMENT": "การชำระเงิน",
    "title": "หัวข้อ",
    "titleDescription": "หัวข้อปัญหาหรือบริการ",
    "description": "ข้อความ",
    "descriptionDetail": "แจ้งปัญหาการใช้งาน, แนะนำฟังก์ชันเพิ่มเติม, แนะนำบริการ, การชำระเงิน",
    "send": "ส่งข้อมูล",
  },
  "viewPdf": {
    "loading": "กำลังโหลดเอกสาร...",
    "download": "ดาวน์โหลด",
    "print": "พิมพ์",
    "back": "กลับ",
    "signBy": "เอกสารถูกลงลายเซ็นดิจิทัล โดย",
  },
  "viewXml": {
    "loading": "กำลังโหลดเอกสาร...",
    "download": "ดาวน์โหลด",
    "print": "พิมพ์",
    "back": "กลับ",
    "signBy": "เอกสารถูกลงลายเซ็นดิจิทัล โดย",
  },
  "viewPdfExpenseNote": {
    "loading": "กำลังโหลดเอกสาร...",
    "download": "ดาวน์โหลด",
    "print": "พิมพ์",
    "back": "กลับ",
    "signBy": "เอกสารถูกลงลายเซ็นดิจิทัล โดย",
  },
  "excelMenu": {
    "header": "อัปโหลด Excel",
    "subHeader": "สร้างหลายเอกสารพร้อมกัน .csv .xls .xlsx",
    "template": "จัดตำแหน่งคอลัมน์แบบ Template",
    "dragdrop": "จัดตำแหน่งคอลัมน์แบบ",
    "descriptionFirstMenu": "สำหรับเอกสาร e-Tax และเอกสารธุรกิจ",
    "descriptionSecondMenu": "สำหรับใบลดหนี้ และใบเพิ่มหนี้",
  },
  "storagesConnect": {
    "header": "เชื่อมต่อพื้นที่เก็บข้อมูลบนคลาวด์ ประเภท S3",
    "headerOneDrive": "เชื่อมต่อพื้นที่เก็บข้อมูลบนคลาวด์ Microsoft One Drive",
    "headerGoogleDrive": "เชื่อมต่อพื้นที่เก็บข้อมูลบนคลาวด์ Google Drive",
    "subHeader": "สำหรับใช้บันทึกเอกสารเมื่อมีการสร้างเอกสาร (Backup) หรือใช้สำหรับเก็บไฟล์เอกสารอื่น ๆ",
    "selectBucket": "เลือก Buckets",
    "back": "ย้อนกลับ",
    "backupLong": "การสำรองข้อมูล",
    "backupTrue": "บันทึกเอกสารทุกครั้งที่มีการสร้างเอกสาร",
    "backupFalse": "ไม่บันทึกเอกสารไปที่พื้นที่เก็บข้อมูลบนคลาวด์",
    "connect": "เชื่อมต่อ",
    "pleaseSelect": "กรุณาเลือก",
    "cancel": "ยกเลิก",
    "processing": "กำลังดำเนินการ...",
    "loading": "กำลังโหลด...",
    "successMessage": "เชื่อมต่อสำเร็จ!",
    "details": "รายละเอียด",
    "failMessage": "ไม่สำเร็จ!",
    "failButton": "ลองอีกครั้ง",
    "ConnectAnotherBucket": "เชื่อมต่อ bucket อื่น",
    "ConnectAnotherDrive": "เชื่อมต่อ Drive อื่น",
    "selectStorage": "เลือก Storage",
    "selectRegion": "เลือก Region",
  },
  "storages": {
    "header": "พื้นที่เก็บข้อมูล (Cloud Storage)",
    "creating": "กำลังสร้าง...",
    "createIncomplete": "สร้างไม่สำเร็จ",
    "createComplete": "สร้างสำเร็จ",
    "upload": "อัปโหลด",
    "uploading": "กำลังอัปโหลด...",
    "uploadComplete": "อัปโหลดสำเร็จ",
    "uploadIncomplete": "อัปโหลดไม่สำเร็จ",
    "delete": "ลบ",
    "deleting": "กำลังลบ...",
    "deleteComplete": "ลบสำเร็จ",
    "deleteIncomplete": "ลบไม่สำเร็จ",
    "download": "ดาวน์โหลด",
    "downloading": "กำลังดาวน์โหลด...",
    "downloadComplete": "ดาวน์โหลดสำเร็จ",
    "downloadIncomplete": "ดาวน์โหลดไม่สำเร็จ",
    "loading": "กำลังโหลด...",
    "copied": "คัดลอกสำเร็จ",
    "share": "แชร์",
    "shareIncomplete": "แชร์ไม่สำเร็จ",
    "new": "สร้าง",
    "newFolder": "สร้างโฟลเดอร์",
    "newFolderName": "ชื่อโฟลเดอร์",
    "folder": "โฟลเดอร์",
    "create": "สร้าง",
    "cancel": "ยกเลิก",
    "disconnect": "ยกเลิกการเชื่อมต่อ",
    "deleteConfirmMessage": "คุณต้องการลบหรือไม่",
    "open": "เปิด",
    "file": "ไฟล์",
    "name": "ชื่อ",
    "modified": "ปรับเปลี่ยนเมื่อ",
    "size": "ขนาดไฟล์",
    "documents": "เอกสาร",
    "alertMessageDownloadManyLargeFiles": "ไฟล์ขนาดใหญ่ไม่สามารถดาวน์โหลดหลายไฟล์พร้อมกันได้",
    "alertMessageDownloadManyFiles": "ดาวน์โหลดได้สูงสุดครั้งละ 100 ไฟล์เท่านั้น",
    "alertMessageDownloadFolder": "ไม่สามารถดาวน์โหลดโฟลเดอร์ได้",
    "disconnectConfirm": "กรุณายืนยัน ยกเลิกการเชื่อมต่อ",
    "automaticBackup": "บันทึกเอกสารอัตโนมัติ",
    "backupConfirm": "บันทึกเอกสาร",
    "backupEnableDescription1": "ท่านต้องการเปิดบันทึกเอกสารจาก Leceipt อัตโนมัติหรือไม่",
    "backupEnableDescription2": "(บันทึกเอกสารอัติโนมัติได้แค่ 1 ไดร์เท่านั้น หากเปิดในไดร์นี้ ไดร์อื่นที่เปิดบันทึกเอกสารอัติโนมัติไว้จะถูกปิด)",
    "backupDisableDescription1": "ท่านต้องการปิดบันทึกเอกสารจาก Leceipt อัตโนมัติหรือไม่",
  },
  "users": {
    "header": "ผู้ใช้งาน",
    "addUser": "เพิ่มผู้ใช้งาน",
    "name": "ชื่อผู้ใช้งาน",
    "email": "อีเมล",
    "role": "สิทธิผู้ใช้งาน",
    "activeDate": "ใช้งานล่าสุด",
    "itemsPerPage1": "แสดง",
    "itemsPerPage2": "รายการ",
    "page": "หน้า",
    "edit": "แก้ไข",
    "delete": "ลบ",
    "search": "ค้นหา",
    "creating": "กำลังสร้าง",
    "selectRole": "เลือกสิทธิผู้ใช้งาน",
    "add": "เพิ่ม",
    "cancel": "ยกเลิก",
    "emailErrorMessage": "อีเมลไม่ถูกต้อง",
    "editUser": "แก้ไขผู้ใช้งาน",
    "confirm": "ยืนยัน",
  },
  "roles": {
    "header": "สิทธิการใช้งาน",
    "addRole": "เพิ่มสิทธิการใช้งาน",
    "name": "ชื่อสิทธิการใช้งาน",
    "email": "อีเมล",
    "role": "สิทธิผู้ใช้งาน",
    "activeDate": "ใช้งานล่าสุด",
    "itemsPerPage1": "แสดง",
    "itemsPerPage2": "รายการ",
    "page": "หน้า",
    "edit": "แก้ไข",
    "delete": "ลบ",
    "search": "ค้นหา",
    "creating": "กำลังสร้าง",
    "selectRole": "เลือกสิทธิผู้ใช้งาน",
    "add": "เพิ่ม",
    "cancel": "ยกเลิก",
    "errorMessage": "ชื่อนี้ถูกใช้แล้ว",
    "editUser": "แก้ไขผู้ใช้งาน",
    "confirm": "ยืนยัน",
    "save": "บันทึก",
  },
  "autocompleteTextField": {
    "placeholder": "พิมพ์เพื่อค้นหา",
  },
  "storagesOneDrive": {
    "header": "พื้นที่เก็บข้อมูล (Cloud Storage)",
    "creating": "กำลังสร้าง...",
    "createIncomplete": "สร้างไม่สำเร็จ",
    "createComplete": "สร้างสำเร็จ",
    "upload": "อัปโหลด",
    "uploading": "กำลังอัปโหลด...",
    "uploadComplete": "อัปโหลดสำเร็จ",
    "uploadIncomplete": "อัปโหลดไม่สำเร็จ",
    "delete": "ลบ",
    "deleting": "กำลังลบ...",
    "deleteComplete": "ลบสำเร็จ",
    "deleteIncomplete": "ลบไม่สำเร็จ",
    "download": "ดาวน์โหลด",
    "downloading": "กำลังดาวน์โหลด...",
    "downloadComplete": "ดาวน์โหลดสำเร็จ",
    "downloadIncomplete": "ดาวน์โหลดไม่สำเร็จ",
    "loading": "กำลังโหลด...",
    "copied": "คัดลอกสำเร็จ",
    "share": "แชร์",
    "shareIncomplete": "แชร์ไม่สำเร็จ",
    "new": "สร้าง",
    "newFolder": "สร้างโฟลเดอร์",
    "newFolderName": "ชื่อโฟลเดอร์",
    "folder": "โฟลเดอร์",
    "create": "สร้าง",
    "cancel": "ยกเลิก",
    "disconnect": "ยกเลิกการเชื่อมต่อ",
    "deleteConfirmMessage": "คุณต้องการลบหรือไม่",
    "open": "เปิด",
    "file": "ไฟล์",
    "name": "ชื่อ",
    "modified": "ปรับเปลี่ยนเมื่อ",
    "size": "ขนาดไฟล์",
    "documents": "เอกสาร",
    "alertMessageDownloadManyLargeFiles": "ไฟล์ขนาดใหญ่ไม่สามารถดาวน์โหลดหลายไฟล์พร้อมกันได้",
    "alertMessageDownloadManyFiles": "ดาวน์โหลดได้สูงสุดครั้งละ 100 ไฟล์เท่านั้น",
    "alertMessageDownloadFolder": "ไม่สามารถดาวน์โหลดโฟลเดอร์ได้",
    "disconnectConfirm": "กรุณายืนยัน ยกเลิกการเชื่อมต่อ",
    "modifiedBy": "ปรับเปลี่ยนโดย",
    "alertErrorOccured": "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง",
  },
  "storagesGoogleDrive": {
    "header": "พื้นที่เก็บข้อมูล (Cloud Storage)",
    "creating": "กำลังสร้าง...",
    "createIncomplete": "สร้างไม่สำเร็จ",
    "createComplete": "สร้างสำเร็จ",
    "upload": "อัปโหลด",
    "uploading": "กำลังอัปโหลด...",
    "uploadComplete": "อัปโหลดสำเร็จ",
    "uploadIncomplete": "อัปโหลดไม่สำเร็จ",
    "delete": "ลบ",
    "deleting": "กำลังลบ...",
    "deleteComplete": "ลบสำเร็จ",
    "deleteIncomplete": "ลบไม่สำเร็จ",
    "download": "ดาวน์โหลด",
    "downloading": "กำลังดาวน์โหลด...",
    "downloadComplete": "ดาวน์โหลดสำเร็จ",
    "downloadIncomplete": "ดาวน์โหลดไม่สำเร็จ",
    "loading": "กำลังโหลด...",
    "copied": "คัดลอกสำเร็จ",
    "share": "แชร์",
    "shareIncomplete": "แชร์ไม่สำเร็จ",
    "new": "สร้าง",
    "newFolder": "สร้างโฟลเดอร์",
    "newFolderName": "ชื่อโฟลเดอร์",
    "folder": "โฟลเดอร์",
    "create": "สร้าง",
    "cancel": "ยกเลิก",
    "disconnect": "ยกเลิกการเชื่อมต่อ",
    "deleteConfirmMessage": "คุณต้องการลบหรือไม่",
    "open": "เปิด",
    "file": "ไฟล์",
    "name": "ชื่อ",
    "modified": "ปรับเปลี่ยนเมื่อ",
    "size": "ขนาดไฟล์",
    "documents": "เอกสาร",
    "alertMessageDownloadManyLargeFiles": "ไฟล์ขนาดใหญ่ไม่สามารถดาวน์โหลดหลายไฟล์พร้อมกันได้",
    "alertMessageDownloadManyFiles": "ดาวน์โหลดได้สูงสุดครั้งละ 100 ไฟล์เท่านั้น",
    "alertMessageDownloadFolder": "ไม่สามารถดาวน์โหลดโฟลเดอร์ได้",
    "disconnectConfirm": "กรุณายืนยัน ยกเลิกการเชื่อมต่อ",
    "mimeType": "ไฟล์",
    "modifiedTime": "ปรับเปลี่ยนเมื่อ",
    "modifyingUser": "ปรับเปลี่ยนโดย",
  },
  "TemplatesExample": {
    "back": "กลับ",
    "next": "ต่อไป",
    "loading": "กำลังโหลด...",
    "uploadLogoOrSignature": "ใส่โลโก้/ลายเซ็น",
    "resetTemplate": "รีเซ็ตรูปแบบ",
    "resetTemplateAlready": "รีเซ็ตรูปแบบเอกสารแล้ว",
    "sendingInProgress": "กำลังดำเนินการ...",
    "saveSuccessfully": "บันทึกสำเร็จแล้ว",
    "failToSave": "บันทึกไม่สำเร็จ",
    "pickColor": "เลือกสี",
    "closePickColor": "ปิดใช้งานการเลือกสีอยู่",
    "openedPickColor": "เปิดใช้งานการเลือกสีอยู่",
    "resetSpecificThisTemplates": "รีเซ็ตเฉพาะเอกสารนี้",
    "resetAllTemplates": "รีเซ็ตทุกเอกสาร",
    "confirmToReset": "รีเซ็ตรูปแบบเอกสาร",
    "everyTemplatesWillChanged": "รูปแบบเอกสารที่เลือกจะเปลี่ยนแปลงในทุกประเภทเอกสาร รวมไปถึงโลโก้หรือลายเซ็นจะถูกลบ ไม่มีการบันทึกเก็บไว้ในทุกเอกสาร หากกดยืนยันแล้วจะไม่สามารถย้อนกลับได้",
    "PleaseConfirmToResetTemplates": "กรุณากดปุ่มยืนยันเพื่อรีเซ็ตรูปแบบเอกสารนี้",
    "notSaveDocumentCaution": "รูปแบบเอกสารยังไม่ได้บันทึก",
    "notSaveDocumentCautionDescription": "กรุณากดบันทึกหากต้องการใช้งานรูปแบบที่กำลังแสดงอยู่",
    "cautionExistingCustomers1": "ลูกค้าเก่าที่เปิดบัญชีใช้งานก่อนวันที่ 1 มกราคม 2568 จะยังไม่สามารถอัปโหลดโลโก้/ลายเซ็น กรุณาติดต่อ LINE OA: @Leceipt"
  },
  "uploadPage": {
    "back": "กลับ",
    "next": "ต่อไป",
    "saveAndContinue": "บันทึกและดำเนินการต่อ",
    "cancel": "ยกเลิก",
    "clickAddLogo": "กดอัปโหลดโลโก้",
    "clickAddSignature": "กดอัปโหลดลายเซ็น",
    "continue": "ดำเนินการต่อ",
    "confirm": "ยืนยัน",
    "choiceforupload": "ตัวเลือกการใส่รูป",
    "addLogo": "ใส่รูปภาพโลโก้",
    "addSignature": "ใส่รูปภาพลายเซ็น",
    "logoImage": "รูปโลโก้",
    "signatureImage": "รูปลายเซ็น",
    "removeLogo": "ยกเลิกโลโก้",
    "removeSignature": "ยกเลิกลายเซ็น",
    "dragDropOrClickAdd": "ลากไฟล์มาวาง หรือ กดเลือกใส่รูป",
    "addLogoOrSignature1": " การใส่รูปโลโก้หรือรูปลายเซ็นจะถือว่าคุณยอมรับข้อกำหนดการให้บริการของซอฟต์แวร์",
    "addLogoOrSignature2": "โปรดตรวจสอบว่ารูปโลโก้หรือรูปลายเซ็นที่ใส่ไม่ละเมิดลิขสิทธิ์และความเป็นส่วนตัวของผู้อื่น",
    "termsAndConditions": "เงื่อนไขและข้อตกลงในการใช้งาน",
    "includes": "รวมทั้ง",
    "privacyPolicy": "นโยบายคุ้มครองความเป็นส่วนตัวและข้อมูลส่วนบุคคล",
    "confirmToUpload": "ยืนยันการใส่รูป",
    "wouldYouLikeToUpload": "คุณต้องการที่จะใส่รูปโลโก้หรือรูปลายเซ็นหรือไม่",
    "fileThatAllowed": "ไฟล์ที่รองรับ .png .jpg .jpeg .webp",
    "yourImageWeNotAllow": " รูปภาพที่ท่านอัปโหลดเป็นไฟล์ที่ระบบไม่ได้รองรับ ",
    "pleaseAddFileTypeThatAllow": "โปรดอัปโหลดไฟล์ที่มีนามสกุลดังต่อไปนี้ ได้แก่ .png .jpg .jpeg .webp ",
    "acknowledge": "รับทราบ",
    "uploadFail": "อัปโหลดไม่สำเร็จ"
  },
  "billings": {
    "addCredit": "เติมเครดิต",
    "addCreditDocument": "เติมเครดิตเอกสาร",
    "availableTime": "ใช้ได้ถึง ",
    "documentUsed": "ใช้เอกสารไป ",
    "fromTotal": " จากทั้งหมด ",
    "document": " เอกสาร",
    "nextResetTime": " รีเซ็ตเอกสารครั้งต่อไป:",
    "processing": " กำลังดำเนินการ...",
    "loading": " กำลังโหลด...",
    "addCreditSMS": "เติมเครดิตSMS",
    "paymentHistory": "ประวัติการชำระเงิน",
    "activateLeceipt": "เปิดใช้งานซอฟต์แวร์ Leceipt",
    "requestQuotation": "ขอใบเสนอราคา",
    "requestQuotationDescription": "คลิกที่ปุ่มข้างล่างนี้เพื่อขอใบเสนอราคา",
    "priceDetail": "รายละเอียดราคา",
    "contact": "ติดต่อสอบถาม",
    "tel": "โทร:",
    "email": "อีเมล:",
    "addFriend": "สแกนเพิ่มเพื่อน",
    "workdays": "เวลาทำการ 8.30-17.30 น. (จันทร์ - ศุกร์)",
  },
  "requestFormContactsList": {
    "listOfApplicant": "รายชื่อผู้ขอใบกำกับภาษี",
    "date": "วันที่",
    "name": "ชื่อ",
    "taxId": "เลขผู้เสียภาษี",
    "address": "ที่อยู่",
    "email": "อีเมล",
    "phoneNumber": "เบอร์โทร",
    "phoneNo": "เบอร์โทร",
    "select": "เลือก",
    "delete": "ลบ",
    "edit": "ดู/แก้ไข",
    "recieptTaxInvoice": "สร้างใบเสร็จรับเงิน/ใบกำกับภาษี",
    "reciept": "สร้างใบเสร็จรับเงิน",
    "invoiceTaxInvoice": "สร้างสร้างใบแจ้งหนี้/ใบกำกับภาษี",
    "deliveryOrderTaxInvoice": "สร้างสร้างใบส่งของ/ใบกำกับภาษี",
    "taxInvoice": "สร้างใบกำกับภาษี",
    "debitNote": "สร้างใบเพิ่มหนี้",
    "creditNote": "สร้างใบลดหนี้",
    "quotation": "สร้างใบเสนอราคา",
    "invoice": "สร้างใบแจ้งหนี้",
    "billingNote": "สร้างใบวางบิล",
    "deliveryOrder": "สร้างใบส่งของ",
    "withholdingTax": "การสร้างหนังสือรับรองการหักภาษี ณ ที่จ่าย",
    "from": "จาก",
    "to": "ถึง",
    "pickRangeTime": "เลือกช่วงเวลาที่ต้องการ",
    "goToCurrent": "ไปวันที่ปัจจุบัน",
    "refresh": "รีเฟรช",
    "search": "ค้นหา",
    "searchBy": "ค้นหาด้วย ชื่อ เลขผู้เสียภาษี เบอร์ อีเมล",
    "confirmToDelete": "ยืนยันการลบ",
    "page": "หน้า",
    "documents": "เอกสาร/หน้า",
    "editInformation": "แก้ไขข้อมูล",
    "areYouWantToDelete": "คุณต้องการลบ",
    "orNot": "หรือไม่ ?",
    "saveSuccessfully": "บันทึกสำเร็จแล้ว",
    "saveUnsucess": "บันทึกไม่สำเร็จ",
    "linkToManagement": "QR Code สำหรับผู้ซื้อ",
    "descriptionFormManagement": "ระบบจัดการแบบฟอร์ม QR Code สำหรับให้ผู้ซื้อ scan กรอกข้อมูล ขอใบกำกับภาษี",
    "taxInvAbbNumber": "เลขที่ใบกำกับภาษีอย่างย่อ",
    "note": "หมายเหตุ"
  },
  "requestFormEditContact": {
    "dropdownErrMsg": "กรุณาเลือก",
    "getErrorMessage": "กรุณากรอกข้อมูล",
    "getPostCodeErrorMessage1": "กรุณาระบุตัวเลขเท่านั้น",
    "getTaxIdErrorMessage": "กรอกเลข 13 หลัก",
    "getBranchNumberErrorMessage1": "ระบุเป็นตัวเลขเท่านั้น",
    "getBranchNumberErrorMessage2": "กรอกเลข 5 หลัก",
    "getPhoneNumberErrorMessage": "รูปแบบไม่ถูกต้อง ตัวอย่าง: 0812345678",
    "documentHeader": "ใบเสร็จรับเงิน/ใบกำกับภาษี",
    "documentSubHeader": "Receipt/Tax Invoice",
    "original": "ต้นฉบับ",
    "companyTel": "โทร",
    "companyEmail": "อีเมล",
    "companyTaxId": "เลขประจำตัวผู้เสียภาษี",
    "customerName": "ชื่อลูกค้า",
    "pleaseSelect": "กรุณาเลือก",
    "othr": "ไม่ระบุ",
    "number": "เลขที่",
    "tel": "โทร",
    "priceIncludeVat": "ราคารวมภาษีมูลค่าเพิ่ม",
    "date": "วันที่",
    "pleaseSelectDate": "กรุณาเลือกวันที่",
    "reference": "อ้างอิง",
    "itemNumber": "ลำดับ",
    "item": "รายการ",
    "quantity": "จำนวน",
    "unit": "หน่วย",
    "unitPrice": "ราคา/หน่วย",
    "discount": "ส่วนลด",
    "itemVat": "ภาษี (%)",
    "totalIncludeVat": "มูลค่ารวม VAT(บาท)",
    "totalBeforeVat": "มูลค่าก่อนภาษี (บาท)",
    "totalErrorMessage1": "มูลค่าไม่สามารถติดลบได้",
    "delete": "ลบ",
    "addItem": "เพิ่มรายการ",
    "note": "หมายเหตุ",
    "receivedBy": "ผู้รับเงิน",
    "createdBy": "ผู้จัดทำ",
    "reIssueMessage1": "เป็นการยกเลิกและออกใบกำกับภาษีใหม่แทนใบกำกับภาษีเดิม",
    "amountTotalBeforeVat": "รวมเงิน (ก่อน Vat)",
    "amountTotal": "รวมเป็นเงิน",
    "thb": "บาท",
    "vat": "ภาษีมูลค่าเพิ่ม",
    "grandTotal": "จำนวนเงินทั้งสิ้น",
    "referNumber": "เลขที่ใบกำกับภาษีเดิม",
    "referDate": "วันที่ใบกำกับภาษีเดิม",
    "reason": "สาเหตุการยกเลิกใบกำกับภาษีเดิม",
    "specificReason": "ระบุสาเหตุ",
    "nonVatPriceTotal": "มูลค่ารายการยกเว้นภาษี",
    "zeroVatPriceTotal": "มูลค่ารายการภาษี 0%",
    "vatPriceTotal": "มูลค่ารายการภาษี 7%",
    "next": "ต่อไป",
    "confirm": "ยืนยัน",
    "cancel": "ยกเลิก",
    "confirmToSaveHeader": "ยืนยันเพื่อบันทึกข้อมูล",
    "confirmToSaveData": "โปรดกดยืนยันเพื่อบันทึกข้อมูล",
    "loading": "กำลังโหลด...",
    "isRequiredErrorMessage": "กรุณาเลือกวันที่",
    "taxIdError": "กรอกเลขประจำตัวผู้เสียภาษีให้ครบ 13 หลัก",
    "updateSuccess": "บันทึกสำเร็จ!",
    "updateFail": "บันทึกไม่สำเร็จ!",
    "name": "ชื่อ",
    "namePlaceHolder": "บริษัท ผู้ขาย จำกัด",
    "addressForCreate": "ที่อยู่สำหรับออกเอกสาร",
    "getPostCodeErrorMessage2": "โปรดเติม 5 ตัวเลขไปรษณีย์",
    "contactForCustomer": "ลูกค้าติดต่อที่",
    "registerVat": "จดทะเบียนภาษีมูลค่าเพิ่ม",
    "registerVatYes": "ได้จดทะเบียน ภ.พ. 20",
    "registerVatNo": "ไม่ได้จดทะเบียน ภ.พ. 20",
    "saveSeparator": "กดปุ่มเพื่อบันทึกข้อมูล",
    "save": "บันทึก",
    "saving": "กำลังบันทึก...",
    "notDisplay": "ไม่แสดง",
    "alreadyHaveTaxId": "เลขประจำตัวผู้เสียภาษีมีในรายชื่อผู้ติดต่อแล้ว!",
    "alreadyHaveContact": "ผู้ติดต่อนี้ถูกใช้แล้ว!",
    "processing": "กำลังดำเนินการ...",
    "header": "เพิ่มผู้ติดต่อ",
    "headerDescription": "(ข้อมูลทั้งหมดข้างล่างนี้ ใช้สำหรับแสดงข้อมูลผู้ติดต่อบนเอกสารของท่าน)",
    "companyInfo": "ข้อมูลชื่อกิจการ",
    "taxNumberType": "ประเภทผู้เสียภาษี",
    "txid": "เลขประจำตัวผู้เสียภาษี (นิติบุคคล)",
    "nidn": "เลขประจำตัวประชาชน (บุคคลธรรมดา)",
    "ccpt": "เลขที่หนังสือเดินทาง",
    "english": "ภาษาอังกฤษ",
    "on": "เปิด",
    "off": "ปิด",
    "taxId": "เลขที่",
    "branchDisplay": "สาขา",
    "branchNumberDesc": "ตัวอย่าง 00001",
    "search": "ค้นหา",
    "companyName": "ชื่อบริษัท",
    "addressHeader": "ที่อยู่",
    "addressStructure": "โครงสร้างที่อยู่",
    "buildingNumber": "บ้านเลขที่",
    "address": "ที่อยู่",
    "streetPrefix": "คำนำหน้าถนน",
    "streetName": "ถนน",
    "provincePrefix": "คำนำหน้าจังหวัด",
    "province": "จังหวัด",
    "districtPrefix": "คำนำหน้าอำเภอ",
    "district": "อำเภอ (เลือกจังหวัดก่อน)",
    "districtPlus": "",
    "subDistrictPrefix": "คำนำหน้าตำบล",
    "subDistrict": "ตำบล (เลือกอำเภอก่อน)",
    "postCode": "รหัสไปรษณีย์",
    "lineOne": "ที่อยู่ บรรทัดที่ 1",
    "lineTwo": "ที่อยู่ บรรทัดที่ 2",
    "country": "ประเทศ",
    "contactHeader": "ช่องทางติดต่อ",
    "phone": "เบอร์โทร",
    "website": "เว็บไซต์",
    "fax": "เบอร์แฟกซ์",
    "email": "อีเมล",
    "contactHeader2": "ข้อมูลบุคคลที่ติดต่อได้",
    "contactName": "ชื่อผู้ติดต่อ",
    "contactNickName": "ชื่อเล่น",
    "contactEmail": "อีเมล",
    "contactPhone": "เบอร์โทร",
    "contactDepartment": "แผนก",
    "contactPosition": "ตำแหน่งงาน",
    "paymentHeader": "ข้อมูลธนาคารของคู่ค้า",
    "accountName": "ชื่อบัญชีธนาคาร",
    "bankName": "ธนาคาร",
    "bankBranch": "สาขา",
    "accountType": "บัญชีประเภท",
    "accountNumber": "บัญชีเลขที่",
    "paymentNote": "หมายเหตุการชำระเงิน",
    "addBank": "เพิ่มธนาคาร",
    "back": "กลับ",
    "getErrorMessageTaxIdZeroOnly": "ข้อมูลไม่ถูกต้อง",
    "firstStep": "ขั้นตอนที่ 1: ป้อนข้อมูลของท่าน",
    "secondStep": "ขั้นตอนที่ 2: ตรวจสอบข้อมูล",
    "pleaseCheck": "โปรดตรวจสอบข้อมูลให้ถูกต้องครบถ้วน",
    "thirdStep": "ขั้นตอนที่ 3: ส่งข้อมูลเรียบร้อยแล้ว",
    "done": "เสร็จสิ้น!",
    "ninText": "เลขประจำตัวประชาชน",
    "passportText": "เลขหนังสือเดินทางต่างประเทศ",
    "tinText": "เลขประจำตัวผู้เสียภาษี",
    "success": "สำเร็จ !",
    "enterClient": "ข้อมูลผู้ซื้อ ",
    "fillDataRequest": "กรอกข้อมูลขอ",
    "fillDataRequest2": "ใบเสร็จรับเงินใบกำกับภาษี",
    "onlyName": "ชื่อ",
    "fullRequest": "แบบฟอร์มขอใบกำกับภาษีเต็มรูปแบบ",
    "pleaseSelectBranch": "กรุณาเลือกสาขา",
    "pleaseEnterYourEmail": "กรุณากรอกอีเมล",
    "pleaseCheckYourEmail": "กรุณาตรวจสอบอีเมล",
    "pleaseSelectTaxPayerType": "กรุณาเลือกประเภทผู้เสียภาษี",
    "pleaseEnterYourName": "กรุณากรอกชื่อ",
    "pleaseEnterTheNumber": "กรุณากรอกเลขที่",
    "pleaseEnterTheAddress": "กรุณากรอกที่อยู่",
    "pleaseSelectProvince": "กรุณาเลือกจังหวัด",
    "pleaseSelectDistrict": "กรุณาเลือกอำเภอ",
    "pleaseSelectSubDistrict": "กรุณาเลือกตำบล",
    "headOffice": "สำนักงานใหญ่",
    "sendDataViaEmail": "อีเมลสำหรับรับใบกำกับภาษี",
    "branchNumber": "สาขาเลขที่",
    "saveSuccessfully": "บันทึกสำเร็จ",
    "saveUnsuccess": "บันทึกไม่สำเร็จ",
    "taxInvAbb": "ใบกำกับภาษีอย่างย่อ",
  },
  "requestFormAdminFormEditor": {
    "menuEditForm": "เมนูปรับแต่งแบบฟอร์มสำหรับขอใบกำกับภาษี",
    "preview": "ดูตัวอย่าง",
    "previewPaper": "พรีวิวหน้ากระดาษ",
    "openForm": "เปิดการใช้งานแบบฟอร์มขอใบกำกับภาษี",
    "addingTextToForm": "เพิ่มข้อความในแบบฟอร์ม",
    "selectTheme": "เลือกธีม",
    "printQrCode": "พิมพ์ QR Code",
    "saveInformation": "บันทึกข้อมูล",
    "selectThemeForForm": "เลือกธีมสำหรับแบบฟอร์ม",
    "exampleThemeForForm": "ตัวอย่างการแสดงผล",
    "addingFirstPage": "เพิ่มคำอธิบายในหน้าแรกของแบบฟอร์ม",
    "addingLastPage": "เพิ่มคำอธิบายหลังจากลูกค้าส่งข้อมูลสำเร็จ",
    "addingCloseForm": "เพิ่มคำอธิบายเมื่อปิดการใช้แบบฟอร์ม",
    "editPaper": "ปรับแต่งหน้ากระดาษ",
    "youCanAddLogo": "สามารถอัปโหลดโลโก้บริษัทหรือโลโก้แบรนด์ของคุณเพิ่มเติม",
    "uploadLogo": "อัปโหลดโลโก้",
    "closeEtax": "ปิดการใช้งานโลโก้ E-Tax",
    "selectBackground": "เลือกพื้นหลังสำหรับกระดาษ",
    "addDescription": "เพิ่มคำอธิบาย",
    "displayPaper": "ข้อมูลที่จะแสดงผลบนหน้ากระดาษ",
    "addingHere": "เพิ่มคำอธิบายในส่วนนี้",
    "deleteLogo": "ลบโลโก้",
    "creatingPaper": "กำลังสร้างหน้ากระดาษ",
    "print": "ปริ้น",
    "saveTheme": "บันทึกธีม",
    "closeForm": "เปิด/ปิด แบบฟอร์ม",
    "avaliable": "เปิดการใช้งานอยู่",
    "disabled": "ปิดการใช้งานอยู่",
    "thaiPaper": "ภาษาไทย",
    "englishPaper": "ภาษาอังกฤษ",
    "confirmSaveData": "บันทึกข้อมูล",
    "confirm": "ยืนยัน",
    "cancel": "ยกเลิก",
    "confirmForSave": "ยืนยันการบันทึกข้อมูล",
    "confirmToSavePolicy": "กรุณากดปุ่มยืนยันเพื่อบันทึกข้อมูลนโยบายคุ้มครองข้อมูลส่วนบุคคล",
    "confirmToSave": "กรุณากดปุ่มยืนยันเพื่อบันทึกข้อมูล",
    "confirmTheme": "ยืนยันธีม",
    "fullRequest": "แบบฟอร์ม",
    "fullRequest2": "ขอใบกำกับภาษีเต็มรูปแบบ",
    "firstStep": "ขั้นตอนที่ 1: กรอกข้อมูล",
    "secondStep": "ขั้นตอนที่ 2: ตรวจสอบข้อมูล",
    "pleaseCheck": "โปรดตรวจสอบข้อมูลให้ถูกต้องครบถ้วน",
    "thirdStep": "ขั้นตอนที่ 3: ส่งข้อมูลเรียบร้อยแล้ว",
    "done": "เสร็จสิ้น!",
    "checkData": "ตรวจสอบข้อมูล",
    "sentAlready": "ส่งข้อมูลเสร็จสิ้น",
    "backHomePage": "กลับสู่หน้าแรก",
    "thereIsEdit": "มีการแก้ไข! จำเป็นต้องกดบันทึกข้อมูลก่อน",
    "resetCodeFormat": " รีเซ็ตรูปแบบ QR code",
    "previewBeforePrinting": "ดูตัวอย่างก่อนพิมพ์",
    "confirmLogo": "ยืนยันการลบโลโก้",
    "confirmLogoDeletion": "คุณต้องการลบโลโก้ที่อัปโหลดหรือไม่?",
    "toEnableOrDisable": "เปิดปิดการใช้งานโลโก้ E-Tax :",
    "paperLanguage": "เปลี่ยนภาษาภายในกระดาษ :",
    "toAccessPlease": "เพื่อเข้าใช้งานฟีเจอร์นี้ กรุณาสมัครใช้บริการและดำเนินการชำระเงิน",
    "subscribeNow": "สมัครใช้งาน",
    "youNotSubscriber": "คุณยังไม่ได้สมัครใช้งานแบบ Subscription",
    "saveSuccessfully": "บันทึกสำเร็จเรียบร้อย",
    "failToSave": "บันทึกไม่สำเร็จ",
    "loading": " กำลังโหลด...",
    "wouldYouLikeToReset": "ต้องการรีเซ็ตรูปแบบกลับเป็นค่าเริ่มต้นหรือไม่",
    "resetFormatQrCode": "รีเซ็ตรูปแบบ QR CODE",
    "copyLinkAlready": "คัดลอกลิงค์เรียบร้อย",
    "addingPrivacyPolicy": "เพิ่มข้อความ นโยบายคุ้มครองข้อมูลส่วนบุคคล",
    "termsOfService": "เงื่อนไขการใช้งาน",
    "termsOfDescription": "แบบฟอร์มขอใบกำกับภาษีเต็มรูปแบบนี้ ถูกเปิดใช้งานโดยผู้ใช้บริการบนซอฟต์แวร์ Leceipt ข้อมูลที่ลูกค้ากรอกจะถูกส่งเข้าสู่บัญชีนี้ ซึ่งจะถือว่าผู้ใช้บริการที่เปิดใช้งานแบบฟอร์มเป็นผู้เก็บรวบรวมข้อมูลและนำข้อมูลส่วนบุคคลไปใช้งาน โดยผู้ใช้งานที่เปิดใช้แบบฟอร์มนี้ ต้องทำการแจ้งนโยบายคุ้มครองข้อมูลส่วนบุคคลต่อผู้กรอกแบบฟอร์ม ทาง Leceipt ในฐานะผู้ให้บริการซอฟต์แวร์ จะไม่นำข้อมูลส่วนบุคคลนี้ไปใช้ และจะไม่รับผิดชอบต่อความเป็นส่วนตัวหรือแนวทางปฏิบัติด้านความปลอดภัยของลูกค้า",
    "privacyPolicy": "นโยบายคุ้มครองข้อมูลส่วนบุคคล",
    "resetData": "รีเซ็ตข้อมูล",
    "resetBackToOrigin": "ลบข้อมูลนโยบายคุ้มครองข้อมูลส่วนบุคคลทั้งหมด",
    "wouldYouLikeToDeleteAll": "คุณต้องการที่จะลบข้อมูลนโยบายคุ้มครองข้อมูลส่วนบุคคลทั้งหมดหรือไม่?",
    "saveData": "บันทึกข้อมูล",
    "opendUseEnglish": "เปิดการใช้งาน",
    "openUseEnglish": "ปิดการใช้งานอยู่",
    "thaiLanguage": "ภาษาไทย",
    "englishLanguage": "ภาษาอังกฤษ",
    "save": "บันทึก",
    "wouldYouLikeToSaveData": "คุณต้องการที่จะบันทึกข้อมูลหรือไม่?",
    "deletedDataPrivacyPolicy": "ลบข้อมูลนโยบายเรียบร้อย",
    "sendingData": "กำลังส่งข้อมูล"
  },
  "requestFormThemeColor": {
    "selectForForm": "เลือก Theme สำหรับแบบฟอร์ม",
    "001": "มาตรฐาน",
    "002": "ชายหาด",
    "003": "ป่าไม้",
    "004": "ทันสมัย",
    "005": "ฮาโลวีน",
    "006": "ฤดูหนาว",
    "007": "คริสมาสต์",
    "008": "มหาสมุทร",
    "009": "สวนผึ้ง",
    "010": "พระอาทิตย์ตก",
    "011": "ดาราศาสตร์",
    "012": "วาเลนไทน์",
    "013": "ลาเวนเดอร์",
    "014": "รุ่งอรุณ",
    "015": "สวนสัตว์",
    "016": "สงกรานต์",
    "017": "ฤดูร้อน",
  },
  "requestFormThemeQrColor": {
    "001": "มาตรฐาน",
    "002": "ชายหาด",
    "003": "ป่าไม้",
    "004": "ทันสมัย",
    "005": "สวนผึ้ง",
    "006": "วาเลนไทน์",
    "007": "คริสมาสต์",
    "008": "มหาสมุทร",
    "009": "พระอาทิตย์",
    "010": "ภูเขา",
    "011": "ทะเลทราย",
    "012": "ลูกอม",
    "013": "จักรวาล",
    "014": "ใบไม้ร่วง",
    "015": "น้ำแข็ง",
    "016": "ทุ่งลาเวนเดอร์",
  },
  "requestFormDocuments": {
    "months01": "มกราคม",
    "months02": "กุมภาพันธ์",
    "months03": "มีนาคม",
    "months04": "เมษายน",
    "months05": "พฤษภาคม",
    "months06": "มิถุนายน",
    "months07": "กรกฎาคม",
    "months08": "สิงหาคม",
    "months09": "กันยายน",
    "months10": "ตุลาคม",
    "months11": "พฤศจิกายน",
    "months12": "ธันวาคม",
    "shortMonths01": "ม.ค.",
    "shortMonths02": "ก.พ.",
    "shortMonths03": "มี.ค.",
    "shortMonths04": "เม.ย.",
    "shortMonths05": "พ.ค.",
    "shortMonths06": "มิ.ย.",
    "shortMonths07": "ก.ค.",
    "shortMonths08": "ส.ค.",
    "shortMonths09": "ก.ย.",
    "shortMonths10": "ต.ค.",
    "shortMonths11": "พ.ย.",
    "shortMonths12": "ธ.ค.",
    "thisMonth": "เดือนนี้",
  },
  "requestFormRequestList": {
    "pleaseSelect": "โปรดเลือก",
    "search": "ค้นหา",
    "timeRangeError": "กรุณาระบุช่วงเวลาไม่เกิน 30 วัน",
  },
  "requestFormSidebar": {
    "taxInvoiceRequests": "รายชื่อผู้ขอใบกำกับภาษี",
    "menuCustomandPrinting": "เมนูปรับแต่งแบบฟอร์มและพิมพ์",
  },
  "packageDetail": {
    "messageBarPaymentCompleteTitle": "ชำระเงินสำเร็จ",
    "messageBarPaymentCompleteMessage": "สามารถดูใบเสร็จรับเงินได้",
    "messageBarPaymentCompleteActionText": "ดู",
    "package300DocsMonth": "300 เอกสาร/เดือน",
    "package500DocsMonth": "500 เอกสาร/เดือน",
    "package1000DocsMonth": "1,000 เอกสาร/เดือน",
    "package3000DocsMonth": "3,000 เอกสาร/เดือน",
    "package10000DocsMonth": "10,000 เอกสาร/เดือน",
    "package300DocsYear": "300 เอกสาร/เดือน 1 ปี",
    "package500DocsYear": "500 เอกสาร/เดือน 1 ปี",
    "package1000DocsYear": "1,000 เอกสาร/เดือน 1 ปี",
    "package3000DocsYear": "3,000 เอกสาร/เดือน 1 ปี",
    "package10000DocsYear": "10,000 เอกสาร/เดือน 1 ปี",
    "package": "ข้อมูลแพ็กเกจ",
    "messageBarOverdueTitle1": "ยอดค้างชำระ ",
    "messageBarOverdueTitle2": " บาท",
    "messageBarOverdueActionText": "ชำระ",
    "loading": "กำลังโหลด...",
    "switchPackage": "เปลี่ยนแพ็กเกจ",
    "currentBillPeriod": "รอบบิลปัจจุบัน",
    "usageAmount": "จำนวนที่ใช้",
    "usageAmountLeft": "เหลือ",
    "amountOverused": "จำนวนที่ใช้เกิน",
    "emailSentCount": "จำนวนที่ส่งอีเมล",
    "smsSentCount": "จำนวนที่ส่ง SMS",
    "emailForReceiveInvoice": "อีเมลสำหรับรับใบแจ้งหนี้ค่าบริการ",
    "paymentHistory": "ประวัติการชำระเงิน",
    "email": "อีเมล",
    "save": "บันทึก",
    "close": "ปิด",
  },
  "templatesSelector": {
    "advanceEdit": "แก้ไขขั้นสูง",
    "back": "กลับ",
    "preview": "ดูตัวอย่าง",
    "resetDoc": "รีเซ็ตเอกสาร",
    "confirm": "ยืนยัน",
    "cancel": "ยกเลิก",
    "save": "บันทึก",
    "title": "กดปุ่มเพื่อบันทึก",
    "areYouSure": "กรุณากดปุ่มยืนยันเพื่อใช้งานรูปแบบเอกสาร",
    "template": "รูปแบบที่",
    "documentType": "ประเภทเอกสาร",
    "confirmTemplate": "ยืนยันรูปแบบเอกสาร",
    "selectedDocument1": "รูปแบบเอกสารที่เลือกจะเปลี่ยนแปลงในทุกประเภทเอกสาร",
    "selectedDocument2": "รวมไปถึงโลโก้และลายเซ็นจะถูกอัปโหลดในทุกเอกสาร",
    "selectedDocument3": "หากกดยืนยันแล้วจะไม่สามารถย้อนกลับได้ ",
    "savedTemplateToAllDocuments": "บันทึกรูปแบบเอกสารทุกประเภท",
    "savedTemplateForThisDocuments": "บันทึกรูปแบบเฉพาะเอกสารนี้",
    "acknowledgeMessage": "รับทราบข้อความข้างต้น",
    "saving": "กำลังบันทึก...",
    "titleReset": "ยืนยันการรีเซ็ตรูปแบบเอกสาร",
    "areYouSureToReset": "กรุณากดปุ่มยืนยันเพื่อรีเซ็ตรูปแบบเอกสาร",
    "loading": "กำลังโหลด...",
    "saveSuccessfully": "บันทึกสำเร็จแล้ว",
    "resetTemplateAlready": "รีเซ็ตรูปแบบเอกสารแล้ว",
    "failToSave": "บันทึกไม่สำเร็จ",
    "style": "สีที่",
    "formats": "รูปแบบ",
    "page": "หน้า",
    "youCanPickTemplates": "ท่านสามารถเลือกใช้รูปแบบเอกสารได้ถึง",
    "click": "คลิก",
  },
  "templateTinyPreview": {
    "receipt-taxinvoice": "ใบเสร็จรับเงิน/ใบกำกับภาษี",
    "receipt-taxinvoice-abb": "ใบเสร็จรับเงิน/ใบกำกับภาษีฉบับย่อ",
    "receipt": "ใบเสร็จรับเงิน",
    "invoice-taxinvoice": "ใบแจ้งหนี้/ใบกำกับภาษี",
    "deliveryorder-taxinvoice": "ใบส่งของ/ใบกำกับภาษี",
    "taxinvoice": "ใบกำกับภาษี",
    "debit-note": "ใบเพิ่มหนี้",
    "credit-note": "ใบลดหนี้",
    "quotation": "ใบเสนอราคา",
    "invoice": "ใบแจ้งหนี้",
    "billing-note": "ใบวางบิล",
    "deliveryorder": "ใบส่งของ",
    "cancel": "ยกเลิก",
    "reset": "ยืนยัน",
    "title": "ยืนยันการรีเซ็ตรูปแบบเอกสาร",
    "areYouSureToReset": "กรุณากดปุ่มยืนยันเพื่อรีเซ็ตรูปแบบเอกสาร",
    "resetDoc": "รีเซ็ตเอกสาร",
    "errorCaution": ">>{{ตัวอย่าง}<< ขาดตกเป็น, ควรจะเป็น >>{{ตัวอย่าง}} << <= (เครื่องหมายปีกกาหายไป)",
    "errorCaution2": "และตรวจสอบแท็กอื่นๆ เช่น {{if}},{{/if}}  เป็นต้น หากแก้ไขไม่สำเร็จ ท่านสามารถทำการกดปุ่มรีเซ็ตเอกสาร ",
    "errorCaution3": "รีเซ็ตเอกสาร",
    "errorCaution4": "กลับสู่ต้นฉบับ",
    "errorCaution5": "โปรดตรวจ Editor อีกครั้งว่าไม่มีการลบแท็กตัวแปรผิดพลาด ตัวอย่างเช่น,",
    "errorCautionLabel": "เกิดข้อผิดพลาดในการแก้ไข",
    "resetSuccess": "รีเซ็ตเอกสารสำเร็จแล้ว",
    "example": "ตัวอย่าง",
    "category": "รูปแบบที่",
    "areYouSure": "คุณยืนยันที่จะบันทึกรูปแบบเอกสารนี้?",
    "save": "บันทึก",
    "savedSuccess": "บันทึกเรียบร้อยแล้ว",
    "cautionEditRequireSave": "กดดูตัวอย่างก่อนบันทึก",
    "cautionEditRequireSave2": "หากต้องการบันทึกการแก้ไขเปลี่ยนแปลงกรุณากดปุ่มดูตัวอย่าง",
    "loading": "กำลังโหลด..."
  }
};