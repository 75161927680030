import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { silentRequest } from "../../../authConfig";

import { useMsal, useAccount } from "@azure/msal-react";

import paginate from "jw-paginate";

import {
    DetailsListLayoutMode,
    ShimmeredDetailsList,
    Selection,
    SelectionMode,
    Icon,
    Calendar,
    DateRangeType,
    Spinner,
    SpinnerSize,
    CommandBar,
    Stack,
    SearchBox,
    PrimaryButton,
    DefaultButton,
    TextField,
    ContextualMenu,
    Toggle,
    CheckboxVisibility,
    Dialog,
    DialogType,
    DialogFooter,
    Checkbox,
    Text,
    DatePicker,
    IconButton,
    MessageBarType,
    MessageBar,
    TooltipHost,
} from "@fluentui/react";

// import { Pagination } from "@uifabric/experiments/lib/Pagination";
import { Pagination } from '@fluentui/react-experiments/lib/Pagination';

// import { getFileTypeIconProps } from "@uifabric/file-type-icons";
import { getFileTypeIconProps } from '@fluentui/react-file-type-icons';
import axios from "axios";

const homeStyles = {
    border: 0,
    margin: 0,
    width: "100%",
    background: "#FAF9F8",
};

const spinnerStyles = {
    circle: {
        height: 56,
        width: 56,
        borderWidth: 4,
    },
};

const onFormatDate = (date, format) => {
    if (format === "CE") {
        return `${date.getDate()}`.padStart(2, "0") + "/" + `${date.getMonth() + 1}`.padStart(2, "0") + "/" + date.getFullYear();
    } else {
        return `${date.getDate()}`.padStart(2, "0") + "/" + `${date.getMonth() + 1}`.padStart(2, "0") + "/" + (date.getFullYear() + 543);
    }
};

const styles = {
    table: {
      borderCollapse: 'collapse', width: '100%', 
    },
    th: {
      border: '1px solid #ddd', padding: '8px', textAlign: 'left', backgroundColor: '#f2f2f2'
    },
    td: {
      border: '1px solid #ddd', padding: '8px', textAlign: 'left'
    },
    scrollable: {
      maxHeight: '200px', overflowY: 'auto', border: '1px solid #ddd'
    }
}

function removeInvisibleCharacters(inputString) {
    // Use a regular expression to remove invisible characters
    var cleanedString = inputString.replace(/[^\x20-\x7E]/g, '');

    return cleanedString;
}

function removeCarriageReturnAndNewline(inputString) {
    // Check if inputString is empty or null
    if (!inputString) {
        return inputString;
    }
    // Check if inputString contains "\r\n"
    if (inputString.indexOf("\r\n") === -1) {
        // If not found, return inputString as it is
        return inputString;
    }
    // Replace all occurrences of "\r\n" with an empty string
    return inputString.replace(/\r\n/g, " ");
  }

  const timestamp_toDate = (timestamp) => {
    // Split the timestamp into date and time components
    let parts = timestamp.split(" ");
    let datePart = parts[0]; // "2024-09-11"
    let timePart = parts[1]; // "16:14:33"
    
    // Construct a new Date object with the correct format (YYYY-MM-DDTHH:MM:SS)
    let formattedTimestamp = datePart + "T" + timePart + "+07:00"
    let date = new Date(formattedTimestamp);
    
    // Ensure the date object is valid
    if (isNaN(date.getTime())) {
        return "Invalid date";
    }

    // Format the date
    let month = "" + (date.getMonth() + 1);
    let day = "" + date.getDate();
    let year = date.getFullYear() + 543;

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [day, month, year].join("/");
};

const parseDate = (timestamp) => {
    // Regular expression to match and capture date, time, and timezone offset
    let match = timestamp.match(/^(\d{4}-\d{2}-\d{2}) (\d{2}:\d{2}:\d{2}) ([+-]\d{4})$/);
    
    if (match) {
        // Extract the date, time, and timezone offset
        let datePart = match[1]; // "2024-09-11"
        let timePart = match[2]; // "16:14:33"
        // let timezonePart = match[3]; // "+0700"
        let timezonePart = "+07:00" // "+0700"
        
        // Insert 'T' between date and time to create a valid ISO 8601 string
        let isoString = `${datePart}T${timePart}${timezonePart}`;

        // Create and return the Date object
        return new Date(isoString);
    } else {
        // Return Invalid Date if the format doesn't match
        return new Date(NaN);
    }
};

function Lazada() {
    const API_URL = process.env.REACT_APP_API_URL;

    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});

    const { t, i18n } = useTranslation();

    const [company, setCompany] = useState("");
    const [items, setItems] = useState([]);

    const percentVat = 7;
    const zeroVat = 0;
    // const nonVat = -1;

    const percentVatText = "7%";
    const zeroVatText = "0%";
    // const nonVatText = '-';

    const [totalDocuments, setTotalDocuments] = useState(0);

    const [loadDataComplete, setLoadDataComplete] = useState(false);
    const [getNewDocuments, setGetNewDocuments] = useState(false);
    const [pageData, setPageData] = useState({});
    const [pageSize, setPageSize] = useState(sessionStorage.getItem("lazada-pageSize") * 1 || 10);
    const [currentPage, setCurrentPage] = useState(sessionStorage.getItem("lazada-currentPage") * 1 || 1);

    const [allDocs, setAllDocs] = useState([]);
    //CommandBars Items
    const [orderType, setOrderType] = useState(
        (sessionStorage.getItem("lazada-orderType-key") && {
            key: sessionStorage.getItem("lazada-orderType-key"),
            text: sessionStorage.getItem("lazada-orderType-text"),
        }) || {
            key: "delivered",
            text: t("lazada.delivered"),
        }
    );

    const [timeRangeField, setTimeRangeField] = useState(
        (sessionStorage.getItem("lazada-timeRangeField-key") && {
            key: sessionStorage.getItem("lazada-timeRangeField-key"),
            text: sessionStorage.getItem("lazada-timeRangeField-text"),
        }) || {
            key: "updated_at",
            text: t("lazada.updateTime"),
        }
    );

    const [search, setSearch] = useState("");

    const [includeVat, setIncludeVat] = useState(true);

    const [isLoading, setIsLoading] = useState(false);

    const [registerVat, setRegisterVat] = useState(true);

    const isCompactMode = false;

    const [isCreateDocument, setIsCreateDocument] = useState(false);
    const [isCreateDocumentComplete, setIsCreateDocumentComplete] = useState(false);

    const [selectedItems, setSelectedItems] = useState([]);

    const [selection] = useState(
        new Selection({
            onSelectionChanged: () => {
                console.log("isCreateDocument: ", isCreateDocument);
                console.log("isCreateDocumentComplete: ", isCreateDocumentComplete);
                let _selectedItems = selection.getSelection();

                setSelectedItems(_selectedItems);
            },
        })
    );

    const [dialogConfirm, setDialogConfirm] = useState({
        isClick: false,
        header: "",
        message: "",
        onConfirm: null,
        onCancel: null,
    });

    const [createdBy, setCreatedBy] = useState("");

    const [prefixNumber, setPrefixNumber] = useState("");
    const [countingNumber, setCountingNumber] = useState("");

    // const [enableAutomation, setEnableAutomation] = useState(false);

    const companyId = sessionStorage.getItem("companyId") || "";

    const [primarySort, setPrimarySort] = useState(sessionStorage.getItem("lazada-primarySort") || "createdTime");
    const [isSortedDescendingCreatedTime, setIsSortedDescendingCreatedTime] = useState(() => {
        if (sessionStorage.getItem("lazada-sort-createdTime") === "false") {
            return false;
        } else {
            return true;
        }
    });

    const [isSortedDescendingNumber, setIsSortedDescendingNumber] = useState(() => {
        if (sessionStorage.getItem("lazada-sort-number")) {
            if (sessionStorage.getItem("lazada-sort-number") === "true") {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    });
    const [isSortedDescendingCustomerName, setIsSortedDescendingCustomerName] = useState(() => {
        if (sessionStorage.getItem("lazada-sort-customerName")) {
            if (sessionStorage.getItem("lazada-sort-customerName") === "true") {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    });
    const [isSortedDescendingVatTotal, setIsSortedDescendingVatTotal] = useState(() => {
        if (sessionStorage.getItem("lazada-sort-vatTotal")) {
            if (sessionStorage.getItem("lazada-sort-vatTotal") === "true") {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    });
    const [isSortedDescendingGrandTotal, setIsSortedDescendingGrandTotal] = useState(() => {
        if (sessionStorage.getItem("lazada-sort-grandTotal")) {
            if (sessionStorage.getItem("lazada-sort-grandTotal") === "true") {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    });

    const [isSortedDescendingIsRequest, setIsSortedDescendingIsRequest] = useState(() => {
        if (sessionStorage.getItem("lazada-sort-isRequest")) {
            if (sessionStorage.getItem("lazada-sort-isRequest") === "true") {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    });

    const [showShippingFee, setShowShippingFee] = useState(true);
    const [downloadAWBFail, setDownloadAWBFail] = useState(false)

    const [showMessageBarOffsetError, setShowMessageBarOffsetError] = useState(false)

    const DayPickerStrings = {
        months: [t("lazada.months01"), t("lazada.months02"), t("lazada.months03"), t("lazada.months04"), t("lazada.months05"), t("lazada.months06"), t("lazada.months07"), t("lazada.months08"), t("lazada.months09"), t("lazada.months10"), t("lazada.months11"), t("lazada.months12")],

        shortMonths: [
            t("lazada.shortMonths01"),
            t("lazada.shortMonths02"),
            t("lazada.shortMonths03"),
            t("lazada.shortMonths04"),
            t("lazada.shortMonths05"),
            t("lazada.shortMonths06"),
            t("lazada.shortMonths07"),
            t("lazada.shortMonths08"),
            t("lazada.shortMonths09"),
            t("lazada.shortMonths10"),
            t("lazada.shortMonths11"),
            t("lazada.shortMonths12"),
        ],

        days: ["อาทิตย์", "จันทร์", "อังคาร", "พุธ", "พฤหัสบดี", "ศุกร์", "เสาร์"],

        shortDays: ["อา.", "จ.", "อ.", "พ.", "พฤ.", "ศ.", "ส."],

        goToToday: t("lazada.goToToday"),
        prevMonthAriaLabel: "ไปเดือนที่แล้ว",
        nextMonthAriaLabel: "ไปเดือนถัดไป",
        prevYearAriaLabel: "ไปปีที่แล้ว",
        nextYearAriaLabel: "ไปปีถัดไป",
        closeButtonAriaLabel: "ปิด",
        monthPickerHeaderAriaLabel: "{0}, เลือกเพื่อเปลี่ยนปี",
        yearPickerHeaderAriaLabel: "{0}, เลือกเพื่อเปลี่ยนเดือน",

        isRequiredErrorMessage: t("lazada.isRequiredErrorMessage"),

        invalidInputErrorMessage: "รูปแบบวันที่ไม่ถูกต้อง",
    };

    const toThaiDateString = (isoDateTime) => {
        let date = '';
        date = new Date(isoDateTime);
        let year = date.getFullYear() + 543;
        let month = (date.getMonth() + 1).toString().padStart(2, '0');
        let numOfDay = date.getDate().toString().padStart(2, '0');
      
        let hour = date.getHours().toString().padStart(2, '0');
        let minutes = date.getMinutes().toString().padStart(2, '0');
        let second = date.getSeconds().toString().padStart(2, '0');
      
        return `${numOfDay}/${month}/${year} ${hour}:${minutes}:${second} ${i18n.language === 'th' ? 'น.' : ''}`;
    };

    const createSelectedDocuments = async (documentType) => {
        let _items = selection.getItems();

        let postUrl = "";

        switch (documentType) {
            case "RECEIPT-TAXINVOICE":
                postUrl = API_URL + "/etax/documents/receipts-taxinvoices/pdf-xml";
                break;
            case "RECEIPT-TAXINVOICE-ABB":
                postUrl = API_URL + "/etax/documents/receipts-taxinvoices-abb/pdf";
                break;
            case "RECEIPT":
                postUrl = API_URL + "/etax/documents/receipts/pdf-xml";
                break;
            case "INVOICE-TAXINVOICE":
                postUrl = API_URL + "/etax/documents/invoices-taxinvoices/pdf-xml";
                break;
            case "DELIVERYORDER-TAXINVOICE":
                postUrl = API_URL + "/etax/documents/deliveryorders-taxinvoices/pdf-xml";
                break;
            case "TAXINVOICE":
                postUrl = API_URL + "/etax/documents/taxinvoices/pdf-xml";
                break;
            default:
                break;
        }

        if (postUrl) {
            let _countingNumberLength = countingNumber.length;
            let _countingNumber = Number(countingNumber);

            for (let i = 0; i < selectedItems.length; i++) {
                let _getOrderItems = [];
                let _orderItems = [];
                let _email = "";

                let _discount = 0;
                let _amountTotal = 0;
                let _vatTotal = 0;
                let _grandTotal = 0;

                if (selectedItems[i].orderId) {
                    _getOrderItems = await getOrderItems(selectedItems[i].orderId);

                    console.log("getOrderItems: ", _getOrderItems);
                    let number = 0;
                    if (_getOrderItems.length > 0) {
                        _email = _getOrderItems[0].digital_delivery_info;

                        if (registerVat) {
                            for (let j = 0; j < _getOrderItems.length; j++) {
                                _discount = _discount + _getOrderItems[j].voucher_seller;

                                let _variation = ''
                                if (_getOrderItems[j].variation) {
                                    _variation = ` (${_getOrderItems[j].variation})`
                                }

                                let _sku = ""
                                if (_getOrderItems[j].sku) {
                                    _sku = _getOrderItems[j].sku
                                }

                                let _price = Math.round(_getOrderItems[j].item_price * 100) / 100;

                                if (_orderItems.some((item) => item.orderItemId === _getOrderItems[j].order_item_id)) {
                                    _orderItems.forEach((item) => {
                                        if (item.description === (_getOrderItems[j].name + _variation)) {
                                            item.quantity += 1;
                                            item.total += _price;
                                        }
                                    });
                                } else {
                                    number += 1;

                                    _orderItems.push({
                                        number: number + "",
                                        description: _getOrderItems[j].name + _variation,
                                        quantity: 1,
                                        unitCode: "",
                                        unitName: "-",
                                        price: _price,
                                        discount: 0,
                                        percentVat: percentVat,
                                        percentVatText: percentVatText,
                                        total: _price,
                                        orderItemId: _getOrderItems[j].order_item_id,
                                        sku: _sku
                                    });
                                }
                            }

                            if (showShippingFee) {
                                number += 1;

                                _orderItems.push({
                                    number: number + "",
                                    description: "ค่าขนส่ง",
                                    quantity: 1,
                                    unitCode: "",
                                    unitName: "-",
                                    price: selectedItems[i].shippingFee,
                                    discount: 0,
                                    percentVat: percentVat,
                                    percentVatText: percentVatText,
                                    total: selectedItems[i].shippingFee,
                                    orderItemId: 0,
                                    sku: ""
                                });
                            }
                        } else {
                            for (let j = 0; j < _getOrderItems.length; j++) {
                                _discount = _discount + _getOrderItems[j].voucher_seller;

                                let _variation = ''
                                if (_getOrderItems[j].variation) {
                                    _variation = ` (${_getOrderItems[j].variation})`
                                }

                                let _sku = ""
                                if (_getOrderItems[j].sku) {
                                    _sku = _getOrderItems[j].sku
                                }

                                
                                let _price = Math.round(_getOrderItems[j].item_price * 100) / 100;

                                if (_orderItems.some((item) => item.orderItemId === _getOrderItems[j].order_item_id)) {
                                    _orderItems.forEach((item) => {
                                        if (item.description === (_getOrderItems[j].name + _variation)) {
                                            item.quantity += 1;
                                            item.total += _price;
                                        }
                                    });
                                } else {
                                    number += 1;

                                    _orderItems.push({
                                        number: number + "",
                                        description: (_getOrderItems[j].name + _variation),
                                        quantity: 1,
                                        unitCode: "",
                                        unitName: "-",
                                        price: _price,
                                        discount: 0,
                                        percentVat: zeroVat,
                                        percentVatText: zeroVatText,
                                        total: _price,
                                        orderItemId: _getOrderItems[j].order_item_id,
                                        sku: _sku
                                    });
                                }
                            }

                            if (showShippingFee) {
                                number += 1;

                                _orderItems.push({
                                    number: number + "",
                                    description: "ค่าขนส่ง",
                                    quantity: 1,
                                    unitCode: "",
                                    unitName: "-",
                                    price: selectedItems[i].shippingFee,
                                    discount: 0,
                                    percentVat: zeroVat,
                                    percentVatText: zeroVatText,
                                    total: selectedItems[i].shippingFee,
                                    orderItemId: 0,
                                    sku: ""
                                });
                            }
                        }

                        _discount = Math.round(_discount * 100) / 100;

                        if (includeVat) {
                            _grandTotal = Math.round((_orderItems.reduce((accumulator, item) => accumulator + item.total, 0) - _discount) * 100) / 100;
                            _vatTotal = Math.round(((_grandTotal * percentVat) / (100 + percentVat)) * 100) / 100;
                            _amountTotal = Math.round((_grandTotal - _vatTotal) * 100) / 100;
                        } else {
                            _amountTotal = Math.round((_orderItems.reduce((accumulator, item) => accumulator + item.total, 0) - _discount) * 100) / 100;
                            _vatTotal = Math.round(((_amountTotal * percentVat) / 100) * 100) / 100;
                            _grandTotal = Math.round((_amountTotal + _vatTotal) * 100) / 100;
                        }

                        let _number = prefixNumber + padLeadingZeros(Number(_countingNumber), _countingNumberLength);
                        _countingNumber = padLeadingZeros(Number(_countingNumber) + 1, _countingNumberLength);
                        setCountingNumber(_countingNumber);

                        await instance
                            .acquireTokenSilent({
                                ...silentRequest,
                                account: account,
                            })
                            .then(async (tokenResponse) => {
                                console.log("create selected document: ", selectedItems[i]);

                                for (let j = 0; j < _items.length; j++) {
                                    if (_items[j].key === selectedItems[i].key) {
                                        console.log("item key: ", _items[j].key);
                                        _items[j].status = "processing";

                                        console.log("items: ", _items);
                                        setItems([..._items]);

                                        console.log("item: ", _items[j]);

                                        let _orderDate = timeRangeField.key === "updated_at" ? selectedItems[i].updatedTime : selectedItems[i].createdTime
                                        if (!_orderDate) {
                                            _orderDate = selectedItems[i].createdTime
                                        }

                                        let _date = parseDate(_orderDate);

                                        let _dateBE = `${_date.getDate()}`.padStart(2, "0") + "/" + `${_date.getMonth() + 1}`.padStart(2, "0") + "/" + (_date.getFullYear() + 543);
                                        let _dateCE = `${_date.getDate()}`.padStart(2, "0") + "/" + `${_date.getMonth() + 1}`.padStart(2, "0") + "/" + _date.getFullYear();

                                        let _customer = {
                                            addressLineOne: selectedItems[i].customerAddress,
                                            addressLineTwo: "",
                                            branchNumber: selectedItems[i].branchNumber,
                                            branchText: selectedItems[i].branchText,
                                            email: _email,
                                            name: selectedItems[i].customerName,
                                            phone: selectedItems[i].customerPhone !== "" ? selectedItems[i].customerPhone : "",
                                            phoneCountryCode: "+66",
                                            postcode: selectedItems[i].customerPostcode,
                                            taxId: selectedItems[i].customerTaxId,
                                            taxNumberType: selectedItems[i].taxNumberType,
                                            language: "th",
                                            unstructure: true,
                                        };

                                        console.log("_items: ", _orderItems);
                                        console.log("_customer: ", _customer);
                                        console.log(_date);
                                        console.log(_dateBE);
                                        console.log(_dateCE);

                                        let _vatPriceTotal = 0;
                                        let _zeroVatPriceTotal = 0;
                                        let _nonVatPriceTotal = 0;

                                        _orderItems.forEach((item) => {
                                            if (item.percentVat === percentVat) {
                                                _vatPriceTotal = _vatPriceTotal + item.total;
                                            } else if (item.percentVat === zeroVat) {
                                                _zeroVatPriceTotal = _zeroVatPriceTotal + item.total;
                                            }
                                        });

                                        const promise_PDF_XML = await new Promise(async (resolve) => {
                                            await axios
                                                .post(
                                                    postUrl,
                                                    {
                                                        amountTotal: _amountTotal,
                                                        company: {
                                                            ...company,
                                                            includeVat: includeVat,
                                                        },
                                                        reference: selectedItems[i].orderId,
                                                        customer: _customer,
                                                        date: _date.toISOString(),
                                                        dateBE: _dateBE,
                                                        dateCE: _dateCE,
                                                        discount: _discount,
                                                        grandTotal: _grandTotal,
                                                        items: _orderItems,
                                                        percentVat: registerVat ? percentVat : 0,
                                                        nonVatPriceTotal: _nonVatPriceTotal,
                                                        zeroVatPriceTotal: _zeroVatPriceTotal,
                                                        vatPriceTotal: _vatPriceTotal,
                                                        vatTotal: _vatTotal,
                                                        reIssue: false,
                                                        includeVat: includeVat,
                                                        number: _number,
                                                        note: selectedItems[i].note,
                                                        receivedBy: createdBy,
                                                        createdBy: createdBy,
                                                    },
                                                    {
                                                        headers: {
                                                            Authorization: "Bearer " + tokenResponse.accessToken,
                                                            "cid": companyId
                                                        },
                                                    }
                                                )
                                                .then(
                                                    async (response) => {
                                                        console.log("Create document response: ", response);

                                                        let count = 0;

                                                        let interval = await setInterval(async () => {
                                                            await axios
                                                                .get(`${API_URL}/etax/jobs/${response.data.id}`, {
                                                                    headers: {
                                                                        Authorization: "Bearer " + tokenResponse.accessToken,
                                                                        "Content-Type": "application/json",
                                                                        "cid": companyId
                                                                },
                                                                })
                                                                .then((jobsResponse) => {
                                                                    count += 1;
                                                                    console.log("Jobs response: ", j, jobsResponse.data.status, count);

                                                                    if (jobsResponse.data.status === "complete") {
                                                                        _items[j].status = "successful";
                                                                        _items[j].etaxCreated = true;

                                                                        setItems([..._items]);

                                                                        clearInterval(interval);

                                                                        resolve(true);
                                                                    } else if (count >= 300) {
                                                                        _items[j].status = "fail";

                                                                        setItems([..._items]);

                                                                        clearInterval(interval);

                                                                        resolve(false);
                                                                    }
                                                                });
                                                        }, 1000);
                                                    },
                                                    (error) => {
                                                        console.log("Error Create document response: ", error);
                                                        _items[j].status = "fail";
                                                        setItems([..._items]);
                                                        resolve(false);
                                                    }
                                                );
                                        }); //promise

                                        Promise.all([promise_PDF_XML]).then((value) => {
                                            console.log(i, value);
                                        });
                                    } //if
                                } //for j
                            })
                            .catch((error) => {
                                //Acquire token silent failure, and send an interactive request
                                console.log(error);
                                instance.acquireTokenRedirect(silentRequest);
                            });
                    }
                }
            }

            setIsCreateDocumentComplete(true);
            setIsCreateDocument(false);
        }

        setIsCreateDocumentComplete(true);
        setIsCreateDocument(false);
    };

    const createDocument = async (documentType, item) => {
        setIsLoading(true);

        console.log("item: ", item);

        let documentPath = "/etax/documents/receipts";

        switch (documentType) {
            case "RECEIPT-TAXINVOICE":
                documentPath = "/etax/documents/receipts-taxinvoices";
                break;
            case "RECEIPT-TAXINVOICE-ABB":
                documentPath = "/etax/documents/receipts-taxinvoices-abb";
                break;
            case "RECEIPT":
                documentPath = "/etax/documents/receipts";
                break;
            case "INVOICE-TAXINVOICE":
                documentPath = "/etax/documents/invoices-taxinvoices";
                break;
            case "DELIVERYORDER-TAXINVOICE":
                documentPath = "/etax/documents/deliveryorders-taxinvoices";
                break;
            case "TAXINVOICE":
                documentPath = "/etax/documents/taxinvoices";
                break;
            default:
                break;
        }

        let _orderDate = timeRangeField.key === "updated_at" ? item.updatedTime : item.createdTime
        if (!_orderDate) {
            _orderDate = item.createdTime
        }

        let _date = parseDate(_orderDate);

        let _dateBE = `${_date.getDate()}`.padStart(2, "0") + "/" + `${_date.getMonth() + 1}`.padStart(2, "0") + "/" + (_date.getFullYear() + 543);
        let _dateCE = `${_date.getDate()}`.padStart(2, "0") + "/" + `${_date.getMonth() + 1}`.padStart(2, "0") + "/" + _date.getFullYear();

        console.log(_date);
        console.log(_dateBE);
        console.log(_dateCE);

        let _orderItems = [];
        let _items = [];
        let _email = "";

        let _discount = 0;
        let _amountTotal = 0;
        let _vatTotal = 0;
        let _grandTotal = 0;

        if (item.orderId) {
            _orderItems = await getOrderItems(item.orderId);

            console.log("_orderItems", _orderItems);
            let number = 0;

            if (_orderItems.length > 0) {
                _email = _orderItems[0].digital_delivery_info;

                if (registerVat) {
                    for (let i = 0; i < _orderItems.length; i++) {
                        _discount = _discount + _orderItems[i].voucher_seller;

                        let _variation = ''
                        if (_orderItems[i].variation) {
                        _variation = ` (${_orderItems[i].variation})`
                        }

                        let _sku = ""
                        if (_orderItems[i].sku) {
                            _sku = _orderItems[i].sku
                        }

                        let _price = Math.round(_orderItems[i].item_price * 100) / 100;

                        if (_items.some((item) => item.orderItemId === _orderItems[i].order_item_id)) {
                            _items.forEach((item) => {
                                if (item.description === (_orderItems[i].name + _variation)) {
                                    item.quantity += 1;
                                    item.total += _price;
                                }
                            });
                        } else {
                            number += 1;

                            _items.push({
                                number: number + "",
                                description: _orderItems[i].name + _variation,
                                quantity: 1,
                                unitCode: "",
                                unitName: "-",
                                price: _price,
                                discount: 0,
                                percentVat: percentVat,
                                percentVatText: percentVatText,
                                total: _price,
                                orderItemId: _orderItems[i].order_item_id,
                                sku: _sku
                            });
                        }
                    }

                    if (showShippingFee) {
                        number += 1;

                        _items.push({
                            number: number + "",
                            description: "ค่าขนส่ง",
                            quantity: 1,
                            unitCode: "",
                            unitName: "-",
                            price: item.shippingFee,
                            discount: 0,
                            percentVat: percentVat,
                            percentVatText: percentVatText,
                            total: item.shippingFee,
                            orderItemId: 0,
                            sku: ""
                        });
                    }
                } else {
                    for (let i = 0; i < _orderItems.length; i++) {
                        _discount = _discount + _orderItems[i].voucher_seller;

                        let _variation = ''
                        if (_orderItems[i].variation) {
                        _variation = ` (${_orderItems[i].variation})`
                        }

                        let _sku = ""
                        if (_orderItems[i].sku) {
                            _sku = _orderItems[i].sku
                        }

                        let _price = Math.round(_orderItems[i].item_price * 100) / 100;

                        if (_items.some((item) => item.orderItemId === _orderItems[i].order_item_id)) {
                            _items.forEach((item) => {
                                if (item.description === (_orderItems[i].name + _variation)) {
                                    item.quantity += 1;
                                    item.total += _price;
                                }
                            });
                        } else {
                            number += 1;

                            _items.push({
                                number: number + "",
                                description: _orderItems[i].name + _variation,
                                quantity: 1,
                                unitCode: "",
                                unitName: "-",
                                price: _price,
                                discount: 0,
                                percentVat: zeroVat,
                                percentVatText: zeroVatText,
                                total: _price,
                                orderItemId: _orderItems[i].order_item_id,
                                sku: _sku
                            });
                        }
                    }

                    if (showShippingFee) {
                        number += 1;

                        _items.push({
                            number: number + "",
                            description: "ค่าขนส่ง",
                            quantity: 1,
                            unitCode: "",
                            unitName: "-",
                            price: item.shippingFee,
                            discount: 0,
                            percentVat: zeroVat,
                            percentVatText: zeroVatText,
                            total: item.shippingFee,
                            orderItemId: 0,
                            sku: ""
                        });
                    }
                }

                console.log("_items: ", _items);
            }
        }

        let _customer = {
            addressLineOne: item.customerAddress,
            addressLineTwo: "",
            branchNumber: item.branchNumber,
            branchText: item.branchText,
            email: _email,
            name: item.customerName,
            phone: item.customerPhone !== "" ? item.customerPhone : "",
            phoneCountryCode: "+66",
            postcode: item.customerPostcode,
            taxId: item.customerTaxId,
            taxNumberType: item.taxNumberType,
            language: "th",
            unstructure: true,
        };

        _discount = Math.round(_discount * 100) / 100;

        if (includeVat) {
            _grandTotal = Math.round((_items.reduce((accumulator, item) => accumulator + item.total, 0) - _discount) * 100) / 100;
            _vatTotal = Math.round(((_grandTotal * percentVat) / (100 + percentVat)) * 100) / 100;
            _amountTotal = Math.round((_grandTotal - _vatTotal) * 100) / 100;
        } else {
            _amountTotal = Math.round((_items.reduce((accumulator, item) => accumulator + item.total, 0) - _discount) * 100) / 100
            _vatTotal = Math.round(((_grandTotal * percentVat) / (100 + percentVat)) * 100) / 100;
            _grandTotal = Math.round((_amountTotal + _vatTotal) * 100) / 100;
        }

        console.log("_customer: ", _customer);

        console.log({
            pathname: documentPath,
            state: {
                command: "EDIT",
                amountTotal: _amountTotal,
                company: {
                    ...company,
                    includeVat: includeVat,
                },
                reference: item.orderId,
                customer: _customer,
                date: _date.toISOString(),
                dateBE: _dateBE,
                dateCE: _dateCE,
                grandTotal: _grandTotal,
                items: _items,
                number: "",
                percentVat: registerVat ? percentVat : 0,
                receivedBy: "Lazada",
                vatTotal: _vatTotal,
                reIssue: false,
                discount: _discount,
                backUrl: "/etax/sources/lazada",
                includeVat: includeVat,
                note: item.note,
            },
        });

        history.push({
            pathname: documentPath,
            state: {
                command: "EDIT",
                amountTotal: item.amountTotal,
                company: {
                    ...company,
                    includeVat: includeVat,
                },
                reference: item.orderId,
                customer: _customer,
                date: _date.toISOString(),
                dateBE: _dateBE,
                dateCE: _dateCE,
                grandTotal: item.grandTotal,
                items: _items,
                number: "",
                percentVat: registerVat ? percentVat : 0,
                receivedBy: "Lazada",
                vatTotal: item.vatTotal,
                reIssue: false,
                discount: _discount,
                backUrl: "/etax/sources/lazada",
                includeVat: includeVat,
                note: item.note,
            },
        });
    };

    function checkDuplicateCreatedDocuments(_selectedItems) {
        // const duplicateTaxInvoiceNumbers = new Set();
        const duplicateReferenceNumbers = new Set();
    
        // const taxInvoiceSelectedKey = new Set(_selectedItems.map(obj => obj.number));
        const referenceSelectedKey = new Set(_selectedItems.map(obj => obj.key));
        
        // const taxInvoiceCreatedDocuments = new Set(allDocs.map(obj => obj.data.number));
        const referenceCreatedDocuments = new Set(allDocs.map(obj => obj.data.reference));
        
    
        // for (const number of taxInvoiceSelectedKey) {
        //     if (taxInvoiceCreatedDocuments.has(number)) {
        //       duplicateTaxInvoiceNumbers.add(number);
        //     }
        // }
    
        for (const reference of referenceSelectedKey) {
          if (referenceCreatedDocuments.has(reference)) {
            duplicateReferenceNumbers.add(reference);
          }
        }
    
        // const duplicateTaxInvoice = allDocs.filter(doc => duplicateTaxInvoiceNumbers.has(doc.data.number));
        const duplicateReference = allDocs.filter(doc => duplicateReferenceNumbers.has(doc.data.reference));
    
        return { /* duplicateTaxInvoice: duplicateTaxInvoice, */ duplicateReference: duplicateReference }
    }

    async function createSelectedDocumentsOnClick(documentType) {
        const { /* duplicateTaxInvoice,  */duplicateReference} = checkDuplicateCreatedDocuments(selectedItems)
        
        if (/* duplicateTaxInvoice.length ||  */duplicateReference.length) {
            setDialogConfirm((prev) => ({
                ...prev,
                isClick: true,
                header: /* duplicateTaxInvoice.length ? t("lazada.duplicateTaxInvoiceHeader") :  */t("lazada.duplicateReferenceHeader"),
                message: '',
                maxWidth: 680,
                content: 
                <Stack tokens={{ childrenGap: 10}}>
                    {/* {duplicateTaxInvoice.length ? (
                    <>
                        <label>{t("lazada.duplicateTaxInvoiceList")}</label>
                        <div style={styles.scrollable}>
                        <table style={styles.table}>
                            <thead>
                            <tr>
                                <th style={styles.th}>{t("lazada.taxInvoiceNumber")}</th>
                                <th style={styles.th}>{t("lazada.createAt")}</th>
                                
                            </tr>
                            </thead>
                            <tbody>
                            {duplicateTaxInvoice.map(_taxinvoice => (
                                <tr key={_taxinvoice.id}>
                                <td style={styles.td}>{_taxinvoice.data.number}</td>
                                <td style={styles.td}>{toThaiDateString(_taxinvoice.data.createdTime)}</td>

                                </tr>
                            ))}
                            </tbody>
                        </table>

                        </div>
                    </>
                    ) : null} */}

                    {duplicateReference.length ? (
                    <>
                        <label>{t("lazada.duplicateReferenceList")}</label>
                        <div style={styles.scrollable}>
                        <table style={styles.table}>
                            <thead>
                            <tr>
                                <th style={styles.th}>{t("lazada.reference")}</th>
                                <th style={styles.th}>{t("lazada.taxInvoiceNumber")}</th>
                                <th style={styles.th}>{t("lazada.createAt")}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {duplicateReference.map(_taxinvoice => (
                                <tr key={_taxinvoice.id}>
                                <td style={styles.td}>{_taxinvoice.data.reference}</td>
                                <td style={styles.td}>{_taxinvoice.data.number}</td>
                                <td style={styles.td}>{toThaiDateString(_taxinvoice.data.createdTime)}</td>

                                </tr>
                            ))}
                            </tbody>
                        </table>

                        </div>
                    </>
                    ) : null}

                    <label>{t("lazada.proceedMessage")} {<strong>"{t("documents.confirm")}"</strong>}</label>
                </Stack>,
                onConfirm: async () => {
                setDialogConfirm((prev) => ({
                    isClick: false,
                    header: '',
                    message: '',
                    onConfirm: null,
                    onCancel: null,
                }));

                setIsCreateDocument(true);
                await createSelectedDocuments(documentType);
                },
                onCancel: () =>
                setDialogConfirm((prev) => ({
                    isClick: false,
                    header: '',
                    message: '',
                    onConfirm: null,
                    onCancel: null,
                })),
            }));
        } else {
            setIsCreateDocument(true);
            await createSelectedDocuments(documentType);
        }
    }

    const columns = [
        ...(timeRangeField.key === "created_at" ? [
            {
                key: "column1",
                name: t("lazada.createdTime"),
                fieldName: "createdTime",
                minWidth: 90,
                maxWidth: 125,
                isResizable: true,
                isSorted: primarySort === "createdTime" ? true : false,
                isSortedDescending: isSortedDescendingCreatedTime,
                isSortedAscending: !isSortedDescendingCreatedTime,
                onColumnClick: (item) => {
                    console.log("Sort document createTime!");
                    setPrimarySort("createdTime");
                    setIsSortedDescendingCreatedTime(!isSortedDescendingCreatedTime);
    
                    sessionStorage.setItem("lazada-primarySort", "createdTime");
                    sessionStorage.setItem("lazada-sort-createdTime", !isSortedDescendingCreatedTime === false ? "false" : "true");
    
                    sessionStorage.removeItem("lazada-sort-number");
                    sessionStorage.removeItem("lazada-sort-customerName");
                    sessionStorage.removeItem("lazada-sort-vatTotal");
                    sessionStorage.removeItem("lazada-sort-grandTotal");
                    sessionStorage.removeItem("lazada-sort-isRequest");
    
                    setLoadDataComplete(false);
                    setItems([]);
                    setGetNewDocuments(true);
    
                    setTotalDocuments(0);
    
                    setCurrentPage(1);
    
                    setIsCreateDocumentComplete(false);
                    setSelectedItems([]);
                },
                data: "string",
                onRender: (item) => {
                    return (
                        <TooltipHost content={timestamp_toDate(item.createdTime)}>
                            <span>{timestamp_toDate(item.createdTime)}</span>
                        </TooltipHost>
                    )
                },
                // isPadded: true,
            },

        ] : [
            {
                key: "column1",
                name: getUpdatedTimeText(orderType.key),
                fieldName: "updateTime",
                minWidth: 90,
                maxWidth: 125,
                isResizable: true,
                isSorted: primarySort === "createdTime" ? true : false,
                isSortedDescending: isSortedDescendingCreatedTime,
                isSortedAscending: !isSortedDescendingCreatedTime,
                onColumnClick: (item) => {
                    console.log("Sort document createTime!");
                    setPrimarySort("createdTime");
                    setIsSortedDescendingCreatedTime(!isSortedDescendingCreatedTime);
    
                    sessionStorage.setItem("lazada-primarySort", "createdTime");
                    sessionStorage.setItem("lazada-sort-createdTime", !isSortedDescendingCreatedTime === false ? "false" : "true");
    
                    sessionStorage.removeItem("lazada-sort-number");
                    sessionStorage.removeItem("lazada-sort-customerName");
                    sessionStorage.removeItem("lazada-sort-vatTotal");
                    sessionStorage.removeItem("lazada-sort-grandTotal");
                    sessionStorage.removeItem("lazada-sort-isRequest");
    
                    setLoadDataComplete(false);
                    setItems([]);
                    setGetNewDocuments(true);
    
                    setTotalDocuments(0);
    
                    setCurrentPage(1);
    
                    setIsCreateDocumentComplete(false);
                    setSelectedItems([]);
                },
                data: "string",
                onRender: (item) => {
                    return (
                        <TooltipHost content={timestamp_toDate(item.updatedTime)}>
                            <span>{timestamp_toDate(item.updatedTime)}</span>
                        </TooltipHost>
                    )
                },
                // isPadded: true,
            }
        ]),
        {
            key: "column2",
            name: t("lazada.orderId"),
            fieldName: "orderId",
            minWidth: 120,
            maxWidth: 120,
            isRowHeader: true,
            isResizable: true,
            isSorted: primarySort === "number" ? true : false,
            isSortedDescending: isSortedDescendingNumber,
            isSortedAscending: !isSortedDescendingNumber,
            onColumnClick: () => {
                console.log("Sort document number!");
                setPrimarySort("number");
                setIsSortedDescendingNumber(!isSortedDescendingNumber);

                sessionStorage.setItem("lazada-primarySort", "number");
                sessionStorage.setItem("lazada-sort-number", !isSortedDescendingNumber === false ? "false" : "true");

                sessionStorage.removeItem("lazada-sort-createdTime");
                sessionStorage.removeItem("lazada-sort-customerName");
                sessionStorage.removeItem("lazada-sort-vatTotal");
                sessionStorage.removeItem("lazada-sort-grandTotal");
                sessionStorage.removeItem("lazada-sort-isRequest");

                setLoadDataComplete(false);
                setItems([]);
                setGetNewDocuments(true);

                setTotalDocuments(0);

                setCurrentPage(1);

                setIsCreateDocumentComplete(false);
                setSelectedItems([]);
            },
            data: "string",
            onRender: (item) => {
                return <span>{item.orderId}</span>;
            },
            // isPadded: true,
        },
        {
            key: "column3",
            name: t("lazada.customerName"),
            fieldName: "customerName",
            minWidth: 70,
            maxWidth: 110,
            isResizable: true,
            isSorted: primarySort === "customerName" ? true : false,
            isSortedDescending: isSortedDescendingCustomerName,
            isSortedAscending: !isSortedDescendingCustomerName,
            onColumnClick: () => {
                console.log("Sort document customerName!");
                setPrimarySort("customerName");
                setIsSortedDescendingCustomerName(!isSortedDescendingCustomerName);

                handleSort("customerName", !isSortedDescendingCustomerName);

                sessionStorage.setItem("lazada-primarySort", "customerName");
                sessionStorage.setItem("lazada-sort-customerName", !isSortedDescendingCustomerName === false ? "false" : "true");

                sessionStorage.removeItem("lazada-sort-createdTime");
                sessionStorage.removeItem("lazada-sort-number");
                sessionStorage.removeItem("lazada-sort-vatTotal");
                sessionStorage.removeItem("lazada-sort-grandTotal");
                sessionStorage.removeItem("lazada-sort-isRequest");

                setIsCreateDocumentComplete(false);
                setSelectedItems([]);
            },
            data: "string",
            onRender: (item) => {
                return <span>{item.customerName}</span>;
            },
            // isPadded: true,
        },
        {
            key: "column4",
            name: t("lazada.customerAddress"),
            fieldName: "customerAddress",
            minWidth: 100,
            maxWidth: 270,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            onColumnClick: "",
            data: "string",
            onRender: (item) => {
                return <span>{item.customerAddress}</span>;
            },
            // isPadded: true,
        },
        // {
        //     key: "column5",
        //     name: t("lazada.phone"),
        //     fieldName: "name",
        //     minWidth: 50,
        //     maxWidth: 80,
        //     isRowHeader: true,
        //     isResizable: true,
        //     isSorted: false,
        //     onColumnClick: "",
        //     data: "string",
        //     onRender: (item) => {
        //         return <span>{item.customerPhone}</span>;
        //     },
        //     // isPadded: true,
        // },
        {
            key: "column6",
            name: t("lazada.vatTotal"),
            fieldName: "vatTotal",
            minWidth: 70,
            maxWidth: 70,
            isResizable: true,
            isSorted: primarySort === "vatTotal" ? true : false,
            isSortedDescending: isSortedDescendingVatTotal,
            isSortedAscending: !isSortedDescendingVatTotal,
            onColumnClick: () => {
                console.log("Sort document vatTotal!");
                setPrimarySort("vatTotal");
                setIsSortedDescendingVatTotal(!isSortedDescendingVatTotal);

                handleSort("vatTotal", !isSortedDescendingVatTotal);

                sessionStorage.setItem("lazada-primarySort", "vatTotal");
                sessionStorage.setItem("lazada-sort-vatTotal", !isSortedDescendingVatTotal === false ? "false" : "true");

                sessionStorage.removeItem("lazada-sort-number");
                sessionStorage.removeItem("lazada-sort-customerName");
                sessionStorage.removeItem("lazada-sort-createdTime");
                sessionStorage.removeItem("lazada-sort-grandTotal");
                sessionStorage.removeItem("lazada-sort-isRequest");

                setIsCreateDocumentComplete(false);
                setSelectedItems([]);
            },
            data: "string",
            onRender: (item) => {
                return (
                    <span style={{ display: "block", textAlign: "right" }}>
                        {item.vatTotal.toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                        })}
                    </span>
                );
            },
            // isPadded: true,
        },
        {
            key: "column7",
            name: registerVat ? t("lazada.grandTotal") : t("lazada.total"),
            fieldName: "grandTotal",
            minWidth: 70,
            maxWidth: 70,
            isResizable: true,
            isSorted: primarySort === "grandTotal" ? true : false,
            isSortedDescending: isSortedDescendingGrandTotal,
            isSortedAscending: !isSortedDescendingGrandTotal,
            onColumnClick: () => {
                console.log("Sort document grandTotal!");
                setPrimarySort("grandTotal");
                setIsSortedDescendingGrandTotal(!isSortedDescendingGrandTotal);

                handleSort("grandTotal", !isSortedDescendingGrandTotal);

                sessionStorage.setItem("lazada-primarySort", "grandTotal");
                sessionStorage.setItem("lazada-sort-grandTotal", !isSortedDescendingGrandTotal === false ? "false" : "true");

                sessionStorage.removeItem("lazada-sort-customerName");
                sessionStorage.removeItem("lazada-sort-number");
                sessionStorage.removeItem("lazada-sort-vatTotal");
                sessionStorage.removeItem("lazada-sort-createdTime");
                sessionStorage.removeItem("lazada-sort-isRequest");

                setIsCreateDocumentComplete(false);
                setSelectedItems([]);
            },
            data: "string",
            onRender: (item) => {
                return (
                    <span style={{ display: "block", textAlign: "right" }}>
                        {item.grandTotal.toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                        })}
                    </span>
                );
            },
            // isPadded: true,
        },
        {
            key: "column8",
            name: t("lazada.isRequest"),
            fieldName: "isRequest",
            minWidth: 50,
            maxWidth: 50,
            isResizable: true,
            isSorted: primarySort === "isRequest" ? true : false,
            isSortedDescending: isSortedDescendingIsRequest,
            isSortedAscending: !isSortedDescendingIsRequest,
            onColumnClick: () => {
                console.log("Sort document number!");
                setPrimarySort("isRequest");
                setIsSortedDescendingIsRequest(!isSortedDescendingIsRequest);

                handleSort("isRequest", !isSortedDescendingIsRequest);

                sessionStorage.setItem("lazada-primarySort", "isRequest");
                sessionStorage.setItem("lazada-sort-isRequest", !isSortedDescendingIsRequest === false ? "false" : "true");

                sessionStorage.removeItem("lazada-sort-customerName");
                sessionStorage.removeItem("lazada-sort-number");
                sessionStorage.removeItem("lazada-sort-vatTotal");
                sessionStorage.removeItem("lazada-sort-createdTime");
                sessionStorage.removeItem("lazada-sort-grandTotal");

                setIsCreateDocumentComplete(false);
                setSelectedItems([]);
            },
            data: "string",
            onRender: (item) => {
                if (item.isRequest) {
                    return (
                        <div style={{ textAlign: "center" }}>
                            <Icon iconName="SkypeCircleCheck" style={{ fontSize: "20px", color: "green" }} />
                        </div>
                    );
                }
            },
        },
        {
            key: "column9",
            name: "Leceipt",
            fieldName: "etaxCreated",
            minWidth: 50,
            maxWidth: 50,
            isResizable: true,
            data: "string",
            onRender: (item) => {
                if (item.etaxCreated) {
                    return (
                        <div style={{ textAlign: "center" }}>
                            <Icon iconName="SkypeCircleCheck" style={{ fontSize: "20px", color: "green" }} />
                        </div>
                    );
                }
            },
        },
        {
            key: "column10",
            name: t("lazada.awb"),
            fieldName: "awb",
            minWidth: 60,
            maxWidth: 60,
            isResizable: true,
            data: "string",
            onRender: (item) => (
                <Stack horizontal data-selection-disabled={true}>
                    <IconButton
                        onClick={() => {
                            getOrderAWB(item.key);
                        }}
                        iconProps={{
                        ...getFileTypeIconProps({
                            extension: "txt",
                            size: 32,
                            imageFileType: 'svg',
                        }),
                        }}
                    />
                </Stack>
            )
        },
        {
            key: "column11",
            name: "",
            fieldName: "command",
            minWidth: 120,
            maxWidth: 120,
            isResizable: true,
            data: "string",
            onColumnClick: "",
            onRender: (item) => {
                if (isCreateDocument || isCreateDocumentComplete) {
                    if (item.status === "pending") {
                        return <span style={{ color: "#106EBE" }}>{t("lazada.pending")}</span>;
                    } else if (item.status === "processing") {
                        return <Spinner label={t("lazada.processing")} labelPosition="right" style={{ marginLeft: "-35px" }} />;
                    } else if (item.status === "successful") {
                        return <span style={{ color: "green" }}>{t("lazada.success")}</span>;
                    } else if (item.status === "fail") {
                        return <span style={{ color: "red" }}>{t("lazada.fail")}</span>;
                    }
                } else {
                    return (
                        <span data-selection-disabled={true}>
                            <DefaultButton
                                text={t("lazada.create")}
                                iconProps={{ iconName: "Add", style: { color: "#106ebe" } }}
                                menuProps={{
                                    items: [
                                        {
                                            key: "RECEIPT-TAXINVOICE",
                                            text: t("lazada.receipt-taxinvoice"),
                                            iconProps: { iconName: "PageAdd" },
                                            onClick: () => {
                                                createDocument("RECEIPT-TAXINVOICE", item);
                                            },
                                        },
                                        {
                                            key: "RECEIPT-TAXINVOICE-ABB",
                                            text: t("lazada.receipt-taxinvoice-abb"),
                                            iconProps: { iconName: "PageAdd" },
                                            onClick: () => {
                                                createDocument("RECEIPT-TAXINVOICE-ABB", item);
                                            },
                                        },
                                        {
                                            key: "RECEIPT",
                                            text: t("lazada.receipt"),
                                            iconProps: { iconName: "PageAdd" },
                                            onClick: () => {
                                                createDocument("RECEIPT", item);
                                            },
                                        },
                                        {
                                            key: "INVOICE-TAXINVOICE",
                                            text: t("lazada.invoice-taxinvoice"),
                                            iconProps: { iconName: "PageAdd" },
                                            onClick: () => {
                                                createDocument("INVOICE-TAXINVOICE", item);
                                            },
                                        },
                                        {
                                            key: "DELIVERYORDER-TAXINVOICE",
                                            text: t("lazada.deliveryorder-taxinvoice"),
                                            iconProps: { iconName: "PageAdd" },
                                            onClick: () => {
                                                createDocument("DELIVERYORDER-TAXINVOICE", item);
                                            },
                                        },
                                        {
                                            key: "TAXINVOICE",
                                            text: t("lazada.taxinvoice"),
                                            iconProps: { iconName: "PageAdd" },
                                            onClick: () => {
                                                createDocument("TAXINVOICE", item);
                                            },
                                        },
                                    ],
                                    shouldFocusOnMount: true,
                                    directionalHintFixed: true,
                                }}
                                // Optional callback to customize menu rendering
                                menuAs={(props) => <ContextualMenu {...props} />}
                            />
                        </span>
                    );
                }
            },
        },
    ];

    const getMonth = (goBack) => {
        let monthNames = [t("lazada.months01"), t("lazada.months02"), t("lazada.months03"), t("lazada.months04"), t("lazada.months05"), t("lazada.months06"), t("lazada.months07"), t("lazada.months08"), t("lazada.months09"), t("lazada.months10"), t("lazada.months11"), t("lazada.months12")];

        let d = new Date();

        let adjustDate = new Date(d.getFullYear(), d.getMonth() - goBack, 1);

        let year = adjustDate.getFullYear() + 543;

        return {
            month: adjustDate.getMonth(),
            year: adjustDate.getFullYear(),
            key: adjustDate.getMonth() + 1,
            text: monthNames[adjustDate.getMonth()] + " " + year,
        };
    };

    const [queryMonth, setQueryMonth] = useState(
        (sessionStorage.getItem("lazada-queryMonth-key") && {
            key: sessionStorage.getItem("lazada-queryMonth-key"),
            text: sessionStorage.getItem("lazada-queryMonth-text"),
            ...(sessionStorage.getItem("lazada-queryMonth-type") ? { type: sessionStorage.getItem("lazada-queryMonth-type") } : {}),
        }) || { key: getMonth(0).year + "" + getMonth(0).key, text: t("lazada.thisMonth") }
    );

    const [timeRangeDate, setTimeRangeDate] = useState(sessionStorage.getItem("lazada-queryMonth-type") ? true : false);
    const [fromDate, setFromDate] = useState(sessionStorage.getItem("lazada-fromDate") ? new Date(sessionStorage.getItem("lazada-fromDate")) : null);
    const [toDate, setToDate] = useState(sessionStorage.getItem("lazada-toDate") ? new Date(sessionStorage.getItem("lazada-toDate")) : null);
    const [timeRangeError, setTimeRangeError] = useState(false);

    const getFrom = (month, year) => {
        let _firstDay = new Date(year, month, 1);

        return formatDate(_firstDay);
    };

    const getTo = (month, year) => {
        let _lastDay = new Date(year, month + 1, 1);

        return formatDate(_lastDay);
    };

    const getFromDMY = (day, month, year) => {
        let _firstDay = new Date(year, month, day);

        return formatDate(_firstDay);
    };

    const getToDMY = (day, month, year) => {
        let _lastDay = new Date(year, month, day + 1);

        return formatDate(_lastDay);
    };

    const getFrom2 = (month, year) => {
        let _firstDay = new Date(year, month, 1 - 15);

        return formatDate(_firstDay);
    };

    const formatDate = (d) => {
        let month = "" + (d.getMonth() + 1);
        let day = "" + d.getDate();
        let year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        return [year, month, day].join("-");
    };

    const [date, setDate] = useState(sessionStorage.getItem("lazada-date") ? new Date(sessionStorage.getItem("lazada-date")) : new Date());

    const [from, setFrom] = useState(sessionStorage.getItem("lazada-from") || getFrom(getMonth(0).month, getMonth(0).year));
    const [to, setTo] = useState(sessionStorage.getItem("lazada-to") || getTo(getMonth(0).month, getMonth(0).year));

    const [pageDropdown, setPageDropdown] = useState([]);

    const history = useHistory();

    function getDocumentTypeText(key) {
        if (key === "all") return t("lazada.allType");
        if (key === "delivered") return t("lazada.delivered");
        if (key === "shipped") return t("lazada.shipped");
        if (key === "ready_to_ship") return t("lazada.readyToShip");
        if (key === "pending") return t("lazada.pending");
        if (key === "unpaid") return t("lazada.unpaidType");
        if (key === "canceled") return t("lazada.cancel");
        if (key === "returned") return t("lazada.returned");
        if (key === "shipped_back") return t("lazada.shippedBack");
    }

    function getUpdatedTimeText(key) {
        if (key === "all") return t("lazada.updateTime");
        if (key === "delivered") return t("lazada.day1") + t("lazada.delivered") + t("lazada.day2");
        if (key === "shipped") return t("lazada.day1") + t("lazada.shipped") + t("lazada.day2");
        if (key === "ready_to_ship") return t("lazada.day1") + t("lazada.readyToShip") + t("lazada.day2");
        if (key === "pending") return t("lazada.day1") + t("lazada.pending") + t("lazada.day2");
        if (key === "unpaid") return t("lazada.day1") + t("lazada.unpaidType") + t("lazada.day2");
        if (key === "canceled") return t("lazada.day1") + t("lazada.cancel") + t("lazada.day2");
        if (key === "returned") return t("lazada.day1") + t("lazada.returned") + t("lazada.day2");
        if (key === "shipped_back") return t("lazada.day1") + t("lazada.shippedBack") + t("lazada.day2");
    }

    function getDateText(monthObject) {
        if (monthObject.type === "dateRange") {
            if (i18n.language === "th") {
                return onFormatDate(fromDate, "BE") + " - " + onFormatDate(toDate, "BE");
            }
            return onFormatDate(fromDate, "CE") + " - " + onFormatDate(toDate, "CE");
        }

        if (monthObject.text === "เดือนนี้" || monthObject.text === "This Month") return t("lazada.thisMonth");

        let monthNames = [t("lazada.months01"), t("lazada.months02"), t("lazada.months03"), t("lazada.months04"), t("lazada.months05"), t("lazada.months06"), t("lazada.months07"), t("lazada.months08"), t("lazada.months09"), t("lazada.months10"), t("lazada.months11"), t("lazada.months12")];

        let _monthIndex = Number(monthObject.key.substring(4, 6)) - 1;
        let _year = Number(monthObject.key.substring(0, 4));
        if (i18n.language === "th") {
            _year = _year + 543;
        }

        return monthNames[_monthIndex] + " " + _year;
    }

    const commandBarItems = [
        {
            key: orderType.key,
            text: getDocumentTypeText(orderType.key),
            cacheKey: "myCacheKey", // changing this key will invalidate this item's cache
            iconProps: { iconName: "ProductList" },
            disabled: !loadDataComplete || isCreateDocument || search,
            subMenuProps: {
                items: [
                    {
                        key: "all",
                        text: t("lazada.allType"),
                        iconProps: { iconName: "ProductList" },
                        onClick: () => {
                            console.log("ออร์เดอร์ทั้งหมด");
                            setOrderType({
                                key: "all",
                                text: t("lazada.allType"),
                            });

                            sessionStorage.setItem("lazada-orderType-key", "all");
                            sessionStorage.setItem("lazada-orderType-text", t("lazada.allType"));

                            setLoadDataComplete(false);
                            setItems([]);
                            setGetNewDocuments(true);

                            setTotalDocuments(0);

                            setSearch("");

                            setIsCreateDocumentComplete(false);
                            setSelectedItems([]);
                        },
                    },
                    {
                        key: "delivered",
                        text: t("lazada.delivered"),
                        iconProps: { iconName: "ProductList" },
                        onClick: () => {
                            console.log("จัดส่งสำเร็จ");
                            setOrderType({
                                key: "delivered",
                                text: t("lazada.delivered"),
                            });

                            sessionStorage.setItem("lazada-orderType-key", "delivered");
                            sessionStorage.setItem("lazada-orderType-text", t("lazada.delivered"));

                            setLoadDataComplete(false);
                            setItems([]);
                            setGetNewDocuments(true);

                            setTotalDocuments(0);

                            setSearch("");

                            setIsCreateDocumentComplete(false);
                            setSelectedItems([]);
                        },
                    },
                    {
                        key: "shipped",
                        text: t("lazada.shipped"),
                        iconProps: { iconName: "ProductList" },
                        onClick: () => {
                            console.log("อยู่ระหว่างการจัดส่ง");
                            setOrderType({
                                key: "shipped",
                                text: t("lazada.shipped"),
                            });

                            sessionStorage.setItem("lazada-orderType-key", "shipped");
                            sessionStorage.setItem("lazada-orderType-text", t("lazada.shipped"));

                            setLoadDataComplete(false);
                            setItems([]);
                            setGetNewDocuments(true);

                            setTotalDocuments(0);

                            setSearch("");

                            setIsCreateDocumentComplete(false);
                            setSelectedItems([]);
                        },
                    },
                    {
                        key: "ready_to_ship",
                        text: t("lazada.readyToShip"),
                        iconProps: { iconName: "ProductList" },
                        onClick: () => {
                            console.log("พร้อมส่ง");
                            setOrderType({
                                key: "ready_to_ship",
                                text: t("lazada.readyToShip"),
                            });

                            sessionStorage.setItem("lazada-orderType-key", "ready_to_ship");
                            sessionStorage.setItem("lazada-orderType-text", t("lazada.readyToShip"));

                            setLoadDataComplete(false);
                            setItems([]);
                            setGetNewDocuments(true);

                            setTotalDocuments(0);

                            setSearch("");

                            setIsCreateDocumentComplete(false);
                            setSelectedItems([]);
                        },
                    },
                    {
                        key: "pending",
                        text: t("lazada.pending"),
                        iconProps: { iconName: "ProductList" },
                        onClick: () => {
                            console.log("รอดำเนินการ");
                            setOrderType({
                                key: "pending",
                                text: t("lazada.pending"),
                            });

                            sessionStorage.setItem("lazada-orderType-key", "pending");
                            sessionStorage.setItem("lazada-orderType-text", t("lazada.pending"));

                            setLoadDataComplete(false);
                            setItems([]);
                            setGetNewDocuments(true);

                            setTotalDocuments(0);

                            setSearch("");

                            setIsCreateDocumentComplete(false);
                            setSelectedItems([]);
                        },
                    },
                    {
                        key: "unpaid",
                        text: t("lazada.unpaidType"),
                        iconProps: { iconName: "ProductList" },
                        onClick: () => {
                            console.log("ยังไม่ชำระเงิน");
                            setOrderType({
                                key: "unpaid",
                                text: t("lazada.unpaidType"),
                            });

                            sessionStorage.setItem("lazada-orderType-key", "unpaid");
                            sessionStorage.setItem("lazada-orderType-text", t("lazada.unpaidType"));

                            setLoadDataComplete(false);
                            setItems([]);
                            setGetNewDocuments(true);

                            setTotalDocuments(0);

                            setSearch("");

                            setIsCreateDocumentComplete(false);
                            setSelectedItems([]);
                        },
                    },
                    {
                        key: "canceled",
                        text: t("lazada.cancel"),
                        iconProps: { iconName: "ProductList" },
                        onClick: () => {
                            console.log("ยกเลิก");
                            setOrderType({
                                key: "canceled",
                                text: t("lazada.cancel"),
                            });

                            sessionStorage.setItem("lazada-orderType-key", "canceled");
                            sessionStorage.setItem("lazada-orderType-text", t("lazada.cancel"));

                            setLoadDataComplete(false);
                            setItems([]);
                            setGetNewDocuments(true);

                            setTotalDocuments(0);

                            setSearch("");

                            setIsCreateDocumentComplete(false);
                            setSelectedItems([]);
                        },
                    },
                    {
                        key: "returned",
                        text: t("lazada.returned"),
                        iconProps: { iconName: "ProductList" },
                        onClick: () => {
                            console.log("ตีกลับ");
                            setOrderType({
                                key: "returned",
                                text: t("lazada.returned"),
                            });

                            sessionStorage.setItem("lazada-orderType-key", "returned");
                            sessionStorage.setItem("lazada-orderType-text", t("lazada.returned"));

                            setLoadDataComplete(false);
                            setItems([]);
                            setGetNewDocuments(true);

                            setTotalDocuments(0);

                            setSearch("");

                            setIsCreateDocumentComplete(false);
                            setSelectedItems([]);
                        },
                    },
                    {
                        key: "shipped_back",
                        text: t("lazada.shippedBack"),
                        iconProps: { iconName: "ProductList" },
                        onClick: () => {
                            console.log("คืนสินค้า");
                            setOrderType({ key: "shipped_back", text: t("lazada.shippedBack") });

                            sessionStorage.setItem("lazada-orderType-key", "shipped_back");
                            sessionStorage.setItem("lazada-orderType-text", t("lazada.shippedBack"));

                            setLoadDataComplete(false);
                            setItems([]);
                            setGetNewDocuments(true);

                            setTotalDocuments(0);

                            setSearch("");

                            setIsCreateDocumentComplete(false);
                            setSelectedItems([]);
                        },
                    },
                ],
            },
        },
        {
            key: timeRangeField.key,
            // text: timeRangeField.text,
            text: timeRangeField.key === "updated_at" ? t("lazada.updateTime") : timeRangeField.key === "created_at" ? t("lazada.createTime") : timeRangeField.text,
            cacheKey: "myCacheKey", // changing this key will invalidate this item's cache
            iconProps: { iconName: "CalendarSettings" },
            disabled: !loadDataComplete || isCreateDocument || search,
            subMenuProps: {
                items: [
                    {
                        key: "updated_at",
                        text: t("lazada.updateTime"),
                        iconProps: { iconName: "CalendarSettings" },
                        onClick: () => {
                            console.log("วันที่อัปเดตสถานะ");
                            setTimeRangeField({
                                key: "updated_at",
                                text: t("lazada.updateTime"),
                            });

                            sessionStorage.setItem("lazada-timeRangeField-key", "updated_at");
                            sessionStorage.setItem("lazada-timeRangeField-text", t("lazada.updateTime"));

                            setLoadDataComplete(false);
                            setItems([]);
                            setGetNewDocuments(true);

                            setTotalDocuments(0);

                            setSearch("");

                            setIsCreateDocumentComplete(false);
                            setSelectedItems([]);
                        },
                    },
                    {
                        key: "created_at",
                        text: t("lazada.createTime"),
                        iconProps: { iconName: "CalendarSettings" },
                        onClick: () => {
                            console.log("วันที่สร้างเอกสาร");
                            setTimeRangeField({
                                key: "created_at",
                                text: t("lazada.createTime"),
                            });

                            sessionStorage.setItem("lazada-timeRangeField-key", "created_at");
                            sessionStorage.setItem("lazada-timeRangeField-text", t("lazada.createTime"));

                            setLoadDataComplete(false);
                            setItems([]);
                            setGetNewDocuments(true);

                            setTotalDocuments(0);

                            setSearch("");

                            setIsCreateDocumentComplete(false);
                            setSelectedItems([]);
                        },
                    },
                ],
            },
        },
        {
            key: queryMonth.key,
            text: getDateText(queryMonth),
            iconProps: { iconName: "Calendar" },
            disabled: !loadDataComplete || isCreateDocument,
            subMenuProps: {
                items: [
                    {
                        key: "queryMonth",
                    },
                ],
                onRenderMenuList: () => {
                    return (
                        <div style={{ width: 220 }}>
                            <Stack styles={{ root: { padding: "12px 12px 0px 12px" } }}>
                                <Checkbox
                                    label={t("sellSummary.timeRange")}
                                    checked={timeRangeDate}
                                    onChange={(e) => {
                                        setTimeRangeDate((prev) => !prev);
                                    }}
                                />
                            </Stack>
                            {timeRangeDate ? (
                                <Stack>
                                    <Stack horizontal styles={{ root: { height: 42, paddingTop: 10 } }}>
                                        <Stack styles={{ root: { width: "20%", justifyContent: "center", alignItems: "center" } }}>
                                            <Text>{t("sellSummary.fromRange")}</Text>
                                        </Stack>
                                        <Stack styles={{ root: { width: "80%", justifyContent: "center" /* alignItems: 'center' */ } }}>
                                            <DatePicker
                                                styles={{ root: { width: "90%" } }}
                                                placeholder={t("deliveryOrderTaxInvoice.pleaseSelect")}
                                                strings={DayPickerStrings}
                                                value={fromDate}
                                                maxDate={toDate}
                                                formatDate={onFormatDate}
                                                onSelectDate={(_date) => {
                                                    setFromDate(_date);

                                                    let adjustDate = _date;

                                                    setFrom(getFromDMY(adjustDate.getDate(), adjustDate.getMonth(), adjustDate.getFullYear()));

                                                    setTimeRangeError(false);
                                                    if (Math.ceil(Math.abs(_date - toDate) / (1000 * 60 * 60 * 24)) > 30) setTimeRangeError(true);
                                                }}
                                            />
                                        </Stack>
                                    </Stack>
                                    <Stack horizontal styles={{ root: { height: 42, paddingTop: 10 } }}>
                                        <Stack styles={{ root: { width: "20%", justifyContent: "center", alignItems: "center" } }}>
                                            <Text>{t("sellSummary.toRange")}</Text>
                                        </Stack>
                                        <Stack styles={{ root: { width: "80%" /* alignItems: 'center' */ } }}>
                                            <DatePicker
                                                styles={{ root: { width: "90%" } }}
                                                placeholder={t("deliveryOrderTaxInvoice.pleaseSelect")}
                                                strings={DayPickerStrings}
                                                value={toDate}
                                                minDate={fromDate}
                                                formatDate={onFormatDate}
                                                onSelectDate={(_date) => {
                                                    setToDate(_date);

                                                    let adjustDate = _date;

                                                    setTo(getToDMY(adjustDate.getDate(), adjustDate.getMonth(), adjustDate.getFullYear()));
                                                    setTimeRangeError(false);
                                                    if (Math.ceil(Math.abs(_date - fromDate) / (1000 * 60 * 60 * 24)) > 30) setTimeRangeError(true);
                                                }}
                                            />
                                        </Stack>
                                    </Stack>
                                    {timeRangeError ? (
                                        <Stack styles={{ root: { padding: "12px 0px 0px 12px" } }}>
                                            <Text styles={{ root: { fontSize: 12, color: "#A4262C" } }}>{t("sellSummary.timeRangeError")}</Text>
                                        </Stack>
                                    ) : null}
                                    <Stack horizontal horizontalAlign="end" styles={{ root: { width: "92%", height: 52, padding: "10px 0px 10px 0px" } }}>
                                        <PrimaryButton
                                            text={t("sellSummary.search")}
                                            onClick={() => {
                                                if (!timeRangeError && fromDate && toDate) {
                                                    setQueryMonth({
                                                        type: "dateRange",
                                                        key: formatDate(fromDate) + ":" + formatDate(toDate),
                                                        text: formatDate(fromDate) + " - " + formatDate(toDate),
                                                    });

                                                    sessionStorage.setItem("lazada-queryMonth-type", "dateRange");
                                                    sessionStorage.setItem("lazada-queryMonth-key", formatDate(fromDate) + ":" + formatDate(toDate));
                                                    sessionStorage.setItem("lazada-queryMonth-text", formatDate(fromDate) + " - " + formatDate(toDate));


                                                    sessionStorage.setItem("lazada-from2", getFromDMY(fromDate.getDate() - 15, fromDate.getMonth(), fromDate.getFullYear()));
                                                    sessionStorage.setItem("lazada-to2", getToDMY(toDate.getDate(), toDate.getMonth(), toDate.getFullYear()));

                                                    sessionStorage.setItem("lazada-from", getFromDMY(fromDate.getDate(), fromDate.getMonth(), fromDate.getFullYear()));
                                                    sessionStorage.setItem("lazada-to", getToDMY(toDate.getDate(), toDate.getMonth(), toDate.getFullYear()));

                                                    sessionStorage.setItem("lazada-fromDate", fromDate.toISOString());
                                                    sessionStorage.setItem("lazada-toDate", toDate.toISOString());

                                                    setSearch("");

                                                    setLoadDataComplete(false);
                                                    setItems([]);
                                                    setGetNewDocuments(true);

                                                    setTotalDocuments(0);

                                                    setCurrentPage(1);
                                                }
                                            }}
                                            style={{ width: "125px", letterSpacing: "1px" }}
                                        />
                                    </Stack>
                                </Stack>
                            ) : (
                                <Calendar
                                    onSelectDate={(_date) => {
                                        let monthNames = [
                                            t("lazada.months01"),
                                            t("lazada.months02"),
                                            t("lazada.months03"),
                                            t("lazada.months04"),
                                            t("lazada.months05"),
                                            t("lazada.months06"),
                                            t("lazada.months07"),
                                            t("lazada.months08"),
                                            t("lazada.months09"),
                                            t("lazada.months10"),
                                            t("lazada.months11"),
                                            t("lazada.months12"),
                                        ];

                                        setDate(_date);

                                        sessionStorage.setItem("lazada-date", _date.toISOString());

                                        let adjustDate = _date;

                                        let year = adjustDate.getFullYear() + 543;

                                        setQueryMonth({
                                            key: adjustDate.getFullYear() + "" + String(adjustDate.getMonth() + 1).padStart(2, "0"),
                                            text: monthNames[adjustDate.getMonth()] + " " + year,
                                        });

                                        sessionStorage.setItem("lazada-queryMonth-type", "");
                                        sessionStorage.setItem("lazada-queryMonth-key", adjustDate.getFullYear() + "" + String(adjustDate.getMonth() + 1).padStart(2, "0"));
                                        sessionStorage.setItem("lazada-queryMonth-text", monthNames[adjustDate.getMonth()] + " " + year);

                                        setFrom(getFrom(adjustDate.getMonth(), adjustDate.getFullYear()));
                                        setTo(getTo(adjustDate.getMonth(), adjustDate.getFullYear()));

                                        sessionStorage.setItem("lazada-from", getFrom(adjustDate.getMonth(), adjustDate.getFullYear()));
                                        sessionStorage.setItem("lazada-to", getTo(adjustDate.getMonth(), adjustDate.getFullYear()));


                                        sessionStorage.setItem("lazada-from2", getFrom2(adjustDate.getMonth(), adjustDate.getFullYear()));
                                        sessionStorage.setItem("lazada-to2", getTo(adjustDate.getMonth(), adjustDate.getFullYear()));

                                        setSearch("");

                                        setLoadDataComplete(false);
                                        setItems([]);
                                        setGetNewDocuments(true);

                                        setTotalDocuments(0);

                                        setCurrentPage(1);
                                    }}
                                    isMonthPickerVisible={true}
                                    highlightSelectedMonth={true}
                                    isDayPickerVisible={false}
                                    dateRangeType={DateRangeType.Day}
                                    autoNavigateOnSelection={false}
                                    showGoToToday={false}
                                    value={date}
                                    strings={DayPickerStrings}
                                    showSixWeeksByDefault={true}
                                />
                            )}
                        </div>
                    );
                },
            },
        },
        {
            key: "pageSize " + pageSize,
            text: pageSize + " " + t("lazada.documentPerPage"),
            cacheKey: "myCacheKey", // changing this key will invalidate this item's cache
            iconProps: { iconName: "NumberedList" },
            disabled: !loadDataComplete || isCreateDocument || search,
            subMenuProps: {
                items: [
                    {
                        key: "pageSize " + 10,
                        text: "10 " + t("lazada.documentPerPage"),
                        iconProps: { iconName: "NumberedList" },
                        onClick: () => {
                            console.log("10 ออเดอร์/หน้า");
                            setPageSize(10);
                            sessionStorage.setItem("lazada-pageSize", 10);

                            setSearch("");

                            setLoadDataComplete(false);
                            setItems([]);
                            setGetNewDocuments(true);

                            setTotalDocuments(0);

                            setCurrentPage(1);
                        },
                    },
                    {
                        key: "pageSize " + 20,
                        text: "20 " + t("lazada.documentPerPage"),
                        iconProps: { iconName: "NumberedList" },
                        onClick: () => {
                            console.log("20 ออเดอร์/หน้า");
                            setPageSize(20);
                            sessionStorage.setItem("lazada-pageSize", 20);

                            setSearch("");

                            setLoadDataComplete(false);
                            setItems([]);
                            setGetNewDocuments(true);

                            setTotalDocuments(0);

                            setCurrentPage(1);
                        },
                    },
                    {
                        key: "pageSize " + 30,
                        text: "30 " + t("lazada.documentPerPage"),
                        iconProps: { iconName: "NumberedList" },
                        onClick: () => {
                            console.log("30 ออเดอร์/หน้า");
                            setPageSize(30);
                            sessionStorage.setItem("lazada-pageSize", 30);

                            setSearch("");

                            setLoadDataComplete(false);
                            setItems([]);
                            setGetNewDocuments(true);

                            setTotalDocuments(0);

                            setCurrentPage(1);
                        },
                    },
                    {
                        key: "pageSize " + 40,
                        text: "40 " + t("lazada.documentPerPage"),
                        iconProps: { iconName: "NumberedList" },
                        onClick: () => {
                            console.log("40 ออเดอร์/หน้า");
                            setPageSize(40);
                            sessionStorage.setItem("lazada-pageSize", 40);

                            setSearch("");

                            setLoadDataComplete(false);
                            setItems([]);
                            setGetNewDocuments(true);

                            setTotalDocuments(0);

                            setCurrentPage(1);
                        },
                    },
                    {
                        key: "pageSize " + 50,
                        text: "50 " + t("lazada.documentPerPage"),
                        iconProps: { iconName: "NumberedList" },
                        onClick: () => {
                            console.log("50 ออเดอร์/หน้า");
                            setPageSize(50);
                            sessionStorage.setItem("lazada-pageSize", 50);

                            setSearch("");

                            setLoadDataComplete(false);
                            setItems([]);
                            setGetNewDocuments(true);

                            setTotalDocuments(0);

                            setCurrentPage(1);
                        },
                    },
                ],
            },
        },
        {
            key: currentPage,
            text: t("lazada.page") + " " + currentPage,
            iconProps: { iconName: "Page" },
            disabled: !loadDataComplete || isCreateDocument || search,
            subMenuProps: {
                items: pageDropdown,
            },
        },
        {
            key: "refresh",
            text: t("lazada.refresh"),
            iconProps: { iconName: "Refresh" },
            disabled: !loadDataComplete || isCreateDocument || search,
            onClick: () => {
                console.log("Refresh");

                setSearch("");

                setLoadDataComplete(false);
                setItems([]);
                setGetNewDocuments(true);

                setTotalDocuments(0);

                setCurrentPage(1);

                setIsCreateDocumentComplete(false);
                setSelectedItems([]);
            },
        },
    ];

    const overflowItems = [
        {
            key: "transactionList",
            text: t("lazada.transactionList"),
            disabled: !loadDataComplete || isCreateDocument || search,
            onClick: () => {
                history.push({
                    pathname: '/etax/sources/lazada/finance',
                    state: {},
                });
            },
            iconProps: { iconName: "Financial" },
        },
        {
            key: "payoutList",
            text: t("lazada.payoutList"),
            disabled: !loadDataComplete || isCreateDocument || search,
            onClick: () => {
                history.push({
                    pathname: '/etax/sources/lazada/payout',
                    state: {},
                });
            },
            iconProps: { iconName: "Bank" },
        },
        {
            key: "disconnect",
            text: t("lazada.disconnect"),
            onClick: () => {
                console.log("Lazada disconnect!");
                setDialogConfirm((prev) => ({
                    ...prev,
                    isClick: true,
                    header: t("lazada.disconnect"),
                    message: t("lazada.disconnectConfirm"),
                    onConfirm: () => {
                        disconnect(true);
                        setDialogConfirm((prev) => ({
                            ...prev,
                            isClick: false,
                        }));
                    },
                    onCancel: () =>
                        setDialogConfirm((prev) => ({
                            ...prev,
                            isClick: false,
                        })),
                }));
            },
            iconProps: { iconName: "PlugDisconnected" },
        },
    ];

    const padLeadingZeros = (num, size) => {
        var s = num + "";
        while (s.length < size) s = "0" + s;
        return s;
    };

    const disconnect = (_confirm) => {
        let confirm = _confirm;

        if (confirm) {
            setIsLoading(true);

            instance.acquireTokenSilent({ ...silentRequest, account: account }).then(
                (tokenResponse) => {
                    axios
                        .patch(
                            API_URL + "/sources",
                            {
                                source: "lazada",
                                replace: "/disabled",
                                value: true,
                            },
                            {
                                headers: {
                                    Authorization: "Bearer " + tokenResponse.accessToken,
                                    "cid": companyId
                                },
                            }
                        )
                        .then((response) => {
                            console.log("disconnect: ", response);

                            history.push({
                                pathname: "/etax/sources/lazada/connect",
                                state: {},
                            });

                            setIsLoading(false);
                        })
                        .catch((error) => {
                            //Acquire token silent failure, and send an interactive request
                            console.log(error);
                            instance.acquireTokenRedirect(silentRequest);
                        });
                },
                (error) => {
                    console.log(error);
                }
            );
        }
    };

    useEffect(() => {
        console.log("userEffect initial call!");

        setIsLoading(true);

        if (localStorage.getItem("showShippingFee") === "no") {
            setShowShippingFee(false);
        } else {
            setShowShippingFee(true);
        }

        instance
            .acquireTokenSilent({ ...silentRequest, account: account })
            .then((tokenResponse) => {
                axios
                    .get(API_URL + "/etax/companies", {
                        headers: {
                            Authorization: "Bearer " + tokenResponse.accessToken,
                            "cid": companyId
                        },
                    })
                    .then(
                        (response) => {
                            console.log(response);

                            if (response.data[0]) {
                                if (companyId && response.data.find(_company => _company.id === companyId)) {
                                    const _company = response.data.find(_company => _company.id === companyId)
                                
                                    setCompany(_company);
    
                                    if (_company.registerVat) {
                                        setIncludeVat(true);
                                        setRegisterVat(true);
                                    } else {
                                        setIncludeVat(false);
                                        setRegisterVat(false);
                                    }
                                } else {
                                    setCompany(response.data[0]);
    
                                    if (response.data[0].registerVat) {
                                        setIncludeVat(true);
                                        setRegisterVat(true);
                                    } else {
                                        setIncludeVat(false);
                                        setRegisterVat(false);
                                    }

                                }

                                setGetNewDocuments(true);
                            } else {
                                history.push({
                                    pathname: "/etax/companies/profiles",
                                    state: {},
                                });
                            }

                            setIsLoading(false);
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
            })
            .catch((error) => {
                //Acquire token silent failure, and send an interactive request
                console.log(error);
                instance.acquireTokenRedirect(silentRequest);
            });

        instance
            .acquireTokenSilent({ ...silentRequest, account: account })
            .then((tokenResponse) => {
                axios
                    .get(API_URL + "/sources/lazada", {
                        headers: {
                            Authorization: "Bearer " + tokenResponse.accessToken,
                            "cid": companyId
                        },
                    })
                    .then(
                        (response) => {
                            console.log("lazada source response", response);

                            if (response.data?.automation?.enable) {
                                // setEnableAutomation(response.data.automation.enable);
                                // setTriggerStatus(_triggerStatus);
                                // setReceiptTaxInvoice(_receiptTaxInvoice);
                                // setSendEmail(_sendEmail);
                                // setShowShippingFee(_showShippingFee);
                                // setOnlyBillingAddress(_onlyBillingAddress);
                            }
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
            })
            .catch((error) => {
                //Acquire token silent failure, and send an interactive request
                console.log(error);
                instance.acquireTokenRedirect(silentRequest);
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function getOrderItems(orderId) {
        let orderItems = [];

        await instance
            .acquireTokenSilent({ ...silentRequest, account: account })
            .then(async (tokenResponse) => {
                await axios
                    .get(API_URL + "/sources/lazada/orderitems/" + orderId, {
                        headers: {
                            Authorization: "Bearer " + tokenResponse.accessToken,
                            "cid": companyId
                        },
                    })
                    .then(
                        function (response) {
                            console.log("response order items: ", response);

                            orderItems = response.data.orderItems.data;
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
            })
            .catch((error) => {
                //Acquire token silent failure, and send an interactive request
                console.log(error);
                // instance.acquireTokenRedirect(silentRequest);

                setItems([]);
                setLoadDataComplete(true);
                setGetNewDocuments(false);
                setIsCreateDocumentComplete(false);
            });

        return orderItems;
    }

    async function getOrderAWB(orderId) {
        setIsLoading(true);

        const _orderItems = await getOrderItems(orderId);

        const order_item_ids = []
        for (let i = 0; i < _orderItems.length; i++) {
            const { order_item_id } = _orderItems[0]

            order_item_ids.push(order_item_id)
        }

        instance
            .acquireTokenSilent({ ...silentRequest, account: account })
            .then(async (tokenResponse) => {
                const [response] = await Promise.all([
                    axios
                        // .get("http://localhost:7071/api/LazadaOrderDocumentsGet", {
                        .get(API_URL + "/sources/lazada/orders/documents", {
                            headers: {
                                Authorization: "Bearer " + tokenResponse.accessToken,
                                "cid": companyId
                            },
                            params: {
                                order_item_ids: `[${order_item_ids}]`,
                                doc_type: "shippingLabel",
                            },
                            responseType: 'text/html',
                        })
                        .catch((error) => console.log(error)),
                ]);
/* 
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', orderId + ".html"); //or any other extension
                document.body.appendChild(link);
                link.click();
                link.remove();
 
                let awbWindow = window.open('', 'blank');
                awbWindow.document.write(response.data);
*/
                let awbWindow = window.open('', '_blank');
                awbWindow.document.body.innerHTML = response.data;

                setIsLoading(false)
                
            }).catch((error) => {
                //Acquire token silent failure, and send an interactive request
                console.log(error);
                setIsLoading(false)
                setDownloadAWBFail(true)
                // instance.acquireTokenRedirect(silentRequest);
            });
    }

    useEffect(() => {
        if (getNewDocuments) {
            if (search) {
                console.log("handleSearch Running!!!");

                handleSearch();
            } else {
                console.log("getNewOrder Running!!!");
                setShowMessageBarOffsetError(false)

                instance
                    .acquireTokenSilent({ ...silentRequest, account: account })
                    .then(async (tokenResponse) => {
                        const [response] = await Promise.all([
                            axios
                                .get(
                                    API_URL + "/sources/lazada/orders"
                                    // "http://localhost:7071/api/Lazada_getOrders"
                                    , {
                                    headers: {
                                        Authorization: "Bearer " + tokenResponse.accessToken,
                                        "cid": companyId
                                    },
                                    params: {
                                        from: from + "T00:00:00+07:00",
                                        to: to + "T00:00:00+07:00",
                                        status: orderType.key,
                                        offset: (currentPage - 1) * pageSize,
                                        limit: pageSize,
                                        sort_direction: isSortedDescendingCreatedTime ? "DESC" : "ASC",
                                        sort_by: timeRangeField.key,
                                    },
                                })
                                .catch((error) => console.log(error)),
                        ]);

                        const orders = response.data?.data?.orders || []
                        const countTotal = response.data?.data?.countTotal || 0

                        const orderIdList = response.data?.data?.orders.map(_order => {
                            return String(_order.order_id)
                        }).join(",")

                        console.log("orderIdList", orderIdList)

                        const [documentsResponse] = await Promise.all([
                            axios
                                .get(`${API_URL}/documents/?referenceList=${orderIdList}`, {
                                    headers: {
                                        Authorization: "Bearer " + tokenResponse.accessToken,
                                        "cid": companyId
                                    },
                                })
                                .catch((error) => console.log(error)),
                        ]);

                        setAllDocs(documentsResponse.data)

                        let _pageDropdown = [];

                        for (let i = 1; i <= Math.ceil(countTotal / pageSize); i++) {
                            _pageDropdown.push({
                                key: i,
                                text: t("lazada.page") + " " + i,
                                iconProps: { iconName: "Page" },
                                onClick: () => {
                                    console.log("Selected page: " + i);
                                    setCurrentPage(i);
                                },
                            });
                        }

                        setPageDropdown(_pageDropdown);

                        if (currentPage > Math.ceil(countTotal / pageSize)) {
                            setCurrentPage(1);
                        }

                        setTotalDocuments(countTotal);
                        setPageData(paginate(countTotal, currentPage, pageSize, 10));

                        const _docs = [];

                        if (orders.length > 0) {
                            for (let i = 0; i < Math.min(pageSize, orders.length); i++) {
                                let _createdTime = "";
                                let _updatedTime = ""
                                let _amountTotal = 0;
                                let _vatTotal = 0;
                                let _grandTotal = 0;
                                let _vatPriceTotal = 0;
                                let _zeroVatPriceTotal = 0;
                                let _nonVatPriceTotal = 0;

                                let _customerTaxId = "N/A";
                                let _branchNumber = "";
                                let _branchText = "";
                                let _taxNumberType = "OTHR";

                                if (orders[i].created_at) {
                                    _createdTime = orders[i].created_at;
                                }

                                if (orders[i].updated_at) {
                                    _updatedTime = orders[i].updated_at
                                }

                                let _customerName = ""
                                let _customerPhone = "";
                                let _customerAddress = ""
                                let _postcode = ""

                                let _isRequest = false

                                if (orders[i].address_billing && orders[i].address_billing !== null && orders[i].address_billing !== undefined) {
                                    _customerName = orders[i].address_billing.first_name + " " + orders[i].address_billing.last_name
                                    _customerPhone = orders[i].address_billing.phone.replace("66", "");
                                    _customerAddress =  orders[i].address_billing.address1 + " " + orders[i].address_billing.address2 + " " + orders[i].address_billing.address4.split("/", 1) + " " + orders[i].address_billing.address3.split("/", 1)
                                    _postcode = orders[i].address_billing.post_code
                                } else {
                                    _customerName = orders[i].address_shipping.first_name + " " + orders[i].address_shipping.last_name
                                    _customerPhone = orders[i].address_shipping.phone.replace("66", "");
                                    _customerAddress =  orders[i].address_shipping.address1 + " " + orders[i].address_shipping.address2 + " " + orders[i].address_shipping.address4.split("/", 1) + " " + orders[i].address_shipping.address3.split("/", 1)
                                    _postcode = orders[i].address_shipping.post_code
                                }

                                _amountTotal = Math.round((Number(orders[i].price) - Number(orders[i].voucher_seller)) * 100) / 100;

                                if (showShippingFee) {
                                    _amountTotal += Number(orders[i].shipping_fee);
                                }

                                if (registerVat) {
                                    if (includeVat) {
                                        _amountTotal = Math.round((_amountTotal / ((100 + percentVat) / 100)) * 100) / 100;

                                        _vatPriceTotal = _amountTotal;

                                        _vatTotal = Math.round(_amountTotal * (percentVat / 100) * 100) / 100;
                                        _grandTotal = Math.round((_amountTotal + _vatTotal) * 100) / 100;
                                    } else {
                                        _amountTotal = Math.round(_amountTotal * 100) / 100;

                                        _vatPriceTotal = _amountTotal;

                                        _vatTotal = Math.round(_amountTotal * (percentVat / 100) * 100) / 100;
                                        _grandTotal = Math.round((_amountTotal + _vatTotal) * 100) / 100;
                                    }
                                } else {
                                    _amountTotal = Math.round(_amountTotal * 100) / 100;
                                    _zeroVatPriceTotal = _amountTotal;

                                    _vatTotal = 0;
                                    _grandTotal = Math.round(_amountTotal * 100) / 100;
                                }

                                if (orders[i].tax_code && orders[i].tax_code !== "") {
                                    _customerTaxId = removeInvisibleCharacters(orders[i].tax_code.replace(/\s+/g, ""));

                                    if (_customerTaxId.length !== 13) {
                                        _customerTaxId = "N/A";
                                    }

                                    if (_customerTaxId.length === 13) {
                                        if (
                                            _customerName.includes('บริษัท') ||
                                            _customerName.includes('ห้างหุ้นส่วน') ||
                                            _customerName.includes('จำกัด') ||
                                            _customerName.includes('Company') ||
                                            _customerName.includes('company') ||
                                            _customerName.includes('ltd.') ||
                                            _customerName.includes('Ltd.') ||
                                            _customerName.includes('Limited') ||
                                            _customerName.includes('limited') ||
                                            // _customerAddress.includes('สำนักงานใหญ่') ||
                                            // /Head Office/i.test(_customerAddress) ||
                                            // _customerAddress.includes('สาขา') ||
                                            // /Branch/i.test(_customerAddress) || 
                                            orders[i].branch_number?.includes("สำนักงานใหญ่") ||
                                            orders[i].branch_number?.toLowerCase().includes("head office") ||
                                            orders[i].branch_number?.includes("สาขา") ||
                                            orders[i].branch_number?.toLowerCase().includes("branch")
                                        ) {
                                            _taxNumberType = "TXID";

                                            if (orders[i].branch_number && orders[i].branch_number !== "") {
                                                if (
                                                    orders[i].branch_number.includes("สำนักงานใหญ่") || 
                                                    orders[i].branch_number.toLowerCase().includes("head office") || 
                                                    Number(orders[i].branch_number) === 0 || 
                                                    isNaN(orders[i].branch_number)
                                                ) {
                                                    _branchNumber = "00000";
                                                    _branchText = "สำนักงานใหญ่";
        
                                                    if (orders[i].branch_number.toLowerCase().includes("head office")) {
                                                        _branchText = "Head Office";
                                                    }
                                                } else {
                                                    // _branchNumber = orders[i].branch_number.replace(/\s+/g, '');
                                                    // _branchText = 'สาขาที่ ' + orders[i].branch_number;
                                                    if (String(Number(orders[i].branch_number)).length <= 5) {
                                                        _branchNumber = orders[i].branch_number.padStart(5, "0");
                                                        _branchText = "สาขาที่ " + orders[i].branch_number.padStart(5, "0");
                                                    } else {
                                                        _branchNumber = "00000";
                                                        _branchText = "สำนักงานใหญ่"
                                                    }
                                                }
                                            } else {
                                                _branchNumber = "00000";
                                                _branchText = "สำนักงานใหญ่";
                                            }
                                        } else {
                                            _taxNumberType = "NIDN";
                                        }
                                    }
                                }

                                if (!_customerTaxId || _customerTaxId === "N/A" || Number(_customerTaxId) === 0) {
                                    _customerTaxId = "N/A";
                                    _taxNumberType = "OTHR";
                                    _branchNumber = "";
                                    _branchText = "";
                                }

                                if (orders[i].extra_attributes) {
                                    const extra_attributes = JSON.parse(orders[i].extra_attributes)

                                    if (extra_attributes?.TaxInvoiceRequested) {
                                        _isRequest = true
                                    }
                                }
                                
                                _docs.push({
                                    key: String(orders[i].order_id),
                                    createdTime: _createdTime,
                                    updatedTime: _updatedTime,
                                    orderId: String(orders[i].order_id),
                                    customerName: _customerName,
                                    customerAddress: removeCarriageReturnAndNewline(_customerAddress),
                                    customerPhone: _customerPhone,
                                    amountTotal: _amountTotal,
                                    vatTotal: _vatTotal,
                                    grandTotal: _grandTotal,
                                    nonVatPriceTotal: _nonVatPriceTotal,
                                    zeroVatPriceTotal: _zeroVatPriceTotal,
                                    vatPriceTotal: _vatPriceTotal,
                                    customerPostcode: _postcode,
                                    customerTaxId: _customerTaxId,
                                    branchNumber: _branchNumber,
                                    branchText: _branchText,
                                    taxNumberType: _taxNumberType,
                                    note: orders[i].remarks,
                                    discount: orders[i].voucher_seller,
                                    shippingFee: orders[i].shipping_fee,
                                    // etaxCreated: responseAllDocs.data.some((_allDocs) => String(_allDocs.data.reference) === String(orders[i].order_id)),
                                    etaxCreated: documentsResponse.data.some(_doc => String(_doc.data.reference) === String(orders[i].order_id)),
                                    isRequest: _isRequest
                                });
                            }
                        }

                        if (_docs.length > 0) {
                            if (primarySort === "number") {
                                if (isSortedDescendingNumber) {
                                    _docs.sort((a, b) => {
                                        return b.orderId - a.orderId;
                                    });
                                } else {
                                    _docs.sort((a, b) => {
                                        return a.orderId - b.orderId;
                                    });
                                }
                            } else if (primarySort === "customerName") {
                                if (isSortedDescendingCustomerName) {
                                    _docs.sort((a, b) => {
                                        if (b.customerName < a.customerName) {
                                            return -1;
                                        }
                                        if (b.customerName > a.customerName) {
                                            return 1;
                                        }
                                        return 0;
                                    });
                                } else {
                                    _docs.sort((a, b) => {
                                        if (a.customerName < b.customerName) {
                                            return -1;
                                        }
                                        if (a.customerName > b.customerName) {
                                            return 1;
                                        }
                                        return 0;
                                    });
                                }
                            } else if (primarySort === "vatTotal") {
                                if (isSortedDescendingVatTotal) {
                                    _docs.sort(function (a, b) {
                                        return b.vatTotal - a.vatTotal;
                                    });
                                } else {
                                    _docs.sort(function (a, b) {
                                        return a.vatTotal - b.vatTotal;
                                    });
                                }
                            } else if (primarySort === "grandTotal") {
                                if (isSortedDescendingGrandTotal) {
                                    _docs.sort(function (a, b) {
                                        return b.grandTotal - a.grandTotal;
                                    });
                                } else {
                                    _docs.sort(function (a, b) {
                                        return a.grandTotal - b.grandTotal;
                                    });
                                }
                            } else if (primarySort === "isRequest") {
                                if (isSortedDescendingIsRequest) {
                                    _docs.sort(function(x, y) {
                                        return (x.isRequest === y.isRequest) ? 0 : x.isRequest ? -1 : 1;
                                    })
            
                                } else {
                                    _docs.sort(function(x, y) {
                                        return (x.isRequest === y.isRequest) ? 0 : x.isRequest ? 1 : -1;
                                    })
                
                                }
                            }
                        }

                        console.log("_docs", _docs)

                        setItems(_docs);
                        setLoadDataComplete(true);
                        setGetNewDocuments(false);
                    })
                    .catch((error) => {
                        //Acquire token silent failure, and send an interactive request
                        console.log(error);

                        setItems([]);
                        setLoadDataComplete(true);
                        setGetNewDocuments(false);

                        if ((currentPage - 1) * pageSize >= 5000) {
                            setShowMessageBarOffsetError(true)
                        }
                        // instance.acquireTokenRedirect(silentRequest);
                        // disconnect(true);
                    });
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getNewDocuments]);

    useEffect(() => {
        sessionStorage.setItem("lazada-currentPage", currentPage);

        if (company && company.name) {
            setLoadDataComplete(false);

            setItems([]);
            setGetNewDocuments(true);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage]);

    useEffect(() => {
        console.log("isCreateDocumentComplete useEffect: ", isCreateDocumentComplete);

        if (loadDataComplete && !isCreateDocument) {
            let _items = selection.getItems();
            console.log("_items: ", _items);

            let _selectedItems = selection.getSelection();

            if (isCreateDocumentComplete) {
                setIsCreateDocumentComplete(false);

                for (let i = 0; i < _items.length; i++) {
                    if (_items[i].status !== "") {
                        _items[i].status = "";
                    }
                }
            } else {
                for (let i = 0; i < _items.length; i++) {
                    if (_items[i].status !== "") {
                        _items[i].status = "";
                    }
                }

                for (let i = 0; i < _selectedItems.length; i++) {
                    console.log("select item key: ", _selectedItems[i].key);

                    for (let j = 0; j < _items.length; j++) {
                        if (_items[j].key === _selectedItems[i].key) {
                            console.log("set pending item key: ", _items[j].key);
                            _items[j].status = "pending";
                            console.log("_items: ", _items);
                        }
                    }
                }
            }

            setItems(_items);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedItems]);

    const handleSearch = async () => {
        setShowMessageBarOffsetError(false)

        await instance
            .acquireTokenSilent({ ...silentRequest, account: account })
            .then(async (tokenResponse) => {
                const [response, documentsResponse] = await Promise.all([
                    axios
                        .get(API_URL + "/sources/lazada/orders/" + search, {
                            headers: {
                                Authorization: "Bearer " + tokenResponse.accessToken,
                                "cid": companyId
                            },
                        })
                        .catch((error) => console.log(error)),

                    axios
                        .get(`${API_URL}/documents/?referenceList=${search}`, {
                            headers: {
                                Authorization: "Bearer " + tokenResponse.accessToken,
                                "cid": companyId
                            },
                        })
                        .catch((error) => console.log(error)),
                ]);
                
                setTotalDocuments(1);
                setPageData(paginate(1, currentPage, pageSize, 10));

                setAllDocs(documentsResponse.data)

                const _docs = [];

                console.log("response: ", response);
                console.log("response order number: ", response.data.order_number);

                if (response.data && response.data.order_number) {
                    let _createdTime = "";
                    let _updatedTime = ""
                    let _customerPhone = "";
                    let _amountTotal = 0;
                    let _vatTotal = 0;
                    let _grandTotal = 0;

                    let _vatPriceTotal = 0;
                    let _zeroVatPriceTotal = 0;
                    let _nonVatPriceTotal = 0;

                    let _customerTaxId = "N/A";
                    let _branchNumber = "";
                    let _branchText = "";
                    let _taxNumberType = "OTHR";

                    if (response.data.created_at) {
                        _createdTime = response.data.created_at;
                    }

                    if (response.data.updated_at) {
                        _updatedTime = response.data.updated_at
                    }

                    let _customerName = ""
                    let _customerAddress = ""
                    let _postcode = ""

                    let _isRequest = false

                    if (response.data.address_billing && response.data.address_billing !== null && response.data.address_billing !== undefined) {
                        _customerName = response.data.address_billing.first_name + " " + response.data.address_billing.last_name
                        _customerPhone = response.data.address_billing.phone.replace("66", "");
                        _customerAddress =  response.data.address_billing.address1 + " " + response.data.address_billing.address2 + " " + response.data.address_billing.address4.split("/", 1) + " " + response.data.address_billing.address3.split("/", 1)
                        _postcode = response.data.address_billing.post_code
                    } else {
                        _customerName = response.data.address_shipping.first_name + " " + response.data.address_shipping.last_name
                        _customerPhone = response.data.address_shipping.phone.replace("66", "");
                        _customerAddress =  response.data.address_shipping.address1 + " " + response.data.address_shipping.address2 + " " + response.data.address_shipping.address4.split("/", 1) + " " + response.data.address_shipping.address3.split("/", 1)
                        _postcode = response.data.address_shipping.post_code
                    }

                    _amountTotal = Math.round(Number(response.data.price) * 100) / 100;

                    if (showShippingFee) {
                        _amountTotal += Number(response.data.shipping_fee);
                    }

                    if (registerVat) {
                        if (includeVat) {
                            _amountTotal = Math.round((_amountTotal / ((100 + percentVat) / 100)) * 100) / 100;

                            _vatPriceTotal = _amountTotal;

                            _vatTotal = Math.round(_amountTotal * (percentVat / 100) * 100) / 100;
                            _grandTotal = Math.round((_amountTotal + _vatTotal) * 100) / 100;
                        } else {
                            _amountTotal = Math.round(_amountTotal * 100) / 100;

                            _vatPriceTotal = _amountTotal;

                            _vatTotal = Math.round(_amountTotal * (percentVat / 100) * 100) / 100;
                            _grandTotal = Math.round((_amountTotal + _vatTotal) * 100) / 100;
                        }
                    } else {
                        _amountTotal = Math.round(_amountTotal * 100) / 100;

                        _zeroVatPriceTotal = _amountTotal;

                        _vatTotal = 0;
                        _grandTotal = Math.round(_amountTotal * 100) / 100;
                    }

                    if (response.data.tax_code && response.data.tax_code !== "") {
                        _customerTaxId = removeInvisibleCharacters(response.data.tax_code.replace(/\s+/g, ""));

                        if (_customerTaxId.length !== 13) {
                            _customerTaxId = "N/A";
                        }

                        if (_customerTaxId.length === 13) {
                            if (
                                _customerName.includes('บริษัท') ||
                                _customerName.includes('ห้างหุ้นส่วน') ||
                                _customerName.includes('จำกัด') ||
                                _customerName.includes('Company') ||
                                _customerName.includes('company') ||
                                _customerName.includes('ltd.') ||
                                _customerName.includes('Ltd.') ||
                                _customerName.includes('Limited') ||
                                _customerName.includes('limited') ||
                                // _customerAddress.includes('สำนักงานใหญ่') ||
                                // /Head Office/i.test(_customerAddress) ||
                                // _customerAddress.includes('สาขา') ||
                                // /Branch/i.test(_customerAddress) || 
                                response.data.branch_number?.includes("สำนักงานใหญ่") ||
                                response.data.branch_number?.toLowerCase().includes("head office") ||
                                response.data.branch_number?.includes("สาขา") ||
                                response.data.branch_number?.toLowerCase().includes("branch")
                            ) {
                                _taxNumberType = "TXID";

                                if (response.data.branch_number && response.data.branch_number !== "") {
                                    if (
                                        response.data.branch_number.includes("สำนักงานใหญ่") || 
                                        response.data.branch_number.toLowerCase().includes("head office") || 
                                        Number(response.data.branch_number) === 0 || 
                                        isNaN(response.data.branch_number)
                                    ) {
                                        _branchNumber = "00000";
                                        _branchText = "สำนักงานใหญ่";
        
                                        if (response.data.branch_number.toLowerCase().includes("head office")) {
                                            _branchText = "Head Office";
                                        }
                                    } else {
                                        // _branchNumber = response.data.branch_number.replace(/\s+/g, '');
                                        // _branchText = 'สาขาที่ ' + response.data.branch_number;
                                        if (String(Number(response.data.branch_number)).length <= 5) {
                                            _branchNumber = response.data.branch_number.padStart(5, "0");
                                            _branchText = "สาขาที่ " + response.data.branch_number.padStart(5, "0");
                                        } else {
                                            _branchNumber = "00000";
                                            _branchText = "สำนักงานใหญ่"
                                        }
                                    }
                                } else {
                                    _branchNumber = "00000";
                                    _branchText = "สำนักงานใหญ่";
                                }
                            } else {
                                _taxNumberType = "NIDN";
                            }
                        }

                        
                    }

                    if (!_customerTaxId || _customerTaxId === "N/A" || Number(_customerTaxId) === 0) {
                        _customerTaxId = "N/A";
                        _taxNumberType = "OTHR";
                        _branchNumber = "";
                        _branchText = "";
                    }

                    if (response.data.extra_attributes) {
                        const extra_attributes = JSON.parse(response.data.extra_attributes)
    
                        if (extra_attributes?.TaxInvoiceRequested) {
                            _isRequest = true
                        }
                    }


                    _docs.push({
                        key: String(response.data.order_id),
                        createdTime: _createdTime,
                        updatedTime: _updatedTime,
                        orderId: String(response.data.order_id),
                        customerName: _customerName,
                        customerAddress: removeCarriageReturnAndNewline(_customerAddress),
                        customerPhone: _customerPhone,
                        amountTotal: _amountTotal,
                        vatTotal: _vatTotal,
                        grandTotal: _grandTotal,
                        nonVatPriceTotal: _nonVatPriceTotal,
                        zeroVatPriceTotal: _zeroVatPriceTotal,
                        vatPriceTotal: _vatPriceTotal,
                        customerPostcode: _postcode,
                        customerTaxId: _customerTaxId,
                        branchNumber: _branchNumber,
                        branchText: _branchText,
                        taxNumberType: _taxNumberType,
                        note: response.data.remarks,
                        discount: 0,
                        shippingFee: response.data.shipping_fee,
                        // etaxCreated: responseAllDocs.data.some((_allDocs) => String(_allDocs.data.reference) === String(response.data.order_id)),
                        etaxCreated: documentsResponse.data.some(_doc => String(_doc.data.reference) === String(response.data.order_id)),
                        isRequest: _isRequest
                    });
                }

                console.log("_docs", _docs)

                setItems(_docs);
                setLoadDataComplete(true);
                setGetNewDocuments(false);
            })
            .catch((error) => {
                //Acquire token silent failure, and send an interactive request
                console.log(error);
                instance.acquireTokenRedirect(silentRequest);

                setItems([]);
                setLoadDataComplete(true);
                setGetNewDocuments(false);

                if ((currentPage - 1) * pageSize >= 5000) {
                    setShowMessageBarOffsetError(true)
                }
            });
    };

    const handleSort = (primary, isSorted) => {
        if (items.length > 0) {
            let _items = selection.getItems();
            let _selectedItems = selection.getSelection();
            console.log("selection: ", selection.getSelection());

            for (let i = 0; i < _selectedItems.length; i++) {
                console.log("select item key: ", _selectedItems[i].key);
                _selectedItems[i].status = "";

                for (let j = 0; j < _items.length; j++) {
                    if (_items[j].key === _selectedItems[i].key) {
                        console.log("item key: ", _items[j].key);
                        _items[j].status = "";
                        _selectedItems[i].status = "";
                    }
                }
            }

            selection.setAllSelected(false);

            if (primary === "customerName") {
                if (isSorted) {
                    _items.sort((a, b) => {
                        if (b.customerName < a.customerName) {
                            return -1;
                        }
                        if (b.customerName > a.customerName) {
                            return 1;
                        }
                        return 0;
                    });

                    setItems(_items);
                } else {
                    _items.sort((a, b) => {
                        if (a.customerName < b.customerName) {
                            return -1;
                        }
                        if (a.customerName > b.customerName) {
                            return 1;
                        }
                        return 0;
                    });

                    setItems(_items);
                }
            } else if (primary === "vatTotal") {
                if (isSorted) {
                    _items.sort(function (a, b) {
                        return b.vatTotal - a.vatTotal;
                    });

                    setItems(_items);
                } else {
                    _items.sort(function (a, b) {
                        return a.vatTotal - b.vatTotal;
                    });

                    setItems(_items);
                }
            } else if (primary === "grandTotal") {
                if (isSorted) {
                    _items.sort(function (a, b) {
                        return b.grandTotal - a.grandTotal;
                    });

                    setItems(_items);
                } else {
                    _items.sort(function (a, b) {
                        return a.grandTotal - b.grandTotal;
                    });

                    setItems(_items);
                }
            } else if (primary === "isRequest") {
                if (isSorted) {
                    _items.sort(function(x, y) {
                        return (x.isRequest === y.isRequest) ? 0 : x.isRequest ? -1 : 1;
                    })

                    setItems(_items);
                } else {
                    _items.sort(function(x, y) {
                        return (x.isRequest === y.isRequest) ? 0 : x.isRequest ? 1 : -1;
                    })

                    setItems(_items);
                }
            }
        }
    };

    const onPageChange = (selectedPageIndex) => {
        console.log("selectedPageIndex: ", selectedPageIndex);
        setCurrentPage(selectedPageIndex + 1);
    };

    return (
        <div style={homeStyles}>
            {downloadAWBFail && (
            <MessageBar
                onDismiss={() => {
                    setDownloadAWBFail(false);
                }}
                messageBarType={MessageBarType.error}
                dismissButtonAriaLabel={t('documents.close')}
                isMultiline={false}
                style={{
                fontSize: 14,
                }}
            >
                {t("lazada.messageBarAWBFails")}
            </MessageBar>
            )}
            {showMessageBarOffsetError && (
                <MessageBar
                    onDismiss={() => {
                        setShowMessageBarOffsetError(false);
                    }}
                    messageBarType={MessageBarType.error}
                    dismissButtonAriaLabel={t('documents.close')}
                    isMultiline={false}
                    style={{
                    fontSize: 14,
                    }}
                >
                    {t("lazada.offsetError")}
                </MessageBar>
            )}
            {!isLoading ? (
                <div>
                    <Stack>
                        <center>
                            <h2 style={{ marginLeft: "0px", marginTop: "25px" }}>{t("lazada.header")}</h2>
                            {!loadDataComplete ? (
                                <div>
                                    <Spinner
                                        label={t("lazada.loading")}
                                        labelPosition="right"
                                        style={{
                                            marginTop: "21px",
                                            marginBottom: "2px",
                                        }}
                                    />

                                    <br />
                                </div>
                            ) : (
                                !search && (
                                    <h4>
                                        {queryMonth.text !== "เดือนนี้" && queryMonth.text !== "This Month" ? t("lazada.month") : ""}
                                        {getDateText(queryMonth)} ({totalDocuments.toLocaleString(undefined)} {t("lazada.orderUnit")})
                                    </h4>
                                )
                            )}

                            <Stack horizontal horizontalAlign="center">
                                <Toggle
                                    label={t("lazada.displayShipCost")}
                                    inlineLabel
                                    onText={t("lazada.on")}
                                    offText={t("lazada.off")}
                                    checked={showShippingFee}
                                    disabled={!loadDataComplete || isCreateDocument}
                                    onChange={() => {
                                        setShowShippingFee(!showShippingFee);

                                        if (!showShippingFee) {
                                            localStorage.setItem("showShippingFee", "yes");
                                        } else {
                                            localStorage.setItem("showShippingFee", "no");
                                        }

                                        setLoadDataComplete(false);
                                        setItems([]);
                                        setGetNewDocuments(true);

                                        setTotalDocuments(0);
                                    }}
                                />
                            </Stack>
                        </center>
                    </Stack>

                    {(selectedItems.length > 0 || isCreateDocument || isCreateDocumentComplete) && (
                        <Stack vertical tokens={{ childrenGap: "10px" }}>
                            <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: "10px" }}>
                                <h3>{t("lazada.subHeader")}</h3>
                            </Stack>
                            <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: "10px" }}>
                                <TextField
                                    name="prefixNumber"
                                    label={t("lazada.prefixNumber")}
                                    description={t("lazada.prefixNumberDescription")}
                                    styles={{ root: { width: 200 } }}
                                    onChange={(e) => {
                                        setPrefixNumber(e.target.value);
                                    }}
                                    value={prefixNumber}
                                    disabled={isCreateDocument}
                                />
                                <TextField
                                    name="countingNumber"
                                    label={t("lazada.countingNumber")}
                                    description={countingNumber.length === 0 ? "" : t("lazada.countingNumberDesciption")}
                                    styles={{ root: { width: 150 } }}
                                    onChange={(e) => {
                                        if (!/\D/.test(e.target.value)) {
                                            setCountingNumber(e.target.value);
                                        }
                                    }}
                                    value={countingNumber}
                                    errorMessage={countingNumber.length === 0 ? t("lazada.countingNumberErrorMessage") : ""}
                                    disabled={isCreateDocument}
                                    required
                                />
                            </Stack>
                            <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: '10px' }}>
                                <TextField
                                    name="createdBy"
                                    label={t('lazada.createdBy')}
                                    description={t('lazada.createdByDesc')}
                                    styles={{ root: { width: 200 } }}
                                    onChange={(e) => {
                                        setCreatedBy(e.target.value);
                                    }}
                                    value={createdBy}
                                    disabled={isCreateDocument}
                                />
                            </Stack>
                            {countingNumber.length > 0 ? (
                                <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: "10px" }}>
                                    {!isCreateDocument ? (
                                        <h4>
                                            {t("lazada.startDocumentNumber")} {prefixNumber}
                                            {countingNumber}
                                        </h4>
                                    ) : (
                                        <h4>
                                            {t("lazada.endDocumentNumber")} {prefixNumber}
                                            {countingNumber}
                                        </h4>
                                    )}
                                </Stack>
                            ) : (
                                <br />
                            )}
                        </Stack>
                    )}

                    {selectedItems.length > 0 && !isCreateDocument && (
                        <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: "10px" }}>
                            <PrimaryButton
                                text={t("lazada.createDocument")}
                                iconProps={{ iconName: "Add" }}
                                menuProps={{
                                    items: [
                                        {
                                            key: "RECEIPT-TAXINVOICE",
                                            text: t("lazada.receipt-taxinvoice"),
                                            iconProps: { iconName: "PageAdd" },
                                            onClick: async () => {
                                                if (countingNumber.length > 0) {
                                                    // setIsCreateDocument(true);
                                                    // await createSelectedDocuments("RECEIPT-TAXINVOICE");
                                                    await createSelectedDocumentsOnClick("RECEIPT-TAXINVOICE");
                                                }
                                            },
                                        },
                                        {
                                            key: "RECEIPT-TAXINVOICE-ABB",
                                            text: t("lazada.receipt-taxinvoice-abb"),
                                            iconProps: { iconName: "PageAdd" },
                                            onClick: async () => {
                                                if (countingNumber.length > 0) {
                                                    // setIsCreateDocument(true);
                                                    // await createSelectedDocuments("RECEIPT-TAXINVOICE-ABB");
                                                    await createSelectedDocumentsOnClick("RECEIPT-TAXINVOICE-ABB");
                                                }
                                            },
                                        },
                                        {
                                            key: "RECEIPT",
                                            text: t("lazada.receipt"),
                                            iconProps: { iconName: "PageAdd" },
                                            onClick: async () => {
                                                if (countingNumber.length > 0) {
                                                    // setIsCreateDocument(true);
                                                    // await createSelectedDocuments("RECEIPT");
                                                    await createSelectedDocumentsOnClick("RECEIPT");
                                                }
                                            },
                                        },
                                        {
                                            key: "INVOICE-TAXINVOICE",
                                            text: t("lazada.invoice-taxinvoice"),
                                            iconProps: { iconName: "PageAdd" },
                                            onClick: async () => {
                                                if (countingNumber.length > 0) {
                                                    // setIsCreateDocument(true);
                                                    // await createSelectedDocuments("INVOICE-TAXINVOICE");
                                                    await createSelectedDocumentsOnClick("INVOICE-TAXINVOICE");
                                                }
                                            },
                                        },
                                        {
                                            key: "DELIVERYORDER-TAXINVOICE",
                                            text: t("lazada.deliveryorder-taxinvoice"),
                                            iconProps: { iconName: "PageAdd" },
                                            onClick: async () => {
                                                if (countingNumber.length > 0) {
                                                    // setIsCreateDocument(true);
                                                    // await createSelectedDocuments("DELIVERYORDER-TAXINVOICE");
                                                    await createSelectedDocumentsOnClick("DELIVERYORDER-TAXINVOICE");
                                                }
                                            },
                                        },
                                        {
                                            key: "TAXINVOICE",
                                            text: t("lazada.taxinvoice"),
                                            iconProps: { iconName: "PageAdd" },
                                            onClick: async () => {
                                                if (countingNumber.length > 0) {
                                                    // setIsCreateDocument(true);
                                                    // await createSelectedDocuments("TAXINVOICE");
                                                    await createSelectedDocumentsOnClick("TAXINVOICE");
                                                }
                                            },
                                        },
                                    ],
                                    shouldFocusOnMount: true,
                                    directionalHintFixed: true,
                                }}
                                // Optional callback to customize menu rendering
                                menuAs={(props) => <ContextualMenu {...props} />}
                            />
                            <DefaultButton
                                text={t("lazada.cancel")}
                                style={{ marginLeft: "10px" }}
                                onClick={() => {
                                    let _items = selection.getItems();
                                    let _selectedItems = selection.getSelection();
                                    console.log("selection: ", selection.getSelection());

                                    for (let i = 0; i < _selectedItems.length; i++) {
                                        console.log("select item key: ", _selectedItems[i].key);
                                        _selectedItems[i].status = "";

                                        for (let j = 0; j < _items.length; j++) {
                                            if (_items[j].key === _selectedItems[i].key) {
                                                console.log("item key: ", _items[j].key);
                                                _items[j].status = "";
                                                _selectedItems[i].status = "";
                                            }
                                        }
                                    }

                                    selection.setAllSelected(false);

                                    setItems(_items);
                                    console.log("selection: ", selection.getSelection());
                                }}
                            />
                            <br />
                            <br />
                        </Stack>
                    )}

                    {isCreateDocument && (
                        <div>
                            <Spinner label={t("lazada.signProcessing")} size={SpinnerSize.large} />
                        </div>
                    )}

                    {isCreateDocumentComplete && (
                        <center>
                            <PrimaryButton
                                text={t("lazada.createdDocuments")}
                                iconProps={{ iconName: "Documentation" }}
                                onClick={async () => {
                                    console.log(selectedItems);
                                    history.push({
                                        pathname: "/etax/documents/all",
                                        state: {},
                                    });
                                }}
                            />
                        </center>
                    )}
                    <br />

                    <Stack horizontal horizontalAlign="center">
                        <Stack
                            vertical
                            style={{
                                width: "98%",
                                minWidth: "800px",
                                maxWidth: "1350px",
                            }}
                        >
                            {/* <Stack horizontal horizontalAlign="end" style={{ marginRight: 15, marginTop: -50 }}>
                                <Icon iconName="Robot" style={{ color: "#106ebe", marginRight: "7px", marginTop: "-3px", fontSize: "21px" }} />
                                <Link
                                    disabled={true}
                                    onClick={() => {
                                        history.push({
                                            pathname: "/etax/sources/lazada/automation",
                                            state: {},
                                        });
                                    }}
                                >
                                    <h3 style={{ marginTop: 0, width: 140 }}>{t("lazada.automation")}</h3>
                                </Link>
                            </Stack>

                            {enableAutomation ? (
                                <Stack horizontal horizontalAlign="end" style={{ marginRight: 13, marginTop: -10, marginBottom: 10 }}>
                                    <Icon iconName="SyncStatusSolid" style={{ color: "#008000", marginTop: "-2px", marginRight: "10px", fontSize: "20px" }} />
                                    <span style={{ marginTop: 0, width: 140, color: "#008000" }}>{t("lazada.onAutomationStatus")}</span>
                                </Stack>
                            ) : (
                                <Stack horizontal horizontalAlign="end" style={{ marginRight: 13, marginTop: -10, marginBottom: 10 }}>
                                        <Icon iconName="Blocked2" style={{ color: "#106ebe", marginTop: "-2px", marginRight: "10px", fontSize: "20px" }} />
                                    <span style={{ marginTop: 0, width: 140 }}>{t("lazada.offAutomationStatus")}</span>
                                </Stack>
                            )} */}

                            <Stack 
                                horizontal 
                                styles={{
                                    root: {
                                        backgroundColor: 'white',
                                    },
                                }} 
                                horizontalAlign="space-between"
                            >
                                <Stack
                                    styles={{
                                        root: {
                                            width: "calc(100vw - 700px)",
                                            minWidth: "700px",
                                            maxWidth: "900px",
                                        },
                                    }}
                                >
                                    <CommandBar
                                        items={commandBarItems}
                                        overflowItems={overflowItems}
                                    />
                                </Stack>
                                <Stack
                                    horizontal
                                    styles={{
                                        root: {
                                            height: 44,
                                        },
                                    }}
                                    tokens={{ childrenGap: "10px" }}
                                >
                                    <SearchBox
                                        className="ms-borderColor-themePrimary"
                                        styles={{
                                            root: {
                                                marginTop: 6,
                                                marginLeft: 6,
                                                width: 200,
                                                fontSize: 13,
                                                fontWeight: 0,
                                            },
                                        }}
                                        disabled={!loadDataComplete || isCreateDocument}
                                        placeholder={t("lazada.searchBoxPlaceholder")}
                                        onChange={(e) => setSearch(e ? e.target.value.replace(/\s/g, "") : "")}
                                        onClear={() => {
                                            setLoadDataComplete(false);
                                            setItems([]);
                                            setGetNewDocuments(true);

                                            setCurrentPage(1);

                                            setTotalDocuments(0);
                                        }}
                                        onSearch={() => {
                                            setLoadDataComplete(false);
                                            setItems([]);
                                            setGetNewDocuments(true);

                                            setCurrentPage(1);

                                            setTotalDocuments(0);
                                        }}
                                        value={search}
                                    />
                                    <PrimaryButton
                                        disabled={!loadDataComplete || !search || isCreateDocument}
                                        styles={{
                                            root: {
                                                marginTop: 6,
                                                marginRight: 8,
                                                width: 90,
                                            },
                                        }}
                                        text={t("lazada.search")}
                                        onClick={() => {
                                            setLoadDataComplete(false);
                                            setItems([]);
                                            setGetNewDocuments(true);

                                            setCurrentPage(1);

                                            setTotalDocuments(0);
                                        }}
                                    />
                                </Stack>
                            </Stack>
                            <ShimmeredDetailsList
                                items={items || []}
                                enableShimmer={!loadDataComplete}
                                compact={isCompactMode}
                                columns={columns}
                                selection={selection}
                                selectionMode={!loadDataComplete || isCreateDocument ? SelectionMode.none : SelectionMode.multiple}
                                layoutMode={DetailsListLayoutMode.justified}
                                isHeaderVisible={true}
                                checkboxVisibility={CheckboxVisibility.always}
                                selectionPreservedOnEmptyClick={true}
                            />
                        </Stack>
                    </Stack>
                    <br />
                    <center>
                        {!(items.length === 0 || (currentPage === 1 && items.length < pageSize)) && !(!loadDataComplete || isCreateDocument) && (
                            <Pagination
                                selectedPageIndex={currentPage - 1}
                                pageCount={pageData.totalPages}
                                onPageChange={onPageChange}
                                format
                                firstPageIconProps={{
                                    iconName: "DoubleChevronLeft",
                                }}
                                previousPageIconProps={{
                                    iconName: "ChevronLeft",
                                }}
                                nextPageIconProps={{ iconName: "ChevronRight" }}
                                lastPageIconProps={{
                                    iconName: "DoubleChevronRight",
                                }}
                            />
                        )}

                        <br />
                        <br />
                    </center>
                </div>
            ) : (
                <Stack>
                    <Stack horizontal horizontalAlign="center">
                        <br />
                        <br />
                        <div className="certificates-loading-center">
                            <Spinner label={t("lazada.loading")} labelPosition="down" styles={spinnerStyles} />
                        </div>
                        <br />
                    </Stack>
                </Stack>
            )}
            <Dialog
                hidden={!dialogConfirm.isClick}
                onDismiss={dialogConfirm.onCancel}
                styles={{ main: { minHeight: 0 } }}
                maxWidth={dialogConfirm.maxWidth}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: dialogConfirm.header,
                    styles: { header: { textAlign: "center" }, title: { paddingRight: 24 }, subText: { textAlign: "center" } },
                    subText: dialogConfirm.message,
                }}
            >
                {dialogConfirm.content}
                <DialogFooter styles={{ actionsRight: { textAlign: "center" } }}>
                    <PrimaryButton onClick={dialogConfirm.onConfirm} text={t("documents.confirm")} />
                    <DefaultButton onClick={dialogConfirm.onCancel} text={t("documents.cancel")} />
                </DialogFooter>
            </Dialog>
        </div>
    );
}

export default Lazada;
